import { SVGProps } from 'react';

const PilatesSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M10.875 22.984a2.597 2.597 0 0 1 2.586 2.585 2.594 2.594 0 0 1-2.586 2.585 2.585 2.585 0 0 1 0-5.17Z"
    />
    <path
      fill="#6C5DD3"
      d="M31.281 17.298h-5.903c-.448 0-.807.161-1.074.407a6.24 6.24 0 0 0-6.19-5.423c-3.444 0-6.246 2.788-6.246 6.214a6.213 6.213 0 0 0 3.253 5.451l-1.097 1.317a1.913 1.913 0 0 0 1.71 3.013h9.299c1.958 0 1.961-1.991 1.961-2.007V20.07h4.287a1.387 1.387 0 0 0 0-2.773Zm-18.56 1.198c0-2.958 2.42-5.365 5.393-5.365 2.974 0 5.394 2.407 5.394 5.365 0 2.796-2.162 5.096-4.91 5.34l1.635-1.963a1.3 1.3 0 0 0 .231-1.254L18.93 16.14a1.3 1.3 0 0 0-2.46.843l1.299 3.786-2.088 2.506c-1.752-.887-2.959-2.694-2.959-4.78Zm8.56 5.346a6.257 6.257 0 0 0 2.47-2.688v2.682l-2.47.006Z"
    />
  </svg>
);
export default PilatesSvg;
