import { useNavigate } from 'react-router-dom';
import { linearProgressClasses, ListItemButton, Stack, Typography } from '@mui/material';
import { dayjs } from '../../../../utils/dayjs';
import { EventByType } from './types';
import { LinearProgress } from '../../../atoms/LinearProgress';
import { getBarColor } from './utils';

export const EventItem = ({ event, isActive }: { event: EventByType; isActive: boolean }) => {
  const navigate = useNavigate();
  const { event: scheduledEvent, duration, timeLeft } = event;

  return (
    <ListItemButton
      onClick={() =>
        navigate(
          `/schedule?eventId=${scheduledEvent.id}&eventDate=${
            new Date(scheduledEvent.start).toISOString().split('T')[0]
          }`,
        )
      }
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack width="100%">
        <Stack alignItems="center" justifyContent="space-between" direction="row" width="100%">
          <Typography
            sx={{
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '65%',
            }}
          >
            {scheduledEvent.title}
          </Typography>
          {!isActive && (
            <Typography
              sx={{
                fontWeight: 400,
              }}
            >
              {dayjs(scheduledEvent.start).format('hh:mm a')}
            </Typography>
          )}
        </Stack>
        {isActive && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 0.5, width: '100%' }}
          >
            <LinearProgress
              variant="determinate"
              value={100 - duration}
              sx={{
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor: getBarColor(100 - duration),
                },
                maxWidth: '75%',
                flex: 1,
              }}
            />

            <Typography sx={{ ml: 1 }} variant="body2">
              {timeLeft} left
            </Typography>
          </Stack>
        )}
      </Stack>
    </ListItemButton>
  );
};
