export const abbreviation = (text: string) => {
  const names = text.split(/\s+/);
  let initials = names[0]!.slice(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1]!.slice(0, 1).toUpperCase();
  }

  return initials;
};
