import {
  Checkbox,
  Drawer,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Dialog,
  Slide,
  CircularProgress,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { ReactElement, useCallback, useState, forwardRef, useMemo, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../../../theme';
import { useCreateAssessmentResponseMutation } from '../../../../graphql';
import { useCurrentUser } from '../../../../states';
import { getDateStringFromDateTime } from '../../../../utils';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { useActiveJournal } from '../../../../states/journal/useActiveJournal';
import { usePhoneCall } from '../../../../states/vonage/usePhoneCall';
import { useVideoCall } from '../../../../states/vonage/useVideoCall';

const Transition = forwardRef(function Transition(
  properties: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  reference: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={reference} {...properties} />;
});
const REASONS = [
  { i18nKey: 'journal.decline.reason.option1', value: 'I forgot' },
  { i18nKey: 'journal.decline.reason.option2', value: 'I was too busy' },
  { i18nKey: 'journal.decline.reason.option3', value: `I didn't feel good` },
  { i18nKey: 'journal.decline.reason.option4', value: 'I was too tired' },
];

export function DeclinePopup(): ReactElement {
  const { t, Trans } = usePreferredTranslation();
  const { data: userData } = useCurrentUser();
  const {
    incompleteModalJournal: journal,
    activeDate,
    setIncompleteModalJournal,
    refetchJournalDetails,
  } = useActiveJournal();
  const [reasons, setReasons] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [isOtherReasonSelected, setIsOtherReasonSelected] = useState<boolean>(false);
  const [otherReason, setOtherReason] = useState('');
  const [createAssessmentResponse] = useCreateAssessmentResponseMutation();
  const sm = useMediaQuery('(min-width:750px)');
  const { conferenceId: phoneConferenceId } = usePhoneCall();
  const { conferenceId: videoConferenceId } = useVideoCall();
  const open = !!journal;

  useEffect(() => {
    if (!open) {
      setOtherReason('');
      setReasons([]);
      setIsOtherReasonSelected(false);
      setLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (isOtherReasonSelected) {
      setOtherReason('');
    }
  }, [isOtherReasonSelected]);

  const onClose = useCallback(() => {
    setIncompleteModalJournal(null);
  }, [setIncompleteModalJournal]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const values = [...reasons];
    if (isOtherReasonSelected) {
      values.push(otherReason);
    }
    await createAssessmentResponse({
      variables: {
        assessmentResponse: {
          library: Number(journal?.id) || 0,
          user: Number(userData?.user?.id),
          conversationUUID: phoneConferenceId || videoConferenceId,
          answerDate: getDateStringFromDateTime(activeDate) as unknown as Date,
          answers:
            journal?.questions?.map((ques) => {
              return {
                questionId: Number(ques.id) || 0,
                values,
                comment: '',
              };
            }) || [],
        },
      },
    });
    onClose();
    refetchJournalDetails();
    setLoading(false);
  }, [
    activeDate,
    journal,
    userData,
    otherReason,
    createAssessmentResponse,
    reasons,
    onClose,
    isOtherReasonSelected,
    refetchJournalDetails,
    phoneConferenceId,
    videoConferenceId,
  ]);

  const onOtherReasonChange = useCallback((event: any) => {
    setOtherReason(event.target.value);
  }, []);

  const PopupContent = useMemo(
    () => (
      <Stack direction="column" sx={{ p: 2.5, width: '100%' }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <Typography
            fontSize={theme.typography.pxToRem(20)}
            fontWeight={600}
            sx={{ maxWidth: 240 }}
          >
            {t('journal.decline.title', { title: journal?.title || '' })}
          </Typography>
          <Typography variant="subtitle1">{t('journal.decline.reason.subtitle')}</Typography>
          <Stack spacing={1}>
            {REASONS.map((reason) => (
              <FormControlLabel
                key={reason.value}
                label={<Trans i18nKey={reason.i18nKey}>{reason.value}</Trans>}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(event, value) => {
                      if (value) {
                        setReasons([...reasons, reason.value]);
                      } else {
                        setReasons(reasons.filter((r) => r !== reason.value));
                      }
                    }}
                  />
                }
                sx={{ m: 0 }}
              />
            ))}
            <Stack direction="row" alignItems="center">
              <Checkbox
                color="primary"
                onChange={(event) => setIsOtherReasonSelected(event.target.checked)}
              />
              <TextField
                disabled={!isOtherReasonSelected}
                variant="standard"
                placeholder={t('journal.decline.reason.option.other')}
                value={otherReason}
                onChange={onOtherReasonChange}
                fullWidth
              />
            </Stack>
            <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
              <Button fullWidth variant="contained" onClick={handleSubmit}>
                <Trans i18nKey="journal.decline.button.submit">Submit</Trans>
              </Button>
              <Button fullWidth color="secondary" onClick={onClose}>
                <Trans i18nKey="journal.decline.button.cancel">Cancel</Trans>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      journal?.title,
      handleSubmit,
      onOtherReasonChange,
      otherReason,
      reasons,
      t,
      isOtherReasonSelected,
      onClose,
      Trans,
    ],
  );

  if (sm) {
    return (
      <Dialog
        open={!!open}
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
          },
        }}
        TransitionComponent={Transition}
        transitionDuration={350}
      >
        {PopupContent}
        {loading && (
          <CircularProgress
            sx={{
              position: 'fixed',
              top: '45%',
              zIndex: 1000,
              alignSelf: 'center',
            }}
          />
        )}
      </Dialog>
    );
  }

  return (
    <Drawer
      open={open}
      anchor="bottom"
      sx={{ position: 'relative' }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      }}
      ModalProps={{ onBackdropClick: onClose }}
      transitionDuration={350}
    >
      {PopupContent}
      {loading && (
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '45%',
            zIndex: 1000,
            alignSelf: 'center',
          }}
        />
      )}
    </Drawer>
  );
}
