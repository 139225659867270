import { RefObject, useCallback, useRef } from 'react';

export function usePreloader(selector = '.preloader') {
  const reference: RefObject<HTMLElement> = useRef<HTMLElement>(document.querySelector(selector));

  const preloader = useCallback(
    (bool: boolean) => {
      if (reference?.current) {
        reference.current.style.display = bool ? 'flex' : 'none';
      }
    },
    [reference],
  );

  return { preloader };
}
