export const PUSHER = {
  CHANNELS: {
    MESSAGES: 'messages',
    NOTES: 'notes',
    NOTIFICATIONS: 'notifications',
    EMAILS: 'emails',
    VOICES: 'voices',
    EVENTS: 'events',
    RECORDINS: 'recordings',
  },
  EVENTS: {
    // messages
    MESSAGE_CREATED: 'message.created',
    MESSAGE_UPDATED: 'message.updated',
    MESSAGE_DELETED: 'message.deleted',
    MESSAGES_UPDATED: 'messages.updated',
    // notes
    NOTE_CREATED: 'note.created',
    NOTE_UPDATED: 'note.updated',
    NOTE_DELETED: 'note.deleted',
    NOTES_UPDATED: 'notes.updated',
    // notifications
    NOTIFICATION_CREATED: 'notification.created',
    NOTIFICATION_UPDATED: 'notification.updated',
    NOTIFICATION_DELETED: 'notification.deleted',
    NOTIFICATIONS_UPDATED: 'notifications.updated',
    // emails
    EMAIL_CREATED: 'email.created',
    EMAIL_UPDATED: 'email.updated',
    EMAIL_DELETED: 'email.deleted',
    EMAILS_UPDATED: 'emails.updated',
    // voices
    VOICE_CREATED: 'voice.created',
    VOICE_UPDATED: 'voice.updated',
    VOICE_DELETED: 'voice.deleted',
    VOICES_UPDATED: 'voices.updated',
    // events
    EVENT_CREATED: 'event.created',
    EVENT_UPDATED: 'event.updated',
    EVENT_DELETED: 'event.deleted',
    EVENTS_UPDATED: 'events.updated',

    // recordings
    RECORDING_CREATED: 'recording.created',
    RECORDING_UPDATED: 'recording.updated',
    RECORDING_DELETED: 'recording.deleted',
    RECORDINGS_UPDATED: 'recordings.updated',
  },
};
