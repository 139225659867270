import { SVGProps } from 'react';

const VideoCallSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} fill="none" {...properties}>
    <path
      fill="#43A047"
      d="M4 10.2h4.8a.774.774 0 0 0 .57-.23.774.774 0 0 0 .23-.57V7.64l2.22 1.78c.2.16.413.186.64.08a.553.553 0 0 0 .34-.54V5.04a.553.553 0 0 0-.34-.54.568.568 0 0 0-.64.08L9.6 6.36V4.6a.774.774 0 0 0-.23-.57.774.774 0 0 0-.57-.23H4a.774.774 0 0 0-.57.23.774.774 0 0 0-.23.57v4.8c0 .226.077.416.23.57.153.153.343.23.57.23Zm-2.4 3.2a1.54 1.54 0 0 1-1.13-.47A1.54 1.54 0 0 1 0 11.8V2.2c0-.44.157-.817.47-1.13A1.54 1.54 0 0 1 1.6.6h12.8c.44 0 .817.156 1.13.47.313.313.47.69.47 1.13v9.6c0 .44-.157.816-.47 1.13a1.54 1.54 0 0 1-1.13.47H1.6Z"
    />
  </svg>
);
export default VideoCallSvg;
