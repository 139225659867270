import { ReactElement } from 'react';
import { FormControl, IconButton, Typography, Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { theme } from '../../../theme';

interface Properties {
  index?: number;
  label?: string;
  value?: string;
  wrapperSx?: object;
  hideLabel?: boolean;
  onChange: (value: string) => void;
}

export const StyledInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    position: 'relative',
  },
  'label + &.Mui-focused': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export function PlusMinusField({
  label,
  value,
  onChange,
  wrapperSx,
  hideLabel = false,
}: Properties): ReactElement<Properties> {
  const currentValue = Number.parseInt(value || '0', 10);
  const { t } = usePreferredTranslation();
  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2, ...wrapperSx }}>
      {!hideLabel && <Typography sx={{ fontSize: theme.typography.h5 }}>{label}</Typography>}

      <Box
        sx={{
          pt: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton
          data-testid="minus-button"
          onClick={() => {
            if (currentValue > 0) {
              onChange(`${currentValue - 1}`);
            }
          }}
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={currentValue === 0}
          sx={{ background: theme.palette.grey[300] }}
        >
          <RemoveIcon />
        </IconButton>
        <Typography sx={{ fontSize: theme.typography.h3, fontWeight: 'normal', mx: 4 }}>
          {currentValue}
        </Typography>
        <IconButton
          data-testid="plus-button"
          onClick={() => onChange(`${currentValue + 1}`)}
          color="secondary"
          aria-label="upload picture"
          component="label"
          sx={{ background: theme.palette.grey[300] }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </FormControl>
  );
}
