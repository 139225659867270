import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { get } from 'lodash';
import { ISectionProperty, usePainSectionsStore } from '../../../journaling/pain/store';

import { IBodySections } from '../constant';
import { Image } from '../../Image';
import { PainLevelPopup } from '../PainLevelPopup';

interface Properties {
  defaultSections?: IBodySections[];
  originImg: string;
  onChangeValue?: (value: any) => void;
  value?: any[];
  handleDelete?: (id: string) => void;
}

export function BodyPainControl({
  defaultSections = [],
  originImg,
  onChangeValue,
  value,
  handleDelete,
}: Properties) {
  const { painSections, setSections, resetSections, setMultipleSections } = usePainSectionsStore();
  const [painLevelPopupOpen, setPainLevelPopupOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentSection, setCurrentSection] = useState<ISectionProperty | null>(null);

  useEffect(() => {
    return resetSections;
  }, []);

  const handleClickSection = useCallback(
    (sectionId: string) => {
      setCurrentSection(get(painSections, `${sectionId}`));
      setPainLevelPopupOpen(true);
    },
    [painSections],
  );

  useEffect(() => {
    if (value) {
      setMultipleSections(value);
    }
  }, [value, setMultipleSections]);

  const handleClose = useCallback(() => {
    setPainLevelPopupOpen(false);
  }, [setPainLevelPopupOpen]);

  const handleChange = useCallback(
    (currentLevel: number) => {
      if (currentSection) {
        setSections({
          ...currentSection,
          painLevel: currentLevel,
        });
        onChangeValue?.({
          ...currentSection,
          painLevel: currentLevel,
        });
      }
    },
    [currentSection, setSections, onChangeValue],
  );

  const handleOnDelete = useCallback(() => {
    if (currentSection) {
      setSections({
        ...currentSection,
        painLevel: 1,
      });
      handleDelete?.(currentSection.id);
      setPainLevelPopupOpen(false);
    }
  }, [handleDelete, currentSection, setSections]);

  return (
    <Box sx={{ position: 'relative' }}>
      {loaded && (
        <svg
          width="367"
          height="781"
          viewBox="0 0 367 781"
          fill="none"
          style={{ position: 'absolute', minHeight: 500, zIndex: 1200 }}
        >
          <g clipPath="url(#clip0_15567_67074)">
            <rect x="0.20166" y="1.33105" width="366.798" height="779.446" fill="url(#pattern0)" />
            {defaultSections.map((section) => (
              <path
                key={section.id}
                d={section.path}
                fill="#6C5DD3"
                fillOpacity={0.1 * Number(get(painSections, `${section.id}.painLevel`) || 1)}
                stroke="#6C5DD3"
                strokeWidth="1.49837"
                onClick={() => handleClickSection(section.id)}
              />
            ))}
          </g>
          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use
                href="#image0_15567_67074"
                transform="matrix(0.00210606 0 0 0.00099323 -0.763636 -0.105478)"
              />
            </pattern>
            <clipPath id="clip0_15567_67074">
              <rect
                width="366.798"
                height="780.748"
                fill="white"
                transform="translate(0.20166 0.0292969)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
      <Image src={originImg} alt="origin-img" afterLoad={() => setLoaded(true)} />
      <PainLevelPopup
        open={painLevelPopupOpen}
        painLevel={Number(get(painSections, `${currentSection?.id}.painLevel`) || 1)}
        sectionName={currentSection?.i18nKey || currentSection?.name}
        onChange={handleChange}
        onClose={handleClose}
        isAdded={value?.some((v) => v.id === currentSection?.id)}
        onDelete={handleOnDelete}
      />
    </Box>
  );
}
