import { ReactElement } from 'react';
import { Typography, Chip, Stack, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import StartIcon from '@mui/icons-material/Start';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';
import { IIntervention } from '../../types/intervention';
import { theme } from '../../theme';

export default function InterventionCardPage(
  properties: IIntervention,
): ReactElement<IIntervention> {
  const { t } = usePreferredTranslation();
  const { id, type, title, questionsCount, studies } = properties;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.12),
        },
      }}
    >
      <Stack spacing={1} sx={{ flex: 1, px: 3, py: 2 }}>
        <Stack direction="row" spacing={1}>
          <Chip sx={{ borderRadius: 2, height: 24 }} label={type} variant="outlined" />
        </Stack>

        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>

        <Typography color="GrayText" component="span" variant="body2">
          {questionsCount} questions{' '}
          <CircleIcon sx={{ fontSize: 8, color: theme.palette.grey[500], mx: 0.5 }} />
          associated with {studies?.length || 0} studies
        </Typography>
      </Stack>
      <Link
        component={RouterLink}
        to={`/interventions/${id}`}
        sx={{ textDecoration: 'none', color: 'inherit', p: 2 }}
      >
        <StartIcon sx={{ color: theme.palette.text.secondary }} />
      </Link>
    </Stack>
  );
}
