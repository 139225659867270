import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type Type = '' | 'normal' | 'full';

export interface ILayoutContext {
  readonly leftSidebar: boolean;
  readonly setLeftSidebar: (bool: boolean) => void;
  readonly rightSidebar: boolean;
  readonly setRightSidebar: (bool: boolean) => void;
  readonly header: boolean;
  readonly setHeader: (bool: boolean) => void;
  readonly footer: boolean;
  readonly setFooter: (bool: boolean) => void;
  readonly type: Type;
  readonly setType: (type: Type) => void;
}

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);

export function LayoutProvider({ children }: { children: ReactNode }) {
  const [leftSidebar, setLeftSidebar] = useState<boolean>(true);
  const [rightSidebar, setRightSidebar] = useState<boolean>(false);
  const [header, setHeader] = useState<boolean>(true);
  const [footer, setFooter] = useState<boolean>(false);
  const [type, setType] = useState<Type>('');

  const contextValue = useMemo(
    (): ILayoutContext => ({
      leftSidebar,
      setLeftSidebar,
      rightSidebar,
      setRightSidebar,
      header,
      setHeader,
      footer,
      setFooter,
      type,
      setType,
    }),
    [
      leftSidebar,
      setLeftSidebar,
      rightSidebar,
      setRightSidebar,
      header,
      setHeader,
      footer,
      setFooter,
      type,
      setType,
    ],
  );

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export const useLayout = () => useContext(LayoutContext);
