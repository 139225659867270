import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { useMemo } from 'react';
import _ from 'lodash';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Stack, Typography } from '@mui/material';
import { dayjs } from '../../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { ChartsProperties } from './types';
import { convertToMiles } from '../../../../../utils';

const formatTicks = (value: number) => {
  return value === 0 ? '0' : `${value}mi`;
};

const CustomTooltip = (properties: TooltipProps<ValueType, NameType>) => {
  const { t } = usePreferredTranslation();
  const { payload } = properties;

  const data = payload?.[0];

  if (!data) return null;
  const duration = data.payload[data.dataKey as string];
  const label = data.payload.date;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        {t('Distance')} ({label})
      </Typography>
      <Typography variant="body1">{`${duration}${t('miles')}`}</Typography>
    </Stack>
  );
};

export const DistanceChart: React.FC<ChartsProperties> = ({ data }) => {
  const chartData = useMemo(() => {
    const distanceData = data?.fitbitChartsData.distance;
    const filteredData = _.filter(distanceData, (item) => Number(item.value) > 0);

    return filteredData?.map((item) => ({
      date: dayjs.utc(item.date).format('MM/DD'),
      value: convertToMiles(Number(item.value), 'Kilometer').toFixed(2),
    }));
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Tooltip shared={false} cursor={{ fill: 'none' }} content={CustomTooltip} />
        <XAxis dataKey="date" />
        <YAxis
          label={{ value: 'Distance', angle: -90, position: 'insideLeft' }}
          tickFormatter={formatTicks}
        />
        <Bar dataKey="value" fill="#039BE5" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};
