import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';

import { CircularProgress, IconButton, Input, InputAdornment } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/system';
import {
  ChartTabOutputType,
  useCreateChartTabMutation,
  useDeleteChartTabMutation,
  useUpdateChartTabMutation,
} from '../../../../graphql';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

const StyledInput = styled(Input)`
  margin: 10px 0px;
  padding: 5px 0px;
`;

interface Properties {
  userId: number;
  element?: ChartTabOutputType;
  isAdding?: boolean;
  loading?: boolean;
  onCloseAdd?: () => void;
  onRefetchTabs?: () => void;
  onSave?: (value: string) => void;
  onDelete?: () => void;
}

const TabInput = ({ element, isAdding, loading, onCloseAdd, onSave, onDelete }: Properties) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState('');
  const { t } = usePreferredTranslation();
  useEffect(() => {
    if (element?.title) {
      setInputText(element?.title);
    }
  }, [isEditing, element]);

  useEffect(() => {
    if (isAdding) {
      setIsEditing(true);
    }
  }, [isAdding]);

  const onSubmit = () => {
    setIsEditing(false);
    onSave?.(inputText);
    onCloseAdd?.();
  };

  if (!isEditing) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          marginBottom: 1.5,
          py: '5px',
        }}
      >
        <div>
          <strong style={{ marginBottom: 1 }}>{element?.title}</strong>
          <p style={{ margin: 0, fontSize: '14px' }}>
            {element?.chartCount || 0} {(element?.chartCount || 0) === 1 ? t('chart') : t('charts')}
          </p>
        </div>
        <div>
          <IconButton disabled={loading} aria-label="save" onClick={() => setIsEditing(true)}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={loading} aria-label="save" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </div>
      </Box>
    );
  }

  return (
    <StyledInput
      fullWidth
      placeholder={t('Text')}
      value={inputText}
      onChange={(event) => setInputText(event.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="save" disabled={loading || !inputText} onClick={() => onSubmit()}>
            <CheckIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            disabled={loading}
            onClick={() => {
              setIsEditing(false);
              onCloseAdd?.();
            }}
          >
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default TabInput;
