import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';

export function UserRowPlaceholder(): ReactElement {
  const rows = [1, 2, 3, 4, 5];

  return (
    <>
      {rows.map((key) => {
        const emailWidth = 300;
        const studyWidth = 150;
        const roleWidth = 200;
        const statusWidth = 275;

        return (
          <TableRow key={key}>
            <TableCell />
            <TableCell>
              <ContentLoader
                speed={2}
                width={20}
                height={20}
                viewBox="0 0 20 20"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={emailWidth}
                height={15}
                viewBox={`0 0 ${emailWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={emailWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={studyWidth}
                height={15}
                viewBox={`0 0 ${studyWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={studyWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={roleWidth}
                height={15}
                viewBox={`0 0 ${roleWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={roleWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell colSpan={2}>
              <ContentLoader
                speed={2}
                width={statusWidth}
                height={15}
                viewBox={`0 0 ${statusWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={statusWidth} height="15" />
              </ContentLoader>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
