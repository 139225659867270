import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { getToken, logger } from '../../../utils';

export function useFitbitErrorResponse() {
  const [error, setError] = useState<Error | null>(null);
  const [fitbitRefreshTokenError, setFitbitRefreshTokenError] = useState(false);

  const handleRefreshToken = useCallback((user: IFitbitUserInput) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/fitbit/auth/refresh`,
      params: {
        userId: Number(user.id),
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }).catch((error) => {
      setFitbitRefreshTokenError(true);
      logger.log(`axios error response: ${error.message}`);
    });
  }, []);

  const handleError = useCallback(
    (user: IFitbitUserInput, responseError: any) => {
      const {
        original: {
          response: { status, data },
        },
      } = responseError;

      switch (status) {
        case 401: {
          setError(
            new Error(
              'Could not connect to your Fitbit account. Did you grant the Wellness app the permission?',
            ),
          );

          if (data) {
            const { errors = [] } = data;

            const tokenExpired: boolean = errors.some(
              ({ errorType }: IFitbitApiError) => errorType === 'expired_token',
            );

            if (tokenExpired) {
              handleRefreshToken(user);
              setError(null);
            }
          }

          break;
        }
        case 404: {
          handleRefreshToken(user);
          setError(new Error('No Fitbit resources/data found'));
          break;
        }
        default: {
          setError(responseError);
        }
      }

      logger.log('Fitbit returned an error', responseError);
    },
    [handleRefreshToken],
  );

  return useMemo(
    () => ({
      error,
      setError,
      handleError,
      fitbitRefreshTokenError,
    }),
    [error, setError, handleError, fitbitRefreshTokenError],
  );
}
