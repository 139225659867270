import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';

export interface IParticipantSchedule {
  readonly id: string;
  description: string;
  date: string;
  time: string;
  status: string;
  category: string;
}

export type IParticipantSchedules = Array<IParticipantSchedule>;

export const ParticipantScheduleContext = createContext<IParticipantSchedules>([]);

export const ParticipantScheduleProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USER_SCHEDULES });
  const contextValue = useMemo((): IParticipantSchedules => data || [], [data]);

  return (
    <ParticipantScheduleContext.Provider value={contextValue}>
      {children}
    </ParticipantScheduleContext.Provider>
  );
};

export const useParticipantSchedule = () => useContext(ParticipantScheduleContext);
