import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import Question, { AnswerableAssessmentQuestionEntity } from '../../../callnotes/Question';
import { LANGUAGES } from '../../../../constants';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  questions: AnswerableAssessmentQuestionEntity[];
  language?: string;
  handleQuestionAnswered: (questionId: string, value: string[]) => void;
  handleCommentChange: (questionId: string, comment: string) => void;
}

export default function LibraryQuestions({
  questions,
  handleCommentChange,
  handleQuestionAnswered,
  language,
}: Properties) {
  const { t } = usePreferredTranslation();
  if (questions.length === 0) {
    return (
      <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
        {t('No questions found.')}
      </Typography>
    );
  }

  const sortedQuestions = _.sortBy(questions, ['order']);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {sortedQuestions.map((questionData, index) => (
        <Question
          key={questionData.id}
          handleCommentChange={handleCommentChange}
          handleQuestionAnswered={handleQuestionAnswered}
          questionSx={index === 0 ? { marginRight: 15 } : {}}
          questionData={questionData}
          answerNotePlaceholder={
            language === LANGUAGES.SPANISH.value ? 'Cualquier nota adicional va aquí.' : undefined
          }
        />
      ))}
    </Box>
  );
}
