import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { ENDPOINTS } from '../constants';
import { keyIndicators } from './report';
import { interventions } from './interventions';
import { users } from './users';
import { activities, signinSuccess, signupSuccess, stats } from './user';
import { researchActivity } from './researchActivities';
import { participantMedications } from './participantMedications';
import { participantSymptoms } from './participantSymptoms';
import { participantInternalNotes } from './participantNotes';
import { participantActivity } from './participantActivity';
import { participantSchedules } from './participantSchedules';
import { userMedia } from './userMedia';
import { roles } from './roles';
import { permissions } from './permissions';
import { studies } from './studies';
import { userSharedFiles } from './userSharedFiles';
import { userMessages, userMessage } from './userMessages';
import { notifications } from './notifications';

const mock = new MockAdapter(axios, { delayResponse: 2000 });

mock.onGet(ENDPOINTS.REPORTS_INDICATORS).reply(200, keyIndicators);
mock.onGet(ENDPOINTS.USER_STATS).reply(200, stats);
mock.onGet(ENDPOINTS.USERS).reply(200, users);
mock.onGet(ENDPOINTS.USER_ACTIVITIES).reply(200, activities);
mock.onGet(ENDPOINTS.PARTICIPANT_ACTIVITIES).reply(200, participantActivity);
mock.onGet(ENDPOINTS.USER_SCHEDULES).reply(200, participantSchedules);
mock.onGet(ENDPOINTS.USER_MEDICATIONS).reply(200, participantMedications);
mock.onGet(ENDPOINTS.USER_SYMPTOMS).reply(200, participantSymptoms);
mock.onGet(ENDPOINTS.USER_INTERNAL_NOTES).reply(200, participantInternalNotes);
mock.onGet(ENDPOINTS.RESEARCH_ACTIVITIES).reply(200, researchActivity);
mock.onPost(ENDPOINTS.SIGN_IN).reply(200, signinSuccess);
mock.onPost(ENDPOINTS.SIGN_UP).reply(200, signupSuccess);
mock.onGet(ENDPOINTS.CURRENT_USER).reply(200, users[0]);
mock.onGet(ENDPOINTS.GENERATE_REPORTS).reply(200, keyIndicators);
mock.onGet(ENDPOINTS.INTERVENTIONS).reply(200, interventions);
mock.onGet(ENDPOINTS.USER_MEDIA).reply(200, userMedia);
mock.onGet(ENDPOINTS.ROLES).reply(200, roles);
mock.onGet(ENDPOINTS.PERMISSIONS).reply(200, permissions);
mock.onGet(ENDPOINTS.STUDIES).reply(200, studies);
mock.onGet(new RegExp(`${process.env.REACT_APP_FITBIT_API_HOST}.*`, 'gi')).passThrough();
mock.onPost(new RegExp(`${process.env.REACT_APP_FITBIT_API_HOST}.*`, 'gi')).passThrough();
mock
  .onPost(new RegExp(`${process.env.REACT_APP_API_URL}/fitbit/disconnect/.+`, 'gi'))
  .passThrough();
mock.onGet(`${process.env.REACT_APP_API_URL}/fitbit/auth`).passThrough();
mock.onGet(`${process.env.REACT_APP_API_URL}/fitbit/auth/refresh`).passThrough();
mock.onGet(ENDPOINTS.USER_SHARED_FILES).reply(200, userSharedFiles);
mock.onGet(ENDPOINTS.USER_MESSAGES).reply(200, userMessages);
mock.onPost(ENDPOINTS.USER_MESSAGES).reply(200, userMessage);
mock.onGet(ENDPOINTS.NOTIFICATIONS).reply(200, notifications);
