export const keyIndicators = [
  {
    id: '507f1f77bcf86cd799439011',
    label: 'APP COMPLIANCE',
    metric: '76%',
    change: '+4%',
    date: 'since prev. month',
  },
  {
    id: '507f191e810c19729de860ea',
    label: 'CALLS COMPLETED',
    metric: '384',
    change: '+21%',
    date: 'since prev. month',
  },
  {
    id: '00000020f51bb4362eee2a4d',
    label: 'CALLS COMPLETED',
    metric: '256',
    change: '-8%',
    date: 'since prev. month',
  },
  {
    id: '407f1f77bcf86cd799439011',
    label: 'Participants enrolled',
    metric: '37',
    change: '+5%',
    date: 'since prev. month',
  },
];
