import { createContext, ReactNode, useContext, useMemo } from 'react';
import { MediaCardProperties } from '../../components/atoms/MediaCard/types';
import { ENDPOINTS } from '../../constants';
import { useAxios } from '../../hooks';
import { IResponseBase } from '../../types/axios';

interface IResponse extends IResponseBase {
  data: Array<MediaCardProperties> | null;
}

const defaultResponse: IResponse = {
  data: [],
  loading: true,
};

export const MediaContext = createContext<IResponse>(defaultResponse);

export const MediaProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useAxios({ url: ENDPOINTS.USER_MEDIA });

  const contextValue = useMemo((): IResponse => {
    return { data, loading };
  }, [data, loading]);

  return <MediaContext.Provider value={contextValue}>{children}</MediaContext.Provider>;
};

export const useMedia = () => useContext(MediaContext);
