import styled from 'styled-components';

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const DropDown = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  border: 1.5px solid #ccc;
  border-radius: 3px;
  padding: 15px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > :first-child {
    /* Assuming the first child is the text */
    flex: 85;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  & > :last-child {
    /* Assuming the last child is the icon */
    flex: 15;
  }

  &:hover {
    border-color: #000;
  }
`;

export const OptionsContainer = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 10;
  border-radius: 4;
`;

export const Options = styled.div`
  width: 100%;
`;

export const OptionLabel = styled.button<{ isSelected: boolean; pad: number }>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-left: ${(properties) => `${properties.pad}px`};
  margin: 0;
  background-color: ${(properties) => (properties.isSelected ? '#f1f1f1e2' : '#ffffff')};
  text-align: start;

  &:hover {
    background-color: #f1f1f1e2;
    cursor: pointer;
  }
`;
