import { FormControl, Select, Stack } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@mui/icons-material';

import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { theme } from '../../../theme';

interface Properties {
  value?: number;
  onChange?: (event: any) => void;
}

export default function FeelingInput({ value, onChange }: Properties): ReactElement {
  const handleChange = useCallback(
    (event: any) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  return (
    <FormControl fullWidth>
      <Select
        value={value}
        sx={{ backgroundColor: theme.palette.common.white }}
        onChange={handleChange}
      >
        <MenuItem value={5}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>5</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={4}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>4</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={3}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>3</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={2}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>2</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={1}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>1</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={0}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>0</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={-1}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>-1</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={-2}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>-2</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={-3}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>-3</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={-4}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>-4</Box>
          </Stack>
        </MenuItem>
        <MenuItem value={-5}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box>-5</Box>
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
