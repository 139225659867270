const TOKEN_KEY = 'auth-token';

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const storeToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const destroyToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
