import { useEffect, useState } from 'react';
import { Typography, Box, BoxProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { dayjs } from '../../../../utils/dayjs';

import { IParticle } from '../../../../types/particles';
import { VoiceEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import { FORMATS } from '../../../../constants';
import { Particles } from '../../particles';
import { Waveform } from '../../../atoms';

interface Properties extends BoxProps {
  owner?: boolean;
  voice: VoiceEntity;
  selected?: boolean;
}

export const Voice = ({ owner, voice, selected = false, ...rest }: Properties) => {
  const [viewed, setViewed] = useState(voice.viewed);

  const particles: IParticle[] = JSON.parse(voice.data || '[]') || [];

  useEffect(() => {
    if (selected || voice.viewed) {
      setViewed(true);
    }
  }, [selected, voice, setViewed]);

  return (
    <Box
      sx={{
        py: 1.5,
        px: 1.5,
        alignItems: 'center',
        minWidth: 0,
        maxWidth: '80%',
        borderRadius: owner ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
        color: owner ? theme.palette.common.white : theme.palette.common.black,
        alignSelf: owner ? 'flex-end' : 'flex-start',
        flexDirection: 'column',
        background: owner ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12),
      }}
      {...rest}
    >
      <Box sx={{ pl: 1, flexGrow: 1, minWidth: 0 }}>
        <Box fontWeight="normal" sx={{ display: 'flex', alignItems: 'center', pr: 3 }}>
          {voice.audioUrl ? (
            <Waveform
              url={voice.audioUrl}
              contentColor={owner ? theme.palette.common.white : undefined}
            />
          ) : (
            voice.title
          )}
        </Box>
        <Typography
          fontSize={10}
          fontWeight="normal"
          color={alpha(owner ? theme.palette.common.white : theme.palette.common.black, 0.6)}
          sx={{ mb: 1 }}
        >
          {dayjs(voice.createdAt).format(FORMATS.DATE)} ({dayjs().to(dayjs(voice.createdAt))})
        </Typography>
      </Box>

      {particles.length > 0 && (
        <Particles maxWidth="200px" maxHeight="200px" particles={particles} />
      )}

      {selected && (
        <ArrowForwardIosRoundedIcon sx={{ color: theme.palette.text.disabled, fontSize: 14 }} />
      )}
    </Box>
  );
};
