import { UserEntity } from '../../graphql';
import { UserRoles } from '../../constants/users';

const STUDY_ID_KEY = 'study-id';
const STUDY_ARM_ID_KEY = 'study-arm-id';
const USER_ID_KEY = 'user-id';

export const getStudyId = (): number | null => {
  const value = localStorage.getItem(STUDY_ID_KEY);

  if (!value) {
    return null;
  }

  return Number(value);
};

export const storeStudyId = (studyId: number) => {
  if (studyId) {
    localStorage.setItem(STUDY_ID_KEY, String(studyId));
  }
};

export const getStudyArmId = (): number | null => {
  const value = localStorage.getItem(STUDY_ARM_ID_KEY);

  if (!value) {
    return null;
  }

  return Number(value);
};

export const storeStudyArmId = (studyArmId: number) => {
  if (studyArmId) {
    localStorage.setItem(STUDY_ARM_ID_KEY, String(studyArmId));
  }
};

export const removeStudySelected = () => {
  localStorage.removeItem(STUDY_ID_KEY);
};

export const removeStudyArmSelected = () => {
  localStorage.removeItem(STUDY_ARM_ID_KEY);
};

export const storeUserId = (userId: number) => {
  if (userId) {
    localStorage.setItem(USER_ID_KEY, String(userId));
  }
};

export const getUserId = () => {
  const value = localStorage.getItem(USER_ID_KEY);

  if (!value) {
    return null;
  }

  return Number(value);
};

export const removeUserId = () => {
  localStorage.removeItem(USER_ID_KEY);
};

export const checkRole = (user?: UserEntity, studyId?: number, role?: UserRoles) =>
  user &&
  studyId &&
  role &&
  !!user.roles?.find(
    (r) => r.role.name.toLowerCase() === role.toLowerCase() && r.study.id === studyId,
  );
