import { Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import {
  BedIcon,
  DateRangePickerField,
  DistanceIcon,
  FairlyActiveIcon,
  FootIcon,
  LightlyActiveIcon,
  RunningIcon,
} from '../../../atoms';
import { containerStyles } from './styles';
import { useFitbitActivityOverview } from './useFitbitActivityOverview';

interface ItemProperties {
  label: string;
  value: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

interface Properties {
  user: IUser;
  dateRange: { fromDate: Date; toDate: Date };
  setDateRange: (range: { fromDate: Date; toDate: Date }) => void;
}

const Row: React.FC<PropsWithChildren & { hideBorder?: boolean }> = ({
  children,
  hideBorder = false,
}) => (
  <Grid container sx={hideBorder ? {} : { borderBottom: '1px solid #ccc', pb: 1.5 }}>
    {children}
  </Grid>
);

const Item: React.FC<ItemProperties> = ({ Icon, label, value }) => {
  const { t } = usePreferredTranslation();
  return (
    <Grid item xs={6}>
      <Stack gap={1}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {label}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1.2}>
          <Icon />
          <Typography variant="body2">{value}</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export const ActivityOverview: React.FC<Properties> = ({ user, dateRange, setDateRange }) => {
  const hasRenderedOnce = useRef(false);
  const { t } = usePreferredTranslation();

  const [dateValue, setDateValue] = useState(dateRange);

  const handleDateChange = useCallback(
    (dates: any[]) => {
      setDateValue({
        fromDate: dates[0],
        toDate: dates[1],
      });
      setDateRange({ fromDate: dates[0], toDate: dates[1] });
    },
    [setDateRange],
  );

  const { loading, overviewData } = useFitbitActivityOverview(
    user,
    dateValue.fromDate,
    dateValue.toDate,
    t,
  );

  if (loading && !hasRenderedOnce.current) {
    hasRenderedOnce.current = true;
    return <Skeleton variant="rectangular" height={300} sx={{ mt: 3 }} />;
  }

  return (
    <Paper sx={containerStyles}>
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Typography variant="h6">{t('Activity Totals')}</Typography>
        <DateRangePickerField
          sx={{
            padding: '6px 12px',
            backgroundColor: '#eeeeee',
            border: 'none',
          }}
          onChange={handleDateChange}
          value={[dateValue.fromDate, dateValue.toDate]}
          showIcon
        />
      </Stack>
      <Stack gap={1.5} sx={{ mt: 2 }}>
        <Row>
          <Item label={t('Distance')} value={overviewData.distance} Icon={DistanceIcon} />
          <Item label={t('Steps')} value={overviewData.steps} Icon={FootIcon} />
        </Row>
        <Row>
          <Item
            label={t('Lightly Active (min)')}
            value={overviewData.lightlyActiveMinutes}
            Icon={LightlyActiveIcon}
          />
          <Item
            label={t('Fairly Active (min)')}
            value={overviewData.fairlyActiveMinutes}
            Icon={FairlyActiveIcon}
          />
        </Row>
        <Row hideBorder>
          <Item label={t('Sedentary (min)')} value={overviewData.sedentaryMinutes} Icon={BedIcon} />
          <Item
            label={t('Very Active (min)')}
            value={overviewData.veryActiveMinutes}
            Icon={RunningIcon}
          />
        </Row>
      </Stack>
    </Paper>
  );
};
