import { useEffect, useRef } from 'react';

export const useAnimationFrame = (callback: () => void) => {
  const frame = useRef(0);

  const animate = () => {
    callback();

    frame.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    frame.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(frame.current);
  }, []);
};
