import { RRule, datetime, Frequency } from 'rrule';
import { dayjs } from '../dayjs';
import { AssessmentQuestionInputType, LibraryEntity } from '../../graphql';
import { formatTimezone } from '../calendar';

export const JOURNAL_ENABLED = true;

interface ValidJournalDates {
  date: dayjs.Dayjs;
  isEditable: boolean;
}

/**
 * Retrieves an array of valid journal dates based on the repeat text and user startDate.
 *
 * @param journal - The library entity.
 * @param startDate - The start date of user.
 * @param timeZone - The time zone of user.
 * @param hour - The hour (default: 0).
 * @param min - The minute (default: 0).
 * @returns An array of valid journal dates.
 */
export const getValidJournalDates = (
  journal: LibraryEntity | null,
  startDate: Date | undefined | null,
  timeZone: string | undefined | null,
  hour = 0,
  min = 0,
): ValidJournalDates[] => {
  if (!journal || !journal.libraryConfigs || !startDate) return [];
  const { libraryConfigs } = journal;
  const journalConfig = libraryConfigs[0];
  const duration = journalConfig?.duration || 1;
  const repeat = journalConfig?.repeat || 1;
  const repeatText = journalConfig?.repeatText || `every day for ${duration * repeat} times`;

  const isDailyJournal = RRule.fromText(repeatText).options.freq !== Frequency.WEEKLY;

  const offset = journalConfig?.offset || 0;

  const formatedTimezone = formatTimezone(timeZone);

  const userStartDate = dayjs(startDate).tz(formatedTimezone).startOf('day').toDate();

  userStartDate.setDate(userStartDate.getDate() + offset);

  const rule = RRule.fromText(repeatText);
  rule.options.dtstart = datetime(
    userStartDate.getFullYear(),
    userStartDate.getMonth() + 1,
    userStartDate.getDate(),
  );

  const validDates = [];

  const currentWeekEnd = dayjs().endOf('week');

  for (const d of rule.all() || []) {
    const scheduledDateInUTC = dayjs(d).utc().tz(undefined, true);
    const timezone = timeZone ? formatTimezone(timeZone) : dayjs.tz.guess();
    let dateInParticipantTimezone = dayjs().tz(timezone);
    dateInParticipantTimezone = dateInParticipantTimezone.set(
      'date',
      scheduledDateInUTC.get('date'),
    );
    dateInParticipantTimezone = dateInParticipantTimezone.set(
      'month',
      scheduledDateInUTC.get('month'),
    );
    dateInParticipantTimezone = dateInParticipantTimezone.set(
      'year',
      scheduledDateInUTC.get('year'),
    );
    dateInParticipantTimezone = dateInParticipantTimezone.set('hours', Number(hour));
    dateInParticipantTimezone = dateInParticipantTimezone.set('minutes', Number(min));
    dateInParticipantTimezone = dateInParticipantTimezone.set('seconds', 0);

    if (dateInParticipantTimezone.isBefore(dayjs())) {
      validDates.push({
        date: dateInParticipantTimezone,
        isEditable: true,
      });
    } else if (dateInParticipantTimezone.isAfter(currentWeekEnd)) {
      validDates.push({
        date: dateInParticipantTimezone,
        isEditable: false,
      });
    } else {
      validDates.push({
        date: dateInParticipantTimezone,
        isEditable: !isDailyJournal,
      });
    }
  }

  return validDates;
};

export const findQuestion = (
  questionId: string,
  questions: AssessmentQuestionInputType[],
): AssessmentQuestionInputType | null | undefined => {
  let result;
  for (const question of questions) {
    if (question.id === questionId) {
      result = question;
    }
    if (question.type === 'question') {
      result = findQuestion(questionId, question.questions ?? []);
    }
  }

  return result;
};

export const findAnswers = (
  questionAnswersMap: Map<any, any>,
  questions: AssessmentQuestionInputType[],
) => {
  const answers = [];
  for (const [key, value] of questionAnswersMap.entries()) {
    const ques = findQuestion(key, questions);
    if (!ques) {
      continue;
    }

    let answerValue = value;

    if (ques.type === 'human-body') {
      answerValue = [value ? JSON.parse(value.join(',')) : []];
    }

    const answer = {
      questionId: key,
      values: [
        {
          questionId: key,
          values: answerValue,
        },
      ],
    };
    answers.push(answer);
  }

  return answers;
};

export const getJournalsDatesLength = (
  journals: LibraryEntity[],
  startDate: Date | undefined | null,
  timeZone: string | undefined | null,
) => {
  let totalCount = 0;

  for (const journal of journals) {
    const validDates = getValidJournalDates(journal, startDate, timeZone);
    const today = dayjs().endOf('day');

    const filteredDates = validDates.filter((date) => date.date.isBefore(today));
    totalCount += filteredDates.length;
  }

  return totalCount;
};
