import { ReactElement, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

import { IParticle } from '../../../../types/particles';
import { Particle } from '../Particle';

interface Properties extends BoxProps {
  readonly renderBefore?: () => ReactNode;
  readonly renderAfter?: () => ReactNode;
  readonly particles: IParticle[];
  readonly maxWidth?: string;
  readonly maxHeight?: string;
}

export function Particles({
  renderBefore,
  renderAfter,
  particles,
  maxWidth = '25%',
  maxHeight = '25%',
  ...rest
}: Properties): ReactElement<Properties> {
  return (
    <Box
      sx={{ display: 'flex', gap: 1, flexDirection: 'column', width: '100%' }}
      data-testid="particlesComponent"
      {...rest}
    >
      {renderBefore?.()}
      {particles?.map((particle: IParticle, index: number) => (
        <Particle
          sx={{ maxWidth, maxHeight }}
          key={`${JSON.stringify(particle)}-${index}`}
          particle={particle}
        />
      ))}
      {renderAfter?.()}
    </Box>
  );
}
