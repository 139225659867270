import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import RadarIcon from '@mui/icons-material/Radar';
import {
  useCreateUserSubscriptionMutation,
  useUnsubscribeUserMutation,
  useUpdateNotificationPreferenceMutation,
} from '../../graphql';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

export enum NotificationPreferenceTypes {
  'ALL' = 'all',
  'LIVE_NOTIFICATION' = 'live_notifications',
  'DAILY_DIGEST' = 'daily_digest',
  'UNSUBSCRIBE' = 'unsubscribe',
}
interface SubscribeButtonProperties {
  userId?: string;
  isSubscribed?: boolean;
  notificationPreferenceType?: string;
  refetch?: () => Promise<void>;
}

const SubscribeButton: React.FC<SubscribeButtonProperties> = ({
  userId,
  isSubscribed = false,
  notificationPreferenceType,
  refetch,
}) => {
  const { t } = usePreferredTranslation();
  const buttonReference = useRef<HTMLButtonElement>(null);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const [createUserSubscription, { loading }] = useCreateUserSubscriptionMutation();
  const [updateNotificationPreference, { loading: updatingPreference }] =
    useUpdateNotificationPreferenceMutation();
  const [unsubscribeUser, { loading: unsubscribingUser }] = useUnsubscribeUserMutation();

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (!isSubscribed && userId) {
      const { errors } = await createUserSubscription({
        variables: {
          createUserSubscriptionInput: {
            userId: Number(userId),
            notificationPreference: NotificationPreferenceTypes.ALL,
          },
        },
      });

      if (!errors && refetch) {
        toast.success(t('You have successfully subscribed the participant'));
        refetch();
      }
    }
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const updatePreference = async (updatedPreference: NotificationPreferenceTypes) => {
    if (isSubscribed && userId && notificationPreferenceType !== updatedPreference) {
      const { errors } = await updateNotificationPreference({
        variables: {
          userId: Number(userId),
          notificationPreference: updatedPreference,
        },
      });

      if (!errors && refetch) {
        toast.success(t('Notification preference updated'));
        refetch();
      }
    }
    handleClose();
  };

  const unsubscribeParticipant = async () => {
    if (isSubscribed && userId) {
      const { errors } = await unsubscribeUser({
        variables: {
          userId: Number(userId),
        },
      });

      if (!errors && refetch) {
        refetch();
      }
    }
    handleClose();
  };

  const getButtonStartIcon = () => {
    if (!isSubscribed) {
      return <StarIcon sx={{ color: '#FFB400' }} />;
    }

    if (!open) {
      if (notificationPreferenceType === NotificationPreferenceTypes.UNSUBSCRIBE) {
        return <NotificationsOffIcon sx={{ fontSize: 14, color: '#6C5DD3' }} />;
      }
      return <NotificationsIcon sx={{ fontSize: 16, color: '#6C5DD3' }} />;
    }

    switch (notificationPreferenceType) {
      case NotificationPreferenceTypes.LIVE_NOTIFICATION: {
        return <RadarIcon sx={{ fontSize: 16, color: '#6C5DD3' }} />;
      }
      case NotificationPreferenceTypes.DAILY_DIGEST: {
        return <EmailOutlinedIcon sx={{ fontSize: 14, color: '#6C5DD3' }} />;
      }
      case NotificationPreferenceTypes.UNSUBSCRIBE: {
        return <NotificationsOffIcon sx={{ fontSize: 14, color: '#6C5DD3' }} />;
      }
      default: {
        return <NotificationsIcon sx={{ fontSize: 16, color: '#6C5DD3' }} />;
      }
    }
  };

  const getButtonText = () => {
    if (loading || updatingPreference || unsubscribingUser) {
      return <CircularProgress size={16} />;
    }

    if (isSubscribed && notificationPreferenceType === NotificationPreferenceTypes.UNSUBSCRIBE) {
      return 'Unsubscribed';
    }
    return isSubscribed ? t('Subscribed') : t('Subscribe');
  };

  const getButtonEndIcon = () => {
    if (!isSubscribed) {
      return null;
    }
    return open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };

  return (
    <div>
      <Button
        ref={buttonReference}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 500,
          padding: '0px 8px',
          borderRadius: '8px',
        }}
        startIcon={getButtonStartIcon()}
        endIcon={getButtonEndIcon()}
        fullWidth
      >
        {getButtonText()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={isSubscribed && open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            overflow: 'visible',
            borderRadius: 3,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
      >
        <MenuItem
          selected={notificationPreferenceType === NotificationPreferenceTypes.ALL}
          onClick={() => updatePreference(NotificationPreferenceTypes.ALL)}
        >
          <NotificationsIcon
            sx={{
              mr: 2,
              fontSize: 20,
              color:
                notificationPreferenceType === NotificationPreferenceTypes.ALL
                  ? '#6C5DD3'
                  : '#bdbdbd',
            }}
          />
          All
        </MenuItem>
        <MenuItem
          selected={notificationPreferenceType === NotificationPreferenceTypes.LIVE_NOTIFICATION}
          onClick={() => updatePreference(NotificationPreferenceTypes.LIVE_NOTIFICATION)}
        >
          <RadarIcon
            sx={{
              mr: 2,
              fontSize: 20,
              color:
                notificationPreferenceType === NotificationPreferenceTypes.LIVE_NOTIFICATION
                  ? '#6C5DD3'
                  : '#bdbdbd',
            }}
          />
          {t('Live Notifications')}
        </MenuItem>
        <MenuItem
          selected={notificationPreferenceType === NotificationPreferenceTypes.DAILY_DIGEST}
          onClick={() => updatePreference(NotificationPreferenceTypes.DAILY_DIGEST)}
        >
          <EmailOutlinedIcon
            sx={{
              mr: 2,
              fontSize: 20,
              color:
                notificationPreferenceType === NotificationPreferenceTypes.DAILY_DIGEST
                  ? '#6C5DD3'
                  : '#bdbdbd',
            }}
          />
          {t('Daily Digest')}
        </MenuItem>
        <MenuItem
          selected={notificationPreferenceType === NotificationPreferenceTypes.UNSUBSCRIBE}
          onClick={unsubscribeParticipant}
        >
          <PersonRemoveIcon
            sx={{
              mr: 2,
              fontSize: 20,
              color:
                notificationPreferenceType === NotificationPreferenceTypes.UNSUBSCRIBE
                  ? '#6C5DD3'
                  : '#bdbdbd',
            }}
          />
          {t('Unsubscribe')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SubscribeButton;
