import { SVGProps } from 'react';

const WeightsSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g clipPath="url(#a)">
      <path
        fill="#6C5DD3"
        d="M14.62 18.513v3.514h10.942v-3.514H14.619Zm11.622 6.347v-9.18a.68.68 0 0 1 .68-.68h2.142a2.448 2.448 0 0 1 2.448 2.447v5.646a2.447 2.447 0 0 1-2.448 2.447h-2.142a.68.68 0 0 1-.68-.68Zm-12.98.68h-2.145a2.447 2.447 0 0 1-2.448-2.447v-5.646A2.447 2.447 0 0 1 11.117 15h2.142a.68.68 0 0 1 .68.68v9.18a.68.68 0 0 1-.68.68h.003Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M31.512 15H8.669v10.54h22.843z" />
      </clipPath>
    </defs>
  </svg>
);
export default WeightsSvg;
