import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import FeelingInput from './FeelingInput';
import QuestionNavigation from './QuestionNavigation';
import { TimeField } from '../../atoms';
import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { getDateTimeStringBasedOnTimezone } from '../../../utils';
import { useCurrentUser } from '../../../states';

const FeelingContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  overflow: 'hidden',
  width: '100%',
  flex: 1,
  gap: theme.typography.pxToRem(16),
}));

interface Properties {
  value?: ICardioInput;
  onChange?: (value: ICardioInput) => void;
  onPrev?: () => void;
  onSubmit?: (value: ICardioInput) => void;
}

const defaultState = {
  date: new Date().toDateString(),
  duration: 0,
  effort: '',
  feelingStart: 0,
  feelingEnd: 0,
};
const MINUTE = 'journal.select.minute';
const MINUTES = 'journal.select.minutes';

export default function CardioInputIntake({
  onPrev,
  onSubmit,
  onChange,
  value,
}: Properties): ReactElement {
  const [answers, setAnswers] = useState<ICardioInput>(value ?? defaultState);
  const { data: currentUserData } = useCurrentUser();

  const { t } = usePreferredTranslation();

  const handleChange = useCallback(
    (value_: ICardioInput) => {
      const newAnswers = { ...answers, ...value_ };

      if (onChange) {
        onChange(newAnswers);
      }

      setAnswers(newAnswers);
    },
    [answers, onChange],
  );

  const handleClear = useCallback(() => {
    setAnswers(defaultState);
  }, [setAnswers]);

  const handleSubmit = useCallback(() => {
    if (onSubmit && answers) {
      onSubmit(answers);
    }
  }, [answers, onSubmit]);

  const onTimeQuestionAnswered = useCallback(
    (newValue: any) => {
      const time = getDateTimeStringBasedOnTimezone(
        newValue?.date,
        currentUserData?.user?.timezone,
      );
      handleChange({ date: time });
    },
    [handleChange, currentUserData?.user?.timezone],
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Accordion
          sx={{
            borderRadius: '8px',
            backgroundColor: theme.palette.grey[100],
            my: 2,
          }}
        >
          <AccordionDetails>
            <Stack spacing={2} sx={{ mb: 1 }}>
              <FormControl fullWidth>
                <TimeField
                  placeholder={t('journal.select.time') || ''}
                  sx={{ backgroundColor: theme.palette.common.white }}
                  onChange={(date: any) => onTimeQuestionAnswered({ date })}
                  value={answers?.date || null}
                />
              </FormControl>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={answers?.duration || 0}
                    sx={{ backgroundColor: theme.palette.common.white }}
                    onChange={(event: any) => handleChange({ duration: event.target.value })}
                  >
                    <MenuItem value={0} disabled>
                      {t('journal.select.duration')}
                    </MenuItem>
                    <MenuItem value={1}>{`1 ${t(MINUTE)}`}</MenuItem>
                    <MenuItem value={2}>{`2 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={5}>{`5 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={10}>{`10 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={15}>{`15 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={20}>{`20 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={25}>{`25 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={30}>{`30 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={35}>{`35 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={40}>{`40 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={45}>{`45 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={50}>{`50 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={55}>{`55 ${t(MINUTES)}`}</MenuItem>
                    <MenuItem value={60}>{`1 ${t('journal.select.hour')}`}</MenuItem>
                    <MenuItem value={60 * 2}>{`2 ${t('journal.select.hours')}`}</MenuItem>
                    <MenuItem value={60 * 3}>{`3 ${t('journal.select.hours')}`}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={answers?.effort || ''}
                    sx={{ backgroundColor: theme.palette.common.white }}
                    onChange={(event: any) => handleChange({ effort: event.target.value })}
                  >
                    <MenuItem value="" disabled>
                      {t('journal.select.effort')}
                    </MenuItem>
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <FeelingContainer>
                <Box flexGrow={1}>
                  <Typography
                    fontSize={theme.typography.pxToRem(14)}
                    fontWeight={500}
                    sx={{ mb: 0.5 }}
                  >
                    {t('journal.feeling.start')}
                  </Typography>
                  <FeelingInput
                    value={answers?.feelingStart}
                    onChange={(event: any) => handleChange({ feelingStart: event.target.value })}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography
                    fontSize={theme.typography.pxToRem(14)}
                    fontWeight={500}
                    sx={{ mb: 0.5 }}
                  >
                    {t('journal.feeling.end')}
                  </Typography>
                  <FeelingInput
                    value={answers?.feelingEnd}
                    onChange={(event: any) => handleChange({ feelingEnd: event.target.value })}
                  />
                </Box>
              </FeelingContainer>
            </Stack>
            <Button color="primary" variant="text" onClick={handleClear}>
              {t('clear')}
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>
      <QuestionNavigation submit onPrev={onPrev} onSubmit={handleSubmit} />
    </>
  );
}
