import { ReactElement, useCallback, useEffect, useState, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography, BoxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { isTest } from '../../../utils';

interface Properties extends BoxProps {
  url: string;
  contentColor?: string;
}

export function Waveform({ url, contentColor, ...rest }: Properties): ReactElement {
  const container = useRef<typeof Box | null>(null);
  const wrapper = useRef<typeof Box | null>(null);
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState();
  let wavesurfer: any;

  const onTogglePlay = useCallback(() => {
    if (!wavesurfer) {
      return;
    }

    if (wavesurfer.isPlaying()) {
      wavesurfer.stop();
    } else {
      wavesurfer.play();
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (!isTest && !wavesurfer && wrapper.current) {
      wavesurfer = WaveSurfer.create({
        container: wrapper.current as unknown as HTMLElement,
        backend: 'MediaElement',
        waveColor: theme.palette.primary.main,
        responsive: true,
        hideScrollbar: true,
        barWidth: 2,
        barGap: 3,
        height: 60,
      });

      wavesurfer.on('play', () => setIsPlaying(true));
      wavesurfer.on('pause', () => setIsPlaying(false));
      wavesurfer.on('ready', () => setDuration(wavesurfer.getDuration()));

      wavesurfer.load(url);
    }
  }, [theme, container, url]);

  return (
    <Box ref={container} data-testid="waveformComponent" {...rest}>
      <Stack direction="row" alignItems="center">
        {isPlaying ? (
          <StopCircleIcon sx={{ fontSize: '3rem' }} onClick={onTogglePlay} />
        ) : (
          <PlayCircleIcon sx={{ fontSize: '3rem' }} onClick={onTogglePlay} />
        )}
        <Box ref={wrapper} flexGrow={1} maxWidth={150} />
        <Typography
          sx={{ ml: 1, fontSize: theme.typography.fontSize }}
          color={contentColor || 'text.secondary'}
        >
          {duration && Math.floor(duration / 60)}:{duration && Math.floor(duration % 60)}
        </Typography>
        {/* <Button
          variant="outlined"
          sx={{
            ml: 2,
            color: contentColor || 'text.secondary',
            borderColor: contentColor || 'text.secondary',
          }}
        >
          Score sample
        </Button> */}
      </Stack>
    </Box>
  );
}
