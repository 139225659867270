import { SVGProps } from 'react';

const Svg404 = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={271} height={271} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M0 229.784v29.923a11.29 11.29 0 0 0 11.292 11.291 11.29 11.29 0 0 0 11.291-11.291v-29.923a11.29 11.29 0 0 1 4.291-8.808c13.437-10.275 43.473-27.1 97.334-29.019a3.271 3.271 0 0 1 2.033.79 3.05 3.05 0 0 1 .903 2.033v64.927a8.47 8.47 0 0 0 16.938 0v-65.153a3.05 3.05 0 0 1 .903-2.033 3.273 3.273 0 0 1 1.807-.564c53.748 2.145 83.784 18.97 97.221 29.245a11.3 11.3 0 0 1 4.291 8.808v29.697a11.287 11.287 0 0 0 11.291 11.291 11.291 11.291 0 0 0 11.292-11.291v-29.923a33.876 33.876 0 0 0-12.985-26.536 177.627 177.627 0 0 0-85.704-31.729 2.71 2.71 0 0 1-2.823-2.146 2.815 2.815 0 0 1 .227-1.768c.264-.55.7-.999 1.241-1.28a79.038 79.038 0 0 0 43.699-70.799V22.582a11.29 11.29 0 0 0-4.517-9.034 91.574 91.574 0 0 0-25.971-12.533 11.29 11.29 0 0 0-14.114 7.34 11.281 11.281 0 0 0 .712 8.639 11.297 11.297 0 0 0 6.627 5.588 96.477 96.477 0 0 1 13.099 5.307 2.6 2.6 0 0 1 1.58 2.484v31.278a2.824 2.824 0 0 1-2.823 2.823h-8.468a8.472 8.472 0 0 0-8.469 8.469 8.469 8.469 0 0 0 8.469 8.468h8.468a2.825 2.825 0 0 1 2.823 2.823v11.292a55.767 55.767 0 0 1-7.001 26.987 3.497 3.497 0 0 1-1.806 1.355 3.16 3.16 0 0 1-2.258 0 81.08 81.08 0 0 0-90.334 0 3.162 3.162 0 0 1-2.258 0 3.502 3.502 0 0 1-1.807-1.355 55.78 55.78 0 0 1-7.452-26.987V84.234a2.823 2.823 0 0 1 2.823-2.823h8.468a8.469 8.469 0 0 0 0-16.937h-8.468a2.822 2.822 0 0 1-2.823-2.823v-31.39a2.71 2.71 0 0 1 1.58-2.485 96.437 96.437 0 0 1 13.099-5.194 11.293 11.293 0 0 0 7.904-14.228 11.302 11.302 0 0 0-5.759-6.831 11.291 11.291 0 0 0-8.92-.508 91.575 91.575 0 0 0-25.971 12.082 11.292 11.292 0 0 0-4.517 9.485v73.396a79.045 79.045 0 0 0 43.247 70.347 2.825 2.825 0 0 1 1.92 3.048 2.713 2.713 0 0 1-2.371 2.372 177.619 177.619 0 0 0-86.156 31.503A33.882 33.882 0 0 0 0 229.784zm171.859-92.027a3.042 3.042 0 0 1 1.242 2.145 3.163 3.163 0 0 1-1.129 2.371 59.276 59.276 0 0 1-72.944 0 3.158 3.158 0 0 1-1.13-2.371 3.045 3.045 0 0 1 1.243-2.145 66.842 66.842 0 0 1 72.718 0z"
      fill="currentColor"
    />
    <path
      d="M87.51 253.611a2.825 2.825 0 0 1 2.823-2.823h5.646a8.473 8.473 0 0 0 8.469-8.469 8.468 8.468 0 0 0-8.469-8.469h-5.646a2.82 2.82 0 0 1-2.823-2.823v-5.194a8.468 8.468 0 1 0-16.937 0v5.646a2.821 2.821 0 0 1-2.823 2.823h-5.646a8.47 8.47 0 1 0 0 16.937h5.646a2.825 2.825 0 0 1 2.823 2.823v5.646a8.468 8.468 0 1 0 16.938 0v-6.097zm79.042-25.407c6.236 0 11.292-5.056 11.292-11.292s-5.056-11.291-11.292-11.291-11.292 5.055-11.292 11.291c0 6.236 5.056 11.292 11.292 11.292zm11.292 22.583a11.293 11.293 0 0 0-22.584 0 11.32 11.32 0 0 0 1.059 4.663 11.293 11.293 0 0 0 21.525-4.663zM135.5 2.37a36.698 36.698 0 1 0 36.698 36.699A36.811 36.811 0 0 0 135.5 2.37zm0 25.407a11.292 11.292 0 1 1 0 22.584 11.292 11.292 0 0 1 0-22.584zm22.583 76.219a8.47 8.47 0 1 0 0-16.939 8.47 8.47 0 0 0 0 16.939zm-45.166 0a8.469 8.469 0 0 0 8.468-8.47 8.468 8.468 0 1 0-16.937 0 8.47 8.47 0 0 0 8.469 8.47z"
      fill="currentColor"
    />
  </svg>
);

export default Svg404;
