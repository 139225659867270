import { ReactElement, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { ModalContainer } from 'react-modal-global';
import Global from './components/modals/global';
import ContextProvider from './contexts';
import { usePreloader, useTimeout } from './hooks';
import { Layouts } from './layouts';
import { FallbackLoader } from './components';
import 'react-modal-global/styles/modal.scss';
import './styles/modal.scss';
import { PhoneCallProvider } from './states/vonage/usePhoneCall';
import { VideoCallProvider } from './states/vonage/useVideoCall';

export function App(): ReactElement {
  const { preloader } = usePreloader();

  useTimeout(() => preloader(false), 250);

  return (
    <Suspense fallback={<FallbackLoader />}>
      <ContextProvider>
        <VideoCallProvider>
          <PhoneCallProvider>
            <Layouts>
              <ModalContainer controller={Global} />
              <ToastContainer />
              <Outlet />
            </Layouts>
          </PhoneCallProvider>
        </VideoCallProvider>
      </ContextProvider>
    </Suspense>
  );
}
