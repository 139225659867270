import { useTranslation, Trans as ReactTrans, TransProps } from 'react-i18next';
import { TOptions } from 'i18next';
import { DefaultNamespace } from 'react-i18next/TransWithoutContext';
import { useEffect, useMemo } from 'react';
import { dayjs } from '../../utils/dayjs';
import { useCurrentUser } from '../../states';
import { getUserPreferredLanguage } from '../../utils/i18n/languages';

/**
 * Custom hook that provides a preferred translation function and translation context.
 * It combines the `t` function from the `useTranslation` hook with the user's preferred language from the `useCurrentUser` hook.
 *
 * @returns An object containing the `t` function, `i18n` object, and `Trans` component.
 */
export const usePreferredTranslation = () => {
  const { t: i18nt, i18n } = useTranslation();
  const { data } = useCurrentUser();
  const lang = getUserPreferredLanguage(data?.user);

  useEffect(() => {
    const dayjsLang = dayjs.locale();
    if (dayjsLang !== lang) dayjs.locale(lang);
  }, [lang]);

  const changeLanguage = (language: string) => {
    localStorage.setItem('language', language);
    dayjs.locale(language);
    i18n.changeLanguage(language);
  };

  const t = (key: string, options?: TOptions) => {
    return i18nt(key, {
      ...(options || []),
      lng: lang,
    });
  };

  const Trans = useMemo(() => {
    const TransComponent: React.FC<
      TransProps<string, DefaultNamespace, undefined, React.HTMLProps<HTMLDivElement>>
    > = ({ i18nKey, tOptions, ...properties_ }) => {
      return (
        <ReactTrans
          {...properties_}
          i18nKey={i18nKey}
          tOptions={{ ...tOptions, lng: getUserPreferredLanguage(data?.user) }}
        />
      );
    };

    return TransComponent;
  }, [data]);

  return { t, i18n, Trans, changeLanguage };
};
