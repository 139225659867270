import { List, ListProps, ListItemButton } from '@mui/material';
import { Notification } from '../Notification';
import { theme } from '../../../../theme';
import { NotificationEntity } from '../../../../graphql';

export interface NotificationsProperties extends ListProps {
  notifications?: NotificationEntity[];
  onSelectNotification: (notification: NotificationEntity | null) => void;
  selectedNotification: NotificationEntity | null;
}

export const Notifications = ({
  notifications,
  onSelectNotification,
  selectedNotification,
  ...rest
}: NotificationsProperties) => {
  return (
    <List sx={{ p: 0 }} {...rest}>
      {notifications?.map((notification: NotificationEntity) => (
        <ListItemButton
          key={notification.id}
          selected={notification.id === selectedNotification?.id}
          onClick={() => onSelectNotification(notification)}
          sx={{
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
            p: 0,
          }}
        >
          <Notification
            selected={notification.id === selectedNotification?.id}
            notification={notification}
          />
        </ListItemButton>
      ))}
    </List>
  );
};
