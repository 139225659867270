export default {
  question: {
    label: 'Question',
    value: 'question',
  },
  resistance: {
    label: 'Resistance Exercise',
    value: 'resistance',
  },
  functional: {
    label: 'Functional Exercise',
    value: 'functional',
  },
  flexibility: {
    label: 'Flexibility Exercise',
    value: 'flexibility',
  },
  cardio: {
    label: 'Cardio Exercise',
    value: 'cardio',
  },
  incrementPanel: {
    label: 'Increment Panel',
    value: 'custom-plus-minus',
  },
  secondPanel: {
    label: 'Second Panel',
    value: 'second-panel',
  },
  hydration: {
    label: 'Hydration',
    value: 'hydration',
  },
  pain: {
    label: 'Pain',
    value: 'human-body',
  },
  resistanceExerciseHeader: {
    label: 'Exercise - Header',
    value: 'resistance-exercise-header',
  },
  resistanceExerciseCategory: {
    label: 'Exercise - Category',
    value: 'resistance-exercise-category',
  },
  resistanceBands: {
    label: 'Resistance - Bands',
    value: 'resistance-bands',
  },
  resistanceFreeWeight: {
    label: 'Resistance - Freeweight',
    value: 'resistance-free-weight',
  },
  resistanceBodyWeight: {
    label: 'Resistance - Bodyweight',
    value: 'resistance-body-weight',
  },
  resistanceSelect: {
    label: 'Resistance - Select',
    value: 'resistance-select',
  },
  resistanceRepsSets: {
    label: 'Reps Sets',
    value: 'resistance-reps-sets',
  },
  resistanceFeeling: {
    label: 'Feeling',
    value: 'resistance-feeling',
  },
};
