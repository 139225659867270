export const IVR = {
  label: 'Ivr',
  value: 'ivr',
};
export const ASSESSMENT = {
  label: 'Coaching',
  value: 'assessment',
};
export const DEFAULT = {
  label: 'Coaching',
  value: '',
};
export const JOURNAL = {
  label: 'Journal',
  value: 'journal',
};
