import { Empty } from 'antd';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { toast } from 'react-toastify';

import {
  useAssessmentResponsesQuery,
  useParticipantCoachingLibrariesForDateQuery,
  useTranslateLazyQuery,
} from '../../../../graphql';
import { getDateStringFromDateTime } from '../../../../utils';
import LibraryItem from './LibraryItem';
import { AnswerableAssessmentQuestionEntity } from '../../../callnotes/Question';
import LibraryQuestions from './LibraryQuestions';
import LibraryActions from './LibraryActions';
import { DropdownField } from '../../../atoms';
import { LANGUAGES } from '../../../../constants';
import QuestionTypes from '../../../organisms/EditableQuestion/QuestionTypes';
import { parseQuestions } from './utils';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  selectedDate: Date;
  userId: number;
  hasSearch?: boolean;
}

export default function CoachingQuestions({ userId, selectedDate, hasSearch }: Properties) {
  const { t } = usePreferredTranslation();
  const shouldRefreshQuestions = useRef(false);
  const translations = useRef<{ before: string; after: string }[] | undefined>();
  const questionsData = useRef<AnswerableAssessmentQuestionEntity[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES.ENGLISH);

  const [translate] = useTranslateLazyQuery();

  const [selectedLibrary, setSelectedLibrary] = useState<string | null>(null);
  const [libraryQuestions, setLibraryQuestions] = useState<AnswerableAssessmentQuestionEntity[]>(
    [],
  );

  const [searchKeyword, setSearchKeyword] = useState('');
  const [loadingLanguage, setLoadingLanguage] = useState(false);

  const { data, loading } = useParticipantCoachingLibrariesForDateQuery({
    variables: {
      userId,
      date: getDateStringFromDateTime(selectedDate),
    },
    skip: !userId,
  });

  const {
    data: assessmentResponsesData,
    loading: loadingAssessment,
    refetch: refetchAssessments,
  } = useAssessmentResponsesQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        userId: Number(userId),
        answerDate: getDateStringFromDateTime(selectedDate) as unknown as Date,
      },
    },
    skip: !selectedLibrary,
  });

  const librariesItems = useMemo(
    () =>
      data?.participantCoachingLibrariesForDate.items?.filter((item) => {
        // Check if the keyword is in the library item title
        const isKeywordInTitle = item.title?.toLowerCase()?.includes(searchKeyword.toLowerCase());

        // Check if the keyword is in any of the question labels
        const isKeywordInQuestions = item.questions?.some((question) => {
          const isInLabel = question.label.toLowerCase().includes(searchKeyword.toLowerCase());

          // check if the keyword is in any of the options
          const isInOptions = question.options
            ?.toLowerCase()
            ?.includes(searchKeyword.toLowerCase());

          return isInLabel || isInOptions;
        });

        return isKeywordInTitle || isKeywordInQuestions;
      }) || [],
    [data?.participantCoachingLibrariesForDate.items, searchKeyword],
  );

  useEffect(() => {
    const library = data?.participantCoachingLibrariesForDate.items?.find(
      (library_) => library_.id === selectedLibrary,
    );

    const answerResponse = assessmentResponsesData?.assessmentResponses?.items ?? [];
    const answerItems =
      answerResponse.find((response) => response.library.id === selectedLibrary)?.answers ?? [];
    const questions = library?.questions ?? [];

    const questionWithAnswers = questions.map((question) => {
      const answer = answerItems.find((ans) => ans.question?.id === question.id);

      const answerValue = answer?.values ?? [];
      const comment = answer?.comment ?? '';

      return {
        ...question,
        answer: answerValue,
        comment,
        libraryId: selectedLibrary,
      };
    });

    setLibraryQuestions(questionWithAnswers as AnswerableAssessmentQuestionEntity[]);
    questionsData.current = questionWithAnswers as AnswerableAssessmentQuestionEntity[];
  }, [
    assessmentResponsesData?.assessmentResponses.items,
    data?.participantCoachingLibrariesForDate.items,
    selectedLibrary,
  ]);

  useEffect(() => {
    if (selectedLibrary) return;

    if (data?.participantCoachingLibrariesForDate.items?.length) {
      const libraryId = data.participantCoachingLibrariesForDate.items?.[0]?.id;
      if (!libraryId) return;

      setSelectedLibrary(libraryId);
    }
  }, [data?.participantCoachingLibrariesForDate.items, selectedLibrary]);

  useEffect(() => {
    if (!shouldRefreshQuestions.current) {
      return;
    }
    if (selectedLanguage.value === LANGUAGES.ENGLISH.value || !shouldRefreshQuestions.current) {
      shouldRefreshQuestions.current = false;
      setLibraryQuestions(questionsData.current);
    } else {
      setLoadingLanguage(true);
      shouldRefreshQuestions.current = false;
      const translateText = [
        ...libraryQuestions.map((q) => q.label),
        ...libraryQuestions.map((q) =>
          q.type === QuestionTypes.imagePicker ? '' : q.options || '',
        ),
      ].map((t) => ({ before: t, after: '' }));
      translate({
        variables: {
          texts: translateText.map((t) => t.before),
          languageCode: selectedLanguage.value || '',
        },
      })
        .then((response) => {
          const translatedLabels = response.data?.translate?.texts || [];
          for (const [index, t] of translatedLabels.entries()) {
            const item = translateText[index];
            if (item) item.after = t;
          }
          translations.current = translateText;
          const translatedOptions = (response.data?.translate?.texts || []).slice(
            libraryQuestions.length,
          );
          if (translatedLabels.length > 0) {
            const newQuestions = parseQuestions(
              libraryQuestions,
              selectedLanguage,
              translatedOptions,
              translatedLabels,
            );
            setLibraryQuestions(newQuestions);
          } else {
            setLibraryQuestions(libraryQuestions);
          }
          return response;
        })
        .finally(() => {
          setLoadingLanguage(false);
        })
        .catch(() => {
          toast.error(t('Failed to translate questions'));
        });
    }
  }, [selectedLanguage, selectedLanguage.value, translate, libraryQuestions]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(event.target.value);
      shouldRefreshQuestions.current = true;
    },
    [setSearchKeyword],
  );

  const handleSearchReset = useCallback(() => {
    setSearchKeyword('');
    shouldRefreshQuestions.current = true;
  }, [setSearchKeyword]);

  const handleSelectLibrary = useCallback((libraryId: string) => {
    setSelectedLibrary(libraryId);
    setSelectedLanguage(LANGUAGES.ENGLISH);
  }, []);

  const handleQuestionAnswered = useCallback(
    (questionId: string, value: string[]) => {
      const updatedQuestionsData = libraryQuestions?.map((item) =>
        item.id === questionId
          ? {
              ...item,
              answer: value,
            }
          : item,
      );
      setLibraryQuestions(updatedQuestionsData);
    },
    [libraryQuestions],
  );

  const handleCommentChange = useCallback(
    (questionId: string, comment: string) => {
      const updatedQuestionsData = libraryQuestions?.map((item) =>
        item.id === questionId
          ? {
              ...item,
              comment,
            }
          : item,
      );
      setLibraryQuestions(updatedQuestionsData);
    },
    [libraryQuestions],
  );

  if (data?.participantCoachingLibrariesForDate.count === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t(
          'No coaching library configured for the selected date, please choose a different date',
        )}
      />
    );
  }

  if (loadingLanguage || loading || loadingAssessment) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: 16,
        }}
      >
        <DropdownField
          value={selectedLanguage.label}
          onChange={(event) => {
            const languageCode =
              event.target.value === LANGUAGES.SPANISH.label
                ? LANGUAGES.SPANISH
                : LANGUAGES.ENGLISH;
            shouldRefreshQuestions.current = true;
            setSelectedLanguage(languageCode);
          }}
          options={[LANGUAGES.ENGLISH.label, LANGUAGES.SPANISH.label]}
          optionLabel={t('Select a language')}
        />
      </Box>
      <Grid container sx={{ flexGrow: 1, height: '100%' }}>
        <Grid
          item
          xs={3}
          sx={{
            height: 'inherit',
            overflow: 'auto',
          }}
        >
          <Stack spacing={2}>
            {hasSearch && (
              <TextField
                placeholder={t('Search...')}
                type="text"
                variant="outlined"
                size="small"
                onChange={handleSearchChange}
                value={searchKeyword}
                sx={{ mr: 2, mb: 1 }}
                InputProps={{
                  sx: {
                    px: 1,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchKeyword && (
                    <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                      <CancelRoundedIcon onClick={handleSearchReset} />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Stack sx={{ pr: 1 }}>
              {librariesItems.map((library) => (
                <LibraryItem
                  key={library.id}
                  library={library}
                  selectedLibrary={selectedLibrary}
                  assessmentResponsesData={assessmentResponsesData}
                  onSelectLibrary={handleSelectLibrary}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={9} sx={{ px: 2, borderLeft: `1px solid ${grey[300]}` }}>
          <LibraryQuestions
            questions={libraryQuestions}
            handleCommentChange={handleCommentChange}
            handleQuestionAnswered={handleQuestionAnswered}
            language={selectedLanguage.value}
          />
          <LibraryActions
            assessmentResponses={assessmentResponsesData}
            libraryQuestions={libraryQuestions}
            refetchAssessments={() => {
              shouldRefreshQuestions.current = true;
              refetchAssessments();
            }}
            selectedDate={selectedDate}
            selectedLibrary={selectedLibrary}
            userId={userId}
            translations={translations.current}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
