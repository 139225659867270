import { alpha, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties {
  data: {
    label: string;
    value: string;
    color: string;
  }[];
}

const SummaryGroup: React.FC<Properties> = ({ data }) => {
  const { t } = usePreferredTranslation();
  return (
    <Box
      sx={{
        alignSelf: 'flex-end',
        alignItems: 'center',
        background: 'white',
        display: 'flex',
        margin: 1,
        borderRadius: 2,
      }}
    >
      {data.map((item, index) => (
        <React.Fragment key={`${item.label}-${index}`}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 13,
              margin: 1,
              mr: 0.5,
            }}
          >
            {item.label}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              background: alpha(item.color, 0.15),
              color: item.color,
              pl: 0.5,
              pr: 0.5,
              borderRadius: 1,
              mr: 1,
            }}
          >
            {t(item.value)}
          </Typography>
          {index < data.length - 1 && (
            <Box sx={{ height: 20, width: 1.5, background: grey[400] }} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SummaryGroup;
