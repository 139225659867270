import { Stack, alpha } from '@mui/system';

import { useEffect, useState } from 'react';
import { InputField } from '../../../../../atoms';
import { theme } from '../../../../../../theme';
import { AssessmentQuestionEntity } from '../../../../../../graphql';
import { useActiveJournal } from '../../../../../../states/journal/useActiveJournal';
import { usePreferredTranslation } from '../../../../../../hooks/usePreferredTranslation';

const arrayRange = (stop: number, step = 1, start = 1) =>
  Array.from({ length: stop + 1 - start || 1 / step + 1 }, (_, index) => start + index * step);

interface Properties {
  question: AssessmentQuestionEntity;
  defaultValue?: any;
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
}

export const ResistanceBodyWeight = ({ question, defaultValue, onChange }: Properties) => {
  const { reps, sets } = question?.meta || {};
  const [selectedSets, setSelectedSets] = useState<number | null>(null);
  const [weightValues, setWeightValues] = useState<any>({});

  const { t } = usePreferredTranslation();

  const { isEditing } = useActiveJournal();

  useEffect(() => {
    if (!isEditing) return;

    setSelectedSets(defaultValue?.sets);
    setWeightValues(defaultValue?.values ?? {});
  }, []);

  const handleChange = (values: any) => {
    onChange?.(values);
  };

  const handleSetsChange = (event_: any) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      setSelectedSets(0);
      return;
    }
    setSelectedSets(Number(value));
    handleChange({ sets: Number(value) });
  };

  const handleWeightChange = (event_: any, index: number) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      setWeightValues({ ...weightValues, [index]: 0 });
      return;
    }
    setWeightValues({ ...weightValues, [index]: event_.target.value });
    handleChange({ values: { ...weightValues, [index]: event_.target.value } });
  };

  return (
    <>
      <Stack sx={{ mb: 1.5, backgroundColor: theme.palette.common.white }}>
        <InputField
          labelSx={{ maxHeight: 0 }}
          wrapperSx={{ mb: 0 }}
          style={{
            marginTop: 0,
            borderColor: alpha('#000', 0.23),
            borderRadius: 4,
          }}
          placeholder={t('journal.select.no_of_sets')}
          type="number"
          value={selectedSets?.toString()}
          onChange={handleSetsChange}
          endAdornment={selectedSets === null ? '' : t('journal.select.sets')?.toLowerCase()}
        />
      </Stack>
      {Boolean(selectedSets && selectedSets > 0) &&
        arrayRange(Number(selectedSets), Number(reps)).map((ele, index) => (
          <Stack
            sx={{ mb: 1.5 }}
            gap={2}
            direction="row"
            key={ele}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                border: '1px solid rgba(0, 0, 0, 0.20)',
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: theme.palette.common.white,
                color: '#000',
              }}
            >
              {index + 1}
            </Stack>
            <Stack sx={{ width: '100%', m: 'auto', backgroundColor: theme.palette.common.white }}>
              <InputField
                labelSx={{ maxHeight: 0 }}
                wrapperSx={{ mb: 0 }}
                style={{
                  marginTop: 0,
                  borderColor: alpha('#000', 0.23),
                  borderRadius: 4,
                }}
                placeholder={t('journal.select.no_of_reps')}
                type="number"
                value={weightValues?.[index] || 0}
                onChange={(event_) => handleWeightChange(event_, index)}
                endAdornment={selectedSets === null ? '' : t('journal.select.reps')?.toLowerCase()}
              />
            </Stack>
          </Stack>
        ))}
    </>
  );
};
