import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

import workoutBackgroundImage from '../../../assets/journaling/workout-summary-background.svg';
import trophyIconImage from '../../../assets/journaling/trophy-icon.svg';
import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

const StyledBox = styled(Box)`
  height: 90vh;
  width: 100%;
  background-image: url(${workoutBackgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

const TrophyWrapper = styled.div`
  margin-top: 7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 75px;

  img {
    max-height: 75px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin: 10px 0px 5px 0px;
  }
  p {
    margin: 0px;
  }
`;

const StyledTable = styled(Table)`
  max-width: 588px;
  margin: auto;

  td:not(:first-of-type) {
    text-align: center;
  }

  th:nth-child(1) {
    width: 65%;
  }

  th:nth-child(even),
  td:nth-child(even) {
    background: #eae8ff;
  }

  td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 800;
    background-color: ${theme.palette.common.white};
    text-align: left;
  }
`;

const ColumnTitle = styled.div`
  transform: rotate(-90deg);
  text-transform: uppercase;
  color: #5e35b1;
  padding: 0px;
  border-bottom: 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px;
`;

const StyledStickyCell = styled(TableCell)`
  padding: 0 6px 16px;
  text-align: center;
`;

const StickyTableCell = styled(TableCell)`
  position: sticky;
  left: 0;
  z-index: 800;
`;

const QuestionCell = styled(TableCell)`
  border-bottom: 0px;
`;

const QuestionTitleCell = styled(StickyTableCell)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #5e35b1;
  padding-bottom: 0px;
  border-bottom: 0px;
  line-height: 25px;
  letter-spacing: 2.1px;
`;

interface Properties {
  question?: IResistanceExerciseIntakeTab[];
  answers: IResistanceIntakeAnswers[] | undefined;
  onFinishResistanceAnswerPreview?: () => void;
}

const ResistanceAnswerPreview = ({
  question = [],
  answers = [],
  onFinishResistanceAnswerPreview,
}: Properties) => {
  const { t } = usePreferredTranslation();

  const data = useMemo(() => {
    const elements = [];
    for (const que of question) {
      elements.push({ label: que.question?.label, isQuestion: true });
      for (const value of que.values) {
        const item: any = { label: value.label };
        const answer = answers.find((ele) => String(ele.questionId) === String(value.id));
        if (answer) {
          const values: any = Object.values(answer.value || {});
          for (const value_ of values) {
            if (value_.feelingStart) {
              item.feelingStart = value_.feelingStart;
            }
            if (value_.feelingEnd) {
              item.feelingEnd = value_.feelingEnd;
            }
            if (value_.lbs) {
              item.lbs = value_.lbs;
            }
            if (value_.sets) {
              item.sets = value_.sets;
            }
            if (value_.sets) {
              item.reps = value_.reps;
            }
          }
        }
        elements.push(item);
      }
    }
    // for (const element of answers || []) {
    //   elements.push({ label: element.questionId, isQuestion: true });
    //   const values: any = element?.value;
    //   for (const [key, value] of Object.entries(values || {})) {
    //     elements.push({ label: key, ...(value as Record<string, unknown>) });
    //   }
    // }
    return elements;
  }, [answers, question]);

  const sentiment: any = {
    '5': <SentimentVerySatisfied style={{ color: 'orange' }} />,
    '3': <SentimentSatisfied style={{ color: 'orange' }} />,
    '0': <SentimentNeutralIcon style={{ color: 'orange' }} />,
    '-3': <SentimentDissatisfied style={{ color: 'orange' }} />,
    '-5': <SentimentVeryDissatisfied style={{ color: 'orange' }} />,
  };

  const tableHeader = [
    {
      name: 'Workout Summary',
      i18nKey: 'journal.workoutSummary',
    },
    {
      name: 'Sets',
      i18nKey: 'journal.select.sets',
    },
    {
      name: 'Reps',
      i18nKey: 'journal.select.reps',
    },
    {
      name: 'Band',
      i18nKey: 'journal.select.band',
    },
    {
      name: 'Effort',
      i18nKey: 'journal.select.effort',
    },
    {
      name: 'Start',
      i18nKey: 'journal.button.start',
    },
    {
      name: 'End',
      i18nKey: 'end',
    },
  ];

  return (
    <StyledBox>
      <TrophyWrapper>
        <img src={trophyIconImage} alt="trophy-icon" />
      </TrophyWrapper>
      <TextWrapper>
        <h3>{t('resistanceAnswerPreview.congrats')}</h3>
        <p>{t('resistanceAnswerPreview.restAdvice')}</p>
      </TextWrapper>
      <Box sx={{ overflow: 'auto' }}>
        <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', margin: '15px 0px' }}>
          <StyledTable stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader.map((header, index) => {
                  if (index === 0) {
                    return (
                      <StickyTableCell align="left" key={header.i18nKey}>
                        <h1>{t(header.i18nKey) || header.name}</h1>
                      </StickyTableCell>
                    );
                  }
                  return (
                    <StyledStickyCell key={header.i18nKey}>
                      <ColumnTitle>{t(header.i18nKey) || header.name}</ColumnTitle>
                    </StyledStickyCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: number) => (
                <TableRow hover key={index}>
                  {row.isQuestion ? (
                    <>
                      <QuestionTitleCell align="left">{row.label}</QuestionTitleCell>
                      <QuestionCell />
                      <QuestionCell />
                      <QuestionCell />
                      <QuestionCell />
                      <QuestionCell />
                      <QuestionCell />
                    </>
                  ) : (
                    <>
                      <TableCell align="left">{row.label}</TableCell>
                      <TableCell>{row.sets}</TableCell>
                      <TableCell>{row.reps}</TableCell>
                      <TableCell>{row.band}</TableCell>
                      <TableCell>{row.effort}</TableCell>
                      <TableCell>{row.feelingStart}</TableCell>
                      <TableCell>{row.feelingEnd}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
      </Box>
      <ButtonWrapper>
        <Button variant="contained" onClick={onFinishResistanceAnswerPreview}>
          {t('journal.finish')}
        </Button>
      </ButtonWrapper>
    </StyledBox>
  );
};

export default ResistanceAnswerPreview;
