export default {
  radio: 'radio',
  dateTime: 'datetime',
  checkbox: 'checkbox',
  multiselect: 'multi-select',
  select: 'select',
  text: 'text',
  input: 'input',
  boolean: 'boolean',
  range5: 'range-5',
  range10: 'range-10',
  slider: 'slider',
  plusMinus: 'plus-minus',
  imagePicker: 'image-picker',
};
