import { LibraryEntity } from '../../../graphql';

export enum ActiveStep {
  Splash = 'Splash',
  Question = 'Question',
}

export interface ActiveJournal {
  /**
   * The currently active journal
   */
  activeJournal: LibraryEntity | null;

  /**
   * The current step in the journal process
   */
  activeStep: ActiveStep;

  /**
   * The journal that is currently being shown in the incomplete modal
   */
  incompleteModalJournal: LibraryEntity | null;

  /**
   * The currently active date
   */
  activeDate: Date;

  /**
   * A flag indicating whether the journal details should be refetched
   */
  refetch: boolean;

  /**
   * The index of the current question
   */
  questionIndex: number;

  /**
   * If the journal is being edited
   */
  isEditing: boolean;

  /**
   * The id of the response being edited
   */
  editingResponseId: number | null;

  /**
   * The answers collection provided by the user
   */
  answersCollection: IQuestionAnswer[];

  /**
   * The answers provided by the user
   */
  answers: IQuestionAnswer | undefined;

  updateAnswers: (answers: any) => void;

  updateAnswersCollection: (answersCollection: any) => void;

  /**
   * Function to change journal editing mode
   */
  setIsEditing: (isEditing: boolean) => void;

  /**
   * Function to set the journal that is currently being shown in the incomplete modal
   */
  setIncompleteModalJournal: (journal: LibraryEntity | null) => void;

  /**
   * Function to set the currently active journal
   */
  setActiveJournal: (journal: LibraryEntity | null) => void;

  /**
   * Function to close the journal modal
   */
  closeJournalModal: () => void;

  /**
   * Function to set the active step to 'Splash'
   */
  setActiveStepToSplash: () => void;

  /**
   * Function to set the active step to 'Question'
   */
  setActiveStepToQuestion: () => void;

  /**
   * Function to start the journal at a specific question index
   */
  startJournal: (qsIndex: number) => void;

  /**
   * Function to set the journal in editing mode
   */
  editJournal: (questionIndex: number, responseId: number) => void;

  /**
   * Function to set the current question index
   */
  setQuestionIndex: (index: number) => void;

  /**
   * Function to toggle the refetch flag
   */
  refetchJournalDetails: () => void;

  /**
   * Function to set the currently active date
   */
  setActiveDate: (date: Date) => void;

  /**
   * Function to close the editing mode
   */
  closeEditing: () => void;
}
