import { Box, Popover, Radio, RadioGroup, Stack, Typography, useMediaQuery } from '@mui/material';
import { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { AssessmentQuestionEntity } from '../../../graphql';
import { theme } from '../../../theme';
import QuestionInput from './QuestionInput';
import QuestionNavigation from './QuestionNavigation';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import { InfoModal } from '../../atoms/Journal';

interface QuestionProperties {
  submit?: boolean;
  question: AssessmentQuestionEntity;
  questionAnswersMap: Map<any, any>;
  onSubmitResistance?: (value: IResistanceIntakeAnswers[]) => void;
  onSubmitCardio?: (value: ICardioIntakeAnswer) => void;
  onPrev?: () => void;
}

const QuestionCategory = ({
  question,
  submit,
  onSubmitResistance,
  onSubmitCardio,
  onPrev,
  questionAnswersMap,
}: QuestionProperties): ReactElement | null => {
  const sm = useMediaQuery('(min-width:750px)');
  const { t } = usePreferredTranslation();
  const { isEditing, updateAnswers } = useActiveJournal();
  const [value, setValue] = useState<AssessmentQuestionEntity>();
  const [step, setStep] = useState<number>(0);

  const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  const handleClick = useCallback(
    (question_: AssessmentQuestionEntity) => {
      if (value === question_) {
        return;
      }

      setValue(question_);
    },
    [value],
  );

  const handleAnswers = useCallback(
    (question_: AssessmentQuestionEntity, newValues: IAnswer[]) => {
      const newAnswer: IQuestionAnswer = {
        questionId: question_.id,
        values: newValues,
      };

      updateAnswers(newAnswer);
    },
    [updateAnswers],
  );

  const handleChange = useCallback(
    (newValues: IAnswer[]) => {
      const newAnswer: IQuestionAnswer = {
        questionId: question.id,
        values: newValues,
      };

      updateAnswers(newAnswer);
    },
    [question.id, updateAnswers],
  );

  const reset = useCallback(() => {
    setStep(0);
  }, []);

  const handlePrevious = useCallback(() => {
    if (onPrev) {
      onPrev();
    }
  }, [onPrev]);

  const handleNext = useCallback(() => {
    setStep(1);
  }, []);

  const handleSubmit = useCallback(() => {
    setStep(1);
  }, []);

  const handleResistanceSubmit = useCallback(
    (value_: IResistanceIntakeAnswers[]) => {
      if (onSubmitResistance) {
        onSubmitResistance(value_);
      }
    },
    [onSubmitResistance],
  );

  const handleCardioSubmit = useCallback(
    (value_: ICardioInput) => {
      if (onSubmitCardio) {
        onSubmitCardio({ questionId: value?.id || question.id, value: value_ });
      }
    },
    [onSubmitCardio, question.id, value?.id],
  );

  useEffect(() => {
    if (isEditing) {
      const radioValue = question.questions?.find((q) => questionAnswersMap.has(q.id));
      setValue(radioValue);
    }
  }, []);

  if (step === 1 && value) {
    const cardioValue = questionAnswersMap.get(value.id);
    return (
      <>
        {value.icon ? (
          <Box
            height={90}
            sx={{
              background: `url('${value.icon}') no-repeat center/cover`,
              position: 'relative',
              mb: 2,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: '10px',
                left: '20px',
                color: theme.palette.common.white,
              }}
            >
              <Typography variant="h5">{value.label}</Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="h5" sx={{ mb: 4 }}>
            {value.label}
          </Typography>
        )}
        <QuestionInput
          question={value}
          onCategoryChange={handleAnswers}
          onChange={handleChange}
          onSubmitResistance={handleResistanceSubmit}
          onSubmitCardio={handleCardioSubmit}
          onPrev={reset}
          questionAnswersMap={questionAnswersMap}
          cardioValue={cardioValue}
        />
      </>
    );
  }

  return (
    <Box>
      <RadioGroup value={value?.id}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant={sm ? 'h5' : 'h6'}>{question?.label}</Typography>
            {question?.tooltip?.title && (
              <>
                <InfoIcon
                  color="primary"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={{ cursor: 'pointer', ml: 1 }}
                />
                <Popover
                  id="info-popover"
                  sx={{ pointerEvents: 'none' }}
                  open={Boolean(anchorElement)}
                  anchorEl={anchorElement}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  disableRestoreFocus
                  PaperProps={{
                    style: {
                      boxShadow:
                        '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
                      borderRadius: '10px',
                    },
                  }}
                >
                  <InfoModal
                    displayTitle={question.tooltip?.title}
                    example={question.tooltip?.description?.toString()}
                    thumbnail={question.tooltip?.icon?.toString()}
                    showCloseButton={false}
                  />
                </Popover>
              </>
            )}
          </Stack>
          {question.questions?.map((q) => {
            return (
              <Box key={q.id} sx={{ cursor: 'pointer' }} onClick={() => handleClick(q)}>
                {q.icon ? (
                  <Stack
                    sx={{
                      position: 'relative',
                      background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 90.62%), url('${q.icon}') no-repeat center/cover`,
                      minHeight: '150px',
                    }}
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                    <Radio
                      value={q.id}
                      checked={value?.id === q.id}
                      sx={{
                        color: theme.palette.common.white,
                        mr: 3,
                        '&, &.Mui-checked': {
                          color: theme.palette.common.white,
                        },
                      }}
                    />
                    <Typography
                      sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
                      fontSize={theme.typography.pxToRem(18)}
                      color={theme.palette.common.white}
                      fontWeight={500}
                    >
                      {q.label}
                    </Typography>
                  </Stack>
                ) : (
                  q.label
                )}
              </Box>
            );
          })}
        </Stack>
      </RadioGroup>
      <QuestionNavigation
        submit={submit}
        onPrev={handlePrevious}
        onNext={handleNext}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default QuestionCategory;
