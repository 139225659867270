import { MenuItem, Select, Stack, alpha } from '@mui/material';
import { useEffect, useState } from 'react';
import { InputField } from '../../../../../atoms';
import { theme } from '../../../../../../theme';
import { AssessmentQuestionEntity } from '../../../../../../graphql';
import { useActiveJournal } from '../../../../../../states/journal/useActiveJournal';
import { usePreferredTranslation } from '../../../../../../hooks/usePreferredTranslation';

interface Properties {
  parentQuestion?: AssessmentQuestionEntity;
  defaultValue?: any;
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
}

const arrayRange = (stop: number, step = 1, start = 1) =>
  Array.from({ length: stop + 1 - start || 1 / step + 1 }, (_, index) => start + index * step);

export const ResistanceBandsInput = ({ defaultValue, onChange, parentQuestion }: Properties) => {
  const [selectedSets, setSelectedSets] = useState<number | null>(null);
  const [selectedReps, setSelectedReps] = useState<number | null>(null);
  const [resistanceValues, setResistanceValues] = useState<any>({});

  const { t } = usePreferredTranslation();

  const { isEditing } = useActiveJournal();

  const handleChange = (values: any) => {
    onChange?.(values);
  };

  useEffect(() => {
    if (!isEditing) return;

    setSelectedSets(defaultValue?.sets);
    setResistanceValues(defaultValue?.resistance ?? {});
    setSelectedReps(defaultValue?.reps);
  }, []);

  const handleSetsChange = (event_: any) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      setSelectedSets(0);
      return;
    }
    setSelectedSets(Number(value));
    handleChange({ sets: Number(value) });
  };

  const handleRepsChange = (event_: any) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      setSelectedReps(0);
      return;
    }
    setSelectedReps(Number(value));
    handleChange({ reps: Number(value) });
  };

  const handleResistanceChange = (event_: any, index: number) => {
    setResistanceValues({ ...resistanceValues, [index]: event_.target.value });
    handleChange({ resistance: { ...resistanceValues, [index]: event_.target.value } });
  };

  const options =
    (parentQuestion?.questions || []).find((ele) => ele.meta?.type === 'resistance-input')
      ?.options || '';
  const resistanceOptions = options.split(',') || [];

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 1.5, backgroundColor: theme.palette.common.white }}
      >
        <InputField
          labelSx={{ maxHeight: 0 }}
          wrapperSx={{ mb: 0 }}
          style={{
            marginTop: 0,
            borderColor: alpha('#000', 0.23),
            borderRadius: 4,
          }}
          placeholder={t('journal.select.no_of_sets')}
          type="number"
          value={selectedSets?.toString()}
          onChange={handleSetsChange}
          endAdornment={selectedSets === null ? '' : t('journal.select.sets')?.toLowerCase()}
        />
        <InputField
          labelSx={{ maxHeight: 0 }}
          wrapperSx={{ mb: 0 }}
          style={{
            marginTop: 0,
            borderColor: alpha('#000', 0.23),
            borderRadius: 4,
          }}
          placeholder={t('journal.select.no_of_reps')}
          type="number"
          value={selectedReps?.toString()}
          onChange={handleRepsChange}
          endAdornment={selectedReps === null ? '' : t('journal.select.reps')?.toLowerCase()}
        />
      </Stack>
      {Boolean(selectedSets && selectedSets > 0 && resistanceOptions.length > 0) &&
        arrayRange(Number(selectedSets)).map((ele, index) => (
          <Stack
            sx={{ mb: 1.5 }}
            gap={2}
            direction="row"
            key={ele}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                border: '1px solid rgba(0, 0, 0, 0.20)',
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: theme.palette.common.white,
                color: '#000',
              }}
            >
              {index + 1}
            </Stack>
            <Select
              displayEmpty
              value={resistanceValues[index]}
              onChange={(event_) => handleResistanceChange(event_, index)}
              sx={{ backgroundColor: theme.palette.common.white, width: '100%', m: 'auto' }}
            >
              <MenuItem disabled>{t('journal.exercise.resistance')}</MenuItem>
              {options.split(',').map((ele) => {
                const [value, ...rest] = ele.split('-');
                const text = rest.join('');
                if (!(value || text)) {
                  return '';
                }
                return (
                  <MenuItem key={ele} value={value}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        ))}
    </>
  );
};
