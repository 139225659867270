import { CSSProperties, ReactElement } from 'react';
import { DatePicker, ConfigProvider, DatePickerProps } from 'antd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dayjs } from '../../../utils/dayjs';
import { theme } from '../../../theme';

const { RangePicker } = DatePicker;

interface Properties {
  readonly dataTestId?: string;
  readonly disabled?: boolean;
  readonly value?: any;
  readonly placeholder?: string;
  readonly endPlaceholder?: string;
  dateFormat?: string;
  showIcon?: boolean;
  readonly onChange?: (event: any) => void;
  sx?: CSSProperties;
  allowClear?: boolean;
  disabledDate?: DatePickerProps['disabledDate'];
  popupStyle?: CSSProperties;
}

export function DateRangePickerField({
  dataTestId,
  placeholder = '',
  endPlaceholder = '',
  onChange,
  value = [null, null],
  disabled,
  sx = {},
  dateFormat,
  showIcon = false,
  allowClear = false,
  disabledDate,
  popupStyle,
}: Properties): ReactElement<Properties> {
  const handleChange = (event: any) => {
    onChange?.(event);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.palette.primary.main,
        },
      }}
    >
      <RangePicker
        placeholder={[placeholder, endPlaceholder]}
        format={dateFormat ?? 'MMM D, YY'}
        disabled={disabled}
        value={[dayjs(value[0]), dayjs(value[1])]}
        onChange={handleChange}
        allowClear={allowClear}
        suffixIcon={showIcon ? <ExpandMoreIcon /> : null}
        style={sx}
        data-testid={dataTestId}
        disabledDate={disabledDate}
        popupStyle={popupStyle}
      />
    </ConfigProvider>
  );
}
