import { ReactElement, useCallback } from 'react';
import { Box, Stack, Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Image } from '../../Image';
import { DialogCloseButton } from '../..';
import { theme } from '../../../../theme';

interface Properties {
  displayTitle?: string;
  thumbnail?: string;
  example?: string;
  showCloseButton?: boolean;
}

export function InfoModal({
  displayTitle,
  thumbnail: source = '',
  example,
  showCloseButton = true,
}: Properties): ReactElement | null {
  const handleClose = useCallback(() => {}, []);

  return (
    <Paper
      sx={{
        minWidth: '100%',
        maxWidth: 500,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {showCloseButton ? (
        <DialogCloseButton
          iconColor="white"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
          }}
          onClick={handleClose}
        />
      ) : null}
      <Stack>
        <Box width="100%" height={source && 120} sx={{ position: 'relative', overflow: 'hidden' }}>
          {source && (
            <Image
              wrapperClassName="journal-info-modal-img"
              style={{ width: '100%' }}
              src={source}
            />
          )}
          <Box sx={source ? { position: 'absolute', bottom: 0, ml: 2, mb: 2 } : { m: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: source ? 'white' : 'unset' }}>
              {displayTitle}
            </Typography>
          </Box>
        </Box>

        <Box sx={source ? { p: 2.5 } : { ml: 2, mr: 2, mb: 2 }}>
          {example && <div dangerouslySetInnerHTML={{ __html: example }} />}
        </Box>
      </Stack>
    </Paper>
  );
}
