import { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { grey } from '@mui/material/colors';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { NoteEntity } from '../../../graphql';
import { DialogCloseButton } from '../../atoms';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties {
  open?: boolean;
  modalTitle?: string;
  onClose?: () => void;
  onSubmit?: (note: NoteEntity | null, message: string) => void;
  note: NoteEntity | null;
}

export default function AddEditNoteModal({
  open = false,
  modalTitle,
  note,
  onClose,
  onSubmit,
}: Properties) {
  const [noteText, setNoteText] = useState('');
  const { t } = usePreferredTranslation();
  useEffect(() => {
    setNoteText((note && note?.title) || '');
  }, [note]);

  const handleClose = useCallback(() => {
    if (onClose) {
      setNoteText((note && note?.title) || '');
      onClose();
    }
  }, [note, onClose]);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(note, noteText);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack spacing={1}>
          <Box sx={{ mb: 2 }}>
            <Typography component="p" variant="h5">
              {modalTitle}
            </Typography>
            <DialogCloseButton onClick={handleClose} />
          </Box>
          <TextareaAutosize
            placeholder={t('Add your internal notes here...')}
            value={noteText}
            onChange={(event: any) => setNoteText(event.target.value)}
            style={{
              width: '100%',
              resize: 'vertical',
              minHeight: 104,
              borderColor: grey[300],
              borderRadius: '12px',
              padding: '1rem',
            }}
          />
          <Stack alignItems="flex-end">
            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
              <Button variant="outlined" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button variant="contained" onClick={handleSubmit} disabled={!noteText}>
                {t('Save')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
