import { LANGUAGES } from '../../../../constants';
import { AnswerableAssessmentQuestionEntity } from '../../../callnotes/Question';
import QuestionTypes from '../../../organisms/EditableQuestion/QuestionTypes';

export const parseQuestions = (
  questions: AnswerableAssessmentQuestionEntity[],
  selectedLanguage: any,
  translatedOptions: string[],
  translatedLabels: string[],
) => {
  return questions.map((ques, index) => {
    let options: string | undefined | null;
    switch (ques.type) {
      case QuestionTypes.imagePicker: {
        options = ques.options;
        break;
      }
      case QuestionTypes.boolean: {
        options =
          JSON.parse(ques.options || '[]').length === 0 &&
          selectedLanguage.value === LANGUAGES.SPANISH.value
            ? JSON.stringify(['Sí', 'No'])
            : ques.options;
        break;
      }
      default: {
        options = translatedOptions[index] || '';
        break;
      }
    }
    const originalOptions = JSON.parse(ques.options || '[]');

    const answer = ques.answer.map((a) => {
      if (selectedLanguage.value === LANGUAGES.SPANISH.value && a === 'Yes') return 'Sí';
      const answerIndex = originalOptions.indexOf(a);
      if (answerIndex < 0) return a;
      return JSON.parse(options || '[]')?.[answerIndex];
    });
    return {
      ...ques,
      label: translatedLabels[index] || '',
      answer,
      options,
    };
  });
};

export const parseQuestionValues = (
  question: AnswerableAssessmentQuestionEntity,
  translations: { before: string; after: string }[] | undefined = [],
) => {
  translations.push({ before: '["Yes","No"]', after: '["Sí","No"]' });
  const translatedOption = translations?.find((t) => t.after === question.options);
  if (!translatedOption) return question.answer;
  const optionsToCheck = JSON.parse(question.options || '[]');
  const optionsToUse = JSON.parse(translatedOption.before || '[]');
  return question.answer.map((a) => {
    const index = optionsToCheck.indexOf(a);
    return index < 0 ? a : optionsToUse[index];
  });
};
