/* eslint-disable security/detect-object-injection */
import { ReactElement, useState } from 'react';
import { Typography, MobileStepper, Box, BoxProps, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ArrowBack, ArrowForward } from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';
// eslint-disable-next-line import/no-extraneous-dependencies
import { autoPlay } from 'react-swipeable-views-utils';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface SwipeableViewsItem {
  label?: string;
  imgPath?: string;
  description?: string;
}
export type SwipeableViewsData = Array<SwipeableViewsItem>;

interface Properties extends BoxProps {
  data: SwipeableViewsData;
  autoplay?: boolean;
}

export function Swipeable({
  data,
  autoplay,
  ...rest
}: Properties): ReactElement<Properties> | null {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = usePreferredTranslation();
  if (!data) {
    return null;
  }

  const SwipeableComponent = autoplay ? autoPlay(SwipeableViews) : SwipeableViews;

  const maxSteps = data.length;

  const handleNext = () => {
    setActiveStep((previousActiveStep) =>
      previousActiveStep === maxSteps - 1 ? 0 : previousActiveStep + 1,
    );
  };

  const handleBack = () => {
    setActiveStep((previousActiveStep) =>
      previousActiveStep === 0 ? maxSteps - 1 : previousActiveStep - 1,
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      data-testid="SwipeableViewsComponent"
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <SwipeableComponent
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          style={{ textAlign: 'center' }}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          slideStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {data.map((step, index) => (
            <Box key={step.label} sx={{ justifyContent: 'center', display: 'flex' }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    display: 'block',
                    overflow: 'hidden',
                    maxWidth: 400,
                    width: '100%',
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </Box>
          ))}
        </SwipeableComponent>
        <Box sx={{ width: '100%', position: 'relative', px: 5, textAlign: 'center', mt: 4 }}>
          <IconButton
            onClick={handleBack}
            color="primary"
            aria-label="Carousel previous"
            sx={{ position: 'absolute', left: 0, background: 'white', opacity: 0.4 }}
          >
            <ArrowBack />
          </IconButton>

          <IconButton
            onClick={handleNext}
            color="primary"
            aria-label="Carousel next"
            sx={{ position: 'absolute', right: 0, background: 'white', opacity: 0.4 }}
          >
            <ArrowForward />
          </IconButton>

          <Typography variant="h5">{data[activeStep]?.label}</Typography>
          <Typography>{data[activeStep]?.description}</Typography>
        </Box>
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={false}
        backButton={false}
        sx={{ background: 'transparent', alignSelf: 'center', mb: 4 }}
      />
    </Box>
  );
}
