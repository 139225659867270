import { useRequest } from '@axios-use/react';
import { useEffect, useMemo, useState } from 'react';
import { IResponseBase } from '../../../types/axios';
import * as fitbit from '../../../api/Fitbit';
import { useFitbitErrorResponse } from '../utils';

interface IResponse extends IResponseBase {
  data: Array<IFitBitDevice>;
  fitbitRefreshTokenError: boolean;
}

interface Properties {
  user?: IFitbitUserInput | null;
}

/**
 * Fetches the user's devices data from FitBit
 *
 * @param {UserEntity} user (Optional) The user to fetch the devices
 * @param {string|undefined} refreshToken (Optional) Used to gracefully fetch a Fitbit access token for the user
 * @returns {{ data: any, loading: boolean, error: any }} HTTP response object
 */
export const useFitBitDevices = ({ user }: Properties = {}) => {
  const [data, setData] = useState<Array<IFitBitDevice>>([]);
  const { error, handleError, fitbitRefreshTokenError } = useFitbitErrorResponse();
  const [createRequest, { hasPending, clear: cancel }] = useRequest(fitbit.getDevices);

  useEffect(() => {
    if (!user || !user.fitbitId || !user.fitbitAccessToken) {
      return cancel;
    }

    const { ready } = createRequest(user);

    ready()
      .then((response: any) => {
        const [data] = response;
        setData(data);

        return data;
      })
      .catch((error) => {
        return handleError(user, error);
      });

    return cancel;
  }, [cancel, createRequest, handleError, user]);

  return useMemo((): IResponse => {
    return {
      data,
      loading: hasPending,
      error,
      fitbitRefreshTokenError,
    };
  }, [data, hasPending, error, fitbitRefreshTokenError]);
};
