import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCountVoicesQuery } from '../../graphql';
import { useCurrentUser } from '../../states/user/useCurrentUser';
import { useChannel, useEvents } from '../../modules';
import { PUSHER } from '../../constants';

export type VoicesAnalytics = {
  readonly viewedCount: number;
  readonly notViewedCount: number;
  readonly likedCount: number;
  readonly notLikedCount: number;
  readonly count: number;
};

const defaultValue: VoicesAnalytics = {
  viewedCount: 0,
  notViewedCount: 0,
  likedCount: 0,
  notLikedCount: 0,
  count: 0,
};

export const VoicesAnalyticsContext = createContext<VoicesAnalytics>(defaultValue);

export const VoicesAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();

  const notViewedCountVoicesQuery = useCountVoicesQuery({
    variables: { where: { viewed: false } },
  });
  const notLikedCountVoicesQuery = useCountVoicesQuery({
    variables: { where: { liked: false } },
  });
  const countVoicesQuery = useCountVoicesQuery();

  const contextValue: VoicesAnalytics = useMemo<VoicesAnalytics>((): VoicesAnalytics => {
    const notViewedCount = notViewedCountVoicesQuery.data?.countVoices || 0;
    const notLikedCount = notLikedCountVoicesQuery.data?.countVoices || 0;
    const count = countVoicesQuery.data?.countVoices || 0;
    const viewedCount = count - notViewedCount;
    const likedCount = count - notLikedCount;

    return {
      ...defaultValue,
      viewedCount,
      notViewedCount,
      likedCount,
      notLikedCount,
      count,
    };
  }, [notViewedCountVoicesQuery, notLikedCountVoicesQuery, countVoicesQuery]);

  const voicesChannel = useChannel(PUSHER.CHANNELS.VOICES);

  useEvents(
    voicesChannel,
    [
      `${PUSHER.EVENTS.VOICE_CREATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.VOICE_DELETED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.VOICE_UPDATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.VOICES_UPDATED}.${currentUser.data?.user?.id}`,
    ],
    () => {
      notViewedCountVoicesQuery.refetch();
      notLikedCountVoicesQuery.refetch();
      countVoicesQuery.refetch();
    },
  );

  return (
    <VoicesAnalyticsContext.Provider value={contextValue}>
      {children}
    </VoicesAnalyticsContext.Provider>
  );
};

export const useVoicesAnalytics = () => useContext(VoicesAnalyticsContext);
