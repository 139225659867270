import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PLACEHOLDER_SRC = '/images/icons/icon-96x96.png';

export const Image = ({ ...rest }: LazyLoadImageProps) => {
  return (
    <LazyLoadImage
      data-testid="imageComponent"
      effect="blur"
      placeholderSrc={PLACEHOLDER_SRC}
      {...rest}
    />
  );
};
