import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import FeelingInput from './FeelingInput';
import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

const FeelingContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  overflow: 'hidden',
  width: '100%',
  flex: 1,
  gap: theme.typography.pxToRem(16),
}));

interface Properties {
  tabs: IResistanceIntakeTab[];
  currentTab: number;
  defaultAnswers: IResistanceIntakeAnswers[];
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
  onActiveTabChanged?: (tabIndex: number) => void;
}

export default function ResistanceInputIntake({
  tabs,
  currentTab,
  onChange,
  onActiveTabChanged,
  defaultAnswers,
}: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const [answers, setAnswers] = useState<IResistanceIntakeAnswers[]>(defaultAnswers);
  const handleTabChange = useCallback((event: any, value: number) => {
    onActiveTabChanged?.(value);
  }, []);
  const activeTab = tabs[currentTab];

  const handleChange = useCallback(
    (option: string, { questionId, value }: IResistanceIntakeAnswer) => {
      let newAnswers = [...answers, { questionId, value: { [option]: value } }];

      if (answers?.some((a) => a.questionId === questionId)) {
        newAnswers = answers?.map((q) => {
          const existingOptions: any = q.value;

          if (q.questionId === questionId) {
            return {
              ...q,
              value: {
                ...q.value,
                [option]: typeof value !== 'string' && {
                  ...(existingOptions && existingOptions[option] ? existingOptions[option] : {}),
                  ...value,
                },
              },
            };
          }

          return q;
        }, []);
      }

      if (onChange) {
        onChange(newAnswers as IResistanceIntakeAnswers[]);
      }

      setAnswers(newAnswers as IResistanceIntakeAnswers[]);
    },
    [answers, onChange],
  );

  const colorToIndexMapping = {
    [t('journal.select.band.none')]: 0,
    [t('journal.select.band.yellow')]: 1,
    [t('journal.select.band.orange')]: 2,
    [t('journal.select.band.black')]: 3,
    [t('journal.select.band.green')]: 4,
    [t('journal.select.band.red')]: 5,
    [t('journal.select.band.pink')]: 6,
  };

  const getBandIndex = (band: string | undefined) => {
    return band === undefined ? undefined : colorToIndexMapping[band];
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs onChange={handleTabChange} value={currentTab}>
          {tabs?.map((q, index) => (
            <Tab key={q.question.id} label={q.question.label} value={index} />
          ))}
        </Tabs>
      </Box>
      {activeTab?.values.map((option, index) => {
        const answerForOption = answers?.find((a) => a.questionId === activeTab.question.id);
        const value = answerForOption?.value?.[option];
        return (
          <Accordion
            defaultExpanded={index === 0}
            key={option}
            sx={{
              borderRadius: '8px',
              backgroundColor: theme.palette.grey[100],
              my: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                sx={{
                  alignSelf: 'flex-end',
                  background: theme.palette.common.black,
                  color: theme.palette.common.white,
                  borderRadius: 5,
                  pl: 1.5,
                  pr: 1.5,
                  pt: 0.5,
                  pb: 0.5,
                  mr: 1,
                }}
              >
                {index + 1}
              </Typography>
              <Typography variant="h6" component="span">
                {option}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    sx={{ backgroundColor: theme.palette.common.white }}
                    onChange={(event: any) =>
                      handleChange(option, {
                        questionId: activeTab.question.id,
                        value: {
                          sets: event.target.value,
                        },
                      })
                    }
                    value={value?.sets}
                  >
                    <MenuItem disabled>{t('journal.select.sets')}</MenuItem>
                    <MenuItem value={1}>{t('journal.select.set_one', { count: 1 })}</MenuItem>
                    <MenuItem value={2}>{t('journal.select.set_other', { count: 2 })}</MenuItem>
                    <MenuItem value={3}>{t('journal.select.set_other', { count: 3 })}</MenuItem>
                    <MenuItem value={4}>{t('journal.select.set_other', { count: 4 })}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    sx={{ backgroundColor: theme.palette.common.white }}
                    SelectDisplayProps={{
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                      },
                    }}
                    onChange={(event: any) => {
                      handleChange(option, {
                        questionId: activeTab.question.id,
                        value: { band: event.target.value },
                      });
                    }}
                    // backward compatibility
                    value={
                      Number(value?.band) || Number(value?.band) === 0
                        ? value?.band
                        : getBandIndex(value?.band)
                    }
                  >
                    <MenuItem disabled>{t('journal.select.band')}</MenuItem>
                    {[
                      t('journal.select.band.none'),
                      t('journal.select.band.yellow'),
                      t('journal.select.band.orange'),
                      t('journal.select.band.black'),
                      t('journal.select.band.green'),
                      t('journal.select.band.red'),
                      t('journal.select.band.pink'),
                    ].map((color, index) => (
                      <MenuItem value={index} sx={{ display: 'flex' }} key={color}>
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            background: color,
                            borderRadius: 5,
                            mr: 1,
                          }}
                        />
                        {color}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    sx={{ backgroundColor: theme.palette.common.white }}
                    onChange={(event: any) =>
                      handleChange(option, {
                        questionId: activeTab.question.id,
                        value: {
                          effort: event.target.value,
                        },
                      })
                    }
                    value={value?.effort}
                  >
                    <MenuItem disabled>{t('journal.select.effort')}</MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    sx={{ backgroundColor: theme.palette.common.white }}
                    onChange={(event: any) =>
                      handleChange(option, {
                        questionId: activeTab.question.id,
                        value: {
                          reps: event.target.value,
                        },
                      })
                    }
                    value={value?.reps}
                  >
                    <MenuItem disabled>{t('journal.select.reps')}</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FeelingContainer>
                  <Box flexGrow={1}>
                    <Typography
                      fontSize={theme.typography.pxToRem(14)}
                      fontWeight={500}
                      sx={{ mb: 0.5 }}
                    >
                      {t('journal.feeling.start')}
                    </Typography>
                    <FeelingInput
                      onChange={(event: any) =>
                        handleChange(option, {
                          questionId: activeTab.question.id,
                          value: {
                            feelingStart: event.target.value,
                          },
                        })
                      }
                      value={value?.feelingStart}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography
                      fontSize={theme.typography.pxToRem(14)}
                      fontWeight={500}
                      sx={{ mb: 0.5 }}
                    >
                      {t('journal.feeling.end')}
                    </Typography>
                    <FeelingInput
                      onChange={(event: any) =>
                        handleChange(option, {
                          questionId: activeTab.question.id,
                          value: {
                            feelingEnd: event.target.value,
                          },
                        })
                      }
                      value={value?.feelingEnd}
                    />
                  </Box>
                </FeelingContainer>
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
