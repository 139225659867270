import { useCallback, useRef, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lowerCase } from 'lodash';
import { dayjs } from '../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { Accordion } from '../../../atoms/Accordion';
import { DateRangePickerField } from '../../../atoms';
import { cardHeaderStyles, headerContainerStyles } from './styles';
import { useFitbitActivitiesListQuery } from '../../../../graphql';
import {
  convertMinutesToHHMMSS,
  fitbitActivitySvgMap,
  getDateStringFromDateTime,
} from '../../../../utils';
import { theme } from '../../../../theme';

interface Properties {
  user: IUser;
  dateRange: { fromDate: Date; toDate: Date };
  setDateRange: (range: { fromDate: Date; toDate: Date }) => void;
}

const EmptyListComp: React.FC = () => {
  const { t } = usePreferredTranslation();
  return (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 2,
        marginBottom: 2,
        backgroundColor: '#fbfbfb',
      }}
    >
      {t('No data found.')}
    </Typography>
  );
};

export const ExercisesList: React.FC<Properties> = ({ user, dateRange, setDateRange }) => {
  const [dateValue, setDateValue] = useState(dateRange);
  const hasRenderedOnce = useRef(false);
  const { t } = usePreferredTranslation();
  const { data: activityList, loading } = useFitbitActivitiesListQuery({
    variables: {
      fromDate: getDateStringFromDateTime(dateValue.fromDate),
      toDate: getDateStringFromDateTime(dateValue.toDate),
      userId: Number(user?.id),
    },
  });

  const handleDateChange = useCallback(
    (dates: any[]) => {
      setDateValue({
        fromDate: dates[0],
        toDate: dates[1],
      });
      setDateRange({ fromDate: dates[0], toDate: dates[1] });
    },
    [setDateRange],
  );

  if (loading && !hasRenderedOnce.current) {
    hasRenderedOnce.current = true;
    return <Skeleton variant="rectangular" height={300} sx={{ mt: 3 }} />;
  }

  return (
    <Paper style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
      <Stack sx={headerContainerStyles}>
        <Typography variant="h6">{t('Events')}</Typography>
        <DateRangePickerField
          sx={{
            padding: '6px 12px',
            backgroundColor: '#eeeeee',
            border: 'none',
          }}
          onChange={handleDateChange}
          value={[dateValue.fromDate, dateValue.toDate]}
          showIcon
        />
      </Stack>
      <Paper
        sx={{
          height: '500px',
          overflow: 'scroll',
          alignItems: 'center',
        }}
      >
        {!activityList?.fitbitActivitiesList || activityList?.fitbitActivitiesList?.length <= 0 ? (
          <EmptyListComp />
        ) : (
          activityList.fitbitActivitiesList.map((data, index) => {
            const activityName: string = lowerCase(data.name);
            const Icon = fitbitActivitySvgMap[activityName] ?? fitbitActivitySvgMap.default;

            const time = convertMinutesToHHMMSS(data.duration);
            const dateWithoutTimezone = data.startTime?.split('.')?.[0];
            const date = dayjs.utc(dateWithoutTimezone).format('MMMM D, YYYY @ h:mma');

            return (
              <Accordion sx={{ boxShadow: 'none' }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ height: '80px' }}
                  sx={{ p: 2 }}
                >
                  <Stack sx={cardHeaderStyles}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {Icon && <Icon />}
                      <Typography variant="subtitle2" marginLeft={2}>
                        {data.name}
                      </Typography>
                    </div>
                    <Typography variant="subtitle1" fontSize={14}>
                      {date}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Paper
                    variant="outlined"
                    sx={{ paddingX: 2, paddingY: 1, backgroundColor: '#fbfbfb' }}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="subtitle2">{time}</Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={14}
                        sx={{
                          lineHeight: theme.typography.pxToRem(15),
                        }}
                      >
                        {t('Time')}
                      </Typography>
                    </Stack>
                    <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="subtitle2">{data.averageHeartRate ?? '-'}</Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={14}
                        sx={{
                          lineHeight: theme.typography.pxToRem(15),
                        }}
                      >
                        {t('Heart Rate')}
                      </Typography>
                    </Stack>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Paper>
    </Paper>
  );
};
