import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  List,
  TablePagination,
  Box,
  BoxProps,
  ListItem,
  InputAdornment,
  IconButton,
  TextField,
  Alert,
} from '@mui/material';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { theme } from '../../../../theme';
import {
  EmailEntity,
  useCreateEmailMutation,
  useEmailsQuery,
  UserEntity,
} from '../../../../graphql';
import { useChannel, useEvent } from '../../../../modules';
import { PUSHER } from '../../../../constants';
import { EmailCardsPlaceholder } from '../../../atoms';
import { useCurrentUser, useUser } from '../../../../states';

import { Controls } from '../../controls';
import { Email } from '../Email';
import { Attachment } from '../../attachments';
import { scrollListToEnd } from '../../../../utils';
import { withValidStudyUsers } from '../../../hoc/withValidStudyUsers';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends BoxProps {
  readonly user?: UserEntity;
  readonly onSelectEmail: (email: EmailEntity | null) => void;
  readonly selectedEmail: EmailEntity | null;
  readonly hasSearch?: boolean;
  readonly hasPagination?: boolean;
}

const EmailsComp = ({
  user,
  onSelectEmail,
  selectedEmail,
  hasSearch = true,
  hasPagination = true,
  ...rest
}: Properties) => {
  const { t } = usePreferredTranslation();
  const { studyId, studyArmId } = useUser();
  const [search, setSearch] = useState('');
  const listReference = useRef<HTMLUListElement | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [channelEmails, setChannelEmails] = useState<EmailEntity[]>([]);

  const currentUser = useCurrentUser();

  const [createEmailMutation, createEmailMutationVariables] = useCreateEmailMutation();

  const emailsQuery = useEmailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      user: Number(currentUser.data?.user?.id),
      correspondingUser: user?.id ? Number(user.id) : null,
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    skip: !currentUser.data?.user?.id,
  });

  const emails = useMemo<EmailEntity[]>(
    () => [...channelEmails, ...(emailsQuery.data?.emails.items || [])].reverse(),
    [channelEmails, emailsQuery],
  );

  useEffect(() => {
    scrollListToEnd(listReference, false);
  }, [emailsQuery.loading]);

  useEffect(() => {
    scrollListToEnd(listReference, true);
  }, [emails]);

  const count = (emailsQuery.data?.emails.count || 0) + channelEmails.length;

  const emailsChannel = useChannel(PUSHER.CHANNELS.EMAILS);

  useEvent<EmailEntity>(
    emailsChannel,
    `${PUSHER.EVENTS.EMAIL_CREATED}.${currentUser.data?.user?.id}`,
    (email?: EmailEntity) => {
      if (email) {
        setPage(0);
        setChannelEmails([]);
        emailsQuery.refetch();
      }
    },
  );
  useEvent<number>(
    emailsChannel,
    `${PUSHER.EVENTS.EMAILS_UPDATED}.${currentUser.data?.user?.id}`,
    (c?: number) => {
      if (c) {
        setPage(0);
        setChannelEmails([]);
        emailsQuery.refetch();
      }
    },
  );

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setChannelEmails([]);
    setPage(value);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannelEmails([]);
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setChannelEmails([]);
      setPage(0);
    },
    [setSearch, setChannelEmails, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setChannelEmails([]);
    setPage(0);
  }, [setSearch, setChannelEmails, setPage]);

  const handleSubmitting = useCallback(
    async (text: string, attachments: Attachment[]) => {
      const data = await createEmailMutation({
        variables: {
          files: attachments.map((attachment) => attachment.file),
          createEmailInput: {
            user: Number(user?.id),
            title: text,
            studyId,
            studyArmId,
          },
        },
      });

      if (data.errors?.length) {
        toast.error(data.errors[0]?.message || 'Error');
      }

      await emailsQuery.refetch();
    },
    [createEmailMutation, studyArmId, studyId, user?.id],
  );

  const handleResetting = useCallback(() => {}, []);

  return (
    <Box
      {...rest}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      {hasSearch && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasSearch && (
            <TextField
              fullWidth
              placeholder={t('Search...')}
              type="text"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: search && (
                  <IconButton aria-label="Reset search emails..." onClick={handleSearchReset}>
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        </Box>
      )}
      {count === 0 && (currentUser.loading || emailsQuery.loading) ? (
        <EmailCardsPlaceholder />
      ) : (
        <List
          sx={{
            p: 0,
            overflow: 'auto',
          }}
          ref={(r) => {
            listReference.current = r;
          }}
        >
          {emails.length === 0 && (
            <ListItem>
              <Alert sx={{ width: '100%' }} severity="error">
                {t('Nothing found')}
              </Alert>
            </ListItem>
          )}

          {emails?.map((email: EmailEntity) => (
            <ListItem
              key={email.id}
              onClick={() => onSelectEmail(email)}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderColor: theme.palette.divider,
                p: 2,
              }}
            >
              <Email
                owner={String(email.sender?.id) === String(currentUser.data?.user?.id)}
                email={email}
                isGroupChat={emailsQuery.data?.emails.isGroupChat}
              />
            </ListItem>
          ))}
        </List>
      )}
      {hasPagination && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          {hasPagination && (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              labelRowsPerPage={t('Rows per page:')}
              nextIconButtonProps={{
                'aria-label': t('Go to next page'),
                title: t('Go to next page'),
              }}
              backIconButtonProps={{
                'aria-label': t('Go to previous page'),
                title: t('Go to previous page'),
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      )}
      <Controls
        onSubmitting={handleSubmitting}
        onResetting={handleResetting}
        loading={emailsQuery.loading || createEmailMutationVariables.loading}
        sx={{
          border: '1px solid',
          borderLeft: 'none',
          borderBottom: 'none',
          borderRight: 'none',
          borderColor: (t) => t.palette.divider,
        }}
      />
    </Box>
  );
};

const Emails = withValidStudyUsers(EmailsComp);

export { Emails };
