import { useCallback, useEffect, useRef, useState } from 'react';
import {
  register,
  MediaRecorder as ExtendableMediaRecorder,
  IMediaRecorder,
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

import { isTest, logger } from '../../utils';
import { checkMediaPermission } from './utils';
import {
  MediaRecorderHookProperties,
  MediaRecorderProperties,
  MediaRecorderRenderProperties,
  MediaRecorderErrors,
  MediaRecorderStatusMessages,
} from './types';

export function useMediaRecorder({
  audio = true,
  video = false,
  onStop,
  onStart,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions,
  customMediaStream,
  stopStreamsOnStop = true,
  askPermissionOnMount = false,
}: MediaRecorderHookProperties): MediaRecorderRenderProperties {
  const permissionErrorMessage = 'An error occurred while checking media permissions';
  const mediaRecorder = useRef<IMediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<MediaRecorderStatusMessages>('idle');
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [mediaBlob, setMediaBlob] = useState<Blob | null>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [error, setError] = useState<keyof typeof MediaRecorderErrors>('NONE');
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  // Media Recorder Handlers

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStart = () => {
    onStart?.();
  };

  const onError = () => {
    setError('NO_RECORDER');
    setStatus('idle');
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = {
      type: chunk?.type,
      ...(blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' })),
    };
    const blob = new Blob(mediaChunks.current, blobProperty);
    setMediaBlob(blob);

    const url = URL.createObjectURL(blob);
    setMediaBlobUrl(url);

    setStatus('stopped');

    onStop?.(url, blob);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      for (const audioTrack of mediaStream.current.getAudioTracks()) audioTrack.enabled = !mute;
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus('paused');
      mediaRecorder.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      setStatus('recording');
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      setStatus('stopping');
      mediaRecorder.current.stop();
      if (stopStreamsOnStop && mediaStream.current) {
        for (const track of mediaStream.current.getTracks()) track.stop();
      }
      mediaChunks.current = [];
    }
  }, [stopStreamsOnStop]);

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };
    try {
      if (customMediaStream) {
        mediaStream.current = customMediaStream;
      } else if (screen) {
        const stream = await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true,
        });

        const [track] = stream.getVideoTracks();

        track?.addEventListener('ended', () => {
          stopRecording();
        });

        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });

          for (const audioTrack of audioStream.getAudioTracks()) stream.addTrack(audioTrack);
        }
        mediaStream.current = stream;
      } else {
        mediaStream.current = await window.navigator.mediaDevices.getUserMedia(requiredMedia);
      }
      setStatus('idle');
    } catch (error_: any) {
      setError(error_.name);
      setStatus('idle');
    }
  }, [audio, video, customMediaStream, screen, stopRecording]);

  const startRecording = async () => {
    setError('NONE');
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }
      mediaRecorder.current = new ExtendableMediaRecorder(
        mediaStream.current,
        mediaRecorderOptions || undefined,
      );
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onstart = onRecordingStart;
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      mediaRecorder.current.onerror = onError;
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const checkPermission = useCallback(() => {
    return Promise.all([checkMediaPermission('microphone'), checkMediaPermission('camera')]).then(
      ([microphonePermission, cameraPermission]) => {
        const audioPermissionStatus = !audio || microphonePermission === 'granted';
        const videoPermissionStatus = !video || cameraPermission === 'granted';

        return audioPermissionStatus && videoPermissionStatus;
      },
    );
  }, [audio, video]);

  const handleMediaPermissionChange = useCallback(() => {
    return checkPermission().then((permissionStatus) => {
      return setHasPermission(permissionStatus as boolean);
    });
  }, [checkPermission]);

  const getMediaAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video, audio });
    } catch (error_) {
      logger.error('>>> getMediaAccess', error_);
      throw error_;
    }
  };

  useEffect(() => {
    if (!isTest) {
      const setup = async () => {
        try {
          await register(await connect());
        } catch (error_) {
          // eslint-disable-next-line no-console
          console.error('>>> register - connect', error_);
        }
      };

      setup().catch((error_) => {
        logger.error('An error occurred while setting up the media SDK', error_);
      });
    }
  }, []);

  useEffect(() => {
    const getPermissionStatus = async () => {
      if (!navigator.permissions) {
        logger.error('Permissions API is not available');
      }

      const audioPermissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });

      const videoPermissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      audioPermissionStatus.addEventListener('change', handleMediaPermissionChange);
      videoPermissionStatus.addEventListener('change', handleMediaPermissionChange);

      return () => {
        audioPermissionStatus.removeEventListener('change', handleMediaPermissionChange);
        videoPermissionStatus.removeEventListener('change', handleMediaPermissionChange);
      };
    };

    getPermissionStatus().catch((error_) => {
      logger.error(permissionErrorMessage, error_);
    });
  }, [audio, video, screen, handleMediaPermissionChange]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      if (isTest) {
        return;
      }

      throw new Error('Unsupported Browser');
    }

    if (screen && !window.navigator.mediaDevices.getDisplayMedia) {
      if (isTest) {
        return;
      }

      throw new Error("This browser doesn't support screen capturing");
    }

    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();

      const unSupportedConstraints = Object.keys(mediaType).filter(
        (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint],
      );

      if (unSupportedConstraints.length > 0) {
        // eslint-disable-next-line no-console
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ',',
          )} doesn't support on this browser. Please check your MediaRecorder component.`,
        );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }

    if (typeof video === 'object') {
      checkConstraints(video);
    }

    if (
      mediaRecorderOptions &&
      mediaRecorderOptions.mimeType &&
      !ExtendableMediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)
    ) {
      // eslint-disable-next-line no-console
      console.error(
        `The specified MIME type you supplied for MediaRecorder doesn't support this browser`,
      );
    }

    if (!mediaStream.current && askPermissionOnMount) {
      getMediaStream().catch((error_) => {
        logger.error(error_);
      });
    }

    checkPermission()
      .then((permissionStatus) => {
        return setHasPermission(permissionStatus as boolean);
      })
      .catch((checkPermissionsError) => {
        logger.error(permissionErrorMessage, checkPermissionsError);
      });

    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        for (const track of tracks) track.clone().stop();
      }
    };
  }, [
    audio,
    screen,
    video,
    getMediaStream,
    mediaRecorderOptions,
    askPermissionOnMount,
    checkPermission,
  ]);

  return {
    error: MediaRecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlob,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewVideoStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
    previewAudioStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getAudioTracks())
      : null,
    clearBlobUrl: () => {
      if (mediaBlobUrl) {
        URL.revokeObjectURL(mediaBlobUrl);
      }
      setMediaBlob(null);
      setMediaBlobUrl(null);
      setStatus('idle');
    },
    hasPermission,
    getMediaAccess,
  };
}

export const MediaRecorder = (properties: MediaRecorderProperties) =>
  properties.render(useMediaRecorder(properties));

export * from './types';
