export const participantSchedules = [
  {
    id: 16,
    title: 'Chat with David ',
    start: new Date('Fri 23 Jun'),
    end: new Date('Fri 23 Jun'),
    confirmed: 0,
    liked: false,
    viewed: false,
    createdAt: new Date('2023-03-11T13:27:53.480Z'),
    updatedAt: new Date('2023-03-11T13:27:53.480Z'),
  },
];

export const participantSchedulesWithTimeWindow = [
  {
    event: {
      id: 16,
      title: 'Chat with David ',
      start: new Date('Fri 23 Jun'),
      end: new Date('Fri 23 Jun'),
      confirmed: 0,
      liked: false,
      viewed: false,
      createdAt: new Date('2023-03-11T13:27:53.480Z'),
      updatedAt: new Date('2023-03-11T13:27:53.480Z'),
    },
    windowStartTime: new Date('2023-03-11T13:27:53.480Z'),
    windowEndTime: new Date('2023-03-11T13:27:53.480Z'),
    libraryConfig: {
      id: '1',
      library: {
        id: '1',
      },
    },
  },
];
