import React, { useMemo } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { Empty } from 'antd';
import _ from 'lodash';

import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { dayjs } from '../../../../../utils/dayjs';
import { MetricsAdherenceByWeek, getEventIcon, getEventName } from '../../../../../utils/adherence';
import { theme } from '../../../../../theme';

export enum AdherenceTabs {
  All = 'All',
  IVR = 'IVR',
  Coaching = 'Coaching',
  Calls = 'Calls',
}

interface Properties {
  items: MetricsAdherenceByWeek[];
  tab: AdherenceTabs;
}

const filterEventWeeks = (weeks: MetricsAdherenceByWeek[], tab: AdherenceTabs) => {
  if (tab === AdherenceTabs.All) return weeks.filter((week) => week.items.length > 0);

  if (tab === AdherenceTabs.Calls) {
    return weeks
      .map((week) => ({
        ...week,
        items: week.items.filter(
          (event) => event.type === 'phone-call' || event.type === 'video-call',
        ),
      }))
      .filter((week) => week.items.length > 0);
  }

  return weeks
    .map((week) => ({
      ...week,
      items: week.items.filter((event) => event.type === tab.toUpperCase()),
    }))
    .filter((week) => week.items.length > 0);
};

const Week = ({ item, divider }: { item: MetricsAdherenceByWeek; divider: boolean }) => {
  return (
    <Stack direction="row">
      <Stack gap={3} sx={{ width: '180px', px: 1 }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey[800] }}
        >
          {item.key}
        </Typography>

        <Stack gap={2}>
          {item.items.map((event) => {
            const Icon = getEventIcon(event.type, event.status);

            return (
              <Stack key={event.id}>
                <Stack direction="row" gap={1} alignItems="center">
                  {Icon && <Icon />}
                  <Typography
                    variant="body1"
                    sx={{ fontSize: theme.typography.pxToRem(13), fontWeight: 600 }}
                  >
                    {getEventName(event.type)}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{ fontSize: theme.typography.pxToRem(13), fontWeight: 600, opacity: '50%' }}
                >
                  {dayjs(event.startDate).format('MM/DD/YYYY')}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      {divider && <Divider orientation="vertical" flexItem />}
    </Stack>
  );
};

const Timeline: React.FC<Properties> = ({ items, tab }) => {
  const { t } = usePreferredTranslation();
  const filteredWeeks = useMemo(() => filterEventWeeks(items, tab), [items, tab]);

  if (filteredWeeks.length === 0) {
    return (
      <Empty
        style={{ marginTop: '100px' }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={`${t('No Events Found for')} ${_.capitalize(tab)}`}
      />
    );
  }

  return (
    <Stack direction="row" flexGrow={1} gap={2} sx={{ mt: 2, px: 1 }}>
      {filteredWeeks.map((item, index) => (
        <Week key={item.key + index.toString()} item={item} divider={index !== items.length - 1} />
      ))}
    </Stack>
  );
};

export default Timeline;
