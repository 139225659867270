import {
  FilledInputProps,
  FormControl,
  InputLabel,
  InputProps as MUIInputprops,
  OutlinedInputProps,
  Stack,
} from '@mui/material';
import { HTMLAttributes, ReactElement, useCallback, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { theme } from '../../../theme';
import { logger } from '../../../utils';

interface Properties extends HTMLAttributes<HTMLElement> {
  label?: string;
  value?: string | null;
  placeholder?: string;
  spacing?: number;
  sx?: any;
  containerSx?: any;
  labelSx?: any;
  disabled?: boolean;
  disableErrorState?: boolean;
  InputProps?:
    | Partial<MUIInputprops>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
  onChange?: (event: any) => void;
}

export function TimeField({
  label,
  value,
  placeholder,
  sx,
  spacing = 3,
  labelSx,
  containerSx,
  InputProps,
  onChange,
  disabled = false,
  disableErrorState = false,
}: Properties): ReactElement<Properties> {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  const handleChange = useCallback(
    (event_: any) => {
      if (onChange) {
        try {
          setInternalValue(event_);
          onChange(event_);
        } catch (error) {
          logger.error('[Error] in TimeField.onChange:', error);
        }
      }
    },
    [onChange],
  );

  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2, ...containerSx }}>
      <Stack spacing={spacing}>
        {label && (
          <InputLabel
            shrink
            sx={{
              ml: '2px',
              transform: 'scale(1)',
              color: theme.palette.common.black,
              fontWeight: 500,
              ...labelSx,
            }}
          >
            {label}
          </InputLabel>
        )}
        <TimePicker
          disabled={disabled}
          label={placeholder}
          value={internalValue}
          onChange={handleChange}
          InputProps={InputProps}
          renderInput={(parameters) => (
            <TextField
              {...{
                ...parameters,
                ...(disableErrorState ? { error: false } : {}),
                sx,
              }}
            />
          )}
        />
      </Stack>
    </FormControl>
  );
}
