import { ReactElement, ReactNode, useCallback } from 'react';
import { Box, BoxProps, IconButton, Typography, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

import { Attachment } from '../types';
import { proxy } from '../../../../utils';
import { Image } from '../../../atoms';

interface Properties extends BoxProps {
  readonly hasType?: boolean;
  readonly hasName?: boolean;
  readonly hasSize?: boolean;
  readonly hasActions?: boolean;
  readonly renderBefore?: () => ReactNode;
  readonly renderAfter?: () => ReactNode;
  readonly attachments: Attachment[];
  readonly onDelete?: (attachment: Attachment) => void;
}

const getSize = (attachment: Attachment): string => {
  const size = attachment.file?.size || attachment.blob?.size || 0;

  return size < 1_000_000 ? `${Math.floor(size / 1000)} KB` : `${Math.floor(size / 1_000_000)} MB`;
};

const getName = (attachment: Attachment): string => {
  return (
    attachment.file?.name ||
    attachment.blob?.name ||
    attachment.type.charAt(0).toUpperCase() + attachment.type.slice(1)
  );
};

const getType = (attachment: Attachment): string => {
  let value;

  if (attachment?.file) {
    const [, type] = attachment.file.type.split('/');

    value = value || type;
  }

  if (attachment?.blob) {
    const [, type] = attachment.blob.type.split('/');

    value = value || type;
  }

  return value || attachment.type;
};

export function AttachmentsItems({
  hasType = true,
  hasName = true,
  hasSize = true,
  hasActions = true,
  renderBefore,
  renderAfter,
  attachments,
  onDelete,
  children,
  ...rest
}: Properties): ReactElement<Properties> {
  const { t } = usePreferredTranslation();
  const renderType = useCallback((type: string, attachment: Attachment) => {
    const url =
      attachment.url ||
      (attachment.file || attachment.blob
        ? URL.createObjectURL(attachment.file || attachment.blob!)
        : '');

    switch (type) {
      case 'audio':
      case 'mp3':
      case 'mpeg':
      case 'ogg': {
        return (
          <audio controls>
            <track kind="captions" srcLang="en" label={t('Audio')} />
            <source src={proxy(url)} />
          </audio>
        );
      }
      case 'video':
      case 'mp4':
      case 'webm': {
        return (
          <video height="150px" controls>
            <track kind="captions" srcLang="en" label={t('Video')} />
            <source src={proxy(url)} />
          </video>
        );
      }
      case 'image':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'x-icon':
      case 'svg+xml':
      case 'svg':
      case 'webp':
      case 'gif':
      case 'avif':
      case 'apng':
      case 'bmp': {
        return <Image height="150px" alt="Attachment" src={proxy(url)} />;
      }
      default: {
        const size: string = getSize(attachment);
        const name: string = getName(attachment);

        return hasName || hasSize ? (
          <Box display="flex" alignItems="center">
            <FilePresentIcon color="secondary" />
            {hasName && <Typography ml={1}>{name}</Typography>}
            {hasSize && (
              <Typography ml={1} color="secondary" variant="body2">
                ({size})
              </Typography>
            )}
          </Box>
        ) : (
          `${name} (${size})`
        );
      }
    }
  }, []);

  const renderContent = useCallback((attachment: Attachment) => {
    switch (attachment.type) {
      case 'audio':
      case 'video':
      case 'image': {
        return renderType(attachment.type, attachment);
      }
      default: {
        return renderType(getType(attachment), attachment);
      }
    }
  }, []);

  return (
    <Box data-testid="attachmentsItemsComponent" {...rest}>
      {renderBefore?.()}
      <Stack spacing={1}>
        {attachments?.map((attachment: Attachment, index: number) => {
          const size: string = getSize(attachment);
          const name: string = getName(attachment);
          const type: string = getType(attachment);

          return (
            <Box
              sx={{
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                p: 1,
              }}
              key={`${index}-${name}-${size}-${type}`}
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box display="flex">{renderContent(attachment)}</Box>
              </Box>
              {hasActions && (
                <IconButton color="error" onClick={() => onDelete?.(attachment)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          );
        })}
      </Stack>
      {renderAfter?.()}
    </Box>
  );
}
