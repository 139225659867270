import { HTMLAttributes, ReactElement, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';

import StackedIcon from '../../../../assets/journaling/stacked.svg';
import { LibraryEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import { useCurrentUser } from '../../../../states';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  nextStep?: LibraryEntity;
  completedCount?: number;
  onNext?: (nextStep: LibraryEntity) => void;
}

export function RewardPopup({ nextStep, completedCount, onNext }: Properties) {
  const { data } = useCurrentUser();
  const { t } = usePreferredTranslation();

  const handleNext = useCallback(() => {
    if (onNext && nextStep) {
      onNext(nextStep);
    }
  }, [nextStep, onNext]);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <img src={StackedIcon} alt="stacked-icon" />
        <Typography variant="subtitle2" sx={{ color: theme.palette.common.black }}>
          {t('journal.popup.nice_going', { name: data?.user?.name })}
        </Typography>
      </Stack>
      <Typography variant="body2">
        <Typography
          component="span"
          style={{
            color: purple[300],
            fontWeight: 600,
            fontSize: 14,
            textDecoration: 'underline',
          }}
          onClick={handleNext}
        >
          {t('journal.popup.completed_notification', { completedCount: completedCount || 0 })}
        </Typography>
      </Typography>
    </>
  );
}
