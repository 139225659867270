import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom';

import { SvgIconTypeMap, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/system';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { theme } from '../../../theme';

interface Properties {
  onClick: (pathName: string) => void;
  testId?: string;
  label?: string;
  pathName: string;
  SvgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  Icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  renderBadge?: ReactElement<Properties>;
}

export default function Row({
  onClick,
  testId,
  label,
  pathName,
  SvgIcon,
  Icon,
  renderBadge,
}: Properties): ReactElement<Properties> {
  const { pathname } = useLocation();

  const xs = useMediaQuery('(max-width:750px)');
  const rowStyle = xs
    ? {
        ml: 2,
        mr: 2,
        p: 2,
        pl: 0,
        borderBottom: 'dashed',
        borderColor: alpha(theme.palette.common.black, 0.4),
        borderWidth: 1,
      }
    : { sm: 2 };

  return (
    <ListItem data-testid={testId} disablePadding onClick={() => onClick(pathName)}>
      <ListItemButton sx={rowStyle} aria-label={label}>
        {SvgIcon && (
          <SvgIcon
            style={{ marginRight: 16 }}
            color={
              pathname === pathName ? theme.palette.primary.main : theme.palette.text.secondary
            }
          />
        )}
        {Icon && (
          <Icon
            style={{ marginRight: 16 }}
            sx={{
              color:
                pathname === pathName ? theme.palette.primary.main : theme.palette.text.secondary,
            }}
          />
        )}
        <ListItemText
          primaryTypographyProps={{
            sx: {
              fontWeight: 500,
            },
          }}
          primary={label}
          sx={{
            color:
              pathname === pathName ? theme.palette.primary.main : theme.palette.text.secondary,
          }}
        />
        <Box
          sx={{
            alignSelf: 'flex-end',
            pr: xs ? 2 : 1,
            height: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {renderBadge}
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
