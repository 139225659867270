import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import { logger } from '../../utils/logger';

export function useAxios(config: AxiosRequestConfig) {
  const [data, setData] = useState(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;

    const source = axios.CancelToken.source();

    axios({ ...config, cancelToken: source.token })
      .then(({ data }) => {
        if (!unmounted) {
          setData(data);
          setLoading(false);
        }

        return data;
      })
      .catch((error_) => {
        if (!unmounted) {
          setError(error_);
          setLoading(false);
          if (axios.isCancel(error_)) {
            logger.log(`axios error cancelled: ${error_.message}`);
          } else {
            logger.log(`axios error response: ${error_.message}`);
          }
        }
      });

    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [config]);

  return { loading, data, error };
}

export function useLazyAxios(config: AxiosRequestConfig): IRequestHook {
  const [data, setData] = useState(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const sendRequest = useCallback(() => {
    setLoading(true);
    return axios({ ...config })
      .then(({ data }) => {
        setData(data);
        setLoading(false);

        return data;
      })
      .catch((error_) => {
        setError(error_);
        setLoading(false);
        if (axios.isCancel(error_)) {
          logger.log(`axios error cancelled: ${error_.message}`);
        } else {
          logger.log(`axios error response: ${error_.message}`);
        }
      });
  }, [config]);

  return [sendRequest, { loading, data, error }];
}
