import { SVGProps } from 'react';

const RunningSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g clipPath="url(#a)">
      <path
        fill="#6C5DD3"
        d="M21.738 12.393a2.34 2.34 0 0 0 2.334-2.333 2.34 2.34 0 0 0-2.334-2.333 2.34 2.34 0 0 0-2.333 2.333 2.34 2.34 0 0 0 2.333 2.333Zm-4.2 16.217 1.167-5.133 2.45 2.333v7h2.333v-8.75l-2.45-2.333.7-3.5a8.543 8.543 0 0 0 6.417 2.916V18.81c-2.217 0-4.083-1.167-5.017-2.8l-1.166-1.867c-.467-.7-1.167-1.166-1.984-1.166-.35 0-.583.116-.933.116l-6.067 2.567v5.483h2.334v-3.966l2.1-.817-1.867 9.45-5.717-1.167-.466 2.334 8.166 1.633Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6 6h28v28H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default RunningSvg;
