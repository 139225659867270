import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import {
  EmailEntity,
  UserEntity,
  useUpdateEmailMutation,
  useViewAllEmailsMutation,
} from '../../../../graphql';

import { logger } from '../../../../utils';
import { useCurrentUser, useUser } from '../../../../states';
import { UserRoles } from '../../../../constants/users';
import { UsersEmails } from '../UsersEmails';
import { Emails } from '../Emails';
import { EmailDetails } from '../EmailDetails';

interface Properties {
  readonly studyId: number;
  readonly preSelectedUser?: UserEntity;
}

export const EmailsSection = ({ preSelectedUser, studyId }: Properties): ReactElement => {
  const { checkUserRole } = useUser();
  const { data: userData } = useCurrentUser();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);
  const [selectedEmail, setSelectedEmail] = useState<EmailEntity | null>(null);
  const sm = useMediaQuery('(max-width:750px)');

  useEffect(() => {
    if (preSelectedUser) setSelectedUser(preSelectedUser);
  }, [preSelectedUser]);

  const [updateEmailMutation] = useUpdateEmailMutation();

  const [viewAllEmails] = useViewAllEmailsMutation();

  const handleSelectEmail = useCallback(
    (email: EmailEntity | null) => {
      setSelectedEmail(email);

      if (email && !email.viewed) {
        updateEmailMutation({
          variables: {
            id: email.id,
            updateEmailInput: { viewed: true },
          },
        }).catch((error) => {
          logger.error(error);
        });
      }
    },
    [updateEmailMutation],
  );

  const handleSelectUser = useCallback(
    (user: UserEntity | null) => {
      if (user && user.id) setSearchParameters({ userId: user.id });
      setSelectedUser(user);
    },
    [setSearchParameters],
  );

  useEffect(() => {
    if (selectedUser) {
      viewAllEmails({
        variables: {
          user: Number(selectedUser.id),
        },
      }).catch((error) => {
        logger.error(error);
      });
    }
  }, [selectedUser, viewAllEmails]);

  const isStaff =
    checkUserRole(userData?.user, UserRoles.ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.SUPER_ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.COACH);
  return (
    <Paper sx={{ flexGrow: 1 }}>
      <Grid container sx={{ flexGrow: 1, height: '100%' }}>
        {isStaff && (
          <Grid item xs={selectedUser ? 4 : 12} sx={{ height: 'inherit', overflow: 'auto' }}>
            <Box
              sx={{
                textAlign: 'left',
                padding: 0,
                flexGrow: 1,
                overflow: 'auto',
                height: 'calc(100vh - 135px)', // Have a fixed height for content to be scrollable
              }}
            >
              <Box sx={{ overflow: 'auto', height: '100%' }}>
                <UsersEmails
                  hasTabs={false}
                  hasSort={false}
                  selectedUser={selectedUser}
                  onSelectUser={handleSelectUser}
                  studyId={studyId}
                />
              </Box>
            </Box>
          </Grid>
        )}
        {(selectedUser || checkUserRole(userData?.user, UserRoles.PARTICIPANT)) && (
          <Grid
            item
            xs={isStaff ? 6 : 12}
            sx={{
              height: 'calc(100vh - 135px)', // Have a fixed height for content to be scrollable
              border: '1px solid',
              borderTop: 'none',
              borderBottom: 'none',
              borderColor: (theme) => theme.palette.divider,
              marginBottom: sm ? 15 : 0,
            }}
          >
            <Emails
              user={selectedUser ?? undefined}
              selectedEmail={selectedEmail}
              onSelectEmail={handleSelectEmail}
            />
          </Grid>
        )}
        {isStaff && !!selectedUser && (
          <Grid item xs={2}>
            <EmailDetails user={selectedUser} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
