import { combineProviders } from 'react-combine-providers';
import { LayoutProvider } from './layout';
import { UserProvider, UserStatsProvider } from './users/user';
import { UsersProvider } from './users/users';
import { RolesProvider } from './roles/roles';
import { ActivityProvider } from './useActivity';
import { ResearchActivityProvider } from './useResearchActivity';
import { ParticipantMedicationsProvider } from './participant/medications';
import { ParticipantSymptomsProvider } from './participant/symptoms';
import { ParticipantInternalNotesProvider } from './participant/notes';
import { ParticipantActivityProvider } from './participant/activity';
import { ParticipantScheduleProvider } from './participant/schedule';
import { InterventionsProvider } from './interventionsContext';
import { MediaProvider } from './media';
import { StudiesProvider } from './studies/studies';
import { PermissionsProvider } from './permissions/permissions';
import { MessagesAnalyticsProvider } from './messagesAnalytics';
import { VoicesAnalyticsProvider } from './voicesAnalytics';
import { EmailsAnalyticsProvider } from './emailsAnalytics';
import { NotesAnalyticsProvider } from './notesAnalytics';
import { NotificationsAnalyticsProvider } from './notificationsAnalytics';

const provider = combineProviders();

for (const contentProvider of [
  LayoutProvider,
  UserProvider,
  UserStatsProvider,
  UsersProvider,
  ActivityProvider,
  ResearchActivityProvider,
  ParticipantMedicationsProvider,
  ParticipantSymptomsProvider,
  ParticipantInternalNotesProvider,
  ParticipantActivityProvider,
  ParticipantScheduleProvider,
  InterventionsProvider,
  MediaProvider,
  RolesProvider,
  StudiesProvider,
  PermissionsProvider,
  MessagesAnalyticsProvider,
  VoicesAnalyticsProvider,
  EmailsAnalyticsProvider,
  NotesAnalyticsProvider,
  NotificationsAnalyticsProvider,
]) {
  provider.push(contentProvider);
}

// master provider is used to provide the context to all components
export default provider.master();

export { useLayout } from './layout';
export { useUser } from './users/user';
export { useUsers } from './users/users';
export { useActivity } from './useActivity';
export { useResearchActivity } from './useResearchActivity';
export { useUserStats } from './users/user';
export { useParticipantMedications } from './participant/medications';
export { useParticipantSymptoms } from './participant/symptoms';
export { useParticipantInternalNotes } from './participant/notes';
export { useParticipantActivity } from './participant/activity';
export { useParticipantSchedule } from './participant/schedule';
export { useInterventions } from './interventionsContext';
export { useMedia } from './media';
export { useRoles } from './roles/roles';
export { useStudies } from './studies/studies';
export { usePermissions } from './permissions/permissions';
export { useMessagesAnalytics } from './messagesAnalytics';
export { useVoicesAnalytics } from './voicesAnalytics';
export { useEmailsAnalytics } from './emailsAnalytics';
export { useNotesAnalytics } from './notesAnalytics';
export { useNotificationsAnalytics } from './notificationsAnalytics';
