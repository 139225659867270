import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { ReactComponent as MessagesIcon } from '../../../assets/icons/messages.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/icons/schedule.svg';
import { PAGES } from '../../../constants';
import { useNotificationsAnalytics } from '../../../contexts';
import { useCurrentUser } from '../../../states';
import { CompanyLogo } from '../../atoms';
import Row from './Row';

interface Properties extends BoxProps {
  onClose?: () => void;
}

export function SidebarLeftParticipant({ onClose, ...rest }: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:750px)');

  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const notificationsAnalytics = useNotificationsAnalytics();

  const handleNavigation = (path: string) => {
    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      navigate(path);
    }, 100);
  };

  return (
    <Box
      role="navigation"
      aria-label="Sidebar Left"
      data-testid="sidebarLeftComponent"
      sx={{
        flexGrow: 1,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...rest}
    >
      <Stack alignItems="flex-start" spacing={1} sx={{ py: 3, pl: 2 }}>
        {!sm && (
          <IconButton aria-label="Close" onClick={onClose} sx={{ p: 0, mb: 3 }}>
            <CloseIcon />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <CompanyLogo />
        </Box>
      </Stack>

      <Typography
        variant="subtitle2"
        sx={{
          ml: 2,
          color: 'text.secondary',
        }}
      >
        {t('GENERAL')}
      </Typography>

      <List component="div" sx={{ width: '100%' }}>
        <Row
          onClick={handleNavigation}
          pathName={PAGES.HOME}
          label={t('Dashboard')}
          SvgIcon={HomeIcon}
        />
        <Row
          onClick={handleNavigation}
          pathName={PAGES.MESSAGING}
          label={t('Messaging')}
          SvgIcon={MessagesIcon}
        />
        <Row
          onClick={handleNavigation}
          pathName={PAGES.SCHEDULE}
          label={t('Schedule')}
          SvgIcon={ScheduleIcon}
        />
      </List>

      <Box sx={{ mt: 'auto' }}>
        <Typography
          variant="subtitle2"
          sx={{
            ml: 2,
            mt: 5,
            color: 'text.secondary',
          }}
        >
          {t('OTHER')}
        </Typography>

        <List component="div" sx={{ width: '100%' }}>
          <Row
            onClick={handleNavigation}
            pathName={PAGES.NOTIFICATIONS}
            label={t('Notifications')}
            Icon={NotificationsActiveOutlinedIcon}
            renderBadge={
              <Badge badgeContent={notificationsAnalytics.notViewedCount} color="error" />
            }
          />
          <Row
            onClick={handleNavigation}
            pathName={PAGES.SUPPORT}
            label={t('Help and support')}
            Icon={SupportOutlinedIcon}
          />
          <Row
            onClick={handleNavigation}
            pathName={PAGES.SETTINGS}
            label={t('Settings')}
            Icon={SettingsOutlinedIcon}
          />
        </List>

        <Divider />

        {currentUser.data?.user && (
          <ListItem
            data-testid="personalDetailsPageLinkFromSidebar"
            sx={{
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => handleNavigation(PAGES.PERSONAL_DETAILS)}
            disablePadding
          >
            <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2, my: 2 }}>
              <Avatar>{currentUser.data.user.name.charAt(0)}</Avatar>
              <Stack direction="column">
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {currentUser.data.user.name}
                </Typography>
              </Stack>
            </Stack>
          </ListItem>
        )}
      </Box>
    </Box>
  );
}
