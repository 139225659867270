import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { dayjs } from '../../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { ChartsProperties } from './types';

const formatTicks = (value: number) => {
  return value === 0 ? '0' : `${value / 1000}K`;
};

const CustomTooltip = (properties: TooltipProps<ValueType, NameType>) => {
  const { payload } = properties;
  const { t } = usePreferredTranslation();

  const data = payload?.[0];

  if (!data) return null;
  const duration = data.payload[data.dataKey as string];
  const label = data.payload.date;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        Steps ({label})
      </Typography>
      <Typography variant="body1">{Number(duration).toLocaleString()}</Typography>
    </Stack>
  );
};

export const StepsChart: React.FC<ChartsProperties> = ({ data }) => {
  const chartData = useMemo(() => {
    const stepsData = data?.fitbitChartsData.steps;
    const filteredData = _.filter(stepsData, (item) => Number(item.value) > 0);

    return filteredData?.map((item) => ({
      date: dayjs.utc(item.date).format('MM/DD'),
      value: Number(item.value),
    }));
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Tooltip shared={false} cursor={{ fill: 'none' }} content={CustomTooltip} />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatTicks} />
        <Bar dataKey="value" fill="#5E35B1" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};
