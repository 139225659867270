import { Grid, Paper } from '@mui/material';

import { ContentLoader } from '../ContentLoader';
import { IPlaceholderCards } from './types';

export const ReportCardPlaceholder = () => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <Paper sx={{ minWidth: 100, p: 2 }}>
      <ContentLoader
        speed={2}
        width={200}
        height={90}
        viewBox="0 0 200 90"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="5" rx="3" ry="3" width="80" height="10" />
        <rect x="0" y="32" rx="3" ry="3" width="60" height="30" />
        <rect x="0" y="80" rx="3" ry="3" width="100" height="10" />
      </ContentLoader>
    </Paper>
  </Grid>
);

export const ReportCardsPlaceholder = ({ count = 4 }: IPlaceholderCards) => {
  return (
    <>
      {Array.from({ length: count })
        .fill(null)
        .map((_, index) => (
          <ReportCardPlaceholder key={index} />
        ))}
    </>
  );
};

export default ReportCardsPlaceholder;
