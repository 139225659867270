import { dayjs } from '../../../../utils/dayjs';
import { Event, EventByType, EventsTabs } from './types';

const getInfoForNormalEvents = (event: Event) => {
  const endTime = dayjs(event.event.end).valueOf();
  const startTime = dayjs(event.event.start).valueOf();
  const current = dayjs().valueOf();

  const totalDuration = endTime - startTime;
  const currentDuration = endTime - Math.max(startTime, current);

  const duration = (currentDuration / totalDuration) * 100;

  const timeLeft =
    currentDuration < 3_600_000
      ? `${dayjs.duration(currentDuration, 'milliseconds').asMinutes().toFixed(0)}mins`
      : `${dayjs.duration(currentDuration, 'milliseconds').asHours().toFixed(0)}hrs`;

  return {
    duration,
    timeLeft,
  };
};

const getInfoForIvrCoachingEvents = (event: Event) => {
  const windowEndTime = dayjs(event.windowEndTime).valueOf();
  const windowStartTime = dayjs(event.windowStartTime).valueOf();
  const current = dayjs().valueOf();

  const totalDuration = windowEndTime - windowStartTime;
  const currentDuration = windowEndTime - Math.max(windowStartTime, current);

  let duration = 0;
  let timeLeft =
    currentDuration < 3_600_000
      ? `${dayjs.duration(currentDuration, 'milliseconds').asMinutes().toFixed(0)}mins`
      : `${dayjs.duration(currentDuration, 'milliseconds').asHours().toFixed(0)}hrs`;

  if (totalDuration !== 0) {
    duration = (currentDuration / totalDuration) * 100;
  }

  if (duration === 0) {
    const { duration: newDuration, timeLeft: newTimeLeft } = getInfoForNormalEvents(event);
    duration = newDuration;
    timeLeft = newTimeLeft;
  }

  return {
    duration,
    timeLeft,
  };
};

export const getEvents = (events: Event[], tab: EventsTabs) => {
  const activeEvents: EventByType[] = [];
  const pastEvents: EventByType[] = [];
  const upcomingEvents: EventByType[] = [];

  const eventFilters = {
    [EventsTabs.All]: () => true,
    [EventsTabs.Coaching]: ({ event }: Event) => event.type === 'COACHING',
    [EventsTabs.IVR]: ({ event }: Event) => event.type === 'IVR',
  };

  const tabEvents = events.filter(eventFilters[tab] || eventFilters[EventsTabs.All]);

  for (const event of tabEvents) {
    const resp = {
      event: event.event,
      duration: 0,
      timeLeft: '',
    };

    if (event.event.type === 'COACHING' || event.event.type === 'IVR') {
      const { duration, timeLeft } = getInfoForIvrCoachingEvents(event);

      resp.duration = duration;
      resp.timeLeft = timeLeft;
    } else {
      const { duration, timeLeft } = getInfoForNormalEvents(event);

      resp.duration = duration;
      resp.timeLeft = timeLeft;
    }

    if (resp.duration <= 0) {
      pastEvents.push(resp);
    } else if (resp.duration === 100) {
      upcomingEvents.push(resp);
    } else {
      activeEvents.push(resp);
    }
  }

  return { activeEvents, pastEvents, upcomingEvents };
};

export const getBarColor = (duration: number) => {
  if (duration > 50) {
    return '#f44336';
  }

  return '#673AB7';
};
