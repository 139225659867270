import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { AssessmentQuestionEntity } from '../../../graphql';
import { theme } from '../../../theme';
import QuestionNavigation from './QuestionNavigation';
import ResistanceInputIntake from './ResistanceInputIntake';
import ResistanceAnswerPreview from './ResistanceAnswerPreview';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';

interface Properties {
  question: AssessmentQuestionEntity;
  resistanceAnswers?: IResistanceIntakeAnswers[] | [];
  showResistanceAnswerPreview?: boolean;
  questionAnswersMap?: Map<any, any>;
  onChange?: (value: IResistanceIntakeTab[]) => void;
  onAnswer?: (value: IResistanceIntakeAnswers[]) => void;
  onSubmit?: (value: IResistanceIntakeAnswers[]) => void;
  onPrev?: () => void;
  onFinishResistanceAnswerPreview?: () => void;
}

export default function ResistanceInput({
  question,
  showResistanceAnswerPreview,
  resistanceAnswers,
  onChange,
  onAnswer,
  onSubmit,
  onPrev,
  onFinishResistanceAnswerPreview,
  questionAnswersMap,
}: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const [step, setStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [value, setValue] = useState<IResistanceIntakeTab[]>([]);
  const [answers, setAnswers] = useState<IResistanceIntakeAnswers[]>([]);

  const { isEditing } = useActiveJournal();

  useEffect(() => {
    if (!isEditing) return;

    const tabs: IResistanceIntakeTab[] = [];
    const answersMap = new Map<string, IResistanceIntakeAnswers>();

    for (const ques of question?.questions || []) {
      const answer = questionAnswersMap?.get(ques.id);
      if (answer) {
        const tab: IResistanceIntakeTab = {
          question: ques,
          values: Object.keys(answer),
        };
        tabs.push(tab);
        if (answersMap.has(ques.id)) {
          const currentAnswer = answersMap.get(ques.id);
          answersMap.set(ques.id, {
            questionId: ques.id,
            value: { ...currentAnswer?.value, answer },
          });
        } else {
          answersMap.set(ques.id, { questionId: ques.id, value: answer });
        }
      }
    }

    setAnswers([...answersMap.values()]);
    setValue(tabs);
  }, []);

  const handleAnswers = useCallback(
    (newAnswers: IResistanceIntakeAnswers[]) => {
      if (onAnswer) {
        onAnswer(newAnswers);
      }

      setAnswers(newAnswers);
    },
    [onAnswer],
  );

  const handleChange = useCallback(
    (q: AssessmentQuestionEntity, event: any) => {
      let newValue: IResistanceIntakeTab[] = [
        ...value,
        { question: q, values: [event.target.value] },
      ];

      if (value.some((v) => v.question.id === q.id)) {
        newValue = event.target.checked
          ? value.map((v) => {
              if (v.question.id === q.id) {
                return {
                  ...v,
                  values: [...v.values, event.target.value],
                };
              }

              return v;
            })
          : value.map((v) => {
              if (v.question.id === q.id) {
                return {
                  ...v,
                  values: v.values.filter((option) => option !== event.target.value),
                };
              }

              return v;
            });
        if (!event.target.checked) {
          const newAnswers: IResistanceIntakeAnswers[] = answers.map((a) => {
            if (a.questionId === q.id) {
              // Make a copy of the value object
              const newAnswerValue = { ...a.value };
              // Delete the property from the copied object
              delete newAnswerValue[event.target.value];
              return {
                ...a,
                value: newAnswerValue,
              };
            }
            return a;
          });

          handleAnswers(newAnswers);
        }
      }

      if (onChange) {
        onChange(newValue);
      }

      setValue(newValue);
    },
    [onChange, value, answers, handleAnswers],
  );

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(answers);
    }
  }, [onSubmit, answers]);

  const handlePrevious = useCallback(() => {
    setValue([]);
    setStep(0);

    if (onPrev) {
      onPrev();
    }
  }, [onPrev]);

  const handleNext = useCallback(() => {
    setStep(1);
  }, []);

  if (showResistanceAnswerPreview) {
    return (
      <ResistanceAnswerPreview
        answers={resistanceAnswers}
        onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
      />
    );
  }

  if (step === 1 && value) {
    return (
      <>
        <ResistanceInputIntake
          defaultAnswers={answers}
          tabs={value}
          onChange={handleAnswers}
          currentTab={activeTab}
          onActiveTabChanged={(index) => {
            setActiveTab(index);
          }}
        />
        <QuestionNavigation
          submit={activeTab === value.length - 1}
          onPrev={handlePrevious}
          onNext={() => {
            setActiveTab(activeTab + 1);
          }}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {question.questions?.map((q) => {
          let values: string[];
          try {
            values = JSON.parse(q.options || '');
          } catch {
            values = q.options?.split(',') || [];
          }
          const selectedValues = value?.find((v) => v.question.label === q.label);

          return (
            <Accordion
              defaultExpanded
              key={q.id}
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.grey[100],
                my: 2,
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6" component="span">
                    {q.label}
                  </Typography>
                  {selectedValues?.values.length && selectedValues?.values.length > 0 && (
                    <Typography
                      sx={{
                        alignSelf: 'flex-end',
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        borderRadius: 5,
                        pl: 1.5,
                        pr: 1.5,
                        pt: 0.5,
                        pb: 0.5,
                      }}
                    >
                      {selectedValues?.values.length}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ mx: 1 }}>
                  {values.map((option) => (
                    <FormControlLabel
                      key={option}
                      labelPlacement="start"
                      onChange={(event) => handleChange(q, event)}
                      control={
                        <Checkbox checked={!!value.some((v) => v.values.includes(option))} />
                      }
                      label={<Typography sx={{ width: '100%' }}>{option}</Typography>}
                      value={option}
                      sx={{
                        mb: 2,
                        p: 1,
                        width: '100%',
                        backgroundColor: theme.palette.common.white,
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box
          sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
          onClick={() => {
            onSubmit?.([{ questionId: question.id, values: ['DID_NOT_DO_RESISTANCE'] }]);
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              borderRadius: 5,
              pt: 0.5,
              pb: 0.5,
            }}
          >
            {t('journal.exercies.decline')}
          </Typography>
        </Box>
      </Box>
      <QuestionNavigation onPrev={handlePrevious} onNext={handleNext} />
    </>
  );
}
