import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { getToken, storeToken, destroyToken } from '../../utils';
import { useAxios, useLazyAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';
import { IUser, IUserStat } from '../../types/user';

export interface IUserContext {
  readonly token: string | null;
  readonly setToken: (token: string) => void;
  readonly currentUser: IUser | null;
}

export type IUserStatsContext = Array<IUserStat>;

export const UserContext = createContext<IUserContext>({
  token: null,
  setToken(): void {},
  currentUser: null,
});
export const UserStatsContext = createContext<IUserStatsContext>([]);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(getToken());
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  const [getCurrentUser, { data, loading }]: any = useLazyAxios({
    url: ENDPOINTS.CURRENT_USER,
    data: { token },
  });

  useEffect(() => {
    if (token && !currentUser && !loading) {
      getCurrentUser();
    }

    if (!token) {
      setCurrentUser(null);
    }
  }, [currentUser, getCurrentUser, loading, token]);

  useEffect(() => {
    if (data) {
      setCurrentUser(data);
    }
  }, [data, setCurrentUser]);

  const saveToken = (authToken: string | null) => {
    if (authToken) {
      storeToken(authToken);
      setToken(authToken);
    } else {
      // TODO: DESTROY user session in backend
      destroyToken();
      setToken(null);
    }
  };

  const contextValue = useMemo(
    (): IUserContext => ({ token, setToken: saveToken, currentUser }),
    [currentUser, token],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const UserStatsProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USER_STATS });
  const contextValue = useMemo((): Array<IUserStat> => data || [], [data]);

  return <UserStatsContext.Provider value={contextValue}>{children}</UserStatsContext.Provider>;
};

export const useUser = () => useContext(UserContext);
export const useUserStats = () => useContext(UserStatsContext);
