import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { PAIN_LEVEL_MARKS } from '../constant';

const IOSSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    border: `7px solid`,
    backgroundColor: 'white',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#C4C4C4',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#C4C4C4',
    height: 12,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

interface Properties {
  value?: number;
  onChange?: (event: any, newValue: number | number[]) => void;
}

export default function PainLevelControlSlider({ value, onChange }: Properties) {
  return (
    <Box sx={{ maxWidth: 274, width: '100%' }}>
      <IOSSlider
        defaultValue={value || 1}
        min={1}
        max={10}
        marks={PAIN_LEVEL_MARKS}
        valueLabelDisplay="on"
        onChange={onChange}
      />
    </Box>
  );
}
