import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { BodyPainControl } from '../../atoms/Journal';
import { FRONT_SECTIONS, BACK_SECTIONS } from '../../atoms/Journal/constant';
import FrontOriginImg from '../../../assets/journaling/front-origin.svg';
import BackOriginImg from '../../../assets/journaling/back-origin.svg';

interface Properties {
  readonly step?: number;
  onChangeValue?: (value: any) => void;
  handleClearingAnswer?: () => void;
  defaultValue?: any;
}

export function PainControl({
  step = 1,
  onChangeValue,
  defaultValue,
  handleClearingAnswer,
}: Properties) {
  const parsedDefaultValue = defaultValue ? JSON.parse(defaultValue.join(',')) : [];
  const [value, setValue] = useState<any[]>(parsedDefaultValue ?? []);
  const handleChange = (property: any) => {
    const newValue = [...value.filter((v) => v.id !== property.id), property];
    setValue(newValue);
    onChangeValue?.(newValue);
  };

  const handleDelete = useCallback(
    (id: string) => {
      const newValue = value.filter((v) => v.id !== id);

      setValue(newValue);
      if (newValue.length === 0) {
        handleClearingAnswer?.();
      } else {
        onChangeValue?.(newValue);
      }
    },
    [setValue, value, onChangeValue, handleClearingAnswer],
  );

  return (
    <Stack sx={{ position: 'relative', width: '100%', alignItems: 'center' }} alignItems="center">
      {step === 1 && (
        <BodyPainControl
          defaultSections={FRONT_SECTIONS}
          originImg={FrontOriginImg}
          onChangeValue={handleChange}
          value={value}
          handleDelete={handleDelete}
        />
      )}
      {step === 2 && (
        <BodyPainControl
          defaultSections={BACK_SECTIONS}
          originImg={BackOriginImg}
          onChangeValue={handleChange}
          value={value}
          handleDelete={handleDelete}
        />
      )}
    </Stack>
  );
}
