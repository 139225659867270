import { SVGProps } from 'react';

const IVRCallSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...properties}>
    <path
      fill="#43A047"
      d="M6.18 9.16a3.09 3.09 0 1 0 0-6.181 3.09 3.09 0 0 0 0 6.18ZM6.181 10.704C4.118 10.704 0 11.74 0 13.794v1.546h12.362v-1.545c0-2.055-4.118-3.09-6.181-3.09Zm5.996-7.448-1.298 1.306a2.563 2.563 0 0 1 0 3.005l1.298 1.306c1.56-1.56 1.56-3.917 0-5.617ZM14.734.66l-1.26 1.26c2.14 2.333 2.14 5.84 0 8.298l1.26 1.259c3.013-3.006 3.021-7.688 0-10.817Z"
    />
  </svg>
);
export default IVRCallSvg;
