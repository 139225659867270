import { MutableRefObject, useEffect, useRef } from 'react';

export function useClickOutside(
  elementReference: MutableRefObject<HTMLElement>,
  callback: () => void,
) {
  const callbackReference: MutableRefObject<() => void> = useRef<() => void>(callback);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      event.preventDefault();
      if (
        elementReference?.current &&
        !elementReference.current.contains(event.target as Node | null)
      ) {
        callbackReference.current();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [elementReference, callback]);
}
