import './style.scss';

import { Button, Popover, Stack, Typography } from '@mui/material';
import { HTMLAttributes, MouseEvent, ReactElement, useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/system';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { InfoModal } from '../InfoModal';
import { theme } from '../../../../theme';

const StyledButton = styled(Button)(() => ({
  color: theme.palette.grey['500'],
  background: '#FAFAFA',
  '&:hover': { background: '#F0F0F0' },
  height: '3rem',
  borderTop: '1px solid',
  borderColor: 'var(--light-text-primary-shades-30-p, rgba(0, 0, 0, 0.30))',
}));

interface Properties extends HTMLAttributes<ReactElement> {
  title: string;
  onChangeValue: (amount: number) => void;
  imageSource?: string;
  incrementLabel?: string;
  min?: number;
  max?: number;
  stepSize?: number;
  initialValue?: number;
  infoPopover?: {
    displayTitle?: string;
    thumbnail?: string;
    description?: string;
    showCloseButton?: boolean;
  };
}

export function IncrementPanel({
  imageSource,
  title,
  incrementLabel,
  min = 0,
  max = 128,
  stepSize = 1,
  initialValue,
  onChangeValue,
  infoPopover,
}: Properties) {
  const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(null);
  const [value, setValue] = useState<number>(initialValue ?? min);
  const { t } = usePreferredTranslation();
  const adjustAmount = useCallback(
    (offset: number) => {
      const newValue = Math.min(max, Math.max(min, value + Number(offset)));
      setValue(newValue);
      onChangeValue(newValue);
    },
    [max, min, onChangeValue, value],
  );
  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const showInfoPopOver = !!(infoPopover?.displayTitle || infoPopover?.description);
  return (
    <Stack
      direction="row"
      className="increment-panel-wrapper"
      border={1}
      borderColor="var(--light-text-primary-shades-30-p, rgba(0, 0, 0, 0.30))"
      p={0}
      borderRadius="8px"
      sx={{ overflow: 'hidden', background: '#FFF', mb: 1 }}
    >
      <Grid container spacing={3} alignItems="center" py={2} pl={4} pr={4}>
        <Grid item>
          <Avatar alt="Image" src={imageSource} sx={{ width: 56, height: 56 }} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            <Grid item>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ flexWrap: 'wrap' }}
              >
                <Typography variant="h6">{title}</Typography>
                {showInfoPopOver && (
                  <>
                    <InfoIcon
                      color="primary"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      sx={{ cursor: 'pointer' }}
                    />
                    <Popover
                      id="info-popover"
                      sx={{ pointerEvents: 'none' }}
                      open={open}
                      anchorEl={anchorElement}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      disableRestoreFocus
                      PaperProps={{
                        style: {
                          boxShadow:
                            '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
                          borderRadius: '10px',
                        },
                      }}
                    >
                      <InfoModal
                        displayTitle={infoPopover?.displayTitle}
                        example={infoPopover?.description}
                        thumbnail={infoPopover?.thumbnail}
                        showCloseButton={infoPopover?.showCloseButton}
                      />
                    </Popover>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item>
              <Chip
                size="small"
                label={`${value} ${incrementLabel || 'Units'}`}
                sx={{ backgroundColor: 'grey.100' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="column" className="action-buttons">
        <StyledButton
          onClick={() => adjustAmount(stepSize)}
          sx={{ height: '50%', borderRadius: '0', borderLeft: '1px solid', borderTop: 'none' }}
        >
          <AddIcon />
        </StyledButton>
        <StyledButton
          onClick={() => adjustAmount(-stepSize)}
          sx={{
            height: '50%',
            borderRadius: '0',
            borderLeft: '1px solid',
          }}
        >
          <RemoveIcon />
        </StyledButton>
      </Stack>
    </Stack>
  );
}
