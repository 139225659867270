import { ReactElement, useCallback } from 'react';

import { Grid } from '@mui/material';
import { AssessmentQuestionEntity } from '../../../graphql';
import CardioInputIntake from './CardioInputIntake';
import HydrationQuestion from './HydrationQuestion';
import { IncrementPanel } from '../../atoms/Journal';
import { PainControl } from '../pain';
import RadioButtonGrid from '../../atoms/RadioButtonList/RadioButtonGrid';
import ResistanceInput from './ResistanceInput';
import {
  CheckboxField,
  DropdownField,
  InputField,
  RadioField,
  TextareaField,
  TimeField,
} from '../../atoms';
import ResistanceExercise from './ResistanceExercise';
import { useCurrentUser } from '../../../states';
import { getDateTimeStringBasedOnTimezone } from '../../../utils';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import QuestionTypes from '../../organisms/EditableQuestion/QuestionTypes';
import { BlockSelector } from '../../atoms/BlockSelector';
import { BLOCK_SELECTOR_TYPES } from '../../callnotes/Question';
import { PlusMinusField } from '../../atoms/InputField/PlusMinusField';
import { SliderField } from '../../atoms/SliderField';
import { ImageSelector } from '../../atoms/ImageSelector';

interface QuestionProperties {
  question: AssessmentQuestionEntity;
  resistanceAnswers?: IResistanceIntakeAnswers[] | [];
  showResistanceAnswerPreview?: boolean;
  onChange?: (values: IAnswer[]) => void;
  onCategoryChange?: (question: AssessmentQuestionEntity, values: IAnswer[]) => void;
  onSubmitResistance?: (values: IResistanceIntakeAnswers[]) => void;
  onSubmitCardio?: (values: ICardioInput) => void;
  onPrev: () => void;
  onFinishResistanceAnswerPreview?: () => void;
  questionAnswersMap?: Map<any, any>;
  cardioValue?: any;
  scrollableList?: boolean;
}

const QuestionInput = ({
  question,
  resistanceAnswers,
  showResistanceAnswerPreview,
  onChange,
  onCategoryChange,
  onSubmitResistance,
  onSubmitCardio,
  onPrev,
  onFinishResistanceAnswerPreview,
  questionAnswersMap,
  cardioValue,
  scrollableList,
}: QuestionProperties): ReactElement | null => {
  const { answers, updateAnswersCollection, answersCollection, updateAnswers } = useActiveJournal();
  const { data: currentUserData } = useCurrentUser();

  const onQuestionAnswered = useCallback(
    (newValue: any) => {
      const answer: IAnswer = {
        questionId: question.id,
        values: [newValue],
      };

      if (onChange) {
        onChange([answer]);
      }
    },
    [onChange, question.id],
  );

  const onMultiQuestionAnswered = useCallback(
    (value: string[]) => {
      const previousAnswer = answersCollection.find((a) => a.questionId === question.id);
      const previousValues = ((previousAnswer?.values || []) as IAnswer[]).flatMap((v) => v.values);

      const newValue = [...(previousValues || []), ...value] as string[];

      const answer: IAnswer = {
        questionId: question.id,
        values: newValue,
      };

      if (onChange) {
        onChange([answer]);
      }
    },
    [onChange, question.id, answersCollection],
  );

  const onTimeQuestionAnswered = useCallback(
    (newValue: any) => {
      const time = getDateTimeStringBasedOnTimezone(newValue, currentUserData?.user?.timezone);
      onQuestionAnswered(time);
    },
    [onQuestionAnswered, currentUserData?.user?.timezone],
  );

  const handleQuestionCategoryAnswer = useCallback(
    (question_: AssessmentQuestionEntity, values: IAnswer[]) => {
      const previousValues =
        (answers?.values as IAnswer[])?.filter((q: any) => q.questionId !== question_.id) ?? [];

      const newValue = [...previousValues, ...values];

      if (onCategoryChange) {
        onCategoryChange(question_, newValue);
      }
    },
    [onCategoryChange, answers],
  );

  const handleSubmitResistance = useCallback(
    (v: IResistanceIntakeAnswers[]) => {
      if (onSubmitResistance) {
        onSubmitResistance(v);
      }
    },
    [onSubmitResistance],
  );

  const handleSubmitCardio = useCallback(
    (v: ICardioInput) => {
      if (onSubmitCardio) {
        onSubmitCardio(v);
      }
    },
    [onSubmitCardio],
  );

  const handlePrevious = useCallback(() => {
    if (onPrev) {
      onPrev();
    }
  }, [onPrev]);

  const handleClearBodyPainValue = useCallback(() => {
    if (scrollableList) {
      updateAnswersCollection(answersCollection.filter((a) => a.questionId !== question.id));
    } else {
      updateAnswers({ questionId: question.id, values: [] });
    }
  }, [answersCollection, updateAnswers, updateAnswersCollection, scrollableList, question.id]);

  if (!question) {
    return null;
  }

  const value = questionAnswersMap?.get(question.id);

  const activeAnswerCollection = answersCollection.find((a) => a.questionId === question.id);
  const activeAnswer = question.id === answers?.questionId ? answers?.values?.[0] : undefined;

  // For multiple questions we store answers in answersCollection otherwise in answers
  const activeValue = (activeAnswerCollection?.values[0] ?? activeAnswer) as IAnswer;

  switch (question.type) {
    case 'datetime': {
      const timeValue = value?.[0];
      return (
        <TimeField onChange={onTimeQuestionAnswered} {...(timeValue ? { value: timeValue } : {})} />
      );
    }
    case 'custom-plus-minus': {
      return (
        <IncrementPanel
          key={question.id}
          imageSource={question.icon || ''}
          title={question.label}
          incrementLabel={question.meta?.units}
          min={question.meta?.minValue}
          max={question.meta?.maxValue}
          stepSize={question.meta?.stepSize}
          initialValue={value?.[0] ? Number(value[0]) : 0}
          onChangeValue={onQuestionAnswered}
          infoPopover={{
            displayTitle: question.tooltip?.title,
            thumbnail: question.tooltip?.icon || '',
            description: question.tooltip?.description || '',
          }}
        />
      );
    }
    case 'human-body': {
      return (
        <PainControl
          step={question.label.includes('back') || question.meta?.showHumanBodyBack ? 2 : 1}
          onChangeValue={onQuestionAnswered}
          handleClearingAnswer={handleClearBodyPainValue}
          defaultValue={value}
        />
      );
    }
    case 'hydration': {
      return (
        <HydrationQuestion
          min={question.meta?.minValue}
          max={question.meta?.maxValue}
          defaultValue={value?.[0] ? Number(value[0]) : question.meta?.default}
          onChangeValue={onQuestionAnswered}
        />
      );
    }
    case 'radio': {
      let options;
      if (question.options) {
        try {
          options = JSON.parse(question.options);
        } catch {
          options = question.options.split(',');
        }
      }

      return (
        <RadioButtonGrid
          key={question.id}
          value={value?.[0]}
          {...question}
          options={options}
          onChange={onQuestionAnswered}
        />
      );
    }
    case 'cardio': {
      return (
        <CardioInputIntake
          onChange={onQuestionAnswered}
          onPrev={handlePrevious}
          onSubmit={handleSubmitCardio}
          value={cardioValue}
        />
      );
    }
    case 'resistance-exercise-header': {
      return (
        <ResistanceExercise
          question={question}
          onChange={onQuestionAnswered}
          onSubmit={handleSubmitResistance}
          onPrev={handlePrevious}
          resistanceAnswers={resistanceAnswers}
          showResistanceAnswerPreview={showResistanceAnswerPreview}
          onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
          questionAnswersMap={questionAnswersMap}
        />
      );
    }
    case 'functional':
    case 'flexibility':
    case 'resistance': {
      return (
        <ResistanceInput
          question={question}
          onChange={onQuestionAnswered}
          onSubmit={handleSubmitResistance}
          onPrev={handlePrevious}
          resistanceAnswers={resistanceAnswers}
          showResistanceAnswerPreview={showResistanceAnswerPreview}
          onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
          questionAnswersMap={questionAnswersMap}
        />
      );
    }
    case 'question': {
      if (question.questions && question.questions.length > 0)
        return (
          <>
            {question.questions.map((q) => (
              <Grid item xs={6} key={q.id}>
                <QuestionInput
                  question={q}
                  onChange={(newValue) => {
                    handleQuestionCategoryAnswer(q, newValue);
                  }}
                  onSubmitResistance={handleSubmitResistance}
                  onSubmitCardio={handleSubmitCardio}
                  onPrev={handlePrevious}
                  questionAnswersMap={questionAnswersMap}
                  cardioValue={cardioValue}
                  scrollableList={scrollableList}
                />
              </Grid>
            ))}
          </>
        );
      return null;
    }
    case QuestionTypes.range10:
    case QuestionTypes.range5: {
      const rangeValue = activeValue?.values[0] || '';

      return (
        <BlockSelector
          key={question.id}
          {...question}
          value={rangeValue}
          onChange={onQuestionAnswered}
          options={BLOCK_SELECTOR_TYPES[question.type] || []}
          hideLabel
        />
      );
    }
    case QuestionTypes.text: {
      const textValue = activeValue?.values[0] || '';

      return (
        <TextareaField
          key={question.id}
          {...question}
          value={textValue}
          onChange={(event) => onQuestionAnswered(event.target.value)}
          hideLabel
        />
      );
    }
    case QuestionTypes.select: {
      const selectValue = activeValue?.values[0] || '';

      return (
        <DropdownField
          data-testid={`dropdown-question-${question.id}`}
          key={question.id}
          {...question}
          value={selectValue}
          onChange={(event) => {
            onQuestionAnswered(event.target.value);
          }}
          options={question.options?.split(',')}
          hideLabel
        />
      );
    }
    case 'checkbox':
    case QuestionTypes.multiselect: {
      const multiSelectValues = activeValue?.values || [];
      return (
        <CheckboxField
          key={question.id}
          {...question}
          options={question.options?.split(',')}
          value={multiSelectValues}
          onChange={(checkedValues: string[]) => onMultiQuestionAnswered(checkedValues || [])}
          hideLabel
        />
      );
    }
    case 'input': {
      const inputValue = activeValue?.values[0] || '';

      return (
        <InputField
          key={question.id}
          {...question}
          type={question.type}
          value={inputValue}
          onChange={(event) => {
            onQuestionAnswered(event.target.value);
          }}
          sx={{ pt: 3 }}
          labelSx={{ display: 'none' }}
        />
      );
    }
    case QuestionTypes.boolean: {
      const booleanValue = activeValue?.values[0] || '';

      return (
        <RadioField
          {...question}
          key={question.id}
          options={
            JSON.parse(question.options || '[]').length === 0
              ? ['Yes', 'No']
              : JSON.parse(question.options || '[]')
          }
          value={booleanValue}
          onChange={(event) => onQuestionAnswered(event.target.value)}
          hideLabel
        />
      );
    }
    case QuestionTypes.plusMinus: {
      const plusMinusValue = activeValue?.values[0] || '';

      return (
        <PlusMinusField
          key={question.id}
          {...question}
          value={plusMinusValue}
          onChange={(value: string) => onQuestionAnswered(value)}
          hideLabel
        />
      );
    }
    case QuestionTypes.slider: {
      const sliderValue = activeValue?.values[0] || '';

      return (
        <SliderField
          key={question.id}
          {...question}
          value={sliderValue}
          onChange={(value: string) => onQuestionAnswered(value)}
          hideLabel
        />
      );
    }
    case QuestionTypes.imagePicker: {
      const imagePickerValue = activeValue?.values[0] || '';
      return (
        <ImageSelector
          key={question.id}
          {...question}
          value={imagePickerValue}
          onChange={(value: string) => onQuestionAnswered(value)}
          options={question.options?.split(',') || []}
          hideLabel
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default QuestionInput;
