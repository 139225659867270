import { create } from 'zustand';
import { BACK_SECTIONS, FRONT_SECTIONS } from '../../atoms/Journal/constant';

export interface ISectionProperty {
  id: string;
  name: string;
  i18nKey: string;
  painLevel: number;
}

export interface ISectionCollection {
  [section: string]: ISectionProperty;
}

export interface ICreatePainSectionsProperties {
  painSections: ISectionCollection;
  setSections: (value: ISectionProperty) => void;
  setMultipleSections: (values: ISectionProperty[]) => void;
  resetSections: () => void;
}

export const generateBasicSections = () => {
  return Object.fromEntries(
    [...FRONT_SECTIONS, ...BACK_SECTIONS].map((section) => [
      section.id,
      {
        id: section.id,
        name: section.name,
        i18nKey: section.i18nKey,
        painLevel: 1,
      },
    ]),
  );
};

export const usePainSectionsStore = create<ICreatePainSectionsProperties>((set) => ({
  painSections: generateBasicSections(),
  setSections: (value: ISectionProperty) =>
    set((state) => ({
      painSections: { ...state.painSections, [value.id]: value },
    })),
  resetSections: () =>
    set(() => ({
      painSections: generateBasicSections(),
    })),
  setMultipleSections: (values: ISectionProperty[]) =>
    set((state) => {
      const newPainSections = { ...state.painSections };
      for (const value of values) {
        newPainSections[value.id] = value;
      }
      return { painSections: newPainSections };
    }),
}));
