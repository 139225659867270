import { Stack, alpha } from '@mui/system';
import { useEffect, useState } from 'react';
import { InputField } from '../../../../../atoms';
import { theme } from '../../../../../../theme';
import { useActiveJournal } from '../../../../../../states/journal/useActiveJournal';
import { usePreferredTranslation } from '../../../../../../hooks/usePreferredTranslation';

const arrayRange = (stop: number, step = 1, start = 1) =>
  Array.from({ length: stop + 1 - start || 1 / step + 1 }, (_, index) => start + index * step);

interface Properties {
  defaultValue?: any;
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
}

export const ResistanceFreeWeightInput = ({ defaultValue, onChange }: Properties) => {
  const [selectedSets, setSelectedSets] = useState<number | null>(null);
  const [weightValues, setWeightValues] = useState<any>({});

  const { isEditing } = useActiveJournal();
  const { t } = usePreferredTranslation();

  useEffect(() => {
    if (!isEditing) return;

    setSelectedSets(defaultValue?.sets);
    setWeightValues(defaultValue?.values ?? {});
  }, []);

  const handleChange = (values: any) => {
    onChange?.(values);
  };

  const handleSetsChange = (event_: any) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      setSelectedSets(0);
      return;
    }
    setSelectedSets(Number(event_.target.value));
    const newWeightValues = {} as any;
    for (let index = 0; index < Number(event_.target.value); index += 1) {
      newWeightValues[index] = weightValues[index];
    }
    setWeightValues(newWeightValues);

    handleChange({ sets: Number(value), values: newWeightValues });
  };

  const handleWeightChange = (event_: any, index: number) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      const values: any = weightValues[String(index)] || {};
      setWeightValues({
        ...weightValues,
        [index]: { ...values, lbs: 0 },
      });
      return;
    }
    const values: any = weightValues[String(index)] || {};
    const newWeightValues = { ...weightValues, [index]: { ...values, lbs: value } };
    setWeightValues(newWeightValues);

    handleChange({ values: newWeightValues });
  };

  const handleRepsChange = (event_: any, index: number) => {
    const { value } = event_.target;
    if (value === '' || !/^\d+$/.test(value)) {
      const values: any = weightValues[String(index)] || {};
      setWeightValues({
        ...weightValues,
        [index]: { ...values, reps: 0 },
      });
      return;
    }
    const values: any = weightValues[String(index)] || {};
    const newWeightValues = { ...weightValues, [index]: { ...values, reps: value } };
    setWeightValues(newWeightValues);

    handleChange({ values: newWeightValues });
  };

  return (
    <>
      <Stack sx={{ mb: 1.5, backgroundColor: theme.palette.common.white }}>
        <InputField
          labelSx={{ maxHeight: 0 }}
          wrapperSx={{ mb: 0 }}
          style={{
            marginTop: 0,
            borderColor: alpha('#000', 0.23),
            borderRadius: 4,
          }}
          placeholder={t('journal.select.no_of_sets')}
          type="number"
          value={selectedSets?.toString()}
          onChange={handleSetsChange}
          endAdornment={selectedSets === null ? '' : t('journal.select.reps')?.toLowerCase()}
        />
      </Stack>
      {Boolean(selectedSets && selectedSets > 0) &&
        arrayRange(Number(selectedSets)).map((ele, index) => (
          <Stack
            sx={{ mb: 1.5 }}
            gap={2}
            direction="row"
            key={ele}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                border: '1px solid rgba(0, 0, 0, 0.20)',
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: theme.palette.common.white,
                color: '#000',
              }}
            >
              {index + 1}
            </Stack>
            <Stack
              sx={{ width: '100%', m: 'auto', p: 0 }}
              gap={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <InputField
                labelSx={{ maxHeight: 0 }}
                wrapperSx={{ mb: 0 }}
                style={{
                  marginTop: 0,
                  borderColor: alpha('#000', 0.23),
                  borderRadius: 4,
                }}
                placeholder={t('journal.select.lbs')}
                type="number"
                value={weightValues[index]?.lbs || 0}
                onChange={(event_) => handleWeightChange(event_, index)}
                endAdornment={selectedSets === null ? '' : t('journal.select.lbs')?.toLowerCase()}
              />
              <InputField
                labelSx={{ maxHeight: 0 }}
                wrapperSx={{ mb: 0 }}
                style={{
                  marginTop: 0,
                  borderColor: alpha('#000', 0.23),
                  borderRadius: 4,
                }}
                placeholder={t('journal.select.no_of_reps')}
                type="number"
                value={weightValues?.[index]?.reps || 0}
                onChange={(event_) => handleRepsChange(event_, index)}
                endAdornment={selectedSets === null ? '' : t('journal.select.reps')?.toLowerCase()}
              />
            </Stack>
          </Stack>
        ))}
    </>
  );
};
