import { Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';

export type StepperNavItemProperties = {
  id?: number;
  stepNumber?: number;
  status?: 'default' | 'success' | 'processing';
  depth?: 0 | 1;
  label?: string;
  expandable?: boolean;
  showing?: boolean;
};

export type StepperNavProperties = {
  step?: StepperNavItemProperties;
  activeStep?: StepperNavItemProperties;
  onSelectStep?: (step: StepperNavItemProperties) => void;
};

export const StepperNavItem = ({ step, activeStep, onSelectStep }: StepperNavProperties) => {
  const theme = useTheme();
  const {
    stepNumber,
    status = 'default',
    depth = 0,
    label = '',
  } = step as StepperNavItemProperties;

  const NAV_STATUS = {
    default: {
      color: 'secondary.main',
      textColor: 'secondary.main',
      bgColor:
        activeStep?.stepNumber === stepNumber && depth === 0
          ? alpha(theme.palette.primary.main, 0.25)
          : 'transparent',
      icon: depth ? <FiberManualRecordIcon sx={{ fontSize: theme.typography.pxToRem(6) }} /> : null,
    },
    success: {
      color: green[700],
      textColor: theme.palette.common.black,
      bgColor:
        activeStep?.stepNumber === stepNumber && depth === 0
          ? alpha(theme.palette.primary.main, 0.25)
          : 'transparent',
      icon: <CheckIcon sx={{ fontSize: theme.typography.pxToRem(14) }} />,
    },
    processing: {
      color: theme.palette.primary.main,
      textColor: theme.palette.primary.main,
      bgColor:
        activeStep?.stepNumber === stepNumber && depth === 0
          ? alpha(theme.palette.primary.main, 0.25)
          : 'transparent',
      icon: <FiberManualRecordIcon sx={{ fontSize: theme.typography.pxToRem(6) }} />,
    },
  } as {
    [key: string]: any;
  };

  if (activeStep?.stepNumber !== stepNumber && depth === 1) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: theme.typography.pxToRem(15),
        py: theme.typography.pxToRem(11),
        borderRadius: theme.typography.pxToRem(8),
        background: NAV_STATUS[status].bgColor,
        cursor: 'pointer',
      }}
      onClick={() => onSelectStep && onSelectStep(step!)}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: theme.typography.pxToRem(18),
          height: theme.typography.pxToRem(18),
          color: NAV_STATUS[status].color,
          borderColor: NAV_STATUS[status].color,
          borderWidth: 2,
          borderStyle: depth ? 'none' : 'solid',
          borderRadius: '50%',
          paddingLeft: theme.typography.pxToRem(depth * 32),
          marginRight: theme.typography.pxToRem(15),
        }}
      >
        {NAV_STATUS[status].icon}
      </Stack>
      <Typography
        variant="body2"
        sx={{
          color: NAV_STATUS[status].textColor,
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default StepperNavItem;
