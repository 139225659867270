export const participantSymptoms = [
  {
    id: '007f1f77bcf86cd799439011',
    label: 'Blurred Vision',
    description: 'Severe for three days in week two',
    frequency: 4,
    userId: 11,
    updatedAt: new Date('Mon, 22 Jun'),
    createdAt: new Date('Mon, 22 Jun'),
  },
  {
    id: '107f1f77bcf86cd799439011',
    label: 'Nausea',
    description: 'Grade 3 for one day',
    frequency: 8,
    userId: 11,
    updatedAt: new Date('Mon, 22 Jun'),
    createdAt: new Date('Mon, 22 Jun'),
  },
  {
    id: '207f1f77bcf86cd799439011',
    label: 'Pain',
    description: 'Higher than usual this month',
    frequency: 1,
    userId: 11,
    updatedAt: new Date('Mon, 22 Jun'),
    createdAt: new Date('Mon, 22 Jun'),
  },
];
