import { Alert, Box, BoxProps, List, ListItem, TablePagination } from '@mui/material';
import { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';

import { UserEntity, useCallConferencesQuery } from '../../../../graphql';
import { useCurrentUser, useUser } from '../../../../states';
import { theme } from '../../../../theme';
import { VoiceCardsPlaceholder } from '../../../atoms';

import { withValidStudyUsers } from '../../../hoc/withValidStudyUsers';
import { RecordingItem } from '../RecordingItem';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends BoxProps {
  readonly user?: UserEntity;
  readonly hasPagination?: boolean;
}

const RecordingsComp = ({ user, hasPagination = true, ...rest }: Properties) => {
  const { studyId } = useUser();
  const listReference = useRef<HTMLUListElement | null>(null);
  const { t } = usePreferredTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentUser = useCurrentUser();

  const { data: conferencesData, loading } = useCallConferencesQuery({
    fetchPolicy: 'network-only',
    variables: {
      correspondingUser: Number(user?.id),
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    skip: !currentUser.data?.user?.id,
  });

  useEffect(() => {
    if (listReference.current) {
      listReference.current.scrollTop = listReference.current.scrollHeight;
    }
  }, [loading]);

  const count = conferencesData?.callConferences.count || 0;

  const conferences = useMemo(() => {
    if (!conferencesData?.callConferences.items) return [];
    return (
      conferencesData.callConferences.items
        // .filter((item) => item.recording && 'recordingUrl' in item.recording)
        .reverse()
    );
  }, [conferencesData?.callConferences.items]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}
      {...rest}
    >
      {count === 0 && (currentUser.loading || loading) ? (
        <VoiceCardsPlaceholder />
      ) : (
        <List
          sx={{ p: 0, overflow: 'auto' }}
          ref={(r) => {
            listReference.current = r;
          }}
        >
          {conferences.length === 0 && (
            <ListItem>
              <Alert sx={{ width: '100%' }} severity="error">
                {t('Nothing found')}
              </Alert>
            </ListItem>
          )}

          {conferences?.map((conference) => (
            <ListItem
              key={conference.id}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderColor: theme.palette.divider,
                p: 2,
              }}
            >
              <RecordingItem
                owner={Number(conference.fromUser?.id) === Number(currentUser.data?.user?.id)}
                recording={{
                  createdAt: conference.createdAt,
                  url: conference.recording?.recordingUrl || '',
                  type:
                    conference.type === 'video-call'
                      ? 'video'
                      : conference.type === 'phone-call'
                      ? 'audio'
                      : 'unknown',
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
      {hasPagination && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-end',
          }}
        >
          {hasPagination && (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              labelRowsPerPage={t('Rows per page:')}
              nextIconButtonProps={{
                'aria-label': t('Go to next page'),
                title: t('Go to next page'),
              }}
              backIconButtonProps={{
                'aria-label': t('Go to previous page'),
                title: t('Go to previous page'),
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const Recordings = withValidStudyUsers(RecordingsComp);

export { Recordings };
