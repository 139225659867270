import { ReactNode } from 'react';
import { ToastPosition } from 'react-toastify';

export enum ToastType {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface ToastBaseProperties {
  type?: ToastType;
}

export interface ToastBodyProperties {
  title: ReactNode | string;
  description: ReactNode | string;
}

export interface ToastButtonProperties extends ToastBaseProperties, ToastBodyProperties {
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  label: string;
}

export interface ToastProperties extends ToastBaseProperties {
  position?: ToastPosition;
  hideProgressBar?: boolean;
  limit?: number;
  autoCloseDelay?: number;
}

export interface UseToastProperties extends ToastBaseProperties {
  content: ReactNode;
}
