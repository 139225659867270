export const SEXUAL_ORIENTATIONS = ['Male', 'Female'];
export const TIMEZONES = [
  '(GMT-12:00)',
  '(GMT-11:00)',
  '(GMT-10:00)',
  '(GMT-09:00)',
  '(GMT-08:00)',
  '(GMT-07:00)',
  '(GMT-06:00)',
  '(GMT-05:00)',
  '(GMT-04:00)',
  '(GMT-03:30)',
  '(GMT-03:00)',
  '(GMT-02:00)',
  '(GMT-01:00)',
  '(GMT+00:00)',
  '(GMT+01:00)',
  '(GMT+02:00)',
  '(GMT+03:00)',
  '(GMT+03:30)',
  '(GMT+04:00)',
  '(GMT+04:30)',
  '(GMT+05:00)',
  '(GMT+05:30)',
  '(GMT+05:45)',
  '(GMT+06:00)',
  '(GMT+06:30)',
  '(GMT+07:00)',
  '(GMT+08:00)',
  '(GMT+09:00)',
  '(GMT+09:30)',
  '(GMT+10:00)',
  '(GMT+11:00)',
  '(GMT+12:00)',
  '(GMT+13:00)',
];

export const REAL_TIMEZONES = [
  'Etc/GMT+12',
  'Etc/GMT+11',
  'Etc/GMT+10',
  'Etc/GMT+9',
  'Etc/GMT+8',
  'Etc/GMT+7',
  'Etc/GMT+6',
  'Etc/GMT+5',
  'Etc/GMT+4',
  'CNT',
  'Etc/GMT+3',
  'Etc/GMT+2',
  'Etc/GMT+1',
  'Etc/GMT+0',
  'Etc/GMT-1',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Iran',
  'Etc/GMT-4',
  'Asia/Kabul',
  'Etc/GMT-5',
  'IST',
  'Asia/Katmandu',
  'Etc/GMT-6',
  'Indian/Cocos',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'ACT',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
];

export enum AttachmentType {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  FILE = 'file',
  EMAIL = 'email',
}
