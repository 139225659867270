import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const LibraryWithTokenDocument = gql`
    query libraryWithToken($token: String!) {
  libraryWithToken(token: $token) {
    id
    title
    type
    duration
    icon
    tooltip {
      id
      title
      icon
      description
      createdAt
      updatedAt
    }
    creator {
      id
      name
      email
      createdAt
      updatedAt
    }
    superset {
      id
      title
      type
      duration
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
    }
    subsets {
      id
      title
      type
      duration
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
    }
    createdAt
    updatedAt
    questions {
      id
      type
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      category {
        id
        name
      }
      label
      identifier
      options
      automation
      createdAt
      updatedAt
      questions {
        id
        type
        label
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        category {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useLibraryWithTokenQuery__
 *
 * To run a query within a React component, call `useLibraryWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLibraryWithTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.LibraryWithTokenQuery, Types.LibraryWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LibraryWithTokenQuery, Types.LibraryWithTokenQueryVariables>(LibraryWithTokenDocument, options);
      }
export function useLibraryWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LibraryWithTokenQuery, Types.LibraryWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LibraryWithTokenQuery, Types.LibraryWithTokenQueryVariables>(LibraryWithTokenDocument, options);
        }
export type LibraryWithTokenQueryHookResult = ReturnType<typeof useLibraryWithTokenQuery>;
export type LibraryWithTokenLazyQueryHookResult = ReturnType<typeof useLibraryWithTokenLazyQuery>;
export type LibraryWithTokenQueryResult = Apollo.QueryResult<Types.LibraryWithTokenQuery, Types.LibraryWithTokenQueryVariables>;
export function refetchLibraryWithTokenQuery(variables: Types.LibraryWithTokenQueryVariables) {
      return { query: LibraryWithTokenDocument, variables: variables }
    }
export const CreateAssessmentResponseWithTokenDocument = gql`
    mutation createAssessmentResponseWithToken($token: String!, $assessmentResponse: AssessmentResponseWithTokenInputType!) {
  createAssessmentResponseWithToken(
    token: $token
    assessmentResponse: $assessmentResponse
  ) {
    id
    answers {
      id
      values
    }
  }
}
    `;
export type CreateAssessmentResponseWithTokenMutationFn = Apollo.MutationFunction<Types.CreateAssessmentResponseWithTokenMutation, Types.CreateAssessmentResponseWithTokenMutationVariables>;

/**
 * __useCreateAssessmentResponseWithTokenMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentResponseWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentResponseWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentResponseWithTokenMutation, { data, loading, error }] = useCreateAssessmentResponseWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      assessmentResponse: // value for 'assessmentResponse'
 *   },
 * });
 */
export function useCreateAssessmentResponseWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssessmentResponseWithTokenMutation, Types.CreateAssessmentResponseWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssessmentResponseWithTokenMutation, Types.CreateAssessmentResponseWithTokenMutationVariables>(CreateAssessmentResponseWithTokenDocument, options);
      }
export type CreateAssessmentResponseWithTokenMutationHookResult = ReturnType<typeof useCreateAssessmentResponseWithTokenMutation>;
export type CreateAssessmentResponseWithTokenMutationResult = Apollo.MutationResult<Types.CreateAssessmentResponseWithTokenMutation>;
export type CreateAssessmentResponseWithTokenMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssessmentResponseWithTokenMutation, Types.CreateAssessmentResponseWithTokenMutationVariables>;
export const AssessmentQuestionsDocument = gql`
    query assessmentQuestions($limit: LimitInput, $where: AssessmentQuestionWhereInputType) {
  assessmentQuestions(limit: $limit, where: $where) {
    items {
      id
      type
      icon
      variableName
      tooltip {
        id
        icon
        description
        createdAt
        updatedAt
      }
      category {
        id
        name
      }
      label
      identifier
      options
      automation
      createdAt
      updatedAt
      questions {
        id
      }
    }
    count
  }
}
    `;

/**
 * __useAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentQuestionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAssessmentQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AssessmentQuestionsQuery, Types.AssessmentQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssessmentQuestionsQuery, Types.AssessmentQuestionsQueryVariables>(AssessmentQuestionsDocument, options);
      }
export function useAssessmentQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssessmentQuestionsQuery, Types.AssessmentQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssessmentQuestionsQuery, Types.AssessmentQuestionsQueryVariables>(AssessmentQuestionsDocument, options);
        }
export type AssessmentQuestionsQueryHookResult = ReturnType<typeof useAssessmentQuestionsQuery>;
export type AssessmentQuestionsLazyQueryHookResult = ReturnType<typeof useAssessmentQuestionsLazyQuery>;
export type AssessmentQuestionsQueryResult = Apollo.QueryResult<Types.AssessmentQuestionsQuery, Types.AssessmentQuestionsQueryVariables>;
export function refetchAssessmentQuestionsQuery(variables?: Types.AssessmentQuestionsQueryVariables) {
      return { query: AssessmentQuestionsDocument, variables: variables }
    }
export const CreateAssessmentQuestionDocument = gql`
    mutation createAssessmentQuestion($question: AssessmentQuestionInputType!) {
  createAssessmentQuestion(question: $question) {
    id
    type
    icon
    tooltip {
      id
      title
      icon
      description
      createdAt
      updatedAt
    }
    label
    icon
    identifier
    options
    createdAt
    updatedAt
    tooltip {
      id
      title
      createdAt
      deletedAt
      description
      icon
      updatedAt
    }
    meta
    questions {
      id
      type
      label
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      meta
      questions {
        id
        type
        label
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        meta
        questions {
          id
          type
          label
          icon
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          meta
        }
      }
    }
  }
}
    `;
export type CreateAssessmentQuestionMutationFn = Apollo.MutationFunction<Types.CreateAssessmentQuestionMutation, Types.CreateAssessmentQuestionMutationVariables>;

/**
 * __useCreateAssessmentQuestionMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentQuestionMutation, { data, loading, error }] = useCreateAssessmentQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useCreateAssessmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssessmentQuestionMutation, Types.CreateAssessmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssessmentQuestionMutation, Types.CreateAssessmentQuestionMutationVariables>(CreateAssessmentQuestionDocument, options);
      }
export type CreateAssessmentQuestionMutationHookResult = ReturnType<typeof useCreateAssessmentQuestionMutation>;
export type CreateAssessmentQuestionMutationResult = Apollo.MutationResult<Types.CreateAssessmentQuestionMutation>;
export type CreateAssessmentQuestionMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssessmentQuestionMutation, Types.CreateAssessmentQuestionMutationVariables>;
export const UpdateAssessmentQuestionDocument = gql`
    mutation updateAssessmentQuestion($id: Float!, $question: AssessmentQuestionInputType!) {
  updateAssessmentQuestion(id: $id, question: $question) {
    id
  }
}
    `;
export type UpdateAssessmentQuestionMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentQuestionMutation, Types.UpdateAssessmentQuestionMutationVariables>;

/**
 * __useUpdateAssessmentQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentQuestionMutation, { data, loading, error }] = useUpdateAssessmentQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useUpdateAssessmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentQuestionMutation, Types.UpdateAssessmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentQuestionMutation, Types.UpdateAssessmentQuestionMutationVariables>(UpdateAssessmentQuestionDocument, options);
      }
export type UpdateAssessmentQuestionMutationHookResult = ReturnType<typeof useUpdateAssessmentQuestionMutation>;
export type UpdateAssessmentQuestionMutationResult = Apollo.MutationResult<Types.UpdateAssessmentQuestionMutation>;
export type UpdateAssessmentQuestionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentQuestionMutation, Types.UpdateAssessmentQuestionMutationVariables>;
export const AssessmentAnsweredResponseDocument = gql`
    query assessmentAnsweredResponse($userId: Float!, $libraryId: Float!, $startDate: DateTime!, $endDate: DateTime!) {
  assessmentAnsweredResponse(
    userId: $userId
    libraryId: $libraryId
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    isAnswered
  }
}
    `;

/**
 * __useAssessmentAnsweredResponseQuery__
 *
 * To run a query within a React component, call `useAssessmentAnsweredResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentAnsweredResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentAnsweredResponseQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      libraryId: // value for 'libraryId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAssessmentAnsweredResponseQuery(baseOptions: Apollo.QueryHookOptions<Types.AssessmentAnsweredResponseQuery, Types.AssessmentAnsweredResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssessmentAnsweredResponseQuery, Types.AssessmentAnsweredResponseQueryVariables>(AssessmentAnsweredResponseDocument, options);
      }
export function useAssessmentAnsweredResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssessmentAnsweredResponseQuery, Types.AssessmentAnsweredResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssessmentAnsweredResponseQuery, Types.AssessmentAnsweredResponseQueryVariables>(AssessmentAnsweredResponseDocument, options);
        }
export type AssessmentAnsweredResponseQueryHookResult = ReturnType<typeof useAssessmentAnsweredResponseQuery>;
export type AssessmentAnsweredResponseLazyQueryHookResult = ReturnType<typeof useAssessmentAnsweredResponseLazyQuery>;
export type AssessmentAnsweredResponseQueryResult = Apollo.QueryResult<Types.AssessmentAnsweredResponseQuery, Types.AssessmentAnsweredResponseQueryVariables>;
export function refetchAssessmentAnsweredResponseQuery(variables: Types.AssessmentAnsweredResponseQueryVariables) {
      return { query: AssessmentAnsweredResponseDocument, variables: variables }
    }
export const AssessmentAnsweredResponsesDocument = gql`
    query assessmentAnsweredResponses($userId: Float!, $libraryIds: [Float!]!, $startDate: DateTime!, $endDate: DateTime!) {
  assessmentAnsweredResponses(
    userId: $userId
    libraryIds: $libraryIds
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    isAnswered
  }
}
    `;

/**
 * __useAssessmentAnsweredResponsesQuery__
 *
 * To run a query within a React component, call `useAssessmentAnsweredResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentAnsweredResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentAnsweredResponsesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      libraryIds: // value for 'libraryIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAssessmentAnsweredResponsesQuery(baseOptions: Apollo.QueryHookOptions<Types.AssessmentAnsweredResponsesQuery, Types.AssessmentAnsweredResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssessmentAnsweredResponsesQuery, Types.AssessmentAnsweredResponsesQueryVariables>(AssessmentAnsweredResponsesDocument, options);
      }
export function useAssessmentAnsweredResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssessmentAnsweredResponsesQuery, Types.AssessmentAnsweredResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssessmentAnsweredResponsesQuery, Types.AssessmentAnsweredResponsesQueryVariables>(AssessmentAnsweredResponsesDocument, options);
        }
export type AssessmentAnsweredResponsesQueryHookResult = ReturnType<typeof useAssessmentAnsweredResponsesQuery>;
export type AssessmentAnsweredResponsesLazyQueryHookResult = ReturnType<typeof useAssessmentAnsweredResponsesLazyQuery>;
export type AssessmentAnsweredResponsesQueryResult = Apollo.QueryResult<Types.AssessmentAnsweredResponsesQuery, Types.AssessmentAnsweredResponsesQueryVariables>;
export function refetchAssessmentAnsweredResponsesQuery(variables: Types.AssessmentAnsweredResponsesQueryVariables) {
      return { query: AssessmentAnsweredResponsesDocument, variables: variables }
    }
export const AssessmentResponsesDocument = gql`
    query assessmentResponses($limit: LimitInput, $where: AssessmentResponsesWhereInputType) {
  assessmentResponses(limit: $limit, where: $where) {
    items {
      id
      createdAt
      updatedAt
      answerDate
      conversationUUID
      skippedQuestionCount
      skippedQuestionsBySystemCount
      preIVRQuestionsRequired
      library {
        id
        type
        title
        questions {
          id
          label
          type
          order
          isAnswered
          category {
            name
          }
          questions {
            id
            label
            type
            order
            category {
              name
            }
            questions {
              id
              label
              type
              meta
              order
              category {
                name
              }
              questions {
                id
                label
                type
                meta
                order
                category {
                  name
                }
              }
              questions {
                id
                label
                type
                meta
                order
                category {
                  name
                }
              }
            }
          }
        }
      }
      answers {
        id
        value
        values
        comment
        question {
          id
          label
          options
          type
          meta
          order
          category {
            name
          }
          questions {
            id
            label
            type
            meta
            order
            category {
              name
            }
          }
        }
        createdAt
        updatedAt
      }
    }
    count
  }
}
    `;

/**
 * __useAssessmentResponsesQuery__
 *
 * To run a query within a React component, call `useAssessmentResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentResponsesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAssessmentResponsesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AssessmentResponsesQuery, Types.AssessmentResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AssessmentResponsesQuery, Types.AssessmentResponsesQueryVariables>(AssessmentResponsesDocument, options);
      }
export function useAssessmentResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AssessmentResponsesQuery, Types.AssessmentResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AssessmentResponsesQuery, Types.AssessmentResponsesQueryVariables>(AssessmentResponsesDocument, options);
        }
export type AssessmentResponsesQueryHookResult = ReturnType<typeof useAssessmentResponsesQuery>;
export type AssessmentResponsesLazyQueryHookResult = ReturnType<typeof useAssessmentResponsesLazyQuery>;
export type AssessmentResponsesQueryResult = Apollo.QueryResult<Types.AssessmentResponsesQuery, Types.AssessmentResponsesQueryVariables>;
export function refetchAssessmentResponsesQuery(variables?: Types.AssessmentResponsesQueryVariables) {
      return { query: AssessmentResponsesDocument, variables: variables }
    }
export const CreateAssessmentResponseDocument = gql`
    mutation createAssessmentResponse($assessmentResponse: AssessmentResponseInputType!) {
  createAssessmentResponse(assessmentResponse: $assessmentResponse) {
    id
    user {
      id
    }
    answers {
      id
      value
      values
    }
  }
}
    `;
export type CreateAssessmentResponseMutationFn = Apollo.MutationFunction<Types.CreateAssessmentResponseMutation, Types.CreateAssessmentResponseMutationVariables>;

/**
 * __useCreateAssessmentResponseMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentResponseMutation, { data, loading, error }] = useCreateAssessmentResponseMutation({
 *   variables: {
 *      assessmentResponse: // value for 'assessmentResponse'
 *   },
 * });
 */
export function useCreateAssessmentResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssessmentResponseMutation, Types.CreateAssessmentResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssessmentResponseMutation, Types.CreateAssessmentResponseMutationVariables>(CreateAssessmentResponseDocument, options);
      }
export type CreateAssessmentResponseMutationHookResult = ReturnType<typeof useCreateAssessmentResponseMutation>;
export type CreateAssessmentResponseMutationResult = Apollo.MutationResult<Types.CreateAssessmentResponseMutation>;
export type CreateAssessmentResponseMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssessmentResponseMutation, Types.CreateAssessmentResponseMutationVariables>;
export const TranslateDocument = gql`
    query translate($texts: [String!]!, $languageCode: String!) {
  translate(texts: $texts, languageCode: $languageCode) {
    texts
  }
}
    `;

/**
 * __useTranslateQuery__
 *
 * To run a query within a React component, call `useTranslateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateQuery({
 *   variables: {
 *      texts: // value for 'texts'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTranslateQuery(baseOptions: Apollo.QueryHookOptions<Types.TranslateQuery, Types.TranslateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TranslateQuery, Types.TranslateQueryVariables>(TranslateDocument, options);
      }
export function useTranslateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TranslateQuery, Types.TranslateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TranslateQuery, Types.TranslateQueryVariables>(TranslateDocument, options);
        }
export type TranslateQueryHookResult = ReturnType<typeof useTranslateQuery>;
export type TranslateLazyQueryHookResult = ReturnType<typeof useTranslateLazyQuery>;
export type TranslateQueryResult = Apollo.QueryResult<Types.TranslateQuery, Types.TranslateQueryVariables>;
export function refetchTranslateQuery(variables: Types.TranslateQueryVariables) {
      return { query: TranslateDocument, variables: variables }
    }
export const UpdateAssessmentAnswerDocument = gql`
    mutation updateAssessmentAnswer($id: Float!, $updateAssessmentAnswerInput: AssessmentAnswerInputType!) {
  updateAssessmentAnswer(
    id: $id
    updateAssessmentAnswerInput: $updateAssessmentAnswerInput
  ) {
    id
    answers {
      id
      value
      values
    }
  }
}
    `;
export type UpdateAssessmentAnswerMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentAnswerMutation, Types.UpdateAssessmentAnswerMutationVariables>;

/**
 * __useUpdateAssessmentAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentAnswerMutation, { data, loading, error }] = useUpdateAssessmentAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateAssessmentAnswerInput: // value for 'updateAssessmentAnswerInput'
 *   },
 * });
 */
export function useUpdateAssessmentAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentAnswerMutation, Types.UpdateAssessmentAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentAnswerMutation, Types.UpdateAssessmentAnswerMutationVariables>(UpdateAssessmentAnswerDocument, options);
      }
export type UpdateAssessmentAnswerMutationHookResult = ReturnType<typeof useUpdateAssessmentAnswerMutation>;
export type UpdateAssessmentAnswerMutationResult = Apollo.MutationResult<Types.UpdateAssessmentAnswerMutation>;
export type UpdateAssessmentAnswerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentAnswerMutation, Types.UpdateAssessmentAnswerMutationVariables>;
export const AddAssessmentAnswerDocument = gql`
    mutation addAssessmentAnswer($id: Float!, $updateAssessmentAnswerInput: AssessmentAnswerInputType!) {
  addAssessmentAnswer(
    id: $id
    updateAssessmentAnswerInput: $updateAssessmentAnswerInput
  ) {
    id
    answers {
      id
      value
      values
    }
  }
}
    `;
export type AddAssessmentAnswerMutationFn = Apollo.MutationFunction<Types.AddAssessmentAnswerMutation, Types.AddAssessmentAnswerMutationVariables>;

/**
 * __useAddAssessmentAnswerMutation__
 *
 * To run a mutation, you first call `useAddAssessmentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssessmentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssessmentAnswerMutation, { data, loading, error }] = useAddAssessmentAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateAssessmentAnswerInput: // value for 'updateAssessmentAnswerInput'
 *   },
 * });
 */
export function useAddAssessmentAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddAssessmentAnswerMutation, Types.AddAssessmentAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddAssessmentAnswerMutation, Types.AddAssessmentAnswerMutationVariables>(AddAssessmentAnswerDocument, options);
      }
export type AddAssessmentAnswerMutationHookResult = ReturnType<typeof useAddAssessmentAnswerMutation>;
export type AddAssessmentAnswerMutationResult = Apollo.MutationResult<Types.AddAssessmentAnswerMutation>;
export type AddAssessmentAnswerMutationOptions = Apollo.BaseMutationOptions<Types.AddAssessmentAnswerMutation, Types.AddAssessmentAnswerMutationVariables>;
export const UpdateAssessmentResponseDocument = gql`
    mutation updateAssessmentResponse($id: Float!, $assessmentResponse: AssessmentResponseInputType!) {
  updateAssessmentResponse(id: $id, assessmentResponse: $assessmentResponse) {
    id
    user {
      id
    }
    answers {
      id
      value
      values
    }
  }
}
    `;
export type UpdateAssessmentResponseMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentResponseMutation, Types.UpdateAssessmentResponseMutationVariables>;

/**
 * __useUpdateAssessmentResponseMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentResponseMutation, { data, loading, error }] = useUpdateAssessmentResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assessmentResponse: // value for 'assessmentResponse'
 *   },
 * });
 */
export function useUpdateAssessmentResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentResponseMutation, Types.UpdateAssessmentResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentResponseMutation, Types.UpdateAssessmentResponseMutationVariables>(UpdateAssessmentResponseDocument, options);
      }
export type UpdateAssessmentResponseMutationHookResult = ReturnType<typeof useUpdateAssessmentResponseMutation>;
export type UpdateAssessmentResponseMutationResult = Apollo.MutationResult<Types.UpdateAssessmentResponseMutation>;
export type UpdateAssessmentResponseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentResponseMutation, Types.UpdateAssessmentResponseMutationVariables>;
export const AuditDocument = gql`
    query audit($id: Float!) {
  audit(id: $id) {
    id
    tableName
    action
    oldData
    newData
    user {
      id
      name
      email
      createdAt
      updatedAt
    }
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    updatedAt
  }
}
    `;

/**
 * __useAuditQuery__
 *
 * To run a query within a React component, call `useAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuditQuery(baseOptions: Apollo.QueryHookOptions<Types.AuditQuery, Types.AuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AuditQuery, Types.AuditQueryVariables>(AuditDocument, options);
      }
export function useAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AuditQuery, Types.AuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AuditQuery, Types.AuditQueryVariables>(AuditDocument, options);
        }
export type AuditQueryHookResult = ReturnType<typeof useAuditQuery>;
export type AuditLazyQueryHookResult = ReturnType<typeof useAuditLazyQuery>;
export type AuditQueryResult = Apollo.QueryResult<Types.AuditQuery, Types.AuditQueryVariables>;
export function refetchAuditQuery(variables: Types.AuditQueryVariables) {
      return { query: AuditDocument, variables: variables }
    }
export const AuditsDocument = gql`
    query audits($limit: LimitInput, $where: AuditInput) {
  audits(limit: $limit, where: $where) {
    items {
      id
      tableName
      action
      oldData
      newData
      notify
      user {
        id
        name
        email
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useAuditsQuery__
 *
 * To run a query within a React component, call `useAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAuditsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AuditsQuery, Types.AuditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AuditsQuery, Types.AuditsQueryVariables>(AuditsDocument, options);
      }
export function useAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AuditsQuery, Types.AuditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AuditsQuery, Types.AuditsQueryVariables>(AuditsDocument, options);
        }
export type AuditsQueryHookResult = ReturnType<typeof useAuditsQuery>;
export type AuditsLazyQueryHookResult = ReturnType<typeof useAuditsLazyQuery>;
export type AuditsQueryResult = Apollo.QueryResult<Types.AuditsQuery, Types.AuditsQueryVariables>;
export function refetchAuditsQuery(variables?: Types.AuditsQueryVariables) {
      return { query: AuditsDocument, variables: variables }
    }
export const SearchAutomationWhoDocument = gql`
    query searchAutomationWho($search: String) {
  searchAutomationWho(search: $search) {
    id
    name
    type
  }
}
    `;

/**
 * __useSearchAutomationWhoQuery__
 *
 * To run a query within a React component, call `useSearchAutomationWhoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAutomationWhoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAutomationWhoQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchAutomationWhoQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchAutomationWhoQuery, Types.SearchAutomationWhoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchAutomationWhoQuery, Types.SearchAutomationWhoQueryVariables>(SearchAutomationWhoDocument, options);
      }
export function useSearchAutomationWhoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchAutomationWhoQuery, Types.SearchAutomationWhoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchAutomationWhoQuery, Types.SearchAutomationWhoQueryVariables>(SearchAutomationWhoDocument, options);
        }
export type SearchAutomationWhoQueryHookResult = ReturnType<typeof useSearchAutomationWhoQuery>;
export type SearchAutomationWhoLazyQueryHookResult = ReturnType<typeof useSearchAutomationWhoLazyQuery>;
export type SearchAutomationWhoQueryResult = Apollo.QueryResult<Types.SearchAutomationWhoQuery, Types.SearchAutomationWhoQueryVariables>;
export function refetchSearchAutomationWhoQuery(variables?: Types.SearchAutomationWhoQueryVariables) {
      return { query: SearchAutomationWhoDocument, variables: variables }
    }
export const ChartTabsDocument = gql`
    query chartTabs($userId: ID!, $chartCount: Boolean!) {
  chartTabs(userId: $userId, chartCount: $chartCount) {
    id
    title
    order
    createdAt
    updatedAt
    chartCount
  }
}
    `;

/**
 * __useChartTabsQuery__
 *
 * To run a query within a React component, call `useChartTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartTabsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      chartCount: // value for 'chartCount'
 *   },
 * });
 */
export function useChartTabsQuery(baseOptions: Apollo.QueryHookOptions<Types.ChartTabsQuery, Types.ChartTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChartTabsQuery, Types.ChartTabsQueryVariables>(ChartTabsDocument, options);
      }
export function useChartTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChartTabsQuery, Types.ChartTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChartTabsQuery, Types.ChartTabsQueryVariables>(ChartTabsDocument, options);
        }
export type ChartTabsQueryHookResult = ReturnType<typeof useChartTabsQuery>;
export type ChartTabsLazyQueryHookResult = ReturnType<typeof useChartTabsLazyQuery>;
export type ChartTabsQueryResult = Apollo.QueryResult<Types.ChartTabsQuery, Types.ChartTabsQueryVariables>;
export function refetchChartTabsQuery(variables: Types.ChartTabsQueryVariables) {
      return { query: ChartTabsDocument, variables: variables }
    }
export const CreateChartTabDocument = gql`
    mutation createChartTab($title: String!, $userId: ID!) {
  createChartTab(title: $title, userId: $userId) {
    id
    title
    order
    createdAt
    updatedAt
  }
}
    `;
export type CreateChartTabMutationFn = Apollo.MutationFunction<Types.CreateChartTabMutation, Types.CreateChartTabMutationVariables>;

/**
 * __useCreateChartTabMutation__
 *
 * To run a mutation, you first call `useCreateChartTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChartTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChartTabMutation, { data, loading, error }] = useCreateChartTabMutation({
 *   variables: {
 *      title: // value for 'title'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateChartTabMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateChartTabMutation, Types.CreateChartTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateChartTabMutation, Types.CreateChartTabMutationVariables>(CreateChartTabDocument, options);
      }
export type CreateChartTabMutationHookResult = ReturnType<typeof useCreateChartTabMutation>;
export type CreateChartTabMutationResult = Apollo.MutationResult<Types.CreateChartTabMutation>;
export type CreateChartTabMutationOptions = Apollo.BaseMutationOptions<Types.CreateChartTabMutation, Types.CreateChartTabMutationVariables>;
export const UpdateChartTabDocument = gql`
    mutation updateChartTab($id: ID!, $title: String!) {
  updateChartTab(id: $id, title: $title) {
    id
    title
    order
    createdAt
    updatedAt
  }
}
    `;
export type UpdateChartTabMutationFn = Apollo.MutationFunction<Types.UpdateChartTabMutation, Types.UpdateChartTabMutationVariables>;

/**
 * __useUpdateChartTabMutation__
 *
 * To run a mutation, you first call `useUpdateChartTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartTabMutation, { data, loading, error }] = useUpdateChartTabMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateChartTabMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChartTabMutation, Types.UpdateChartTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChartTabMutation, Types.UpdateChartTabMutationVariables>(UpdateChartTabDocument, options);
      }
export type UpdateChartTabMutationHookResult = ReturnType<typeof useUpdateChartTabMutation>;
export type UpdateChartTabMutationResult = Apollo.MutationResult<Types.UpdateChartTabMutation>;
export type UpdateChartTabMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChartTabMutation, Types.UpdateChartTabMutationVariables>;
export const DeleteChartTabDocument = gql`
    mutation deleteChartTab($id: ID!) {
  deleteChartTab(id: $id) {
    id
    title
    order
    createdAt
    updatedAt
  }
}
    `;
export type DeleteChartTabMutationFn = Apollo.MutationFunction<Types.DeleteChartTabMutation, Types.DeleteChartTabMutationVariables>;

/**
 * __useDeleteChartTabMutation__
 *
 * To run a mutation, you first call `useDeleteChartTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartTabMutation, { data, loading, error }] = useDeleteChartTabMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChartTabMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteChartTabMutation, Types.DeleteChartTabMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteChartTabMutation, Types.DeleteChartTabMutationVariables>(DeleteChartTabDocument, options);
      }
export type DeleteChartTabMutationHookResult = ReturnType<typeof useDeleteChartTabMutation>;
export type DeleteChartTabMutationResult = Apollo.MutationResult<Types.DeleteChartTabMutation>;
export type DeleteChartTabMutationOptions = Apollo.BaseMutationOptions<Types.DeleteChartTabMutation, Types.DeleteChartTabMutationVariables>;
export const ManageChartTabsDocument = gql`
    mutation manageChartTabs($userId: ID!, $data: ManageChartTabInputType!) {
  manageChartTabs(userId: $userId, data: $data) {
    success
  }
}
    `;
export type ManageChartTabsMutationFn = Apollo.MutationFunction<Types.ManageChartTabsMutation, Types.ManageChartTabsMutationVariables>;

/**
 * __useManageChartTabsMutation__
 *
 * To run a mutation, you first call `useManageChartTabsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageChartTabsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageChartTabsMutation, { data, loading, error }] = useManageChartTabsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useManageChartTabsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ManageChartTabsMutation, Types.ManageChartTabsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ManageChartTabsMutation, Types.ManageChartTabsMutationVariables>(ManageChartTabsDocument, options);
      }
export type ManageChartTabsMutationHookResult = ReturnType<typeof useManageChartTabsMutation>;
export type ManageChartTabsMutationResult = Apollo.MutationResult<Types.ManageChartTabsMutation>;
export type ManageChartTabsMutationOptions = Apollo.BaseMutationOptions<Types.ManageChartTabsMutation, Types.ManageChartTabsMutationVariables>;
export const CallConferencesDocument = gql`
    query callConferences($user: Float, $correspondingUser: Float, $studyId: Float, $limit: LimitInput, $where: ConferenceWhereInputType) {
  callConferences(
    user: $user
    correspondingUser: $correspondingUser
    studyId: $studyId
    limit: $limit
    where: $where
  ) {
    items {
      id
      type
      title
      fromUser {
        id
        name
      }
      toUser {
        id
        name
      }
      recording {
        recordingUrl
      }
      createdAt
    }
    count
  }
}
    `;

/**
 * __useCallConferencesQuery__
 *
 * To run a query within a React component, call `useCallConferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallConferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallConferencesQuery({
 *   variables: {
 *      user: // value for 'user'
 *      correspondingUser: // value for 'correspondingUser'
 *      studyId: // value for 'studyId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCallConferencesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CallConferencesQuery, Types.CallConferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CallConferencesQuery, Types.CallConferencesQueryVariables>(CallConferencesDocument, options);
      }
export function useCallConferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CallConferencesQuery, Types.CallConferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CallConferencesQuery, Types.CallConferencesQueryVariables>(CallConferencesDocument, options);
        }
export type CallConferencesQueryHookResult = ReturnType<typeof useCallConferencesQuery>;
export type CallConferencesLazyQueryHookResult = ReturnType<typeof useCallConferencesLazyQuery>;
export type CallConferencesQueryResult = Apollo.QueryResult<Types.CallConferencesQuery, Types.CallConferencesQueryVariables>;
export function refetchCallConferencesQuery(variables?: Types.CallConferencesQueryVariables) {
      return { query: CallConferencesDocument, variables: variables }
    }
export const StartConferenceDocument = gql`
    mutation startConference($type: String!, $fromUserId: Float, $toUserId: Float, $studyId: Float, $sessionId: String, $conversationUuid: String) {
  startConference(
    type: $type
    fromUserId: $fromUserId
    toUserId: $toUserId
    studyId: $studyId
    sessionId: $sessionId
    conversationUuid: $conversationUuid
  ) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type StartConferenceMutationFn = Apollo.MutationFunction<Types.StartConferenceMutation, Types.StartConferenceMutationVariables>;

/**
 * __useStartConferenceMutation__
 *
 * To run a mutation, you first call `useStartConferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConferenceMutation, { data, loading, error }] = useStartConferenceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      fromUserId: // value for 'fromUserId'
 *      toUserId: // value for 'toUserId'
 *      studyId: // value for 'studyId'
 *      sessionId: // value for 'sessionId'
 *      conversationUuid: // value for 'conversationUuid'
 *   },
 * });
 */
export function useStartConferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartConferenceMutation, Types.StartConferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartConferenceMutation, Types.StartConferenceMutationVariables>(StartConferenceDocument, options);
      }
export type StartConferenceMutationHookResult = ReturnType<typeof useStartConferenceMutation>;
export type StartConferenceMutationResult = Apollo.MutationResult<Types.StartConferenceMutation>;
export type StartConferenceMutationOptions = Apollo.BaseMutationOptions<Types.StartConferenceMutation, Types.StartConferenceMutationVariables>;
export const ConferencesDocument = gql`
    query conferences($where: ConferenceWhereInputType) {
  conferences(where: $where) {
    items {
      id
      fromUserId
      toUserId
      sessionId
      studyId
      title
      type
      createdAt
    }
    count
  }
}
    `;

/**
 * __useConferencesQuery__
 *
 * To run a query within a React component, call `useConferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConferencesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConferencesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ConferencesQuery, Types.ConferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConferencesQuery, Types.ConferencesQueryVariables>(ConferencesDocument, options);
      }
export function useConferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConferencesQuery, Types.ConferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConferencesQuery, Types.ConferencesQueryVariables>(ConferencesDocument, options);
        }
export type ConferencesQueryHookResult = ReturnType<typeof useConferencesQuery>;
export type ConferencesLazyQueryHookResult = ReturnType<typeof useConferencesLazyQuery>;
export type ConferencesQueryResult = Apollo.QueryResult<Types.ConferencesQuery, Types.ConferencesQueryVariables>;
export function refetchConferencesQuery(variables?: Types.ConferencesQueryVariables) {
      return { query: ConferencesDocument, variables: variables }
    }
export const MetricsConferencesDocument = gql`
    query metricsConferences($where: MetricsInputType) {
  metricsConferences(where: $where) {
    count
    totalDuration
    enrolledCount
    increasedDurationRate
    increasedCountRate
    increasedEnrollRate
  }
}
    `;

/**
 * __useMetricsConferencesQuery__
 *
 * To run a query within a React component, call `useMetricsConferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsConferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsConferencesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMetricsConferencesQuery(baseOptions?: Apollo.QueryHookOptions<Types.MetricsConferencesQuery, Types.MetricsConferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MetricsConferencesQuery, Types.MetricsConferencesQueryVariables>(MetricsConferencesDocument, options);
      }
export function useMetricsConferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MetricsConferencesQuery, Types.MetricsConferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MetricsConferencesQuery, Types.MetricsConferencesQueryVariables>(MetricsConferencesDocument, options);
        }
export type MetricsConferencesQueryHookResult = ReturnType<typeof useMetricsConferencesQuery>;
export type MetricsConferencesLazyQueryHookResult = ReturnType<typeof useMetricsConferencesLazyQuery>;
export type MetricsConferencesQueryResult = Apollo.QueryResult<Types.MetricsConferencesQuery, Types.MetricsConferencesQueryVariables>;
export function refetchMetricsConferencesQuery(variables?: Types.MetricsConferencesQueryVariables) {
      return { query: MetricsConferencesDocument, variables: variables }
    }
export const UpdateConferenceDocument = gql`
    mutation updateConference($id: Float!, $updateConferenceInput: ConferenceInputType!) {
  updateConference(id: $id, updateConferenceInput: $updateConferenceInput) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateConferenceMutationFn = Apollo.MutationFunction<Types.UpdateConferenceMutation, Types.UpdateConferenceMutationVariables>;

/**
 * __useUpdateConferenceMutation__
 *
 * To run a mutation, you first call `useUpdateConferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConferenceMutation, { data, loading, error }] = useUpdateConferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateConferenceInput: // value for 'updateConferenceInput'
 *   },
 * });
 */
export function useUpdateConferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateConferenceMutation, Types.UpdateConferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateConferenceMutation, Types.UpdateConferenceMutationVariables>(UpdateConferenceDocument, options);
      }
export type UpdateConferenceMutationHookResult = ReturnType<typeof useUpdateConferenceMutation>;
export type UpdateConferenceMutationResult = Apollo.MutationResult<Types.UpdateConferenceMutation>;
export type UpdateConferenceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateConferenceMutation, Types.UpdateConferenceMutationVariables>;
export const UsersConferencesDocument = gql`
    query usersConferences($studyId: Float, $limit: LimitInput) {
  userConferences(studyId: $studyId, limit: $limit) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      createdAt
      updatedAt
      lastMessageAt
    }
    count
  }
}
    `;

/**
 * __useUsersConferencesQuery__
 *
 * To run a query within a React component, call `useUsersConferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersConferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersConferencesQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersConferencesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersConferencesQuery, Types.UsersConferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersConferencesQuery, Types.UsersConferencesQueryVariables>(UsersConferencesDocument, options);
      }
export function useUsersConferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersConferencesQuery, Types.UsersConferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersConferencesQuery, Types.UsersConferencesQueryVariables>(UsersConferencesDocument, options);
        }
export type UsersConferencesQueryHookResult = ReturnType<typeof useUsersConferencesQuery>;
export type UsersConferencesLazyQueryHookResult = ReturnType<typeof useUsersConferencesLazyQuery>;
export type UsersConferencesQueryResult = Apollo.QueryResult<Types.UsersConferencesQuery, Types.UsersConferencesQueryVariables>;
export function refetchUsersConferencesQuery(variables?: Types.UsersConferencesQueryVariables) {
      return { query: UsersConferencesDocument, variables: variables }
    }
export const ExportStudyDocument = gql`
    query exportStudy($participantIds: [Float!]!, $questionIds: [Float!]!, $columns: ParticipantInput!, $fromDate: String!, $toDate: String!, $studyId: Float) {
  exportStudy(
    participantIds: $participantIds
    questionIds: $questionIds
    columns: $columns
    fromDate: $fromDate
    toDate: $toDate
    studyId: $studyId
  )
}
    `;

/**
 * __useExportStudyQuery__
 *
 * To run a query within a React component, call `useExportStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStudyQuery({
 *   variables: {
 *      participantIds: // value for 'participantIds'
 *      questionIds: // value for 'questionIds'
 *      columns: // value for 'columns'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useExportStudyQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportStudyQuery, Types.ExportStudyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportStudyQuery, Types.ExportStudyQueryVariables>(ExportStudyDocument, options);
      }
export function useExportStudyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportStudyQuery, Types.ExportStudyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportStudyQuery, Types.ExportStudyQueryVariables>(ExportStudyDocument, options);
        }
export type ExportStudyQueryHookResult = ReturnType<typeof useExportStudyQuery>;
export type ExportStudyLazyQueryHookResult = ReturnType<typeof useExportStudyLazyQuery>;
export type ExportStudyQueryResult = Apollo.QueryResult<Types.ExportStudyQuery, Types.ExportStudyQueryVariables>;
export function refetchExportStudyQuery(variables: Types.ExportStudyQueryVariables) {
      return { query: ExportStudyDocument, variables: variables }
    }
export const ExportFitbitDataDocument = gql`
    query exportFitbitData($participantIds: [Float!]!, $columns: ParticipantInput!, $fromDate: String!, $toDate: String!, $types: [String!]!, $studyId: Float) {
  exportFitbitData(
    participantIds: $participantIds
    columns: $columns
    fromDate: $fromDate
    toDate: $toDate
    types: $types
    studyId: $studyId
  )
}
    `;

/**
 * __useExportFitbitDataQuery__
 *
 * To run a query within a React component, call `useExportFitbitDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportFitbitDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportFitbitDataQuery({
 *   variables: {
 *      participantIds: // value for 'participantIds'
 *      columns: // value for 'columns'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      types: // value for 'types'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useExportFitbitDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportFitbitDataQuery, Types.ExportFitbitDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportFitbitDataQuery, Types.ExportFitbitDataQueryVariables>(ExportFitbitDataDocument, options);
      }
export function useExportFitbitDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportFitbitDataQuery, Types.ExportFitbitDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportFitbitDataQuery, Types.ExportFitbitDataQueryVariables>(ExportFitbitDataDocument, options);
        }
export type ExportFitbitDataQueryHookResult = ReturnType<typeof useExportFitbitDataQuery>;
export type ExportFitbitDataLazyQueryHookResult = ReturnType<typeof useExportFitbitDataLazyQuery>;
export type ExportFitbitDataQueryResult = Apollo.QueryResult<Types.ExportFitbitDataQuery, Types.ExportFitbitDataQueryVariables>;
export function refetchExportFitbitDataQuery(variables: Types.ExportFitbitDataQueryVariables) {
      return { query: ExportFitbitDataDocument, variables: variables }
    }
export const ExportReportDocument = gql`
    query exportReport($participantIds: [Float!]!, $questionIds: [Float!]!, $fromDate: String!, $toDate: String!, $studyId: Float) {
  exportReport(
    participantIds: $participantIds
    questionIds: $questionIds
    fromDate: $fromDate
    toDate: $toDate
    studyId: $studyId
  )
}
    `;

/**
 * __useExportReportQuery__
 *
 * To run a query within a React component, call `useExportReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportQuery({
 *   variables: {
 *      participantIds: // value for 'participantIds'
 *      questionIds: // value for 'questionIds'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useExportReportQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportReportQuery, Types.ExportReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportReportQuery, Types.ExportReportQueryVariables>(ExportReportDocument, options);
      }
export function useExportReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportReportQuery, Types.ExportReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportReportQuery, Types.ExportReportQueryVariables>(ExportReportDocument, options);
        }
export type ExportReportQueryHookResult = ReturnType<typeof useExportReportQuery>;
export type ExportReportLazyQueryHookResult = ReturnType<typeof useExportReportLazyQuery>;
export type ExportReportQueryResult = Apollo.QueryResult<Types.ExportReportQuery, Types.ExportReportQueryVariables>;
export function refetchExportReportQuery(variables: Types.ExportReportQueryVariables) {
      return { query: ExportReportDocument, variables: variables }
    }
export const ExportStudyArmDataDocument = gql`
    query exportStudyArmData($studyArmsIds: [Float!]!, $columns: StudyArmExportInput!, $fromDate: String!, $toDate: String!, $studyId: Float) {
  exportStudyArmData(
    studyArmsIds: $studyArmsIds
    columns: $columns
    fromDate: $fromDate
    toDate: $toDate
    studyId: $studyId
  )
}
    `;

/**
 * __useExportStudyArmDataQuery__
 *
 * To run a query within a React component, call `useExportStudyArmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStudyArmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStudyArmDataQuery({
 *   variables: {
 *      studyArmsIds: // value for 'studyArmsIds'
 *      columns: // value for 'columns'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useExportStudyArmDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ExportStudyArmDataQuery, Types.ExportStudyArmDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExportStudyArmDataQuery, Types.ExportStudyArmDataQueryVariables>(ExportStudyArmDataDocument, options);
      }
export function useExportStudyArmDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExportStudyArmDataQuery, Types.ExportStudyArmDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExportStudyArmDataQuery, Types.ExportStudyArmDataQueryVariables>(ExportStudyArmDataDocument, options);
        }
export type ExportStudyArmDataQueryHookResult = ReturnType<typeof useExportStudyArmDataQuery>;
export type ExportStudyArmDataLazyQueryHookResult = ReturnType<typeof useExportStudyArmDataLazyQuery>;
export type ExportStudyArmDataQueryResult = Apollo.QueryResult<Types.ExportStudyArmDataQuery, Types.ExportStudyArmDataQueryVariables>;
export function refetchExportStudyArmDataQuery(variables: Types.ExportStudyArmDataQueryVariables) {
      return { query: ExportStudyArmDataDocument, variables: variables }
    }
export const CountEmailsDocument = gql`
    query countEmails($search: String, $user: Float, $type: String, $where: EmailsInput) {
  countEmails(search: $search, user: $user, type: $type, where: $where)
}
    `;

/**
 * __useCountEmailsQuery__
 *
 * To run a query within a React component, call `useCountEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEmailsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      type: // value for 'type'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountEmailsQuery, Types.CountEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountEmailsQuery, Types.CountEmailsQueryVariables>(CountEmailsDocument, options);
      }
export function useCountEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountEmailsQuery, Types.CountEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountEmailsQuery, Types.CountEmailsQueryVariables>(CountEmailsDocument, options);
        }
export type CountEmailsQueryHookResult = ReturnType<typeof useCountEmailsQuery>;
export type CountEmailsLazyQueryHookResult = ReturnType<typeof useCountEmailsLazyQuery>;
export type CountEmailsQueryResult = Apollo.QueryResult<Types.CountEmailsQuery, Types.CountEmailsQueryVariables>;
export function refetchCountEmailsQuery(variables?: Types.CountEmailsQueryVariables) {
      return { query: CountEmailsDocument, variables: variables }
    }
export const CreateEmailDocument = gql`
    mutation createEmail($files: [Upload!], $createEmailInput: CreateEmailInput!) {
  createEmail(files: $files, createEmailInput: $createEmailInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateEmailMutationFn = Apollo.MutationFunction<Types.CreateEmailMutation, Types.CreateEmailMutationVariables>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      files: // value for 'files'
 *      createEmailInput: // value for 'createEmailInput'
 *   },
 * });
 */
export function useCreateEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEmailMutation, Types.CreateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEmailMutation, Types.CreateEmailMutationVariables>(CreateEmailDocument, options);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<Types.CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<Types.CreateEmailMutation, Types.CreateEmailMutationVariables>;
export const DeleteEmailDocument = gql`
    mutation deleteEmail($id: Float!) {
  deleteEmail(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type DeleteEmailMutationFn = Apollo.MutationFunction<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>;

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>(DeleteEmailDocument, options);
      }
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>;
export type DeleteEmailMutationResult = Apollo.MutationResult<Types.DeleteEmailMutation>;
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEmailMutation, Types.DeleteEmailMutationVariables>;
export const EmailDocument = gql`
    query email($id: Float!) {
  email(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useEmailQuery__
 *
 * To run a query within a React component, call `useEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.EmailQuery, Types.EmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailQuery, Types.EmailQueryVariables>(EmailDocument, options);
      }
export function useEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailQuery, Types.EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailQuery, Types.EmailQueryVariables>(EmailDocument, options);
        }
export type EmailQueryHookResult = ReturnType<typeof useEmailQuery>;
export type EmailLazyQueryHookResult = ReturnType<typeof useEmailLazyQuery>;
export type EmailQueryResult = Apollo.QueryResult<Types.EmailQuery, Types.EmailQueryVariables>;
export function refetchEmailQuery(variables: Types.EmailQueryVariables) {
      return { query: EmailDocument, variables: variables }
    }
export const EmailsDocument = gql`
    query emails($search: String, $user: Float, $correspondingUser: Float, $type: String, $studyId: Float, $studyArmId: Float, $limit: LimitInput, $where: EmailsInput) {
  emails(
    search: $search
    user: $user
    correspondingUser: $correspondingUser
    type: $type
    studyId: $studyId
    studyArmId: $studyArmId
    limit: $limit
    where: $where
  ) {
    items {
      id
      title
      description
      data
      labels
      actions
      viewed
      liked
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    count
    isGroupChat
  }
}
    `;

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      correspondingUser: // value for 'correspondingUser'
 *      type: // value for 'type'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.EmailsQuery, Types.EmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailsQuery, Types.EmailsQueryVariables>(EmailsDocument, options);
      }
export function useEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailsQuery, Types.EmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailsQuery, Types.EmailsQueryVariables>(EmailsDocument, options);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsQueryResult = Apollo.QueryResult<Types.EmailsQuery, Types.EmailsQueryVariables>;
export function refetchEmailsQuery(variables?: Types.EmailsQueryVariables) {
      return { query: EmailsDocument, variables: variables }
    }
export const LikeAllEmailsDocument = gql`
    mutation likeAllEmails($user: Float) {
  likeAllEmails(user: $user)
}
    `;
export type LikeAllEmailsMutationFn = Apollo.MutationFunction<Types.LikeAllEmailsMutation, Types.LikeAllEmailsMutationVariables>;

/**
 * __useLikeAllEmailsMutation__
 *
 * To run a mutation, you first call `useLikeAllEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllEmailsMutation, { data, loading, error }] = useLikeAllEmailsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLikeAllEmailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllEmailsMutation, Types.LikeAllEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllEmailsMutation, Types.LikeAllEmailsMutationVariables>(LikeAllEmailsDocument, options);
      }
export type LikeAllEmailsMutationHookResult = ReturnType<typeof useLikeAllEmailsMutation>;
export type LikeAllEmailsMutationResult = Apollo.MutationResult<Types.LikeAllEmailsMutation>;
export type LikeAllEmailsMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllEmailsMutation, Types.LikeAllEmailsMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation updateEmail($id: Float!, $updateEmailInput: UpdateEmailInput!) {
  updateEmail(id: $id, updateEmailInput: $updateEmailInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateEmailInput: // value for 'updateEmailInput'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<Types.UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;
export const UsersEmailsDocument = gql`
    query usersEmails($search: String, $studyId: Float, $studyArmId: Float, $type: String, $limit: LimitInput) {
  usersEmails(
    search: $search
    studyId: $studyId
    studyArmId: $studyArmId
    type: $type
    limit: $limit
  ) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      createdAt
      updatedAt
      lastMessageAt
    }
    count
  }
}
    `;

/**
 * __useUsersEmailsQuery__
 *
 * To run a query within a React component, call `useUsersEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersEmailsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersEmailsQuery, Types.UsersEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersEmailsQuery, Types.UsersEmailsQueryVariables>(UsersEmailsDocument, options);
      }
export function useUsersEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersEmailsQuery, Types.UsersEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersEmailsQuery, Types.UsersEmailsQueryVariables>(UsersEmailsDocument, options);
        }
export type UsersEmailsQueryHookResult = ReturnType<typeof useUsersEmailsQuery>;
export type UsersEmailsLazyQueryHookResult = ReturnType<typeof useUsersEmailsLazyQuery>;
export type UsersEmailsQueryResult = Apollo.QueryResult<Types.UsersEmailsQuery, Types.UsersEmailsQueryVariables>;
export function refetchUsersEmailsQuery(variables?: Types.UsersEmailsQueryVariables) {
      return { query: UsersEmailsDocument, variables: variables }
    }
export const ViewAllEmailsDocument = gql`
    mutation viewAllEmails($user: Float) {
  viewAllEmails(user: $user)
}
    `;
export type ViewAllEmailsMutationFn = Apollo.MutationFunction<Types.ViewAllEmailsMutation, Types.ViewAllEmailsMutationVariables>;

/**
 * __useViewAllEmailsMutation__
 *
 * To run a mutation, you first call `useViewAllEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllEmailsMutation, { data, loading, error }] = useViewAllEmailsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useViewAllEmailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllEmailsMutation, Types.ViewAllEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllEmailsMutation, Types.ViewAllEmailsMutationVariables>(ViewAllEmailsDocument, options);
      }
export type ViewAllEmailsMutationHookResult = ReturnType<typeof useViewAllEmailsMutation>;
export type ViewAllEmailsMutationResult = Apollo.MutationResult<Types.ViewAllEmailsMutation>;
export type ViewAllEmailsMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllEmailsMutation, Types.ViewAllEmailsMutationVariables>;
export const AdherenceMetricsDocument = gql`
    query adherenceMetrics($studyArmsId: Float, $type: String, $fromDate: String, $toDate: String, $userId: Float) {
  adherenceMetrics(
    studyArmsId: $studyArmsId
    type: $type
    fromDate: $fromDate
    toDate: $toDate
    userId: $userId
  ) {
    adherenceItems {
      id
      startDate
      endDate
      type
      status
      receiver {
        id
        name
      }
    }
    completedJournals
    libraries {
      id
      title
      libraryConfigs {
        id
        repeatText
        duration
        offset
        repeat
        order
      }
    }
  }
}
    `;

/**
 * __useAdherenceMetricsQuery__
 *
 * To run a query within a React component, call `useAdherenceMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdherenceMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdherenceMetricsQuery({
 *   variables: {
 *      studyArmsId: // value for 'studyArmsId'
 *      type: // value for 'type'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdherenceMetricsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdherenceMetricsQuery, Types.AdherenceMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdherenceMetricsQuery, Types.AdherenceMetricsQueryVariables>(AdherenceMetricsDocument, options);
      }
export function useAdherenceMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdherenceMetricsQuery, Types.AdherenceMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdherenceMetricsQuery, Types.AdherenceMetricsQueryVariables>(AdherenceMetricsDocument, options);
        }
export type AdherenceMetricsQueryHookResult = ReturnType<typeof useAdherenceMetricsQuery>;
export type AdherenceMetricsLazyQueryHookResult = ReturnType<typeof useAdherenceMetricsLazyQuery>;
export type AdherenceMetricsQueryResult = Apollo.QueryResult<Types.AdherenceMetricsQuery, Types.AdherenceMetricsQueryVariables>;
export function refetchAdherenceMetricsQuery(variables?: Types.AdherenceMetricsQueryVariables) {
      return { query: AdherenceMetricsDocument, variables: variables }
    }
export const CoachingEventsDocument = gql`
    query coachingEvents($userId: Float!) {
  coachingEvents(userId: $userId) {
    id
    start
    end
    status
    libraryId
  }
}
    `;

/**
 * __useCoachingEventsQuery__
 *
 * To run a query within a React component, call `useCoachingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCoachingEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.CoachingEventsQuery, Types.CoachingEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CoachingEventsQuery, Types.CoachingEventsQueryVariables>(CoachingEventsDocument, options);
      }
export function useCoachingEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CoachingEventsQuery, Types.CoachingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CoachingEventsQuery, Types.CoachingEventsQueryVariables>(CoachingEventsDocument, options);
        }
export type CoachingEventsQueryHookResult = ReturnType<typeof useCoachingEventsQuery>;
export type CoachingEventsLazyQueryHookResult = ReturnType<typeof useCoachingEventsLazyQuery>;
export type CoachingEventsQueryResult = Apollo.QueryResult<Types.CoachingEventsQuery, Types.CoachingEventsQueryVariables>;
export function refetchCoachingEventsQuery(variables: Types.CoachingEventsQueryVariables) {
      return { query: CoachingEventsDocument, variables: variables }
    }
export const ConfirmEventDocument = gql`
    mutation confirmEvent($id: Float!, $confirmEventInput: UpdateEventInput!) {
  confirmEvent(id: $id, confirmEventInput: $confirmEventInput) {
    confirmed
  }
}
    `;
export type ConfirmEventMutationFn = Apollo.MutationFunction<Types.ConfirmEventMutation, Types.ConfirmEventMutationVariables>;

/**
 * __useConfirmEventMutation__
 *
 * To run a mutation, you first call `useConfirmEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEventMutation, { data, loading, error }] = useConfirmEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      confirmEventInput: // value for 'confirmEventInput'
 *   },
 * });
 */
export function useConfirmEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmEventMutation, Types.ConfirmEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmEventMutation, Types.ConfirmEventMutationVariables>(ConfirmEventDocument, options);
      }
export type ConfirmEventMutationHookResult = ReturnType<typeof useConfirmEventMutation>;
export type ConfirmEventMutationResult = Apollo.MutationResult<Types.ConfirmEventMutation>;
export type ConfirmEventMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmEventMutation, Types.ConfirmEventMutationVariables>;
export const ConfirmProposedTimeForEventDocument = gql`
    mutation confirmProposedTimeForEvent($id: Float!, $updateEventInput: UpdateEventInput!) {
  confirmProposedTimeForEvent(id: $id, updateEventInput: $updateEventInput) {
    id
    title
    description
    data
    start
    end
    resource
    labels
    actions
    viewed
    liked
    confirmed
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type ConfirmProposedTimeForEventMutationFn = Apollo.MutationFunction<Types.ConfirmProposedTimeForEventMutation, Types.ConfirmProposedTimeForEventMutationVariables>;

/**
 * __useConfirmProposedTimeForEventMutation__
 *
 * To run a mutation, you first call `useConfirmProposedTimeForEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmProposedTimeForEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmProposedTimeForEventMutation, { data, loading, error }] = useConfirmProposedTimeForEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateEventInput: // value for 'updateEventInput'
 *   },
 * });
 */
export function useConfirmProposedTimeForEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmProposedTimeForEventMutation, Types.ConfirmProposedTimeForEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmProposedTimeForEventMutation, Types.ConfirmProposedTimeForEventMutationVariables>(ConfirmProposedTimeForEventDocument, options);
      }
export type ConfirmProposedTimeForEventMutationHookResult = ReturnType<typeof useConfirmProposedTimeForEventMutation>;
export type ConfirmProposedTimeForEventMutationResult = Apollo.MutationResult<Types.ConfirmProposedTimeForEventMutation>;
export type ConfirmProposedTimeForEventMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmProposedTimeForEventMutation, Types.ConfirmProposedTimeForEventMutationVariables>;
export const CountEventsDocument = gql`
    query countEvents($search: String, $user: Float, $type: String, $where: EventsInput) {
  countEvents(search: $search, user: $user, type: $type, where: $where)
}
    `;

/**
 * __useCountEventsQuery__
 *
 * To run a query within a React component, call `useCountEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      type: // value for 'type'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountEventsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountEventsQuery, Types.CountEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountEventsQuery, Types.CountEventsQueryVariables>(CountEventsDocument, options);
      }
export function useCountEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountEventsQuery, Types.CountEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountEventsQuery, Types.CountEventsQueryVariables>(CountEventsDocument, options);
        }
export type CountEventsQueryHookResult = ReturnType<typeof useCountEventsQuery>;
export type CountEventsLazyQueryHookResult = ReturnType<typeof useCountEventsLazyQuery>;
export type CountEventsQueryResult = Apollo.QueryResult<Types.CountEventsQuery, Types.CountEventsQueryVariables>;
export function refetchCountEventsQuery(variables?: Types.CountEventsQueryVariables) {
      return { query: CountEventsDocument, variables: variables }
    }
export const CreateEventDocument = gql`
    mutation createEvent($createEventInput: CreateEventInput!) {
  createEvent(createEventInput: $createEventInput) {
    id
    title
    description
    data
    start
    end
    resource
    labels
    actions
    viewed
    liked
    confirmed
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<Types.CreateEventMutation, Types.CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      createEventInput: // value for 'createEventInput'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEventMutation, Types.CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEventMutation, Types.CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<Types.CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<Types.CreateEventMutation, Types.CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: Float!) {
  deleteEvent(id: $id) {
    id
    title
    description
    data
    start
    end
    resource
    labels
    actions
    viewed
    liked
    confirmed
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<Types.DeleteEventMutation, Types.DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEventMutation, Types.DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEventMutation, Types.DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<Types.DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEventMutation, Types.DeleteEventMutationVariables>;
export const EventDocument = gql`
    query event($id: Float!) {
  event(id: $id) {
    id
    title
    type
    description
    data
    start
    end
    resource
    labels
    actions
    viewed
    liked
    confirmed
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<Types.EventQuery, Types.EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventQuery, Types.EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventQuery, Types.EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventQuery, Types.EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<Types.EventQuery, Types.EventQueryVariables>;
export function refetchEventQuery(variables: Types.EventQueryVariables) {
      return { query: EventDocument, variables: variables }
    }
export const EventsDocument = gql`
    query events($search: String, $user: Float, $type: String, $studyId: Float, $studyArmId: Float, $limit: LimitInput, $where: EventsInput) {
  events(
    search: $search
    user: $user
    type: $type
    studyId: $studyId
    studyArmId: $studyArmId
    limit: $limit
    where: $where
  ) {
    items {
      id
      title
      type
      description
      data
      start
      end
      resource
      labels
      actions
      viewed
      liked
      confirmed
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        phoneNumbers {
          id
          type
          label
          isPreferred
          createdAt
          updatedAt
          country {
            id
            countryCode
            countryCallingCode
            nationalNumber
            numberValue
            createdAt
            updatedAt
          }
        }
        primaryRole {
          id
          name
        }
        roles {
          id
          role {
            id
            name
          }
          study {
            id
          }
        }
        createdAt
        updatedAt
        idNumber
      }
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      type: // value for 'type'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<Types.EventsQuery, Types.EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventsQuery, Types.EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventsQuery, Types.EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventsQuery, Types.EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<Types.EventsQuery, Types.EventsQueryVariables>;
export function refetchEventsQuery(variables?: Types.EventsQueryVariables) {
      return { query: EventsDocument, variables: variables }
    }
export const LikeAllEventsDocument = gql`
    mutation likeAllEvents($user: Float) {
  likeAllEvents(user: $user)
}
    `;
export type LikeAllEventsMutationFn = Apollo.MutationFunction<Types.LikeAllEventsMutation, Types.LikeAllEventsMutationVariables>;

/**
 * __useLikeAllEventsMutation__
 *
 * To run a mutation, you first call `useLikeAllEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllEventsMutation, { data, loading, error }] = useLikeAllEventsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLikeAllEventsMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllEventsMutation, Types.LikeAllEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllEventsMutation, Types.LikeAllEventsMutationVariables>(LikeAllEventsDocument, options);
      }
export type LikeAllEventsMutationHookResult = ReturnType<typeof useLikeAllEventsMutation>;
export type LikeAllEventsMutationResult = Apollo.MutationResult<Types.LikeAllEventsMutation>;
export type LikeAllEventsMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllEventsMutation, Types.LikeAllEventsMutationVariables>;
export const MetricsEventsDocument = gql`
    query metricsEvents($where: EventMetricsInputType) {
  metricsEvents(where: $where) {
    totalCount
    missedCount
    incompleteCount
    completedCount
    totalCountMonth
    missedCountMonth
    incompleteCountMonth
    completedCountMonth
  }
}
    `;

/**
 * __useMetricsEventsQuery__
 *
 * To run a query within a React component, call `useMetricsEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMetricsEventsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MetricsEventsQuery, Types.MetricsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MetricsEventsQuery, Types.MetricsEventsQueryVariables>(MetricsEventsDocument, options);
      }
export function useMetricsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MetricsEventsQuery, Types.MetricsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MetricsEventsQuery, Types.MetricsEventsQueryVariables>(MetricsEventsDocument, options);
        }
export type MetricsEventsQueryHookResult = ReturnType<typeof useMetricsEventsQuery>;
export type MetricsEventsLazyQueryHookResult = ReturnType<typeof useMetricsEventsLazyQuery>;
export type MetricsEventsQueryResult = Apollo.QueryResult<Types.MetricsEventsQuery, Types.MetricsEventsQueryVariables>;
export function refetchMetricsEventsQuery(variables?: Types.MetricsEventsQueryVariables) {
      return { query: MetricsEventsDocument, variables: variables }
    }
export const ProfileEventsDocument = gql`
    query profileEvents($user: Float!, $studyId: Float, $limit: LimitInput, $where: EventsInput) {
  getProfileEvents(user: $user, studyId: $studyId, limit: $limit, where: $where) {
    event {
      id
      title
      start
      end
      startForIVR
      type
      receiver {
        id
        name
        idNumber
      }
      sender {
        id
        name
        idNumber
      }
    }
    windowStartTime
    windowEndTime
    libraryConfig {
      id
      library {
        id
      }
    }
  }
}
    `;

/**
 * __useProfileEventsQuery__
 *
 * To run a query within a React component, call `useProfileEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEventsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      studyId: // value for 'studyId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProfileEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProfileEventsQuery, Types.ProfileEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileEventsQuery, Types.ProfileEventsQueryVariables>(ProfileEventsDocument, options);
      }
export function useProfileEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileEventsQuery, Types.ProfileEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileEventsQuery, Types.ProfileEventsQueryVariables>(ProfileEventsDocument, options);
        }
export type ProfileEventsQueryHookResult = ReturnType<typeof useProfileEventsQuery>;
export type ProfileEventsLazyQueryHookResult = ReturnType<typeof useProfileEventsLazyQuery>;
export type ProfileEventsQueryResult = Apollo.QueryResult<Types.ProfileEventsQuery, Types.ProfileEventsQueryVariables>;
export function refetchProfileEventsQuery(variables: Types.ProfileEventsQueryVariables) {
      return { query: ProfileEventsDocument, variables: variables }
    }
export const GetScheduledEventsDocument = gql`
    query getScheduledEvents($studyArmsIds: [Float!]!) {
  getScheduledEvents(studyArmsIds: $studyArmsIds) {
    event {
      id
      startForIVR
      start
      end
      type
      status
      receiver {
        id
        name
        idNumber
        phoneNumbers {
          id
          label
          type
          createdAt
          updatedAt
          isPreferred
          country {
            id
            numberValue
            countryCallingCode
            countryCode
            nationalNumber
            updatedAt
            createdAt
          }
        }
      }
    }
    windowStartTime
    windowEndTime
  }
}
    `;

/**
 * __useGetScheduledEventsQuery__
 *
 * To run a query within a React component, call `useGetScheduledEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledEventsQuery({
 *   variables: {
 *      studyArmsIds: // value for 'studyArmsIds'
 *   },
 * });
 */
export function useGetScheduledEventsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScheduledEventsQuery, Types.GetScheduledEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScheduledEventsQuery, Types.GetScheduledEventsQueryVariables>(GetScheduledEventsDocument, options);
      }
export function useGetScheduledEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScheduledEventsQuery, Types.GetScheduledEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScheduledEventsQuery, Types.GetScheduledEventsQueryVariables>(GetScheduledEventsDocument, options);
        }
export type GetScheduledEventsQueryHookResult = ReturnType<typeof useGetScheduledEventsQuery>;
export type GetScheduledEventsLazyQueryHookResult = ReturnType<typeof useGetScheduledEventsLazyQuery>;
export type GetScheduledEventsQueryResult = Apollo.QueryResult<Types.GetScheduledEventsQuery, Types.GetScheduledEventsQueryVariables>;
export function refetchGetScheduledEventsQuery(variables: Types.GetScheduledEventsQueryVariables) {
      return { query: GetScheduledEventsDocument, variables: variables }
    }
export const UpdateCoachingEventStatusDocument = gql`
    mutation UpdateCoachingEventStatus($date: DateTime!, $status: String!, $id: Float!) {
  updateCoachingEventStatus(date: $date, status: $status, id: $id) {
    id
    status
  }
}
    `;
export type UpdateCoachingEventStatusMutationFn = Apollo.MutationFunction<Types.UpdateCoachingEventStatusMutation, Types.UpdateCoachingEventStatusMutationVariables>;

/**
 * __useUpdateCoachingEventStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCoachingEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachingEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachingEventStatusMutation, { data, loading, error }] = useUpdateCoachingEventStatusMutation({
 *   variables: {
 *      date: // value for 'date'
 *      status: // value for 'status'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCoachingEventStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCoachingEventStatusMutation, Types.UpdateCoachingEventStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCoachingEventStatusMutation, Types.UpdateCoachingEventStatusMutationVariables>(UpdateCoachingEventStatusDocument, options);
      }
export type UpdateCoachingEventStatusMutationHookResult = ReturnType<typeof useUpdateCoachingEventStatusMutation>;
export type UpdateCoachingEventStatusMutationResult = Apollo.MutationResult<Types.UpdateCoachingEventStatusMutation>;
export type UpdateCoachingEventStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCoachingEventStatusMutation, Types.UpdateCoachingEventStatusMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: Float!, $updateEventInput: UpdateEventInput!) {
  updateEvent(id: $id, updateEventInput: $updateEventInput) {
    id
    title
    description
    data
    start
    end
    resource
    labels
    actions
    viewed
    liked
    confirmed
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<Types.UpdateEventMutation, Types.UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateEventInput: // value for 'updateEventInput'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEventMutation, Types.UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEventMutation, Types.UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<Types.UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEventMutation, Types.UpdateEventMutationVariables>;
export const UsersEventsDocument = gql`
    query usersEvents($search: String, $type: String, $limit: LimitInput) {
  usersEvents(search: $search, type: $type, limit: $limit) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useUsersEventsQuery__
 *
 * To run a query within a React component, call `useUsersEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersEventsQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersEventsQuery, Types.UsersEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersEventsQuery, Types.UsersEventsQueryVariables>(UsersEventsDocument, options);
      }
export function useUsersEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersEventsQuery, Types.UsersEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersEventsQuery, Types.UsersEventsQueryVariables>(UsersEventsDocument, options);
        }
export type UsersEventsQueryHookResult = ReturnType<typeof useUsersEventsQuery>;
export type UsersEventsLazyQueryHookResult = ReturnType<typeof useUsersEventsLazyQuery>;
export type UsersEventsQueryResult = Apollo.QueryResult<Types.UsersEventsQuery, Types.UsersEventsQueryVariables>;
export function refetchUsersEventsQuery(variables?: Types.UsersEventsQueryVariables) {
      return { query: UsersEventsDocument, variables: variables }
    }
export const ViewAllEventsDocument = gql`
    mutation viewAllEvents($user: Float) {
  viewAllEvents(user: $user)
}
    `;
export type ViewAllEventsMutationFn = Apollo.MutationFunction<Types.ViewAllEventsMutation, Types.ViewAllEventsMutationVariables>;

/**
 * __useViewAllEventsMutation__
 *
 * To run a mutation, you first call `useViewAllEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllEventsMutation, { data, loading, error }] = useViewAllEventsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useViewAllEventsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllEventsMutation, Types.ViewAllEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllEventsMutation, Types.ViewAllEventsMutationVariables>(ViewAllEventsDocument, options);
      }
export type ViewAllEventsMutationHookResult = ReturnType<typeof useViewAllEventsMutation>;
export type ViewAllEventsMutationResult = Apollo.MutationResult<Types.ViewAllEventsMutation>;
export type ViewAllEventsMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllEventsMutation, Types.ViewAllEventsMutationVariables>;
export const CountFilesDocument = gql`
    query countFiles($where: FilesInput) {
  countFiles(where: $where)
}
    `;

/**
 * __useCountFilesQuery__
 *
 * To run a query within a React component, call `useCountFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountFilesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountFilesQuery, Types.CountFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountFilesQuery, Types.CountFilesQueryVariables>(CountFilesDocument, options);
      }
export function useCountFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountFilesQuery, Types.CountFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountFilesQuery, Types.CountFilesQueryVariables>(CountFilesDocument, options);
        }
export type CountFilesQueryHookResult = ReturnType<typeof useCountFilesQuery>;
export type CountFilesLazyQueryHookResult = ReturnType<typeof useCountFilesLazyQuery>;
export type CountFilesQueryResult = Apollo.QueryResult<Types.CountFilesQuery, Types.CountFilesQueryVariables>;
export function refetchCountFilesQuery(variables?: Types.CountFilesQueryVariables) {
      return { query: CountFilesDocument, variables: variables }
    }
export const CreateFileDocument = gql`
    mutation createFile($file: Upload!) {
  createFile(file: $file) {
    id
    title
    description
    type
    path
    url
    size
    createdAt
    updatedAt
  }
}
    `;
export type CreateFileMutationFn = Apollo.MutationFunction<Types.CreateFileMutation, Types.CreateFileMutationVariables>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFileMutation, Types.CreateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFileMutation, Types.CreateFileMutationVariables>(CreateFileDocument, options);
      }
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>;
export type CreateFileMutationResult = Apollo.MutationResult<Types.CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<Types.CreateFileMutation, Types.CreateFileMutationVariables>;
export const CreateFilesDocument = gql`
    mutation createFiles($files: [Upload!]!) {
  createFiles(files: $files) {
    id
    title
    description
    type
    path
    url
    size
    createdAt
    updatedAt
  }
}
    `;
export type CreateFilesMutationFn = Apollo.MutationFunction<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>;

/**
 * __useCreateFilesMutation__
 *
 * To run a mutation, you first call `useCreateFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilesMutation, { data, loading, error }] = useCreateFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateFilesMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>(CreateFilesDocument, options);
      }
export type CreateFilesMutationHookResult = ReturnType<typeof useCreateFilesMutation>;
export type CreateFilesMutationResult = Apollo.MutationResult<Types.CreateFilesMutation>;
export type CreateFilesMutationOptions = Apollo.BaseMutationOptions<Types.CreateFilesMutation, Types.CreateFilesMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: Float!) {
  deleteFile(id: $id) {
    id
    title
    description
    type
    path
    url
    size
    createdAt
    updatedAt
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<Types.DeleteFileMutation, Types.DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFileMutation, Types.DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFileMutation, Types.DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<Types.DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFileMutation, Types.DeleteFileMutationVariables>;
export const FileDocument = gql`
    query file($id: Float!) {
  file(id: $id) {
    id
    title
    description
    type
    path
    url
    size
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileQuery(baseOptions: Apollo.QueryHookOptions<Types.FileQuery, Types.FileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FileQuery, Types.FileQueryVariables>(FileDocument, options);
      }
export function useFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FileQuery, Types.FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FileQuery, Types.FileQueryVariables>(FileDocument, options);
        }
export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileQueryResult = Apollo.QueryResult<Types.FileQuery, Types.FileQueryVariables>;
export function refetchFileQuery(variables: Types.FileQueryVariables) {
      return { query: FileDocument, variables: variables }
    }
export const FilesDocument = gql`
    query files($limit: LimitInput, $where: FilesInput) {
  files(limit: $limit, where: $where) {
    items {
      id
      title
      description
      type
      path
      url
      size
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<Types.FilesQuery, Types.FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FilesQuery, Types.FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FilesQuery, Types.FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FilesQuery, Types.FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<Types.FilesQuery, Types.FilesQueryVariables>;
export function refetchFilesQuery(variables?: Types.FilesQueryVariables) {
      return { query: FilesDocument, variables: variables }
    }
export const UpdateFileDocument = gql`
    mutation updateFile($id: Float!, $updateFileInput: UpdateFileInput!) {
  updateFile(id: $id, updateFileInput: $updateFileInput) {
    id
    title
    description
    type
    path
    url
    size
    createdAt
    updatedAt
  }
}
    `;
export type UpdateFileMutationFn = Apollo.MutationFunction<Types.UpdateFileMutation, Types.UpdateFileMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateFileInput: // value for 'updateFileInput'
 *   },
 * });
 */
export function useUpdateFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFileMutation, Types.UpdateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFileMutation, Types.UpdateFileMutationVariables>(UpdateFileDocument, options);
      }
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<Types.UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFileMutation, Types.UpdateFileMutationVariables>;
export const FitbitActivitiesTrendDocument = gql`
    query fitbitActivitiesTrend($parameters: FitbitActivityTrendInputType!) {
  fitbitActivitiesTrend(parameters: $parameters) {
    value
    dateTime
  }
}
    `;

/**
 * __useFitbitActivitiesTrendQuery__
 *
 * To run a query within a React component, call `useFitbitActivitiesTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useFitbitActivitiesTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFitbitActivitiesTrendQuery({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useFitbitActivitiesTrendQuery(baseOptions: Apollo.QueryHookOptions<Types.FitbitActivitiesTrendQuery, Types.FitbitActivitiesTrendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FitbitActivitiesTrendQuery, Types.FitbitActivitiesTrendQueryVariables>(FitbitActivitiesTrendDocument, options);
      }
export function useFitbitActivitiesTrendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FitbitActivitiesTrendQuery, Types.FitbitActivitiesTrendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FitbitActivitiesTrendQuery, Types.FitbitActivitiesTrendQueryVariables>(FitbitActivitiesTrendDocument, options);
        }
export type FitbitActivitiesTrendQueryHookResult = ReturnType<typeof useFitbitActivitiesTrendQuery>;
export type FitbitActivitiesTrendLazyQueryHookResult = ReturnType<typeof useFitbitActivitiesTrendLazyQuery>;
export type FitbitActivitiesTrendQueryResult = Apollo.QueryResult<Types.FitbitActivitiesTrendQuery, Types.FitbitActivitiesTrendQueryVariables>;
export function refetchFitbitActivitiesTrendQuery(variables: Types.FitbitActivitiesTrendQueryVariables) {
      return { query: FitbitActivitiesTrendDocument, variables: variables }
    }
export const FitbitActivitiesListDocument = gql`
    query fitbitActivitiesList($userId: Float!, $fromDate: String!, $toDate: String!) {
  fitbitActivitiesList(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
    name
    duration
    startTime
    calories
    pace
    speed
    distance
    distanceUnit
    elivation
    steps
    averageHeartRate
    activityLevel {
      name
      minutes
    }
    date
  }
}
    `;

/**
 * __useFitbitActivitiesListQuery__
 *
 * To run a query within a React component, call `useFitbitActivitiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFitbitActivitiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFitbitActivitiesListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useFitbitActivitiesListQuery(baseOptions: Apollo.QueryHookOptions<Types.FitbitActivitiesListQuery, Types.FitbitActivitiesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FitbitActivitiesListQuery, Types.FitbitActivitiesListQueryVariables>(FitbitActivitiesListDocument, options);
      }
export function useFitbitActivitiesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FitbitActivitiesListQuery, Types.FitbitActivitiesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FitbitActivitiesListQuery, Types.FitbitActivitiesListQueryVariables>(FitbitActivitiesListDocument, options);
        }
export type FitbitActivitiesListQueryHookResult = ReturnType<typeof useFitbitActivitiesListQuery>;
export type FitbitActivitiesListLazyQueryHookResult = ReturnType<typeof useFitbitActivitiesListLazyQuery>;
export type FitbitActivitiesListQueryResult = Apollo.QueryResult<Types.FitbitActivitiesListQuery, Types.FitbitActivitiesListQueryVariables>;
export function refetchFitbitActivitiesListQuery(variables: Types.FitbitActivitiesListQueryVariables) {
      return { query: FitbitActivitiesListDocument, variables: variables }
    }
export const FitbitChartsDataDocument = gql`
    query fitbitChartsData($userId: Float!, $fromDate: String!, $toDate: String!) {
  fitbitChartsData(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
    steps {
      date
      value
    }
    distance {
      date
      value
    }
    sleep {
      date
      data
      duration
      endTime
      startTime
      id
    }
    heartRate {
      id
      date
      restingHeartRate
    }
    activity {
      distance
      distanceUnit
      activityLevel {
        minutes
        name
      }
      averageHeartRate
      elivation
      duration
      id
      name
      pace
      speed
      startTime
      steps
      calories
      date
      data
    }
  }
}
    `;

/**
 * __useFitbitChartsDataQuery__
 *
 * To run a query within a React component, call `useFitbitChartsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFitbitChartsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFitbitChartsDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useFitbitChartsDataQuery(baseOptions: Apollo.QueryHookOptions<Types.FitbitChartsDataQuery, Types.FitbitChartsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FitbitChartsDataQuery, Types.FitbitChartsDataQueryVariables>(FitbitChartsDataDocument, options);
      }
export function useFitbitChartsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FitbitChartsDataQuery, Types.FitbitChartsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FitbitChartsDataQuery, Types.FitbitChartsDataQueryVariables>(FitbitChartsDataDocument, options);
        }
export type FitbitChartsDataQueryHookResult = ReturnType<typeof useFitbitChartsDataQuery>;
export type FitbitChartsDataLazyQueryHookResult = ReturnType<typeof useFitbitChartsDataLazyQuery>;
export type FitbitChartsDataQueryResult = Apollo.QueryResult<Types.FitbitChartsDataQuery, Types.FitbitChartsDataQueryVariables>;
export function refetchFitbitChartsDataQuery(variables: Types.FitbitChartsDataQueryVariables) {
      return { query: FitbitChartsDataDocument, variables: variables }
    }
export const FitbitLifetimeStatsDocument = gql`
    query fitbitLifetimeStats($userId: Float!) {
  fitbitLifetimeStats(userId: $userId) {
    label
    metric
  }
}
    `;

/**
 * __useFitbitLifetimeStatsQuery__
 *
 * To run a query within a React component, call `useFitbitLifetimeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFitbitLifetimeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFitbitLifetimeStatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFitbitLifetimeStatsQuery(baseOptions: Apollo.QueryHookOptions<Types.FitbitLifetimeStatsQuery, Types.FitbitLifetimeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FitbitLifetimeStatsQuery, Types.FitbitLifetimeStatsQueryVariables>(FitbitLifetimeStatsDocument, options);
      }
export function useFitbitLifetimeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FitbitLifetimeStatsQuery, Types.FitbitLifetimeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FitbitLifetimeStatsQuery, Types.FitbitLifetimeStatsQueryVariables>(FitbitLifetimeStatsDocument, options);
        }
export type FitbitLifetimeStatsQueryHookResult = ReturnType<typeof useFitbitLifetimeStatsQuery>;
export type FitbitLifetimeStatsLazyQueryHookResult = ReturnType<typeof useFitbitLifetimeStatsLazyQuery>;
export type FitbitLifetimeStatsQueryResult = Apollo.QueryResult<Types.FitbitLifetimeStatsQuery, Types.FitbitLifetimeStatsQueryVariables>;
export function refetchFitbitLifetimeStatsQuery(variables: Types.FitbitLifetimeStatsQueryVariables) {
      return { query: FitbitLifetimeStatsDocument, variables: variables }
    }
export const SyncFitbitDataDocument = gql`
    query syncFitbitData($userId: Float!, $fromDate: String!, $toDate: String!) {
  syncFitbitData(userId: $userId, fromDate: $fromDate, toDate: $toDate)
}
    `;

/**
 * __useSyncFitbitDataQuery__
 *
 * To run a query within a React component, call `useSyncFitbitDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncFitbitDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncFitbitDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useSyncFitbitDataQuery(baseOptions: Apollo.QueryHookOptions<Types.SyncFitbitDataQuery, Types.SyncFitbitDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SyncFitbitDataQuery, Types.SyncFitbitDataQueryVariables>(SyncFitbitDataDocument, options);
      }
export function useSyncFitbitDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SyncFitbitDataQuery, Types.SyncFitbitDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SyncFitbitDataQuery, Types.SyncFitbitDataQueryVariables>(SyncFitbitDataDocument, options);
        }
export type SyncFitbitDataQueryHookResult = ReturnType<typeof useSyncFitbitDataQuery>;
export type SyncFitbitDataLazyQueryHookResult = ReturnType<typeof useSyncFitbitDataLazyQuery>;
export type SyncFitbitDataQueryResult = Apollo.QueryResult<Types.SyncFitbitDataQuery, Types.SyncFitbitDataQueryVariables>;
export function refetchSyncFitbitDataQuery(variables: Types.SyncFitbitDataQueryVariables) {
      return { query: SyncFitbitDataDocument, variables: variables }
    }
export const CreateChartDocument = gql`
    mutation CreateChart($payload: CreateChartInput!) {
  createChart(payload: $payload) {
    id
    type
  }
}
    `;
export type CreateChartMutationFn = Apollo.MutationFunction<Types.CreateChartMutation, Types.CreateChartMutationVariables>;

/**
 * __useCreateChartMutation__
 *
 * To run a mutation, you first call `useCreateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChartMutation, { data, loading, error }] = useCreateChartMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateChartMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateChartMutation, Types.CreateChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateChartMutation, Types.CreateChartMutationVariables>(CreateChartDocument, options);
      }
export type CreateChartMutationHookResult = ReturnType<typeof useCreateChartMutation>;
export type CreateChartMutationResult = Apollo.MutationResult<Types.CreateChartMutation>;
export type CreateChartMutationOptions = Apollo.BaseMutationOptions<Types.CreateChartMutation, Types.CreateChartMutationVariables>;
export const DeleteChartDocument = gql`
    mutation DeleteChart($id: Float!) {
  deleteChart(id: $id) {
    id
  }
}
    `;
export type DeleteChartMutationFn = Apollo.MutationFunction<Types.DeleteChartMutation, Types.DeleteChartMutationVariables>;

/**
 * __useDeleteChartMutation__
 *
 * To run a mutation, you first call `useDeleteChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartMutation, { data, loading, error }] = useDeleteChartMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChartMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteChartMutation, Types.DeleteChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteChartMutation, Types.DeleteChartMutationVariables>(DeleteChartDocument, options);
      }
export type DeleteChartMutationHookResult = ReturnType<typeof useDeleteChartMutation>;
export type DeleteChartMutationResult = Apollo.MutationResult<Types.DeleteChartMutation>;
export type DeleteChartMutationOptions = Apollo.BaseMutationOptions<Types.DeleteChartMutation, Types.DeleteChartMutationVariables>;
export const GetChartsDocument = gql`
    query GetCharts($userId: Float!, $categoryId: Float!) {
  getCharts(userId: $userId, categoryId: $categoryId) {
    id
    type
    dataSource {
      id
      label
      questions {
        id
        label
        meta
        type
        options
      }
      options
    }
    plots
    xAxis
    yAxis
    fullWidth
    categoryId
    title
    title1
    type1
    plots1
    yAxis1
    meta
    dataSource1 {
      id
      label
      questions {
        id
        label
        meta
        type
        options
      }
      options
    }
  }
}
    `;

/**
 * __useGetChartsQuery__
 *
 * To run a query within a React component, call `useGetChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetChartsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChartsQuery, Types.GetChartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChartsQuery, Types.GetChartsQueryVariables>(GetChartsDocument, options);
      }
export function useGetChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChartsQuery, Types.GetChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChartsQuery, Types.GetChartsQueryVariables>(GetChartsDocument, options);
        }
export type GetChartsQueryHookResult = ReturnType<typeof useGetChartsQuery>;
export type GetChartsLazyQueryHookResult = ReturnType<typeof useGetChartsLazyQuery>;
export type GetChartsQueryResult = Apollo.QueryResult<Types.GetChartsQuery, Types.GetChartsQueryVariables>;
export function refetchGetChartsQuery(variables: Types.GetChartsQueryVariables) {
      return { query: GetChartsDocument, variables: variables }
    }
export const GraphDataPointsDocument = gql`
    query GraphDataPoints($graphDataPointsInput: GraphDataPointsInput!) {
  graphDataPoints(payload: $graphDataPointsInput) {
    items {
      values
      answerDate
      value
      id
      question {
        id
        type
        label
        meta
        options
      }
    }
  }
}
    `;

/**
 * __useGraphDataPointsQuery__
 *
 * To run a query within a React component, call `useGraphDataPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataPointsQuery({
 *   variables: {
 *      graphDataPointsInput: // value for 'graphDataPointsInput'
 *   },
 * });
 */
export function useGraphDataPointsQuery(baseOptions: Apollo.QueryHookOptions<Types.GraphDataPointsQuery, Types.GraphDataPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GraphDataPointsQuery, Types.GraphDataPointsQueryVariables>(GraphDataPointsDocument, options);
      }
export function useGraphDataPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GraphDataPointsQuery, Types.GraphDataPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GraphDataPointsQuery, Types.GraphDataPointsQueryVariables>(GraphDataPointsDocument, options);
        }
export type GraphDataPointsQueryHookResult = ReturnType<typeof useGraphDataPointsQuery>;
export type GraphDataPointsLazyQueryHookResult = ReturnType<typeof useGraphDataPointsLazyQuery>;
export type GraphDataPointsQueryResult = Apollo.QueryResult<Types.GraphDataPointsQuery, Types.GraphDataPointsQueryVariables>;
export function refetchGraphDataPointsQuery(variables: Types.GraphDataPointsQueryVariables) {
      return { query: GraphDataPointsDocument, variables: variables }
    }
export const GraphDataSourcesDocument = gql`
    query graphDataSources($limit: LimitInput, $where: LibraryWhereInputType, $studyId: Float, $showOnlyQuestions: Boolean) {
  libraries(
    limit: $limit
    where: $where
    studyId: $studyId
    showOnlyQuestions: $showOnlyQuestions
  ) {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        type
        order
        category {
          id
          name
        }
        label
        icon
        identifier
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          type
          order
          label
          icon
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          identifier
          options
          createdAt
          updatedAt
          meta
          questions {
            id
            type
            order
            label
            icon
            tooltip {
              id
              title
              icon
              description
              createdAt
              updatedAt
            }
            category {
              id
              name
            }
            identifier
            options
            createdAt
            updatedAt
            meta
            questions {
              id
              type
              order
              label
              icon
              tooltip {
                id
                title
                icon
                description
                createdAt
                updatedAt
              }
              category {
                id
                name
              }
              identifier
              options
              createdAt
              updatedAt
              meta
              questions {
                id
                type
                order
                label
                icon
                tooltip {
                  id
                  title
                  icon
                  description
                  createdAt
                  updatedAt
                }
                category {
                  id
                  name
                }
                identifier
                options
                createdAt
                updatedAt
                meta
              }
            }
          }
        }
      }
      automations
    }
    count
  }
}
    `;

/**
 * __useGraphDataSourcesQuery__
 *
 * To run a query within a React component, call `useGraphDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphDataSourcesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      studyId: // value for 'studyId'
 *      showOnlyQuestions: // value for 'showOnlyQuestions'
 *   },
 * });
 */
export function useGraphDataSourcesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GraphDataSourcesQuery, Types.GraphDataSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GraphDataSourcesQuery, Types.GraphDataSourcesQueryVariables>(GraphDataSourcesDocument, options);
      }
export function useGraphDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GraphDataSourcesQuery, Types.GraphDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GraphDataSourcesQuery, Types.GraphDataSourcesQueryVariables>(GraphDataSourcesDocument, options);
        }
export type GraphDataSourcesQueryHookResult = ReturnType<typeof useGraphDataSourcesQuery>;
export type GraphDataSourcesLazyQueryHookResult = ReturnType<typeof useGraphDataSourcesLazyQuery>;
export type GraphDataSourcesQueryResult = Apollo.QueryResult<Types.GraphDataSourcesQuery, Types.GraphDataSourcesQueryVariables>;
export function refetchGraphDataSourcesQuery(variables?: Types.GraphDataSourcesQueryVariables) {
      return { query: GraphDataSourcesDocument, variables: variables }
    }
export const UpdateChartDocument = gql`
    mutation UpdateChart($id: Float!, $payload: UpdateChartInput!) {
  updateChart(id: $id, payload: $payload) {
    id
  }
}
    `;
export type UpdateChartMutationFn = Apollo.MutationFunction<Types.UpdateChartMutation, Types.UpdateChartMutationVariables>;

/**
 * __useUpdateChartMutation__
 *
 * To run a mutation, you first call `useUpdateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartMutation, { data, loading, error }] = useUpdateChartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateChartMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateChartMutation, Types.UpdateChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateChartMutation, Types.UpdateChartMutationVariables>(UpdateChartDocument, options);
      }
export type UpdateChartMutationHookResult = ReturnType<typeof useUpdateChartMutation>;
export type UpdateChartMutationResult = Apollo.MutationResult<Types.UpdateChartMutation>;
export type UpdateChartMutationOptions = Apollo.BaseMutationOptions<Types.UpdateChartMutation, Types.UpdateChartMutationVariables>;
export const AllJournalsDocument = gql`
    query allJournals {
  allJournals {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        type
        order
        category {
          id
          name
        }
        label
        icon
        identifier
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          type
          order
          label
          icon
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          identifier
          options
          createdAt
          updatedAt
          meta
          questions {
            id
            type
            order
            label
            icon
            tooltip {
              id
              title
              icon
              description
              createdAt
              updatedAt
            }
            category {
              id
              name
            }
            identifier
            options
            createdAt
            updatedAt
            meta
            questions {
              id
              type
              order
              label
              icon
              tooltip {
                id
                title
                icon
                description
                createdAt
                updatedAt
              }
              category {
                id
                name
              }
              identifier
              options
              createdAt
              updatedAt
              meta
              questions {
                id
                type
                order
                label
                icon
                tooltip {
                  id
                  title
                  icon
                  description
                  createdAt
                  updatedAt
                }
                category {
                  id
                  name
                }
                identifier
                options
                createdAt
                updatedAt
                meta
              }
            }
          }
        }
      }
      automations
    }
    count
  }
}
    `;

/**
 * __useAllJournalsQuery__
 *
 * To run a query within a React component, call `useAllJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJournalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllJournalsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllJournalsQuery, Types.AllJournalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllJournalsQuery, Types.AllJournalsQueryVariables>(AllJournalsDocument, options);
      }
export function useAllJournalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllJournalsQuery, Types.AllJournalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllJournalsQuery, Types.AllJournalsQueryVariables>(AllJournalsDocument, options);
        }
export type AllJournalsQueryHookResult = ReturnType<typeof useAllJournalsQuery>;
export type AllJournalsLazyQueryHookResult = ReturnType<typeof useAllJournalsLazyQuery>;
export type AllJournalsQueryResult = Apollo.QueryResult<Types.AllJournalsQuery, Types.AllJournalsQueryVariables>;
export function refetchAllJournalsQuery(variables?: Types.AllJournalsQueryVariables) {
      return { query: AllJournalsDocument, variables: variables }
    }
export const DailyJournalsDocument = gql`
    query dailyJournals {
  dailyJournals {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        type
        order
        category {
          id
          name
        }
        label
        icon
        identifier
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          type
          order
          label
          icon
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          identifier
          options
          createdAt
          updatedAt
          meta
          questions {
            id
            type
            order
            label
            icon
            tooltip {
              id
              title
              icon
              description
              createdAt
              updatedAt
            }
            category {
              id
              name
            }
            identifier
            options
            createdAt
            updatedAt
            meta
            questions {
              id
              type
              order
              label
              icon
              tooltip {
                id
                title
                icon
                description
                createdAt
                updatedAt
              }
              category {
                id
                name
              }
              identifier
              options
              createdAt
              updatedAt
              meta
              questions {
                id
                type
                order
                label
                icon
                tooltip {
                  id
                  title
                  icon
                  description
                  createdAt
                  updatedAt
                }
                category {
                  id
                  name
                }
                identifier
                options
                createdAt
                updatedAt
                meta
              }
            }
          }
        }
      }
      automations
      libraryConfigs {
        id
        repeatText
        offset
        duration
        repeat
        order
      }
    }
    count
  }
}
    `;

/**
 * __useDailyJournalsQuery__
 *
 * To run a query within a React component, call `useDailyJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyJournalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyJournalsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DailyJournalsQuery, Types.DailyJournalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DailyJournalsQuery, Types.DailyJournalsQueryVariables>(DailyJournalsDocument, options);
      }
export function useDailyJournalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DailyJournalsQuery, Types.DailyJournalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DailyJournalsQuery, Types.DailyJournalsQueryVariables>(DailyJournalsDocument, options);
        }
export type DailyJournalsQueryHookResult = ReturnType<typeof useDailyJournalsQuery>;
export type DailyJournalsLazyQueryHookResult = ReturnType<typeof useDailyJournalsLazyQuery>;
export type DailyJournalsQueryResult = Apollo.QueryResult<Types.DailyJournalsQuery, Types.DailyJournalsQueryVariables>;
export function refetchDailyJournalsQuery(variables?: Types.DailyJournalsQueryVariables) {
      return { query: DailyJournalsDocument, variables: variables }
    }
export const DailyAssessmentsDocument = gql`
    query dailyAssessments {
  dailyAssessments {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        order
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        category {
          id
          name
        }
        label
        icon
        identifier
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          order
          type
          label
          icon
          meta
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
      automations
    }
    types
    count
  }
}
    `;

/**
 * __useDailyAssessmentsQuery__
 *
 * To run a query within a React component, call `useDailyAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyAssessmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyAssessmentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DailyAssessmentsQuery, Types.DailyAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DailyAssessmentsQuery, Types.DailyAssessmentsQueryVariables>(DailyAssessmentsDocument, options);
      }
export function useDailyAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DailyAssessmentsQuery, Types.DailyAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DailyAssessmentsQuery, Types.DailyAssessmentsQueryVariables>(DailyAssessmentsDocument, options);
        }
export type DailyAssessmentsQueryHookResult = ReturnType<typeof useDailyAssessmentsQuery>;
export type DailyAssessmentsLazyQueryHookResult = ReturnType<typeof useDailyAssessmentsLazyQuery>;
export type DailyAssessmentsQueryResult = Apollo.QueryResult<Types.DailyAssessmentsQuery, Types.DailyAssessmentsQueryVariables>;
export function refetchDailyAssessmentsQuery(variables?: Types.DailyAssessmentsQueryVariables) {
      return { query: DailyAssessmentsDocument, variables: variables }
    }
export const DeleteLibraryDocument = gql`
    mutation deleteLibrary($id: Float!) {
  deleteLibrary(id: $id)
}
    `;
export type DeleteLibraryMutationFn = Apollo.MutationFunction<Types.DeleteLibraryMutation, Types.DeleteLibraryMutationVariables>;

/**
 * __useDeleteLibraryMutation__
 *
 * To run a mutation, you first call `useDeleteLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLibraryMutation, { data, loading, error }] = useDeleteLibraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLibraryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLibraryMutation, Types.DeleteLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLibraryMutation, Types.DeleteLibraryMutationVariables>(DeleteLibraryDocument, options);
      }
export type DeleteLibraryMutationHookResult = ReturnType<typeof useDeleteLibraryMutation>;
export type DeleteLibraryMutationResult = Apollo.MutationResult<Types.DeleteLibraryMutation>;
export type DeleteLibraryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLibraryMutation, Types.DeleteLibraryMutationVariables>;
export const CanDeleteLibraryDocument = gql`
    query canDeleteLibrary($id: Float!) {
  canDeleteLibrary(id: $id) {
    canDelete
    message
  }
}
    `;

/**
 * __useCanDeleteLibraryQuery__
 *
 * To run a query within a React component, call `useCanDeleteLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanDeleteLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanDeleteLibraryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCanDeleteLibraryQuery(baseOptions: Apollo.QueryHookOptions<Types.CanDeleteLibraryQuery, Types.CanDeleteLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CanDeleteLibraryQuery, Types.CanDeleteLibraryQueryVariables>(CanDeleteLibraryDocument, options);
      }
export function useCanDeleteLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CanDeleteLibraryQuery, Types.CanDeleteLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CanDeleteLibraryQuery, Types.CanDeleteLibraryQueryVariables>(CanDeleteLibraryDocument, options);
        }
export type CanDeleteLibraryQueryHookResult = ReturnType<typeof useCanDeleteLibraryQuery>;
export type CanDeleteLibraryLazyQueryHookResult = ReturnType<typeof useCanDeleteLibraryLazyQuery>;
export type CanDeleteLibraryQueryResult = Apollo.QueryResult<Types.CanDeleteLibraryQuery, Types.CanDeleteLibraryQueryVariables>;
export function refetchCanDeleteLibraryQuery(variables: Types.CanDeleteLibraryQueryVariables) {
      return { query: CanDeleteLibraryDocument, variables: variables }
    }
export const JournalDocument = gql`
    query journal($limit: LimitInput, $where: LibraryWhereInputType, $studyId: Float, $showOnlyQuestions: Boolean) {
  libraries(
    limit: $limit
    where: $where
    studyId: $studyId
    showOnlyQuestions: $showOnlyQuestions
  ) {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        type
        order
        category {
          id
          name
        }
        label
        icon
        identifier
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          type
          order
          label
          icon
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          identifier
          options
          createdAt
          updatedAt
          meta
          questions {
            id
            type
            order
            label
            icon
            tooltip {
              id
              title
              icon
              description
              createdAt
              updatedAt
            }
            category {
              id
              name
            }
            identifier
            options
            createdAt
            updatedAt
            meta
            questions {
              id
              type
              order
              label
              icon
              tooltip {
                id
                title
                icon
                description
                createdAt
                updatedAt
              }
              category {
                id
                name
              }
              identifier
              options
              createdAt
              updatedAt
              meta
              questions {
                id
                type
                order
                label
                icon
                tooltip {
                  id
                  title
                  icon
                  description
                  createdAt
                  updatedAt
                }
                category {
                  id
                  name
                }
                identifier
                options
                createdAt
                updatedAt
                meta
              }
            }
          }
        }
      }
      automations
      libraryConfigs {
        id
        repeatText
        offset
        duration
        repeat
        order
      }
    }
  }
}
    `;

/**
 * __useJournalQuery__
 *
 * To run a query within a React component, call `useJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      studyId: // value for 'studyId'
 *      showOnlyQuestions: // value for 'showOnlyQuestions'
 *   },
 * });
 */
export function useJournalQuery(baseOptions?: Apollo.QueryHookOptions<Types.JournalQuery, Types.JournalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JournalQuery, Types.JournalQueryVariables>(JournalDocument, options);
      }
export function useJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JournalQuery, Types.JournalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JournalQuery, Types.JournalQueryVariables>(JournalDocument, options);
        }
export type JournalQueryHookResult = ReturnType<typeof useJournalQuery>;
export type JournalLazyQueryHookResult = ReturnType<typeof useJournalLazyQuery>;
export type JournalQueryResult = Apollo.QueryResult<Types.JournalQuery, Types.JournalQueryVariables>;
export function refetchJournalQuery(variables?: Types.JournalQueryVariables) {
      return { query: JournalDocument, variables: variables }
    }
export const LibrariesDocument = gql`
    query libraries($limit: LimitInput, $where: LibraryWhereInputType, $studyId: Float, $showOnlyQuestions: Boolean) {
  libraries(
    limit: $limit
    where: $where
    studyId: $studyId
    showOnlyQuestions: $showOnlyQuestions
  ) {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        order
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        category {
          id
          name
        }
        label
        icon
        identifier
        variableName
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          order
          type
          label
          icon
          meta
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
      automations
    }
    types
    count
  }
}
    `;

/**
 * __useLibrariesQuery__
 *
 * To run a query within a React component, call `useLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibrariesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      studyId: // value for 'studyId'
 *      showOnlyQuestions: // value for 'showOnlyQuestions'
 *   },
 * });
 */
export function useLibrariesQuery(baseOptions?: Apollo.QueryHookOptions<Types.LibrariesQuery, Types.LibrariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LibrariesQuery, Types.LibrariesQueryVariables>(LibrariesDocument, options);
      }
export function useLibrariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LibrariesQuery, Types.LibrariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LibrariesQuery, Types.LibrariesQueryVariables>(LibrariesDocument, options);
        }
export type LibrariesQueryHookResult = ReturnType<typeof useLibrariesQuery>;
export type LibrariesLazyQueryHookResult = ReturnType<typeof useLibrariesLazyQuery>;
export type LibrariesQueryResult = Apollo.QueryResult<Types.LibrariesQuery, Types.LibrariesQueryVariables>;
export function refetchLibrariesQuery(variables?: Types.LibrariesQueryVariables) {
      return { query: LibrariesDocument, variables: variables }
    }
export const LibrariesWithOnlyQuestionsDocument = gql`
    query librariesWithOnlyQuestions($limit: LimitInput, $where: LibraryWhereInputType, $studyId: Float, $showOnlyQuestions: Boolean) {
  libraries(
    limit: $limit
    where: $where
    studyId: $studyId
    showOnlyQuestions: $showOnlyQuestions
  ) {
    items {
      id
      type
      title
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      questions {
        id
        type
        label
        questions {
          id
          label
        }
      }
    }
    types
    count
  }
}
    `;

/**
 * __useLibrariesWithOnlyQuestionsQuery__
 *
 * To run a query within a React component, call `useLibrariesWithOnlyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibrariesWithOnlyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibrariesWithOnlyQuestionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      studyId: // value for 'studyId'
 *      showOnlyQuestions: // value for 'showOnlyQuestions'
 *   },
 * });
 */
export function useLibrariesWithOnlyQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.LibrariesWithOnlyQuestionsQuery, Types.LibrariesWithOnlyQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LibrariesWithOnlyQuestionsQuery, Types.LibrariesWithOnlyQuestionsQueryVariables>(LibrariesWithOnlyQuestionsDocument, options);
      }
export function useLibrariesWithOnlyQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LibrariesWithOnlyQuestionsQuery, Types.LibrariesWithOnlyQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LibrariesWithOnlyQuestionsQuery, Types.LibrariesWithOnlyQuestionsQueryVariables>(LibrariesWithOnlyQuestionsDocument, options);
        }
export type LibrariesWithOnlyQuestionsQueryHookResult = ReturnType<typeof useLibrariesWithOnlyQuestionsQuery>;
export type LibrariesWithOnlyQuestionsLazyQueryHookResult = ReturnType<typeof useLibrariesWithOnlyQuestionsLazyQuery>;
export type LibrariesWithOnlyQuestionsQueryResult = Apollo.QueryResult<Types.LibrariesWithOnlyQuestionsQuery, Types.LibrariesWithOnlyQuestionsQueryVariables>;
export function refetchLibrariesWithOnlyQuestionsQuery(variables?: Types.LibrariesWithOnlyQuestionsQueryVariables) {
      return { query: LibrariesWithOnlyQuestionsDocument, variables: variables }
    }
export const LibraryDocument = gql`
    query library($id: Float!) {
  library(id: $id) {
    id
    type
    duration
    icon
    title
    preIVRDuration
    preIVRDurationType
    initialMessage
    tooltip {
      id
      title
      createdAt
      deletedAt
      description
      icon
      updatedAt
    }
    creator {
      id
      name
      email
      createdAt
      updatedAt
    }
    superset {
      id
      type
      duration
      icon
      title
      type
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
    }
    subsets {
      id
      type
      duration
      icon
      title
      type
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
    }
    createdAt
    updatedAt
    questions {
      id
      order
      type
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      category {
        id
        name
      }
      label
      icon
      identifier
      variableName
      symptomName
      symptomNameEs
      options
      createdAt
      updatedAt
      conditions
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      meta
      questions {
        id
        order
        type
        icon
        conditions
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        category {
          id
          name
        }
        label
        icon
        identifier
        variableName
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          order
          type
          icon
          conditions
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          label
          icon
          identifier
          variableName
          options
          createdAt
          updatedAt
          tooltip {
            id
            title
            createdAt
            deletedAt
            description
            icon
            updatedAt
          }
          meta
          questions {
            id
            order
            type
            icon
            tooltip {
              id
              title
              icon
              description
              createdAt
              updatedAt
            }
            conditions
            category {
              id
              name
            }
            label
            icon
            identifier
            variableName
            options
            createdAt
            updatedAt
            tooltip {
              id
              title
              createdAt
              deletedAt
              description
              icon
              updatedAt
            }
            meta
            questions {
              id
              order
              type
              icon
              tooltip {
                id
                title
                icon
                description
                createdAt
                updatedAt
              }
              category {
                id
                name
              }
              conditions
              label
              icon
              identifier
              variableName
              options
              createdAt
              updatedAt
              tooltip {
                id
                title
                createdAt
                deletedAt
                description
                icon
                updatedAt
              }
              meta
              questions {
                id
                order
                type
                icon
                tooltip {
                  id
                  title
                  icon
                  description
                  createdAt
                  updatedAt
                }
                category {
                  id
                  name
                }
                conditions
                label
                icon
                identifier
                variableName
                options
                createdAt
                updatedAt
                tooltip {
                  id
                  title
                  createdAt
                  deletedAt
                  description
                  icon
                  updatedAt
                }
                meta
              }
            }
          }
        }
      }
    }
    automations
  }
}
    `;

/**
 * __useLibraryQuery__
 *
 * To run a query within a React component, call `useLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLibraryQuery(baseOptions: Apollo.QueryHookOptions<Types.LibraryQuery, Types.LibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LibraryQuery, Types.LibraryQueryVariables>(LibraryDocument, options);
      }
export function useLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LibraryQuery, Types.LibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LibraryQuery, Types.LibraryQueryVariables>(LibraryDocument, options);
        }
export type LibraryQueryHookResult = ReturnType<typeof useLibraryQuery>;
export type LibraryLazyQueryHookResult = ReturnType<typeof useLibraryLazyQuery>;
export type LibraryQueryResult = Apollo.QueryResult<Types.LibraryQuery, Types.LibraryQueryVariables>;
export function refetchLibraryQuery(variables: Types.LibraryQueryVariables) {
      return { query: LibraryDocument, variables: variables }
    }
export const CreateLibraryDocument = gql`
    mutation createLibrary($title: String!, $initialMessage: String, $icon: String, $iconFile: [Upload!], $duration: Float, $type: String, $questions: [AssessmentQuestionInputType!], $automations: [AutomationInputType!], $preIVRDuration: Float, $preIVRDurationType: String) {
  createLibrary(
    title: $title
    initialMessage: $initialMessage
    icon: $icon
    iconFile: $iconFile
    duration: $duration
    type: $type
    questions: $questions
    automations: $automations
    preIVRDuration: $preIVRDuration
    preIVRDurationType: $preIVRDurationType
  ) {
    id
    title
    initialMessage
    type
    duration
    icon
    tooltip {
      id
      title
      icon
      description
      createdAt
      updatedAt
    }
    creator {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    questions {
      id
      order
      type
      type
      label
      identifier
      variableName
      symptomName
      symptomNameEs
      options
      createdAt
      updatedAt
      conditions
      questions {
        id
        order
        type
        label
        icon
        conditions
        createdAt
        updatedAt
      }
    }
    automations
  }
}
    `;
export type CreateLibraryMutationFn = Apollo.MutationFunction<Types.CreateLibraryMutation, Types.CreateLibraryMutationVariables>;

/**
 * __useCreateLibraryMutation__
 *
 * To run a mutation, you first call `useCreateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibraryMutation, { data, loading, error }] = useCreateLibraryMutation({
 *   variables: {
 *      title: // value for 'title'
 *      initialMessage: // value for 'initialMessage'
 *      icon: // value for 'icon'
 *      iconFile: // value for 'iconFile'
 *      duration: // value for 'duration'
 *      type: // value for 'type'
 *      questions: // value for 'questions'
 *      automations: // value for 'automations'
 *      preIVRDuration: // value for 'preIVRDuration'
 *      preIVRDurationType: // value for 'preIVRDurationType'
 *   },
 * });
 */
export function useCreateLibraryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLibraryMutation, Types.CreateLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLibraryMutation, Types.CreateLibraryMutationVariables>(CreateLibraryDocument, options);
      }
export type CreateLibraryMutationHookResult = ReturnType<typeof useCreateLibraryMutation>;
export type CreateLibraryMutationResult = Apollo.MutationResult<Types.CreateLibraryMutation>;
export type CreateLibraryMutationOptions = Apollo.BaseMutationOptions<Types.CreateLibraryMutation, Types.CreateLibraryMutationVariables>;
export const UpdateLibraryDocument = gql`
    mutation updateLibrary($id: Float!, $title: String!, $initialMessage: String, $iconFile: [Upload!], $duration: Float, $questions: [AssessmentQuestionInputType!], $automations: [AutomationInputType!], $preIVRDuration: Float, $preIVRDurationType: String) {
  updateLibrary(
    id: $id
    title: $title
    initialMessage: $initialMessage
    iconFile: $iconFile
    duration: $duration
    questions: $questions
    automations: $automations
    preIVRDuration: $preIVRDuration
    preIVRDurationType: $preIVRDurationType
  ) {
    id
    title
    initialMessage
    type
    duration
    icon
    tooltip {
      id
      title
      icon
      description
      createdAt
      updatedAt
    }
    questions {
      id
      order
      type
      label
      identifier
      variableName
      symptomName
      symptomNameEs
      options
      questions {
        id
        order
        type
        label
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
    automations
  }
}
    `;
export type UpdateLibraryMutationFn = Apollo.MutationFunction<Types.UpdateLibraryMutation, Types.UpdateLibraryMutationVariables>;

/**
 * __useUpdateLibraryMutation__
 *
 * To run a mutation, you first call `useUpdateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLibraryMutation, { data, loading, error }] = useUpdateLibraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      initialMessage: // value for 'initialMessage'
 *      iconFile: // value for 'iconFile'
 *      duration: // value for 'duration'
 *      questions: // value for 'questions'
 *      automations: // value for 'automations'
 *      preIVRDuration: // value for 'preIVRDuration'
 *      preIVRDurationType: // value for 'preIVRDurationType'
 *   },
 * });
 */
export function useUpdateLibraryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLibraryMutation, Types.UpdateLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLibraryMutation, Types.UpdateLibraryMutationVariables>(UpdateLibraryDocument, options);
      }
export type UpdateLibraryMutationHookResult = ReturnType<typeof useUpdateLibraryMutation>;
export type UpdateLibraryMutationResult = Apollo.MutationResult<Types.UpdateLibraryMutation>;
export type UpdateLibraryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLibraryMutation, Types.UpdateLibraryMutationVariables>;
export const CreateLibrarySubsetDocument = gql`
    mutation createLibrarySubset($supersetId: Float!, $title: String!, $questionIds: [String!]) {
  createLibrarySubset(
    supersetId: $supersetId
    title: $title
    questionIds: $questionIds
  ) {
    id
    title
    type
    duration
    icon
    tooltip {
      id
      title
      icon
      description
      createdAt
      updatedAt
    }
    creator {
      id
      name
      email
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    questions {
      id
      type
      icon
      tooltip {
        id
        title
        icon
        description
        createdAt
        updatedAt
      }
      category {
        id
        name
      }
      label
      identifier
      variableName
      symptomName
      symptomNameEs
      options
      createdAt
      updatedAt
    }
    automations
  }
}
    `;
export type CreateLibrarySubsetMutationFn = Apollo.MutationFunction<Types.CreateLibrarySubsetMutation, Types.CreateLibrarySubsetMutationVariables>;

/**
 * __useCreateLibrarySubsetMutation__
 *
 * To run a mutation, you first call `useCreateLibrarySubsetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibrarySubsetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibrarySubsetMutation, { data, loading, error }] = useCreateLibrarySubsetMutation({
 *   variables: {
 *      supersetId: // value for 'supersetId'
 *      title: // value for 'title'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useCreateLibrarySubsetMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLibrarySubsetMutation, Types.CreateLibrarySubsetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLibrarySubsetMutation, Types.CreateLibrarySubsetMutationVariables>(CreateLibrarySubsetDocument, options);
      }
export type CreateLibrarySubsetMutationHookResult = ReturnType<typeof useCreateLibrarySubsetMutation>;
export type CreateLibrarySubsetMutationResult = Apollo.MutationResult<Types.CreateLibrarySubsetMutation>;
export type CreateLibrarySubsetMutationOptions = Apollo.BaseMutationOptions<Types.CreateLibrarySubsetMutation, Types.CreateLibrarySubsetMutationVariables>;
export const UploadIconDocument = gql`
    mutation uploadIcon($iconFile: [Upload!]) {
  uploadIcon(iconFile: $iconFile)
}
    `;
export type UploadIconMutationFn = Apollo.MutationFunction<Types.UploadIconMutation, Types.UploadIconMutationVariables>;

/**
 * __useUploadIconMutation__
 *
 * To run a mutation, you first call `useUploadIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadIconMutation, { data, loading, error }] = useUploadIconMutation({
 *   variables: {
 *      iconFile: // value for 'iconFile'
 *   },
 * });
 */
export function useUploadIconMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadIconMutation, Types.UploadIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadIconMutation, Types.UploadIconMutationVariables>(UploadIconDocument, options);
      }
export type UploadIconMutationHookResult = ReturnType<typeof useUploadIconMutation>;
export type UploadIconMutationResult = Apollo.MutationResult<Types.UploadIconMutation>;
export type UploadIconMutationOptions = Apollo.BaseMutationOptions<Types.UploadIconMutation, Types.UploadIconMutationVariables>;
export const ParticipantCoachingLibrariesForDateDocument = gql`
    query participantCoachingLibrariesForDate($userId: Float!, $date: String!, $limit: LimitInput, $where: LibraryWhereInputType, $studyId: Float) {
  participantCoachingLibrariesForDate(
    userId: $userId
    date: $date
    limit: $limit
    where: $where
    studyId: $studyId
  ) {
    items {
      id
      type
      duration
      icon
      title
      tooltip {
        id
        title
        createdAt
        deletedAt
        description
        icon
        updatedAt
      }
      creator {
        id
        name
        email
        createdAt
        updatedAt
      }
      superset {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      subsets {
        id
        type
        duration
        icon
        title
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        creator {
          id
          name
          email
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      questions {
        id
        order
        type
        icon
        tooltip {
          id
          title
          icon
          description
          createdAt
          updatedAt
        }
        category {
          id
          name
        }
        label
        icon
        identifier
        variableName
        options
        createdAt
        updatedAt
        tooltip {
          id
          title
          createdAt
          deletedAt
          description
          icon
          updatedAt
        }
        meta
        questions {
          id
          order
          type
          label
          icon
          meta
          tooltip {
            id
            title
            icon
            description
            createdAt
            updatedAt
          }
          category {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
      automations
    }
    types
    count
  }
}
    `;

/**
 * __useParticipantCoachingLibrariesForDateQuery__
 *
 * To run a query within a React component, call `useParticipantCoachingLibrariesForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantCoachingLibrariesForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantCoachingLibrariesForDateQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useParticipantCoachingLibrariesForDateQuery(baseOptions: Apollo.QueryHookOptions<Types.ParticipantCoachingLibrariesForDateQuery, Types.ParticipantCoachingLibrariesForDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ParticipantCoachingLibrariesForDateQuery, Types.ParticipantCoachingLibrariesForDateQueryVariables>(ParticipantCoachingLibrariesForDateDocument, options);
      }
export function useParticipantCoachingLibrariesForDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ParticipantCoachingLibrariesForDateQuery, Types.ParticipantCoachingLibrariesForDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ParticipantCoachingLibrariesForDateQuery, Types.ParticipantCoachingLibrariesForDateQueryVariables>(ParticipantCoachingLibrariesForDateDocument, options);
        }
export type ParticipantCoachingLibrariesForDateQueryHookResult = ReturnType<typeof useParticipantCoachingLibrariesForDateQuery>;
export type ParticipantCoachingLibrariesForDateLazyQueryHookResult = ReturnType<typeof useParticipantCoachingLibrariesForDateLazyQuery>;
export type ParticipantCoachingLibrariesForDateQueryResult = Apollo.QueryResult<Types.ParticipantCoachingLibrariesForDateQuery, Types.ParticipantCoachingLibrariesForDateQueryVariables>;
export function refetchParticipantCoachingLibrariesForDateQuery(variables: Types.ParticipantCoachingLibrariesForDateQueryVariables) {
      return { query: ParticipantCoachingLibrariesForDateDocument, variables: variables }
    }
export const MedicationDocument = gql`
    query medication($id: Float!) {
  medication(id: $id) {
    id
    userId
    label
    description
    treatment
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMedicationQuery__
 *
 * To run a query within a React component, call `useMedicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicationQuery(baseOptions: Apollo.QueryHookOptions<Types.MedicationQuery, Types.MedicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MedicationQuery, Types.MedicationQueryVariables>(MedicationDocument, options);
      }
export function useMedicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MedicationQuery, Types.MedicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MedicationQuery, Types.MedicationQueryVariables>(MedicationDocument, options);
        }
export type MedicationQueryHookResult = ReturnType<typeof useMedicationQuery>;
export type MedicationLazyQueryHookResult = ReturnType<typeof useMedicationLazyQuery>;
export type MedicationQueryResult = Apollo.QueryResult<Types.MedicationQuery, Types.MedicationQueryVariables>;
export function refetchMedicationQuery(variables: Types.MedicationQueryVariables) {
      return { query: MedicationDocument, variables: variables }
    }
export const AddMedicationDocument = gql`
    mutation addMedication($userId: Float!, $data: MedicationInputType!) {
  addMedication(userId: $userId, data: $data) {
    id
    userId
    label
    description
    treatment
    createdAt
    updatedAt
  }
}
    `;
export type AddMedicationMutationFn = Apollo.MutationFunction<Types.AddMedicationMutation, Types.AddMedicationMutationVariables>;

/**
 * __useAddMedicationMutation__
 *
 * To run a mutation, you first call `useAddMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMedicationMutation, { data, loading, error }] = useAddMedicationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddMedicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddMedicationMutation, Types.AddMedicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddMedicationMutation, Types.AddMedicationMutationVariables>(AddMedicationDocument, options);
      }
export type AddMedicationMutationHookResult = ReturnType<typeof useAddMedicationMutation>;
export type AddMedicationMutationResult = Apollo.MutationResult<Types.AddMedicationMutation>;
export type AddMedicationMutationOptions = Apollo.BaseMutationOptions<Types.AddMedicationMutation, Types.AddMedicationMutationVariables>;
export const MedicationsDocument = gql`
    query medications($limit: LimitInput, $where: MedicationWhereInputType) {
  medications(limit: $limit, where: $where) {
    items {
      id
      userId
      label
      description
      treatment
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useMedicationsQuery__
 *
 * To run a query within a React component, call `useMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMedicationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MedicationsQuery, Types.MedicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MedicationsQuery, Types.MedicationsQueryVariables>(MedicationsDocument, options);
      }
export function useMedicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MedicationsQuery, Types.MedicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MedicationsQuery, Types.MedicationsQueryVariables>(MedicationsDocument, options);
        }
export type MedicationsQueryHookResult = ReturnType<typeof useMedicationsQuery>;
export type MedicationsLazyQueryHookResult = ReturnType<typeof useMedicationsLazyQuery>;
export type MedicationsQueryResult = Apollo.QueryResult<Types.MedicationsQuery, Types.MedicationsQueryVariables>;
export function refetchMedicationsQuery(variables?: Types.MedicationsQueryVariables) {
      return { query: MedicationsDocument, variables: variables }
    }
export const CountMessagesDocument = gql`
    query countMessages($search: String, $user: Float, $type: String, $where: MessagesInput) {
  countMessages(search: $search, user: $user, type: $type, where: $where)
}
    `;

/**
 * __useCountMessagesQuery__
 *
 * To run a query within a React component, call `useCountMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMessagesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      type: // value for 'type'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountMessagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountMessagesQuery, Types.CountMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountMessagesQuery, Types.CountMessagesQueryVariables>(CountMessagesDocument, options);
      }
export function useCountMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountMessagesQuery, Types.CountMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountMessagesQuery, Types.CountMessagesQueryVariables>(CountMessagesDocument, options);
        }
export type CountMessagesQueryHookResult = ReturnType<typeof useCountMessagesQuery>;
export type CountMessagesLazyQueryHookResult = ReturnType<typeof useCountMessagesLazyQuery>;
export type CountMessagesQueryResult = Apollo.QueryResult<Types.CountMessagesQuery, Types.CountMessagesQueryVariables>;
export function refetchCountMessagesQuery(variables?: Types.CountMessagesQueryVariables) {
      return { query: CountMessagesDocument, variables: variables }
    }
export const CreateMessageDocument = gql`
    mutation createMessage($createMessageInput: CreateMessageInput!) {
  createMessage(createMessageInput: $createMessageInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      createMessageInput: // value for 'createMessageInput'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<Types.CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<Types.CreateMessageMutation, Types.CreateMessageMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation deleteMessage($id: Float!) {
  deleteMessage(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<Types.DeleteMessageMutation, Types.DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMessageMutation, Types.DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMessageMutation, Types.DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<Types.DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMessageMutation, Types.DeleteMessageMutationVariables>;
export const LikeAllMessagesDocument = gql`
    mutation likeAllMessages($user: Float) {
  likeAllMessages(user: $user)
}
    `;
export type LikeAllMessagesMutationFn = Apollo.MutationFunction<Types.LikeAllMessagesMutation, Types.LikeAllMessagesMutationVariables>;

/**
 * __useLikeAllMessagesMutation__
 *
 * To run a mutation, you first call `useLikeAllMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllMessagesMutation, { data, loading, error }] = useLikeAllMessagesMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLikeAllMessagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllMessagesMutation, Types.LikeAllMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllMessagesMutation, Types.LikeAllMessagesMutationVariables>(LikeAllMessagesDocument, options);
      }
export type LikeAllMessagesMutationHookResult = ReturnType<typeof useLikeAllMessagesMutation>;
export type LikeAllMessagesMutationResult = Apollo.MutationResult<Types.LikeAllMessagesMutation>;
export type LikeAllMessagesMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllMessagesMutation, Types.LikeAllMessagesMutationVariables>;
export const MessageDocument = gql`
    query message($id: Float!) {
  message(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMessageQuery__
 *
 * To run a query within a React component, call `useMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageQuery(baseOptions: Apollo.QueryHookOptions<Types.MessageQuery, Types.MessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MessageQuery, Types.MessageQueryVariables>(MessageDocument, options);
      }
export function useMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MessageQuery, Types.MessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MessageQuery, Types.MessageQueryVariables>(MessageDocument, options);
        }
export type MessageQueryHookResult = ReturnType<typeof useMessageQuery>;
export type MessageLazyQueryHookResult = ReturnType<typeof useMessageLazyQuery>;
export type MessageQueryResult = Apollo.QueryResult<Types.MessageQuery, Types.MessageQueryVariables>;
export function refetchMessageQuery(variables: Types.MessageQueryVariables) {
      return { query: MessageDocument, variables: variables }
    }
export const MessagesDocument = gql`
    query messages($search: String, $user: Float, $correspondingUser: Float, $type: String, $studyId: Float, $studyArmId: Float, $limit: LimitInput, $where: MessagesInput) {
  messages(
    search: $search
    user: $user
    correspondingUser: $correspondingUser
    type: $type
    studyId: $studyId
    studyArmId: $studyArmId
    limit: $limit
    where: $where
  ) {
    items {
      id
      title
      description
      data
      labels
      actions
      viewed
      liked
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    count
    isGroupChat
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      correspondingUser: // value for 'correspondingUser'
 *      type: // value for 'type'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.MessagesQuery, Types.MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MessagesQuery, Types.MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MessagesQuery, Types.MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MessagesQuery, Types.MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<Types.MessagesQuery, Types.MessagesQueryVariables>;
export function refetchMessagesQuery(variables?: Types.MessagesQueryVariables) {
      return { query: MessagesDocument, variables: variables }
    }
export const UpdateMessageDocument = gql`
    mutation updateMessage($id: Float!, $updateMessageInput: UpdateMessageInput!) {
  updateMessage(id: $id, updateMessageInput: $updateMessageInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type UpdateMessageMutationFn = Apollo.MutationFunction<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateMessageInput: // value for 'updateMessageInput'
 *   },
 * });
 */
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<Types.UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>;
export const UsersMessagesDocument = gql`
    query usersMessages($search: String, $studyId: Float, $studyArmId: Float, $type: String, $limit: LimitInput) {
  usersMessages(
    search: $search
    studyId: $studyId
    studyArmId: $studyArmId
    type: $type
    limit: $limit
  ) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      createdAt
      updatedAt
      lastMessageAt
    }
    count
  }
}
    `;

/**
 * __useUsersMessagesQuery__
 *
 * To run a query within a React component, call `useUsersMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersMessagesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersMessagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersMessagesQuery, Types.UsersMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersMessagesQuery, Types.UsersMessagesQueryVariables>(UsersMessagesDocument, options);
      }
export function useUsersMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersMessagesQuery, Types.UsersMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersMessagesQuery, Types.UsersMessagesQueryVariables>(UsersMessagesDocument, options);
        }
export type UsersMessagesQueryHookResult = ReturnType<typeof useUsersMessagesQuery>;
export type UsersMessagesLazyQueryHookResult = ReturnType<typeof useUsersMessagesLazyQuery>;
export type UsersMessagesQueryResult = Apollo.QueryResult<Types.UsersMessagesQuery, Types.UsersMessagesQueryVariables>;
export function refetchUsersMessagesQuery(variables?: Types.UsersMessagesQueryVariables) {
      return { query: UsersMessagesDocument, variables: variables }
    }
export const ViewAllMessagesDocument = gql`
    mutation viewAllMessages($user: Float) {
  viewAllMessages(user: $user)
}
    `;
export type ViewAllMessagesMutationFn = Apollo.MutationFunction<Types.ViewAllMessagesMutation, Types.ViewAllMessagesMutationVariables>;

/**
 * __useViewAllMessagesMutation__
 *
 * To run a mutation, you first call `useViewAllMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllMessagesMutation, { data, loading, error }] = useViewAllMessagesMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useViewAllMessagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllMessagesMutation, Types.ViewAllMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllMessagesMutation, Types.ViewAllMessagesMutationVariables>(ViewAllMessagesDocument, options);
      }
export type ViewAllMessagesMutationHookResult = ReturnType<typeof useViewAllMessagesMutation>;
export type ViewAllMessagesMutationResult = Apollo.MutationResult<Types.ViewAllMessagesMutation>;
export type ViewAllMessagesMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllMessagesMutation, Types.ViewAllMessagesMutationVariables>;
export const CountNotesDocument = gql`
    query countNotes($where: NotesInput) {
  countNotes(where: $where)
}
    `;

/**
 * __useCountNotesQuery__
 *
 * To run a query within a React component, call `useCountNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountNotesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountNotesQuery, Types.CountNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountNotesQuery, Types.CountNotesQueryVariables>(CountNotesDocument, options);
      }
export function useCountNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountNotesQuery, Types.CountNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountNotesQuery, Types.CountNotesQueryVariables>(CountNotesDocument, options);
        }
export type CountNotesQueryHookResult = ReturnType<typeof useCountNotesQuery>;
export type CountNotesLazyQueryHookResult = ReturnType<typeof useCountNotesLazyQuery>;
export type CountNotesQueryResult = Apollo.QueryResult<Types.CountNotesQuery, Types.CountNotesQueryVariables>;
export function refetchCountNotesQuery(variables?: Types.CountNotesQueryVariables) {
      return { query: CountNotesDocument, variables: variables }
    }
export const CreateNoteDocument = gql`
    mutation createNote($createNoteInput: CreateNoteInput!, $files: [Upload!]) {
  createNote(createNoteInput: $createNoteInput, files: $files) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<Types.CreateNoteMutation, Types.CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      createNoteInput: // value for 'createNoteInput'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateNoteMutation, Types.CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateNoteMutation, Types.CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<Types.CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateNoteMutation, Types.CreateNoteMutationVariables>;
export const CoachingAssessmentForNotesDocument = gql`
    query coachingAssessmentForNotes($userId: ID!) {
  coachingAssessmentForNotes(userId: $userId) {
    noteId
    hasCoachingAssessment
  }
}
    `;

/**
 * __useCoachingAssessmentForNotesQuery__
 *
 * To run a query within a React component, call `useCoachingAssessmentForNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachingAssessmentForNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachingAssessmentForNotesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCoachingAssessmentForNotesQuery(baseOptions: Apollo.QueryHookOptions<Types.CoachingAssessmentForNotesQuery, Types.CoachingAssessmentForNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CoachingAssessmentForNotesQuery, Types.CoachingAssessmentForNotesQueryVariables>(CoachingAssessmentForNotesDocument, options);
      }
export function useCoachingAssessmentForNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CoachingAssessmentForNotesQuery, Types.CoachingAssessmentForNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CoachingAssessmentForNotesQuery, Types.CoachingAssessmentForNotesQueryVariables>(CoachingAssessmentForNotesDocument, options);
        }
export type CoachingAssessmentForNotesQueryHookResult = ReturnType<typeof useCoachingAssessmentForNotesQuery>;
export type CoachingAssessmentForNotesLazyQueryHookResult = ReturnType<typeof useCoachingAssessmentForNotesLazyQuery>;
export type CoachingAssessmentForNotesQueryResult = Apollo.QueryResult<Types.CoachingAssessmentForNotesQuery, Types.CoachingAssessmentForNotesQueryVariables>;
export function refetchCoachingAssessmentForNotesQuery(variables: Types.CoachingAssessmentForNotesQueryVariables) {
      return { query: CoachingAssessmentForNotesDocument, variables: variables }
    }
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: Float!) {
  deleteNote(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<Types.DeleteNoteMutation, Types.DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteNoteMutation, Types.DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteNoteMutation, Types.DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<Types.DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteNoteMutation, Types.DeleteNoteMutationVariables>;
export const LikeAllNotesDocument = gql`
    mutation likeAllNotes {
  likeAllNotes
}
    `;
export type LikeAllNotesMutationFn = Apollo.MutationFunction<Types.LikeAllNotesMutation, Types.LikeAllNotesMutationVariables>;

/**
 * __useLikeAllNotesMutation__
 *
 * To run a mutation, you first call `useLikeAllNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllNotesMutation, { data, loading, error }] = useLikeAllNotesMutation({
 *   variables: {
 *   },
 * });
 */
export function useLikeAllNotesMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllNotesMutation, Types.LikeAllNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllNotesMutation, Types.LikeAllNotesMutationVariables>(LikeAllNotesDocument, options);
      }
export type LikeAllNotesMutationHookResult = ReturnType<typeof useLikeAllNotesMutation>;
export type LikeAllNotesMutationResult = Apollo.MutationResult<Types.LikeAllNotesMutation>;
export type LikeAllNotesMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllNotesMutation, Types.LikeAllNotesMutationVariables>;
export const NoteDocument = gql`
    query note($id: Float, $userId: Float, $conferenceId: Float) {
  note(id: $id, userId: $userId, conferenceId: $conferenceId) {
    id
    title
    description
    data
    user {
      id
      name
      email
      createdAt
      updatedAt
    }
    editor {
      id
      name
      email
      privateNotes
      createdAt
      updatedAt
    }
    parentId
    labels
    actions
    status
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      conferenceId: // value for 'conferenceId'
 *   },
 * });
 */
export function useNoteQuery(baseOptions?: Apollo.QueryHookOptions<Types.NoteQuery, Types.NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteQuery, Types.NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteQuery, Types.NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteQuery, Types.NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<Types.NoteQuery, Types.NoteQueryVariables>;
export function refetchNoteQuery(variables?: Types.NoteQueryVariables) {
      return { query: NoteDocument, variables: variables }
    }
export const NotesDocument = gql`
    query notes($limit: LimitInput, $user: Float, $editor: Float, $where: NotesInput) {
  notes(limit: $limit, user: $user, editor: $editor, where: $where) {
    items {
      id
      title
      description
      data
      type
      user {
        id
        name
        email
        createdAt
        updatedAt
      }
      editor {
        id
        name
        email
        privateNotes
        createdAt
        updatedAt
      }
      parentId
      labels
      actions
      status
      viewed
      liked
      isPinned
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      user: // value for 'user'
 *      editor: // value for 'editor'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotesQuery, Types.NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotesQuery, Types.NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotesQuery, Types.NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotesQuery, Types.NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<Types.NotesQuery, Types.NotesQueryVariables>;
export function refetchNotesQuery(variables?: Types.NotesQueryVariables) {
      return { query: NotesDocument, variables: variables }
    }
export const UpdateNoteDocument = gql`
    mutation updateNote($id: Float!, $updateNoteInput: UpdateNoteInput!, $files: [Upload!]) {
  updateNote(id: $id, updateNoteInput: $updateNoteInput, files: $files) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateNoteInput: // value for 'updateNoteInput'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<Types.UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>;
export const ViewAllNotesDocument = gql`
    mutation viewAllNotes {
  viewAllNotes
}
    `;
export type ViewAllNotesMutationFn = Apollo.MutationFunction<Types.ViewAllNotesMutation, Types.ViewAllNotesMutationVariables>;

/**
 * __useViewAllNotesMutation__
 *
 * To run a mutation, you first call `useViewAllNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllNotesMutation, { data, loading, error }] = useViewAllNotesMutation({
 *   variables: {
 *   },
 * });
 */
export function useViewAllNotesMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllNotesMutation, Types.ViewAllNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllNotesMutation, Types.ViewAllNotesMutationVariables>(ViewAllNotesDocument, options);
      }
export type ViewAllNotesMutationHookResult = ReturnType<typeof useViewAllNotesMutation>;
export type ViewAllNotesMutationResult = Apollo.MutationResult<Types.ViewAllNotesMutation>;
export type ViewAllNotesMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllNotesMutation, Types.ViewAllNotesMutationVariables>;
export const CountNotificationsDocument = gql`
    query countNotifications($search: String, $user: Float, $studyId: Float, $studyArmId: Float, $subscribed: Boolean, $where: NotificationsInput) {
  countNotifications(
    search: $search
    user: $user
    studyId: $studyId
    studyArmId: $studyArmId
    subscribed: $subscribed
    where: $where
  )
}
    `;

/**
 * __useCountNotificationsQuery__
 *
 * To run a query within a React component, call `useCountNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountNotificationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      subscribed: // value for 'subscribed'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountNotificationsQuery, Types.CountNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountNotificationsQuery, Types.CountNotificationsQueryVariables>(CountNotificationsDocument, options);
      }
export function useCountNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountNotificationsQuery, Types.CountNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountNotificationsQuery, Types.CountNotificationsQueryVariables>(CountNotificationsDocument, options);
        }
export type CountNotificationsQueryHookResult = ReturnType<typeof useCountNotificationsQuery>;
export type CountNotificationsLazyQueryHookResult = ReturnType<typeof useCountNotificationsLazyQuery>;
export type CountNotificationsQueryResult = Apollo.QueryResult<Types.CountNotificationsQuery, Types.CountNotificationsQueryVariables>;
export function refetchCountNotificationsQuery(variables?: Types.CountNotificationsQueryVariables) {
      return { query: CountNotificationsDocument, variables: variables }
    }
export const CreateNotificationDocument = gql`
    mutation createNotification($createNotificationInput: CreateNotificationInput!) {
  createNotification(createNotificationInput: $createNotificationInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<Types.CreateNotificationMutation, Types.CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      createNotificationInput: // value for 'createNotificationInput'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateNotificationMutation, Types.CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateNotificationMutation, Types.CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<Types.CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<Types.CreateNotificationMutation, Types.CreateNotificationMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: Float!) {
  deleteNotification(id: $id) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<Types.DeleteNotificationMutation, Types.DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteNotificationMutation, Types.DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteNotificationMutation, Types.DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<Types.DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteNotificationMutation, Types.DeleteNotificationMutationVariables>;
export const LikeAllNotificationsDocument = gql`
    mutation likeAllNotifications($user: Float) {
  likeAllNotifications(user: $user)
}
    `;
export type LikeAllNotificationsMutationFn = Apollo.MutationFunction<Types.LikeAllNotificationsMutation, Types.LikeAllNotificationsMutationVariables>;

/**
 * __useLikeAllNotificationsMutation__
 *
 * To run a mutation, you first call `useLikeAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllNotificationsMutation, { data, loading, error }] = useLikeAllNotificationsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLikeAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllNotificationsMutation, Types.LikeAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllNotificationsMutation, Types.LikeAllNotificationsMutationVariables>(LikeAllNotificationsDocument, options);
      }
export type LikeAllNotificationsMutationHookResult = ReturnType<typeof useLikeAllNotificationsMutation>;
export type LikeAllNotificationsMutationResult = Apollo.MutationResult<Types.LikeAllNotificationsMutation>;
export type LikeAllNotificationsMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllNotificationsMutation, Types.LikeAllNotificationsMutationVariables>;
export const NotificationDocument = gql`
    query notification($id: Float!) {
  notification(id: $id) {
    id
    type
    title
    description
    data
    labels
    actions
    viewed
    liked
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(baseOptions: Apollo.QueryHookOptions<Types.NotificationQuery, Types.NotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationQuery, Types.NotificationQueryVariables>(NotificationDocument, options);
      }
export function useNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationQuery, Types.NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationQuery, Types.NotificationQueryVariables>(NotificationDocument, options);
        }
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationQueryResult = Apollo.QueryResult<Types.NotificationQuery, Types.NotificationQueryVariables>;
export function refetchNotificationQuery(variables: Types.NotificationQueryVariables) {
      return { query: NotificationDocument, variables: variables }
    }
export const NotificationsDocument = gql`
    query notifications($search: String, $user: Float, $studyId: Float, $studyArmId: Float, $subscribed: Boolean, $limit: LimitInput, $where: NotificationsInput) {
  notifications(
    search: $search
    user: $user
    studyId: $studyId
    studyArmId: $studyArmId
    subscribed: $subscribed
    limit: $limit
    where: $where
  ) {
    items {
      id
      title
      type
      description
      data
      labels
      actions
      viewed
      liked
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      subscribed: // value for 'subscribed'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<Types.NotificationsQuery, Types.NotificationsQueryVariables>;
export function refetchNotificationsQuery(variables?: Types.NotificationsQueryVariables) {
      return { query: NotificationsDocument, variables: variables }
    }
export const UpdateNotificationDocument = gql`
    mutation updateNotification($id: Float!, $updateNotificationInput: UpdateNotificationInput!) {
  updateNotification(id: $id, updateNotificationInput: $updateNotificationInput) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateNotificationInput: // value for 'updateNotificationInput'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<Types.UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNotificationMutation, Types.UpdateNotificationMutationVariables>;
export const ViewAllNotificationsDocument = gql`
    mutation viewAllNotifications($user: Float) {
  viewAllNotifications(user: $user)
}
    `;
export type ViewAllNotificationsMutationFn = Apollo.MutationFunction<Types.ViewAllNotificationsMutation, Types.ViewAllNotificationsMutationVariables>;

/**
 * __useViewAllNotificationsMutation__
 *
 * To run a mutation, you first call `useViewAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllNotificationsMutation, { data, loading, error }] = useViewAllNotificationsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useViewAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllNotificationsMutation, Types.ViewAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllNotificationsMutation, Types.ViewAllNotificationsMutationVariables>(ViewAllNotificationsDocument, options);
      }
export type ViewAllNotificationsMutationHookResult = ReturnType<typeof useViewAllNotificationsMutation>;
export type ViewAllNotificationsMutationResult = Apollo.MutationResult<Types.ViewAllNotificationsMutation>;
export type ViewAllNotificationsMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllNotificationsMutation, Types.ViewAllNotificationsMutationVariables>;
export const PermissionDocument = gql`
    query permission($id: Float!) {
  permission(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePermissionQuery__
 *
 * To run a query within a React component, call `usePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionQuery(baseOptions: Apollo.QueryHookOptions<Types.PermissionQuery, Types.PermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PermissionQuery, Types.PermissionQueryVariables>(PermissionDocument, options);
      }
export function usePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PermissionQuery, Types.PermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PermissionQuery, Types.PermissionQueryVariables>(PermissionDocument, options);
        }
export type PermissionQueryHookResult = ReturnType<typeof usePermissionQuery>;
export type PermissionLazyQueryHookResult = ReturnType<typeof usePermissionLazyQuery>;
export type PermissionQueryResult = Apollo.QueryResult<Types.PermissionQuery, Types.PermissionQueryVariables>;
export function refetchPermissionQuery(variables: Types.PermissionQueryVariables) {
      return { query: PermissionDocument, variables: variables }
    }
export const UpdatePermissionDocument = gql`
    mutation updatePermission($id: Float!, $permission: PermissionInputType!) {
  updatePermission(id: $id, permission: $permission) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useUpdatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>(UpdatePermissionDocument, options);
      }
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>;
export type UpdatePermissionMutationResult = Apollo.MutationResult<Types.UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePermissionMutation, Types.UpdatePermissionMutationVariables>;
export const PermissionsDocument = gql`
    query permissions($limit: LimitInput, $where: PermissionWhereType) {
  permissions(limit: $limit, where: $where) {
    items {
      id
      name
      description
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PermissionsQuery, Types.PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PermissionsQuery, Types.PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PermissionsQuery, Types.PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PermissionsQuery, Types.PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<Types.PermissionsQuery, Types.PermissionsQueryVariables>;
export function refetchPermissionsQuery(variables?: Types.PermissionsQueryVariables) {
      return { query: PermissionsDocument, variables: variables }
    }
export const QuestionCategoriesDocument = gql`
    query questionCategories($limit: LimitInput, $where: QuestionCategoryWhereInputType) {
  questionCategories(limit: $limit, where: $where) {
    items {
      id
      name
    }
    count
  }
}
    `;

/**
 * __useQuestionCategoriesQuery__
 *
 * To run a query within a React component, call `useQuestionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useQuestionCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionCategoriesQuery, Types.QuestionCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionCategoriesQuery, Types.QuestionCategoriesQueryVariables>(QuestionCategoriesDocument, options);
      }
export function useQuestionCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionCategoriesQuery, Types.QuestionCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionCategoriesQuery, Types.QuestionCategoriesQueryVariables>(QuestionCategoriesDocument, options);
        }
export type QuestionCategoriesQueryHookResult = ReturnType<typeof useQuestionCategoriesQuery>;
export type QuestionCategoriesLazyQueryHookResult = ReturnType<typeof useQuestionCategoriesLazyQuery>;
export type QuestionCategoriesQueryResult = Apollo.QueryResult<Types.QuestionCategoriesQuery, Types.QuestionCategoriesQueryVariables>;
export function refetchQuestionCategoriesQuery(variables?: Types.QuestionCategoriesQueryVariables) {
      return { query: QuestionCategoriesDocument, variables: variables }
    }
export const RoleDocument = gql`
    query role($id: Float!) {
  role(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
    permissions {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<Types.RoleQuery, Types.RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RoleQuery, Types.RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RoleQuery, Types.RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RoleQuery, Types.RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<Types.RoleQuery, Types.RoleQueryVariables>;
export function refetchRoleQuery(variables: Types.RoleQueryVariables) {
      return { query: RoleDocument, variables: variables }
    }
export const CreateRoleDocument = gql`
    mutation createRole($role: RoleInputType!, $permissions: [PermissionPartialInputType!], $userStudyList: [UserStudyInputType!]) {
  createRole(
    role: $role
    permissions: $permissions
    userStudyList: $userStudyList
  ) {
    id
    name
    description
    permissions {
      id
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *      userStudyList: // value for 'userStudyList'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<Types.CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: Float!, $role: RoleInputType!, $permissions: [PermissionPartialInputType!], $userStudyList: [UserStudyInputType!]) {
  updateRole(
    id: $id
    role: $role
    permissions: $permissions
    userStudyList: $userStudyList
  ) {
    id
    name
    description
    permissions {
      id
    }
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *      userStudyList: // value for 'userStudyList'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<Types.UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($ids: [Float!]!) {
  deleteRole(ids: $ids) {
    id
    name
    description
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<Types.DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>;
export const RolesDocument = gql`
    query roles($limit: LimitInput, $where: RoleWhereInputType) {
  roles(limit: $limit, where: $where) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      permissions {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
    count
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<Types.RolesQuery, Types.RolesQueryVariables>;
export function refetchRolesQuery(variables?: Types.RolesQueryVariables) {
      return { query: RolesDocument, variables: variables }
    }
export const SearchRolesDocument = gql`
    query searchRoles($name: String, $staff: Boolean) {
  searchRoles(name: $name, staff: $staff) {
    id
    name
  }
}
    `;

/**
 * __useSearchRolesQuery__
 *
 * To run a query within a React component, call `useSearchRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRolesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      staff: // value for 'staff'
 *   },
 * });
 */
export function useSearchRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchRolesQuery, Types.SearchRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchRolesQuery, Types.SearchRolesQueryVariables>(SearchRolesDocument, options);
      }
export function useSearchRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchRolesQuery, Types.SearchRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchRolesQuery, Types.SearchRolesQueryVariables>(SearchRolesDocument, options);
        }
export type SearchRolesQueryHookResult = ReturnType<typeof useSearchRolesQuery>;
export type SearchRolesLazyQueryHookResult = ReturnType<typeof useSearchRolesLazyQuery>;
export type SearchRolesQueryResult = Apollo.QueryResult<Types.SearchRolesQuery, Types.SearchRolesQueryVariables>;
export function refetchSearchRolesQuery(variables?: Types.SearchRolesQueryVariables) {
      return { query: SearchRolesDocument, variables: variables }
    }
export const FetchOriginalStringDocument = gql`
    query fetchOriginalString($token: String!) {
  fetchOriginalString(token: $token) {
    token
    originalString
  }
}
    `;

/**
 * __useFetchOriginalStringQuery__
 *
 * To run a query within a React component, call `useFetchOriginalStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOriginalStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOriginalStringQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchOriginalStringQuery(baseOptions: Apollo.QueryHookOptions<Types.FetchOriginalStringQuery, Types.FetchOriginalStringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FetchOriginalStringQuery, Types.FetchOriginalStringQueryVariables>(FetchOriginalStringDocument, options);
      }
export function useFetchOriginalStringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FetchOriginalStringQuery, Types.FetchOriginalStringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FetchOriginalStringQuery, Types.FetchOriginalStringQueryVariables>(FetchOriginalStringDocument, options);
        }
export type FetchOriginalStringQueryHookResult = ReturnType<typeof useFetchOriginalStringQuery>;
export type FetchOriginalStringLazyQueryHookResult = ReturnType<typeof useFetchOriginalStringLazyQuery>;
export type FetchOriginalStringQueryResult = Apollo.QueryResult<Types.FetchOriginalStringQuery, Types.FetchOriginalStringQueryVariables>;
export function refetchFetchOriginalStringQuery(variables: Types.FetchOriginalStringQueryVariables) {
      return { query: FetchOriginalStringDocument, variables: variables }
    }
export const StudiesDocument = gql`
    query studies($limit: LimitInput, $where: StudyWhereInputType) {
  studies(limit: $limit, where: $where) {
    items {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
      studyArms {
        id
        name
        createdAt
        updatedAt
        users {
          id
          name
          createdAt
          email
          updatedAt
        }
      }
      staff {
        id
        name
        createdAt
        email
        updatedAt
        roles {
          id
          createdAt
          updatedAt
          study {
            id
            name
            irb
            displayName
            createdAt
            updatedAt
          }
          role {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      modules {
        id
        title
        type
        createdAt
        duration
        updatedAt
      }
    }
    count
  }
}
    `;

/**
 * __useStudiesQuery__
 *
 * To run a query within a React component, call `useStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudiesQuery(baseOptions?: Apollo.QueryHookOptions<Types.StudiesQuery, Types.StudiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudiesQuery, Types.StudiesQueryVariables>(StudiesDocument, options);
      }
export function useStudiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudiesQuery, Types.StudiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudiesQuery, Types.StudiesQueryVariables>(StudiesDocument, options);
        }
export type StudiesQueryHookResult = ReturnType<typeof useStudiesQuery>;
export type StudiesLazyQueryHookResult = ReturnType<typeof useStudiesLazyQuery>;
export type StudiesQueryResult = Apollo.QueryResult<Types.StudiesQuery, Types.StudiesQueryVariables>;
export function refetchStudiesQuery(variables?: Types.StudiesQueryVariables) {
      return { query: StudiesDocument, variables: variables }
    }
export const StudiesWithStudyArmsDocument = gql`
    query studiesWithStudyArms($limit: LimitInput, $where: StudyWhereInputType) {
  studiesWithStudyArms(limit: $limit, where: $where) {
    items {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
      studyArms {
        id
        name
        createdAt
        updatedAt
        users {
          id
          name
          createdAt
          email
          updatedAt
        }
      }
      staff {
        id
        name
        createdAt
        email
        updatedAt
        roles {
          id
          createdAt
          updatedAt
          study {
            id
            name
            irb
            displayName
            createdAt
            updatedAt
          }
          role {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
    count
  }
}
    `;

/**
 * __useStudiesWithStudyArmsQuery__
 *
 * To run a query within a React component, call `useStudiesWithStudyArmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiesWithStudyArmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiesWithStudyArmsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudiesWithStudyArmsQuery(baseOptions?: Apollo.QueryHookOptions<Types.StudiesWithStudyArmsQuery, Types.StudiesWithStudyArmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudiesWithStudyArmsQuery, Types.StudiesWithStudyArmsQueryVariables>(StudiesWithStudyArmsDocument, options);
      }
export function useStudiesWithStudyArmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudiesWithStudyArmsQuery, Types.StudiesWithStudyArmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudiesWithStudyArmsQuery, Types.StudiesWithStudyArmsQueryVariables>(StudiesWithStudyArmsDocument, options);
        }
export type StudiesWithStudyArmsQueryHookResult = ReturnType<typeof useStudiesWithStudyArmsQuery>;
export type StudiesWithStudyArmsLazyQueryHookResult = ReturnType<typeof useStudiesWithStudyArmsLazyQuery>;
export type StudiesWithStudyArmsQueryResult = Apollo.QueryResult<Types.StudiesWithStudyArmsQuery, Types.StudiesWithStudyArmsQueryVariables>;
export function refetchStudiesWithStudyArmsQuery(variables?: Types.StudiesWithStudyArmsQueryVariables) {
      return { query: StudiesWithStudyArmsDocument, variables: variables }
    }
export const StudyDocument = gql`
    query study($id: Float!, $showLibraryConfigs: Boolean, $showCoachesStudyArms: Boolean) {
  study(
    id: $id
    showLibraryConfigs: $showLibraryConfigs
    showCoachesStudyArms: $showCoachesStudyArms
  ) {
    id
    irb
    name
    displayName
    createdAt
    updatedAt
    moduleId
    studyArms {
      id
      name
      createdAt
      updatedAt
      communications
      libraryConfigs {
        id
        createdAt
        updatedAt
        library {
          id
          title
          createdAt
          updatedAt
          type
        }
        offset
        duration
        repeat
        repeatText
        time
        beforeStart
        afterStart
        order
      }
      users {
        id
        name
        age
        status
        sexualOrientation
        timezone
        createdAt
        email
        updatedAt
      }
    }
    staff {
      id
      name
      createdAt
      email
      updatedAt
      studyArmsId
      roles {
        id
        createdAt
        updatedAt
        study {
          id
          name
          irb
          displayName
          createdAt
          updatedAt
        }
        role {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
    modules {
      id
      title
      type
      createdAt
      duration
      updatedAt
    }
  }
}
    `;

/**
 * __useStudyQuery__
 *
 * To run a query within a React component, call `useStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      showLibraryConfigs: // value for 'showLibraryConfigs'
 *      showCoachesStudyArms: // value for 'showCoachesStudyArms'
 *   },
 * });
 */
export function useStudyQuery(baseOptions: Apollo.QueryHookOptions<Types.StudyQuery, Types.StudyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudyQuery, Types.StudyQueryVariables>(StudyDocument, options);
      }
export function useStudyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudyQuery, Types.StudyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudyQuery, Types.StudyQueryVariables>(StudyDocument, options);
        }
export type StudyQueryHookResult = ReturnType<typeof useStudyQuery>;
export type StudyLazyQueryHookResult = ReturnType<typeof useStudyLazyQuery>;
export type StudyQueryResult = Apollo.QueryResult<Types.StudyQuery, Types.StudyQueryVariables>;
export function refetchStudyQuery(variables: Types.StudyQueryVariables) {
      return { query: StudyDocument, variables: variables }
    }
export const CreateStudyDocument = gql`
    mutation createStudy($study: StudyInputType!, $staff: [StudyStaffInputType!], $arms: [StudyArmInputType!], $moduleId: String) {
  createStudy(study: $study, staff: $staff, arms: $arms, moduleId: $moduleId) {
    id
    irb
    name
    displayName
    createdAt
    updatedAt
    moduleId
  }
}
    `;
export type CreateStudyMutationFn = Apollo.MutationFunction<Types.CreateStudyMutation, Types.CreateStudyMutationVariables>;

/**
 * __useCreateStudyMutation__
 *
 * To run a mutation, you first call `useCreateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudyMutation, { data, loading, error }] = useCreateStudyMutation({
 *   variables: {
 *      study: // value for 'study'
 *      staff: // value for 'staff'
 *      arms: // value for 'arms'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useCreateStudyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStudyMutation, Types.CreateStudyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStudyMutation, Types.CreateStudyMutationVariables>(CreateStudyDocument, options);
      }
export type CreateStudyMutationHookResult = ReturnType<typeof useCreateStudyMutation>;
export type CreateStudyMutationResult = Apollo.MutationResult<Types.CreateStudyMutation>;
export type CreateStudyMutationOptions = Apollo.BaseMutationOptions<Types.CreateStudyMutation, Types.CreateStudyMutationVariables>;
export const UpdateStudyDocument = gql`
    mutation updateStudy($id: Float!, $study: StudyInputType!, $updatedAtTimestamp: DateTime!, $staff: [StudyStaffInputType!], $arms: [StudyArmInputType!], $moduleId: String) {
  updateStudy(
    id: $id
    study: $study
    updatedAtTimestamp: $updatedAtTimestamp
    staff: $staff
    arms: $arms
    moduleId: $moduleId
  ) {
    id
    irb
    name
    displayName
    createdAt
    updatedAt
    moduleId
  }
}
    `;
export type UpdateStudyMutationFn = Apollo.MutationFunction<Types.UpdateStudyMutation, Types.UpdateStudyMutationVariables>;

/**
 * __useUpdateStudyMutation__
 *
 * To run a mutation, you first call `useUpdateStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudyMutation, { data, loading, error }] = useUpdateStudyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      study: // value for 'study'
 *      updatedAtTimestamp: // value for 'updatedAtTimestamp'
 *      staff: // value for 'staff'
 *      arms: // value for 'arms'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useUpdateStudyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudyMutation, Types.UpdateStudyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudyMutation, Types.UpdateStudyMutationVariables>(UpdateStudyDocument, options);
      }
export type UpdateStudyMutationHookResult = ReturnType<typeof useUpdateStudyMutation>;
export type UpdateStudyMutationResult = Apollo.MutationResult<Types.UpdateStudyMutation>;
export type UpdateStudyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudyMutation, Types.UpdateStudyMutationVariables>;
export const StudyArmDocument = gql`
    query studyArm($id: Float!) {
  studyArm(id: $id) {
    id
    name
    createdAt
    updatedAt
    communications
    users {
      id
      idNumber
      email
      name
      createdAt
      updatedAt
      startDate
      phoneNumbers {
        id
        label
        type
        createdAt
        updatedAt
        isPreferred
        country {
          id
          numberValue
          countryCallingCode
          countryCode
          nationalNumber
          updatedAt
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useStudyArmQuery__
 *
 * To run a query within a React component, call `useStudyArmQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyArmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyArmQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyArmQuery(baseOptions: Apollo.QueryHookOptions<Types.StudyArmQuery, Types.StudyArmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudyArmQuery, Types.StudyArmQueryVariables>(StudyArmDocument, options);
      }
export function useStudyArmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudyArmQuery, Types.StudyArmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudyArmQuery, Types.StudyArmQueryVariables>(StudyArmDocument, options);
        }
export type StudyArmQueryHookResult = ReturnType<typeof useStudyArmQuery>;
export type StudyArmLazyQueryHookResult = ReturnType<typeof useStudyArmLazyQuery>;
export type StudyArmQueryResult = Apollo.QueryResult<Types.StudyArmQuery, Types.StudyArmQueryVariables>;
export function refetchStudyArmQuery(variables: Types.StudyArmQueryVariables) {
      return { query: StudyArmDocument, variables: variables }
    }
export const StudyArmsDocument = gql`
    query studyArms($limit: LimitInput, $where: StudyArmWhereInputType) {
  studyArms(limit: $limit, where: $where) {
    items {
      id
      name
      createdAt
      updatedAt
      communications
      users {
        id
        name
        createdAt
        updatedAt
      }
    }
    count
  }
}
    `;

/**
 * __useStudyArmsQuery__
 *
 * To run a query within a React component, call `useStudyArmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyArmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyArmsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudyArmsQuery(baseOptions?: Apollo.QueryHookOptions<Types.StudyArmsQuery, Types.StudyArmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudyArmsQuery, Types.StudyArmsQueryVariables>(StudyArmsDocument, options);
      }
export function useStudyArmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudyArmsQuery, Types.StudyArmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudyArmsQuery, Types.StudyArmsQueryVariables>(StudyArmsDocument, options);
        }
export type StudyArmsQueryHookResult = ReturnType<typeof useStudyArmsQuery>;
export type StudyArmsLazyQueryHookResult = ReturnType<typeof useStudyArmsLazyQuery>;
export type StudyArmsQueryResult = Apollo.QueryResult<Types.StudyArmsQuery, Types.StudyArmsQueryVariables>;
export function refetchStudyArmsQuery(variables?: Types.StudyArmsQueryVariables) {
      return { query: StudyArmsDocument, variables: variables }
    }
export const StudyArmsByStaffDocument = gql`
    query studyArmsByStaff($studyId: Float!, $userId: Float!) {
  studyArmsByStaff(studyId: $studyId, userId: $userId) {
    id
    name
    createdAt
  }
}
    `;

/**
 * __useStudyArmsByStaffQuery__
 *
 * To run a query within a React component, call `useStudyArmsByStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyArmsByStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyArmsByStaffQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStudyArmsByStaffQuery(baseOptions: Apollo.QueryHookOptions<Types.StudyArmsByStaffQuery, Types.StudyArmsByStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudyArmsByStaffQuery, Types.StudyArmsByStaffQueryVariables>(StudyArmsByStaffDocument, options);
      }
export function useStudyArmsByStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudyArmsByStaffQuery, Types.StudyArmsByStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudyArmsByStaffQuery, Types.StudyArmsByStaffQueryVariables>(StudyArmsByStaffDocument, options);
        }
export type StudyArmsByStaffQueryHookResult = ReturnType<typeof useStudyArmsByStaffQuery>;
export type StudyArmsByStaffLazyQueryHookResult = ReturnType<typeof useStudyArmsByStaffLazyQuery>;
export type StudyArmsByStaffQueryResult = Apollo.QueryResult<Types.StudyArmsByStaffQuery, Types.StudyArmsByStaffQueryVariables>;
export function refetchStudyArmsByStaffQuery(variables: Types.StudyArmsByStaffQueryVariables) {
      return { query: StudyArmsByStaffDocument, variables: variables }
    }
export const CreateSuggestedDateDocument = gql`
    mutation createSuggestedDate($createSuggestedDateInput: CreateSuggestedDateInput!) {
  createSuggestedDate(createSuggestedDateInput: $createSuggestedDateInput) {
    id
    suggester {
      id
      name
      email
      createdAt
      updatedAt
    }
    startTime
    endTime
    event {
      id
      title
      type
      description
      data
      start
      end
      resource
      labels
      actions
      viewed
      liked
      confirmed
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateSuggestedDateMutationFn = Apollo.MutationFunction<Types.CreateSuggestedDateMutation, Types.CreateSuggestedDateMutationVariables>;

/**
 * __useCreateSuggestedDateMutation__
 *
 * To run a mutation, you first call `useCreateSuggestedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestedDateMutation, { data, loading, error }] = useCreateSuggestedDateMutation({
 *   variables: {
 *      createSuggestedDateInput: // value for 'createSuggestedDateInput'
 *   },
 * });
 */
export function useCreateSuggestedDateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSuggestedDateMutation, Types.CreateSuggestedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSuggestedDateMutation, Types.CreateSuggestedDateMutationVariables>(CreateSuggestedDateDocument, options);
      }
export type CreateSuggestedDateMutationHookResult = ReturnType<typeof useCreateSuggestedDateMutation>;
export type CreateSuggestedDateMutationResult = Apollo.MutationResult<Types.CreateSuggestedDateMutation>;
export type CreateSuggestedDateMutationOptions = Apollo.BaseMutationOptions<Types.CreateSuggestedDateMutation, Types.CreateSuggestedDateMutationVariables>;
export const SuggestedDateDocument = gql`
    query suggestedDate($where: SuggestedDateWhereInputType!) {
  suggestedDate(where: $where) {
    id
    suggester {
      id
      name
      email
      createdAt
      updatedAt
    }
    startTime
    endTime
    event {
      id
      title
      type
      description
      data
      start
      end
      resource
      labels
      actions
      viewed
      liked
      confirmed
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSuggestedDateQuery__
 *
 * To run a query within a React component, call `useSuggestedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedDateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSuggestedDateQuery(baseOptions: Apollo.QueryHookOptions<Types.SuggestedDateQuery, Types.SuggestedDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SuggestedDateQuery, Types.SuggestedDateQueryVariables>(SuggestedDateDocument, options);
      }
export function useSuggestedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SuggestedDateQuery, Types.SuggestedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SuggestedDateQuery, Types.SuggestedDateQueryVariables>(SuggestedDateDocument, options);
        }
export type SuggestedDateQueryHookResult = ReturnType<typeof useSuggestedDateQuery>;
export type SuggestedDateLazyQueryHookResult = ReturnType<typeof useSuggestedDateLazyQuery>;
export type SuggestedDateQueryResult = Apollo.QueryResult<Types.SuggestedDateQuery, Types.SuggestedDateQueryVariables>;
export function refetchSuggestedDateQuery(variables: Types.SuggestedDateQueryVariables) {
      return { query: SuggestedDateDocument, variables: variables }
    }
export const SymptomDocument = gql`
    query symptom($id: Float!) {
  symptom(id: $id) {
    id
    userId
    label
    description
    frequency
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSymptomQuery__
 *
 * To run a query within a React component, call `useSymptomQuery` and pass it any options that fit your needs.
 * When your component renders, `useSymptomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSymptomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSymptomQuery(baseOptions: Apollo.QueryHookOptions<Types.SymptomQuery, Types.SymptomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SymptomQuery, Types.SymptomQueryVariables>(SymptomDocument, options);
      }
export function useSymptomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SymptomQuery, Types.SymptomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SymptomQuery, Types.SymptomQueryVariables>(SymptomDocument, options);
        }
export type SymptomQueryHookResult = ReturnType<typeof useSymptomQuery>;
export type SymptomLazyQueryHookResult = ReturnType<typeof useSymptomLazyQuery>;
export type SymptomQueryResult = Apollo.QueryResult<Types.SymptomQuery, Types.SymptomQueryVariables>;
export function refetchSymptomQuery(variables: Types.SymptomQueryVariables) {
      return { query: SymptomDocument, variables: variables }
    }
export const AddSymptomDocument = gql`
    mutation addSymptom($userId: Float!, $data: SymptomInputType!) {
  addSymptom(userId: $userId, data: $data) {
    id
    userId
    label
    description
    frequency
    createdAt
    updatedAt
  }
}
    `;
export type AddSymptomMutationFn = Apollo.MutationFunction<Types.AddSymptomMutation, Types.AddSymptomMutationVariables>;

/**
 * __useAddSymptomMutation__
 *
 * To run a mutation, you first call `useAddSymptomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSymptomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSymptomMutation, { data, loading, error }] = useAddSymptomMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddSymptomMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSymptomMutation, Types.AddSymptomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSymptomMutation, Types.AddSymptomMutationVariables>(AddSymptomDocument, options);
      }
export type AddSymptomMutationHookResult = ReturnType<typeof useAddSymptomMutation>;
export type AddSymptomMutationResult = Apollo.MutationResult<Types.AddSymptomMutation>;
export type AddSymptomMutationOptions = Apollo.BaseMutationOptions<Types.AddSymptomMutation, Types.AddSymptomMutationVariables>;
export const SymptomsDocument = gql`
    query symptoms($limit: LimitInput, $where: SymptomWhereInputType) {
  symptoms(limit: $limit, where: $where) {
    items {
      id
      userId
      label
      description
      frequency
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useSymptomsQuery__
 *
 * To run a query within a React component, call `useSymptomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSymptomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSymptomsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSymptomsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SymptomsQuery, Types.SymptomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SymptomsQuery, Types.SymptomsQueryVariables>(SymptomsDocument, options);
      }
export function useSymptomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SymptomsQuery, Types.SymptomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SymptomsQuery, Types.SymptomsQueryVariables>(SymptomsDocument, options);
        }
export type SymptomsQueryHookResult = ReturnType<typeof useSymptomsQuery>;
export type SymptomsLazyQueryHookResult = ReturnType<typeof useSymptomsLazyQuery>;
export type SymptomsQueryResult = Apollo.QueryResult<Types.SymptomsQuery, Types.SymptomsQueryVariables>;
export function refetchSymptomsQuery(variables?: Types.SymptomsQueryVariables) {
      return { query: SymptomsDocument, variables: variables }
    }
export const ClinicIdsDocument = gql`
    query ClinicIds {
  clinicIds
}
    `;

/**
 * __useClinicIdsQuery__
 *
 * To run a query within a React component, call `useClinicIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClinicIdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ClinicIdsQuery, Types.ClinicIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ClinicIdsQuery, Types.ClinicIdsQueryVariables>(ClinicIdsDocument, options);
      }
export function useClinicIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ClinicIdsQuery, Types.ClinicIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ClinicIdsQuery, Types.ClinicIdsQueryVariables>(ClinicIdsDocument, options);
        }
export type ClinicIdsQueryHookResult = ReturnType<typeof useClinicIdsQuery>;
export type ClinicIdsLazyQueryHookResult = ReturnType<typeof useClinicIdsLazyQuery>;
export type ClinicIdsQueryResult = Apollo.QueryResult<Types.ClinicIdsQuery, Types.ClinicIdsQueryVariables>;
export function refetchClinicIdsQuery(variables?: Types.ClinicIdsQueryVariables) {
      return { query: ClinicIdsDocument, variables: variables }
    }
export const DeleteUserDocument = gql`
    mutation deleteUser($ids: [Float!]!) {
  deleteUser(ids: $ids) {
    id
    name
    description
    email
    status
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;
export const InviteParticipantDocument = gql`
    mutation inviteParticipant($data: InviteParticipantInputType!) {
  inviteParticipant(data: $data) {
    id
    name
    email
    status
    age
    sexualOrientation
    timezone
    studies {
      id
      name
    }
    phoneNumbers {
      id
      type
      label
      isPreferred
      createdAt
      updatedAt
      country {
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
        createdAt
        updatedAt
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export type InviteParticipantMutationFn = Apollo.MutationFunction<Types.InviteParticipantMutation, Types.InviteParticipantMutationVariables>;

/**
 * __useInviteParticipantMutation__
 *
 * To run a mutation, you first call `useInviteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteParticipantMutation, { data, loading, error }] = useInviteParticipantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<Types.InviteParticipantMutation, Types.InviteParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InviteParticipantMutation, Types.InviteParticipantMutationVariables>(InviteParticipantDocument, options);
      }
export type InviteParticipantMutationHookResult = ReturnType<typeof useInviteParticipantMutation>;
export type InviteParticipantMutationResult = Apollo.MutationResult<Types.InviteParticipantMutation>;
export type InviteParticipantMutationOptions = Apollo.BaseMutationOptions<Types.InviteParticipantMutation, Types.InviteParticipantMutationVariables>;
export const InviteStaffDocument = gql`
    mutation inviteStaff($data: [InviteStaffInputType!]!, $studyId: Float!) {
  inviteStaff(data: $data, studyId: $studyId) {
    users {
      id
      name
      email
      status
      createdAt
      updatedAt
      primaryRole {
        id
        name
        createdAt
        updatedAt
      }
      studies {
        id
        name
      }
      roles {
        id
        createdAt
        updatedAt
        role {
          id
          name
        }
        study {
          id
          name
        }
      }
    }
    errors
  }
}
    `;
export type InviteStaffMutationFn = Apollo.MutationFunction<Types.InviteStaffMutation, Types.InviteStaffMutationVariables>;

/**
 * __useInviteStaffMutation__
 *
 * To run a mutation, you first call `useInviteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStaffMutation, { data, loading, error }] = useInviteStaffMutation({
 *   variables: {
 *      data: // value for 'data'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useInviteStaffMutation(baseOptions?: Apollo.MutationHookOptions<Types.InviteStaffMutation, Types.InviteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InviteStaffMutation, Types.InviteStaffMutationVariables>(InviteStaffDocument, options);
      }
export type InviteStaffMutationHookResult = ReturnType<typeof useInviteStaffMutation>;
export type InviteStaffMutationResult = Apollo.MutationResult<Types.InviteStaffMutation>;
export type InviteStaffMutationOptions = Apollo.BaseMutationOptions<Types.InviteStaffMutation, Types.InviteStaffMutationVariables>;
export const MetricsUsersDocument = gql`
    query metricsUsers($studyId: Float, $studyArmId: Float) {
  metricsUsers(studyId: $studyId, studyArmId: $studyArmId) {
    participantCount
    participantCountMonth
  }
}
    `;

/**
 * __useMetricsUsersQuery__
 *
 * To run a query within a React component, call `useMetricsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsUsersQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *   },
 * });
 */
export function useMetricsUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.MetricsUsersQuery, Types.MetricsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MetricsUsersQuery, Types.MetricsUsersQueryVariables>(MetricsUsersDocument, options);
      }
export function useMetricsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MetricsUsersQuery, Types.MetricsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MetricsUsersQuery, Types.MetricsUsersQueryVariables>(MetricsUsersDocument, options);
        }
export type MetricsUsersQueryHookResult = ReturnType<typeof useMetricsUsersQuery>;
export type MetricsUsersLazyQueryHookResult = ReturnType<typeof useMetricsUsersLazyQuery>;
export type MetricsUsersQueryResult = Apollo.QueryResult<Types.MetricsUsersQuery, Types.MetricsUsersQueryVariables>;
export function refetchMetricsUsersQuery(variables?: Types.MetricsUsersQueryVariables) {
      return { query: MetricsUsersDocument, variables: variables }
    }
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const SendInviteEmailDocument = gql`
    mutation sendInviteEmail($email: String!) {
  sendInviteEmail(email: $email)
}
    `;
export type SendInviteEmailMutationFn = Apollo.MutationFunction<Types.SendInviteEmailMutation, Types.SendInviteEmailMutationVariables>;

/**
 * __useSendInviteEmailMutation__
 *
 * To run a mutation, you first call `useSendInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteEmailMutation, { data, loading, error }] = useSendInviteEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendInviteEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendInviteEmailMutation, Types.SendInviteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendInviteEmailMutation, Types.SendInviteEmailMutationVariables>(SendInviteEmailDocument, options);
      }
export type SendInviteEmailMutationHookResult = ReturnType<typeof useSendInviteEmailMutation>;
export type SendInviteEmailMutationResult = Apollo.MutationResult<Types.SendInviteEmailMutation>;
export type SendInviteEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendInviteEmailMutation, Types.SendInviteEmailMutationVariables>;
export const SendResetPasswordLinkDocument = gql`
    mutation sendResetPasswordLink($email: String!) {
  sendResetPasswordLink(email: $email)
}
    `;
export type SendResetPasswordLinkMutationFn = Apollo.MutationFunction<Types.SendResetPasswordLinkMutation, Types.SendResetPasswordLinkMutationVariables>;

/**
 * __useSendResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordLinkMutation, { data, loading, error }] = useSendResetPasswordLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendResetPasswordLinkMutation, Types.SendResetPasswordLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendResetPasswordLinkMutation, Types.SendResetPasswordLinkMutationVariables>(SendResetPasswordLinkDocument, options);
      }
export type SendResetPasswordLinkMutationHookResult = ReturnType<typeof useSendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationResult = Apollo.MutationResult<Types.SendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationOptions = Apollo.BaseMutationOptions<Types.SendResetPasswordLinkMutation, Types.SendResetPasswordLinkMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Float!, $user: UserUpdateInputType!) {
  updateUser(id: $id, user: $user) {
    id
    name
    description
    email
    status
    privateNotes
    createdAt
    updatedAt
    studies {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    roles {
      id
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
    permissions {
      id
      studyId
      permissionId
      createdAt
      updatedAt
      permission {
        id
        name
        description
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const UpdateUserProfileInfoDetailsDocument = gql`
    mutation updateUserProfileInfoDetails($id: Float!, $data: UserProfileDetailsInputType!) {
  updateUserProfileInfoDetails(id: $id, data: $data) {
    id
    idNumber
    name
    address
    clinicId
    preferredLanguage
    startDate
    zipCode
    age
    sexualOrientation
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
    familyMembers {
      id
      name
      relationship
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    treatments {
      id
      disease
      treatmentType
      createdAt
      updatedAt
    }
    clinics {
      id
      name
      doctorName
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    phoneNumbers {
      id
      type
      label
      isPreferred
      createdAt
      updatedAt
      country {
        id
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type UpdateUserProfileInfoDetailsMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileInfoDetailsMutation, Types.UpdateUserProfileInfoDetailsMutationVariables>;

/**
 * __useUpdateUserProfileInfoDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileInfoDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileInfoDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileInfoDetailsMutation, { data, loading, error }] = useUpdateUserProfileInfoDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserProfileInfoDetailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileInfoDetailsMutation, Types.UpdateUserProfileInfoDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileInfoDetailsMutation, Types.UpdateUserProfileInfoDetailsMutationVariables>(UpdateUserProfileInfoDetailsDocument, options);
      }
export type UpdateUserProfileInfoDetailsMutationHookResult = ReturnType<typeof useUpdateUserProfileInfoDetailsMutation>;
export type UpdateUserProfileInfoDetailsMutationResult = Apollo.MutationResult<Types.UpdateUserProfileInfoDetailsMutation>;
export type UpdateUserProfileInfoDetailsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileInfoDetailsMutation, Types.UpdateUserProfileInfoDetailsMutationVariables>;
export const UpdateUserProfileInformationDocument = gql`
    mutation updateUserProfileInformation($id: Float!, $data: UserProfileInfoInputType!) {
  updateUserProfileInformation(id: $id, data: $data) {
    id
    idNumber
    name
    address
    clinicId
    preferredLanguage
    familyMembers {
      id
      name
      relationship
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    treatments {
      id
      disease
      treatmentType
      createdAt
      updatedAt
    }
    clinics {
      id
      name
      doctorName
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    phoneNumbers {
      id
      type
      label
      isPreferred
      createdAt
      updatedAt
      country {
        id
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type UpdateUserProfileInformationMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileInformationMutation, Types.UpdateUserProfileInformationMutationVariables>;

/**
 * __useUpdateUserProfileInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileInformationMutation, { data, loading, error }] = useUpdateUserProfileInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserProfileInformationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileInformationMutation, Types.UpdateUserProfileInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileInformationMutation, Types.UpdateUserProfileInformationMutationVariables>(UpdateUserProfileInformationDocument, options);
      }
export type UpdateUserProfileInformationMutationHookResult = ReturnType<typeof useUpdateUserProfileInformationMutation>;
export type UpdateUserProfileInformationMutationResult = Apollo.MutationResult<Types.UpdateUserProfileInformationMutation>;
export type UpdateUserProfileInformationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileInformationMutation, Types.UpdateUserProfileInformationMutationVariables>;
export const UpdateUserProfileDetailDocument = gql`
    mutation updateUserProfileDetail($id: Float!, $data: UserProfileDetailInputType!) {
  updateUserProfileDetail(id: $id, data: $data) {
    startDate
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export type UpdateUserProfileDetailMutationFn = Apollo.MutationFunction<Types.UpdateUserProfileDetailMutation, Types.UpdateUserProfileDetailMutationVariables>;

/**
 * __useUpdateUserProfileDetailMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileDetailMutation, { data, loading, error }] = useUpdateUserProfileDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserProfileDetailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserProfileDetailMutation, Types.UpdateUserProfileDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserProfileDetailMutation, Types.UpdateUserProfileDetailMutationVariables>(UpdateUserProfileDetailDocument, options);
      }
export type UpdateUserProfileDetailMutationHookResult = ReturnType<typeof useUpdateUserProfileDetailMutation>;
export type UpdateUserProfileDetailMutationResult = Apollo.MutationResult<Types.UpdateUserProfileDetailMutation>;
export type UpdateUserProfileDetailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserProfileDetailMutation, Types.UpdateUserProfileDetailMutationVariables>;
export const UpdateUserPermissionsDocument = gql`
    mutation updateUserPermissions($userId: Float!, $userPermissions: [UserPermissionInputType!]!) {
  updateUserPermissions(userId: $userId, userPermissions: $userPermissions) {
    id
    name
    description
    email
    status
    createdAt
    updatedAt
    studies {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    roles {
      id
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
    permissions {
      id
      studyId
      permissionId
      createdAt
      updatedAt
      permission {
        id
        name
        description
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type UpdateUserPermissionsMutationFn = Apollo.MutationFunction<Types.UpdateUserPermissionsMutation, Types.UpdateUserPermissionsMutationVariables>;

/**
 * __useUpdateUserPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionsMutation, { data, loading, error }] = useUpdateUserPermissionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userPermissions: // value for 'userPermissions'
 *   },
 * });
 */
export function useUpdateUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserPermissionsMutation, Types.UpdateUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserPermissionsMutation, Types.UpdateUserPermissionsMutationVariables>(UpdateUserPermissionsDocument, options);
      }
export type UpdateUserPermissionsMutationHookResult = ReturnType<typeof useUpdateUserPermissionsMutation>;
export type UpdateUserPermissionsMutationResult = Apollo.MutationResult<Types.UpdateUserPermissionsMutation>;
export type UpdateUserPermissionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserPermissionsMutation, Types.UpdateUserPermissionsMutationVariables>;
export const UserDocument = gql`
    query user($id: Float) {
  user(id: $id) {
    id
    name
    address
    idNumber
    description
    email
    age
    sexualOrientation
    timezone
    status
    privateNotes
    startDate
    createdAt
    updatedAt
    fitbitId
    fitbitRefreshToken
    fitbitAccessToken
    preferredLanguage
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
    familyMembers {
      id
      name
      relationship
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    treatments {
      id
      disease
      treatmentType
      createdAt
      updatedAt
    }
    clinics {
      id
      name
      doctorName
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    primaryRole {
      id
      name
      createdAt
      updatedAt
    }
    studies {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
      moduleId
      studyArms {
        id
        name
        createdAt
        updatedAt
        users {
          id
          name
          description
          email
          age
          sexualOrientation
          timezone
          status
          createdAt
          updatedAt
        }
      }
    }
    roles {
      id
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
        studyArms {
          id
          name
          createdAt
          updatedAt
          users {
            id
            name
            description
            email
            age
            sexualOrientation
            timezone
            status
            createdAt
            updatedAt
          }
        }
      }
    }
    permissions {
      id
      studyId
      permissionId
      createdAt
      updatedAt
      permission {
        id
        name
        description
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
    phoneNumbers {
      id
      type
      label
      isPreferred
      createdAt
      updatedAt
      country {
        id
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
        createdAt
        updatedAt
      }
    }
    isSubscribed
    notificationPreferenceType
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<Types.UserQuery, Types.UserQueryVariables>;
export function refetchUserQuery(variables?: Types.UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }
export const UserVerifiedDocument = gql`
    query userVerified($email: String) {
  userVerified(email: $email) {
    verified
  }
}
    `;

/**
 * __useUserVerifiedQuery__
 *
 * To run a query within a React component, call `useUserVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVerifiedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserVerifiedQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserVerifiedQuery, Types.UserVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserVerifiedQuery, Types.UserVerifiedQueryVariables>(UserVerifiedDocument, options);
      }
export function useUserVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserVerifiedQuery, Types.UserVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserVerifiedQuery, Types.UserVerifiedQueryVariables>(UserVerifiedDocument, options);
        }
export type UserVerifiedQueryHookResult = ReturnType<typeof useUserVerifiedQuery>;
export type UserVerifiedLazyQueryHookResult = ReturnType<typeof useUserVerifiedLazyQuery>;
export type UserVerifiedQueryResult = Apollo.QueryResult<Types.UserVerifiedQuery, Types.UserVerifiedQueryVariables>;
export function refetchUserVerifiedQuery(variables?: Types.UserVerifiedQueryVariables) {
      return { query: UserVerifiedDocument, variables: variables }
    }
export const UserProfileInfoDetailsDocument = gql`
    query userProfileInfoDetails($id: Float!, $studyId: Float) {
  userProfileInfoDetails(id: $id, studyId: $studyId) {
    name
    address
    idNumber
    email
    clinicId
    preferredLanguage
    startDate
    status
    preferredTime
    zipCode
    age
    sexualOrientation
    studyArm
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
    familyMembers {
      name
      relationship
      phoneNumber {
        type
        label
        isPreferred
        country {
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
        }
      }
    }
    treatments {
      disease
      treatmentType
    }
    clinics {
      name
      doctorName
      phoneNumber {
        type
        label
        isPreferred
        country {
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
        }
      }
    }
    phoneNumbers {
      type
      label
      isPreferred
      country {
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
      }
    }
  }
}
    `;

/**
 * __useUserProfileInfoDetailsQuery__
 *
 * To run a query within a React component, call `useUserProfileInfoDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileInfoDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileInfoDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useUserProfileInfoDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.UserProfileInfoDetailsQuery, Types.UserProfileInfoDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileInfoDetailsQuery, Types.UserProfileInfoDetailsQueryVariables>(UserProfileInfoDetailsDocument, options);
      }
export function useUserProfileInfoDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileInfoDetailsQuery, Types.UserProfileInfoDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileInfoDetailsQuery, Types.UserProfileInfoDetailsQueryVariables>(UserProfileInfoDetailsDocument, options);
        }
export type UserProfileInfoDetailsQueryHookResult = ReturnType<typeof useUserProfileInfoDetailsQuery>;
export type UserProfileInfoDetailsLazyQueryHookResult = ReturnType<typeof useUserProfileInfoDetailsLazyQuery>;
export type UserProfileInfoDetailsQueryResult = Apollo.QueryResult<Types.UserProfileInfoDetailsQuery, Types.UserProfileInfoDetailsQueryVariables>;
export function refetchUserProfileInfoDetailsQuery(variables: Types.UserProfileInfoDetailsQueryVariables) {
      return { query: UserProfileInfoDetailsDocument, variables: variables }
    }
export const UserProfileInformationDocument = gql`
    query userProfileInformation($id: Float) {
  user(id: $id) {
    name
    address
    idNumber
    email
    clinicId
    preferredLanguage
    familyMembers {
      name
      relationship
      phoneNumber {
        type
        label
        isPreferred
        country {
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
        }
      }
    }
    treatments {
      disease
      treatmentType
    }
    clinics {
      name
      doctorName
      phoneNumber {
        type
        label
        isPreferred
        country {
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
        }
      }
    }
    phoneNumbers {
      type
      label
      isPreferred
      country {
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
      }
    }
  }
}
    `;

/**
 * __useUserProfileInformationQuery__
 *
 * To run a query within a React component, call `useUserProfileInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileInformationQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserProfileInformationQuery, Types.UserProfileInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileInformationQuery, Types.UserProfileInformationQueryVariables>(UserProfileInformationDocument, options);
      }
export function useUserProfileInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileInformationQuery, Types.UserProfileInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileInformationQuery, Types.UserProfileInformationQueryVariables>(UserProfileInformationDocument, options);
        }
export type UserProfileInformationQueryHookResult = ReturnType<typeof useUserProfileInformationQuery>;
export type UserProfileInformationLazyQueryHookResult = ReturnType<typeof useUserProfileInformationLazyQuery>;
export type UserProfileInformationQueryResult = Apollo.QueryResult<Types.UserProfileInformationQuery, Types.UserProfileInformationQueryVariables>;
export function refetchUserProfileInformationQuery(variables?: Types.UserProfileInformationQueryVariables) {
      return { query: UserProfileInformationDocument, variables: variables }
    }
export const UserProfileGeneralDetailsDocument = gql`
    query userProfileGeneralDetails($id: Float) {
  user(id: $id) {
    startDate
    status
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserProfileGeneralDetailsQuery__
 *
 * To run a query within a React component, call `useUserProfileGeneralDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileGeneralDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileGeneralDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileGeneralDetailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserProfileGeneralDetailsQuery, Types.UserProfileGeneralDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileGeneralDetailsQuery, Types.UserProfileGeneralDetailsQueryVariables>(UserProfileGeneralDetailsDocument, options);
      }
export function useUserProfileGeneralDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileGeneralDetailsQuery, Types.UserProfileGeneralDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileGeneralDetailsQuery, Types.UserProfileGeneralDetailsQueryVariables>(UserProfileGeneralDetailsDocument, options);
        }
export type UserProfileGeneralDetailsQueryHookResult = ReturnType<typeof useUserProfileGeneralDetailsQuery>;
export type UserProfileGeneralDetailsLazyQueryHookResult = ReturnType<typeof useUserProfileGeneralDetailsLazyQuery>;
export type UserProfileGeneralDetailsQueryResult = Apollo.QueryResult<Types.UserProfileGeneralDetailsQuery, Types.UserProfileGeneralDetailsQueryVariables>;
export function refetchUserProfileGeneralDetailsQuery(variables?: Types.UserProfileGeneralDetailsQueryVariables) {
      return { query: UserProfileGeneralDetailsDocument, variables: variables }
    }
export const UserLoginDocument = gql`
    query userLogin($email: String!, $password: String!, $token: String!) {
  userLogin(email: $email, password: $password, token: $token) {
    user {
      id
      name
      description
      email
      status
      createdAt
      updatedAt
      primaryRole {
        id
        name
        createdAt
        updatedAt
      }
      studies {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      roles {
        id
        createdAt
        updatedAt
        role {
          id
          name
          description
          permissions {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        study {
          id
          irb
          name
          displayName
          createdAt
          updatedAt
        }
      }
      permissions {
        id
        studyId
        permissionId
        createdAt
        updatedAt
        permission {
          id
          name
          description
          createdAt
          updatedAt
        }
        study {
          id
          irb
          name
          displayName
          createdAt
          updatedAt
        }
      }
      phoneNumbers {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    token
  }
}
    `;

/**
 * __useUserLoginQuery__
 *
 * To run a query within a React component, call `useUserLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUserLoginQuery(baseOptions: Apollo.QueryHookOptions<Types.UserLoginQuery, Types.UserLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserLoginQuery, Types.UserLoginQueryVariables>(UserLoginDocument, options);
      }
export function useUserLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserLoginQuery, Types.UserLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserLoginQuery, Types.UserLoginQueryVariables>(UserLoginDocument, options);
        }
export type UserLoginQueryHookResult = ReturnType<typeof useUserLoginQuery>;
export type UserLoginLazyQueryHookResult = ReturnType<typeof useUserLoginLazyQuery>;
export type UserLoginQueryResult = Apollo.QueryResult<Types.UserLoginQuery, Types.UserLoginQueryVariables>;
export function refetchUserLoginQuery(variables: Types.UserLoginQueryVariables) {
      return { query: UserLoginDocument, variables: variables }
    }
export const UserRegistrationDocument = gql`
    query userRegistration($name: String!, $email: String!, $password: String!) {
  userRegistration(name: $name, email: $email, password: $password) {
    id
  }
}
    `;

/**
 * __useUserRegistrationQuery__
 *
 * To run a query within a React component, call `useUserRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRegistrationQuery({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserRegistrationQuery(baseOptions: Apollo.QueryHookOptions<Types.UserRegistrationQuery, Types.UserRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserRegistrationQuery, Types.UserRegistrationQueryVariables>(UserRegistrationDocument, options);
      }
export function useUserRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserRegistrationQuery, Types.UserRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserRegistrationQuery, Types.UserRegistrationQueryVariables>(UserRegistrationDocument, options);
        }
export type UserRegistrationQueryHookResult = ReturnType<typeof useUserRegistrationQuery>;
export type UserRegistrationLazyQueryHookResult = ReturnType<typeof useUserRegistrationLazyQuery>;
export type UserRegistrationQueryResult = Apollo.QueryResult<Types.UserRegistrationQuery, Types.UserRegistrationQueryVariables>;
export function refetchUserRegistrationQuery(variables: Types.UserRegistrationQueryVariables) {
      return { query: UserRegistrationDocument, variables: variables }
    }
export const UsersDocument = gql`
    query users($studyId: Float, $search: String, $filterAssigned: Boolean, $filterParticipants: Boolean, $status: String, $staff: Boolean, $limit: LimitInput, $sortByLatestMessages: Boolean) {
  users(
    studyId: $studyId
    search: $search
    filterAssigned: $filterAssigned
    filterParticipants: $filterParticipants
    status: $status
    staff: $staff
    limit: $limit
    sortByLatestMessages: $sortByLatestMessages
  ) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      privateNotes
      createdAt
      updatedAt
      idNumber
      primaryRole {
        id
        name
        createdAt
        updatedAt
      }
      studies {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      roles {
        id
        createdAt
        updatedAt
        role {
          id
          name
          description
          createdAt
          updatedAt
          permissions {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        study {
          id
          irb
          name
          displayName
          createdAt
          updatedAt
        }
      }
      permissions {
        id
        studyId
        permissionId
        createdAt
        updatedAt
        permission {
          id
          name
          description
          createdAt
          updatedAt
        }
        study {
          id
          irb
          name
          displayName
          createdAt
          updatedAt
        }
      }
      phoneNumbers {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    count
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      studyId: // value for 'studyId'
 *      search: // value for 'search'
 *      filterAssigned: // value for 'filterAssigned'
 *      filterParticipants: // value for 'filterParticipants'
 *      status: // value for 'status'
 *      staff: // value for 'staff'
 *      limit: // value for 'limit'
 *      sortByLatestMessages: // value for 'sortByLatestMessages'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export function refetchUsersQuery(variables?: Types.UsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const ValidateAuthDirectDocument = gql`
    mutation validateAuthDirect($token: String!) {
  validateDirectSignin(token: $token) {
    id
    name
    address
    idNumber
    description
    email
    age
    sexualOrientation
    timezone
    status
    privateNotes
    startDate
    createdAt
    updatedAt
    fitbitId
    fitbitRefreshToken
    fitbitAccessToken
    preferredLanguage
    assignedStaff {
      user {
        id
        name
      }
      role {
        id
        name
      }
    }
    familyMembers {
      id
      name
      relationship
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    treatments {
      id
      disease
      treatmentType
      createdAt
      updatedAt
    }
    clinics {
      id
      name
      doctorName
      createdAt
      updatedAt
      phoneNumber {
        id
        type
        label
        isPreferred
        createdAt
        updatedAt
        country {
          id
          countryCode
          countryCallingCode
          nationalNumber
          numberValue
          createdAt
          updatedAt
        }
      }
    }
    primaryRole {
      id
      name
      createdAt
      updatedAt
    }
    studies {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
      moduleId
      studyArms {
        id
        name
        createdAt
        updatedAt
        users {
          id
          name
          description
          email
          age
          sexualOrientation
          timezone
          status
          createdAt
          updatedAt
        }
      }
    }
    roles {
      id
      createdAt
      updatedAt
      role {
        id
        name
        description
        permissions {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
        studyArms {
          id
          name
          createdAt
          updatedAt
          users {
            id
            name
            description
            email
            age
            sexualOrientation
            timezone
            status
            createdAt
            updatedAt
          }
        }
      }
    }
    permissions {
      id
      studyId
      permissionId
      createdAt
      updatedAt
      permission {
        id
        name
        description
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
    }
    phoneNumbers {
      id
      type
      label
      isPreferred
      createdAt
      updatedAt
      country {
        id
        countryCode
        countryCallingCode
        nationalNumber
        numberValue
        createdAt
        updatedAt
      }
    }
    isSubscribed
    notificationPreferenceType
  }
}
    `;
export type ValidateAuthDirectMutationFn = Apollo.MutationFunction<Types.ValidateAuthDirectMutation, Types.ValidateAuthDirectMutationVariables>;

/**
 * __useValidateAuthDirectMutation__
 *
 * To run a mutation, you first call `useValidateAuthDirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAuthDirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAuthDirectMutation, { data, loading, error }] = useValidateAuthDirectMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateAuthDirectMutation(baseOptions?: Apollo.MutationHookOptions<Types.ValidateAuthDirectMutation, Types.ValidateAuthDirectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ValidateAuthDirectMutation, Types.ValidateAuthDirectMutationVariables>(ValidateAuthDirectDocument, options);
      }
export type ValidateAuthDirectMutationHookResult = ReturnType<typeof useValidateAuthDirectMutation>;
export type ValidateAuthDirectMutationResult = Apollo.MutationResult<Types.ValidateAuthDirectMutation>;
export type ValidateAuthDirectMutationOptions = Apollo.BaseMutationOptions<Types.ValidateAuthDirectMutation, Types.ValidateAuthDirectMutationVariables>;
export const VerifyUserDocument = gql`
    mutation verifyUser($id: Float!) {
  verifyUser(id: $id) {
    id
  }
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<Types.VerifyUserMutation, Types.VerifyUserMutationVariables>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyUserMutation, Types.VerifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyUserMutation, Types.VerifyUserMutationVariables>(VerifyUserDocument, options);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<Types.VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<Types.VerifyUserMutation, Types.VerifyUserMutationVariables>;
export const CreateUserSubscriptionDocument = gql`
    mutation createUserSubscription($createUserSubscriptionInput: CreateUserSubscriptionInput!) {
  createUserSubscription(
    createUserSubscriptionInput: $createUserSubscriptionInput
  ) {
    id
  }
}
    `;
export type CreateUserSubscriptionMutationFn = Apollo.MutationFunction<Types.CreateUserSubscriptionMutation, Types.CreateUserSubscriptionMutationVariables>;

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      createUserSubscriptionInput: // value for 'createUserSubscriptionInput'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserSubscriptionMutation, Types.CreateUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserSubscriptionMutation, Types.CreateUserSubscriptionMutationVariables>(CreateUserSubscriptionDocument, options);
      }
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = Apollo.MutationResult<Types.CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserSubscriptionMutation, Types.CreateUserSubscriptionMutationVariables>;
export const UnsubscribeUserDocument = gql`
    mutation unsubscribeUser($userId: Float!) {
  unsubscribeUser(userId: $userId)
}
    `;
export type UnsubscribeUserMutationFn = Apollo.MutationFunction<Types.UnsubscribeUserMutation, Types.UnsubscribeUserMutationVariables>;

/**
 * __useUnsubscribeUserMutation__
 *
 * To run a mutation, you first call `useUnsubscribeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeUserMutation, { data, loading, error }] = useUnsubscribeUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnsubscribeUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsubscribeUserMutation, Types.UnsubscribeUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsubscribeUserMutation, Types.UnsubscribeUserMutationVariables>(UnsubscribeUserDocument, options);
      }
export type UnsubscribeUserMutationHookResult = ReturnType<typeof useUnsubscribeUserMutation>;
export type UnsubscribeUserMutationResult = Apollo.MutationResult<Types.UnsubscribeUserMutation>;
export type UnsubscribeUserMutationOptions = Apollo.BaseMutationOptions<Types.UnsubscribeUserMutation, Types.UnsubscribeUserMutationVariables>;
export const UpdateNotificationPreferenceDocument = gql`
    mutation updateNotificationPreference($userId: Float!, $notificationPreference: String!) {
  updateNotificationPreference(
    userId: $userId
    notificationPreference: $notificationPreference
  ) {
    id
  }
}
    `;
export type UpdateNotificationPreferenceMutationFn = Apollo.MutationFunction<Types.UpdateNotificationPreferenceMutation, Types.UpdateNotificationPreferenceMutationVariables>;

/**
 * __useUpdateNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationPreferenceMutation, { data, loading, error }] = useUpdateNotificationPreferenceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notificationPreference: // value for 'notificationPreference'
 *   },
 * });
 */
export function useUpdateNotificationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNotificationPreferenceMutation, Types.UpdateNotificationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNotificationPreferenceMutation, Types.UpdateNotificationPreferenceMutationVariables>(UpdateNotificationPreferenceDocument, options);
      }
export type UpdateNotificationPreferenceMutationHookResult = ReturnType<typeof useUpdateNotificationPreferenceMutation>;
export type UpdateNotificationPreferenceMutationResult = Apollo.MutationResult<Types.UpdateNotificationPreferenceMutation>;
export type UpdateNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNotificationPreferenceMutation, Types.UpdateNotificationPreferenceMutationVariables>;
export const CountVoicesDocument = gql`
    query countVoices($search: String, $user: Float, $type: String, $where: VoicesInput) {
  countVoices(search: $search, user: $user, type: $type, where: $where)
}
    `;

/**
 * __useCountVoicesQuery__
 *
 * To run a query within a React component, call `useCountVoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountVoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountVoicesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      type: // value for 'type'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountVoicesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountVoicesQuery, Types.CountVoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountVoicesQuery, Types.CountVoicesQueryVariables>(CountVoicesDocument, options);
      }
export function useCountVoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountVoicesQuery, Types.CountVoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountVoicesQuery, Types.CountVoicesQueryVariables>(CountVoicesDocument, options);
        }
export type CountVoicesQueryHookResult = ReturnType<typeof useCountVoicesQuery>;
export type CountVoicesLazyQueryHookResult = ReturnType<typeof useCountVoicesLazyQuery>;
export type CountVoicesQueryResult = Apollo.QueryResult<Types.CountVoicesQuery, Types.CountVoicesQueryVariables>;
export function refetchCountVoicesQuery(variables?: Types.CountVoicesQueryVariables) {
      return { query: CountVoicesDocument, variables: variables }
    }
export const CreateVoiceDocument = gql`
    mutation createVoice($createVoiceInput: CreateVoiceInput!) {
  createVoice(createVoiceInput: $createVoiceInput) {
    id
    title
    description
    url
    audioUrl
    data
    labels
    actions
    viewed
    liked
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateVoiceMutationFn = Apollo.MutationFunction<Types.CreateVoiceMutation, Types.CreateVoiceMutationVariables>;

/**
 * __useCreateVoiceMutation__
 *
 * To run a mutation, you first call `useCreateVoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoiceMutation, { data, loading, error }] = useCreateVoiceMutation({
 *   variables: {
 *      createVoiceInput: // value for 'createVoiceInput'
 *   },
 * });
 */
export function useCreateVoiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateVoiceMutation, Types.CreateVoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateVoiceMutation, Types.CreateVoiceMutationVariables>(CreateVoiceDocument, options);
      }
export type CreateVoiceMutationHookResult = ReturnType<typeof useCreateVoiceMutation>;
export type CreateVoiceMutationResult = Apollo.MutationResult<Types.CreateVoiceMutation>;
export type CreateVoiceMutationOptions = Apollo.BaseMutationOptions<Types.CreateVoiceMutation, Types.CreateVoiceMutationVariables>;
export const DeleteVoiceDocument = gql`
    mutation deleteVoice($id: Float!) {
  deleteVoice(id: $id) {
    id
    title
    description
    url
    audioUrl
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type DeleteVoiceMutationFn = Apollo.MutationFunction<Types.DeleteVoiceMutation, Types.DeleteVoiceMutationVariables>;

/**
 * __useDeleteVoiceMutation__
 *
 * To run a mutation, you first call `useDeleteVoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoiceMutation, { data, loading, error }] = useDeleteVoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVoiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteVoiceMutation, Types.DeleteVoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteVoiceMutation, Types.DeleteVoiceMutationVariables>(DeleteVoiceDocument, options);
      }
export type DeleteVoiceMutationHookResult = ReturnType<typeof useDeleteVoiceMutation>;
export type DeleteVoiceMutationResult = Apollo.MutationResult<Types.DeleteVoiceMutation>;
export type DeleteVoiceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteVoiceMutation, Types.DeleteVoiceMutationVariables>;
export const LikeAllVoicesDocument = gql`
    mutation likeAllVoices($user: Float) {
  likeAllVoices(user: $user)
}
    `;
export type LikeAllVoicesMutationFn = Apollo.MutationFunction<Types.LikeAllVoicesMutation, Types.LikeAllVoicesMutationVariables>;

/**
 * __useLikeAllVoicesMutation__
 *
 * To run a mutation, you first call `useLikeAllVoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeAllVoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeAllVoicesMutation, { data, loading, error }] = useLikeAllVoicesMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLikeAllVoicesMutation(baseOptions?: Apollo.MutationHookOptions<Types.LikeAllVoicesMutation, Types.LikeAllVoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LikeAllVoicesMutation, Types.LikeAllVoicesMutationVariables>(LikeAllVoicesDocument, options);
      }
export type LikeAllVoicesMutationHookResult = ReturnType<typeof useLikeAllVoicesMutation>;
export type LikeAllVoicesMutationResult = Apollo.MutationResult<Types.LikeAllVoicesMutation>;
export type LikeAllVoicesMutationOptions = Apollo.BaseMutationOptions<Types.LikeAllVoicesMutation, Types.LikeAllVoicesMutationVariables>;
export const UpdateVoiceDocument = gql`
    mutation updateVoice($id: Float!, $updateVoiceInput: UpdateVoiceInput!) {
  updateVoice(id: $id, updateVoiceInput: $updateVoiceInput) {
    id
    title
    description
    url
    audioUrl
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type UpdateVoiceMutationFn = Apollo.MutationFunction<Types.UpdateVoiceMutation, Types.UpdateVoiceMutationVariables>;

/**
 * __useUpdateVoiceMutation__
 *
 * To run a mutation, you first call `useUpdateVoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoiceMutation, { data, loading, error }] = useUpdateVoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateVoiceInput: // value for 'updateVoiceInput'
 *   },
 * });
 */
export function useUpdateVoiceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVoiceMutation, Types.UpdateVoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateVoiceMutation, Types.UpdateVoiceMutationVariables>(UpdateVoiceDocument, options);
      }
export type UpdateVoiceMutationHookResult = ReturnType<typeof useUpdateVoiceMutation>;
export type UpdateVoiceMutationResult = Apollo.MutationResult<Types.UpdateVoiceMutation>;
export type UpdateVoiceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVoiceMutation, Types.UpdateVoiceMutationVariables>;
export const UsersVoicesDocument = gql`
    query usersVoices($search: String, $studyId: Float, $studyArmId: Float, $type: String, $limit: LimitInput) {
  usersVoices(
    search: $search
    studyId: $studyId
    studyArmId: $studyArmId
    type: $type
    limit: $limit
  ) {
    items {
      id
      name
      description
      email
      age
      sexualOrientation
      timezone
      password
      status
      createdAt
      updatedAt
      lastMessageAt
    }
    count
  }
}
    `;

/**
 * __useUsersVoicesQuery__
 *
 * To run a query within a React component, call `useUsersVoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersVoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersVoicesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersVoicesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersVoicesQuery, Types.UsersVoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsersVoicesQuery, Types.UsersVoicesQueryVariables>(UsersVoicesDocument, options);
      }
export function useUsersVoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersVoicesQuery, Types.UsersVoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsersVoicesQuery, Types.UsersVoicesQueryVariables>(UsersVoicesDocument, options);
        }
export type UsersVoicesQueryHookResult = ReturnType<typeof useUsersVoicesQuery>;
export type UsersVoicesLazyQueryHookResult = ReturnType<typeof useUsersVoicesLazyQuery>;
export type UsersVoicesQueryResult = Apollo.QueryResult<Types.UsersVoicesQuery, Types.UsersVoicesQueryVariables>;
export function refetchUsersVoicesQuery(variables?: Types.UsersVoicesQueryVariables) {
      return { query: UsersVoicesDocument, variables: variables }
    }
export const ViewAllVoicesDocument = gql`
    mutation viewAllVoices($user: Float) {
  viewAllVoices(user: $user)
}
    `;
export type ViewAllVoicesMutationFn = Apollo.MutationFunction<Types.ViewAllVoicesMutation, Types.ViewAllVoicesMutationVariables>;

/**
 * __useViewAllVoicesMutation__
 *
 * To run a mutation, you first call `useViewAllVoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewAllVoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewAllVoicesMutation, { data, loading, error }] = useViewAllVoicesMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useViewAllVoicesMutation(baseOptions?: Apollo.MutationHookOptions<Types.ViewAllVoicesMutation, Types.ViewAllVoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ViewAllVoicesMutation, Types.ViewAllVoicesMutationVariables>(ViewAllVoicesDocument, options);
      }
export type ViewAllVoicesMutationHookResult = ReturnType<typeof useViewAllVoicesMutation>;
export type ViewAllVoicesMutationResult = Apollo.MutationResult<Types.ViewAllVoicesMutation>;
export type ViewAllVoicesMutationOptions = Apollo.BaseMutationOptions<Types.ViewAllVoicesMutation, Types.ViewAllVoicesMutationVariables>;
export const VoiceDocument = gql`
    query voice($id: Float!) {
  voice(id: $id) {
    id
    title
    description
    url
    audioUrl
    data
    labels
    actions
    viewed
    liked
    sender {
      id
      name
      email
      createdAt
      updatedAt
    }
    receiver {
      id
      name
      email
      createdAt
      updatedAt
    }
    study {
      id
      irb
      name
      displayName
      createdAt
      updatedAt
    }
    studyArm {
      id
      name
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useVoiceQuery__
 *
 * To run a query within a React component, call `useVoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoiceQuery(baseOptions: Apollo.QueryHookOptions<Types.VoiceQuery, Types.VoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VoiceQuery, Types.VoiceQueryVariables>(VoiceDocument, options);
      }
export function useVoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VoiceQuery, Types.VoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VoiceQuery, Types.VoiceQueryVariables>(VoiceDocument, options);
        }
export type VoiceQueryHookResult = ReturnType<typeof useVoiceQuery>;
export type VoiceLazyQueryHookResult = ReturnType<typeof useVoiceLazyQuery>;
export type VoiceQueryResult = Apollo.QueryResult<Types.VoiceQuery, Types.VoiceQueryVariables>;
export function refetchVoiceQuery(variables: Types.VoiceQueryVariables) {
      return { query: VoiceDocument, variables: variables }
    }
export const VoicesDocument = gql`
    query voices($search: String, $user: Float, $correspondingUser: Float, $studyId: Float, $studyArmId: Float, $limit: LimitInput, $where: VoicesInput) {
  voices(
    search: $search
    user: $user
    correspondingUser: $correspondingUser
    studyId: $studyId
    studyArmId: $studyArmId
    limit: $limit
    where: $where
  ) {
    items {
      id
      title
      description
      url
      audioUrl
      data
      labels
      actions
      viewed
      liked
      sender {
        id
        name
        email
        createdAt
        updatedAt
      }
      receiver {
        id
        name
        email
        createdAt
        updatedAt
      }
      study {
        id
        irb
        name
        displayName
        createdAt
        updatedAt
      }
      studyArm {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useVoicesQuery__
 *
 * To run a query within a React component, call `useVoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoicesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      user: // value for 'user'
 *      correspondingUser: // value for 'correspondingUser'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVoicesQuery(baseOptions?: Apollo.QueryHookOptions<Types.VoicesQuery, Types.VoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VoicesQuery, Types.VoicesQueryVariables>(VoicesDocument, options);
      }
export function useVoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VoicesQuery, Types.VoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VoicesQuery, Types.VoicesQueryVariables>(VoicesDocument, options);
        }
export type VoicesQueryHookResult = ReturnType<typeof useVoicesQuery>;
export type VoicesLazyQueryHookResult = ReturnType<typeof useVoicesLazyQuery>;
export type VoicesQueryResult = Apollo.QueryResult<Types.VoicesQuery, Types.VoicesQueryVariables>;
export function refetchVoicesQuery(variables?: Types.VoicesQueryVariables) {
      return { query: VoicesDocument, variables: variables }
    }
export const ArchiveSessionDocument = gql`
    query archiveSession($sessionId: String!, $value: Boolean!) {
  archiveSession(sessionId: $sessionId, value: $value) {
    createdAt
    duration
    id
    name
    partnerId
    projectId
    reason
    sessionId
    size
    status
    hasAudio
    hasVideo
    outputMode
    resolution
    streamMode
    streams {
      streamId
      hasAudio
      hasVideo
    }
    url
  }
}
    `;

/**
 * __useArchiveSessionQuery__
 *
 * To run a query within a React component, call `useArchiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useArchiveSessionQuery(baseOptions: Apollo.QueryHookOptions<Types.ArchiveSessionQuery, Types.ArchiveSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ArchiveSessionQuery, Types.ArchiveSessionQueryVariables>(ArchiveSessionDocument, options);
      }
export function useArchiveSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ArchiveSessionQuery, Types.ArchiveSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ArchiveSessionQuery, Types.ArchiveSessionQueryVariables>(ArchiveSessionDocument, options);
        }
export type ArchiveSessionQueryHookResult = ReturnType<typeof useArchiveSessionQuery>;
export type ArchiveSessionLazyQueryHookResult = ReturnType<typeof useArchiveSessionLazyQuery>;
export type ArchiveSessionQueryResult = Apollo.QueryResult<Types.ArchiveSessionQuery, Types.ArchiveSessionQueryVariables>;
export function refetchArchiveSessionQuery(variables: Types.ArchiveSessionQueryVariables) {
      return { query: ArchiveSessionDocument, variables: variables }
    }
export const OpentokAuthDocument = gql`
    query opentokAuth($joinSessionId: String) {
  opentokAuth(joinSessionId: $joinSessionId) {
    apiKey
    sessionId
    token
  }
}
    `;

/**
 * __useOpentokAuthQuery__
 *
 * To run a query within a React component, call `useOpentokAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpentokAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpentokAuthQuery({
 *   variables: {
 *      joinSessionId: // value for 'joinSessionId'
 *   },
 * });
 */
export function useOpentokAuthQuery(baseOptions?: Apollo.QueryHookOptions<Types.OpentokAuthQuery, Types.OpentokAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OpentokAuthQuery, Types.OpentokAuthQueryVariables>(OpentokAuthDocument, options);
      }
export function useOpentokAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OpentokAuthQuery, Types.OpentokAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OpentokAuthQuery, Types.OpentokAuthQueryVariables>(OpentokAuthDocument, options);
        }
export type OpentokAuthQueryHookResult = ReturnType<typeof useOpentokAuthQuery>;
export type OpentokAuthLazyQueryHookResult = ReturnType<typeof useOpentokAuthLazyQuery>;
export type OpentokAuthQueryResult = Apollo.QueryResult<Types.OpentokAuthQuery, Types.OpentokAuthQueryVariables>;
export function refetchOpentokAuthQuery(variables?: Types.OpentokAuthQueryVariables) {
      return { query: OpentokAuthDocument, variables: variables }
    }
export const SendMmsDocument = gql`
    mutation sendMMS($user: Float, $text: String!, $files: [Upload!]!, $studyId: Float = null, $studyArmId: Float = null, $phoneNumber: String = null) {
  sendMMS(
    user: $user
    text: $text
    files: $files
    studyId: $studyId
    studyArmId: $studyArmId
    phoneNumber: $phoneNumber
  ) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type SendMmsMutationFn = Apollo.MutationFunction<Types.SendMmsMutation, Types.SendMmsMutationVariables>;

/**
 * __useSendMmsMutation__
 *
 * To run a mutation, you first call `useSendMmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMmsMutation, { data, loading, error }] = useSendMmsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      text: // value for 'text'
 *      files: // value for 'files'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendMmsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMmsMutation, Types.SendMmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMmsMutation, Types.SendMmsMutationVariables>(SendMmsDocument, options);
      }
export type SendMmsMutationHookResult = ReturnType<typeof useSendMmsMutation>;
export type SendMmsMutationResult = Apollo.MutationResult<Types.SendMmsMutation>;
export type SendMmsMutationOptions = Apollo.BaseMutationOptions<Types.SendMmsMutation, Types.SendMmsMutationVariables>;
export const SendSmsDocument = gql`
    mutation sendSMS($user: Float!, $text: String!, $studyId: Float, $studyArmId: Float) {
  sendSMS(user: $user, text: $text, studyId: $studyId, studyArmId: $studyArmId) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type SendSmsMutationFn = Apollo.MutationFunction<Types.SendSmsMutation, Types.SendSmsMutationVariables>;

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      text: // value for 'text'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendSmsMutation, Types.SendSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendSmsMutation, Types.SendSmsMutationVariables>(SendSmsDocument, options);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = Apollo.MutationResult<Types.SendSmsMutation>;
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<Types.SendSmsMutation, Types.SendSmsMutationVariables>;
export const SendVideoConferenceSmsDocument = gql`
    mutation sendVideoConferenceSMS($user: Float!, $vonageSessionId: String!, $studyId: Float, $studyArmId: Float) {
  sendVideoConferenceSMS(
    user: $user
    vonageSessionId: $vonageSessionId
    studyId: $studyId
    studyArmId: $studyArmId
  ) {
    id
    title
    description
    data
    labels
    actions
    viewed
    liked
    createdAt
    updatedAt
  }
}
    `;
export type SendVideoConferenceSmsMutationFn = Apollo.MutationFunction<Types.SendVideoConferenceSmsMutation, Types.SendVideoConferenceSmsMutationVariables>;

/**
 * __useSendVideoConferenceSmsMutation__
 *
 * To run a mutation, you first call `useSendVideoConferenceSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVideoConferenceSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVideoConferenceSmsMutation, { data, loading, error }] = useSendVideoConferenceSmsMutation({
 *   variables: {
 *      user: // value for 'user'
 *      vonageSessionId: // value for 'vonageSessionId'
 *      studyId: // value for 'studyId'
 *      studyArmId: // value for 'studyArmId'
 *   },
 * });
 */
export function useSendVideoConferenceSmsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendVideoConferenceSmsMutation, Types.SendVideoConferenceSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendVideoConferenceSmsMutation, Types.SendVideoConferenceSmsMutationVariables>(SendVideoConferenceSmsDocument, options);
      }
export type SendVideoConferenceSmsMutationHookResult = ReturnType<typeof useSendVideoConferenceSmsMutation>;
export type SendVideoConferenceSmsMutationResult = Apollo.MutationResult<Types.SendVideoConferenceSmsMutation>;
export type SendVideoConferenceSmsMutationOptions = Apollo.BaseMutationOptions<Types.SendVideoConferenceSmsMutation, Types.SendVideoConferenceSmsMutationVariables>;
export const StopVideoCallDocument = gql`
    mutation stopVideoCall($sessionId: String!) {
  stopVideoCall(sessionId: $sessionId) {
    id
  }
}
    `;
export type StopVideoCallMutationFn = Apollo.MutationFunction<Types.StopVideoCallMutation, Types.StopVideoCallMutationVariables>;

/**
 * __useStopVideoCallMutation__
 *
 * To run a mutation, you first call `useStopVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopVideoCallMutation, { data, loading, error }] = useStopVideoCallMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useStopVideoCallMutation(baseOptions?: Apollo.MutationHookOptions<Types.StopVideoCallMutation, Types.StopVideoCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StopVideoCallMutation, Types.StopVideoCallMutationVariables>(StopVideoCallDocument, options);
      }
export type StopVideoCallMutationHookResult = ReturnType<typeof useStopVideoCallMutation>;
export type StopVideoCallMutationResult = Apollo.MutationResult<Types.StopVideoCallMutation>;
export type StopVideoCallMutationOptions = Apollo.BaseMutationOptions<Types.StopVideoCallMutation, Types.StopVideoCallMutationVariables>;
export const VonageAuthDocument = gql`
    query vonageAuth($userId: Float!) {
  vonageAuth(userId: $userId) {
    token
  }
}
    `;

/**
 * __useVonageAuthQuery__
 *
 * To run a query within a React component, call `useVonageAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useVonageAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVonageAuthQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVonageAuthQuery(baseOptions: Apollo.QueryHookOptions<Types.VonageAuthQuery, Types.VonageAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VonageAuthQuery, Types.VonageAuthQueryVariables>(VonageAuthDocument, options);
      }
export function useVonageAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VonageAuthQuery, Types.VonageAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VonageAuthQuery, Types.VonageAuthQueryVariables>(VonageAuthDocument, options);
        }
export type VonageAuthQueryHookResult = ReturnType<typeof useVonageAuthQuery>;
export type VonageAuthLazyQueryHookResult = ReturnType<typeof useVonageAuthLazyQuery>;
export type VonageAuthQueryResult = Apollo.QueryResult<Types.VonageAuthQuery, Types.VonageAuthQueryVariables>;
export function refetchVonageAuthQuery(variables: Types.VonageAuthQueryVariables) {
      return { query: VonageAuthDocument, variables: variables }
    }
/**
 * @typedef {Object} ActivityLevel
 * @property {number} minutes
 * @property {string} name
 */

/**
 * @typedef {Object} ArchiveOutput
 * @property {number} createdAt
 * @property {string} duration
 * @property {string} hasAudio
 * @property {string} hasVideo
 * @property {string} id
 * @property {string} name
 * @property {string} outputMode
 * @property {string} partnerId
 * @property {string} projectId
 * @property {string} reason
 * @property {string} resolution
 * @property {string} sessionId
 * @property {number} size
 * @property {string} status
 * @property {string} streamMode
 * @property {Array<Stream>} streams
 * @property {string} url
 */

/**
 * @typedef {Object} AssessmentAnswerEntity
 * @property {DateTime} [answerDate]
 * @property {string} comment
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {AssessmentQuestionEntity} [question]
 * @property {DateTime} updatedAt
 * @property {JSONObject} [value]
 * @property {Array<string>} [values]
 */

/**
 * @typedef {Object} AssessmentAnswerInputType
 * @property {DateTime} [answerDate]
 * @property {string} [comment]
 * @property {number} [id]
 * @property {number} questionId
 * @property {JSONObject} [value]
 * @property {Array<string>} [values]
 */

/**
 * @typedef {Object} AssessmentQuestionEntity
 * @property {JSONObject} [automation]
 * @property {QuestionCategoryEntity} [category]
 * @property {string} [conditions]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [icon]
 * @property {string} id
 * @property {string} [identifier]
 * @property {boolean} [isAnswered]
 * @property {string} label
 * @property {JSONObject} [meta]
 * @property {string} [options]
 * @property {number} [order]
 * @property {Array<AssessmentQuestionEntity>} [questions]
 * @property {string} [symptomName]
 * @property {string} [symptomNameEs]
 * @property {TooltipEntity} [tooltip]
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {string} [variableName]
 */

/**
 * @typedef {Object} AssessmentQuestionInputType
 * @property {JSONObject} [automation]
 * @property {QuestionCategoryInputType} [category]
 * @property {string} [conditions]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [icon]
 * @property {string} [id]
 * @property {string} [identifier]
 * @property {boolean} [isAnswered]
 * @property {string} [label]
 * @property {JSONObject} [meta]
 * @property {string} [options]
 * @property {number} [order]
 * @property {Array<AssessmentQuestionInputType>} [questions]
 * @property {string} [symptomName]
 * @property {string} [symptomNameEs]
 * @property {TooltipInputType} [tooltip]
 * @property {string} [type]
 * @property {DateTime} [updatedAt]
 * @property {string} [variableName]
 */

/**
 * @typedef {Object} AssessmentQuestionOutputType
 * @property {number} count
 * @property {Array<AssessmentQuestionEntity>} items
 */

/**
 * @typedef {Object} AssessmentQuestionWhereInputType
 * @property {number} [id]
 * @property {string} [identifier]
 * @property {string} [label]
 * @property {string} [type]
 * @property {string} [variableName]
 */

/**
 * @typedef {Object} AssessmentResponseEntity
 * @property {DateTime} [answerDate]
 * @property {Array<AssessmentAnswerEntity>} [answers]
 * @property {string} [conversationUUID]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {number} [incrementFollowupQuestionsCount]
 * @property {LibraryEntity} library
 * @property {number} libraryId
 * @property {boolean} [preIVRQuestionsRequired]
 * @property {number} [skippedQuestionCount]
 * @property {number} [skippedQuestionsBySystemCount]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} triggeredAutomationIndexes
 * @property {DateTime} updatedAt
 * @property {UserEntity} user
 * @property {number} userId
 */

/**
 * @typedef {Object} AssessmentResponseInputType
 * @property {DateTime} [answerDate]
 * @property {Array<AssessmentAnswerInputType>} answers
 * @property {string} [conversationUUID]
 * @property {number} library
 * @property {boolean} [preIVRQuestionsRequired]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {number} [user]
 */

/**
 * @typedef {Object} AssessmentResponseOutputType
 * @property {number} count
 * @property {Array<AssessmentResponseEntity>} items
 */

/**
 * @typedef {Object} AssessmentResponseWithTokenInputType
 * @property {Array<AssessmentAnswerInputType>} answers
 */

/**
 * @typedef {Object} AssessmentResponsesWhereInputType
 * @property {DateTime} [answerDate]
 * @property {string} [conversationUUID]
 * @property {number} [id]
 * @property {number} [libraryId]
 * @property {Array<number>} [libraryIds]
 * @property {number} [userId]
 */

/**
 * @typedef {Object} AssessmentsAnsweredResponseOutputType
 * @property {DateTime} date
 * @property {boolean} isAnswered
 */

/**
 * @typedef {Object} AssignedStaffInputType
 * @property {string} id
 * @property {string} [name]
 * @property {RoleAutoCompleteInputType} [role]
 */

/**
 * @typedef {Object} AuditEntity
 * @property {string} action
 * @property {number} id
 * @property {string} [newData]
 * @property {boolean} notify
 * @property {string} [oldData]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} tableName
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 */

/**
 * @typedef {Object} AuditInput
 * @property {string} [action]
 * @property {number} [id]
 * @property {string} [newData]
 * @property {boolean} [notify]
 * @property {string} [oldData]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [tableName]
 * @property {DateTime} [updatedAt]
 * @property {number} [userId]
 */

/**
 * @typedef {Object} AuditOutput
 * @property {number} count
 * @property {Array<AuditEntity>} items
 */

/**
 * @typedef {Object} AuthOutputType
 * @property {string} [token]
 */

/**
 * @typedef {Object} AutomationActionType
 * @property {string} [data]
 * @property {string} do
 * @property {string} where
 * @property {Array<AutomationActionWhoType>} who
 */

/**
 * @typedef {Object} AutomationActionWhoType
 * @property {number} id
 * @property {string} name
 * @property {string} type
 */

/**
 * @typedef {Object} AutomationConditionType
 * @property {string} logic
 * @property {string} operator
 * @property {string} question
 * @property {string} value
 * @property {string} what
 */

/**
 * @typedef {Object} AutomationInputType
 * @property {Array<AutomationActionType>} actions
 * @property {Array<AutomationConditionType>} conditions
 */

/**
 * @typedef {Object} AutomationWhoOutputType
 * @property {number} id
 * @property {string} name
 * @property {string} type
 */

/**
 * @typedef {Object} CanDeleteLibraryOutputType
 * @property {boolean} canDelete
 * @property {string} message
 */

/**
 * @typedef {Object} ChartEntity
 * @property {ChartTabEntity} category
 * @property {number} [categoryId]
 * @property {DateTime} createdAt
 * @property {AssessmentQuestionEntity} dataSource
 * @property {AssessmentQuestionEntity} [dataSource1]
 * @property {DateTime} [deletedAt]
 * @property {boolean} fullWidth
 * @property {string} id
 * @property {JSONObject} [meta]
 * @property {Array<string>} plots
 * @property {Array<string>} [plots1]
 * @property {string} title
 * @property {string} [title1]
 * @property {string} type
 * @property {string} [type1]
 * @property {DateTime} updatedAt
 * @property {UserEntity} user
 * @property {string} xAxis
 * @property {string} yAxis
 * @property {string} [yAxis1]
 */

/**
 * @typedef {Object} ChartTabEntity
 * @property {DateTime} createdAt
 * @property {string} id
 * @property {number} [order]
 * @property {string} title
 * @property {DateTime} updatedAt
 * @property {number} [userId]
 */

/**
 * @typedef {Object} ChartTabInputType
 * @property {number} [id]
 * @property {string} title
 */

/**
 * @typedef {Object} ChartTabOutputType
 * @property {number} chartCount
 * @property {DateTime} createdAt
 * @property {string} id
 * @property {number} [order]
 * @property {string} title
 * @property {DateTime} updatedAt
 * @property {number} [userId]
 */

/**
 * @typedef {Object} ClinicEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} doctorName
 * @property {string} id
 * @property {string} name
 * @property {PhoneNumberEntity} [phoneNumber]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} ClinicInputType
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [doctorName]
 * @property {string} [id]
 * @property {string} [name]
 * @property {PhoneNumberInputType} [phoneNumber]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} CoachingAssessmentForNotesOutput
 * @property {boolean} hasCoachingAssessment
 * @property {number} [noteId]
 */

/**
 * @typedef {Object} CoachingEvents
 * @property {DateTime} [end]
 * @property {number} id
 * @property {number} [libraryId]
 * @property {DateTime} start
 * @property {string} [status]
 * @property {string} type
 */

/**
 * @typedef {Object} ConferenceEntity
 * @property {string} [answerTimestamp]
 * @property {string} [conversationUuid]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {boolean} dtmf
 * @property {DateTime} [endDate]
 * @property {number} [eventId]
 * @property {number} [fromUserId]
 * @property {string} id
 * @property {string} [machineDetectionMeta]
 * @property {string} [machineDetectionStatus]
 * @property {string} [meta]
 * @property {Array<NoteEntity>} [notes]
 * @property {boolean} [officialTouchpoint]
 * @property {RecordingEntity} [recording]
 * @property {string} [sessionId]
 * @property {string} [status]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {number} [toUserId]
 * @property {string} [type]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} ConferenceInputType
 * @property {number} [noteId]
 * @property {boolean} [officialTouchpoint]
 * @property {string} [status]
 * @property {number} [studyId]
 * @property {string} [type]
 */

/**
 * @typedef {Object} ConferenceOutputType
 * @property {number} count
 * @property {Array<ConferenceEntity>} items
 */

/**
 * @typedef {Object} ConferenceWhereInputType
 * @property {string} [conversationUuid]
 * @property {number} [fromUserId]
 * @property {number} [id]
 * @property {string} [sessionId]
 * @property {number} [studyId]
 * @property {number} [toUserId]
 * @property {string} [type]
 */

/**
 * @typedef {Object} ConferenceWithUsersOutputType
 * @property {number} count
 * @property {Array<ConferenceWithUsersRelation>} items
 */

/**
 * @typedef {Object} ConferenceWithUsersRelation
 * @property {string} [answerTimestamp]
 * @property {string} [conversationUuid]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {boolean} dtmf
 * @property {DateTime} [endDate]
 * @property {number} [eventId]
 * @property {UserEntity} fromUser
 * @property {number} [fromUserId]
 * @property {string} id
 * @property {string} [machineDetectionMeta]
 * @property {string} [machineDetectionStatus]
 * @property {string} [meta]
 * @property {Array<NoteEntity>} [notes]
 * @property {boolean} [officialTouchpoint]
 * @property {RecordingEntity} [recording]
 * @property {string} [sessionId]
 * @property {string} [status]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {UserEntity} toUser
 * @property {number} [toUserId]
 * @property {string} [type]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} CountryInputType
 * @property {string} countryCallingCode
 * @property {string} countryCode
 * @property {string} nationalNumber
 * @property {string} numberValue
 */

/**
 * @typedef {Object} CountryPhoneNumberEntity
 * @property {string} countryCallingCode
 * @property {string} countryCode
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {string} nationalNumber
 * @property {string} numberValue
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} CountryPhoneNumberInput
 * @property {string} [countryCallingCode]
 * @property {string} [countryCode]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [id]
 * @property {string} [nationalNumber]
 * @property {string} [numberValue]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} CreateChartInput
 * @property {number} categoryId
 * @property {number} dataSourceId
 * @property {number} [dataSourceId1]
 * @property {boolean} fullWidth
 * @property {JSONObject} [meta]
 * @property {Array<string>} plots
 * @property {Array<string>} [plots1]
 * @property {string} title
 * @property {string} [title1]
 * @property {string} type
 * @property {string} [type1]
 * @property {number} userId
 * @property {string} xAxis
 * @property {string} yAxis
 * @property {string} [yAxis1]
 */

/**
 * @typedef {Object} CreateEmailInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} title
 * @property {string} [type]
 * @property {number} [user]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} CreateEventInput
 * @property {string} [actions]
 * @property {number} [confirmed]
 * @property {string} [data]
 * @property {string} [description]
 * @property {DateTime} end
 * @property {number} [hostId]
 * @property {string} [labels]
 * @property {number} [libraryConfigId]
 * @property {boolean} [liked]
 * @property {string} [resource]
 * @property {DateTime} start
 * @property {string} [status]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {Array<number>} studyIds
 * @property {string} title
 * @property {string} [type]
 * @property {Array<number>} userIds
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} CreateMessageInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} title
 * @property {string} [type]
 * @property {number} user
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} CreateNoteInput
 * @property {string} [actions]
 * @property {number} [conferenceId]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [editorId]
 * @property {string} [entity]
 * @property {string} [labels]
 * @property {number} [libraryId]
 * @property {boolean} [liked]
 * @property {number} [parentId]
 * @property {string} [status]
 * @property {string} title
 * @property {string} [type]
 * @property {number} [userId]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} CreateNotificationInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} title
 * @property {string} [type]
 * @property {number} user
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} CreateStudyPermissionInput
 * @property {number} permissionId
 * @property {number} studyId
 */

/**
 * @typedef {Object} CreateStudyRoleInput
 * @property {number} [id]
 * @property {number} roleId
 * @property {number} studyId
 */

/**
 * @typedef {Object} CreateSuggestedDateInput
 * @property {DateTime} endTime
 * @property {number} eventId
 * @property {DateTime} startTime
 */

/**
 * @typedef {Object} CreateUserSubscriptionInput
 * @property {string} notificationPreference
 * @property {number} userId
 */

/**
 * @typedef {Object} CreateVoiceInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} title
 * @property {string} [type]
 * @property {string} [url]
 * @property {number} user
 * @property {boolean} [viewed]
 */

/**
 * A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format.
 * @typedef {*} DateTime
 */

/**
 * @typedef {Object} EmailEntity
 * @property {string} [actions]
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {number} id
 * @property {string} [labels]
 * @property {boolean} liked
 * @property {UserEntity} [receiver]
 * @property {number} [receiverId]
 * @property {UserEntity} [sender]
 * @property {number} [senderId]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {boolean} viewed
 */

/**
 * @typedef {Object} EmailsInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} EmailsOutput
 * @property {number} count
 * @property {boolean} isGroupChat
 * @property {Array<EmailEntity>} items
 */

/**
 * @typedef {Object} EventEntity
 * @property {string} [actions]
 * @property {number} confirmed
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {DateTime} end
 * @property {number} id
 * @property {string} [labels]
 * @property {number} [libraryConfigId]
 * @property {boolean} liked
 * @property {UserEntity} [receiver]
 * @property {string} [resource]
 * @property {UserEntity} [sender]
 * @property {DateTime} start
 * @property {DateTime} [startForIVR]
 * @property {string} [status]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {boolean} viewed
 */

/**
 * @typedef {Object} EventMetricsInputType
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 */

/**
 * @typedef {Object} EventMetricsOutputType
 * @property {number} completedCount
 * @property {number} completedCountMonth
 * @property {number} incompleteCount
 * @property {number} incompleteCountMonth
 * @property {number} missedCount
 * @property {number} missedCountMonth
 * @property {number} totalCount
 * @property {number} totalCountMonth
 */

/**
 * @typedef {Object} EventWithTimeWindow
 * @property {EventEntity} event
 * @property {StudyArmLibraryConfigsEntity} [libraryConfig]
 * @property {DateTime} windowEndTime
 * @property {DateTime} windowStartTime
 */

/**
 * @typedef {Object} EventsInput
 * @property {string} [actions]
 * @property {number} [confirmed]
 * @property {string} [data]
 * @property {string} [description]
 * @property {DateTime} [end]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {string} [resource]
 * @property {DateTime} [start]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} EventsOutput
 * @property {number} count
 * @property {Array<EventEntity>} items
 */

/**
 * @typedef {Object} FamilyMemberEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {string} name
 * @property {PhoneNumberEntity} [phoneNumber]
 * @property {string} relationship
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} FamilyMemberInputType
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [id]
 * @property {string} [name]
 * @property {PhoneNumberInputType} [phoneNumber]
 * @property {string} [relationship]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} FileEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {number} id
 * @property {string} [path]
 * @property {number} [size]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {string} [url]
 */

/**
 * @typedef {Object} FilesInput
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [path]
 * @property {number} [size]
 * @property {string} [title]
 * @property {string} [type]
 * @property {string} [url]
 */

/**
 * @typedef {Object} FilesOutput
 * @property {number} count
 * @property {Array<FileEntity>} items
 */

/**
 * @typedef {Object} FitbitActivityEntity
 * @property {Array<ActivityLevel>} [activityLevel]
 * @property {number} [averageHeartRate]
 * @property {number} [calories]
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {number} [distance]
 * @property {string} [distanceUnit]
 * @property {number} duration
 * @property {number} [elivation]
 * @property {string} id
 * @property {string} name
 * @property {number} [pace]
 * @property {number} [speed]
 * @property {string} startTime
 * @property {number} [steps]
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} version
 */

/**
 * @typedef {Object} FitbitActivityTrendData
 * @property {string} dateTime
 * @property {number} value
 */

/**
 * @typedef {Object} FitbitActivityTrendInputType
 * @property {string} [date]
 * @property {string} period
 * @property {string} resource
 * @property {number} userId
 */

/**
 * @typedef {Object} FitbitChartsData
 * @property {Array<FitbitActivityEntity>} activity
 * @property {Array<FitbitDistanceEntity>} distance
 * @property {Array<FitbitHeartRateEntity>} heartRate
 * @property {Array<FitbitSleepEntity>} sleep
 * @property {Array<FitbitStepsEntity>} steps
 */

/**
 * @typedef {Object} FitbitDistanceEntity
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} value
 * @property {string} version
 */

/**
 * @typedef {Object} FitbitEntity
 * @property {DateTime} createdAt
 * @property {JSONObject} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [hash]
 * @property {string} id
 * @property {string} type
 * @property {DateTime} updatedAt
 * @property {number} userId
 */

/**
 * @typedef {Object} FitbitHeartRateEntity
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {number} restingHeartRate
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} version
 */

/**
 * @typedef {Object} FitbitHistoryEntity
 * @property {DateTime} createdAt
 * @property {JSONObject} [data]
 * @property {string} id
 * @property {number} [statusCode]
 * @property {string} type
 * @property {string} [url]
 * @property {number} [userId]
 */

/**
 * @typedef {Object} FitbitLifetimeStats
 * @property {string} label
 * @property {string} metric
 */

/**
 * @typedef {Object} FitbitSleepEntity
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {number} duration
 * @property {string} endTime
 * @property {string} id
 * @property {string} startTime
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} version
 */

/**
 * @typedef {Object} FitbitStepsEntity
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} value
 * @property {string} version
 */

/**
 * @typedef {Object} FitbitWeightEntity
 * @property {number} bmi
 * @property {DateTime} createdAt
 * @property {Array<JSONObject>} [data]
 * @property {DateTime} date
 * @property {DateTime} [deletedAt]
 * @property {number} fat
 * @property {string} id
 * @property {DateTime} updatedAt
 * @property {UserEntity} [user]
 * @property {string} version
 * @property {number} weight
 */

/**
 * @typedef {Object} GraphDataPointsInput
 * @property {string} fromDate
 * @property {Array<number>} questionsId
 * @property {string} toDate
 * @property {number} userId
 */

/**
 * @typedef {Object} GraphDataPointsOutput
 * @property {Array<AssessmentAnswerEntity>} items
 */

/**
 * @typedef {Object} InviteParticipantInputType
 * @property {string} [age]
 * @property {string} [clinicId]
 * @property {string} email
 * @property {string} name
 * @property {Array<CountryInputType>} [numbersForCalls]
 * @property {Array<CountryInputType>} [numbersForMessages]
 * @property {string} [preferredLanguage]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {number} [studyArm]
 * @property {number} [studyId]
 * @property {string} [timezone]
 */

/**
 * @typedef {Object} InviteStaffInputType
 * @property {string} email
 * @property {string} name
 * @property {number} role
 * @property {Array<number>} [studyArmIds]
 */

/**
 * @typedef {Object} InviteStaffOutputType
 * @property {Array<string>} [errors]
 * @property {Array<UserEntity>} users
 */

/**
 * The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).
 * @typedef {*} JSONObject
 */

/**
 * @typedef {Object} LibraryEntity
 * @property {Array<AssessmentResponseEntity>} [assessmentResponses]
 * @property {string} [automations]
 * @property {DateTime} createdAt
 * @property {UserEntity} [creator]
 * @property {DateTime} [deletedAt]
 * @property {number} [duration]
 * @property {string} [icon]
 * @property {string} id
 * @property {string} [initialMessage]
 * @property {Array<StudyArmLibraryConfigsEntity>} [libraryConfigs]
 * @property {number} [preIVRDuration]
 * @property {string} [preIVRDurationType]
 * @property {Array<AssessmentQuestionEntity>} [questions]
 * @property {Array<StudyEntity>} [studies]
 * @property {Array<StudyArmEntity>} [studyArms]
 * @property {Array<LibraryEntity>} [subsets]
 * @property {LibraryEntity} [superset]
 * @property {string} title
 * @property {TooltipEntity} [tooltip]
 * @property {string} [type]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} LibraryOutputType
 * @property {number} count
 * @property {Array<LibraryEntity>} items
 * @property {Array<string>} types
 */

/**
 * @typedef {Object} LibraryWhereInputType
 * @property {number} [id]
 * @property {string} [title]
 * @property {Array<string>} [type]
 */

/**
 * @typedef {Object} LimitInput
 * @property {number} [skip]
 * @property {number} [take]
 */

/**
 * @typedef {Object} ManageChartTabInputType
 * @property {Array<ChartTabInputType>} create
 * @property {Array<number>} delete
 * @property {Array<ChartTabInputType>} update
 */

/**
 * @typedef {Object} ManageChartTabResponseType
 * @property {boolean} success
 */

/**
 * @typedef {Object} MedicationEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} description
 * @property {string} id
 * @property {string} label
 * @property {boolean} treatment
 * @property {DateTime} updatedAt
 * @property {number} userId
 */

/**
 * @typedef {Object} MedicationInputType
 * @property {string} description
 * @property {string} label
 * @property {boolean} [treatment]
 */

/**
 * @typedef {Object} MedicationOutputType
 * @property {number} count
 * @property {Array<MedicationEntity>} items
 */

/**
 * @typedef {Object} MedicationWhereInputType
 * @property {number} [creatorId]
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [label]
 * @property {boolean} [treatment]
 * @property {number} [userId]
 */

/**
 * @typedef {Object} MemberInputType
 * @property {string} [address]
 * @property {string} [age]
 * @property {string} [clinicId]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} [email]
 * @property {string} [fitbitAccessToken]
 * @property {string} [fitbitId]
 * @property {string} [fitbitRefreshToken]
 * @property {string} [fitbitScopes]
 * @property {string} [id]
 * @property {string} [idNumber]
 * @property {boolean} [isSubscribed]
 * @property {number} [lastMessageAt]
 * @property {string} [name]
 * @property {string} [notificationPreferenceType]
 * @property {string} [password]
 * @property {string} [preferredLanguage]
 * @property {string} [preferredTime]
 * @property {boolean} [privateNotes]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {string} [status]
 * @property {Array<number>} [studyArmsId]
 * @property {string} [timezone]
 * @property {DateTime} [updatedAt]
 * @property {string} [vonageName]
 * @property {string} [vonageUuid]
 * @property {string} [zipCode]
 */

/**
 * @typedef {Object} MessageEntity
 * @property {string} [actions]
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {number} id
 * @property {string} [labels]
 * @property {boolean} liked
 * @property {UserEntity} [receiver]
 * @property {number} [receiverId]
 * @property {UserEntity} [sender]
 * @property {number} [senderId]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {boolean} viewed
 * @property {string} [vonageId]
 */

/**
 * @typedef {Object} MessagesInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} MessagesOutput
 * @property {number} count
 * @property {boolean} isGroupChat
 * @property {Array<MessageEntity>} items
 */

/**
 * @typedef {Object} MetricsAdherence
 * @property {DateTime} [endDate]
 * @property {number} id
 * @property {UserEntity} receiver
 * @property {DateTime} startDate
 * @property {string} [status]
 * @property {string} type
 */

/**
 * @typedef {Object} MetricsAdherenceOutput
 * @property {Array<MetricsAdherence>} adherenceItems
 * @property {number} [completedJournals]
 * @property {Array<LibraryEntity>} [libraries]
 */

/**
 * @typedef {Object} MetricsInputType
 * @property {number} [fromUserId]
 * @property {number} [studyId]
 * @property {number} [toUserId]
 * @property {string} [type]
 */

/**
 * @typedef {Object} MetricsOutputType
 * @property {number} count
 * @property {number} enrolledCount
 * @property {number} increasedCountRate
 * @property {number} increasedDurationRate
 * @property {number} increasedEnrollRate
 * @property {number} totalDuration
 */

/**
 * @typedef {Object} MetricsUsersOutputType
 * @property {number} participantCount
 * @property {number} participantCountMonth
 */

/**
 * @typedef {Object} ModuleInputType
 * @property {string} [automations]
 * @property {DateTime} [createdAt]
 * @property {MemberInputType} [creator]
 * @property {DateTime} [deletedAt]
 * @property {number} [duration]
 * @property {string} [icon]
 * @property {string} [id]
 * @property {string} [initialMessage]
 * @property {number} [preIVRDuration]
 * @property {string} [preIVRDurationType]
 * @property {Array<AssessmentQuestionInputType>} [questions]
 * @property {Array<ModuleInputType>} [subsets]
 * @property {ModuleInputType} [superset]
 * @property {string} [title]
 * @property {TooltipInputType} [tooltip]
 * @property {string} [type]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} Mutation
 * @property {AssessmentResponseEntity} [addAssessmentAnswer]
 * @property {MedicationEntity} [addMedication]
 * @property {SymptomEntity} [addSymptom]
 * @property {ChartTabEntity} chartTab
 * @property {EventEntity} [confirmEvent]
 * @property {EventEntity} [confirmProposedTimeForEvent]
 * @property {AssessmentQuestionEntity} [createAssessmentQuestion]
 * @property {AssessmentResponseEntity} [createAssessmentResponse]
 * @property {AssessmentResponseEntity} [createAssessmentResponseWithToken]
 * @property {ChartEntity} [createChart]
 * @property {ChartTabEntity} createChartTab
 * @property {EmailEntity} [createEmail]
 * @property {Array<EventEntity>} [createEvent]
 * @property {FileEntity} [createFile]
 * @property {Array<FileEntity>} [createFiles]
 * @property {LibraryEntity} [createLibrary]
 * @property {LibraryEntity} [createLibrarySubset]
 * @property {MessageEntity} [createMessage]
 * @property {LibraryEntity} [createModule]
 * @property {NoteEntity} [createNote]
 * @property {NotificationEntity} [createNotification]
 * @property {PermissionEntity} [createPermission]
 * @property {RoleEntity} [createRole]
 * @property {StudyEntity} [createStudy]
 * @property {StudyPermissionEntity} [createStudyPermission]
 * @property {StudyRoleEntity} [createStudyRole]
 * @property {SuggestedDateEntity} createSuggestedDate
 * @property {UserEntity} [createUser]
 * @property {UserSubscriptionEntity} [createUserSubscription]
 * @property {VoiceEntity} [createVoice]
 * @property {ChartEntity} [deleteChart]
 * @property {ChartTabEntity} deleteChartTab
 * @property {EmailEntity} [deleteEmail]
 * @property {EventEntity} [deleteEvent]
 * @property {FileEntity} [deleteFile]
 * @property {string} [deleteLibrary]
 * @property {MessageEntity} [deleteMessage]
 * @property {NoteEntity} [deleteNote]
 * @property {NotificationEntity} [deleteNotification]
 * @property {PermissionEntity} [deletePermission]
 * @property {Array<RoleEntity>} [deleteRole]
 * @property {StudyEntity} [deleteStudy]
 * @property {StudyPermissionEntity} [deleteStudyPermission]
 * @property {StudyRoleEntity} [deleteStudyRole]
 * @property {Array<UserEntity>} [deleteUser]
 * @property {VoiceEntity} [deleteVoice]
 * @property {UserEntity} [inviteParticipant]
 * @property {InviteStaffOutputType} [inviteStaff]
 * @property {number} [likeAllEmails]
 * @property {number} [likeAllEvents]
 * @property {number} [likeAllMessages]
 * @property {number} [likeAllNotes]
 * @property {number} [likeAllNotifications]
 * @property {number} [likeAllVoices]
 * @property {ManageChartTabResponseType} manageChartTabs
 * @property {boolean} [resetPassword]
 * @property {boolean} [sendInviteEmail]
 * @property {Array<MessageEntity>} [sendMMS]
 * @property {boolean} [sendResetPasswordLink]
 * @property {MessageEntity} [sendSMS]
 * @property {MessageEntity} [sendVideoConferenceSMS]
 * @property {string} shortenString
 * @property {ConferenceEntity} [startConference]
 * @property {ConferenceEntity} [stopVideoCall]
 * @property {boolean} [testOutboundCall]
 * @property {boolean} [unsubscribeUser]
 * @property {AssessmentResponseEntity} [updateAssessmentAnswer]
 * @property {AssessmentQuestionEntity} [updateAssessmentQuestion]
 * @property {AssessmentResponseEntity} [updateAssessmentResponse]
 * @property {ChartEntity} [updateChart]
 * @property {ChartTabEntity} updateChartTab
 * @property {EventEntity} [updateCoachingEventStatus]
 * @property {ConferenceEntity} updateConference
 * @property {EmailEntity} [updateEmail]
 * @property {EventEntity} [updateEvent]
 * @property {FileEntity} [updateFile]
 * @property {LibraryEntity} [updateLibrary]
 * @property {MessageEntity} [updateMessage]
 * @property {NoteEntity} [updateNote]
 * @property {NotificationEntity} [updateNotification]
 * @property {NotificationpPreferencesEntity} [updateNotificationPreference]
 * @property {PermissionEntity} [updatePermission]
 * @property {RoleEntity} [updateRole]
 * @property {StudyEntity} [updateStudy]
 * @property {StudyPermissionEntity} [updateStudyPermission]
 * @property {StudyRoleEntity} [updateStudyRole]
 * @property {UserEntity} [updateUser]
 * @property {UserEntity} [updateUserPermissions]
 * @property {UserEntity} [updateUserProfileDetail]
 * @property {UserEntity} [updateUserProfileInfoDetails]
 * @property {UserEntity} [updateUserProfileInformation]
 * @property {VoiceEntity} [updateVoice]
 * @property {string} [uploadIcon]
 * @property {UserEntity} [validateDirectSignin]
 * @property {UserEntity} [verifyUser]
 * @property {number} [viewAllEmails]
 * @property {number} [viewAllEvents]
 * @property {number} [viewAllMessages]
 * @property {number} [viewAllNotes]
 * @property {number} [viewAllNotifications]
 * @property {number} [viewAllVoices]
 */

/**
 * @typedef {Object} NoteEntity
 * @property {string} [actions]
 * @property {AssessmentResponseEntity} [assessmentResponse]
 * @property {ConferenceEntity} [conference]
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {UserEntity} [editor]
 * @property {string} [entity]
 * @property {number} id
 * @property {boolean} isPinned
 * @property {string} [labels]
 * @property {boolean} liked
 * @property {number} [parentId]
 * @property {string} [status]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {UserEntity} user
 * @property {number} [userId]
 * @property {boolean} viewed
 */

/**
 * @typedef {Object} NotesInput
 * @property {string} [actions]
 * @property {number} [assessmentResponseId]
 * @property {number} [conferenceId]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [editor]
 * @property {string} [entity]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [parentId]
 * @property {string} [status]
 * @property {string} [title]
 * @property {string} [type]
 * @property {number} [user]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} NotesOutput
 * @property {number} count
 * @property {Array<NoteEntity>} items
 */

/**
 * @typedef {Object} NotificationEntity
 * @property {string} [actions]
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {number} id
 * @property {boolean} [isSubscribed]
 * @property {string} [labels]
 * @property {boolean} liked
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {number} [studyId]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {boolean} viewed
 */

/**
 * @typedef {Object} NotificationpPreferencesEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {UserSubscriptionEntity} subscription
 * @property {string} type
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} NotificationsInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [receiverId]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} NotificationsOutput
 * @property {number} count
 * @property {Array<NotificationEntity>} items
 */

/**
 * @typedef {Object} OpentokAuthOutputType
 * @property {string} [apiKey]
 * @property {string} [sessionId]
 * @property {string} [token]
 */

/**
 * @typedef {Object} ParticipantInput
 * @property {boolean} address
 * @property {boolean} clinicId
 * @property {boolean} email
 * @property {boolean} goals
 * @property {boolean} name
 * @property {boolean} notes
 * @property {boolean} recordings
 * @property {boolean} startDate
 * @property {boolean} studyArm
 * @property {boolean} zipCode
 */

/**
 * @typedef {Object} PermissionEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} id
 * @property {string} name
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} PermissionInputType
 * @property {string} [description]
 * @property {string} name
 */

/**
 * @typedef {Object} PermissionOutputType
 * @property {number} count
 * @property {Array<PermissionEntity>} items
 */

/**
 * @typedef {Object} PermissionPartialInputType
 * @property {string} [description]
 * @property {number} id
 * @property {string} [name]
 */

/**
 * @typedef {Object} PermissionWhereType
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [name]
 */

/**
 * @typedef {Object} PhoneNumberEntity
 * @property {CountryPhoneNumberEntity} [country]
 * @property {string} [countryId]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {boolean} isPreferred
 * @property {string} label
 * @property {string} type
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} PhoneNumberInputType
 * @property {CountryPhoneNumberInput} [country]
 * @property {string} [countryId]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [id]
 * @property {boolean} [isPreferred]
 * @property {string} [label]
 * @property {string} [type]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} Query
 * @property {MetricsAdherenceOutput} adherenceMetrics
 * @property {LibraryOutputType} allJournals
 * @property {ArchiveOutput} [archiveSession]
 * @property {Array<AssessmentsAnsweredResponseOutputType>} assessmentAnsweredResponse
 * @property {Array<AssessmentsAnsweredResponseOutputType>} assessmentAnsweredResponses
 * @property {AssessmentQuestionEntity} [assessmentQuestion]
 * @property {AssessmentQuestionOutputType} assessmentQuestions
 * @property {AssessmentResponseEntity} [assessmentResponse]
 * @property {AssessmentResponseOutputType} assessmentResponses
 * @property {AuditEntity} [audit]
 * @property {AuditOutput} audits
 * @property {ConferenceWithUsersOutputType} callConferences
 * @property {CanDeleteLibraryOutputType} [canDeleteLibrary]
 * @property {Array<ChartTabOutputType>} chartTabs
 * @property {Array<string>} clinicIds
 * @property {CoachingAssessmentForNotesOutput} coachingAssessmentForNotes
 * @property {Array<CoachingEvents>} coachingEvents
 * @property {ConferenceEntity} [conference]
 * @property {ConferenceOutputType} conferences
 * @property {number} countEmails
 * @property {number} countEvents
 * @property {number} countFiles
 * @property {number} countMessages
 * @property {number} countNotes
 * @property {number} countNotifications
 * @property {number} countVoices
 * @property {LibraryOutputType} dailyAssessments
 * @property {LibraryOutputType} dailyJournals
 * @property {EmailEntity} [email]
 * @property {EmailsOutput} emails
 * @property {EventEntity} [event]
 * @property {EventsOutput} events
 * @property {boolean} exportFitbitData
 * @property {boolean} exportMessages
 * @property {boolean} exportReport
 * @property {boolean} exportStudy
 * @property {boolean} exportStudyArmData
 * @property {StringShortenOutputType} fetchOriginalString
 * @property {FileEntity} [file]
 * @property {FilesOutput} files
 * @property {FitbitEntity} fitbitActivities
 * @property {Array<FitbitActivityEntity>} fitbitActivitiesList
 * @property {Array<FitbitActivityTrendData>} fitbitActivitiesTrend
 * @property {FitbitChartsData} fitbitChartsData
 * @property {Array<FitbitDistanceEntity>} fitbitDistanceList
 * @property {Array<FitbitHeartRateEntity>} fitbitHeartRateList
 * @property {Array<FitbitHistoryEntity>} fitbitHistory
 * @property {Array<FitbitHistoryEntity>} fitbitHistoryByUser
 * @property {Array<FitbitLifetimeStats>} fitbitLifetimeStats
 * @property {Array<FitbitSleepEntity>} fitbitSleepList
 * @property {Array<FitbitStepsEntity>} fitbitStepsList
 * @property {Array<FitbitWeightEntity>} fitbitWeightList
 * @property {Array<ChartEntity>} [getCharts]
 * @property {Array<EventWithTimeWindow>} getProfileEvents
 * @property {Array<EventWithTimeWindow>} getScheduledEvents
 * @property {GraphDataPointsOutput} graphDataPoints
 * @property {string} hello
 * @property {LibraryOutputType} libraries
 * @property {LibraryEntity} [library]
 * @property {LibraryEntity} [libraryWithToken]
 * @property {MedicationEntity} [medication]
 * @property {MedicationOutputType} medications
 * @property {MessageEntity} [message]
 * @property {MessagesOutput} messages
 * @property {MetricsOutputType} metricsConferences
 * @property {EventMetricsOutputType} metricsEvents
 * @property {MetricsUsersOutputType} metricsUsers
 * @property {NoteEntity} [note]
 * @property {NotesOutput} notes
 * @property {NotificationEntity} [notification]
 * @property {NotificationsOutput} notifications
 * @property {OpentokAuthOutputType} opentokAuth
 * @property {LibraryOutputType} participantCoachingLibrariesForDate
 * @property {PermissionEntity} [permission]
 * @property {PermissionOutputType} permissions
 * @property {QuestionCategoryOutputType} questionCategories
 * @property {QuestionCategoryEntity} [questionCategory]
 * @property {RoleEntity} [role]
 * @property {RolesOutputType} roles
 * @property {Array<AutomationWhoOutputType>} searchAutomationWho
 * @property {Array<RoleEntity>} searchRoles
 * @property {StudyOutputType} studies
 * @property {StudyOutputType} studiesWithStudyArms
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {StudyArmLibraryConfigsEntity} [studyArmLibraryConfigs]
 * @property {StudyArmOutputType} studyArms
 * @property {Array<StudyArmEntity>} studyArmsByStaff
 * @property {StudyPermissionEntity} [studyPermission]
 * @property {StudyPermissionsOutput} studyPermissions
 * @property {StudyRoleEntity} [studyRole]
 * @property {StudyRoleOutput} studyRoles
 * @property {SuggestedDateEntity} [suggestedDate]
 * @property {SymptomEntity} [symptom]
 * @property {SymptomOutputType} symptoms
 * @property {boolean} syncFitbitData
 * @property {TooltipEntity} [tooltip]
 * @property {TooltipOutputType} tooltips
 * @property {TranslateOutputType} [translate]
 * @property {UserEntity} [user]
 * @property {UserConferencesOutputType} userConferences
 * @property {UserLoginOutputType} userLogin
 * @property {UserProfileDetailType} [userProfileGeneralDetails]
 * @property {UserInfoDetailsOutputType} [userProfileInfoDetails]
 * @property {UserEntity} [userProfileInformation]
 * @property {UserEntity} userRegistration
 * @property {UserVerifiedInputType} userVerified
 * @property {UsersOutputType} users
 * @property {UsersOutputType} usersEmails
 * @property {UsersOutputType} usersEvents
 * @property {UsersOutputType} usersMessages
 * @property {UsersOutputType} usersVoices
 * @property {VoiceEntity} [voice]
 * @property {VoicesOutput} voices
 * @property {AuthOutputType} vonageAuth
 */

/**
 * @typedef {Object} QuestionCategoryEntity
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {string} name
 * @property {Array<AssessmentQuestionEntity>} [questions]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} QuestionCategoryInputType
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [id]
 * @property {string} [name]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} QuestionCategoryOutputType
 * @property {number} count
 * @property {Array<QuestionCategoryEntity>} items
 */

/**
 * @typedef {Object} QuestionCategoryWhereInputType
 * @property {number} [id]
 * @property {string} [name]
 */

/**
 * @typedef {Object} RecordingEntity
 * @property {string} archiveId
 * @property {string} conversationUuid
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [endTime]
 * @property {string} id
 * @property {string} recordingUrl
 * @property {string} recordingUuid
 * @property {string} sessionId
 * @property {string} size
 * @property {string} startTime
 * @property {string} type
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} RoleAutoCompleteInputType
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} RoleEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} id
 * @property {string} name
 * @property {Array<PermissionEntity>} [permissions]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} RoleInputType
 * @property {string} [description]
 * @property {string} name
 */

/**
 * @typedef {Object} RoleWhereInputType
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [name]
 */

/**
 * @typedef {Object} RolesOutputType
 * @property {number} count
 * @property {Array<RoleEntity>} items
 */

/**
 * @typedef {Object} StaffEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {RoleEntity} role
 * @property {DateTime} updatedAt
 * @property {UserEntity} user
 */

/**
 * @typedef {Object} Stream
 * @property {boolean} hasAudio
 * @property {boolean} hasVideo
 * @property {string} streamId
 */

/**
 * @typedef {Object} StringShortenOutputType
 * @property {string} originalString
 * @property {string} token
 */

/**
 * @typedef {Object} StudyArmEntity
 * @property {Array<UserEntity>} [coaches]
 * @property {JSONObject} [communications]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {Array<StudyArmLibraryConfigsEntity>} [libraryConfigs]
 * @property {string} name
 * @property {DateTime} updatedAt
 * @property {Array<UserEntity>} [users]
 */

/**
 * @typedef {Object} StudyArmExportInput
 * @property {boolean} avgActiveMins
 * @property {boolean} avgSteps
 * @property {boolean} fitbitSynced
 * @property {boolean} hoursOfCall
 * @property {boolean} numberOfEmails
 * @property {boolean} numberOfMessages
 */

/**
 * @typedef {Object} StudyArmInputType
 * @property {Array<JSONObject>} [communications]
 * @property {Array<StudyArmLibraryConfigsInputType>} [libraryConfigs]
 * @property {string} name
 * @property {string} studyArmId
 * @property {Array<number>} userIds
 */

/**
 * @typedef {Object} StudyArmLibraryConfigsEntity
 * @property {number} [afterStart]
 * @property {number} [beforeStart]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {number} duration
 * @property {string} id
 * @property {LibraryEntity} library
 * @property {number} offset
 * @property {number} [order]
 * @property {number} [repeat]
 * @property {string} [repeatText]
 * @property {string} [time]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} StudyArmLibraryConfigsInputType
 * @property {number} [afterStart]
 * @property {number} [beforeStart]
 * @property {number} duration
 * @property {number} [id]
 * @property {number} libraryId
 * @property {number} offset
 * @property {number} [order]
 * @property {number} [repeat]
 * @property {string} [repeatText]
 * @property {string} time
 */

/**
 * @typedef {Object} StudyArmOutputType
 * @property {number} count
 * @property {Array<StudyArmEntity>} items
 */

/**
 * @typedef {Object} StudyArmWhereInputType
 * @property {number} [id]
 * @property {string} [name]
 */

/**
 * @typedef {Object} StudyEntity
 * @property {DateTime} createdAt
 * @property {UserEntity} [creator]
 * @property {number} [creatorId]
 * @property {DateTime} [deletedAt]
 * @property {string} displayName
 * @property {number} id
 * @property {string} irb
 * @property {string} [moduleId]
 * @property {Array<LibraryEntity>} [modules]
 * @property {string} name
 * @property {Array<UserEntity>} [staff]
 * @property {Array<StudyArmEntity>} [studyArms]
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} StudyInputType
 * @property {string} displayName
 * @property {string} irb
 * @property {string} name
 */

/**
 * @typedef {Object} StudyOutputType
 * @property {number} count
 * @property {Array<StudyEntity>} items
 */

/**
 * @typedef {Object} StudyPermissionEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {PermissionEntity} permission
 * @property {string} permissionId
 * @property {StudyEntity} study
 * @property {string} studyId
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} StudyPermissionWhereInput
 * @property {number} [id]
 * @property {number} [permissionId]
 * @property {number} [studyId]
 */

/**
 * @typedef {Object} StudyPermissionsOutput
 * @property {number} count
 * @property {Array<StudyPermissionEntity>} items
 */

/**
 * @typedef {Object} StudyRoleEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} id
 * @property {RoleEntity} role
 * @property {StudyEntity} study
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} StudyRoleOutput
 * @property {number} count
 * @property {Array<StudyRoleEntity>} items
 */

/**
 * @typedef {Object} StudyRoleWhereInput
 * @property {number} [id]
 */

/**
 * @typedef {Object} StudyStaffInputType
 * @property {number} roleId
 * @property {Array<number>} [studyArmsIds]
 * @property {number} userId
 */

/**
 * @typedef {Object} StudyWhereInputType
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [name]
 */

/**
 * @typedef {Object} SuggestedDateEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {DateTime} endTime
 * @property {EventEntity} event
 * @property {number} id
 * @property {DateTime} startTime
 * @property {UserEntity} suggester
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} SuggestedDateWhereInputType
 * @property {number} [eventId]
 */

/**
 * @typedef {Object} SymptomEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} description
 * @property {number} [frequency]
 * @property {string} id
 * @property {string} label
 * @property {DateTime} updatedAt
 * @property {number} userId
 */

/**
 * @typedef {Object} SymptomInputType
 * @property {string} description
 * @property {number} [frequency]
 * @property {string} label
 */

/**
 * @typedef {Object} SymptomOutputType
 * @property {number} count
 * @property {Array<SymptomEntity>} items
 */

/**
 * @typedef {Object} SymptomWhereInputType
 * @property {number} [creatorId]
 * @property {string} [description]
 * @property {number} [frequency]
 * @property {number} [id]
 * @property {string} [label]
 * @property {number} [userId]
 */

/**
 * @typedef {Object} TooltipEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} [icon]
 * @property {string} id
 * @property {string} title
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} TooltipInputType
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} [icon]
 * @property {string} [id]
 * @property {string} [title]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} TooltipOutputType
 * @property {number} count
 * @property {Array<TooltipEntity>} items
 */

/**
 * @typedef {Object} TooltipWhereInputType
 * @property {number} [id]
 * @property {string} [title]
 */

/**
 * @typedef {Object} TranslateOutputType
 * @property {Array<string>} texts
 */

/**
 * @typedef {Object} TreatmentEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} disease
 * @property {string} id
 * @property {string} treatmentType
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} TreatmentInputType
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [disease]
 * @property {string} [id]
 * @property {string} [treatmentType]
 * @property {DateTime} [updatedAt]
 */

/**
 * @typedef {Object} UpdateChartInput
 * @property {number} [categoryId]
 * @property {number} [dataSourceId]
 * @property {number} [dataSourceId1]
 * @property {boolean} [fullWidth]
 * @property {JSONObject} [meta]
 * @property {Array<string>} [plots]
 * @property {Array<string>} [plots1]
 * @property {string} [title]
 * @property {string} [title1]
 * @property {string} [type]
 * @property {string} [type1]
 * @property {number} [userId]
 * @property {string} [xAxis]
 * @property {string} [yAxis]
 * @property {string} [yAxis1]
 */

/**
 * @typedef {Object} UpdateEmailInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} UpdateEventInput
 * @property {string} [actions]
 * @property {number} [confirmed]
 * @property {string} [data]
 * @property {string} [description]
 * @property {DateTime} [end]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {string} [resource]
 * @property {DateTime} [start]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} UpdateFileInput
 * @property {string} [description]
 * @property {string} [path]
 * @property {number} [size]
 * @property {string} [title]
 * @property {string} [type]
 * @property {string} [url]
 */

/**
 * @typedef {Object} UpdateMessageInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} UpdateNoteInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [editorId]
 * @property {string} [entity]
 * @property {boolean} [isPinned]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [parentId]
 * @property {boolean} [privateNotes]
 * @property {string} [status]
 * @property {string} [title]
 * @property {string} [type]
 * @property {number} [userId]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} UpdateNotificationInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {string} [title]
 * @property {string} [type]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} UpdateStudyPermissionInput
 * @property {number} permissionId
 * @property {number} studyId
 */

/**
 * @typedef {Object} UpdateStudyRoleInput
 * @property {number} [id]
 * @property {number} roleId
 * @property {number} studyId
 */

/**
 * @typedef {Object} UpdateVoiceInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {string} [url]
 * @property {boolean} [viewed]
 */

/**
 * The `Upload` scalar type represents a file upload.
 * @typedef {*} Upload
 */

/**
 * @typedef {Object} UserConferencesOutputType
 * @property {number} count
 * @property {Array<UserEntity>} items
 */

/**
 * @typedef {Object} UserEntity
 * @property {string} [address]
 * @property {string} [age]
 * @property {StaffEntity} [assignedStaff]
 * @property {string} [clinicId]
 * @property {Array<ClinicEntity>} [clinics]
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} email
 * @property {Array<FamilyMemberEntity>} [familyMembers]
 * @property {string} [fitbitAccessToken]
 * @property {string} [fitbitId]
 * @property {string} [fitbitRefreshToken]
 * @property {string} [fitbitScopes]
 * @property {string} id
 * @property {string} [idNumber]
 * @property {boolean} [isSubscribed]
 * @property {number} [lastMessageAt]
 * @property {string} name
 * @property {Array<NoteEntity>} [notes]
 * @property {string} [notificationPreferenceType]
 * @property {string} [password]
 * @property {Array<StudyPermissionEntity>} [permissions]
 * @property {Array<PhoneNumberEntity>} [phoneNumbers]
 * @property {string} [preferredLanguage]
 * @property {string} [preferredTime]
 * @property {RoleEntity} [primaryRole]
 * @property {boolean} [privateNotes]
 * @property {Array<EmailEntity>} [receiverEmails]
 * @property {Array<MessageEntity>} [receiverMessages]
 * @property {Array<NotificationEntity>} [receiverNotifications]
 * @property {Array<VoiceEntity>} [receiverVoices]
 * @property {Array<StudyRoleEntity>} [roles]
 * @property {Array<EmailEntity>} [senderEmails]
 * @property {Array<MessageEntity>} [senderMessages]
 * @property {Array<NotificationEntity>} [senderNotifications]
 * @property {Array<VoiceEntity>} [senderVoices]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {string} [status]
 * @property {Array<StudyEntity>} [studies]
 * @property {Array<number>} [studyArmsId]
 * @property {string} [timezone]
 * @property {Array<TreatmentEntity>} [treatments]
 * @property {DateTime} updatedAt
 * @property {string} [vonageName]
 * @property {string} [vonageUuid]
 * @property {string} [zipCode]
 */

/**
 * @typedef {Object} UserInfoDetailsOutputType
 * @property {string} [address]
 * @property {string} [age]
 * @property {StaffEntity} [assignedStaff]
 * @property {string} [clinicId]
 * @property {Array<ClinicEntity>} [clinics]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} [email]
 * @property {Array<FamilyMemberEntity>} [familyMembers]
 * @property {string} [fitbitAccessToken]
 * @property {string} [fitbitId]
 * @property {string} [fitbitRefreshToken]
 * @property {string} [fitbitScopes]
 * @property {string} [id]
 * @property {string} [idNumber]
 * @property {boolean} [isSubscribed]
 * @property {number} [lastMessageAt]
 * @property {string} [name]
 * @property {Array<NoteEntity>} [notes]
 * @property {string} [notificationPreferenceType]
 * @property {string} [password]
 * @property {Array<StudyPermissionEntity>} [permissions]
 * @property {Array<PhoneNumberEntity>} [phoneNumbers]
 * @property {string} [preferredLanguage]
 * @property {string} [preferredTime]
 * @property {RoleEntity} [primaryRole]
 * @property {boolean} [privateNotes]
 * @property {Array<EmailEntity>} [receiverEmails]
 * @property {Array<MessageEntity>} [receiverMessages]
 * @property {Array<NotificationEntity>} [receiverNotifications]
 * @property {Array<VoiceEntity>} [receiverVoices]
 * @property {Array<StudyRoleEntity>} [roles]
 * @property {Array<EmailEntity>} [senderEmails]
 * @property {Array<MessageEntity>} [senderMessages]
 * @property {Array<NotificationEntity>} [senderNotifications]
 * @property {Array<VoiceEntity>} [senderVoices]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {string} [status]
 * @property {Array<StudyEntity>} [studies]
 * @property {string} [studyArm]
 * @property {Array<number>} [studyArmsId]
 * @property {string} [timezone]
 * @property {Array<TreatmentEntity>} [treatments]
 * @property {DateTime} [updatedAt]
 * @property {string} [vonageName]
 * @property {string} [vonageUuid]
 * @property {string} [zipCode]
 */

/**
 * @typedef {Object} UserInputType
 * @property {string} [description]
 * @property {string} email
 * @property {string} name
 * @property {string} password
 * @property {number} roleId
 * @property {string} status
 */

/**
 * @typedef {Object} UserLoginOutputType
 * @property {string} [token]
 * @property {UserEntity} [user]
 */

/**
 * @typedef {Object} UserPermissionInputType
 * @property {Array<number>} permissions
 * @property {number} roleId
 * @property {Array<number>} [studyArmIds]
 * @property {number} studyId
 */

/**
 * @typedef {Object} UserProfileDetailInputType
 * @property {AssignedStaffInputType} [assignedStaff]
 * @property {DateTime} [startDate]
 */

/**
 * @typedef {Object} UserProfileDetailType
 * @property {string} [address]
 * @property {string} [age]
 * @property {StaffEntity} [assignedStaff]
 * @property {string} [clinicId]
 * @property {Array<ClinicEntity>} [clinics]
 * @property {DateTime} [createdAt]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {string} [email]
 * @property {Array<FamilyMemberEntity>} [familyMembers]
 * @property {string} [fitbitAccessToken]
 * @property {string} [fitbitId]
 * @property {string} [fitbitRefreshToken]
 * @property {string} [fitbitScopes]
 * @property {string} [id]
 * @property {string} [idNumber]
 * @property {boolean} [isSubscribed]
 * @property {number} [lastMessageAt]
 * @property {string} [name]
 * @property {Array<NoteEntity>} [notes]
 * @property {string} [notificationPreferenceType]
 * @property {string} [password]
 * @property {Array<StudyPermissionEntity>} [permissions]
 * @property {Array<PhoneNumberEntity>} [phoneNumbers]
 * @property {string} [preferredLanguage]
 * @property {string} [preferredTime]
 * @property {RoleEntity} [primaryRole]
 * @property {boolean} [privateNotes]
 * @property {Array<EmailEntity>} [receiverEmails]
 * @property {Array<MessageEntity>} [receiverMessages]
 * @property {Array<NotificationEntity>} [receiverNotifications]
 * @property {Array<VoiceEntity>} [receiverVoices]
 * @property {Array<StudyRoleEntity>} [roles]
 * @property {Array<EmailEntity>} [senderEmails]
 * @property {Array<MessageEntity>} [senderMessages]
 * @property {Array<NotificationEntity>} [senderNotifications]
 * @property {Array<VoiceEntity>} [senderVoices]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {string} [status]
 * @property {Array<StudyEntity>} [studies]
 * @property {Array<number>} [studyArmsId]
 * @property {string} [timezone]
 * @property {Array<TreatmentEntity>} [treatments]
 * @property {DateTime} [updatedAt]
 * @property {string} [vonageName]
 * @property {string} [vonageUuid]
 * @property {string} [zipCode]
 */

/**
 * @typedef {Object} UserProfileDetailsInputType
 * @property {string} [address]
 * @property {string} [age]
 * @property {AssignedStaffInputType} [assignedStaff]
 * @property {string} [clinicId]
 * @property {Array<ClinicInputType>} clinics
 * @property {Array<FamilyMemberInputType>} familyMembers
 * @property {string} [idNumber]
 * @property {string} name
 * @property {Array<PhoneNumberInputType>} phoneNumbers
 * @property {string} [preferredLanguage]
 * @property {string} [preferredTime]
 * @property {string} [sexualOrientation]
 * @property {DateTime} [startDate]
 * @property {Array<TreatmentInputType>} treatments
 * @property {string} [zipCode]
 */

/**
 * @typedef {Object} UserProfileInfoInputType
 * @property {string} [address]
 * @property {string} [clinicId]
 * @property {Array<ClinicInputType>} clinics
 * @property {Array<FamilyMemberInputType>} familyMembers
 * @property {string} [idNumber]
 * @property {string} name
 * @property {Array<PhoneNumberInputType>} phoneNumbers
 * @property {string} [preferredLanguage]
 * @property {Array<TreatmentInputType>} treatments
 */

/**
 * @typedef {Object} UserStudyInputType
 * @property {number} [studyArmId]
 * @property {number} studyId
 * @property {number} userId
 */

/**
 * @typedef {Object} UserSubscriptionEntity
 * @property {DateTime} createdAt
 * @property {DateTime} [deletedAt]
 * @property {UserEntity} fromUser
 * @property {string} id
 * @property {boolean} isSubscribed
 * @property {NotificationpPreferencesEntity} notificationPreference
 * @property {UserEntity} toUser
 * @property {DateTime} updatedAt
 */

/**
 * @typedef {Object} UserUpdateInputType
 * @property {string} [description]
 * @property {string} email
 * @property {string} name
 * @property {boolean} [privateNotes]
 * @property {number} [roleId]
 * @property {string} status
 */

/**
 * @typedef {Object} UserVerifiedInputType
 * @property {boolean} verified
 */

/**
 * @typedef {Object} UsersOutputType
 * @property {number} count
 * @property {Array<UserEntity>} items
 */

/**
 * @typedef {Object} VoiceEntity
 * @property {string} [actions]
 * @property {string} [audioUrl]
 * @property {DateTime} createdAt
 * @property {string} [data]
 * @property {DateTime} [deletedAt]
 * @property {string} [description]
 * @property {number} id
 * @property {string} [labels]
 * @property {boolean} liked
 * @property {UserEntity} [receiver]
 * @property {number} [receiverId]
 * @property {UserEntity} [sender]
 * @property {number} [senderId]
 * @property {StudyEntity} [study]
 * @property {StudyArmEntity} [studyArm]
 * @property {string} title
 * @property {string} [type]
 * @property {DateTime} updatedAt
 * @property {string} [url]
 * @property {boolean} viewed
 */

/**
 * @typedef {Object} VoicesInput
 * @property {string} [actions]
 * @property {string} [data]
 * @property {string} [description]
 * @property {number} [id]
 * @property {string} [labels]
 * @property {boolean} [liked]
 * @property {number} [studyArmId]
 * @property {number} [studyId]
 * @property {string} [title]
 * @property {string} [type]
 * @property {string} [url]
 * @property {boolean} [viewed]
 */

/**
 * @typedef {Object} VoicesOutput
 * @property {number} count
 * @property {Array<VoiceEntity>} items
 */