import { useCallback, HTMLAttributes, ReactElement } from 'react';
import { Stack, Typography, AppBar, Toolbar, Button, Container } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AssessmentQuestionInputType } from '../../../graphql';
import { DialogCloseButton } from '../../atoms';
import { theme } from '../../../theme';
import HydrationQuestion from './HydrationQuestion';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  title: string;
  i18nKey?: string;
  questions?: AssessmentQuestionInputType[] | null;
  onPrev?: () => void;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function InputStep({
  title,
  questions,
  i18nKey,
  onClose,
  onPrev,
  onSubmit,
}: Properties) {
  const { t, Trans } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:750px)');
  const handleClose = useCallback(() => onClose && onClose(), [onClose]);
  const handlePrevious = useCallback(() => onPrev && onPrev(), [onPrev]);
  const handleSubmit = useCallback(() => onSubmit && onSubmit(), [onSubmit]);

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            background: 'white',
            minHeight: `56px !important`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" color={theme.palette.common.black} component="div">
            {i18nKey ? <Trans i18nKey={i18nKey}>{title}</Trans> : title}
          </Typography>
        </Toolbar>
        <DialogCloseButton onClick={handleClose} />
      </AppBar>
      {questions?.map(({ label, ...question }) => {
        const i18nKey = label;
        return (
          <Stack
            key={question.id}
            justifyContent="center"
            alignItems="center"
            sx={{
              p: 3,
              width: '100%',
              height: '100%',
            }}
          >
            <Typography variant={sm ? 'h4' : 'h5'} sx={{ textAlign: 'center' }}>
              {i18nKey ? <Trans i18nKey={i18nKey}>{label}</Trans> : label}
            </Typography>

            {question.type === 'hydration' && <HydrationQuestion />}

            <Container maxWidth="sm">
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', mt: 2 }}>
                <Button variant="text" onClick={handlePrevious} startIcon={<ArrowBackIosIcon />}>
                  {t('journal.button.prev')}
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  {t('journal.button.submit')}
                </Button>
              </Stack>
            </Container>
          </Stack>
        );
      })}
    </>
  );
}
