import { List, ListProps, ListItemButton } from '@mui/material';
import { Note } from '../Note';
import { theme } from '../../../../theme';
import { NoteEntity } from '../../../../graphql';

export interface NotesProperties extends ListProps {
  notes?: NoteEntity[];
  onSelectNote: (note: NoteEntity | null) => void;
  selectedNote: NoteEntity | null;
}

export const Notes = ({ notes, onSelectNote, selectedNote, ...rest }: NotesProperties) => {
  return (
    <List sx={{ p: 0 }} {...rest}>
      {notes?.map((note: NoteEntity) => (
        <ListItemButton
          key={note.id}
          selected={note.id === selectedNote?.id}
          onClick={() => onSelectNote(note)}
          sx={{
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
            p: 0,
          }}
        >
          <Note selected={note.id === selectedNote?.id} note={note} />
        </ListItemButton>
      ))}
    </List>
  );
};
