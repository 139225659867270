import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useCurrentUser, useUser } from '../../states';
import { UserQuery, useUserQuery } from '../../graphql';
import { UserRoles } from '../../constants/users';

type Properties = object;

type User = UserQuery['user'];

const checkUserPartOfStudy = (user: User, studyId: number | null | undefined) => {
  const isUserInStudy = user?.studies && user?.studies.some((study) => study.id === studyId);
  const hasUserRoleInStudy = user?.roles && user?.roles.some((role) => role.study.id === studyId);

  return isUserInStudy || hasUserRoleInStudy;
};

function withValidStudyUsers<T extends Properties>(WrappedComponent: React.ComponentType<T>) {
  const WithValidStudyUsersComponent: React.FC<T> = (properties) => {
    const { data: currentUser, loading: currentUserLoading } = useCurrentUser();

    const { studyId, checkUserRole, checkUserIsSuperAdministrator } = useUser();

    const [searchParameters] = useSearchParams();
    const qsUserId = Number(searchParameters.get('userId'));

    const { data: { user } = {}, loading: userLoading } = useUserQuery({
      variables: { id: qsUserId },
      skip: !qsUserId,
    });

    const isCurrentUserSuperAdmin = checkUserIsSuperAdministrator(currentUser?.user);
    const isUserSuperAdmin = checkUserIsSuperAdministrator(user);
    const isOtherUserSuperAdmin = checkUserIsSuperAdministrator(user);

    const isCurrentUserPartOfStudy = useMemo(
      () => checkUserPartOfStudy(currentUser?.user, studyId),
      [studyId, currentUser],
    );

    const isUserPartOfStudy = useMemo(() => checkUserPartOfStudy(user, studyId), [studyId, user]);

    if (currentUserLoading || userLoading) {
      return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={25} />
        </Box>
      );
    }

    let canMessage = false;

    // If user is super administrator
    if (isCurrentUserSuperAdmin && isUserPartOfStudy) {
      canMessage = true;
    }

    if (isCurrentUserPartOfStudy && isUserSuperAdmin) {
      canMessage = true;
    }

    if (
      currentUser &&
      (checkUserRole(currentUser.user, UserRoles.ADMINISTRATOR) ||
        checkUserRole(currentUser.user, UserRoles.SUPER_ADMINISTRATOR)) &&
      isCurrentUserPartOfStudy &&
      isOtherUserSuperAdmin
    ) {
      canMessage = true;
    }

    // for normal user i.e Patients
    if (
      currentUser &&
      checkUserRole(currentUser.user, UserRoles.PARTICIPANT) &&
      isCurrentUserPartOfStudy
    ) {
      canMessage = true;
    }

    // for only admins
    if (isCurrentUserPartOfStudy && isUserPartOfStudy) {
      canMessage = true;
    }

    if (canMessage) {
      return <WrappedComponent {...properties} />;
    }

    return (
      <Alert severity="error" sx={{ width: '100%' }}>
        User is not a part of selected study. Please switch to correct study from the menu
      </Alert>
    );
  };

  WithValidStudyUsersComponent.displayName = `WithValidStudyUsers(${WithValidStudyUsersComponent.name})`;

  return WithValidStudyUsersComponent;
}

export { withValidStudyUsers };
