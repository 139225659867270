export enum UserRoles {
  SUPER_ADMINISTRATOR = 'super administrator',
  ADMINISTRATOR = 'administrator',
  PARTICIPANT = 'participant',
  COACH = 'coach',
}

export enum UserStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  INVITE_NOT_SENT = 'invite_not_sent',
}

export enum Permissions {
  USER_MANAGEMENT = 'user management',
  STUDY_MANAGEMENT = 'study management',
  REPORTS = 'reports',
  DASHBOARD = 'dashboard',
  STUDY_ACTIVITY = 'study activity',
  MESSAGING = 'messaging',
  SCHEDULING = 'scheduling',
  LIBRARY = 'library',
  NOTIFICATIONS = 'notifications',
  NOTES = 'notes',
  VIEW_PROFILE = 'view profile',
  EDIT_PROFILE = 'edit profile',
  INVITE_PARTICIPANT = 'invite participant',
  INVITE_STAFF = 'invite staff',
  ADD_ROLE = 'add role',
  EDIT_ROLE = 'edit role',
  DELETE_ROLE = 'delete role',
  EDIT_USER_PERMISSIONS = 'edit user permissions',
  EMAIL_USER_PERMISSIONS = 'email user permissions',
  DELETE_USER = 'delete user',
  CREATE_STUDY = 'create study',
}
