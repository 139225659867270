import { Box, FormGroup, Typography, Slider, Grid } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

interface SliderFieldProperties {
  index?: number;
  label?: string;
  value?: string;
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange: (value: string) => void;
}

export const SliderField = ({
  index,
  label,
  required,
  value,
  showBoldLabel,
  hideLabel = false,
  onChange,
}: SliderFieldProperties) => {
  const numericValue = Number.parseInt(value || '0', 10);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onChange(`${newValue}`);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        {!hideLabel && (
          <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
            {index}
            {index && '.'} {label} {required && '*'}
          </Typography>
        )}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SentimentVeryDissatisfiedIcon />
            </Grid>
            <Grid item xs>
              <Slider
                min={0}
                step={1}
                max={10}
                value={numericValue}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
              />
              {numericValue}
            </Grid>
            <Grid item>
              <SentimentSatisfiedAltIcon />
            </Grid>
          </Grid>
        </Box>
      </FormGroup>
    </Box>
  );
};
