import { createContext, ReactNode, useContext, useMemo } from 'react';

import { ENDPOINTS } from '../../constants';
import { IResponseBase } from '../../types/axios';
import { IActivityData } from '../../types/user';
import { useAxios } from '../../hooks';

interface IResponse extends IResponseBase {
  data: IActivityData;
}

const defaultResponse: IResponse = {
  data: null,
  loading: true,
};

export const ActivityContext = createContext<IResponse>(defaultResponse);

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useAxios({ url: ENDPOINTS.USER_ACTIVITIES });

  const contextValue = useMemo((): IResponse => {
    return { data, loading };
  }, [data, loading]);

  return <ActivityContext.Provider value={contextValue}>{children}</ActivityContext.Provider>;
};

export const useActivity = () => useContext(ActivityContext);
