import { Options } from 'pusher-js';
import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react';
import { dequal } from 'dequal';
import { logger } from '../../utils';
import { PusherContextValues, PusherProviderProperties } from './types';
import { ChannelsProvider } from './ChannelsProvider';

// context setup
const PusherContext = createContext<PusherContextValues>({});
export const __PusherContext = PusherContext;

/**
 * Provider that creates your pusher instance and provides it to child hooks throughout your app.
 * Note, you can pass in value={{}} as a prop if you'd like to override the pusher client passed.
 * This is handy when simulating pusher locally, or for testing.
 *
 * @param props Config for Pusher client
 */

export const CorePusherProvider: FC<PusherProviderProperties> = ({
  clientKey,
  cluster,
  triggerEndpoint,
  defer = false,
  children,
  _PusherRuntime,
  ...properties
}) => {
  // errors when required props are not passed.
  useEffect(() => {
    if (!clientKey) logger.error('A client key is required for pusher');
    if (!cluster) logger.error('A cluster is required for pusher');
  }, [clientKey, cluster]);

  const config: Options = useMemo(() => ({ cluster, ...properties }), [cluster, properties]);

  // track config for comparison
  const previousConfig = useRef<Options | undefined>(properties);
  useEffect(() => {
    previousConfig.current = properties;
  });
  const [client, setClient] = useState<any | undefined>();

  useEffect(() => {
    // Skip creation of client if deferring, a value prop is passed, or config props are the same.
    if (
      !_PusherRuntime ||
      defer ||
      !clientKey ||
      properties.value ||
      (dequal(previousConfig.current, properties) && client !== undefined)
    ) {
      return;
    }

    setClient(new _PusherRuntime(clientKey, config));
  }, [client, clientKey, properties, defer, _PusherRuntime, config]);

  const value = useMemo(
    () => ({
      client,
      triggerEndpoint,
    }),
    [client, triggerEndpoint],
  );

  return (
    <PusherContext.Provider value={value} {...properties}>
      <ChannelsProvider>{children}</ChannelsProvider>
    </PusherContext.Provider>
  );
};
