import { ReactElement, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { Typography, Box, Stack, Button, IconButton, alpha, styled } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { grey } from '@mui/material/colors';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

import { theme } from '../../../theme';

interface Properties {
  acceptedExtentions: string[];
  hasMultiple: boolean;
  hasActions: boolean;
  hasDragDrop: boolean;
}

interface ExtensionIconProperties {
  name: string;
}

const ExtensionIcon = ({ name }: ExtensionIconProperties) => {
  switch (name) {
    case 'png':
    case 'jpeg':
    case 'jpg': {
      return <ImageIcon />;
    }
    case 'pdf': {
      return <PictureAsPdfOutlinedIcon />;
    }
    case 'doc':
    case 'docx': {
      return <ArticleIcon />;
    }
    default: {
      return <InsertDriveFileIcon />;
    }
  }
};

const StyledFileDrop = styled(FileDrop)(() => ({
  '&.file-drop': {
    width: '100%',
    padding: '2rem',
    borderRadius: 8,
    border: `3px dashed ${alpha(theme.palette.primary.main, 0.25)}`,
  },
  '& .file-drop-target': {
    height: '100%',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
  },
  '& .MuiSvgIcon-root': {
    color: alpha(theme.palette.primary.main, 0.25),
    fontSize: '4rem',
    marginBottom: '1rem',
  },
}));

const getExtensionFromName = (string_: string) => {
  const parts = string_.split('.');
  return parts[parts.length - 1]?.toLowerCase();
};

export function Uploader({
  acceptedExtentions = [],
  hasMultiple,
  hasActions,
  hasDragDrop,
}: Properties): ReactElement {
  const inputReference = useRef<HTMLInputElement>(null);
  const [filenames, setNames] = useState<any[]>([]);
  const { t } = usePreferredTranslation();
  const fileHandler = (files: { [name: string]: any }) => {
    const extension = getExtensionFromName(files[0]?.name);

    if (extension !== undefined) {
      const fNames = Object.keys(files).map((index: string) => {
        const { name } = files[Number(index)];
        const fileExtension = getExtensionFromName(name);

        return {
          name,
          extension: fileExtension,
          file: files[Number(index)],
        };
      });
      setNames((previous: any[]) => {
        const newFiles = [...previous, fNames].flat();
        const limitNumber = hasMultiple ? newFiles.length : 1;
        if (acceptedExtentions.length > 0) {
          return newFiles
            .filter((file) =>
              acceptedExtentions
                .map((extension_) => extension_.toLowerCase())
                .includes(`.${String(file.extension)}`),
            )
            .slice(0, limitNumber);
        }
        return newFiles.slice(0, limitNumber);
      });
    }
  };

  const filePicker = () => {
    inputReference?.current?.click();
  };

  const removeFile = (index: number) => {
    const newFiles = [...filenames];
    newFiles.splice(index, 1);
    setNames(newFiles);
  };

  return (
    <Box>
      <StyledFileDrop
        onTargetClick={filePicker}
        onDrop={(files: FileList | null) => hasDragDrop && fileHandler(files || [])}
      >
        <UploadIcon />
        {hasDragDrop && (
          <>
            <Typography variant="h5">{t('Drag and drop to upload')}</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {t('or')}
            </Typography>
          </>
        )}
        <Typography
          variant="subtitle1"
          sx={{
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {t('Browse Files')}
        </Typography>
        <input
          accept={acceptedExtentions?.join(', ')}
          value=""
          style={{ visibility: 'hidden', opacity: 0 }}
          ref={inputReference}
          multiple={hasMultiple}
          type="file"
          onChange={(event) => fileHandler(event?.target?.files || [])}
        />
      </StyledFileDrop>
      <Box sx={{ my: 2 }}>
        {filenames &&
          filenames.map((file, index) => (
            <Stack key={index} direction="row" alignItems="center" sx={{ mb: 1 }}>
              <Stack
                flex={1}
                direction="row"
                alignItems="center"
                sx={{
                  p: 2,
                  border: `1px solid ${grey[300]}`,
                  background: grey[200],
                  borderRadius: theme.typography.pxToRem(8),
                }}
              >
                <ExtensionIcon name={file.extension} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {file.name}
                </Typography>
              </Stack>
              <Box sx={{ ml: 2 }}>
                <IconButton onClick={() => removeFile(index)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </Stack>
          ))}
      </Box>
      {hasActions && (
        <Box textAlign="right" sx={{ pt: 3 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: grey[300],
              color: theme.palette.text.primary,
              mr: 2,
              '&:hover': {
                backgroundColor: grey[400],
              },
            }}
          >
            {t('Cancel')}
          </Button>
          <Button variant="contained">{t('Add Files')}</Button>
        </Box>
      )}
    </Box>
  );
}
