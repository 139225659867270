import { SVGProps } from 'react';

const TreadmillSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g fill="#6C5DD3" clipPath="url(#a)">
      <path d="M30.098 10.834a.442.442 0 0 0-.613-.117l-4.373 2.978a.44.44 0 0 0-.117.611.438.438 0 0 0 .613.118l.761-.52 1.46 3.44-2.989 8.675H9.882a.881.881 0 1 0 0 1.765h15.881a.881.881 0 0 0 .882-.882.861.861 0 0 0-.105-.396l3.07-8.906a.884.884 0 0 0-.02-.633l-1.732-4.076 2.125-1.448a.438.438 0 0 0 .115-.61ZM25.323 28.668h-15a.442.442 0 0 0 0 .882h15a.442.442 0 0 0 0-.882Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 11h21.174v18.909H9z" />
      </clipPath>
    </defs>
  </svg>
);
export default TreadmillSvg;
