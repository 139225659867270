import { styled } from '@mui/material';
import { ReactElement } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastProperties, ToastType } from './types';

const StyledToast = styled(ToastContainer)(() => ({
  '.Toastify__toast-body': {
    alignItems: 'flex-start',
  },
}));

export function Toast({
  type = ToastType.DEFAULT,
  position = toast.POSITION.BOTTOM_CENTER,
  hideProgressBar,
  limit,
  autoCloseDelay,
}: ToastProperties): ReactElement {
  const showProgressBar =
    typeof hideProgressBar === 'boolean' ? !hideProgressBar : type !== ToastType.DEFAULT;

  return (
    <StyledToast
      autoClose={autoCloseDelay}
      limit={limit}
      hideProgressBar={!showProgressBar}
      position={position}
    />
  );
}
