import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import i18next from 'i18next';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FitbitActivityTrendData,
  NoteEntity,
  UserEntity,
  UserProfileDetailsInputType,
  useAddMedicationMutation,
  useAddSymptomMutation,
  useAuditsQuery,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useEmailsQuery,
  useEventsQuery,
  useFitbitActivitiesTrendQuery,
  useFitbitLifetimeStatsQuery,
  useMessagesQuery,
  useNotesQuery,
  useProfileEventsQuery,
  useUpdateEventMutation,
  useUpdateNoteMutation,
  useUserProfileInfoDetailsQuery,
  useUserQuery,
  useVoicesQuery,
  useCallConferencesQuery,
} from '../../graphql';
import { useCurrentUser, useUser } from '../../states';
import { LineChart, LineRecharts } from '../charts';

import { Permissions, UserRoles, UserStatus } from '../../constants/users';
import NotFoundPage from '../../pages/errors/NotFound';
import useAuth from '../../states/fitbit/useAuth';
import { checkRole } from '../../utils';
import { ReportCardsPlaceholder } from '../atoms';
import PageLoader from '../atoms/PageLoader';
import { NotesStepper } from '../callnotes';
import Card from '../dashboard/Card';
import SelectCallDeviceModal from '../modals/users/SelectCallDeviceModal';
import SendMessageDeviceModal from '../modals/users/SendMessageDeviceModal';
import { NotesBoard } from '../molecules';
import Audits from '../molecules/audits/audits';
import Activities from './Activities';
import Assessments from './Assessments';
import ParticipantProfileAppBarSkinny from './ParticipantProfileAppBarSkinny';
import ParticipantProfileInformation from './ParticipantProfileInformation';
import ParticipantProfileInternalNotes from './ParticipantProfileInternalNotes';
import ParticipantProfileInterventionsHistory from './ParticipantProfileInterventionsHistory';
import ParticipantProfileSchedule from './Summary/ParticipantProfileSchedule';
import { UserCharts } from './UserCharts';
import { Integrations } from './Integrations';
import { useMediaQuery } from '../../hooks';
import Coaching from './Coaching';
import ParticipantAdherenceMetrics from './Summary/ParticipantAdherenceMetrics';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

const NO_CACHE: WatchQueryFetchPolicy = 'network-only';

// Map tab query params to tab indices
export const tabMap: Record<string, number> = {
  summary: 0,
  // notes: 1,
  history: 3,
  assessments: 2,
  audits: 4,
  charts: 5,
  integrations: 6,
};

export default function ParticipantProfile(): ReactElement {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const xs = useMediaQuery('(max-width:750px)');
  const { t } = usePreferredTranslation();

  const { studyId, checkUserPermission } = useUser();
  const [openAddMedicationModal, setOpenAddMedicationModal] = useState(false);
  const [openAddSymptomModal, setOpenAddSymptomModal] = useState(false);
  const [trendData, setTrendData] = useState<Array<FitbitActivityTrendData>>();
  const [trendPeriod, setTrendPeriod] = useState<ITrendPeriod>('1m');
  const [activeTrend, setActiveTrend] = useState('Steps');
  const [selectCallDeviceModalOpened, setSelectCallDeviceModalOpened] = useState(false);
  const [selectMessageDeviceModalOpened, setSelectMessageDeviceModalOpened] = useState(false);
  const canEdit = checkUserPermission(currentUser.data?.user, Permissions.EDIT_PROFILE);
  const [queryParameters, setQueryParameters] = useSearchParams();
  const tabParameter = queryParameters.get('tab');
  // Set initial tab index based on query param
  const initialTabIndex = tabParameter ? tabMap[tabParameter] : 0;
  const [tabIndex, setTabIndex] = useState(initialTabIndex ?? 0);
  const [notesToShow, setNotesToShow] = useState<number>(2);

  const { userId } = useParams();
  const { handleRefreshToken } = useAuth();
  const {
    data: { user } = {},
    loading,
    refetch: refetchUser,
  } = useUserQuery({
    variables: { id: Number(userId) },
    fetchPolicy: 'network-only',
  });

  const userProfileInfoDetailsQuery = useUserProfileInfoDetailsQuery({
    variables: {
      id: Number(userId),
      studyId: Number(user?.studies![0]?.id),
    },
    // skip: !user || !user.studies || !user.studies[0]?.id,
    fetchPolicy: 'network-only',
  });

  const { data: profileEventsData, refetch: refetchEvents } = useProfileEventsQuery({
    fetchPolicy: 'network-only',
    variables: {
      user: Number(userId),
    },
    skip: !userId,
  });

  const activityQueryOptions = {
    variables: {
      user: Number(userId),
      studyId,
      limit: {
        take: 2,
      },
    },
    skip: !userId,
    fetchPolicy: NO_CACHE,
  };

  const { data: { emails: { items: emails = [] } = {} } = {}, loading: emailsLoading } =
    useEmailsQuery(activityQueryOptions);
  const { data: { voices: { items: voices = [] } = {} } = {}, loading: voicesLoading } =
    useVoicesQuery(activityQueryOptions);
  const { data: { messages: { items: messages = [] } = {} } = {}, loading: messagesLoading } =
    useMessagesQuery(activityQueryOptions);
  const { data: conferenceData, loading: conferenceLoading } =
    useCallConferencesQuery(activityQueryOptions);
  const { data: { audits: { items: audits = [] } = {} } = {}, loading: auditsLoading } =
    useAuditsQuery({
      fetchPolicy: 'network-only',
      variables: {
        where: {
          userId: Number(userId),
        },
        limit: {
          take: 10,
        },
      },
      skip: !userId,
    });

  const [updateEventMutation, updateEventMutationVariables] = useUpdateEventMutation();
  const handleLike = useCallback(
    async (id: string) => {
      await updateEventMutation?.({
        variables: {
          id: Number(id),
          updateEventInput: {
            liked: true,
          },
        },
      });
      await refetchEvents();
    },
    [refetchEvents, updateEventMutation],
  );

  const [currentNote, setCurrentNote] = useState<NoteEntity | null>(null);
  const {
    refetch: refetchNotes,
    loading: loadingNotes,
    data: { notes: { items: notes = [] } = {} } = {},
  } = useNotesQuery({
    fetchPolicy: 'network-only',
    variables: {
      user: Number(userId),
    },
  });
  const {
    refetch: refetchRecommendations,
    loading: loadingRecommendations,
    data: { notes: { items: recommendations = [] } = {} } = {},
  } = useNotesQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        parentId: Number(currentNote?.id),
      },
    },
  });
  const currentRecommendations = useMemo<NoteEntity[]>(
    () => recommendations || [],
    [recommendations],
  );
  const internalNotes = useMemo<NoteEntity[]>(() => {
    const pinnedNotes = notes.filter(
      (note) =>
        (!note.type || note.type === 'coaching') && note.isPinned && note?.parentId === null,
    );
    const unpinnedNotes = notes.filter(
      (note) =>
        (!note.type || note.type === 'coaching') && !note.isPinned && note?.parentId === null,
    );
    return [...pinnedNotes, ...unpinnedNotes];
  }, [notes]);

  const [createNoteMutation, createNoteMutationVariables] = useCreateNoteMutation();
  const [updateNoteMutation, updateNoteMutationVariables] = useUpdateNoteMutation();
  const [deleteNoteMutation, deleteNoteMutationVariables] = useDeleteNoteMutation();

  const getMessage = useCallback(
    (note: any, id: number | undefined | null, isPinToggle: boolean) => {
      if (id) {
        if (isPinToggle) {
          return `Note ${note?.isPinned ? 'pinned' : 'unpinned'} successfully`;
        }
        return 'Successfully updated a note!';
      }
      return 'Successfully created a note!';
    },
    [],
  );

  const handleSaveNote = useCallback(
    async (newNoteData: any, id: number | undefined | null, isPinToggle = false) => {
      try {
        await (id
          ? updateNoteMutation({
              variables: {
                id,
                updateNoteInput: newNoteData,
              },
            })
          : createNoteMutation({
              variables: {
                createNoteInput: newNoteData,
              },
            }));
        await (newNoteData.parentId ? refetchRecommendations() : refetchNotes());
        const message = getMessage(newNoteData, id, isPinToggle);
        toast.success(message);
      } catch {
        toast.error(
          `An error occurred while ${id ? 'updating' : 'creating'} a note. Please try again.`,
        );
      }
    },
    [createNoteMutation, updateNoteMutation, refetchNotes, refetchRecommendations],
  );

  const handleDeleteNote = useCallback(
    async (selectedIdToDelete: number) => {
      try {
        await deleteNoteMutation({
          variables: {
            id: selectedIdToDelete,
          },
        });
        await refetchRecommendations();
        toast.success(t('Successfully deleted a note!'));
      } catch {
        toast.error(t('An error occurred while deleteing the note. Please try again.'));
      }
    },
    [deleteNoteMutation, refetchRecommendations],
  );

  const handleSetCurrentNote = useCallback(
    (note: NoteEntity | null) => setCurrentNote(note),
    [setCurrentNote],
  );

  const onSetupCallModalToggle = useCallback(
    () =>
      navigate('/schedule', {
        state: {
          action: 'create',
        },
      }),
    [navigate],
  );

  const onSelectCallDeviceModalToggle = useCallback(
    () => setSelectCallDeviceModalOpened(!selectCallDeviceModalOpened),
    [selectCallDeviceModalOpened, setSelectCallDeviceModalOpened],
  );

  const onSelectMessageDeviceModalToggle = useCallback(
    () => setSelectMessageDeviceModalOpened(!selectMessageDeviceModalOpened),
    [selectMessageDeviceModalOpened, setSelectMessageDeviceModalOpened],
  );

  useEffect(() => {
    if (!tabParameter) {
      setTabIndex(0);
      return;
    }
    const index = tabMap[tabParameter];
    if (index) setTabIndex(index);
  }, [tabParameter, setQueryParameters]);

  const onTabChange = useCallback(
    (_event: any, index: any) => {
      const tabParameter_ = Object.keys(tabMap).find((key) => tabMap[key] === index);
      if (!tabParameter_) {
        setTabIndex(0);
        return;
      }
      const newSearchParameters = new URLSearchParams(queryParameters);
      newSearchParameters.set('tab', tabParameter_);
      setQueryParameters(newSearchParameters);
      setTabIndex(index);
    },
    [setTabIndex, setQueryParameters],
  );
  const onVideoCall = useCallback(() => {
    if (user) {
      navigate('/video-call', {
        state: {
          user,
          callType: 'video-call',
          startDate: new Date(),
        },
      });
    }
  }, [navigate, user]);

  const { data: fitbitActivities, loading: fitbitActivitiesLoading } = useFitbitLifetimeStatsQuery({
    variables: {
      userId: Number(userId),
    },
  });
  const { data: fitbitActivityTrendSteps } = useFitbitActivitiesTrendQuery({
    variables: {
      parameters: {
        userId: Number(userId),
        resource: 'steps',
        period: String(trendPeriod),
      },
    },
  });
  const { data: fitbitActivityTrendAZM } = useFitbitActivitiesTrendQuery({
    variables: {
      parameters: {
        userId: Number(userId),
        resource: 'active-zone-minutes',
        period: String(trendPeriod),
      },
    },
  });

  // const medicationsVariables = useMedicationsQuery({
  //   variables: { where: { userId: Number(userId) } },
  // });
  const [addMedication, addMedicationVariables] = useAddMedicationMutation();

  // const symptomsVariables = useSymptomsQuery({
  //   variables: { where: { userId: Number(userId) } },
  // });
  const [addSymptom, addSymptomVariables] = useAddSymptomMutation();

  const onTrendPeriodChange = useCallback((value: ITrendPeriod) => {
    setTrendPeriod(value);
  }, []);

  const onTrendChange = useCallback((value: string) => {
    setActiveTrend(value);
  }, []);

  // const onAddMedicationClose = useCallback(() => {
  //   setOpenAddMedicationModal(false);
  // }, []);

  // const onAddMedicationSubmit = useCallback(
  //   async (medication: IMedication) => {
  //     const { errors } = await addMedication({
  //       variables: { userId: Number(user?.id), data: medication },
  //     });

  //     if (errors) {
  //       const [error] = errors;
  //       toast.error(
  //         error?.message || 'An error occurred while adding the medication. Please try again.',
  //       );

  //       return;
  //     }

  //     medicationsVariables.refetch();
  //     toast.success(t('Successfully added new medication.'));
  //     setOpenAddMedicationModal(false);
  //   },
  //   [addMedication, medicationsVariables, user?.id],
  // );

  // const onOpenAddMedicationModal = useCallback(() => {
  //   setOpenAddMedicationModal(true);
  // }, []);

  // const onAddSymptomClose = useCallback(() => {
  //   setOpenAddSymptomModal(false);
  // }, []);

  // const onAddSymptomSubmit = useCallback(
  //   async ({ frequency, ...symptom }: ISymptom) => {
  //     const { errors } = await addSymptom({
  //       variables: { userId: Number(user?.id), data: { ...symptom, frequency: Number(frequency) } },
  //     });

  //     if (errors) {
  //       const [error] = errors;
  //       toast.error(
  //         error?.message || 'An error occurred while adding the symptom. Please try again.',
  //       );

  //       return;
  //     }

  //     symptomsVariables.refetch();
  //     toast.success(t('Successfully added new symptom.'));
  //     setOpenAddSymptomModal(false);
  //   },
  //   [addSymptom, symptomsVariables, user?.id],
  // );

  // const onOpenAddSymptomModal = useCallback(() => {
  //   setOpenAddSymptomModal(true);
  // }, []);

  const refreshFitbitToken = useCallback(async () => {
    if (user) {
      await handleRefreshToken(user);
      await refetchUser();
    }
  }, [handleRefreshToken, refetchUser, user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user.fitbitAccessToken && user.fitbitRefreshToken) {
      refreshFitbitToken();
    }

    switch (activeTrend) {
      case 'Steps': {
        setTrendData(fitbitActivityTrendSteps?.fitbitActivitiesTrend);
        break;
      }
      case 'Active Zone Minutes': {
        const newActivities = fitbitActivityTrendAZM?.fitbitActivitiesTrend || [];
        for (const item of fitbitActivityTrendSteps?.fitbitActivitiesTrend || []) {
          const exists = newActivities.some((object) => object.dateTime === item.dateTime);
          if (!exists) {
            newActivities.push({ dateTime: item.dateTime, value: 0 });
          }
        }
        newActivities.sort((a, b) => a.dateTime.localeCompare(b.dateTime));
        setTrendData(newActivities);
        break;
      }
      default: {
        setTrendData(fitbitActivityTrendSteps?.fitbitActivitiesTrend);
      }
    }
  }, [
    activeTrend,
    fitbitActivityTrendAZM?.fitbitActivitiesTrend,
    fitbitActivityTrendSteps,
    loading,
    navigate,
    refreshFitbitToken,
    user,
  ]);

  const refetchUserDetails = useCallback(async () => {
    await Promise.all([refetchUser(), userProfileInfoDetailsQuery.refetch()]);
  }, [refetchUser, userProfileInfoDetailsQuery]);

  const isAdmin = useMemo(() => {
    return studyId ? checkRole(user as UserEntity, studyId, UserRoles.ADMINISTRATOR) : false;
  }, [user, studyId]);

  if (loading) {
    return <PageLoader />;
  }

  if (!user) {
    return <NotFoundPage />;
  }

  return (
    <Box
      data-testid="Participant Profile"
      component="main"
      sx={{
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        m: 0,
        pb: xs ? 18 : 0,
      }}
    >
      <Stack>
        <ParticipantProfileAppBarSkinny
          currentTabIndex={tabIndex}
          onTabChange={onTabChange}
          onSelectCallDevice={onSelectCallDeviceModalToggle}
          onSelectMessageDevice={onSelectMessageDeviceModalToggle}
          onVideoCall={onVideoCall}
          user={user}
          loading={loading}
          refetch={refetchUserDetails}
        />
        {tabIndex === 0 && (
          <Box sx={{ px: 3 }}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={8}>
                <Stack spacing={2}>
                  {userProfileInfoDetailsQuery.data?.userProfileInfoDetails &&
                    !userProfileInfoDetailsQuery.loading && (
                      <ParticipantProfileInformation
                        userId={userId}
                        canEdit={canEdit}
                        isAdmin={!!isAdmin}
                        email={userProfileInfoDetailsQuery.data.userProfileInfoDetails.email}
                        studyArm={userProfileInfoDetailsQuery.data.userProfileInfoDetails.studyArm}
                        isVerified={
                          userProfileInfoDetailsQuery.data.userProfileInfoDetails.status ===
                          UserStatus.VERIFIED
                        }
                        refetch={refetchUserDetails}
                        userProfileInfoDetails={
                          userProfileInfoDetailsQuery.data
                            .userProfileInfoDetails as UserProfileDetailsInputType
                        }
                        loading={userProfileInfoDetailsQuery.loading}
                      />
                    )}
                  <ParticipantProfileInternalNotes
                    notes={internalNotes.slice(0, notesToShow)}
                    totalNotes={internalNotes.length}
                    profileId={Number(userId)}
                    loading={loadingNotes || createNoteMutationVariables.loading}
                    saveNote={handleSaveNote}
                    showMore={() => setNotesToShow(notesToShow + 2)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={2}>
                  <ParticipantAdherenceMetrics userId={userId} />
                  <ParticipantProfileSchedule
                    schedules={profileEventsData}
                    loadingProcess={updateEventMutationVariables.loading}
                    onLike={handleLike}
                    onSetupCall={onSetupCallModalToggle}
                    userId={userId}
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ParticipantProfileMedication
                  medications={medicationsVariables.data?.medications.items}
                  onAdd={onOpenAddMedicationModal}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ParticipantProfileSymptoms
                  symptoms={symptomsVariables.data?.symptoms.items}
                  onAdd={onOpenAddSymptomModal}
                />
              </Grid>
            </Grid> */}

            <Grid container spacing={3} sx={{ mb: 3 }}>
              {fitbitActivitiesLoading ? (
                <ReportCardsPlaceholder count={4} />
              ) : (
                fitbitActivities?.fitbitLifetimeStats.map(({ label, metric }) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={label}>
                    <Card
                      label={label}
                      metric={i18next.t('{{metric, number}} {{units}}', {
                        metric,
                        units: label === 'Distance' ? 'mi' : '',
                      })}
                    />
                  </Grid>
                ))
              )}
            </Grid>

            {trendData && (
              <LineChart
                title={activeTrend}
                period={trendPeriod}
                sx={{ pb: 2, mb: 3 }}
                onPeriodChange={onTrendPeriodChange}
                onChange={onTrendChange}
              >
                <LineRecharts chartData={trendData} xKey="dateTime" yKey="value" />
              </LineChart>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Activities
                  profileId={Number(userId)}
                  emails={emails}
                  messages={messages}
                  voices={voices}
                  conferences={conferenceData}
                  loading={
                    (emailsLoading || messagesLoading || voicesLoading || conferenceLoading) ??
                    false
                  }
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {tabIndex === 1 && (
          <Box sx={{ px: 3 }}>
            <ParticipantProfileInterventionsHistory
              notes={internalNotes}
              recommendations={currentRecommendations}
              currentNote={currentNote}
              profileId={Number(userId)}
              loadingNotes={loadingNotes}
              loadingReplies={loadingRecommendations}
              loadingProcess={
                createNoteMutationVariables.loading ||
                updateNoteMutationVariables.loading ||
                deleteNoteMutationVariables.loading
              }
              saveNote={handleSaveNote}
              onDeleteNote={handleDeleteNote}
              onSetCurrentNote={handleSetCurrentNote}
            />
          </Box>
        )} */}

        {tabIndex === 2 && <Coaching userId={Number(userId)} />}

        {tabIndex === 3 && (
          <Box sx={{ px: 3 }}>
            <Assessments userId={Number(userId)} />
          </Box>
        )}
        {tabIndex === 4 && (
          <Box sx={{ px: 3 }}>
            <Audits audits={audits} loading={auditsLoading} />
          </Box>
        )}
        {tabIndex === 5 && (
          <Box sx={{ px: 3 }}>
            <UserCharts profileId={Number(userId)} />
          </Box>
        )}
        {tabIndex === 6 && (
          <Box sx={{ px: 3 }}>
            <Integrations user={user} />
          </Box>
        )}
      </Stack>

      {user && (
        <SelectCallDeviceModal
          user={user}
          open={selectCallDeviceModalOpened}
          onClose={onSelectCallDeviceModalToggle}
        />
      )}
      {user && (
        <SendMessageDeviceModal
          user={user}
          open={selectMessageDeviceModalOpened}
          onClose={onSelectMessageDeviceModalToggle}
        />
      )}
      {/* {openAddMedicationModal && (
        <AddMedication
          open={openAddMedicationModal}
          loading={addMedicationVariables.loading}
          onClose={onAddMedicationClose}
          onSubmit={onAddMedicationSubmit}
        />
      )}
      {openAddSymptomModal && (
        <AddSymptom
          open={openAddSymptomModal}
          loading={addSymptomVariables.loading}
          onClose={onAddSymptomClose}
          onSubmit={onAddSymptomSubmit}
        />
      )} */}
    </Box>
  );
}
