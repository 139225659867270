import { SVGProps } from 'react';

const WorkoutSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M32.538 13.704a2.716 2.716 0 1 1-3.9 3.782 2.716 2.716 0 0 1 3.9-3.782ZM32.236 26.02H29.8c.56-.363.728-1.091.392-1.68l-1.512-2.435-.224-2.016c-.028-.392-.224-.98-.392-1.316-1.008-1.932-1.092-1.988-1.316-2.184-.504-.448-1.12-.616-1.904-.336-.784.28-5.124 1.82-5.124 1.82a3.626 3.626 0 0 0-1.568 1.092l-1.904 2.212a.853.853 0 0 1-.364.252L9.752 23.5c-.728.252-1.148 1.064-.896 1.792.112.336.364.616.644.784H7.26a.562.562 0 0 0-.56.56c0 .308.252.56.56.56h24.976c.308 0 .56-.252.56-.56 0-.364-.252-.616-.56-.616Zm-3.752 0H11.012l15.12-5.095.14 1.204c.028.364.168.728.364 1.036L28.12 25.6a.908.908 0 0 0 .364.42Z"
    />
  </svg>
);
export default WorkoutSvg;
