import {
  handleCardioQuestions,
  handleExerciseQuestion,
  handleIvrQuestions,
  handleResistanceBandsQuestion,
} from '.';
import { IPlot } from '../../components/users/UserCharts/Plot';

export const findPlots = (item: any, plots: IPlot[], plots1: IPlot[]): IPlot | undefined => {
  if (item?.question?.type === 'ivr') {
    const ivrOptionIndex = Number(item.values);
    const options = JSON.parse(item.question?.options ?? '');
    const option = options?.[ivrOptionIndex];

    return (
      plots.find(
        (plotItem) =>
          plotItem?.meta?.dataSourceValue === item.question?.label &&
          plotItem.sourceId === Number(item.question?.id) &&
          option === plotItem.sourceValue,
      ) ||
      plots1.find(
        (plotItem) =>
          plotItem?.meta?.dataSourceValue === item.question?.label &&
          plotItem.sourceId === Number(item.question?.id) &&
          plotItem.sourceValue === option,
      )
    );
  }

  return (
    plots.find((plotItem) => {
      return (
        ((plotItem.meta &&
          `${plotItem.meta.dataSourceValue} - ${plotItem.sourceValue}` === item.question?.label) ||
          plotItem.sourceValue === item.question?.label) &&
        plotItem.sourceId === Number(item.question?.id)
      );
    }) ||
    plots1.find(
      (plotItem) =>
        ((plotItem.meta &&
          `${plotItem.meta.dataSourceValue} - ${plotItem.sourceValue}` === item.question?.label) ||
          plotItem.sourceValue === item.question?.label) &&
        plotItem.sourceId === Number(item.question?.id),
    )
  );
};

export const getPlotkey = (plot: IPlot, dataSource: any, item: any): string | undefined => {
  let plotKey: string | undefined = plot.sourceValue;

  if (plot.meta?.dataSourceType === 'ivr') {
    const ivrOptionIndex = Number(item.values);
    const option = dataSource?.questions?.[ivrOptionIndex];

    plotKey =
      option?.label === plot.sourceValue
        ? `${plot.meta.dataSourceValue} - ${plot.sourceValue}`
        : undefined;
  } else if (
    plot?.meta?.dataSourceType === 'exercise-question' ||
    plot?.meta?.dataSourceType === 'resistance-bands' ||
    plot?.meta?.dataSourceType === 'resistance-free-weight' ||
    plot?.meta?.dataSourceType === 'resistance-body-weight' ||
    plot?.meta?.dataSourceType === 'cardio'
  ) {
    plotKey = `${plot.meta.dataSourceValue} - ${plot.sourceValue}`;
  }

  return plotKey;
};

export const getPlotsDataItems = (items: any[], dataSource: any, dataSource1: any) => {
  const dataItems: any[] = [];
  const resistanceQuestions = new Set([
    'resistance-bands',
    'resistance-free-weight',
    'resistance-body-weight',
  ]);

  for (const item of items) {
    if (item.question?.type === 'human-body') {
      const valuesArray = JSON.parse(item.values?.join(',') ?? '');
      for (const value of valuesArray) {
        const dataItem = {
          ...item,
          question: {
            ...item.question,
            label: value.name,
          },
          values: value,
        };
        dataItems.push(dataItem);
      }
    } else if (dataSource?.type === 'exercise-question' && item.question?.id === dataSource?.id) {
      const exerciseDataSourceQuestions = handleExerciseQuestion(item);
      dataItems.push(...exerciseDataSourceQuestions);
    } else if (dataSource1?.type === 'exercise-question' && item.question?.id === dataSource1?.id) {
      const exerciseDataSource1Questions = handleExerciseQuestion(item);
      dataItems.push(...exerciseDataSource1Questions);
    } else if (
      resistanceQuestions.has(dataSource?.type ?? '') &&
      item.question?.id === dataSource?.id
    ) {
      const resistanceBandsDataSourceQuestions = handleResistanceBandsQuestion(
        item,
        dataSource?.type ?? '',
      );
      dataItems.push(...resistanceBandsDataSourceQuestions);
    } else if (
      resistanceQuestions.has(dataSource1?.type ?? '') &&
      item.question?.id === dataSource1?.id
    ) {
      const resistanceBandsDataSource1Questions = handleResistanceBandsQuestion(
        item,
        dataSource?.type ?? '',
      );
      dataItems.push(...resistanceBandsDataSource1Questions);
    } else if (dataSource?.type === 'cardio' && item.question?.id === dataSource?.id) {
      const cardioDataSourceQuestions = handleCardioQuestions(item);
      dataItems.push(...cardioDataSourceQuestions);
    } else if (dataSource1?.type === 'cardio' && item.question?.id === dataSource1?.id) {
      const cardioDataSource1Questions = handleCardioQuestions(item);
      dataItems.push(...cardioDataSource1Questions);
    } else if (dataSource?.type === 'ivr' && item.question?.id === dataSource?.id) {
      const ivrDataSourceQuestions = handleIvrQuestions(item);
      dataItems.push(ivrDataSourceQuestions);
    } else if (dataSource1?.type === 'ivr' && item.question?.id === dataSource1?.id) {
      const ivrDataSourceQuestions1 = handleIvrQuestions(item);
      dataItems.push(ivrDataSourceQuestions1);
    } else {
      dataItems.push(item);
    }
  }

  return dataItems;
};
