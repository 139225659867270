import { SVGProps } from 'react';

const TennisSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g fill="#6C5DD3" clipPath="url(#a)">
      <path d="M10.879 10.88c2.681-2.68 7.263-2.46 10.21.488 1.439 1.438 2.276 3.3 2.359 5.241l.261 6.128 2.415 2.415c.763-.343 1.718-.226 2.328.383l1.944 1.945a2.064 2.064 0 0 1 0 2.916c-.402.401-.93.602-1.46.602-.529 0-1.055-.2-1.458-.602l-1.944-1.944a2.046 2.046 0 0 1-.388-2.332l-2.409-2.41-6.128-.26h-.002c-1.938-.082-3.8-.919-5.241-2.359-2.948-2.952-3.167-7.53-.487-10.21Zm11.195 5.787c-.006-.135-.041-.264-.056-.398l-.93.93.855.856c.094-.448.152-.907.131-1.388Zm-.434-1.962a6.66 6.66 0 0 0-1.081-1.837l-1.902 1.902 1.459 1.458 1.524-1.523Zm-3.955-.908 1.898-1.897a6.723 6.723 0 0 0-1.83-1.088l-1.527 1.526 1.46 1.46Zm-2.43-.487-1.945 1.945 1.459 1.459 1.944-1.945-1.459-1.459Zm2.43 2.431-1.944 1.945 1.459 1.459 1.944-1.945-1.459-1.458Zm-2.916 2.917-1.902 1.901a6.703 6.703 0 0 0 1.837 1.081l1.524-1.523-1.46-1.46Zm-.972-.972-1.46-1.46-1.52 1.521c.249.65.61 1.267 1.078 1.839l1.902-1.9Zm-2.431-2.431-.85-.85a5.677 5.677 0 0 0-.083 1.782l.933-.932Zm-.332-2.275 1.304 1.304 1.944-1.944-1.303-1.302c-.405.22-.791.478-1.128.816a4.843 4.843 0 0 0-.817 1.126Zm4.505-2.604c-.39 0-.771.05-1.147.128l.862.862.926-.925c-.215-.022-.43-.065-.641-.065Zm6.776 11.938-.068-1.573c-.206.29-.418.577-.673.833a6.288 6.288 0 0 1-.823.674l1.564.066Zm-.895-2.84-1.302-1.302-1.945 1.945 1.301 1.302a4.878 4.878 0 0 0 1.128-.817c.334-.333.594-.72.818-1.128Zm-4.752 2.6c.476.024.938-.034 1.387-.129l-.855-.855-.93.93c.134.013.263.049.398.054ZM25.5 11.75a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 0 0-5.5 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M31 9H9v22h22z" />
      </clipPath>
    </defs>
  </svg>
);
export default TennisSvg;
