import { EventEmitter } from 'events';
import { useEffect } from 'react';

const bus: EventEmitter = new EventEmitter();

export const useBusEmitter = () => {
  return (eventName: string, ...values: any[]) => {
    bus.emit(eventName, ...values);
  };
};

export const useBusListener = (eventName: string, listener: (...values: any[]) => void) => {
  useEffect(() => {
    const handleSignal = (...values: any[]) => {
      listener(...values);
    };

    bus.on(eventName, handleSignal);

    return () => {
      bus.off(eventName, handleSignal);
    };
  }, [eventName]);
};
