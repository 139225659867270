import { Box, FormGroup, Typography, Button } from '@mui/material';

import { theme } from '../../../theme';

interface BlockSelectorProperties {
  index?: number;
  label?: string;
  value?: string;
  options: string[];
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange: (value: string) => void;
}

export const BlockSelector = ({
  index,
  label,
  required,
  value,
  showBoldLabel,
  onChange,
  options,
  hideLabel = false,
}: BlockSelectorProperties) => {
  const handleRangeChanged = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        {!hideLabel && (
          <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
            {index}
            {index && '.'} {label} {required && '*'}
          </Typography>
        )}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {options.map((item) => (
            <Button
              data-testid="block-selector-button"
              key={`item-${item}`}
              size="large"
              color="secondary"
              variant="outlined"
              sx={{
                flexGrow: options.length < 3 ? 1 : 'auto',
                m: 1,
                borderWidth: 2,
                borderColor: value === item ? theme.palette.primary.main : theme.palette.grey[200],
                flexBasis: '16%',
              }}
              onClick={() => handleRangeChanged(item)}
            >
              {item}
            </Button>
          ))}
        </Box>
      </FormGroup>
    </Box>
  );
};
