import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';

export function RoleRowPlaceholder(): ReactElement {
  const rows = [1, 2, 3, 4, 5];

  return (
    <>
      {rows.map((key) => {
        const nameWidth = 350;
        const permissionsWidth = 250;
        const usersAssignedWidth = 150;

        return (
          <TableRow key={key}>
            <TableCell />
            <TableCell>
              <ContentLoader
                speed={2}
                width={20}
                height={20}
                viewBox="0 0 20 20"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={nameWidth}
                height={15}
                viewBox={`0 0 ${nameWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={nameWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={permissionsWidth}
                height={15}
                viewBox={`0 0 ${permissionsWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={permissionsWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell>
              <ContentLoader
                speed={2}
                width={usersAssignedWidth}
                height={15}
                viewBox={`0 0 ${usersAssignedWidth} 15`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width={usersAssignedWidth} height="15" />
              </ContentLoader>
            </TableCell>
            <TableCell colSpan={2}>
              <ContentLoader
                speed={2}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
              </ContentLoader>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
