/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import {
  RadialBarChart as RechartRadialBarChart,
  RadialBar,
  Tooltip,
  Label,
  ResponsiveContainer,
  Cell,
  Legend,
} from 'recharts';
import { GraphDataPointsQuery } from '../../graphql';
import { IPlot } from '../users/UserCharts/Plot';
import { getDataValue } from '../../utils/charts';

interface Properties {
  data: GraphDataPointsQuery | null | undefined;
  plots: IPlot[];
}

const CustomTooltip = ({ active, payload }: { active: any; payload: any }) => {
  if (active && payload && payload?.length > 0) {
    const data = payload[0]?.payload; // Assuming there's only one bar per category

    return (
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          padding: '8px',
          borderRadius: '4px',
          pointerEvents: 'none',
          zIndex: 9999,
        }}
      >
        <p style={{ margin: 0 }}>
          {data.name}: {data.value}
        </p>
      </div>
    );
  }

  return null;
};

const RadialBarChart: React.FC<Properties> = ({ data, plots }) => {
  const { plotsKeys, transformedData } = useMemo(() => {
    const dataByLabelMap = new Map();
    const keysMap = new Map();
    const dataItems: any[] = [];

    const nonHumanBodyItems =
      data?.graphDataPoints.items?.filter((item) => item.question?.type !== 'human-body') ?? [];

    dataItems.push(...nonHumanBodyItems);

    const humanBodyItems =
      data?.graphDataPoints.items?.filter((item) => item.question?.type === 'human-body') ?? [];
    if (humanBodyItems.length > 0) {
      for (const humanBodyItem of humanBodyItems) {
        const valuesArray = JSON.parse(humanBodyItem.values?.join(',') ?? '');
        for (const value of valuesArray) {
          const item = {
            ...humanBodyItem,
            question: {
              ...humanBodyItem.question,
              label: value.name,
            },
            values: value,
          };
          dataItems.push(item);
        }
      }
    }

    const ivrItems = data?.graphDataPoints.items?.filter((item) => item.question?.options) ?? [];

    if (ivrItems.length > 0) {
      const uniqueIvrItems = ivrItems.filter(
        (ivrItem, index, self) =>
          index ===
          self.findIndex(
            (item) =>
              item.question?.id === ivrItem.question?.id && item.answerDate === ivrItem.answerDate,
          ),
      );
      for (const ivrItem of uniqueIvrItems) {
        const options = JSON.parse(ivrItem.question?.options ?? '');
        const ivrOptionIndex = Number(ivrItem.values?.[0]);

        const option = options?.[ivrOptionIndex];

        const item = {
          ...ivrItem,
          question: {
            ...ivrItem.question,
            label: option,
          },
          values: '1',
        };
        dataItems.push(item);
      }
    }

    for (const item of dataItems) {
      const plot = plots.find(
        (plotItem) =>
          plotItem.sourceId === Number(item.question?.id) &&
          plotItem.sourceValue === item.question?.label,
      );

      if (plot) {
        const { color } = plot;

        if (!keysMap.has(item.question?.label)) {
          keysMap.set(item.question?.label, { color });
        }
      }
    }

    for (const item of dataItems) {
      const { question } = item;

      const dataValue = getDataValue(item, question?.label);

      if (dataByLabelMap.has(question?.label)) {
        dataByLabelMap.get(question?.label).value += dataValue;
      } else if (plots.some((plot) => plot.sourceValue === question?.label)) {
        const newData = { name: question?.label, value: dataValue };
        dataByLabelMap.set(question?.label, newData);
      }
    }

    return {
      plotsKeys: keysMap,
      transformedData: [...dataByLabelMap.values()],
    };
  }, [data?.graphDataPoints.items, plots]);

  const cellsToRender = useMemo(() => {
    const renderedPlots = [];

    for (const [key, { color }] of plotsKeys) {
      renderedPlots.push(<Cell key={`radial-${key}`} fill={color} />);
    }
    return renderedPlots;
  }, [plotsKeys]);

  return (
    <ResponsiveContainer width="90%" height={300}>
      <RechartRadialBarChart innerRadius="10%" outerRadius="90%" data={transformedData}>
        <Tooltip
          content={({ active, payload }) => <CustomTooltip active={active} payload={payload} />}
        />
        <Label />
        <Legend
          wrapperStyle={{
            bottom: '-30px',
          }}
          layout="horizontal"
          verticalAlign="bottom"
          align="left"
          iconType="circle"
          height={35}
          formatter={(value, entry: any) => {
            return (
              <span style={{ color: entry.color }}>
                {value} ({entry?.payload?.value})
              </span>
            );
          }}
        />
        <RadialBar
          label={{ fill: '#fff', position: 'insideStart' }}
          background
          dataKey="value"
          width={100}
        >
          {cellsToRender}
        </RadialBar>
      </RechartRadialBarChart>
    </ResponsiveContainer>
  );
};

export default RadialBarChart;
