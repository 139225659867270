import { HTMLAttributes, ReactElement, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { Avatar, Badge, IconButton, Link } from '@mui/material';
import { routes } from '../routes';
import { theme } from '../theme';

import { SidebarMain, SidebarLeft, SidebarRight, SideNavigation } from '../components/sidebars';
import { useNotificationsAnalytics } from '../contexts';
import { PAGES } from '../constants';
import { useCurrentUser } from '../states';

interface Properties extends HTMLAttributes<HTMLElement> {
  readonly [key: string]: any;
}

export function Layouts({ children }: Properties): ReactElement {
  const location = useLocation();

  const current = routes.find((route) => matchPath(route.path, location.pathname));
  const { data: currentUser } = useCurrentUser();
  const sm = useMediaQuery('(min-width:750px)');
  const notificationsAnalytics = useNotificationsAnalytics();
  const [sidebarLeftOpened, setSidebarLeftOpened] = useState(false);
  const onSidebarLeftToggle = useCallback(() => {
    setSidebarLeftOpened(!sidebarLeftOpened);
  }, [sidebarLeftOpened]);

  const [sidebarRightOpened, setSidebarRightOpened] = useState(false);
  const onSidebarRightToggle = useCallback(() => {
    setSidebarRightOpened(!sidebarRightOpened);
  }, [sidebarRightOpened]);

  const leftSidebar = current?.layout === 'normal';
  const rightSidebar = false;

  return (
    <>
      <Box display="flex" data-testid="PageLayout">
        {currentUser && leftSidebar && (
          <SideNavigation
            anchor="left"
            variant={sm ? 'permanent' : 'temporary'}
            isOpened={sidebarLeftOpened}
            onClose={onSidebarLeftToggle}
          >
            <SidebarLeft onClose={onSidebarLeftToggle} />
          </SideNavigation>
        )}
        <Box sx={{ width: '100%', maxHeight: sm ? 'unset' : 'calc(100vh - 135px)' }}>
          {currentUser && !sm && (
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              sx={{ pt: 2, pl: 1, pr: 1, width: '100%' }}
            >
              <IconButton
                aria-label="menu"
                size="large"
                color="inherit"
                onClick={onSidebarLeftToggle}
              >
                <MenuIcon
                  sx={{
                    color: theme.palette.common.black,
                  }}
                />
              </IconButton>

              <Link
                component={RouterLink}
                to={PAGES.NOTIFICATIONS}
                sx={{ alignItems: 'flex-end', justifySelf: 'flex-end' }}
              >
                <Badge
                  badgeContent={notificationsAnalytics.notViewedCount}
                  color="error"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Avatar>
                    <NotificationsIcon />
                  </Avatar>
                </Badge>
              </Link>
            </Box>
          )}
          {children}
        </Box>
        {currentUser && rightSidebar && (
          <SideNavigation
            anchor="right"
            variant={sm ? 'permanent' : 'temporary'}
            isOpened={sidebarRightOpened}
            onClose={onSidebarRightToggle}
          >
            <SidebarRight onClose={onSidebarRightToggle} />
          </SideNavigation>
        )}
      </Box>
      {currentUser && !sm && (
        <SidebarMain hasRightSidebar={rightSidebar} onOpenRightMenu={onSidebarRightToggle} />
      )}
    </>
  );
}
