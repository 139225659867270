import { createContext, ReactNode, useContext, useMemo } from 'react';

import { ENDPOINTS } from '../../constants';
import { IResponseBase } from '../../types/axios';
import { IResearchActivityData } from '../../types/research';
import { useAxios } from '../../hooks';

interface IResponse extends IResponseBase {
  data: IResearchActivityData;
}

const defaultResponse: IResponse = {
  data: null,
  loading: true,
};

export const ResearchActivityContext = createContext<IResponse>(defaultResponse);

export const ResearchActivityProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useAxios({ url: ENDPOINTS.RESEARCH_ACTIVITIES });

  const contextValue = useMemo((): IResponse => {
    return { data, loading };
  }, [data, loading]);

  return (
    <ResearchActivityContext.Provider value={contextValue}>
      {children}
    </ResearchActivityContext.Provider>
  );
};

export const useResearchActivity = () => useContext(ResearchActivityContext);
