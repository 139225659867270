import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSearchParams } from 'react-router-dom';

import React, { useCallback, useState } from 'react';
import { NotesBoard } from '../../molecules';
import InfoHeader from './InfoHeader';
import CoachingQuestions from './CoachingQuestions';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface CoachingProperties {
  userId: number;
}

const Coaching: React.FC<CoachingProperties> = ({ userId }) => {
  const { t } = usePreferredTranslation();
  const [searchParameters] = useSearchParams();
  const coachingDate = searchParameters.get('coachingEventDate') || new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(coachingDate));

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        px: 3,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <InfoHeader userId={userId} date={selectedDate} handleChangeDate={handleChangeDate} />
      <Box sx={{ flex: 1, mb: 2, maxHeight: '50%' }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
              {t('Participant Goals')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NotesBoard userId={userId} />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ flex: 1, maxWidth: '100%' }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
              {t('Coaching Questions')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CoachingQuestions userId={userId} selectedDate={selectedDate} hasSearch />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default Coaching;
