/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import {
  PieChart as RechartPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { GraphDataPointsQuery } from '../../graphql';
import { IPlot } from '../users/UserCharts/Plot';
import { getDataValue } from '../../utils/charts';

interface Properties {
  data: GraphDataPointsQuery | null | undefined;
  plots: IPlot[];
}

interface LabelComponentProperties {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
  index: number;
  transformedData: any[];
}

const LabelComponent = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
  transformedData,
}: LabelComponentProperties) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#8884d8"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontSize: 11 }}
    >
      {transformedData[index].label} ({value})
    </text>
  );
};

const PieChart: React.FC<Properties> = ({ data, plots }) => {
  const transformedData = useMemo(() => {
    const dataByQuestionMap: any[] = [];
    const dataItems: any[] = [];

    const nonHumanBodyItems =
      data?.graphDataPoints.items?.filter((item) => item.question?.type !== 'human-body') ?? [];

    dataItems.push(...nonHumanBodyItems);

    const humanBodyItems =
      data?.graphDataPoints.items?.filter((item) => item.question?.type === 'human-body') ?? [];
    if (humanBodyItems.length > 0) {
      for (const humanBodyItem of humanBodyItems) {
        const valuesArray = JSON.parse(humanBodyItem.values?.join(',') ?? '');
        for (const value of valuesArray) {
          const item = {
            ...humanBodyItem,
            question: {
              ...humanBodyItem.question,
              label: value.name,
            },
            values: value,
          };
          dataItems.push(item);
        }
      }
    }

    const ivrItems = data?.graphDataPoints.items?.filter((item) => item.question?.options) ?? [];

    if (ivrItems.length > 0) {
      const uniqueIvrItems = ivrItems.filter(
        (ivrItem, index, self) =>
          index ===
          self.findIndex(
            (item) =>
              item.question?.id === ivrItem.question?.id && item.answerDate === ivrItem.answerDate,
          ),
      );

      for (const ivrItem of uniqueIvrItems) {
        const options = JSON.parse(ivrItem.question?.options ?? '');
        const ivrOptionIndex = Number(ivrItem.values?.[0]);

        const option = options?.[ivrOptionIndex];

        const item = {
          ...ivrItem,
          question: {
            ...ivrItem.question,
            label: option,
          },
          values: '1',
        };
        dataItems.push(item);
      }
    }

    for (const item of dataItems ?? []) {
      const { question } = item;
      const plot = plots.find(
        (plotItem) =>
          plotItem.sourceId === Number(question?.id) && plotItem.sourceValue === question.label,
      );

      const dataValue = getDataValue(item, question?.label);
      const existingData = dataByQuestionMap.find((data) => data.label === question?.label);

      if (existingData) {
        existingData.value += dataValue;
      } else if (plots.some((plot) => plot.sourceValue === question?.label)) {
        const newData = {
          value: dataValue,
          color: plot?.color,
          label: question?.label,
          name: question?.label,
        };
        dataByQuestionMap.push(newData);
      }
    }

    return dataByQuestionMap;
  }, [data?.graphDataPoints.items, plots]);

  return (
    <ResponsiveContainer width="90%" height={300}>
      <RechartPieChart>
        <Tooltip />
        <Legend
          wrapperStyle={{
            bottom: '-30px',
          }}
          layout="horizontal"
          verticalAlign="bottom"
          align="left"
          iconType="circle"
          height={35}
          formatter={(_value, entry: any) => {
            return (
              <span style={{ color: entry.color }}>
                {entry.payload?.label} ({entry?.payload?.value})
              </span>
            );
          }}
        />
        <Pie
          key="pie"
          data={transformedData}
          dataKey="value"
          outerRadius={100}
          cx="50%"
          cy="50%"
          label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => (
            <LabelComponent
              cx={cx}
              cy={cy}
              midAngle={midAngle}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              value={value}
              index={index}
              transformedData={transformedData ?? []}
            />
          )}
        >
          {transformedData?.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Pie>
      </RechartPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
