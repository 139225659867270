import { SVGProps } from 'react';

const ScheduleCalssEmptyState = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={151}
    height={106}
    fill="none"
    viewBox="0 0 151 106"
    {...properties}
  >
    <g clipPath="url(#a)">
      <path fill="#E6E6E6" d="M151 11.517H0v94.029h151v-94.03Z" />
      <path
        fill="#fff"
        d="M37.781 29.63H10.126v17.977H37.78V29.631ZM72.146 29.63H44.49v17.977h27.656V29.631ZM106.51 29.63H78.854v17.977h27.656V29.631ZM140.874 29.63h-27.655v17.977h27.655V29.631ZM37.781 55.074H10.126V73.05H37.78V55.074ZM72.146 55.074H44.49V73.05h27.656V55.074ZM106.51 55.074H78.854V73.05h27.656V55.074ZM140.874 55.074h-27.655V73.05h27.655V55.074ZM37.781 80.518H10.126v17.976H37.78V80.518ZM72.146 80.518H44.49v17.976h27.656V80.518ZM106.51 80.518H78.854v17.976h27.656V80.518ZM140.874 80.518h-27.655v17.976h27.655V80.518ZM39.547 22.578a4.148 4.148 0 1 0 0-8.296 4.148 4.148 0 0 0 0 8.296ZM108.134 22.578a4.148 4.148 0 1 0 0-8.296 4.148 4.148 0 0 0 0 8.296Z"
      />
      <path
        fill="#E6E6E6"
        d="M41.207.454h-3.319v19.082h3.319V.454ZM109.793.454h-3.319v19.082h3.319V.454Z"
      />
      <path
        fill="#6C63FF"
        d="M133.3 38.619a6.236 6.236 0 1 1-12.472-.01 6.236 6.236 0 0 1 12.472.01Z"
      />
      <path
        fill="#fff"
        d="m132.334 35.278-6.156 6.155c-.387-.599-2.543-3.383-2.543-3.383.276-.28.571-.54.883-.779l1.787 2.384 5.308-5.308c.269.287.51.598.721.93Z"
      />
      <path
        fill="#6C63FF"
        d="M64.572 64.062a6.237 6.237 0 1 1-12.473-.012 6.237 6.237 0 0 1 12.473.012Z"
      />
      <path
        fill="#fff"
        d="m63.605 60.721-6.156 6.155c-.387-.599-2.542-3.383-2.542-3.383.276-.279.57-.539.882-.778l1.788 2.383 5.307-5.308c.27.287.51.599.721.931Z"
      />
      <path
        fill="#6C63FF"
        d="M30.207 64.062a6.236 6.236 0 1 1-12.471-.011 6.236 6.236 0 0 1 12.471.011Z"
      />
      <path
        fill="#fff"
        d="m29.241 60.721-6.156 6.155c-.387-.599-2.543-3.383-2.543-3.383.277-.279.571-.539.883-.778l1.787 2.383 5.308-5.308c.27.287.51.599.721.931Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .454h151v105.092H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ScheduleCalssEmptyState;
