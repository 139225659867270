import { Grid, Paper } from '@mui/material';

import { ContentLoader } from '../ContentLoader';
import { IPlaceholderCards } from './types';

export const VoiceCardPlaceholder = () => (
  <Grid item sx={{ overflow: 'hidden' }}>
    <Paper
      sx={{
        height: 114,
        minWidth: 100,
        overflow: 'hidden',
        boxSizing: 'content-box',
        boxShadow: 'none',
      }}
    >
      <ContentLoader
        speed={2}
        width={2000}
        height={114}
        viewBox="0 0 2000 114"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="42" cy="48" r="24" />
        <rect x="90" y="30" rx="0" ry="0" width="80" height="16" />
        <rect x="90" y="54" rx="0" ry="0" width="100" height="10" />
        <rect x="90" y="78" rx="0" ry="0" width="200" height="10" />
        <rect x="0" y="112" rx="0" ry="0" width="2000" height="2" />
      </ContentLoader>
    </Paper>
  </Grid>
);

export const VoiceCardsPlaceholder = ({ count = 5 }: IPlaceholderCards) => (
  <Grid container flexGrow={1}>
    {Array.from({ length: count })
      .fill(null)
      .map((_, index) => (
        <VoiceCardPlaceholder key={index} />
      ))}
  </Grid>
);
