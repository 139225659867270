import { TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { useSearchRolesLazyQuery, useSearchRolesQuery } from '../../../graphql';

interface Properties {
  staff?: boolean;
}

export function useRolesAutoComplete({ staff = false }: Properties = {}) {
  const [searchRoles, { loading: lazyLoading }] = useSearchRolesLazyQuery();
  const { data: defaultOptions, loading: initialLoading } = useSearchRolesQuery({
    variables: { staff },
  });
  const [options, setOptions] = useState<IRoleAutoComplete[]>([]);

  const onOpen = useCallback(() => {
    if (!defaultOptions) {
      return;
    }

    setOptions(defaultOptions.searchRoles);
  }, [defaultOptions]);

  const getOptionLabel = useCallback((option: any) => option.name, []);

  const onInputChange = useCallback(
    debounce(async (_event: any, value: string) => {
      if (!value) {
        return;
      }

      const { data, error } = await searchRoles({
        variables: {
          name: value,
          staff,
        },
      });

      if (error || !data) {
        return;
      }

      setOptions(data.searchRoles);
    }, 1000),
    [searchRoles],
  );

  const renderOption = useCallback((properties: any, option: IRoleAutoComplete) => {
    return (
      <li {...properties} key={option.id}>
        {option.name}
      </li>
    );
  }, []);

  const renderInput = useCallback(
    (parameters: any) => (
      <TextField
        {...parameters}
        disabled={initialLoading || lazyLoading}
        placeholder={initialLoading || lazyLoading ? 'Loading...' : ''}
      />
    ),
    [initialLoading, lazyLoading],
  );

  return {
    loading: initialLoading || lazyLoading,
    options,
    renderOption,
    renderInput,
    getOptionLabel,
    onOpen,
    onInputChange,
  };
}
