import { SVGProps } from 'react';

const KickboxingSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="m29.832 28.906-10.377-5.192v-3.27l4.723 4.723c.217.217.498.323.78.323a1.1 1.1 0 0 0 .78-1.875l-5.504-5.49a1.104 1.104 0 0 0-.779-.323h-2.848l-3.293-3.299 3.616-3.615a1.1 1.1 0 0 0 0-1.553 1.1 1.1 0 0 0-1.553 0l-4.395 4.395a1.1 1.1 0 0 0 0 1.553l4.073 4.072v3.937H10.66c-.61 0-1.096.493-1.096 1.102v5.49c0 .61.493 1.096 1.096 1.096.61 0 1.096-.492 1.096-1.096V25.49h6.334l10.758 5.379a1.098 1.098 0 1 0 .984-1.963Z"
    />
    <path
      fill="#6C5DD3"
      d="M16.156 14.51a2.198 2.198 0 1 0 4.396-.002 2.198 2.198 0 0 0-4.396.002Z"
    />
  </svg>
);
export default KickboxingSvg;
