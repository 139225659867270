export const ENDPOINTS = {
  REPORTS_INDICATORS: '/reports/indicators',
  GENERATE_REPORTS: '/reports/generate',
  USER_ACTIVITIES: '/users/activities',
  PARTICIPANT_ACTIVITIES: '/users/:id/activities',
  USER_SCHEDULES: '/users/:id/schedules',
  USER_STATS: '/users/stats',
  USER_MEDICATIONS: '/users/medications',
  USER_SYMPTOMS: '/users/symptoms',
  USERS: '/users',
  USERS_MANAGE: '/users/manage',
  RESEARCH_ACTIVITIES: '/research/activities',
  USER_INTERNAL_NOTES: '/users/notes/internal',
  SIGN_IN: '/users/signin',
  SIGN_UP: '/users/signup',
  CURRENT_USER: '/users/current',
  INTERVENTIONS: '/interventions',
  USER_MEDIA: '/user//media',
  ROLES: '/roles',
  PERMISSIONS: '/permissions',
  STUDIES: '/studies',
  FITBIT_AUTH: '/auth/fitbit',
  USER_SHARED_FILES: '/users/:id/sharedfiles',
  USER_MESSAGES: '/users/:id/messages',
  NOTIFICATIONS: '/notifications',
};
