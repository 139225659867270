import { TextField, MenuItem, Box } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { UserEntity, useUsersLazyQuery, useUsersQuery } from '../../../graphql';

interface Properties {
  excludes?: IStaffItem[];
  permissions?: string[] | undefined;
  filterAssigned?: boolean;
  studyId?: string | number | null;
  staff?: boolean;
}

export const useUsersAutoComplete = ({
  studyId,
  excludes = [],
  filterAssigned,
  staff = false,
}: Properties = {}) => {
  const [searchUsers, { loading: lazyLoading }] = useUsersLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { data: defaultOptions, loading: initialLoading } = useUsersQuery({
    variables: {
      studyId: Number(studyId),
      filterAssigned,
      staff,
    },
    fetchPolicy: 'network-only',
  });
  const [options, setOptions] = useState<UserEntity[]>([]);

  const onOpen = useCallback(() => {
    if (!defaultOptions) {
      return;
    }

    setOptions(
      defaultOptions.users.items.filter(
        (user) => Boolean(user.name) && !excludes?.find((user2) => user2.user?.id === user.id),
      ),
    );
  }, [defaultOptions, excludes]);

  const getOptionLabel = useCallback((option: any) => option.name, []);

  const onInputChange = useCallback(
    debounce(async (_: any, search: string) => {
      if (!search) {
        return;
      }

      const { data, error } = await searchUsers({
        variables: {
          search,
          studyId: Number(studyId),
          filterAssigned,
          staff,
        },
      });

      if (error || !data) {
        return;
      }

      setOptions(
        data.users.items.filter(
          (user) => Boolean(user.name) && !excludes?.find((user2) => user2.user?.id === user.id),
        ),
      );
    }, 1000),
    [searchUsers, excludes],
  );

  const renderOption = useCallback((properties: any, option: UserEntity, { selected }: any) => {
    return (
      <MenuItem
        {...properties}
        key={option.id}
        value={option.id}
        sx={{ justifyContent: 'space-between' }}
      >
        <Box flexGrow={1}>{option.name}</Box>
        {selected ? <CheckIcon color="primary" /> : null}
      </MenuItem>
    );
  }, []);

  const renderInput = useCallback(
    (parameters: any) => (
      <TextField
        {...parameters}
        disabled={initialLoading || lazyLoading}
        placeholder={initialLoading || lazyLoading ? 'Loading...' : parameters.placeholder || ''}
      />
    ),
    [initialLoading, lazyLoading],
  );

  return {
    loading: initialLoading || lazyLoading,
    options,
    renderOption,
    renderInput,
    getOptionLabel,
    onOpen,
    onInputChange,
  };
};
