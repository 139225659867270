import { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import { IconButton, TextField, Box } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CloseIcon from '@mui/icons-material/Close';

import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

const renderQuestionIcon = (questionType: string): ReactElement | null => {
  switch (questionType) {
    case 'radio': {
      return <CircleOutlinedIcon sx={{ mr: 2, color: theme.palette.grey[500] }} />;
    }
    case 'incrementPanel': {
      return <CircleOutlinedIcon sx={{ mr: 2, color: theme.palette.grey[500] }} />;
    }
    case 'multiselect':
    case 'checkbox': {
      return <CheckBoxOutlineBlankRoundedIcon sx={{ mr: 2, color: theme.palette.grey[500] }} />;
    }
    default: {
      return null;
    }
  }
};

interface EditableQuestionProperties {
  loading?: boolean;
  disableOptions?: boolean;
  type: string;
  options?: string[] | null;
  onChange?: (options_: any) => void;
}

export const EditableDefaultQuestion = ({
  loading,
  disableOptions,
  type,
  options,
  onChange,
}: EditableQuestionProperties) => {
  const { t } = usePreferredTranslation();
  const [optionText, setOptionText] = useState('');

  const addOptionReference = useRef<HTMLDivElement>(null);

  const handleUpdateQuestion = useCallback(
    (options_: any = {}) => onChange && onChange(options_),
    [onChange],
  );

  const addOption = useCallback(() => {
    if (optionText) {
      handleUpdateQuestion({ options: options ? [...options, optionText] : [optionText] });
    }
  }, [handleUpdateQuestion, optionText, options]);

  const updateOption = useCallback(
    (index: number, value: string) => {
      handleUpdateQuestion({
        options: options?.map((currentValue, index_) => (index === index_ ? value : currentValue)),
      });
    },
    [handleUpdateQuestion, options],
  );

  const handleOptionTextChange = useCallback((event_: any) => {
    setOptionText(event_.target.value);
  }, []);

  const handleRemoveOption = useCallback(
    (index: number) => {
      handleUpdateQuestion({ options: options?.filter((o, index_) => index_ !== index) });
    },
    [handleUpdateQuestion, options],
  );

  useEffect(() => {
    setOptionText('');
    if (document.activeElement?.tagName === 'BODY') {
      addOptionReference.current?.focus();
    }
  }, [options?.length]);

  return (
    <Box>
      {options?.map((option, index_) => (
        <Box key={index_} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
          {renderQuestionIcon(type)}
          <TextField
            disabled={loading}
            size="small"
            placeholder={t('Add another option')}
            value={option}
            onChange={(event_) => updateOption(index_, event_.target.value)}
          />
          <IconButton
            disabled={loading}
            aria-label="remove-option"
            onClick={() => handleRemoveOption(index_)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ))}
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        {renderQuestionIcon(type)}
        <TextField
          disabled={loading}
          size="small"
          placeholder={`Add an${options && options.length > 0 ? 'other' : ''} ${
            type === 'image-picker' ? 'image URL' : 'option'
          }`}
          value={optionText}
          onChange={handleOptionTextChange}
          onBlur={addOption}
          inputRef={addOptionReference}
        />
      </Box>
      {optionText && (
        <Box>
          {renderQuestionIcon(type)}
          <TextField
            disabled={disableOptions || loading}
            size="small"
            placeholder={t(`Add an${options && options.length > 0 ? 'other' : ''} option`)}
          />
        </Box>
      )}
    </Box>
  );
};
