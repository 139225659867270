import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, IconButton, BoxProps, Portal } from '@mui/material';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { useMediaRecorder, useTimer } from '../../../../hooks';

interface Properties extends BoxProps {
  readonly disabled?: boolean;
  readonly onComplete?: (blob: Blob, file: File, url: string) => void;
}

export function VideoRecord({
  disabled = false,
  sx,
  onComplete,
  ...rest
}: Properties): ReactElement<Properties> {
  const video = useRef<HTMLVideoElement | null>(null);

  const timer = useTimer(false);

  const onStart = useCallback(() => {
    timer.start(true);
  }, [timer]);

  const onStop = useCallback(
    (url: string, blob: Blob) => {
      timer.stop(true);

      onComplete?.(blob, new File([blob], 'video.mp4'), url);
    },
    [timer, onComplete],
  );

  const mediaRecorder = useMediaRecorder({ onStart, onStop, video: true, audio: true });

  const handleClick = useCallback(() => {
    if (!mediaRecorder.hasPermission) {
      mediaRecorder.getMediaAccess().catch((error) => {
        if (error.name === 'NotAllowedError') {
          toast.warning(
            `To continue, please give your browser permission to access your camera and microphone.`,
          );
        }
      });
    } else if (mediaRecorder.status === 'recording') {
      mediaRecorder.stopRecording();
    } else {
      mediaRecorder.startRecording();
    }
  }, [mediaRecorder]);

  useEffect(() => {
    if (mediaRecorder.status === 'recording' && video.current && !video.current.srcObject) {
      video.current.srcObject = mediaRecorder.previewVideoStream;
    }
  }, [mediaRecorder.previewVideoStream, mediaRecorder.status]);

  useEffect(() => {
    return () => {
      if (mediaRecorder) mediaRecorder.stopRecording();
    };
  }, []);
  return (
    <Box data-testid="videoRecordComponent" sx={{ display: 'inline-flex', ...sx }} {...rest}>
      <Portal>
        {mediaRecorder.status === 'recording' && (
          <Box
            sx={{
              position: 'fixed',
              right: '50px',
              top: '50px',
            }}
          >
            <video height="300px" muted autoPlay controls ref={video} />
          </Box>
        )}
      </Portal>

      <IconButton disabled={disabled} aria-label="Video Recording" onClick={handleClick}>
        {mediaRecorder.status === 'recording' ? <RecordVoiceOverIcon /> : <VideoChatIcon />}
        {timer.time > 0 && (
          <Badge
            color="primary"
            variant="standard"
            badgeContent={Number(timer.time / 1000).toFixed(2)}
          />
        )}
      </IconButton>
    </Box>
  );
}
