import { makeExecutableSchema } from 'graphql-tools';

import { QueryHelloArgs as QueryHelloArguments, Resolvers, typeDefs } from '../generated';

const resolvers: Resolvers = {
  Query: {
    hello(_: unknown, { name }: Partial<QueryHelloArguments>) {
      return `Hello, ${name}! from Mocks!`;
    },
  },
};

export default makeExecutableSchema({ typeDefs, resolvers });
