/* eslint-disable react/no-unstable-nested-components */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import React, { useMemo } from 'react';
import _ from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { dayjs } from '../../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { ChartsProperties } from './types';
import { FitbitChartsDataQuery } from '../../../../../graphql';
import { formatMinutes, getSleepDuration } from '../../../../../utils';
import { theme } from '../../../../../theme';

const formatTick = (tick: number) => {
  return `${tick} hrs`;
};

const classicSleepLevels = {
  restless: 0,
  asleep: 0,
};

const stagesSleepLevels = {
  rem: 0,
  light: 0,
  deep: 0,
};

const barsColors = ['#039BE5', '#311B92', '#43A047', '#563AD0', '#FF9800', '#9C27B0'];

const legendFormatter = (value: string) => {
  return (
    <span style={{ color: 'black', fontWeight: 500, fontSize: theme.typography.pxToRem(12) }}>
      {_.capitalize(value)}
    </span>
  );
};

interface TooltipProperties {
  data: FitbitChartsDataQuery | undefined;
  tooltipProps: TooltipProps<ValueType, NameType>;
}

const CustomTooltip = ({ data, tooltipProps }: TooltipProperties) => {
  const { payload } = tooltipProps;
  const { t } = usePreferredTranslation();
  if (!payload) return null;

  const date = payload[0]?.payload?.date as string;
  const dataKey = payload[0]?.dataKey;

  const { currentDayTotalDuration, timeRanges, totalDurationInMins } = getSleepDuration(
    data,
    date,
    dataKey,
  );

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
        pointerEvents: 'none',
      }}
    >
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography variant="body2" sx={{ fontWeight: 500, pr: 2 }}>
          {_.capitalize(String(dataKey))}
        </Typography>
        <Typography variant="body2">{formatMinutes(totalDurationInMins)}</Typography>
      </Stack>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, pr: 2, color: theme.palette.primary.main }}
        >
          {t('Total Sleep')}
        </Typography>
        <Typography variant="body2">{formatMinutes(currentDayTotalDuration)}</Typography>
      </Stack>
      <ul>
        {timeRanges.map((timeRange, index) => (
          <li key={index}>
            <p>{timeRange}</p>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export const SleepChart: React.FC<ChartsProperties> = ({ data }) => {
  const { bars, chartData } = useMemo(() => {
    const sleepData = data?.fitbitChartsData.sleep;

    const groupedData = _(sleepData)
      .groupBy((o) => dayjs.utc(o.date).format('MM/DD'))
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();

    const sleepJsonData = sleepData?.flatMap((o) => o.data);
    const isStagesData = sleepJsonData?.some((o) => o.type === 'stages');
    const isClassicData = sleepJsonData?.some((o) => o.type === 'classic');

    const barsDataKeys = [
      ...(isClassicData ? Object.keys(classicSleepLevels) : []),
      ...(isStagesData ? Object.keys(stagesSleepLevels) : []),
      'awake',
    ];

    const finalBars = barsDataKeys.map((key, index) => (
      <Bar key={key} dataKey={key} stackId="stack" barSize={15} fill={barsColors[index]} />
    ));

    const finalData = _.map(groupedData, (value, key) => {
      const sleepLevel: { [key: string]: number } = {
        ...(isClassicData ? classicSleepLevels : {}),
        ...(isStagesData ? stagesSleepLevels : {}),
        awake: 0,
      };

      for (const item of value) {
        const dataSleep = item.data;

        for (const sleep of dataSleep || []) {
          const levelsdata = sleep.levels.data;
          for (const level of levelsdata || []) {
            if (level.level in sleepLevel) {
              sleepLevel[level.level] = (sleepLevel[level.level] ?? 0) + level.seconds / 3600;
            }
          }
        }
      }
      return {
        date: key,
        ...sleepLevel,
      };
    });

    return { bars: finalBars, chartData: finalData };
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Tooltip
          shared={false}
          content={(properties) => <CustomTooltip data={data} tooltipProps={properties} />}
        />
        <Legend
          formatter={legendFormatter}
          verticalAlign="top"
          iconType="circle"
          wrapperStyle={{ marginTop: '-15px' }}
        />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatTick} />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};
