import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCountEmailsQuery } from '../../graphql';
import { useCurrentUser } from '../../states/user/useCurrentUser';
import { useChannel, useEvents } from '../../modules';
import { PUSHER } from '../../constants';

export type EmailsAnalytics = {
  readonly viewedCount: number;
  readonly notViewedCount: number;
  readonly likedCount: number;
  readonly notLikedCount: number;
  readonly count: number;
};

const defaultValue: EmailsAnalytics = {
  viewedCount: 0,
  notViewedCount: 0,
  likedCount: 0,
  notLikedCount: 0,
  count: 0,
};

export const EmailsAnalyticsContext = createContext<EmailsAnalytics>(defaultValue);

export const EmailsAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();

  const notViewedCountEmailsQuery = useCountEmailsQuery({
    variables: { where: { viewed: false } },
  });
  const notLikedCountEmailsQuery = useCountEmailsQuery({
    variables: { where: { liked: false } },
  });
  const countEmailsQuery = useCountEmailsQuery();

  const contextValue: EmailsAnalytics = useMemo<EmailsAnalytics>((): EmailsAnalytics => {
    const notViewedCount = notViewedCountEmailsQuery.data?.countEmails || 0;
    const notLikedCount = notLikedCountEmailsQuery.data?.countEmails || 0;
    const count = countEmailsQuery.data?.countEmails || 0;
    const viewedCount = count - notViewedCount;
    const likedCount = count - notLikedCount;

    return {
      ...defaultValue,
      viewedCount,
      notViewedCount,
      likedCount,
      notLikedCount,
      count,
    };
  }, [notViewedCountEmailsQuery, notLikedCountEmailsQuery, countEmailsQuery]);

  const emailsChannel = useChannel(PUSHER.CHANNELS.EMAILS);

  useEvents(
    emailsChannel,
    [
      `${PUSHER.EVENTS.EMAIL_CREATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.EMAIL_DELETED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.EMAIL_UPDATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.EMAILS_UPDATED}.${currentUser.data?.user?.id}`,
    ],
    () => {
      notViewedCountEmailsQuery.refetch();
      notLikedCountEmailsQuery.refetch();
      countEmailsQuery.refetch();
    },
  );

  return (
    <EmailsAnalyticsContext.Provider value={contextValue}>
      {children}
    </EmailsAnalyticsContext.Provider>
  );
};

export const useEmailsAnalytics = () => useContext(EmailsAnalyticsContext);
