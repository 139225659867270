import { ChangeEvent, DragEvent, ReactElement, useCallback, useRef, useState } from 'react';
import { Badge, Box, BoxProps, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface Properties extends BoxProps {
  readonly hasCount?: boolean;
  readonly disabled?: boolean;
  readonly accepts?: string[];
  readonly multiple?: boolean;
  readonly name?: string;
  readonly icon?: ReactElement;
  readonly onFilesSelect?: (files: File[]) => void;
  readonly label?: ReactElement;
}

export function Attacher({
  hasCount = false,
  disabled = false,
  accepts = ['.mp3', '.mp4', '.png', '.jpg', '.jpeg'],
  multiple = false,
  name,
  onFilesSelect,
  icon,
  sx,
  label,
  ...rest
}: Properties): ReactElement<Properties> {
  const [files, setFiles] = useState<File[]>([]);
  const input = useRef<HTMLInputElement | null>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files: File[] = [...(event.target.files || [])];

      if (files.length > 0 && files[0]) {
        onFilesSelect?.(files);
      }

      setFiles(files);

      event.target.value = '';
    },
    [onFilesSelect],
  );

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      const files: File[] = [
        ...((event.dataTransfer ? event.dataTransfer.files : (event.target as any)?.files) || []),
      ];

      if (files.length > 0 && files[0]) {
        onFilesSelect?.(files);
      }

      setFiles(files);
    },
    [onFilesSelect],
  );

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragEnter = useCallback((event: DragEvent) => {
    //
  }, []);

  const handleDragLeave = useCallback((event: DragEvent) => {
    //
  }, []);

  const accept = accepts?.length ? accepts.join(',') : '';

  return (
    <Box
      aria-label="Attacher"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      data-testid="attacherComponent"
      sx={{ display: 'inline-flex', ...sx }}
      {...rest}
    >
      <IconButton
        sx={icon && { padding: 0, margin: 0 }}
        data-testid="attach-file-button"
        component="label"
        color="secondary"
        aria-label="Attach File"
        disabled={disabled}
      >
        <input
          ref={input}
          name={name}
          accept={accept}
          multiple={multiple}
          hidden
          type="file"
          onChange={handleChange}
        />
        {!!label && label}
        {icon || <AttachFileIcon />}
        {hasCount && <Badge color="primary" variant="standard" badgeContent={files.length} />}
      </IconButton>
    </Box>
  );
}
