import { useEffect, useState } from 'react';
import { Typography, Box, BoxProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { dayjs } from '../../../../utils/dayjs';

import { IParticle } from '../../../../types/particles';
import { EmailEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import { FORMATS } from '../../../../constants';
import { Particles } from '../../particles';
import { Avatar } from '../../../atoms';

interface Properties extends BoxProps {
  owner?: boolean;
  email: EmailEntity;
  selected?: boolean;
  isGroupChat?: boolean;
}

export const Email = ({
  owner,
  email,
  selected = false,
  isGroupChat = false,
  ...rest
}: Properties) => {
  const [viewed, setViewed] = useState(email.viewed);

  const particles: IParticle[] = JSON.parse(email.data || '[]') || [];

  useEffect(() => {
    if (selected || email.viewed) {
      setViewed(true);
    }
  }, [selected, email, setViewed]);

  return (
    <Box
      sx={{
        py: 0,
        px: 0,
        display: 'flex',
        alignSelf: owner ? 'flex-end' : 'flex-start',
        justifyContent: owner ? 'flex-end' : 'flex-start',
      }}
    >
      {isGroupChat && !owner && (
        <Avatar
          size={40}
          name={email.sender?.name}
          sx={{ alignSelf: 'flex-end', ml: 0, mr: 0.5 }}
        />
      )}
      <Box
        sx={{
          py: 0,
          px: 0,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minWidth: 0,
          maxWidth: '60%',
          alignSelf: owner ? 'flex-end' : 'flex-start',
          alignItems: owner ? 'flex-end' : 'flex-start',
        }}
      >
        {isGroupChat && !owner && (
          <Typography fontSize={10} fontWeight="500" color={alpha(theme.palette.common.black, 0.4)}>
            {email.sender?.name}
          </Typography>
        )}

        <Box
          sx={{
            px: 1.5,
            py: 1.5,
            display: 'flex',
            flexGrow: 1,
            minWidth: 230,
            borderRadius: owner ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
            color: owner ? theme.palette.common.white : theme.palette.common.black,
            background: owner
              ? theme.palette.primary.main
              : alpha(theme.palette.primary.main, 0.12),
          }}
          {...rest}
        >
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <Box fontWeight="normal" sx={{ display: 'flex', alignItems: 'center', pr: 3 }}>
              {email.title}
            </Box>
            <Typography
              fontSize={10}
              fontWeight="normal"
              color={alpha(owner ? theme.palette.common.white : theme.palette.common.black, 0.6)}
            >
              {dayjs(email.createdAt).format(FORMATS.DATE)} ({dayjs().to(dayjs(email.createdAt))})
            </Typography>
          </Box>

          {particles.length > 0 && (
            <Particles maxWidth="200px" maxHeight="200px" particles={particles} />
          )}

          {selected && (
            <ArrowForwardIosRoundedIcon sx={{ color: theme.palette.text.disabled, fontSize: 14 }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
