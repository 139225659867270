import React from 'react';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

import { bluetoothIconStyles, fitbitContainerStyles, greenDotStyles, paperStyles } from './styles';
import { theme } from '../../../../theme';
import { FitbitDeviceNotFound } from './FitbitDeviceNotFound';

interface FitbitDeviceProperties {
  user: IUser;
  fitbitDevices: IFitBitDevice[];
  fitbitRefreshTokenError: boolean;
  fitbitLoading: boolean | undefined;
}

export const FitbitDevice: React.FC<FitbitDeviceProperties> = ({
  user,
  fitbitDevices,
  fitbitLoading,
  fitbitRefreshTokenError,
}) => {
  const { t } = usePreferredTranslation();

  const device = fitbitDevices?.[0];

  if (fitbitLoading) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  if ((!user?.fitbitAccessToken && fitbitRefreshTokenError) || !device) {
    return (
      <FitbitDeviceNotFound
        title={t('Fitbit Device Not Found')}
        subtitle={t('User has not connected a Fitbit device.')}
      />
    );
  }

  return (
    <Paper sx={paperStyles}>
      <Typography variant="h6">{t('Device')}</Typography>
      <Stack sx={fitbitContainerStyles} position="relative">
        <img src="/images/fitbit_device.svg" alt="fitbit-device" style={{ maxWidth: '30%' }} />
        <Stack>
          <Typography variant="subtitle2">Fitbit - {device?.deviceVersion}</Typography>
          <Stack direction="row" alignItems="center">
            <Box sx={greenDotStyles} />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {t('Connected')}
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ ...bluetoothIconStyles, position: 'absolute', top: 10, right: 10 }}>
          <BluetoothIcon color="primary" />
        </Stack>
      </Stack>
    </Paper>
  );
};
