import { useState } from 'react';
import { format, subDays, parseISO, isValid } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { ActivityCharts } from './ActivityCharts';
import { FitbitDevice } from './FitbitDevice';
import { ActivityOverview } from './ActivityOverview';
import { ExercisesList } from './ExercisesList';
import { useFitBitDevices } from '../../../states/fitbit/useDevices';

interface IntegrationsProperties {
  user: IUser;
}

export const Integrations: React.FC<IntegrationsProperties> = ({ user }) => {
  const currentDate = new Date();
  const range = {
    fromDate: subDays(currentDate, 30),
    toDate: subDays(currentDate, 1),
  };

  const [searchParameters, setSearchParameters] = useSearchParams();

  const dateRange = searchParameters.get('dateRange');
  if (dateRange) {
    const [fromDate, toDate] = dateRange.split('_').map((date) => parseISO(date));
    if (fromDate && toDate && isValid(fromDate) && isValid(toDate)) {
      range.fromDate = fromDate;
      range.toDate = toDate;
    }
  }

  const [dateValue] = useState(range);

  const formatDate = (date: Date) => format(new Date(date), 'yyyy-MM-dd');

  const updateDateRangeInUrl = ({ fromDate, toDate }: { fromDate: Date; toDate: Date }) => {
    const updatedDateRange = `${formatDate(fromDate)}_${formatDate(toDate)}`;
    const newSearchParameters = new URLSearchParams(searchParameters);
    newSearchParameters.set('dateRange', updatedDateRange);
    setSearchParameters(newSearchParameters);
  };

  const {
    data: fitbitDevices,
    fitbitRefreshTokenError,
    loading: fitbitDevicesLoading,
  } = useFitBitDevices({
    user,
  });

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={8}>
        <Stack gap={3}>
          <ActivityCharts
            user={user}
            fitbitDevices={fitbitDevices}
            fitbitRefreshTokenError={fitbitRefreshTokenError}
            dateRange={dateValue}
            setDateRange={updateDateRangeInUrl}
          />
          <ExercisesList user={user} dateRange={dateValue} setDateRange={updateDateRangeInUrl} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Stack gap={3}>
          <FitbitDevice
            user={user}
            fitbitDevices={fitbitDevices}
            fitbitLoading={fitbitDevicesLoading}
            fitbitRefreshTokenError={fitbitRefreshTokenError}
          />
          <ActivityOverview user={user} dateRange={dateValue} setDateRange={updateDateRangeInUrl} />
        </Stack>
      </Grid>
    </Grid>
  );
};
