import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { dayjs } from '../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { theme } from '../../../../theme';
import ConfirmDialog from '../../../commons/ConfirmDialog';

import {
  NoteEntity,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useNotesQuery,
  CreateNoteInput,
} from '../../../../graphql';
import { useChannel, useEvent } from '../../../../modules';
import { PUSHER, FORMATS } from '../../../../constants';
import { useUser, useCurrentUser } from '../../../../states';

interface Columns {
  [key: string]: {
    name: string;
    status: string;
    items: any[];
  };
}

interface INotesBoard {
  asEditor?: boolean;
  userId?: number;
}

export function NotesBoard({ asEditor, userId }: INotesBoard) {
  const { token } = useUser();
  const { t } = usePreferredTranslation();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(-1);
  const [channelNotes, setChannelNotes] = useState<NoteEntity[]>([]);
  const [createNoteMutation, createNoteMutationVariables] = useCreateNoteMutation();
  const [updateNoteMutation] = useUpdateNoteMutation();
  const [deleteNoteMutation, deleteNoteMutationVariables] = useDeleteNoteMutation();
  const { data } = useCurrentUser();
  const location = useLocation();

  const {
    refetch: refetchNotes,
    loading: loadingNotes,
    data: { notes: { items: notes = [] } = {} } = {},
  } = useNotesQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: { type: 'BOARD' },
      ...(asEditor && {
        editorId: Number(data?.user?.id),
      }),
      ...(asEditor && {
        user: Number(location.state?.user?.id),
      }),
      ...(userId && {
        user: userId,
      }),
    },
  });

  const notesChannel = useChannel(PUSHER.CHANNELS.NOTES);
  useEvent<NoteEntity>(
    notesChannel,
    `${PUSHER.EVENTS.NOTE_CREATED}.${token}`,
    (note?: NoteEntity) => {
      if (note) {
        setChannelNotes([note, ...channelNotes]);
      }
    },
  );

  const [columns, setColumns] = useState<Columns>({
    upcoming: {
      name: 'Upcoming',
      status: 'upcoming',
      items: [],
    },
    todo: {
      name: 'New Goal',
      status: 'todo',
      items: [],
    },
    inprogress: {
      name: 'In Progress',
      status: 'inprogress',
      items: [],
    },
    done: {
      name: 'Completed',
      status: 'done',
      items: [],
    },
  });

  const handleUnsavedNotes = useCallback(async () => {
    const temporaryList = localStorage.getItem('tempNotes');
    const temporaryListObject = JSON.parse(temporaryList || '{}');
    const foundList = Object.entries(temporaryListObject).filter(
      (session: any) => Number(session[1].editorId) === Number(data?.user?.id),
    );
    if (foundList?.length > 0) {
      try {
        await Promise.all(
          foundList.map(async (session) => {
            await createNoteMutation({
              variables: {
                createNoteInput: session[1] as CreateNoteInput,
              },
            });
            delete temporaryListObject[Number(session[0])];
            localStorage.setItem('tempNotes', JSON.stringify(temporaryListObject));
          }),
        );
        await refetchNotes();
        toast.success(t('Successfully added unsaved tasks!'));
      } catch {
        toast.error(t('An error occurred while adding unsaved tasks. Please try again.'));
      }
    }
  }, [createNoteMutation, refetchNotes, data?.user?.id]);

  useEffect(() => {
    if (!createNoteMutationVariables.loading) {
      handleUnsavedNotes();
    }
  }, [createNoteMutationVariables.loading, handleUnsavedNotes]);

  useEffect(() => {
    if (notes.length > 0) {
      setColumns({
        upcoming: {
          name: 'Upcoming',
          status: 'upcoming',
          items: notes.filter((item) => item.status === 'upcoming'),
        },
        todo: {
          name: 'New Goal',
          status: 'todo',
          items: notes.filter((item) => item.status === 'todo'),
        },
        inprogress: {
          name: 'In Progress',
          status: 'inprogress',
          items: notes.filter((item) => item.status === 'inprogress'),
        },
        done: {
          name: 'Completed',
          status: 'done',
          items: notes.filter((item) => item.status === 'done'),
        },
      });
    }
  }, [notes]);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) return;
      const { source, destination } = result;
      if (source.droppableId === destination.droppableId) {
        const column = columns[source.droppableId];
        const copiedItems = column ? [...column.items] : [];
        const [removed] = copiedItems.splice(source.index, 1);
        if (!removed) return;
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        } as Columns);
      } else {
        const sourceColumn = columns[source.droppableId];
        const destinationColumn = columns[destination.droppableId];
        const sourceItems = sourceColumn ? [...sourceColumn.items] : [];
        const destinationItems = destinationColumn ? [...destinationColumn.items] : [];
        const [removed] = sourceItems.splice(source.index, 1);
        if (!removed) return;
        destinationItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destinationColumn,
            items: destinationItems,
          },
        } as Columns);
        try {
          await updateNoteMutation({
            variables: {
              id: removed.id,
              updateNoteInput: { status: destination.droppableId, userId: Number(userId) },
            },
          });
          toast.success(t('Successfully updated the goal status'));
        } catch {
          await refetchNotes();
          toast.error(t('An error occurred while updating the goal status. Please try again.'));
        }
      }
    },
    [columns, setColumns, refetchNotes, updateNoteMutation, userId],
  );

  const [value, setValue] = useState('');
  const [showActiveAdding, setShowActiveAdding] = useState('');

  const handleSubmitAdd = useCallback(
    async (statusName: string) => {
      try {
        await createNoteMutation({
          variables: {
            createNoteInput: {
              title: value,
              type: 'BOARD',
              status: statusName,
              ...(asEditor && {
                editorId: Number(data?.user?.id),
                userId: Number(location.state?.user?.id),
              }),
              ...(userId && {
                userId,
              }),
            },
          },
        });
        setValue('');
        setShowActiveAdding('');
        await refetchNotes();
        toast.success(t('Task added successfully'));
      } catch {
        toast.error(t('An error occurred while creating a task. Please try again.'));
      }
    },
    [
      value,
      createNoteMutation,
      refetchNotes,
      asEditor,
      data?.user?.id,
      location.state?.user?.id,
      userId,
    ],
  );

  const closeConfirmDialog = useCallback(() => setConfirmDialogOpen(false), [setConfirmDialogOpen]);

  const handleDeleteNoteConfirm = useCallback((id: number) => {
    setSelectedIdToDelete(id);
    setConfirmDialogOpen(true);
  }, []);

  const handleDeleteNote = useCallback(async () => {
    try {
      await deleteNoteMutation({
        variables: {
          id: selectedIdToDelete,
        },
      });
      setSelectedIdToDelete(-1);
      setConfirmDialogOpen(false);
      await refetchNotes();
      toast.success(t('Successfully deleted a task!'));
    } catch {
      toast.error(t('An error occurred while deleteing the task. Please try again.'));
    }
  }, [deleteNoteMutation, refetchNotes, selectedIdToDelete]);

  const handleSetShowActiveAdding = useCallback(
    (statusName: string) => {
      setShowActiveAdding(showActiveAdding === statusName ? '' : statusName);
    },
    [showActiveAdding],
  );

  return (
    <Box>
      <Grid
        container
        flexWrap="nowrap"
        sx={{
          overflowX: 'auto',
          height: '100%',
        }}
      >
        <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)}>
          {Object.entries(columns).map(([columnId, column]) => {
            return (
              <Stack
                flexGrow={1}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '25%',
                  minWidth: 236,
                  flex: 1,
                  mr: 2,
                  '&:last-of-type': {
                    mr: 0,
                  },
                }}
                key={columnId}
              >
                <Stack
                  sx={{
                    width: '100%',
                    flexDirection: 'column',
                    flex: 1,
                    border: `1px solid ${grey[300]}`,
                    borderRadius: '12px',
                    background: grey[50],
                    p: 1.5,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Typography variant="caption" component="p" sx={{ textTransform: 'uppercase' }}>
                      {t(column.name)}
                    </Typography>
                    <AddIcon
                      data-testid="start-adding-goal-note"
                      sx={{ color: grey[500] }}
                      fontSize="small"
                      onClick={() => handleSetShowActiveAdding(column.status)}
                    />
                  </Stack>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(providedDrop: DroppableProvided) => {
                      return (
                        <Box
                          {...providedDrop.droppableProps}
                          ref={providedDrop.innerRef}
                          sx={{ minHeight: 200, flex: 1 }}
                        >
                          {column.items.map((item, columnIndex) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={String(item.id)}
                                index={columnIndex}
                              >
                                {(
                                  provided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot,
                                ) => {
                                  return (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        userSelect: 'none',
                                        px: 1.5,
                                        py: 1,
                                        mb: 1,
                                        border: `1px solid ${grey[300]}`,
                                        borderRadius: '8px',
                                        backgroundColor: snapshot.isDragging
                                          ? grey[300]
                                          : theme.palette.common.white,
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Stack direction="row" justifyContent="space-between">
                                        <Box>
                                          <Typography component="p">{item.title}</Typography>
                                          <Typography
                                            variant="caption"
                                            component="p"
                                            color={grey[500]}
                                          >
                                            {dayjs(item.createdAt).format(FORMATS.DATE)}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            component="p"
                                            color={grey[500]}
                                          >
                                            ({dayjs().to(dayjs(item.createdAt))})
                                          </Typography>
                                        </Box>
                                        <Box sx={{ color: grey[500] }}>
                                          <ClearIcon
                                            fontSize="small"
                                            data-testid="remove-goal-note"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteNoteConfirm(item.id)}
                                          />
                                        </Box>
                                      </Stack>
                                    </Box>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {providedDrop.placeholder}
                        </Box>
                      );
                    }}
                  </Droppable>
                  {showActiveAdding === column.status && (
                    <Stack alignItems="flex-end">
                      <TextareaAutosize
                        data-testid="new-goal-note-input"
                        placeholder={t('Add your goal here...')}
                        value={value}
                        onChange={(event: any) => setValue(event.target.value)}
                        style={{
                          width: '100%',
                          resize: 'vertical',
                          minHeight: 50,
                          borderColor: grey[300],
                          borderRadius: '12px',
                          padding: '1rem',
                          marginBottom: '0.5rem',
                        }}
                      />
                      <Button
                        data-testid="submit-new-goal-note"
                        variant="contained"
                        onClick={() => handleSubmitAdd(column.status)}
                        disabled={!value || loadingNotes || createNoteMutationVariables.loading}
                      >
                        {t('Submit')}
                        {(loadingNotes || createNoteMutationVariables.loading) && (
                          <CircularProgress sx={{ ml: 1 }} color="inherit" size={16} />
                        )}
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            );
          })}
        </DragDropContext>
        <ConfirmDialog
          open={confirmDialogOpen}
          loading={deleteNoteMutationVariables.loading}
          title={t('Delete Confirm')}
          message={t('Are you sure to delete the selected note?')}
          onSubmit={handleDeleteNote}
          onClose={closeConfirmDialog}
        />
      </Grid>
    </Box>
  );
}
