import { useCallback } from 'react';
import { Box, Stack, LinearProgress } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { theme } from '../../../theme';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';

interface Properties {
  label: string;
  value?: number;
  index?: number;
  isAnswered?: boolean;
  loading?: boolean;
  responseId?: number;
}

export default function CategoryCard({
  label,
  value = 0,
  isAnswered,
  loading,
  index,
  responseId,
}: Properties) {
  const { startJournal, editJournal } = useActiveJournal();

  const disabled = loading;
  const handleClick = useCallback(() => {
    if (disabled) return;
    if (index === undefined) return;

    if (isAnswered && responseId !== undefined) {
      editJournal(index, responseId);
      return;
    }

    startJournal(index);
  }, [index, disabled, isAnswered, startJournal, editJournal, responseId]);

  return (
    <Box
      sx={{
        padding: '52px 12px 12px',
        minWidth: '138px',
        minHeight: '97px',
        borderRadius: '4px',
        boxShadow: '0px 1px 0px 0px #444444 inset',
        '&:hover': {
          boxShadow: disabled ? '' : '0px 1px 0px 0px #FFFF1A inset',
        },
        background: '#1A1A1A',
        fontSize: theme.typography.pxToRem(14),
        cursor: disabled ? 'unset' : 'pointer',
      }}
      onClick={handleClick}
    >
      <Stack direction="row">
        <Box flex={1}>{label}</Box>
        <ChevronRight color="secondary" />
      </Stack>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          mt: 1,
          borderRadius: 5,
          background: isAnswered ? '#6FB56F' : 'rgba(108, 93, 211, 0.12)',
        }}
      />
    </Box>
  );
}
