import { MenuItem, Select } from '@mui/material';
import { AssessmentQuestionEntity } from '../../../../../graphql';
import { theme } from '../../../../../theme';

interface Properties {
  type?: string;
  question: AssessmentQuestionEntity;
  defaultValue?: string;
  onChange?: (value: IResistanceIntakeAnswers[] | string) => void;
}

const ResistanceSelectInput = ({ question, onChange, defaultValue }: Properties) => {
  const handleChange = (event: any, option: any) => {
    onChange?.(option.props?.value);
  };

  return (
    <Select
      displayEmpty
      sx={{ backgroundColor: theme.palette.common.white }}
      onChange={handleChange}
      value={defaultValue}
    >
      <MenuItem disabled>{question.label}</MenuItem>
      {(question.options || '').split(',').map((ele) => {
        const [value, ...rest] = ele.split('-');
        const text = rest.join('');
        if (!(value || text)) {
          return '';
        }
        return (
          <MenuItem key={ele} value={value}>
            {text}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default ResistanceSelectInput;
