import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { UserEntity } from '../../../../graphql';
import { Avatar } from '../../../atoms';
import { theme } from '../../../../theme';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

type Properties = {
  user: UserEntity;
};

export const EmailDetails = ({ user }: Properties): JSX.Element => {
  const { t } = usePreferredTranslation();
  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        height: 'inherit',
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Avatar sx={{ fontSize: theme.typography.pxToRem(24) }} name={user.name} size={72} />
      <Typography variant="h6" sx={{ mt: 1.5 }}>
        {user.name}
      </Typography>
      <Typography variant="body2" color={theme.palette.secondary.main} sx={{ mt: 0.5 }}>
        {user.email}
      </Typography>
      <Button
        component={RouterLink}
        to={`/users/profile/${user.id}`}
        color="inherit"
        size="small"
        sx={{ mt: 2, boxShadow: 'none', color: theme.palette.secondary.main }}
        variant="contained"
      >
        {t('Open profile')}
      </Button>
    </Box>
  );
};
