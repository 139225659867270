import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { dayjs } from './utils/dayjs';
import './styles/index.scss';
import {
  protectedRoutes,
  publicRoutes,
  publicUnauthenticatedRoutes,
  PublicRoutes,
  PublicUnauthenticatedRoutes,
  ProtectedRoutes,
} from './routes';
import { App } from './App';
import { theme } from './theme';
import { PusherProvider } from './modules';
import { informer, setupSentry, setupVitals, setupI18n, setupGa } from './utils';
import { client } from './graphql';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// @TODO Remove HTTP network mocks when backend servers are ready
import './mocks/adaptor';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);

informer();

if (process.env.REACT_APP_ENABLE_SENTRY === 'true' && process.env.REACT_APP_SENTRY) {
  setupSentry();
}

if (process.env.REACT_APP_ENABLE_VITALS === 'true') {
  setupVitals();
}

const i18n = setupI18n();

const pusherConfiguration = {
  // required config props
  clientKey: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      {protectedRoutes.map(({ path, permission, ...rest }, index) => (
        <Route key={index} element={<ProtectedRoutes permission={permission} />}>
          <Route key={path} path={path} {...rest} />
        </Route>
      ))}

      <Route element={<PublicUnauthenticatedRoutes />}>
        {publicUnauthenticatedRoutes.map(({ path, ...rest }) => (
          <Route key={path} path={path} {...rest} />
        ))}
      </Route>

      <Route element={<PublicRoutes />}>
        {publicRoutes.map(({ path, ...rest }) => (
          <Route key={path} path={path} {...rest} />
        ))}
      </Route>
    </Route>,
  ),
);

createRoot(document.querySelector('#root') as Element).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <PusherProvider {...pusherConfiguration}>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <I18nextProvider i18n={i18n}>
            <DatePickerLocalizationProvider dateAdapter={AdapterDateFns}>
              <RouterProvider router={router} />
            </DatePickerLocalizationProvider>
          </I18nextProvider>
        </DndProvider>
      </ApolloProvider>
    </PusherProvider>
  </ThemeProvider>,
);

serviceWorkerRegistration.unregister();
