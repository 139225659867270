import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import {
  VoiceEntity,
  UserEntity,
  useUpdateVoiceMutation,
  useViewAllVoicesMutation,
} from '../../../../graphql';

import { logger } from '../../../../utils';
import { useCurrentUser, useUser } from '../../../../states';
import { UserRoles } from '../../../../constants/users';
import { UsersVoices } from '../UsersVoices';
import { Voices } from '../Voices';
import { VoiceDetails } from '../VoiceDetails';

interface Properties {
  readonly studyId: number;
  readonly preSelectedUser?: UserEntity;
}

export const VoicesSection = ({ studyId, preSelectedUser }: Properties): ReactElement => {
  const { checkUserRole } = useUser();
  const { data: userData } = useCurrentUser();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);
  const [selectedVoice, setSelectedVoice] = useState<VoiceEntity | null>(null);

  const [updateVoiceMutation] = useUpdateVoiceMutation();
  const [viewAllVoices] = useViewAllVoicesMutation();

  useEffect(() => {
    if (preSelectedUser) setSelectedUser(preSelectedUser);
  }, [preSelectedUser]);

  const handleSelectVoice = useCallback(
    (voice: VoiceEntity | null) => {
      setSelectedVoice(voice);

      if (voice && !voice.viewed) {
        updateVoiceMutation({
          variables: {
            id: voice.id,
            updateVoiceInput: { viewed: true },
          },
        }).catch((error) => {
          logger.error(error);
        });
      }
    },
    [updateVoiceMutation],
  );

  const handleSelectUser = useCallback(
    (user: UserEntity | null) => {
      if (user && user.id) setSearchParameters({ userId: user.id });
      setSelectedUser(user);
    },
    [setSearchParameters],
  );

  useEffect(() => {
    if (selectedUser) {
      viewAllVoices({
        variables: {
          user: Number(selectedUser.id),
        },
      }).catch((error) => {
        logger.error(error);
      });
    }
  }, [selectedUser, viewAllVoices]);

  const isStaff =
    checkUserRole(userData?.user, UserRoles.ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.SUPER_ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.COACH);

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Grid container sx={{ flexGrow: 1, height: '100%' }}>
        {isStaff && (
          <Grid
            item
            xs={selectedUser ? 4 : 12}
            sx={{ height: 'calc(100vh - 135px)', overflow: 'auto' }}
          >
            <Box
              sx={{
                textAlign: 'left',
                padding: 0,
                flexGrow: 1,
                overflow: 'auto',
                height: '100%',
              }}
            >
              <UsersVoices
                hasTabs={false}
                hasSort={false}
                selectedUser={selectedUser}
                onSelectUser={handleSelectUser}
                studyId={studyId}
              />
            </Box>
          </Grid>
        )}
        {(selectedUser || checkUserRole(userData?.user, UserRoles.PARTICIPANT)) && (
          <Grid
            item
            xs={isStaff ? 6 : 12}
            sx={{
              height: 'calc(100vh - 135px)', // Have a fixed height for content to be scrollable
              border: '1px solid',
              borderTop: 'none',
              borderBottom: 'none',
              borderColor: (theme) => theme.palette.divider,
            }}
          >
            <Voices
              user={selectedUser ?? undefined}
              selectedVoice={selectedVoice}
              onSelectVoice={handleSelectVoice}
            />
          </Grid>
        )}
        {isStaff && !!selectedUser && (
          <Grid item xs={2}>
            <VoiceDetails user={selectedUser} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
