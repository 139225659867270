import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useMemo } from 'react';
import _ from 'lodash';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Stack, Typography } from '@mui/material';
import { TOptions } from 'i18next';
import { dayjs } from '../../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { ChartsProperties } from './types';
import { theme } from '../../../../../theme';

const formatTick = (tick: number) => {
  return `${tick} min`;
};

const labelMap: Record<string, string> = {
  sedentary: 'Sedentary',
  lightly: 'Lightly Active',
  fairly: 'Fairly Active',
  very: 'Very Active',
};

const legendFormatter = (
  value: string,
  t: (key: string, options?: TOptions | undefined) => string,
) => {
  return (
    <span style={{ color: 'black', fontWeight: 500, fontSize: theme.typography.pxToRem(12) }}>
      {t(labelMap[value] || '')}
    </span>
  );
};

const CustomTooltip = (properties: TooltipProps<ValueType, NameType>) => {
  const { t } = usePreferredTranslation();
  const { payload } = properties;

  const data = payload?.[0];

  if (!data) return null;
  const duration = data.payload[data.dataKey as string];
  const label = data.payload.date;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography variant="body1">
        {labelMap[data.dataKey as string]} ({label})
      </Typography>
      <Typography variant="body1">
        `${duration}${t('min')}`
      </Typography>
    </Stack>
  );
};

export const ActivityChart: React.FC<ChartsProperties> = ({ data }) => {
  const { t } = usePreferredTranslation();
  const chartData = useMemo(() => {
    const activityData = data?.fitbitChartsData.activity ?? [];

    const groupedData = _.groupBy(activityData, (o) => {
      const dateWithoutTimezone = o.startTime?.split('.')?.[0];
      return dayjs.utc(dateWithoutTimezone).format('MM/DD');
    });

    return _.map(groupedData, (value, key) => {
      const activityLevel: { [key: string]: number } = {
        sedentary: 0,
        lightly: 0,
        fairly: 0,
        very: 0,
      };

      for (const item of value) {
        for (const level of item.activityLevel || []) {
          if (level.name in activityLevel) {
            activityLevel[level.name] += level.minutes;
          }
        }
      }
      return {
        date: key,
        ...activityLevel,
      };
    });
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Legend
          verticalAlign="top"
          iconType="circle"
          formatter={(value) => legendFormatter(value, t)}
          wrapperStyle={{
            top: '-10px',
          }}
        />
        <Tooltip shared={false} content={CustomTooltip} />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatTick} />
        <Bar dataKey="sedentary" fill="#6C5DD3" barSize={15} />
        <Bar dataKey="lightly" fill="#4CAF50" barSize={15} />
        <Bar dataKey="fairly" fill="#D9D9D9" barSize={15} />
        <Bar dataKey="very" fill="#6445B0" barSize={15} />
      </BarChart>
    </ResponsiveContainer>
  );
};
