import { HTMLAttributes, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Svg404 } from '../../../components/atoms/icons';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<HTMLElement> {
  label?: string;
}

export default function NotFoundPage(): ReactElement<Properties> {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = usePreferredTranslation();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box
      data-testid="notFoundPage"
      component="main"
      sx={{
        flexGrow: 1,
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        overflow: 'auto',
        m: 0,
      }}
    >
      <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 600, mb: 3 }}>
        {t('404 Error bee boop')}
      </Typography>
      <Svg404 />
      <Typography variant="h5" sx={{ color: theme.palette.text.secondary, mt: 3 }}>
        {t('Head bone not connected to funny bone')}
      </Typography>
      <Typography component="p" sx={{ color: theme.palette.text.secondary, my: 1 }}>
        {t('The page you are looking for is not here.')}
      </Typography>
      <Button color="primary" variant="contained" sx={{ mt: 5 }} onClick={handleBack}>
        {t('Back to home')}
      </Button>
    </Box>
  );
}
