import { ReactElement, useCallback, useState } from 'react';
import { Box, Paper, Stack, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import { PageHeading } from '../../components';
import { useInterventions } from '../../contexts';
import InterventionCard from './InterventionCard';
import CreateInterventionModal from '../../components/modals/users/interventions/CreateInterventionModal';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

export default function InterventionsPage(): ReactElement {
  const [interventionModalOpened, setInterventionModalOpened] = useState(false);
  const { interventions } = useInterventions();
  const { t } = usePreferredTranslation();

  const onInterventionModalToggle = useCallback(
    () => setInterventionModalOpened(!interventionModalOpened),
    [interventionModalOpened],
  );

  return (
    <Box
      data-testid="Intervention"
      component="main"
      sx={{
        flexGrow: 1,
        padding: 3,
        height: '100vh',
        overflow: 'auto',
        m: 0,
      }}
    >
      <PageHeading title={t('Interventions')}>
        <Button variant="contained" color="primary" onClick={onInterventionModalToggle}>
          <NoteAddOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />
          Create survey
        </Button>
      </PageHeading>
      <Paper>
        <Stack>
          {interventions &&
            interventions.map((intervention, index) => (
              <InterventionCard key={index} {...intervention} />
            ))}
        </Stack>
      </Paper>
      <CreateInterventionModal open={interventionModalOpened} onClose={onInterventionModalToggle} />
    </Box>
  );
}
