import { TextareaAutosize, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties {
  label?: string;
  value?: string;
  index?: number;
  required?: boolean;
  onChange?: (event: any) => void;
  hideLabel?: boolean;
  showBoldLabel?: boolean;
}

export const TextareaField = ({
  index,
  label,
  value = '',
  required,
  onChange,
  showBoldLabel,
  hideLabel = false,
}: Properties) => {
  const { t } = usePreferredTranslation();
  return (
    <Box sx={{ mb: 2 }}>
      {!hideLabel && (
        <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
          {index}
          {index && '.'} {label} {required && '*'}
        </Typography>
      )}
      <TextareaAutosize
        placeholder={t('Add your answer here...')}
        value={value}
        style={{
          width: '100%',
          resize: 'vertical',
          minHeight: 104,
          borderColor: grey[300],
          borderRadius: '12px',
          padding: '1rem',
        }}
        onChange={onChange}
      />
    </Box>
  );
};

export default TextareaField;
