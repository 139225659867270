import { IUser } from '../types/user';

export const users: IUser[] = [
  {
    id: '045f1f77bcf86cd20485720',
    name: 'Dan Miles',
    email: 'dan_miles@example.com',
    studies: [
      {
        name: 'Colon cancer',
        role: 'Researcher',
        permissions: [
          'See profiles',
          'Edit profiles',
          'Study modules',
          'PROs',
          'Active data readings',
          'Telephone assessments',
        ],
      },
      {
        name: 'CoVax study',
        role: 'Lead Researcher',
        permissions: [
          'See profiles',
          'Edit profiles',
          'Study modules',
          'PROs',
          'Active data readings',
          'Telephone assessments',
        ],
      },
    ],
    role: 'Researcher',
    status: 'Pending',
  },
  {
    id: '934f1f77bcf86cd11075993',
    name: 'Sandra Brown',
    email: 'sandra13brown@example.com',
    studies: [
      {
        name: 'COVID-19 study',
        role: 'Participant',
        permissions: ['See profiles', 'Study modules'],
      },
      {
        name: 'CoVax study',
        role: 'Data Collector',
        permissions: ['See profiles', 'Study modules', 'Active data readings'],
      },
    ],
    role: 'Researcher',
    status: 'Pending',
  },
  {
    id: '759f1f77bcf86cd65839465',
    name: 'Jake Nick',
    email: 'jake.nick45@example.com',
    studies: [
      {
        name: 'COVID-19 study',
        role: 'Lead Researcher',
        permissions: [
          'See profiles',
          'Edit profiles',
          'Study modules',
          'PROs',
          'Active data readings',
          'Telephone assessments',
        ],
      },
      {
        name: 'CoVax study',
        role: 'Interventionist',
        permissions: ['See profiles', 'Edit profiles', 'PROs', 'Telephone assessments'],
      },
    ],
    role: 'Researcher',
    status: 'In progress',
  },
];
