import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { addDays, subDays } from 'date-fns';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { IPlot } from './Plot';
import {
  AssessmentQuestionEntity,
  GraphDataPointsQuery,
  useGraphDataPointsLazyQuery,
} from '../../../graphql';
import { ComposedChart, GraphTypes } from '../../charts/ComposedChart';
import PieChart from '../../charts/PieChart';
import RadialBarChart from '../../charts/RadialChart';
import { DateRangePickerField } from '../../atoms';
import { getDateStringFromDateTime } from '../../../utils';

type Properties = {
  type: GraphTypes;
  type1: GraphTypes;
  plots: IPlot[];
  plots1: IPlot[];
  xKey: string | undefined;
  yKey: string | undefined;
  yKey1: string | undefined;
  isPreview?: boolean;
  profileId: number;
  chartTitle?: string;
  dataSource?: AssessmentQuestionEntity | null;
  dataSource1?: AssessmentQuestionEntity | null;
  meta?: any;
};

type ChartDate = {
  fromDate: Date;
  toDate: Date;
};

const PreviewContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = usePreferredTranslation();
  return (
    <Stack sx={{ ml: 4 }}>
      <Typography sx={{ ml: 1, mb: 1 }}>{t('Preview')}</Typography>
      <Stack
        sx={{
          py: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#ccc',
          borderRadius: 8,
          width: 380,
          pb: 8,
          minHeight: 450,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Stack>
    </Stack>
  );
};
const Container: React.FC<
  PropsWithChildren & {
    dateValue: ChartDate;
    setDate: Dispatch<SetStateAction<ChartDate>>;
    chartTitle?: string;
  }
> = ({ children, setDate, dateValue, chartTitle }) => {
  const { t } = usePreferredTranslation();
  return (
    <Paper
      sx={{
        pb: 9,
        pt: 2,
        pl: 2,
        width: '100%',
        borderRadius: 3,
        minHeight: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        mb={6}
        flexWrap="wrap"
        sx={{ maxWidth: '75%' }}
      >
        <Typography variant="h6">{chartTitle}</Typography>
        <DateRangePickerField
          sx={{
            padding: '6px 12px',
            backgroundColor: '#eeeeee',
            border: 'none',
          }}
          onChange={(dates) => {
            setDate({
              fromDate: dates[0],
              toDate: dates[1],
            });
          }}
          value={[dateValue.fromDate, dateValue.toDate]}
        />
      </Stack>
      <Stack
        sx={{ width: '100%', height: '100%', mt: 2 }}
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Stack>
    </Paper>
  );
};

export const PreviewChart = ({
  plots,
  plots1,
  yKey1,
  type,
  type1,
  xKey,
  yKey,
  isPreview = true,
  profileId,
  chartTitle,
  dataSource,
  dataSource1,
  meta,
}: Properties) => {
  const { t } = usePreferredTranslation();
  const [graphDataQuery] = useGraphDataPointsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const currentDate = new Date();
  const [graphData, setGraphData] = useState<GraphDataPointsQuery | null>();
  const [chartDate, setChartDate] = useState<ChartDate>({
    fromDate: getDateStringFromDateTime(subDays(currentDate, 30)) as unknown as Date,
    toDate: getDateStringFromDateTime(addDays(currentDate, 1)) as unknown as Date,
  });

  const fetchGraphData = useCallback(async () => {
    const arePlotIdsEmpty =
      plots.some((item) => !item.sourceId) || plots1.some((item) => !item.sourceId);

    if (isPreview && arePlotIdsEmpty) {
      return;
    }

    const { data } = await graphDataQuery({
      fetchPolicy: 'network-only',
      variables: {
        graphDataPointsInput: {
          fromDate: getDateStringFromDateTime(chartDate.fromDate),
          toDate: getDateStringFromDateTime(chartDate.toDate),
          questionsId: [
            ...plots.map((item) => Number(item.sourceId)),
            ...plots1.map((item) => Number(item.sourceId)),
          ],
          userId: profileId,
        },
      },
    });

    setGraphData(data);
  }, [plots, plots1, graphDataQuery, profileId, chartDate, isPreview]);

  useEffect(() => {
    fetchGraphData();
  }, [plots, fetchGraphData, plots1]);

  const ChartContainer = isPreview ? PreviewContainer : Container;

  const chart =
    type === GraphTypes.PieChart ? (
      <PieChart data={graphData} plots={plots} />
    ) : type === GraphTypes.RadialBarChart ? (
      <RadialBarChart data={graphData} plots={plots} />
    ) : (
      <ComposedChart
        data={graphData}
        xKey={xKey ?? ''}
        yKey={yKey ?? ''}
        yKey2={yKey1 ?? ''}
        plots={plots}
        plots1={plots1}
        graphType={type}
        graphType1={type1}
        dataSource={dataSource}
        dataSource1={dataSource1}
        profileId={profileId}
        isPreview={isPreview}
        meta={meta}
      />
    );

  return (
    <ChartContainer dateValue={chartDate} setDate={setChartDate} chartTitle={chartTitle}>
      {dataSource ? (
        graphData?.graphDataPoints.items.length === 0 ? (
          <Typography>{t('No Data found for selected date range')}</Typography>
        ) : plots.length > 0 ? (
          chart
        ) : (
          <Typography>{t('Select Plot')}</Typography>
        )
      ) : (
        <Typography>{t('Select a data source')}</Typography>
      )}
    </ChartContainer>
  );
};
