export const usePermissions = (user: IUser) => {
  const userPermissions = new Map<string, IStudyPermission>();

  if (!user) {
    return userPermissions;
  }

  // First build a map of study and user permission based on the user's role
  user.roles?.map(({ role, study }) =>
    userPermissions.set(String(study.id), {
      studyId: String(study.id),
      roleId: role.id,
      roleName: role.name,
      permissions: role.permissions || [],
    }),
  );

  // Now override the user's study role permissions with user specific/assigned permissions
  const studyPermissions = user.permissions?.reduce((table, { permission, study }) => {
    let existingPermissions = table.get(study.id);

    if (existingPermissions) {
      existingPermissions.add(permission);
    } else {
      existingPermissions = new Set([permission]);
    }

    table.set(study.id, existingPermissions);

    return table;
  }, new Map());

  // If the user does not have any user-specific/assigned permissions, then return the user's role permissions
  if (!studyPermissions) {
    return userPermissions;
  }

  for (const [studyId, permissions] of studyPermissions) {
    const rolePermission = userPermissions.get(studyId);

    if (rolePermission) {
      rolePermission.permissions = [...permissions];
    }
  }

  return userPermissions;
};
