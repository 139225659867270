import _ from 'lodash';
import { SVGProps } from 'react';
import { dayjs } from '../dayjs';
import {
  MissedIVRCall,
  MissedVideoCall,
  IVRCall,
  VideoCall,
  DirectCall,
  MissedDirectCall,
} from '../../components/atoms/icons';
import { AdherenceMetricsQuery } from '../../graphql';
import { EventStatuses } from '../../components/users/Coaching/InfoHeader/type';

export type MetricsAdherence = AdherenceMetricsQuery['adherenceMetrics']['adherenceItems'][0];

export type MetricsAdherenceByWeek = {
  key: string;
  weekStartDate: Date;
  items: MetricsAdherence[];
};

const getWeeksBetweenDates = (start: Date | string, end: Date | string): [Date, Date][] => {
  const weeks: [Date, Date][] = [];
  let currentDate = dayjs(start).startOf('day');
  const endDate = dayjs(end).endOf('day');
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    const weekStart = currentDate.toDate();
    const weekEnd = currentDate.add(6, 'day').toDate();
    weeks.push([weekStart, weekEnd]);
    currentDate = currentDate.add(1, 'week');
  }
  return weeks;
};

export const getAdherenceMetricsByWeek = (
  start: Date | string,
  end: Date | string,
  events: MetricsAdherence[],
  startDate?: Date,
): Array<MetricsAdherenceByWeek> => {
  const weeks = getWeeksBetweenDates(startDate ?? start, end);

  const sortedEvents = _.orderBy(events, 'startDate', 'desc');

  const weeksData = weeks.map((week, index) => {
    const weekStart = week[0];
    const weekEnd = week[1];
    const key = `Week ${index + 1}`;
    const items = sortedEvents.filter((event) => {
      return (
        dayjs(event.startDate).isBefore(dayjs(weekEnd).endOf('day')) &&
        dayjs(event.startDate).isAfter(dayjs(weekStart).startOf('day'))
      );
    });
    return { key, items, weekStartDate: weekStart };
  });

  return weeksData.reverse();
};

export const getAdherenceCallStats = (events: MetricsAdherence[]) => {
  const totalCalls = events.length;
  const totalMissedCalls = events.filter(
    (event) => event.status === EventStatuses.STARTED || !event.status,
  ).length;
  const totalMissedPercentage = ((totalMissedCalls / totalCalls) * 100).toFixed(0) || 0;

  const totalCompletedCalls = events.filter(
    (event) =>
      event.status === EventStatuses.INCOMPLETE || event.status === EventStatuses.COMPLETED,
  ).length;
  const totalCompletePercentage = ((totalCompletedCalls / totalCalls) * 100).toFixed(0) || 0;

  const totalBarPercentage = Number(totalMissedPercentage) + Number(totalCompletePercentage);

  return {
    totalCalls,
    totalMissedCalls,
    totalMissedPercentage: Number(totalMissedPercentage),
    totalCompletedCalls,
    totalCompletePercentage: Number(totalCompletePercentage),
    totalBarPercentage,
  };
};

export const AdherenceSVGMap: {
  [key: string]: (properties: SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  MissedCoachingCall: MissedVideoCall,
  MissedIVRCall,
  CoachingCall: VideoCall,
  IVRCall,
  DirectCall,
  MissedDirectCall,
};

export const getEventIcon = (type: string, status: string | null | undefined) => {
  if (status === EventStatuses.COMPLETED || status === EventStatuses.INCOMPLETE) {
    if (type === 'IVR') {
      return AdherenceSVGMap.IVRCall;
    }
    if (type === 'COACHING') {
      return AdherenceSVGMap.CoachingCall;
    }
    return AdherenceSVGMap.DirectCall;
  }

  if (type === 'IVR') {
    return AdherenceSVGMap.MissedIVRCall;
  }
  if (type === 'COACHING') {
    return AdherenceSVGMap.MissedCoachingCall;
  }
  return AdherenceSVGMap.MissedDirectCall;
};

export const getEventName = (type: string) => {
  if (type === 'COACHING') {
    return 'Coaching';
  }
  if (type === 'video-call') {
    return 'Video call';
  }
  if (type === 'phone-call') {
    return 'Phone call';
  }
  return type;
};
