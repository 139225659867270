import { ReactElement } from 'react';
import { Box } from '@mui/material';

import { RadioButtonList } from '../RadioButtonList';

interface Properties {
  label?: string;
  value?: string;
  options?: string[] | null;
  index?: number;
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange?: (event: any) => void;
}

export const RadioField = ({
  index,
  label,
  value,
  options,
  required,
  onChange,
  showBoldLabel,
  hideLabel = false,
}: Properties): ReactElement<Properties> => {
  return (
    <Box sx={{ mb: 2 }}>
      <RadioButtonList
        required={required}
        index={index}
        value={value}
        label={label}
        options={options || []}
        showBoldLabel={showBoldLabel}
        hideLabel={hideLabel}
        onChange={onChange}
      />
    </Box>
  );
};

export default RadioField;
