import { SVGProps } from 'react';

const YogaSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M18.046 12.982A2.164 2.164 0 1 1 22.21 11.8a2.164 2.164 0 0 1-4.164 1.181Zm9.674 4.57a1.033 1.033 0 1 0 .006-2.064l-6.292-.02a1.88 1.88 0 0 0-2.591.02l-6.22-.02a1.034 1.034 0 0 0-.006 2.066l5.652.016.035 4.39c.001.081.01.16.02.239l-6.561 5.329a1.187 1.187 0 0 0 1.497 1.843l6.842-5.556c.026.004.049.013.074.016l5.474.668.005 4.128a1.186 1.186 0 1 0 2.374-.043l-.005-5.099a1.183 1.183 0 0 0-.579-.995 1.174 1.174 0 0 0-.569-.234l-4.806-.586-.032-4.114 5.682.017Z"
    />
  </svg>
);
export default YogaSvg;
