import { Grid, Paper } from '@mui/material';

import { ContentLoader } from '../ContentLoader';

export const ProjectCardPlaceholder = () => (
  <Grid item xs={12} md={5}>
    <Paper sx={{ minWidth: 100, p: 2 }}>
      <ContentLoader
        speed={2}
        width={500}
        height={500}
        viewBox="0 0 400 400"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="88" height="20" />
        <rect x="0" y="32" rx="3" ry="3" width="388" height="41" />

        <rect x="0" y="105" rx="0" ry="0" width="280" height="10" />
        <rect x="0" y="123" rx="0" ry="0" width="129" height="10" />
        <rect x="1" y="144" rx="0" ry="0" width="386" height="2" />

        <rect x="0" y="175" rx="0" ry="0" width="280" height="10" />
        <rect x="0" y="193" rx="0" ry="0" width="129" height="10" />
        <rect x="1" y="214" rx="0" ry="0" width="386" height="2" />
      </ContentLoader>
    </Paper>
  </Grid>
);

export default ProjectCardPlaceholder;
