import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCountNotificationsQuery } from '../../graphql';
import { useChannel, useEvents } from '../../modules';
import { PUSHER } from '../../constants';
import { useUser } from '../../states';
import { logger } from '../../utils';

export type NotificationsAnalytics = {
  readonly viewedCount: number;
  readonly notViewedCount: number;
  readonly likedCount: number;
  readonly notLikedCount: number;
  readonly count: number;
};

const defaultValue: NotificationsAnalytics = {
  viewedCount: 0,
  notViewedCount: 0,
  likedCount: 0,
  notLikedCount: 0,
  count: 0,
};

export const NotificationsAnalyticsContext = createContext<NotificationsAnalytics>(defaultValue);

export const NotificationsAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const { studyId, userId } = useUser();

  const notViewedCountNotificationsQuery = useCountNotificationsQuery({
    variables: { where: { viewed: false }, studyId },
  });
  const notLikedCountNotificationsQuery = useCountNotificationsQuery({
    variables: { where: { liked: false }, studyId },
  });
  const countNotificationsQuery = useCountNotificationsQuery({ variables: { studyId } });

  const contextValue: NotificationsAnalytics =
    useMemo<NotificationsAnalytics>((): NotificationsAnalytics => {
      const notViewedCount = notViewedCountNotificationsQuery.data?.countNotifications || 0;
      const notLikedCount = notLikedCountNotificationsQuery.data?.countNotifications || 0;
      const count = countNotificationsQuery.data?.countNotifications || 0;
      const viewedCount = count - notViewedCount;
      const likedCount = count - notLikedCount;

      return { ...defaultValue, viewedCount, notViewedCount, likedCount, notLikedCount, count };
    }, [
      notViewedCountNotificationsQuery,
      notLikedCountNotificationsQuery,
      countNotificationsQuery,
    ]);

  const notificationsChannel = useChannel(PUSHER.CHANNELS.NOTIFICATIONS);

  useEvents(
    notificationsChannel,
    [
      `${PUSHER.EVENTS.NOTIFICATION_CREATED}.${userId ?? ''}`,
      `${PUSHER.EVENTS.NOTIFICATION_DELETED}.${userId ?? ''}`,
      `${PUSHER.EVENTS.NOTIFICATION_UPDATED}.${userId ?? ''}`,
      `${PUSHER.EVENTS.NOTIFICATIONS_UPDATED}.${userId ?? ''}`,
    ],
    () => {
      notViewedCountNotificationsQuery.refetch().catch((error) => logger.error(error));
      notLikedCountNotificationsQuery.refetch().catch((error) => logger.error(error));
      countNotificationsQuery.refetch().catch((error) => logger.error(error));
    },
  );

  return (
    <NotificationsAnalyticsContext.Provider value={contextValue}>
      {children}
    </NotificationsAnalyticsContext.Provider>
  );
};

export const useNotificationsAnalytics = () => useContext(NotificationsAnalyticsContext);
