import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { ENDPOINTS } from '../../constants';
import { IIntervention, IInterventions } from '../../types/intervention';
import { useAxios } from '../../hooks';

export type IInterventionsContextType = {
  interventions: IInterventions;
  loading: boolean;
  createNew?: (intervention: IIntervention) => void;
  updateIntervention?: (intervention: IIntervention) => void;
};

const defaultResponse: IInterventionsContextType = {
  interventions: [],
  loading: true,
};

export const InterventionsContext = createContext<IInterventionsContextType>(defaultResponse);

export const InterventionsProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useAxios({ url: ENDPOINTS.INTERVENTIONS });
  const [interventionsData, setInterventionsData] = useState<IInterventions>([]);

  const createNew = useCallback(
    (intervention: IIntervention) => {
      setInterventionsData([...(interventionsData || []), intervention]);
    },
    [interventionsData, setInterventionsData],
  );

  const updateIntervention = useCallback(
    (intervention: IIntervention) => {
      setInterventionsData(
        interventionsData?.map((item) => (item.id === intervention.id ? intervention : item)) || [],
      );
    },
    [interventionsData, setInterventionsData],
  );

  useEffect(() => {
    if (interventionsData?.length === 0) {
      setInterventionsData(data || []);
    }
  }, [data, interventionsData?.length]);

  const contextValue = useMemo((): IInterventionsContextType => {
    return {
      interventions: interventionsData || [],
      loading,
      createNew,
      updateIntervention,
    };
  }, [interventionsData, loading, createNew, updateIntervention]);

  return (
    <InterventionsContext.Provider value={contextValue}>{children}</InterventionsContext.Provider>
  );
};

export const useInterventions = () => useContext(InterventionsContext);
