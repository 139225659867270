export const signupSuccess = {
  success: true,
};

export const signinSuccess = {
  token: 'BAE4B86E28E791FFC28B46B3D4778',
};

export const MOCK_USER = {
  __typename: undefined,
  id: '11',
  name: 'Haleeq Usman',
  description: 'Development Engineer',
  email: 'haleeq.usman@toptal.com',
  initials: 'DB',
  age: 47,
  sex: 'Male',
  timeZone: '(GMT+3)',
  status: 'verified',
  createdAt: new Date('2022-11-23T21:58:16.192Z'),
  updatedAt: new Date('2022-11-28T01:37:54.000Z'),
  roles: [
    {
      __typename: undefined,
      role: {
        __typename: undefined,
        id: '5',
        name: 'Administrator',
        description: null,
        permissions: [
          {
            __typename: undefined,
            id: '1',
            name: 'See profiles',
            description: 'Determines if a user has access to view user profiles',
            createdAt: new Date('2022-11-11T02:08:34.971Z'),
            updatedAt: new Date('2022-11-11T02:08:34.971Z'),
          },
        ],
      },
      study: {
        __typename: undefined,
        id: 1,
        name: 'COVID-19',
        irb: '10',
        displayName: 'COVID-19',
        description: 'COVID 2019 Pandemic Study',
        createdAt: new Date('2022-11-11T21:56:51.113Z'),
        updatedAt: new Date('2022-11-11T21:56:51.113Z'),
      },
    },
  ],
  permissions: [],
  phoneNumbers: [
    {
      __typename: undefined,
      id: '4',
      type: 'mobile',
      label: 'Mobile',
      isPreferred: true,
      country: {
        __typename: undefined,
        id: '1',
        countryCode: 'US',
        countryCallCode: '44',
        nationalNumber: '7886415352',
        numberValue: '+447886415352',
      },
    },
  ],
};

export const activities = [
  {
    id: '007f1f77bcf86cd799439011',
    title: 'Jennifer Kerr',
    description: 'missed their checkin call three times.',
    date: 'Tue, 24 June',
    category: 'Colon cancer',
    badges: ['Urgent'],
  },
  {
    id: '107f1f77bcf86cd799439011',
    title: 'David Blane',
    description: 'missed intervention two times.',
    date: 'Wed, 23 June',
    category: 'COVID-19 study',
    badges: ['Urgent'],
  },
  {
    id: '207f1f77bcf86cd799439011',
    title: 'Brian Ramsey',
    description: 'left the study',
    date: 'Wed, 23 June',
    category: 'COVID-19 study',
    badges: ['Medium'],
  },
  {
    id: '307f1f77bcf86cd799439011',
    title: 'Clare Doe',
    description: 'reported issue #41',
    date: 'Mon, 21 June',
    category: 'Mock study',
    badges: ['On track'],
  },
];

export const stats = [
  {
    id: '507f1f77bcf86cd799439011',
    label: 'Activity per day',
    metric: '76%',
  },
  {
    id: '507f191e810c19729de860ea',
    label: 'Calories per day',
    metric: '384',
  },
  {
    id: '00000020f51bb4362eee2a4d',
    label: 'Intensities',
    metric: '256',
  },
  {
    id: '407f1f77bcf86cd799439011',
    label: 'Steps per day',
    metric: '37',
  },
];
