import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  List,
  PaperProps,
  ListItemButton,
  Paper,
  Box,
  Button,
  TablePagination,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  ListItem,
} from '@mui/material';
import debounce from 'lodash.debounce';

import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import { useParams } from 'react-router-dom';

import { theme } from '../../../../theme';
import { UserEntity, useUsersLazyQuery, useUsersMessagesQuery } from '../../../../graphql';
import { MessageCardsPlaceholder, Tabs } from '../../../atoms';

import { UserMessage } from '../UserMessage';
import { useChannel, useEvents } from '../../../../modules';
import { PUSHER } from '../../../../constants';
import { useCurrentUser } from '../../../../states';
import { Users } from '../../users';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends PaperProps {
  readonly hasTabs?: boolean;
  readonly hasSearch?: boolean;
  readonly hasSort?: boolean;
  readonly hasPagination?: boolean;
  readonly onSelectUser: (user: UserEntity | null) => void;
  readonly selectedUser: UserEntity | null;
  readonly studyId: number;
}

export const UsersMessages = ({
  hasTabs = false,
  hasSearch = true,
  hasSort = false,
  hasPagination = true,
  onSelectUser,
  selectedUser,
  studyId,
  ...rest
}: Properties) => {
  const tabs: ['All', 'Unread', 'Read'] = ['All', 'Unread', 'Read'];
  const { t } = usePreferredTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showSelectedUserAsDropdownLabel, setShowSelectedUserAsDropdownLabel] =
    useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState<'All' | 'Unread' | 'Read'>(tabs[0]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const renderTab = (value: typeof tab) => {
    let label = '';

    switch (value) {
      case 'All': {
        label = `${value}`;
        break;
      }
      case 'Unread': {
        label = `${value}`;
        break;
      }
      case 'Read': {
        label = `${value}`;
        break;
      }
      default: {
        break;
      }
    }

    return <Tab key={value} value={value} label={label} />;
  };

  const { id } = useParams();

  const currentUser = useCurrentUser();

  const usersMessagesQuery = useUsersMessagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
  });

  const [searchUser, { data: searchedUsersData, loading: searchedUserLoading }] = useUsersLazyQuery(
    {
      variables: {
        search,
        studyId,
        limit: {
          take: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    },
  );

  const searchUserDebouncer = useCallback(
    debounce(() => searchUser(), 1000, { leading: false, trailing: true }),
    [],
  );

  useEffect(() => {
    if (!search) return;
    searchUserDebouncer();
  }, [search, searchUser]);

  const messagesChannel = useChannel(PUSHER.CHANNELS.MESSAGES);

  useEvents(
    messagesChannel,
    [
      `${PUSHER.EVENTS.MESSAGE_CREATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGE_DELETED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGE_UPDATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGES_UPDATED}.${currentUser.data?.user?.id}`,
    ],
    () => {
      usersMessagesQuery.refetch();
    },
  );

  const usersMessages = useMemo(
    () =>
      usersMessagesQuery.data?.usersMessages.items.sort((o1, o2) => {
        if (o1.lastMessageAt && o2.lastMessageAt) return o2.lastMessageAt - o1.lastMessageAt;
        return 0;
      }) || [],
    [usersMessagesQuery.data?.usersMessages],
  );

  const count =
    (search ? searchedUsersData?.users?.count : usersMessagesQuery.data?.usersMessages.count) || 0;

  useEffect(() => {
    if (usersMessagesQuery.loading) {
      onSelectUser(null);
    }
  }, [usersMessagesQuery.loading, onSelectUser]);

  useEffect(() => {
    const uMessage = usersMessages.find((userMessage) => userMessage.id === id);

    if (uMessage) {
      onSelectUser(uMessage);
    }
  }, [id, usersMessages, onSelectUser]);

  useEffect(() => {
    if (count > 0 && !loaded) {
      setLoaded(true);
    }
  }, [count, loaded, setLoaded]);

  const onTabChange = useCallback((_: unknown, value: typeof tab) => {
    setTab(value);
    setPage(0);
  }, []);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setPage(value);
  };

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(Number.parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchor(event.currentTarget);
    },
    [setAnchor],
  );

  const handleMenuClose = useCallback(() => {
    setAnchor(null);
  }, [setAnchor]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setPage(0);
    },
    [setSearch, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setPage(0);
  }, [setSearch, setPage]);

  return (
    <Paper
      {...rest}
      sx={{
        height: '100%',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      {(hasTabs || hasSearch || hasSearch) && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasTabs && (
            <Tabs
              value={tab}
              textColor="inherit"
              indicatorColor="secondary"
              aria-label="Notifications"
              onChange={onTabChange}
            >
              {tabs.map((value) => renderTab(value))}
            </Tabs>
          )}

          {(hasSearch || hasTabs) && (
            <Box
              sx={{
                flexGrow: hasTabs ? 0 : 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {hasSearch && (
                <TextField
                  fullWidth={!hasTabs}
                  placeholder={t('Search user')}
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: search && (
                      <IconButton aria-label="Reset search users..." onClick={handleSearchReset}>
                        <CancelRoundedIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}

              {hasSort && (
                <>
                  <Button
                    size="large"
                    startIcon={<SortRoundedIcon />}
                    sx={{ ml: 1, color: theme.palette.secondary.main, whiteSpace: 'nowrap' }}
                    color="inherit"
                    variant="text"
                    id="users-messages-menu-button"
                    aria-controls={anchor ? 'users-messages-menu-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchor ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    {t('Sort by')}
                  </Button>
                  <Menu
                    id="users-messages-menu-button"
                    aria-labelledby="users-messages-menu-button"
                    anchorEl={anchor}
                    open={!!anchor}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleMenuClose}>Date</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          )}
        </Box>
      )}

      {(!loaded && usersMessagesQuery.loading) || searchedUserLoading ? (
        <MessageCardsPlaceholder />
      ) : (
        <>
          <List sx={{ p: 0, overflow: 'auto' }}>
            {!search && (
              <Users
                sx={{ mb: 2 }}
                onSelectUser={(user) => {
                  setShowSelectedUserAsDropdownLabel(true);
                  onSelectUser(user);
                }}
                selectedUser={showSelectedUserAsDropdownLabel ? selectedUser : null}
              />
            )}
            {(search
              ? searchedUsersData?.users.count === 0
              : usersMessagesQuery.data?.usersMessages.count === 0) &&
              !selectedUser && (
                <ListItem>
                  <Alert sx={{ width: '100%' }} severity="error">
                    {t('Nothing found')}
                  </Alert>
                </ListItem>
              )}
            {(search ? searchedUsersData?.users.items || [] : usersMessages).map(
              (user: UserEntity) => (
                <ListItemButton
                  key={user.id}
                  selected={user.id === selectedUser?.id}
                  onClick={() => onSelectUser(user)}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: theme.palette.divider,
                    p: 0,
                  }}
                >
                  <UserMessage
                    selected={user.id === selectedUser?.id}
                    user={user}
                    newChat={!usersMessages.some((u) => u.id === user.id)}
                  />
                </ListItemButton>
              ),
            )}
          </List>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'flex-end',
            }}
          >
            {hasPagination && (
              <TablePagination
                sx={{}}
                component="div"
                count={count}
                page={page}
                onPageChange={handlePageChange}
                labelRowsPerPage={t('Rows per page:')}
                nextIconButtonProps={{
                  'aria-label': t('Go to next page'),
                  title: t('Go to next page'),
                }}
                backIconButtonProps={{
                  'aria-label': t('Go to previous page'),
                  title: t('Go to previous page'),
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </Box>
        </>
      )}
    </Paper>
  );
};
