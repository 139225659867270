import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Link, { LinkProps } from '@mui/material/Link';

interface Properties extends LinkProps {
  expand?: boolean;
  href?: string;
  onClick: (event: any) => void;
}

export function MoreButton({ expand = true, href = '/', onClick, children }: Properties) {
  const handleClick = useCallback(
    (event: any) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  return (
    <Link
      component={RouterLink}
      to={href}
      onClick={handleClick}
      sx={{ textDecoration: 'none', color: 'secondary.main' }}
    >
      <Stack direction="row">
        {children}
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Stack>
    </Link>
  );
}
