import { ProfileEventsQuery } from '../../../../graphql';

export type Event = ProfileEventsQuery['getProfileEvents'][0];
export type ScheduledEvent = Event['event'];
export type EventByType = {
  event: ScheduledEvent;
  duration: number;
  timeLeft: string;
};

export enum EventsTabs {
  All = 'All',
  Coaching = 'Coaching',
  IVR = 'IVR',
}
