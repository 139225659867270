import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Paper,
  PaperProps,
  Tab,
  TablePagination,
  TextField,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { use } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { UserEntity, useUsersConferencesQuery, useUsersLazyQuery } from '../../../../graphql';
import { theme } from '../../../../theme';
import { Tabs, VoiceCardsPlaceholder } from '../../../atoms';

import { Users } from '../../users';
import { UserRecording } from '../UserRecording';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends PaperProps {
  readonly hasTabs?: boolean;
  readonly hasSearch?: boolean;
  readonly hasSort?: boolean;
  readonly hasPagination?: boolean;
  readonly onSelectUser: (user: UserEntity | null) => void;
  readonly selectedUser: UserEntity | null;
  readonly studyId: number;
}

export const UsersRecordings = ({
  hasTabs = true,
  hasSearch = true,
  hasSort = true,
  hasPagination = true,
  onSelectUser,
  selectedUser,
  studyId,
  ...rest
}: Properties) => {
  const { t } = usePreferredTranslation();
  const tabs: ['All', 'Unread', 'Read'] = ['All', 'Unread', 'Read'];
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState<'All' | 'Unread' | 'Read'>(tabs[0]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParameters] = useSearchParams();

  const id = searchParameters.get('userId');

  const renderTab = (value: typeof tab) => {
    let label = '';

    switch (value) {
      case 'All': {
        label = `${value}`;
        break;
      }
      case 'Unread': {
        label = `${value}`;
        break;
      }
      case 'Read': {
        label = `${value}`;
        break;
      }
      default: {
        break;
      }
    }

    return <Tab key={value} value={value} label={t(label)} />;
  };

  const usersConferencesQuery = useUsersConferencesQuery({
    fetchPolicy: 'network-only',
    variables: {
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
  });

  const [searchUser, { data: searchedUsersData, loading: searchedUserLoading }] = useUsersLazyQuery(
    {
      variables: {
        search,
        studyId,
        limit: {
          take: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    },
  );

  const searchUserDebouncer = useCallback(
    debounce(() => searchUser(), 1000, { leading: false, trailing: true }),
    [],
  );

  useEffect(() => {
    if (!search) return;
    searchUserDebouncer();
  }, [search, searchUser]);

  const onTabChange = useCallback((_: unknown, value: typeof tab) => {
    setTab(value);
    setPage(0);
  }, []);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setPage(value);
  };

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(Number.parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchor(event.currentTarget);
    },
    [setAnchor],
  );

  const handleMenuClose = useCallback(() => {
    setAnchor(null);
  }, [setAnchor]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setPage(0);
    },
    [setSearch, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setPage(0);
  }, [setSearch, setPage]);

  const userConferences = useMemo(
    () =>
      usersConferencesQuery.data?.userConferences.items.sort((c1, c2) => {
        if (c1.lastMessageAt && c2.lastMessageAt) {
          return c2.lastMessageAt - c1.lastMessageAt;
        }
        return 0;
      }) || [],
    [usersConferencesQuery.data?.userConferences],
  );

  useEffect(() => {
    const selectedUserConfig = userConferences.find((c) => c.id === id);
    if (selectedUserConfig) {
      onSelectUser(selectedUserConfig);
    }
  }, [id, userConferences, onSelectUser]);

  const count =
    (search
      ? searchedUsersData?.users?.count
      : usersConferencesQuery.data?.userConferences?.count) || 0;

  return (
    <Paper
      {...rest}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      {(hasTabs || hasSearch || hasSearch) && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasTabs && (
            <Tabs
              value={tab}
              textColor="inherit"
              indicatorColor="secondary"
              aria-label="Notifications"
              onChange={onTabChange}
            >
              {tabs.map((value) => renderTab(value))}
            </Tabs>
          )}

          {(hasSearch || hasTabs) && (
            <Box
              sx={{
                flexGrow: hasTabs ? 0 : 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {hasSearch && (
                <TextField
                  fullWidth={!hasTabs}
                  placeholder={t('Search...')}
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: search && (
                      <IconButton aria-label="Reset search users..." onClick={handleSearchReset}>
                        <CancelRoundedIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}

              {hasSort && (
                <>
                  <Button
                    size="large"
                    startIcon={<SortRoundedIcon />}
                    sx={{ ml: 1, color: theme.palette.secondary.main, whiteSpace: 'nowrap' }}
                    color="inherit"
                    variant="text"
                    id="users-voices-menu-button"
                    aria-controls={anchor ? 'users-voices-menu-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchor ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    Sort by
                  </Button>
                  <Menu
                    id="users-voices-menu-button"
                    aria-labelledby="users-voices-menu-button"
                    anchorEl={anchor}
                    open={!!anchor}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleMenuClose}>Date</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          )}
        </Box>
      )}

      {searchedUserLoading || usersConferencesQuery.loading ? (
        <VoiceCardsPlaceholder />
      ) : (
        <>
          <List sx={{ p: 0, overflow: 'auto' }}>
            {!search && (
              <Users sx={{ mb: 2 }} onSelectUser={onSelectUser} selectedUser={selectedUser} />
            )}
            {searchedUsersData?.users.count === 0 ||
              (usersConferencesQuery.data?.userConferences.count === 0 && !selectedUser && (
                <ListItem>
                  <Alert sx={{ width: '100%' }} severity="error">
                    {t('Nothing found')}
                  </Alert>
                </ListItem>
              ))}
            {(search ? searchedUsersData?.users.items || [] : userConferences)?.map(
              (user: UserEntity) => (
                <ListItemButton
                  key={user.id}
                  selected={user.id === selectedUser?.id}
                  onClick={() => onSelectUser(user)}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: theme.palette.divider,
                    p: 0,
                  }}
                >
                  <UserRecording selected={user.id === selectedUser?.id} user={user} />
                </ListItemButton>
              ),
            )}
          </List>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'flex-end',
            }}
          >
            {hasPagination && (
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handlePageChange}
                labelRowsPerPage={t('Rows per page:')}
                nextIconButtonProps={{
                  'aria-label': t('Go to next page'),
                  title: t('Go to next page'),
                }}
                backIconButtonProps={{
                  'aria-label': t('Go to previous page'),
                  title: t('Go to previous page'),
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </Box>
        </>
      )}
    </Paper>
  );
};
