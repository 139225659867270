import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PinIcon from '@mui/icons-material/PushPinRounded';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { NoteCardsPlaceholder, TabbedCard } from '../atoms';
import { NoteEntity, UpdateNoteInput, useStudyQuery } from '../../graphql';
import { useCurrentUser, useUser } from '../../states';

import AddEditNoteModal from '../modals/users/AddEditNoteModal';
import { DataRowGrid } from '../atoms/TabbedCard/DataRowGrid';
import { FORMATS } from '../../constants';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  notes: Array<NoteEntity>;
  profileId: number;
  loading: boolean;
  saveNote?: (newNoteData: any, id: number | undefined, isPinToggle?: boolean) => void;
  showMore?: () => void;
  totalNotes: number;
}

const convertNoteToActivity = (note: NoteEntity) => ({
  headline: <strong>{note.title}</strong>,
  subtitle: `${note.description || ''}`,
  attachments: JSON.parse(note?.data || '[]').map((a: any) => ({
    type: a.type,
    name: 'Attachment',
    url: a.content,
  })),
  // date: dayjs(note.createdAt).format(FORMATS.DATE),
});

export default function ParticipantProfileInternalNotes({
  notes,
  loading,
  profileId,
  saveNote,
  showMore,
  totalNotes,
}: Properties) {
  const theme = useTheme();
  const { t } = usePreferredTranslation();

  const [currentNote, setCurrentNote] = useState<NoteEntity | null>(null);
  const [addEditNoteModalOpened, setAddEditNoteModalOpened] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const { studyId } = useUser();
  const currentUser = useCurrentUser();
  const studyVariables = useStudyQuery({
    variables: { id: Number(studyId) },
    skip: !studyId,
    fetchPolicy: 'network-only',
  });
  const modalTitle = isReplying
    ? t('Reply to the Note')
    : currentNote
    ? t('Edit Note')
    : t('Add Note');

  const onAddEditNoteModalToggle = useCallback(
    () => setAddEditNoteModalOpened(!addEditNoteModalOpened),
    [addEditNoteModalOpened, setAddEditNoteModalOpened],
  );

  const onTriggerAddNote = useCallback(() => {
    setIsReplying(false);
    setAddEditNoteModalOpened(true);
    setCurrentNote(null);
  }, [setAddEditNoteModalOpened]);

  const onTriggerReply = useCallback(
    (note: NoteEntity) => {
      setIsReplying(true);
      setAddEditNoteModalOpened(true);
      setCurrentNote(note);
    },
    [setIsReplying, setAddEditNoteModalOpened],
  );

  const onTriggerEditNote = useCallback(
    (note: NoteEntity) => {
      setIsReplying(false);
      setAddEditNoteModalOpened(true);
      setCurrentNote(note);
    },
    [setAddEditNoteModalOpened, setCurrentNote],
  );

  const togglePinNote = useCallback(
    (note: NoteEntity) => {
      const savedNote: UpdateNoteInput = { isPinned: !note.isPinned, userId: Number(note.user.id) };
      if (saveNote) saveNote(savedNote, note.id, true);
    },
    [saveNote],
  );

  const onSubmitNote = useCallback(
    (oldNote: NoteEntity | null, message: string) => {
      if (saveNote) {
        const savedNote = isReplying
          ? {
              title: message,
              parentId: Number(currentNote?.id),
              description: `Reply to ${currentNote?.title || ''}`,
            }
          : {
              title: message,
              userId: profileId,
              editorId: Number(currentUser.data?.user?.id),
              data: studyVariables.data?.study?.name,
              ...(!oldNote && {
                description: `Created by ${currentUser.data?.user?.name || ''}`,
              }),
              ...(oldNote && {
                description: `Last updated by ${currentUser.data?.user?.name || ''}`,
              }),
            };
        saveNote(savedNote, oldNote?.id);
        onAddEditNoteModalToggle();
        setCurrentNote(null);
      }
    },
    [
      saveNote,
      isReplying,
      currentNote?.id,
      currentNote?.title,
      profileId,
      currentUser.data?.user?.id,
      currentUser.data?.user?.name,
      studyVariables.data?.study?.name,
      onAddEditNoteModalToggle,
    ],
  );

  const isMine = Number(currentUser.data?.user?.id) === Number(profileId);

  return (
    <>
      <TabbedCard
        dataTest="profileInternalNotes"
        title={isMine ? t('My Notes') : t('Internal Notes')}
        ariaLabel="participant internal notes tabs"
        sx={{
          p: 3,
          minWidth: 160,
          minHeight: 275,
        }}
        secondaryAction={
          <Box
            data-testid="addNewNoteButton"
            onClick={onTriggerAddNote}
            sx={{
              cursor: 'pointer',
              color: 'secondary.main',
            }}
          >
            <Stack data-testid="addNewInternalNoteButton" direction="row">
              <AddIcon />
              {t('Add Note')}
            </Stack>
          </Box>
        }
        tertiaryAction={
          totalNotes === notes.length ? undefined : (
            <Button
              data-testid="showMoreNotesButton"
              onClick={showMore}
              sx={{ color: theme.palette.secondary.main }}
              endIcon={<ExpandMoreIcon />}
            >
              {t('Show more')}
            </Button>
          )
        }
      >
        <Box>
          {loading && <NoteCardsPlaceholder count={2} />}
          {!loading &&
            notes.map((note, rowIndex) => {
              const { isPinned } = note;
              return (
                <DataRowGrid
                  {...convertNoteToActivity(note)}
                  key={note.id || rowIndex}
                  divider={rowIndex > 0}
                  secondaryAction={
                    <Typography component="p" variant="body2">
                      <Box
                        data-testid="editInternalNoteButton"
                        component="span"
                        sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                        onClick={() => onTriggerEditNote(note)}
                      >
                        {t('Edit note')}
                      </Box>
                    </Typography>
                  }
                  tertiaryAction={
                    <IconButton
                      data-testid="note-pin-button"
                      disabled={loading}
                      sx={{
                        background: isPinned ? '#673AB7' : grey[100],
                        ...(isPinned && { color: '#fff' }),
                      }}
                      onClick={() => togglePinNote(note)}
                    >
                      <PinIcon />
                    </IconButton>
                  }
                />
              );
            })}
        </Box>
      </TabbedCard>
      <AddEditNoteModal
        open={addEditNoteModalOpened}
        modalTitle={modalTitle}
        onSubmit={onSubmitNote}
        onClose={onAddEditNoteModalToggle}
        note={isReplying ? null : currentNote}
      />
    </>
  );
}
