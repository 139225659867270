import { IMessage } from '../types/messages';
import { users } from './users';
import { userSharedFiles } from './userSharedFiles';

const user1 = users[0];
const user2 = users[1];

const fakeUser = {
  id: '045f1f77bcf86cd20485720',
  name: 'Dan Miles',
  email: 'dan_miles@example.com',
  studies: [
    {
      name: 'Colon cancer',
      role: 'Researcher',
      permissions: [
        'See profiles',
        'Edit profiles',
        'Study modules',
        'PROs',
        'Active data readings',
        'Telephone assessments',
      ],
    },
    {
      name: 'CoVax study',
      role: 'Lead Researcher',
      permissions: [
        'See profiles',
        'Edit profiles',
        'Study modules',
        'PROs',
        'Active data readings',
        'Telephone assessments',
      ],
    },
  ],
  role: 'Researcher',
  status: 'Pending',
};

const mockMessage1: IMessage = {
  id: '94jd82lndjsn23532',
  content: 'Some text that was sent in the last message so that it can be shown truncated',
  user: user1 || fakeUser,
};

const mockMessage2: IMessage = {
  id: '94jd82lndjsn23532',
  content: 'Response here',
  attachment: userSharedFiles[1],
  user: user2 || fakeUser,
};

const mockMessage3: IMessage = {
  id: '94jd82lndjsn23532',
  content: 'Response here',
  attachment: userSharedFiles[2],
  user: user2 || fakeUser,
};

const mockMessage4: IMessage = {
  id: '94jd82lndjsn23532',
  content: 'Response here',
  attachment: userSharedFiles[0],
  user: user1 || fakeUser,
};

export const userMessage = mockMessage3;

export const userMessages: IMessage[] = [mockMessage1, mockMessage2, mockMessage3, mockMessage4];
