import _ from 'lodash';
import { SVGProps } from 'react';
import duration from 'dayjs/plugin/duration';
import { TOptions } from 'i18next';
import { dayjs } from '../dayjs';
import { FitbitChartsDataQuery } from '../../graphql';
import GolfSvg from '../../components/atoms/icons/Golf';
import HikingSvg from '../../components/atoms/icons/Hiking';
import DistanceSvg from '../../components/atoms/icons/Distance';
import PilatesSvg from '../../components/atoms/icons/Pilates';
import RunningSvg from '../../components/atoms/icons/Running';
import SpinningSvg from '../../components/atoms/icons/Spinning';
import WeightsSvg from '../../components/atoms/icons/Weights';
import YogaSvg from '../../components/atoms/icons/Yoga';
import WalkSvg from '../../components/atoms/icons/Walk';
import TreadmillSvg from '../../components/atoms/icons/Treadmill';
import KickboxingSvg from '../../components/atoms/icons/Kickboxing';
import TennisSvg from '../../components/atoms/icons/Tennis';
import MartialArtSvg from '../../components/atoms/icons/MartialArts';
import EllipticalSvg from '../../components/atoms/icons/Elliptical';
import SwimmingSvg from '../../components/atoms/icons/Swimming';
import WorkoutSvg from '../../components/atoms/icons/Workout';
import ExerciseGenericSvg from '../../components/atoms/icons/ExerciseGeneric';

dayjs.extend(duration);

export type IDate = Date | string;

/**
 * Returns the provided date value as a formatted string (YYYY-MM-DD)
 *
 * @param {Date|string|undefined} value The optional date to format. Defaults to the current date
 */
export function getFormattedDate(value?: IDate) {
  if (value && typeof value === 'string') {
    return value;
  }

  const date = value ? new Date(value) : new Date();
  date.setDate(date.getDate());

  return date.toISOString().split('T')[0];
}

export function convertToMiles(distance: number, unit: string | null | undefined): number {
  let miles: number;

  switch (unit) {
    case 'Kilometer': {
      miles = distance * 0.621_371;
      break;
    }
    case 'Meter': {
      miles = distance * 0.000_621_371;
      break;
    }
    default: {
      throw new Error(`Invalid unit: ${unit}`);
    }
  }

  return miles;
}

export function formatMinutes(minutes: number) {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hrs === 0) {
    return `${mins}min`;
  }
  if (mins === 0) {
    return `${hrs}hr`;
  }
  return `${hrs}hr ${mins}min`;
}

const validAverage = (value: number) => value > 0 && !Number.isNaN(value);

export const getAvergeFitbitData = (data: FitbitChartsDataQuery | undefined) => {
  const fitbitData = data?.fitbitChartsData;

  const averageResult = {
    steps: '-',
    distance: '-',
    exercise: '-',
    activity: '-',
    sleep: '-',
    heartRate: '-',
  };

  // Average Steps
  const stepsLength = _.filter(fitbitData?.steps, (o) => Number(o.value) > 0).length;
  const averageSteps = _.sumBy(fitbitData?.steps, (o) => Number(o.value)) / stepsLength;
  if (validAverage(averageSteps)) {
    averageResult.steps = Math.round(averageSteps).toLocaleString();
  }

  // Average Distance
  const distanceLength = _.filter(fitbitData?.distance, (o) => Number(o.value) > 0).length;
  const averageDistance =
    _.sumBy(fitbitData?.distance, (o) => convertToMiles(Number(o.value), 'Kilometer')) /
    distanceLength;
  if (validAverage(averageDistance)) {
    averageResult.distance = `${averageDistance.toFixed(1)}mi`;
  }

  // Average Exercise
  const exercises = _.filter(fitbitData?.activity, (o) => o.duration > 0);
  const groupedExercises = _.groupBy(exercises, (o) => dayjs(o.date).format('MM/DD'));
  const exerciseData = _.sumBy(fitbitData?.activity, (o) => o.duration) / _.size(groupedExercises);

  if (validAverage(exerciseData)) {
    averageResult.exercise = Math.round(exerciseData).toLocaleString();
  }

  // Average Activity
  const activityData = fitbitData?.activity.flatMap((o) => o.activityLevel || []);
  const activityLength = _.groupBy(fitbitData?.activity, (o) => dayjs(o.date).format('MM/DD'));
  const averageActivityData = _.sumBy(activityData, (o) => o.minutes) / _.size(activityLength);

  if (validAverage(averageActivityData)) {
    averageResult.activity = Math.round(averageActivityData).toLocaleString();
  }

  // Sleep Data
  const sleepData = fitbitData?.sleep.flatMap((o) => o.data || []);
  const sleepLength = _.filter(fitbitData?.sleep, (o) => o.duration > 0).length;

  const averageSleep = _.sumBy(sleepData, (o) => Math.floor(o.duration / 60_000)) / sleepLength;

  if (validAverage(averageSleep)) {
    const sleepMins = Math.round(averageSleep);
    const sleepFormat = sleepMins < 60 ? 'm[m]' : 'H[h] m[m]';
    averageResult.sleep = dayjs.duration(sleepMins, 'minutes').format(sleepFormat);
  }

  // Heart Rate
  const heartRateData = fitbitData?.heartRate || [];
  const heartRateLength = _.filter(heartRateData, (o) => o.restingHeartRate > 0).length;
  const averageHeartRate = _.sumBy(heartRateData, (o) => o.restingHeartRate) / heartRateLength;

  if (validAverage(averageHeartRate)) {
    averageResult.heartRate = Math.round(averageHeartRate).toLocaleString();
  }

  return averageResult;
};

export const getActivityDuration = (
  data: FitbitChartsDataQuery | undefined,
  label: string,
  dataKey: string | number | undefined,
) => {
  const exerciseData = data?.fitbitChartsData.activity || [];
  const currentTooltipData = _.filter(
    exerciseData,
    (o) => dayjs(o.date).format('MM/DD') === label && o.name === dataKey,
  );
  let duration = 0;
  const timeRanges = currentTooltipData.map((item) => {
    const itemStartTime = item.startTime.split('.')[0];
    const startTime = dayjs.utc(itemStartTime).format('h:mm A');
    const endTime = dayjs.utc(itemStartTime).add(item.duration, 'minutes').format('h:mm A');
    duration += item.duration;

    const heartRate = item.averageHeartRate;

    if (heartRate) {
      return `${startTime} to ${endTime} / ${heartRate} bpm`;
    }

    return `${startTime} to ${endTime}`;
  });

  return { timeRanges, duration };
};

export const getSleepDuration = (
  data: FitbitChartsDataQuery | undefined,
  date: string,
  dataKey: string | number | undefined,
) => {
  const sleepData = data?.fitbitChartsData.sleep || [];
  const currentDateData = _.filter(sleepData, (o) => dayjs.utc(o.date).format('MM/DD') === date);
  const currentData = currentDateData.flatMap((o) => o.data);
  const currentLevelData = currentData.flatMap((o) => o?.levels?.data) || [];

  const currentLevel = _.filter(currentLevelData, (o) => o.level === dataKey);

  const totalDurationInMins = _.sumBy(currentLevel, (o) => o.seconds / 60);

  const currentDayTotalDuration = _.sumBy(currentDateData, (o) => o.duration);

  const timeRanges = currentData.map((item) => {
    const startTime = dayjs.utc(item.startTime).format('h:mm A');
    const endTime = dayjs.utc(item.endTime).format('h:mm A');

    return `${startTime} to ${endTime}`;
  });

  return { timeRanges, totalDurationInMins, currentDayTotalDuration };
};

export const colorGenerator = () => {
  const red = Math.floor(Math.random() * 128 + 128).toString(16);
  const green = Math.floor(Math.random() * 128 + 128).toString(16);
  const blue = Math.floor(Math.random() * 128 + 128).toString(16);
  return `#${red}${green}${blue}`;
};

export const convertMinutesToHHMMSS = (totalMinutes: number) => {
  // Convert total minutes to total seconds
  const totalSeconds = totalMinutes * 60;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  // Pad each component to ensure it is two digits long
  const hoursPadded = String(hours).padStart(2, '0');
  const minutesPadded = String(minutes).padStart(2, '0');
  const secondsPadded = String(seconds).padStart(2, '0');

  // Format the time as HH:MM:SS
  return `${hoursPadded}:${minutesPadded}:${secondsPadded}`;
};

export const fitbitActivitySvgMap: {
  [key: string]: (properties: SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  distance: DistanceSvg,
  golf: GolfSvg,
  hiking: HikingSvg,
  pilates: PilatesSvg,
  running: RunningSvg,
  spinning: SpinningSvg,
  weights: WeightsSvg,
  weightlifting: WeightsSvg,
  yoga: YogaSvg,
  walk: WalkSvg,
  treadmill: TreadmillSvg,
  kickboxing: KickboxingSvg,
  tennis: TennisSvg,
  'martial arts': MartialArtSvg,
  elliptical: EllipticalSvg,
  swim: SwimmingSvg,
  workout: WorkoutSvg,
  default: ExerciseGenericSvg,
};

export const formatMiles = (
  value: number,
  t: (key: string, options?: TOptions | undefined) => string,
) => {
  return value === 1 ? `${value} ${t('mile')}` : `${value} ${t('miles')}`;
};

const boldColorSeeds: number[] = [30, 90, 150, 210, 270, 330, 390, 450, 510, 570, 630, 690];

export function generateRandomBoldColor(): string {
  // Choose a random seed from the array
  const seedIndex = Math.floor(Math.random() * boldColorSeeds.length);
  const seedHue = Number(boldColorSeeds[seedIndex]);

  // Generate random variations around the seed hue
  const hue = seedHue + Math.floor(Math.random() * 60) - 30; // 30 degrees variation
  const saturation = Math.floor(Math.random() * 60) + 40; // 40 - 100 (more variation)
  const lightness = Math.floor(Math.random() * 30) + 50; // 50 - 80 (brighter range)

  // Ensure hue stays within 0-360 range
  const adjustedHue = (hue + 360) % 360;

  // Return the color in HSL format
  return `hsl(${adjustedHue}, ${saturation}%, ${lightness}%)`;
}
