import { HTMLAttributes, ReactElement, useCallback } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';

import { theme } from '../../../theme';

interface Properties extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  label?: string;
  value?: string;
  type?: string;
  index?: number;
  children?: ReactElement[] | ReactElement;
  onSelect?: (event: any, index?: number) => void;
  dataTest?: string;
  sx?: any;
  disableLabelColor?: boolean;
}

const StyledSelect = styled(Select)(() => ({
  'label + &': {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: '1rem',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    position: 'relative',
  },
  'label + &.Mui-focused': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
  ':before': {
    border: '0 !important',
  },
}));

export function SelectField({
  disabled,
  label,
  value,
  placeholder,
  type,
  index,
  children,
  onSelect,
  dataTest,
  sx,
  disableLabelColor,
}: Properties): ReactElement<Properties> {
  const onChange = useCallback(
    (event: any) => {
      if (onSelect) {
        onSelect(event, index);
      }
    },
    [index, onSelect],
  );
  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
      <InputLabel
        shrink
        sx={{
          ml: '2px',
          transform: 'scale(1)',
          color:
            disabled && disableLabelColor
              ? theme.palette.text.disabled
              : theme.palette.common.black,
          fontWeight: 500,
        }}
      >
        {label}
      </InputLabel>
      <StyledSelect
        disabled={disabled}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        displayEmpty
        data-testid={dataTest}
        sx={sx}
      >
        {children}
      </StyledSelect>
    </FormControl>
  );
}
