import { ReactElement } from 'react';

export type MediaRecorderRenderProperties = {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  mediaBlob: Blob | null;
  mediaBlobUrl: string | null;
  status: MediaRecorderStatusMessages;
  isAudioMuted: boolean;
  previewVideoStream: MediaStream | null;
  previewAudioStream: MediaStream | null;
  clearBlobUrl: () => void;
  hasPermission: boolean;
  getMediaAccess: () => Promise<void>;
};

export type MediaRecorderHookProperties = {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  screen?: boolean;
  onStop?: (url: string, blob: Blob) => void;
  onStart?: () => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: MediaRecorderOptions;
  customMediaStream?: MediaStream;
  stopStreamsOnStop?: boolean;
  askPermissionOnMount?: boolean;
};

export type MediaRecorderProperties = MediaRecorderHookProperties & {
  render: (properties: MediaRecorderRenderProperties) => ReactElement;
};

export type MediaRecorderStatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped'
  | 'paused';

export enum MediaRecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}

export type MediaPermissionName =
  | 'microphone'
  | 'camera'
  | 'geolocation'
  | 'notifications'
  | 'persistent-storage'
  | 'push'
  | 'screen';
