import { v4 } from 'uuid';
import { IQuestion } from '../../../types/intervention';
import JournalQuestionTypes from '../EditableQuestion/JournalQuestionTypes';
import { AssessmentQuestionEntity, AssessmentQuestionInputType } from '../../../graphql';

function areArraysEqual(array1: any[], array2: any[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (const [index, element] of array1.entries()) {
    if (element !== array2[index]) {
      return false;
    }
  }

  return true;
}

export function updateQuestionObject(
  question: IQuestion,
  newQuestion: IQuestion,
): IQuestion | null {
  const cleanedNewQuestion = Object.fromEntries(
    Object.entries(newQuestion).filter(([_, v]) => {
      if (_ === 'tooltip') return true;
      return v != null;
    }),
  );

  if (question.id === cleanedNewQuestion.id) {
    const {
      id,
      order,
      identifier,
      index,
      icon,
      label,
      type,
      category,
      required,
      options,
      value,
      automation,
      meta,
      questions,
      tooltip,
      variableName,
      ...rest
    } = cleanedNewQuestion;
    const response = { ...question };

    if (id) response.id = id;
    response.order = order;
    response.questions = questions;
    response.variableName = variableName || '';
    response.questions = questions;
    if (index) response.index = index;
    if (identifier) response.identifier = identifier;
    if (icon) response.icon = icon;
    if (label !== undefined) response.label = label;
    if (type) response.type = type;
    if (category) response.category = category;
    if (required) response.required = required;
    if (options) response.options = options;
    if (value) response.value = value;
    if (automation) response.automation = automation;
    if (meta) response.meta = meta;
    if (tooltip !== undefined) response.tooltip = tooltip;
    if (rest) response.meta = { ...response.meta, ...rest };

    return response;
  }

  if (question.questions) {
    const updatedQuestions = question.questions.map((subQuestion) => {
      const updatedSubQuestion = updateQuestionObject(subQuestion, {
        label: '',
        type: '',
        ...cleanedNewQuestion,
      });

      return updatedSubQuestion || subQuestion;
    });

    if (!areArraysEqual(updatedQuestions, question.questions)) {
      return { ...question, questions: updatedQuestions };
    }
  }

  return null;
}

export function copyQuestionWithNewIds(question: IQuestion): IQuestion {
  const newQuestion: IQuestion = {
    ...question,
    id: v4(),
    tooltip: question.tooltip
      ? {
          ...question.tooltip,
          id: v4(),
        }
      : undefined,
    questions: question?.questions?.map((subQuestion) => copyQuestionWithNewIds(subQuestion)),
  };

  return newQuestion;
}

export function duplicateQuestion(question: IQuestion, dup: IQuestion): IQuestion {
  if (question.questions) {
    const updatedQuestions = question.questions.map((subQuestion, index) => {
      if (subQuestion.id === dup.id) {
        const newDup = copyQuestionWithNewIds(dup);
        newDup.order = index + 1;
        return [subQuestion, newDup];
      }
      const updatedSubQuestion = duplicateQuestion(subQuestion, dup);
      return updatedSubQuestion ? [updatedSubQuestion] : [subQuestion];
    });

    const flattenedUpdatedQuestions = updatedQuestions.flat();

    const updatedQuestion = { ...question, questions: flattenedUpdatedQuestions };

    // Update the ordering of questions after the position of the duplicated question
    if (updatedQuestion.questions) {
      const startIndex = updatedQuestion.questions.findIndex((q) => q.id === dup.id);
      if (startIndex !== -1) {
        const updatedQuestionsAfterDup = updatedQuestion.questions.slice(startIndex + 1);
        const updatedQuestionsWithOrder = updatedQuestionsAfterDup.map((q, index) => ({
          ...q,
          order: startIndex + index + 1, // Assign updated order
        }));
        updatedQuestion.questions.splice(
          startIndex + 1,
          updatedQuestionsAfterDup.length,
          ...updatedQuestionsWithOrder,
        );
      }
    }

    return updatedQuestion;
  }

  return question;
}

export function deleteQuestion(question: IQuestion, del: IQuestion): IQuestion {
  if (question.questions) {
    const updatedQuestions = question.questions.filter((subQuestion) => subQuestion.id !== del.id);

    const updatedQuestionsRecursive = updatedQuestions.map((subQuestion, index) => {
      const updatedSubQuestion = deleteQuestion(subQuestion, del);
      if (updatedSubQuestion) {
        updatedSubQuestion.order = index; // Update the order based on the new index
      }
      return updatedSubQuestion || subQuestion;
    });

    return { ...question, questions: updatedQuestionsRecursive };
  }

  return question;
}

export const DEFAULT_RESISTANCE_VARIATION_OPTIONS = [
  '1-Incline',
  '2-Decline',
  '3-Wide',
  '4-Narrow',
  '5-Tricep',
];

export const DEFAULT_RESISTANCE_RESISTANCE_OPTIONS = ['1-Yellow', '2-Orange', '3-Green', '4-Black'];

export const DEFAULT_RESISTANCE_EFFORTS_OPTIONS = [
  '0-0',
  '1-1',
  '2-2',
  '3-3',
  '4-4',
  '5-5',
  '6-6',
  '7-7',
  '8-8',
  '9-9',
  '10-10',
];

const feelingQuestion = (order: number) => ({
  id: v4(),
  label: 'Feeling question',
  type: JournalQuestionTypes.resistanceFeeling.value,
  meta: { feelingStart: true, feelingEnd: true },
  order,
});

export const getsDefaultResistanceBandQuestions = () => {
  return [
    {
      id: v4(),
      label: 'Variation',
      type: JournalQuestionTypes.resistanceSelect.value,
      options: DEFAULT_RESISTANCE_VARIATION_OPTIONS,
      order: 0,
    },
    {
      id: v4(),
      label: 'Bands workout question',
      type: JournalQuestionTypes.resistanceRepsSets.value,
      meta: { reps: 0, sets: 0 },
      order: 1,
    },
    {
      id: v4(),
      label: 'Resistance',
      type: JournalQuestionTypes.resistanceSelect.value,
      options: DEFAULT_RESISTANCE_RESISTANCE_OPTIONS,
      meta: { type: 'resistance-input' },
      order: 2,
    },
    {
      id: v4(),
      label: 'Effort',
      type: JournalQuestionTypes.resistanceSelect.value,
      options: DEFAULT_RESISTANCE_EFFORTS_OPTIONS,
      order: 3,
    },
    { ...feelingQuestion(4) },
  ];
};

export const getDefaultResistanceBodyWeightQuestions = () => {
  return [
    {
      id: v4(),
      label: 'Variation',
      type: JournalQuestionTypes.resistanceSelect.value,
      options: DEFAULT_RESISTANCE_VARIATION_OPTIONS,
      order: 0,
    },
    {
      id: v4(),
      label: 'Workout question',
      type: JournalQuestionTypes.resistanceRepsSets.value,
      meta: { reps: 0, sets: 0 },
      order: 1,
    },
    {
      id: v4(),
      label: 'Effort',
      type: JournalQuestionTypes.resistanceSelect.value,
      options: DEFAULT_RESISTANCE_EFFORTS_OPTIONS,
      order: 2,
    },
    { ...feelingQuestion(3) },
  ];
};

export const getResistanceExerciseCategoryQuestions = () => {
  return [
    {
      id: v4(),
      label: '',
      type: JournalQuestionTypes.resistanceBands.value,
      questions: getsDefaultResistanceBandQuestions(),
      order: 0,
    },
  ];
};

export function getNewQuestionProperties(type: string): any {
  const result: any = { type, options: null, questions: null };
  if (
    type === JournalQuestionTypes.resistance.value ||
    type === JournalQuestionTypes.flexibility.value ||
    type === JournalQuestionTypes.functional.value
  ) {
    result.meta = { hasOwnNavigation: true };
    result.questions = [{ id: v4(), label: '', type: '', order: 0 }];
  }

  if (type === JournalQuestionTypes.question.value) {
    result.questions = [{ id: v4(), label: '', type: '', order: 0 }];
  }

  if (type === JournalQuestionTypes.question.value) {
    result.questions = [{ id: v4(), label: '', type: '', order: 0 }];
  }

  if (type === JournalQuestionTypes.resistanceExerciseHeader.value) {
    result.meta = { hasOwnNavigation: true };
    result.questions = [
      {
        id: v4(),
        label: '',
        type: JournalQuestionTypes.resistanceExerciseCategory.value,
        meta: { hasOwnNavigation: true },
        order: 0,
        questions: getResistanceExerciseCategoryQuestions(),
      },
    ];
  }

  if (type === JournalQuestionTypes.resistanceBands.value) {
    result.questions = getsDefaultResistanceBandQuestions();
  }

  if (type === JournalQuestionTypes.resistanceExerciseHeader.value) {
    result.meta = { hasOwnNavigation: true };
    result.questions = [
      {
        id: v4(),
        label: '',
        type: JournalQuestionTypes.resistanceExerciseCategory.value,
        meta: { hasOwnNavigation: true },
        questions: getResistanceExerciseCategoryQuestions(),
      },
    ];
  }

  if (type === JournalQuestionTypes.resistanceBands.value) {
    result.questions = getsDefaultResistanceBandQuestions();
  }

  if (
    [
      JournalQuestionTypes.resistanceBodyWeight.value,
      JournalQuestionTypes.resistanceFreeWeight.value,
    ].includes(type)
  ) {
    result.questions = getDefaultResistanceBodyWeightQuestions();
  }

  return result;
}

export function extractQuestionIds(questions: AssessmentQuestionEntity[]): string[] {
  const ids: string[] = [];

  function recursiveExtract(ques: AssessmentQuestionEntity[]) {
    for (const question of ques) {
      ids.push(question.id || '');
      if (question?.tooltip) ids.push(question?.tooltip.id || '');

      if (question.questions) {
        recursiveExtract(question.questions);
      }
    }
  }

  recursiveExtract(questions);
  return ids.filter((id) => id !== '');
}

export function getQuestionWithoutIds(
  question: IQuestion,
  idsToNotRemove?: string[] | null,
): IQuestion {
  const { id, ...questionWithoutId } = question;
  const { id: tooltipId, ...tooltipWithoutId } = question.tooltip || {};
  const updatedQuestions = questionWithoutId?.questions?.map((subQuestion) =>
    getQuestionWithoutIds(subQuestion, idsToNotRemove),
  );

  return {
    ...(id && idsToNotRemove?.includes(id) ? question : questionWithoutId),
    tooltip:
      question?.tooltip?.id && idsToNotRemove?.includes(question?.tooltip?.id)
        ? question?.tooltip
        : Object.keys(tooltipWithoutId).length === 0
        ? undefined
        : tooltipWithoutId,
    questions: updatedQuestions,
  };
}

export function transformQuestion(question: IQuestion): AssessmentQuestionInputType {
  const { category, options, questions, conditions, ...rest } = question;

  const transformedCategory = {
    name: category?.name,
    id: category?.id || null,
  };

  const transformedConditions = conditions?.toString();

  const transformedQuestions = questions?.map(transformQuestion);

  return {
    ...rest,
    category: transformedCategory,
    options: options?.toString(),
    conditions: transformedConditions,
    questions: transformedQuestions,
  };
}
export function sortQuestions(question: AssessmentQuestionInputType): AssessmentQuestionInputType {
  const sortedQuestions = question.questions
    ? [...question.questions].sort((q1, q2) => (q1.order || 0) - (q2.order || 0))
    : [];

  const sortedNestedQuestions = sortedQuestions.map((q) => {
    if (q.questions && q.questions.length > 0) {
      return sortQuestions(q);
    }
    return q;
  });

  return { ...question, questions: sortedNestedQuestions };
}
