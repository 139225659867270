import { Box, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface CheckboxFieldProperties {
  index?: number;
  options?: string[] | null;
  label?: string;
  value?: string[];
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange?: (checkedValues: string[]) => void;
}

export const CheckboxField = ({
  index,
  label,
  options,
  required,
  value,
  showBoldLabel,
  onChange,
  hideLabel = false,
}: CheckboxFieldProperties) => {
  const handleCheckboxChanged = (isChecked: boolean, option: string) => {
    if (onChange) {
      onChange(
        isChecked
          ? value
            ? [...value, option]
            : [option]
          : value
          ? value.filter((item) => item !== option)
          : [],
      );
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        {!hideLabel && (
          <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
            {index}
            {index && '.'} {label} {required && '*'}
          </Typography>
        )}
        {options?.map((option, index_) => (
          <FormControlLabel
            key={index_}
            control={
              <Checkbox
                sx={{ color: grey[300] }}
                checked={value?.includes(option)}
                onChange={(event) => handleCheckboxChanged(event.target.checked, option)}
              />
            }
            label={option}
            sx={{ ml: 0 }}
          />
        ))}
      </FormGroup>
    </Box>
  );
};
