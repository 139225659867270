import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  GridContainer,
  RecordingUsersListInnerSection,
  RecordingUsersListSection,
  RecordingsContainer,
  StyledPaper,
} from './RecordingSection.styles';
import { useCurrentUser, useUser } from '../../../../states';
import { UserEntity } from '../../../../graphql';
import { UserRoles } from '../../../../constants/users';
import { RecordingDetails } from '../RecordingDetails';
import { UsersRecordings } from '../UserRecordings';
import { Recordings } from '../Recordings';

interface Properties {
  readonly studyId: number;
  readonly preSelectedUser?: UserEntity;
}

export const RecordingSection: React.FC<Properties> = ({ studyId, preSelectedUser }) => {
  const { checkUserRole } = useUser();
  const { data: userData } = useCurrentUser();
  const [_searchParameters, setSearchParameters] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);

  useEffect(() => {
    if (preSelectedUser) setSelectedUser(preSelectedUser);
  }, [preSelectedUser]);

  const handleSelectUser = useCallback(
    (user: UserEntity | null) => {
      if (user && user.id) setSearchParameters({ userId: user.id });
      setSelectedUser(user);
    },
    [setSearchParameters],
  );

  const isStaff =
    checkUserRole(userData?.user, UserRoles.ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.SUPER_ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.COACH);

  return (
    <StyledPaper>
      <GridContainer container>
        {isStaff && (
          <RecordingUsersListSection item xs={selectedUser ? 4 : 12}>
            <RecordingUsersListInnerSection>
              <UsersRecordings
                hasTabs={false}
                hasSort={false}
                selectedUser={selectedUser}
                onSelectUser={handleSelectUser}
                studyId={studyId}
              />
            </RecordingUsersListInnerSection>
          </RecordingUsersListSection>
        )}
        {(selectedUser || checkUserRole(userData?.user, UserRoles.PARTICIPANT)) && (
          <RecordingsContainer
            item
            xs={isStaff ? 6 : 12}
            sx={{
              borderColor: (theme) => theme.palette.divider,
            }}
          >
            <Recordings user={selectedUser ?? undefined} />
          </RecordingsContainer>
        )}
        {isStaff && !!selectedUser && (
          <Grid item xs={2}>
            <RecordingDetails user={selectedUser} />
          </Grid>
        )}
      </GridContainer>
    </StyledPaper>
  );
};
