export const formatPercentage = (value: number) => (value > 0 ? `+${value}%` : `${value}%`);

export const stringAvatar = (name: string | undefined) => {
  if (!name || name.trim() === '') return '';

  const firstNameInitial = name.trim().split(' ')[0]?.[0] || '';
  const lastNameInitial = name.trim().split(' ')[1]?.[0] || '';

  return `${firstNameInitial}${lastNameInitial}`;
};

export function tryParseNumber(value: any) {
  const parsedValue = Number(value);
  return Number.isNaN(parsedValue) ? value : parsedValue;
}

export function isNumericString(value: string) {
  // Use a regular expression to check if the string is a valid number
  return /^-?(?:\d+(?:\.\d+)?|\.\d+)$/.test(value);
}
