export const participantInternalNotes = [
  {
    id: '007f1f77bcf86cd799439011',
    message: 'Participant said he feels much better after...',
    description: 'Related to the Phone call on Tue 24, June',
    date: 'Tue, 25 Jun',
    badges: ['Treatment'],
    recommendations: [
      {
        id: '117f1f77bcf86cd799439011',
        user: {
          id: '045f1f77bcf86cd20485720',
          name: 'Dan Miles',
          email: 'dan_miles@example.com',
          studies: [
            {
              name: 'Colon cancer',
              role: 'Researcher',
              permissions: [],
            },
            {
              name: 'CoVax study',
              role: 'Researcher',
              permissions: [],
            },
          ],
          role: 'Researcher',
          status: 'Pending',
        },
        date: 'Tue, 25 Jun',
        content:
          'If that doesn’t help, you can also try Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
      },
      {
        id: '227f1f77bcf86cd799439011',
        user: {
          name: 'Dan Miles',
          email: 'dan_miles@example.com',
          studies: [
            {
              name: 'Colon cancer',
              role: 'Researcher',
              permissions: [],
            },
            {
              name: 'CoVax study',
              role: 'Researcher',
              permissions: [],
            },
          ],
          role: 'Researcher',
          status: 'In progress',
        },
        date: 'Wed, 12 Oct',
        content:
          'If that doesn’t help, you can also try Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
      },
    ],
  },
  {
    id: '107f1f77bcf86cd799439011',
    message: 'David reported about his bowel ache at...',
    description: 'Related to the issue #41',
    date: 'Mon, 22 Jun',
    badges: ['Report'],
    recommendations: [
      {
        id: '337f1f77bcf86cd799439011',
        user: {
          name: 'Dan Miles',
          email: 'dan_miles@example.com',
          studies: [
            {
              name: 'Colon cancer',
              role: 'Researcher',
              permissions: [],
            },
            {
              name: 'CoVax study',
              role: 'Researcher',
              permissions: [],
            },
          ],
          role: 'Researcher',
          status: 'In progress',
        },
        date: 'Fri, 11 Mar',
        content:
          'If that doesn’t help, you can also try Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
      },
    ],
  },
];
