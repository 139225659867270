import { CoachingEventsQuery } from '../../../../graphql';
import { EventStatuses } from './type';

type CoachingEvents = CoachingEventsQuery['coachingEvents'][0];

const PRIORITY: Record<EventStatuses, number> = {
  [EventStatuses.COMPLETED]: 1, // High priority
  [EventStatuses.INCOMPLETE]: 2, // Medium priority
  [EventStatuses.STARTED]: 3, // Low priority
};

export function getLowestPriorityStatus(events: CoachingEvents[]): string {
  let lowestPriority = Number.MIN_SAFE_INTEGER;
  let lowestPriorityStatus = '';

  for (const event of events) {
    // Default to STARTED if status is null
    const status = event.status ? (event.status as EventStatuses) : EventStatuses.STARTED;
    if (PRIORITY[status] > lowestPriority) {
      lowestPriority = PRIORITY[status];
      lowestPriorityStatus = status;
    }
  }

  // If no status is found (which should not happen with the null check), default to STARTED
  return lowestPriorityStatus || EventStatuses.STARTED;
}
