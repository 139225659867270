import { HTMLAttributes, ReactElement, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';
import { Tabs } from '../Tabs';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

export interface ITab {
  label: string;
  data: ReactElement | ReactElement[];
}

interface Properties extends HTMLAttributes<HTMLElement> {
  ariaLabel: string;
  dataTest?: string;
  sx?: object;
  contentSx?: object;
  secondaryAction?: ReactElement;
  tertiaryAction?: ReactElement;
  tabs?: Array<ITab>;
  children?: ReactElement;
  sideChildren?: ReactElement | null;
  tabVariant?: 'fullWidth' | 'standard' | 'scrollable' | undefined;
  showTabsOnTop?: boolean;
  displayTabs?: boolean;
}

export function TabbedCard({
  dataTest,
  ariaLabel,
  tabs,
  secondaryAction,
  tertiaryAction,
  children,
  sideChildren,
  sx,
  title,
  tabVariant,
  showTabsOnTop,
  displayTabs = true,
  contentSx,
}: Properties): ReactElement<Properties> {
  const theme = useTheme();
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:650px)');
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (_event: any, index: any) => setTabIndex(index);
  const onTabInputChange = (event: any) => setTabIndex(event.target.value);
  const onTabSwipe = (index: any) => setTabIndex(index);

  const TabsBox = displayTabs && tabs && (
    <Box>
      {sm ? (
        <Tabs
          variant={tabVariant || 'fullWidth'}
          value={tabIndex}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label={ariaLabel}
          onChange={onTabChange}
        >
          {tabs.map(({ label }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={index} value={index} label={t(label)} />
          ))}
        </Tabs>
      ) : (
        <Select value={tabIndex} onChange={onTabInputChange}>
          {tabs.map(({ label }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={index}>
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );

  return (
    <Paper sx={{ position: 'relative', ...sx }} data-testid={dataTest}>
      <Stack spacing={2} sx={{ pb: 5 }} flexGrow={1}>
        {/*  Section header */}
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {title && (
            <Typography
              component="p"
              flexGrow={1}
              sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
          )}
          {showTabsOnTop && TabsBox}
          {secondaryAction}
        </Stack>

        <Stack flexGrow={1}>
          <Box flexGrow={1}>
            {(children && <Box sx={{ pt: 1, ...contentSx }}>{children}</Box>) ||
              (tabs && (
                <>
                  {!showTabsOnTop && TabsBox}
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tabIndex}
                    onChangeIndex={onTabSwipe}
                    enableMouseEvents
                    style={contentSx}
                  >
                    {tabs.map(({ data }, dataTabIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Box key={dataTabIndex}>{dataTabIndex === tabIndex && data}</Box>
                    ))}
                  </SwipeableViews>
                </>
              ))}
          </Box>
        </Stack>
        {tertiaryAction && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            justifyContent="center"
          >
            {tertiaryAction}
          </Box>
        )}
      </Stack>
      {sideChildren || ''}
    </Paper>
  );
}
