import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { ENDPOINTS } from '../../constants';
import { IUser, IUsers, IUserStudy } from '../../types/user';
import { useAxios } from '../../hooks';

export type IUsersContextType = {
  users: IUsers;
  inviteUsers: (users: IUser[]) => void;
  replaceStudies: (studies: IUserStudy[], user: IUser) => void;
  deleteUsers: (names: string[]) => void;
};

export const UsersContext = createContext<IUsersContextType | null>(null);

export const UsersProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USERS });
  const [usersData, setUsersData] = useState<IUser[]>([]);

  useEffect(() => {
    if (usersData?.length === 0) {
      setUsersData(data || []);
    }
  }, [data, usersData?.length]);

  const inviteUsers = useCallback(
    (invitees: IUser[]) => {
      setUsersData([...usersData, ...invitees]);
    },
    [usersData, setUsersData],
  );

  const replaceStudies = useCallback(
    (studies: IUserStudy[], user: IUser) => {
      setUsersData(
        usersData.map((userData) =>
          userData.email === user.email ? { ...userData, studies } : userData,
        ),
      );
    },
    [usersData, setUsersData],
  );

  const deleteUsers = useCallback(
    (names: string[]) => {
      setUsersData(usersData.filter((user) => !names.includes(user.name)));
    },
    [usersData, setUsersData],
  );

  const contextValue = useMemo(
    (): IUsersContextType => ({
      users: usersData || [],
      inviteUsers,
      replaceStudies,
      deleteUsers,
    }),
    [usersData, inviteUsers, replaceStudies, deleteUsers],
  );

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
};

export const useUsers = () => useContext(UsersContext);
