import { AppBar, Box, LinearProgress, Stack, Toolbar, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { AssessmentQuestionEntity, AssessmentQuestionInputType } from '../../../graphql';

import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import { theme } from '../../../theme';
import { DialogCloseButton } from '../../atoms';
import QuestionCategory from './QuestionCategory';
import { QuestionList } from './QuestionList';
import QuestionNavigation from './QuestionNavigation';

interface Properties {
  id: string;
  title: string;
  i18nKey?: string;
  progressBarValue?: number;
  questions?: AssessmentQuestionInputType[] | null;
  scrollableList?: boolean;
  resistanceAnswers?: IResistanceIntakeAnswers[] | [];
  showResistanceAnswerPreview?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  onSubmit?: (answers?: IQuestionAnswer) => void;
  onSubmitResistance?: (value?: IResistanceIntakeAnswers[]) => void;
  onSubmitCardio?: (value?: ICardioIntakeAnswer) => void;
  onFinishResistanceAnswerPreview?: () => void;
  moveToNextOnSubmit?: boolean;
  questionAnswersMap: Map<any, any>;
}

export default function QuestionNavigator({
  id,
  title,
  progressBarValue,
  questions,
  scrollableList,
  i18nKey,
  onPrev,
  onNext,
  onSubmit,
  onSubmitResistance,
  onSubmitCardio,
  moveToNextOnSubmit,
  resistanceAnswers,
  showResistanceAnswerPreview,
  onFinishResistanceAnswerPreview,
  questionAnswersMap,
}: Properties) {
  const { setActiveStepToSplash, answers, answersCollection } = useActiveJournal();

  const [questionIndex, setQuestionIndex] = useState(0);
  const [question, setQuestion] = useState(questions?.length ? questions[questionIndex] : null);
  const nbQuestions = questions?.length ?? 0;

  const handlePrevious = useCallback(() => {
    if (onPrev && questionIndex === 0) {
      onPrev();
    }

    const nextQuestionIndex = questionIndex > 0 ? questionIndex - 1 : 0;

    setQuestionIndex(nextQuestionIndex);
  }, [onPrev, questionIndex]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      if (answersCollection.length > 0 || scrollableList) {
        const final = [...answersCollection];
        if (answers) final.push(answers);
        onSubmit({
          questionId: id,
          values: final.map((f) => ({
            questionId: f?.questionId || '',
            values: typeof f?.values?.[0] === 'string' ? [''] : f?.values?.[0]?.values || [''],
          })),
        });
      } else {
        onSubmit(answers);
      }
    }
  }, [answers, answersCollection, id, onSubmit, scrollableList]);

  const handleNext = useCallback(() => {
    if (moveToNextOnSubmit) {
      handleSubmit();
    } else onNext?.();
  }, [onNext, handleSubmit, moveToNextOnSubmit]);

  const handleResistanceSubmit = useCallback(
    (value: IResistanceIntakeAnswers[]) => {
      if (onSubmitResistance) {
        onSubmitResistance(value);
      }
    },
    [onSubmitResistance],
  );

  const handleCardioInputSubmit = useCallback(
    (value: ICardioInput) => {
      if (onSubmitCardio && question?.id) {
        onSubmitCardio({ questionId: question.id, value });
      }
    },
    [onSubmitCardio, question?.id],
  );

  const handleCardioCategorySubmit = useCallback(
    (value: ICardioIntakeAnswer) => {
      if (onSubmitCardio) {
        onSubmitCardio(value);
      }
    },
    [onSubmitCardio],
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            background: 'white',
            minHeight: `56px !important`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" color={theme.palette.common.black} component="div">
            {i18nKey ? <Trans i18nKey={i18nKey}>{title}</Trans> : title}
          </Typography>
        </Toolbar>
        <DialogCloseButton onClick={setActiveStepToSplash} />
      </AppBar>
      {!!progressBarValue && (
        <LinearProgress
          sx={{ width: '100%', height: 3, background: '#FFF' }}
          value={progressBarValue}
          variant="determinate"
        />
      )}
      <Box display="flex" justifyContent="center">
        {question && (
          <Stack
            key={question.id}
            justifyContent="center"
            sx={{
              maxWidth: 700,
              p: showResistanceAnswerPreview ? '3px 0px 0px' : 3,
              width: '100%',
              height: '100%',
            }}
          >
            {question.type === 'question' && question.meta?.category ? (
              <QuestionCategory
                question={question as AssessmentQuestionEntity}
                submit={scrollableList || questionIndex === nbQuestions - 1}
                onSubmitResistance={handleResistanceSubmit}
                onSubmitCardio={handleCardioCategorySubmit}
                onPrev={handlePrevious}
                questionAnswersMap={questionAnswersMap}
              />
            ) : (
              <>
                <QuestionList
                  i18nKey={i18nKey}
                  questions={questions}
                  question={question}
                  resistanceAnswers={resistanceAnswers}
                  showResistanceAnswerPreview={showResistanceAnswerPreview}
                  onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
                  handleResistanceSubmit={handleResistanceSubmit}
                  handleCardioInputSubmit={handleCardioInputSubmit}
                  handlePrevious={handlePrevious}
                  scrollableList={scrollableList}
                  questionAnswersMap={questionAnswersMap}
                />
                {!question.meta?.hasOwnNavigation && (
                  <QuestionNavigation
                    submit={moveToNextOnSubmit ? false : !!onSubmit}
                    onPrev={handlePrevious}
                    onNext={handleNext}
                    onSubmit={handleSubmit}
                  />
                )}
              </>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
}
