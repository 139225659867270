import { Button, Container, Paper, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { DateRangePickerField } from '../../../atoms';
import { containerStyles, headerContainerStyles } from './styles';
import { ActivityType, BottomTabs } from './BottomTabs';
import { Charts } from './Charts';
import { useFitbitChartsDataQuery, useSyncFitbitDataLazyQuery } from '../../../../graphql';
import { getDateStringFromDateTime } from '../../../../utils';

interface ActivityChartsProperties {
  user: IUser;
  fitbitDevices: IFitBitDevice[];
  fitbitRefreshTokenError: boolean;
  dateRange: { fromDate: Date; toDate: Date };
  setDateRange: (range: { fromDate: Date; toDate: Date }) => void;
}

export const ActivityCharts: React.FC<ActivityChartsProperties> = ({
  user,
  fitbitDevices,
  fitbitRefreshTokenError,
  dateRange,
  setDateRange,
}) => {
  const { t } = usePreferredTranslation();
  const hasRenderedOnce = useRef(false);

  const [selectedActivity, setSelectedActivity] = useState<ActivityType>(ActivityType.Activity);

  const [dateValue, setDateValue] = useState(dateRange);

  const [syncFitbit] = useSyncFitbitDataLazyQuery();
  const { loading: isChartsLoading, data: chartsData } = useFitbitChartsDataQuery({
    variables: {
      userId: Number(user?.id),
      fromDate: getDateStringFromDateTime(dateValue.fromDate),
      toDate: getDateStringFromDateTime(dateValue.toDate),
    },
  });

  const handleDateChange = useCallback(
    (dates: any[]) => {
      setDateValue({
        fromDate: dates[0],
        toDate: dates[1],
      });
      setDateRange({ fromDate: dates[0], toDate: dates[1] });
    },
    [setDateRange],
  );

  const syncFitbitData = async () => {
    const { data } = await syncFitbit({
      variables: {
        userId: Number(user?.id),
        fromDate: getDateStringFromDateTime(dateValue.fromDate),
        toDate: getDateStringFromDateTime(dateValue.toDate),
      },
    });
    if (data?.syncFitbitData) {
      toast.success(t('Data syncing in background'));
    } else {
      toast.error(t('Something went wrong'));
    }
  };

  if (isChartsLoading && !hasRenderedOnce.current) {
    hasRenderedOnce.current = true;
    return <Skeleton variant="rectangular" height={500} />;
  }

  const device = fitbitDevices?.[0];
  const hideSyncNowButton = (!user?.fitbitAccessToken && fitbitRefreshTokenError) || !device;

  return (
    <Paper>
      <Paper
        style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
        sx={containerStyles}
      >
        <Stack sx={headerContainerStyles}>
          <Typography variant="h6">{t(selectedActivity)}</Typography>
          <div>
            {!hideSyncNowButton && (
              <Button sx={{ marginRight: '10px' }} variant="contained" onClick={syncFitbitData}>
                {t('Sync now')}
              </Button>
            )}
            <DateRangePickerField
              sx={{
                padding: '6px 12px',
                backgroundColor: '#eeeeee',
                border: 'none',
              }}
              onChange={handleDateChange}
              value={[dateValue.fromDate, dateValue.toDate]}
              showIcon
            />
          </div>
        </Stack>
        <Container sx={{ height: 400 }}>
          <Charts selectedActivity={selectedActivity} data={chartsData} />
        </Container>
      </Paper>
      <BottomTabs
        data={chartsData}
        activeTab={selectedActivity}
        setActiveTab={(tab) => setSelectedActivity(tab)}
      />
    </Paper>
  );
};
