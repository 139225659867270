import { AssessmentQuestionEntity } from '../../../../../../graphql';
import JournalQuestionTypes from '../../../../../organisms/EditableQuestion/JournalQuestionTypes';
import { ResistanceBandsInput } from './ResistanceBandsInput';
import { ResistanceBodyWeight } from './ResistanceBodyWeight';
import { ResistanceFreeWeightInput } from './ResistanceFreeWeightInput';

interface Properties {
  question: AssessmentQuestionEntity;
  parentQuestion?: AssessmentQuestionEntity;
  defaultValue?: any;
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
}

const ResistanceRepsSetsInput = ({
  parentQuestion,
  question,
  onChange,
  defaultValue,
}: Properties) => {
  switch (parentQuestion?.type) {
    case JournalQuestionTypes.resistanceBands.value: {
      return (
        <ResistanceBandsInput
          defaultValue={defaultValue}
          onChange={onChange}
          parentQuestion={parentQuestion}
        />
      );
    }
    case JournalQuestionTypes.resistanceFreeWeight.value: {
      return <ResistanceFreeWeightInput defaultValue={defaultValue} onChange={onChange} />;
    }
    case JournalQuestionTypes.resistanceBodyWeight.value: {
      return (
        <ResistanceBodyWeight question={question} defaultValue={defaultValue} onChange={onChange} />
      );
    }
    default: {
      return <div />;
    }
  }
};

export default ResistanceRepsSetsInput;
