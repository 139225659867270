import { useEffect, useState } from 'react';
import { Typography, Box, BoxProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import { dayjs } from '../../../../utils/dayjs';
import { NoteEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import { FORMATS } from '../../../../constants';

interface Properties extends BoxProps {
  note: NoteEntity;
  selected?: boolean;
}

export const Note = ({ note, selected = false, ...rest }: Properties) => {
  const [viewed, setViewed] = useState(note.viewed);

  useEffect(() => {
    if (selected || note.viewed) {
      setViewed(true);
    }
  }, [selected, note, setViewed]);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        minWidth: 0,
        background: selected ? alpha(theme.palette.primary.main, 0.12) : theme.palette.common.white,
      }}
      {...rest}
    >
      <Box sx={{ pl: 1, flexGrow: 1, minWidth: 0 }}>
        <Box
          fontWeight={viewed ? 'normal' : 'bold'}
          sx={{ display: 'flex', alignItems: 'center', pr: 3 }}
        >
          {note.title}
        </Box>
        <Typography
          variant="subtitle2"
          fontWeight={viewed ? 'normal' : 'bold'}
          color={theme.palette.grey[500]}
          sx={{ mb: 1 }}
        >
          {dayjs(note.createdAt).format(FORMATS.DATE)} ({dayjs().to(dayjs(note.createdAt))})
        </Typography>
      </Box>

      {!selected && (
        <ArrowForwardIosRoundedIcon sx={{ color: theme.palette.text.disabled, fontSize: 14 }} />
      )}
    </Box>
  );
};
