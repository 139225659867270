import { styled, Box } from '@mui/material';
import { theme } from '../../../theme';

export interface TitleRulerProperties {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  paddingTop?: number;
  paddingBottom?: number;
  marginTop?: number;
  marginBottom?: number;
  title: string;
}

export const TitleRuler = ({
  title,
  fontSize,
  fontWeight,
  paddingTop = 0,
  paddingBottom = 0,
  marginTop = 3,
  marginBottom = 1,
  color,
}: TitleRulerProperties) => {
  const RulerContent = styled('div')<Omit<TitleRulerProperties, 'title'>>((properties) => ({
    position: 'relative',
    textTransform: 'uppercase',
    fontSize: properties.fontSize || '0.75rem',
    letterSpacing: '0.06em',
    fontWeight: properties.fontWeight || '600',
    color: properties.color || theme.palette.text.secondary,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      borderBottom: '1px solid',
      borderColor: theme.palette.divider,
      width: '100%',
      marginLeft: '1rem',
    },
  }));

  return (
    <Box
      sx={{
        overflow: 'hidden',
        pt: paddingTop,
        pb: paddingBottom,
        mt: marginTop,
        mb: marginBottom,
      }}
    >
      <RulerContent color={color} fontSize={fontSize} fontWeight={fontWeight}>
        {title}
      </RulerContent>
    </Box>
  );
};
