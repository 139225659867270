import { SVGProps } from 'react';

const GolfSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g fill="#6C5DD3" clipPath="url(#a)">
      <path d="m16.478 16.53 6.83-3.94a.484.484 0 0 0 0-.837l-7.07-4.08a.478.478 0 0 0-.48 0 .484.484 0 0 0-.24.418v10.877c.31-.139.628-.264.96-.375v-2.062Z" />
      <path d="M20.4 17.956c-1.397 0-2.723.23-3.917.634v3.902a.48.48 0 0 1-.96 0v-3.53c-2.822 1.253-4.723 3.558-4.723 6.192 0 3.975 4.295 7.2 9.6 7.2 5.304 0 9.6-3.225 9.6-7.2 0-3.977-4.301-7.198-9.6-7.198Zm3.384 9.259c-.926 0-1.68-.754-1.68-1.68 0-.926.754-1.68 1.68-1.68.927 0 1.68.754 1.68 1.68 0 .926-.753 1.68-1.68 1.68Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M10.8 7.608H30v24.745H10.8z" />
      </clipPath>
    </defs>
  </svg>
);
export default GolfSvg;
