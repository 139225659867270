import { alpha, SxProps } from '@mui/system';
import { green } from '@mui/material/colors';
import { theme } from '../../../../theme';

export const paperStyles: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  p: 1.5,
  borderRadius: theme.typography.pxToRem(2),
};

export const notFoundPapterStyles: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  p: 2.5,
  py: 5,
  borderRadius: theme.typography.pxToRem(2),
  alignItems: 'center',
};

export const bluetoothIconStyles: SxProps = {
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  width: theme.typography.pxToRem(40),
  height: theme.typography.pxToRem(40),
  borderRadius: '100%',
  alignItems: 'center',
  justifyContent: 'center',
};

export const fitbitContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
  color: `${theme.palette.secondary.main}`,
  borderRadius: theme.typography.pxToRem(12),
  mt: 2,
};

export const greenDotStyles: SxProps = {
  background: green[300],
  width: 6,
  height: 6,
  minWidth: 6,
  borderRadius: '100%',
  mr: 1,
};
