import { Stack, Typography } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { activeTabStyles, tabsContainerStyles, tabsStyles } from './styles';
import {
  ActivityIcon,
  FootIcon,
  HeartIcon,
  HikingIcon,
  MoonIcon,
  RunningIcon,
} from '../../../atoms';
import { FitbitChartsDataQuery } from '../../../../graphql';
import { getAvergeFitbitData } from '../../../../utils';

export enum ActivityType {
  Activity = 'Activity',
  Steps = 'Steps',
  HeartRate = 'Heart Rate',
  Exercise = 'Exercise',
  Distance = 'Distance',
  Sleep = 'Sleep',
}

interface BottomTabsProperties {
  activeTab: ActivityType;
  setActiveTab: (tab: ActivityType) => void;
  data: FitbitChartsDataQuery | undefined;
}

interface TabsProperties extends PropsWithChildren {
  tab: ActivityType;
  label: string;
  activeTab: ActivityType;
  setActiveTab: (tab: ActivityType) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Tab: React.FC<TabsProperties> = ({ activeTab, setActiveTab, tab, Icon, children, label }) => {
  const { t } = usePreferredTranslation();
  const isActive = activeTab === tab;

  return (
    <Stack sx={isActive ? activeTabStyles : tabsStyles} onClick={() => setActiveTab(tab)}>
      <Stack gap={0.4}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {label}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1.2}>
          <Icon />
          <Typography variant="body2">{children}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const BottomTabs: React.FC<BottomTabsProperties> = ({ activeTab, setActiveTab, data }) => {
  const averageData = useMemo(() => getAvergeFitbitData(data), [data]);
  const { t } = usePreferredTranslation();

  return (
    <Stack direction="row" sx={tabsContainerStyles}>
      <Tab
        key={ActivityType.Activity}
        activeTab={activeTab}
        tab={ActivityType.Activity}
        setActiveTab={setActiveTab}
        Icon={ActivityIcon}
        label={t('Avg. Activity')}
      >
        {averageData.activity}
      </Tab>
      <Tab
        key={ActivityType.Steps}
        activeTab={activeTab}
        tab={ActivityType.Steps}
        setActiveTab={setActiveTab}
        Icon={FootIcon}
        label={t('Avg. # of Steps')}
      >
        {averageData.steps}
      </Tab>
      <Tab
        key={ActivityType.HeartRate}
        activeTab={activeTab}
        tab={ActivityType.HeartRate}
        setActiveTab={setActiveTab}
        Icon={HeartIcon}
        label={t('Avg. Heart Rate')}
      >
        {averageData.heartRate}
      </Tab>
      <Tab
        key={ActivityType.Exercise}
        activeTab={activeTab}
        tab={ActivityType.Exercise}
        setActiveTab={setActiveTab}
        Icon={RunningIcon}
        label={t('Exercise')}
      >
        {averageData.exercise}
      </Tab>
      <Tab
        key={ActivityType.Distance}
        activeTab={activeTab}
        tab={ActivityType.Distance}
        setActiveTab={setActiveTab}
        Icon={HikingIcon}
        label={t('Avg. Distance')}
      >
        {averageData.distance}
      </Tab>
      <Tab
        key={ActivityType.Sleep}
        activeTab={activeTab}
        tab={ActivityType.Sleep}
        setActiveTab={setActiveTab}
        Icon={MoonIcon}
        label={t('Avg. Sleep')}
      >
        {averageData.sleep}
      </Tab>
    </Stack>
  );
};
