import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box, FormControl, InputLabel } from '@mui/material';

import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { theme } from '../../../theme';

interface Properties {
  readonly disabled?: boolean;
  readonly value?: string;
  readonly emptyDefault?: boolean;
  readonly clearAfterChange?: boolean;
  readonly placeholder?: string;
  readonly freeSolo?: boolean;
  readonly fullWidth?: boolean;
  readonly label: string;
  readonly limit?: number;
  readonly options?: string[];
  readonly sx?: object;
  readonly endadornment?: any;
  readonly inputType?: string;
  readonly onChange?: any;
}

export function ComboBox({
  disabled = false,
  value,
  emptyDefault = false,
  clearAfterChange = false,
  placeholder,
  freeSolo = false,
  fullWidth = false,
  label,
  limit,
  options = [],
  sx = {},
  endadornment,
  inputType,
  onChange,
  ...rest
}: Properties) {
  const { t } = usePreferredTranslation();
  const [selectedValue, setSelectedValue] = useState<string>(value ?? '');
  const [inputValue, setInputValue] = useState<string>(emptyDefault ? '' : value ?? '');

  useEffect(() => {
    setInputValue(emptyDefault ? '' : value || '');
    setSelectedValue(value || '');
  }, [emptyDefault, value]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit,
  });

  const onInputChange = useCallback(
    (event: any) => {
      if (event?.target?.value) {
        setInputValue(event.target.value);
      } else if (clearAfterChange) {
        setInputValue('');
      }
    },
    [clearAfterChange],
  );

  const handleChange = useCallback(
    (event: object, newValue: string) => {
      if (!clearAfterChange) {
        setSelectedValue(newValue);
      }

      if (onChange) {
        onChange(event, newValue);
      }
    },
    [clearAfterChange, onChange],
  );

  let extraParameters = {};

  if (clearAfterChange) {
    extraParameters = {
      ...extraParameters,
      inputValue,
      onInputChange,
    };
  }

  if (limit) {
    extraParameters = {
      ...extraParameters,
      filterOptions,
    };
  }

  extraParameters = {
    ...extraParameters,
    value: selectedValue,
  };

  return (
    <Box>
      <InputLabel
        shrink
        sx={{
          ml: '2px',
          transform: 'scale(1)',
          color: theme.palette.common.black,
          fontWeight: 500,
        }}
      >
        {label}
      </InputLabel>

      <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
        {emptyDefault || options?.includes(selectedValue) ? (
          <Autocomplete
            noOptionsText={t('No options')}
            disabled={disabled}
            disableClearable
            onChange={handleChange}
            options={options}
            freeSolo={freeSolo}
            fullWidth={fullWidth}
            renderInput={(parameters) => (
              <TextField
                {...parameters}
                placeholder={placeholder}
                type={inputType}
                InputProps={{
                  ...parameters.InputProps,
                  endAdornment: endadornment,
                }}
              />
            )}
            sx={sx}
            {...extraParameters}
            {...rest}
          />
        ) : null}
      </FormControl>
    </Box>
  );
}
