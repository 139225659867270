import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useMemo } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Stack, Typography } from '@mui/material';
import { dayjs } from '../../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import { ChartsProperties } from './types';

const formatTicks = (value: number) => {
  return `${value}bpm`;
};

const CustomTooltip = (properties: TooltipProps<ValueType, NameType>) => {
  const { t } = usePreferredTranslation();
  const { payload } = properties;

  const data = payload?.[0];

  if (!data) return null;
  const value = data.payload[data.dataKey as string];
  const label = data.payload.date;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        BPM ({label})
      </Typography>
      <Typography variant="body1">
        {value}/{t('sec')}
      </Typography>
    </Stack>
  );
};

export const HeartRateChart: React.FC<ChartsProperties> = ({ data }) => {
  const chartData = useMemo(() => {
    const heartRateData = data?.fitbitChartsData.heartRate || [];

    return heartRateData
      .filter((item) => item.restingHeartRate > 0)
      .map((item) => ({
        date: dayjs.utc(item.date).format('MM/DD'),
        value: item.restingHeartRate,
      }));
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart data={chartData}>
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Tooltip shared={false} cursor={{ fill: 'none' }} content={CustomTooltip} />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatTicks} />
        <Bar dataKey="value" fill="#F57C00" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};
