import { Button, Paper, Skeleton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import ParticipantAdherenceSummary from './ParticipantAdherenceSummary';
import AdherenceModal from './AdherenceModal';
import { useAdherenceMetricsQuery, useUserQuery } from '../../../../graphql';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  userId: string | undefined;
}

const ParticipantAdherenceMetrics: React.FC<Properties> = ({ userId }) => {
  const [showAdherenceModal, setShowAdherenceModal] = React.useState(false);
  const { t } = usePreferredTranslation();

  const { data: userData } = useUserQuery({
    variables: {
      id: Number(userId),
    },
    skip: !userId,
  });

  const { data: adherenceData, loading: adherenceLoading } = useAdherenceMetricsQuery({
    variables: {
      userId: Number(userId),
    },
    skip: !userId,
  });

  if (adherenceLoading) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  return (
    <Paper
      sx={{ position: 'relative', p: 3, pb: 4, overflowX: 'auto' }}
      data-testid="participant-adherence-metrics-card"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          component="p"
          flexGrow={1}
          sx={{
            fontSize: '1.25rem',
            fontWeight: 600,
          }}
        >
          {t('Engagement Adherence')}
        </Typography>
        <Button
          data-testid="view-adherence-button"
          variant="text"
          onClick={() => setShowAdherenceModal(true)}
          disabled={adherenceLoading}
        >
          {t('View')}
        </Button>
      </Stack>
      <ParticipantAdherenceSummary
        adherenceData={adherenceData}
        userStartDate={userData?.user?.startDate || ''}
        userTimeZone={userData?.user?.timezone || ''}
      />
      <AdherenceModal
        adherenceData={adherenceData}
        open={showAdherenceModal}
        onClose={() => setShowAdherenceModal(false)}
        userStartDate={userData?.user?.startDate || ''}
      />
    </Paper>
  );
};

export default ParticipantAdherenceMetrics;
