import { Box, Button, MenuItem, MenuList, Popover, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { grey } from '@mui/material/colors';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { EventStatuses } from './type';

const STATUS_COLORS = {
  [EventStatuses.COMPLETED]: '#1B5E20',
  [EventStatuses.INCOMPLETE]: '#A18710',
  [EventStatuses.STARTED]: '#B71C1C',
};

const STATUS_LABELS = {
  [EventStatuses.COMPLETED]: 'Completed call',
  [EventStatuses.STARTED]: 'Call attempted',
  [EventStatuses.INCOMPLETE]: 'Incomplete call',
};

interface CoachingCallStatusProperties {
  callStatus: EventStatuses | null;
  setCallStatus: (status: EventStatuses) => void;
  loading: boolean;
  disabled: boolean;
}

const Option = ({ status }: { status: EventStatuses }) => {
  const { t } = usePreferredTranslation();
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box
        sx={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: STATUS_COLORS[status],
        }}
      />
      <Box>{t(STATUS_LABELS[status])}</Box>
    </Stack>
  );
};

const CoachingCallStatus: React.FC<CoachingCallStatusProperties> = ({
  callStatus,
  setCallStatus,
  loading,
  disabled,
}) => {
  const { t } = usePreferredTranslation();
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleCallStatus = useCallback(
    (status: EventStatuses) => {
      setCallStatus(status);
      handleClose();
    },
    [setCallStatus],
  );

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        disabled={loading || disabled}
        onClick={handleClick}
        variant="text"
        sx={{
          bgcolor: 'white',
          color: 'black',
          border: `1px solid ${grey[300]}`,
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {loading ? 'Loading...' : callStatus ? <Option status={callStatus} /> : t('Call Status')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem onClick={() => handleCallStatus(EventStatuses.COMPLETED)}>
            <Option status={EventStatuses.COMPLETED} />
          </MenuItem>
          <MenuItem onClick={() => handleCallStatus(EventStatuses.INCOMPLETE)}>
            <Option status={EventStatuses.INCOMPLETE} />
          </MenuItem>
          <MenuItem onClick={() => handleCallStatus(EventStatuses.STARTED)}>
            <Option status={EventStatuses.STARTED} />
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
};

export default CoachingCallStatus;
