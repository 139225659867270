import { Select, MenuItem, Box, FormControl, Typography } from '@mui/material';

interface SelectFieldProperties {
  index?: number;
  options?: string[] | null;
  label?: string;
  value?: string;
  optionLabel?: string;
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange?: (event: any) => void;
}

export const DropdownField = ({
  index,
  options,
  label,
  optionLabel,
  value = '',
  required,
  onChange,
  showBoldLabel,
  hideLabel = false,
}: SelectFieldProperties) => {
  return (
    <Box sx={{ mb: 2 }}>
      {!hideLabel && (
        <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
          {index}
          {index && '.'} {label} {required && '*'}
        </Typography>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select displayEmpty value={value} onChange={onChange}>
          <MenuItem disabled>{optionLabel || 'Select an answer'}</MenuItem>
          {options?.map((option, index_) => (
            <MenuItem key={index_} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownField;
