import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SchemaLink } from '@apollo/client/link/schema';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useUser } from '../states';

import mocks from './mocks';

const errorHandler = (error: ErrorResponse) => {
  // eslint-disable-next-line no-console
  console.error('Apollo Error', error);

  if (error?.networkError) {
    //
  }

  if (error?.graphQLErrors?.length) {
    for (const graphQLError of error.graphQLErrors) {
      // eslint-disable-next-line sonarjs/no-all-duplicated-branches
      switch (graphQLError.message) {
        case 'Unauthorized': {
          // if (!window.location.pathname.includes(PAGES.SIGN_IN)) {
          //   window.location.href = PAGES.SIGN_IN;
          // }
          break;
        }
        case 'Forbidden': {
          break;
        }
        default: {
          break;
        }
      }
    }
  }
};

const authLink = setContext((_, { headers }) => {
  const { token, studyId, studyArmId } = useUser.getState();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      selectedStudyId: studyId,
      selectedStudyArmId: studyArmId,
    },
  };
});

const uploadLink: any = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

export const client = new ApolloClient({
  link:
    process.env.REACT_APP_API_MOCKS === 'true'
      ? new SchemaLink({ schema: mocks })
      : ApolloLink.from([onError(errorHandler), authLink, uploadLink]),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});
