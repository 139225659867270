import { MutableRefObject } from 'react';

export const scrollListToEnd = (
  listReference: MutableRefObject<HTMLUListElement | null>,
  isSmooth: boolean,
) => {
  if (listReference?.current) {
    listReference.current.scrollTo({
      top: listReference.current.scrollHeight,
      behavior: isSmooth ? 'smooth' : 'auto',
    });
  }
};
