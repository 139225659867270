import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Container, Stack } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties {
  submit?: boolean;
  disabledSubmit?: boolean;
  loading?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
}

export default function QuestionNavigation({
  submit,
  onPrev,
  onNext,
  onSubmit,
  disabledSubmit = false,
}: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const handlePrevious = useCallback(() => {
    onPrev?.();
  }, [onPrev]);

  const handleNext = useCallback(() => {
    onNext?.();
  }, [onNext]);

  const handleSubmit = useCallback(() => {
    onSubmit?.();
  }, [onSubmit]);

  return (
    <Container maxWidth="sm">
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', mt: 2 }}>
        <Button variant="text" onClick={handlePrevious} startIcon={<ArrowBackIosIcon />}>
          {t('journal.button.prev')}
        </Button>
        {submit ? (
          <Button variant="contained" onClick={handleSubmit} disabled={disabledSubmit}>
            {t('journal.button.submit')}
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNext} disabled={disabledSubmit}>
            {t('journal.button.next')}
          </Button>
        )}
      </Stack>
    </Container>
  );
}
