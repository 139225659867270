import { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { Room } from '@vonage/video-express';
import { toast } from 'react-toastify';
import RoomWrapper from '@vonage/video-express/dist/mp/room';
import {
  useOpentokAuthLazyQuery,
  useSendVideoConferenceSmsMutation,
  useStartConferenceMutation,
  useUpdateConferenceMutation,
} from '../../../graphql';
import { useCurrentUser, useUser } from '../../user';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

const VideoCallContext = createContext<{
  room: RoomWrapper | null;
  roomContainer: any;
  setRoomContainer: any;
  canStartRecording: boolean;
  apiKey: string;
  sessionId: string;
  conferenceId: string;
  token: string;
  initVideoCall: ({ fromUser, user, joinSessionId }: any) => Promise<void>;
  onVideoCallEnd: () => void;
}>({
  room: null,
  roomContainer: null,
  setRoomContainer: () => {},
  canStartRecording: false,
  apiKey: '',
  sessionId: '',
  conferenceId: '',
  token: '',
  onVideoCallEnd: () => {},
  // eslint-disable-next-line @typescript-eslint/require-await
  initVideoCall: async ({ fromUser = '', user = '', joinSessionId = '' }: any) =>
    // eslint-disable-next-line no-console
    console.log({ fromUser, user, joinSessionId }),
});

export const VideoCallProvider = ({ children }: any) => {
  const currentUser = useCurrentUser();
  const { t } = usePreferredTranslation();
  const { studyId, studyArmId } = useUser();
  const [room, setRoom] = useState<any>(null);
  const [roomContainer, setRoomContainer] = useState<any>(null);
  const [canStartRecording, setCanStartRecording] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [conferenceId, setConferenceId] = useState('');
  const [opentokAuth] = useOpentokAuthLazyQuery();
  const [sendVideoConferenceSmsMutation] = useSendVideoConferenceSmsMutation();
  const [updateConference] = useUpdateConferenceMutation();
  const [startConference] = useStartConferenceMutation();

  useEffect(() => {
    return () => {
      setIsInitialized(false);
    };
  }, []);

  const onVideoCallEnd = useCallback(() => {
    setConferenceId('');
    setIsInitialized(false);
    setRoom(null);
    setApiKey('');
    setToken('');
    setCanStartRecording(false);
    setRoomContainer(null);
    setSessionId('');
  }, []);

  const initVideoCall = useCallback(
    async ({ fromUser, user, joinSessionId }: any) => {
      if (isInitialized || (!joinSessionId && (!currentUser.data?.user || !user))) {
        return;
      }

      let configId = '';
      setIsInitialized(true);

      const { data, error } = await opentokAuth({
        variables: { joinSessionId },
        fetchPolicy: 'network-only',
      });

      if (error || !data) {
        toast.error(
          error?.message || 'Could not initialize video session. Authentication was unsuccessful.',
        );
        setIsInitialized(false);
        return;
      }

      const {
        opentokAuth: { apiKey: vonageApiKey, sessionId: vonageSessionId, token: vonageToken },
      } = data;

      if (!vonageApiKey || !vonageSessionId || !vonageToken) {
        toast.error(t('Could not initialize video call. Could not authenticate.'));
        setIsInitialized(false);
        return;
      }

      const vonageRoom = new Room({
        apiKey: vonageApiKey,
        sessionId: joinSessionId || vonageSessionId,
        token: vonageToken,
        roomContainer: 'roomContainer',
        participantName: currentUser.data?.user?.name,
      });

      vonageRoom.on('connected', async () => {
        if (user && fromUser) {
          const { data } = await startConference({
            variables: {
              type: 'video-call',
              fromUserId: Number(fromUser.id),
              toUserId: Number(user.id),
              sessionId: vonageSessionId,
              studyId,
              conversationUuid: null,
            },
          });

          if (data?.startConference?.id) {
            setConferenceId(data.startConference.id);
            configId = data.startConference.id;
          }
        }
      });

      vonageRoom.on('reconnected', () => {
        toast.success(t('Reconnected to video conference.'));
      });

      vonageRoom.on('participantJoined', (participant) => {
        updateConference({
          variables: {
            id: Number(configId),
            updateConferenceInput: {
              status: 'completed',
            },
          },
        });
        setCanStartRecording(true);
        toast.info(`${participant.name || ''} joined`);
      });

      vonageRoom.on('participantLeft', (participant) => {
        setCanStartRecording(false);
        toast.warning(`${participant.name || ''} left the conference`);
      });

      setTimeout(() => {
        // eslint-disable-next-line unicorn/require-array-join-separator
        vonageRoom.join();
      }, 500);

      if (user?.id && !joinSessionId) {
        await sendVideoConferenceSmsMutation({
          variables: {
            user: Number(user.id),
            vonageSessionId,
            studyId,
            studyArmId,
          },
        });
      }

      setApiKey(vonageApiKey);
      setSessionId(vonageSessionId);
      setToken(vonageToken);
      setRoom(vonageRoom);
    },
    [
      currentUser.data?.user,
      isInitialized,
      opentokAuth,
      sendVideoConferenceSmsMutation,
      startConference,
      studyId,
      studyArmId,
    ],
  );

  const contextValue = useMemo(
    () => ({
      room,
      canStartRecording: !!sessionId && canStartRecording,
      apiKey,
      sessionId,
      conferenceId,
      token,
      initVideoCall,
      roomContainer,
      setRoomContainer,
      onVideoCallEnd,
    }),
    [
      room,
      canStartRecording,
      apiKey,
      sessionId,
      conferenceId,
      token,
      initVideoCall,
      roomContainer,
      onVideoCallEnd,
    ],
  );

  return <VideoCallContext.Provider value={contextValue}>{children}</VideoCallContext.Provider>;
};

export const useVideoCall = () => {
  const context = useContext(VideoCallContext);
  if (!context) {
    throw new Error('useVideoCall must be used within a VideoCallProvider');
  }
  return context;
};
