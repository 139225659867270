export const roles = [
  {
    id: '1',
    name: 'Researcher',
    permissions: [
      'See profiles',
      'Edit profiles',
      'Study modules',
      'PROs',
      'Active data readings',
      'Telephone assessments',
    ],
  },
  {
    id: '2',
    name: 'Lead Researcher',
    permissions: [
      'See profiles',
      'Edit profiles',
      'Study modules',
      'PROs',
      'Active data readings',
      'Telephone assessments',
    ],
  },
  {
    id: '3',
    name: 'Interventionist',
    permissions: ['See profiles', 'Edit profiles', 'PROs', 'Telephone assessments'],
  },
  {
    id: '4',
    name: 'Data Collector',
    permissions: ['See profiles', 'Study modules', 'Active data readings'],
  },
  {
    id: '5',
    name: 'Participant',
    permissions: ['See profiles', 'Study modules'],
  },
  {
    id: '6',
    name: 'Clinician',
    permissions: ['See profiles', 'Edit profiles', 'Study modules', 'Active data readings'],
  },
];
