import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Progress } from 'antd';
import React, { useMemo } from 'react';
import CallSummary from './CallSummary';
import { AdherenceMetricsQuery, LibraryEntity } from '../../../../graphql';
import { EventStatuses } from '../../Coaching/InfoHeader/type';
import { getJournalsDatesLength } from '../../../../utils/journal';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  adherenceData: AdherenceMetricsQuery | undefined;
  userStartDate: string | Date;
  userTimeZone: string;
}

const ParticipantAdherenceSummary: React.FC<Properties> = ({
  adherenceData,
  userStartDate,
  userTimeZone,
}) => {
  const { t } = usePreferredTranslation();
  const responseRate = useMemo(() => {
    const metrics = adherenceData?.adherenceMetrics.adherenceItems || [];
    const journals = adherenceData?.adherenceMetrics.libraries || [];
    const completedJournalsEvent = adherenceData?.adherenceMetrics.completedJournals || 0;

    const totalJournalEvents = getJournalsDatesLength(
      journals as LibraryEntity[],
      new Date(userStartDate),
      userTimeZone,
    );

    const total = metrics.length + totalJournalEvents;

    const successCount =
      metrics?.filter(
        (item) =>
          item.status === EventStatuses.COMPLETED || item.status === EventStatuses.INCOMPLETE,
      ).length || 0;

    const successRate = total > 0 ? ((successCount + completedJournalsEvent) / total) * 100 : 0;

    return { successRate: successRate.toFixed(0), failedRate: 100 };
  }, [adherenceData, userStartDate, userTimeZone]);

  return (
    <Stack spacing={2}>
      <CallSummary adherenceData={adherenceData} />
      <Box>
        <Typography variant="body2" fontWeight={500}>
          {responseRate.successRate}% {t('Response Rate')}
        </Typography>
        <Progress
          percent={responseRate.failedRate}
          status="exception"
          showInfo={false}
          success={{ percent: Number(responseRate.successRate), strokeColor: '#43A047' }}
        />
      </Box>
    </Stack>
  );
};

export default ParticipantAdherenceSummary;
