import { Dialog, Drawer, Slide, Stack, Typography, DialogActions, Button } from '@mui/material';
import { ReactElement, forwardRef, useCallback } from 'react';

import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import PainLevelControlSlider from '../PainLevelControlSlider';
import { theme } from '../../../../theme';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  open?: boolean;
  painLevel?: number;
  sectionName?: string;
  isAdded?: boolean;
  onChange?: (currentLevel: number) => void;
  onClose?: () => void;
  onDelete?: () => void;
}

const Transition = forwardRef(function Transition(
  properties: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  reference: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={reference} {...properties} />;
});

export function PainLevelPopup({
  painLevel,
  sectionName,
  open,
  onChange,
  onClose,
  isAdded,
  onDelete,
}: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:750px)');

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleChange = useCallback(
    (event: any, newValue: number | number[]) => {
      if (onChange) {
        onChange(Number(newValue));
      }
    },
    [onChange],
  );

  const PopupContent = (
    <Stack direction="column" sx={{ p: 2.5, width: '100%' }}>
      <Typography fontSize={theme.typography.pxToRem(18)} fontWeight={600}>
        {t('journal.pain.how_severe', { section: t(sectionName || '') })}
      </Typography>
      <Typography fontSize={theme.typography.pxToRem(56)} textAlign="center" py={1}>
        {painLevel}
      </Typography>
      <Stack spacing={1} sx={{ width: '100%' }} alignItems="center" mb={1}>
        <PainLevelControlSlider value={painLevel} onChange={handleChange} />
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {t('journal.pain.drag_to_select_number')}
        </Typography>
      </Stack>
    </Stack>
  );

  if (sm) {
    return (
      <Dialog
        open={!!open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
          },
        }}
        TransitionComponent={Transition}
        transitionDuration={350}
      >
        {PopupContent}

        {isAdded && (
          <DialogActions>
            <Button onClick={onDelete}>{t('clear')}</Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  return (
    <Drawer
      open={open}
      anchor="bottom"
      sx={{ position: 'relative', zIndex: 1300 }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      }}
      ModalProps={{ onBackdropClick: handleClose }}
      transitionDuration={350}
    >
      {PopupContent}
    </Drawer>
  );
}
