import {
  AssessmentQuestionInputType,
  InputMaybe,
  useAssessmentResponsesQuery,
} from '../../../graphql';

function getAnswerWithId(answers: any, questionId: any) {
  for (const element of answers) {
    if (element.question && element.question.id === questionId) {
      return element;
    }
  }
  return null;
}

const getResponseId = (answer: any, assessmentResponses?: any[]) => {
  if (!assessmentResponses || !answer) return null;

  for (const response of assessmentResponses) {
    for (const ans of response.answers || []) {
      if (ans.id === answer.id) {
        return response.id;
      }
    }
  }
  return null;
};

function checkIfQuestionIsAnswered(question: any, answers: any): any {
  const answer = getAnswerWithId(answers, question.id);

  if (answer && (answer.value || answer.values)) return answer;

  if (question.questions && question.questions.length > 0) {
    for (const subQuestion of question.questions) {
      const subAnswer = checkIfQuestionIsAnswered(subQuestion, answers);
      if (subAnswer) {
        return subAnswer;
      }
    }
  }
  return null;
}

function generateModifiedQuestion(
  question: any,
  answers: any,
  questionAnswersMap?: Map<any, any>,
  assessmentResponses?: any[],
) {
  const answer = checkIfQuestionIsAnswered(question, answers);
  const responseId = getResponseId(answer, assessmentResponses);

  const answerValue = answer?.value || answer?.values || null;

  if (questionAnswersMap && answer) questionAnswersMap.set(question.id, answerValue);
  return {
    ...question,
    isAnswered: !!answer,
    responseId,
    questions: question.questions
      ? question.questions.map((subQuestion: any) =>
          generateModifiedQuestion(subQuestion, answers, questionAnswersMap),
        )
      : [],
  };
}

export function generateModifiedQuestionsArray(
  questions: any,
  answers: any,
  questionAnswersMap?: Map<any, any>,
  assessmentResponses?: any[],
) {
  return questions.map((question: any) =>
    generateModifiedQuestion(question, answers, questionAnswersMap, assessmentResponses),
  );
}

export function findQuestionsByType(questionsArray: any[], type: string): any[] {
  const matchingQuestions: any[] = [];

  for (const currentQuestion of questionsArray) {
    if (currentQuestion.type === type) {
      matchingQuestions.push(currentQuestion);
    }

    if (currentQuestion.questions && Array.isArray(currentQuestion.questions)) {
      const nestedResults: any[] = findQuestionsByType(currentQuestion.questions, type);
      matchingQuestions.push(...nestedResults);
    }
  }

  return matchingQuestions;
}

interface Properties {
  userId?: number;
  libraryId?: number;
  answerDate?: Date;
  skip?: boolean;
  questions?: InputMaybe<AssessmentQuestionInputType[]>;
}

export function useJournal({ userId, libraryId, answerDate, skip, questions }: Properties) {
  const {
    data: assessmentResponsesData,
    loading,
    refetch,
  } = useAssessmentResponsesQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        userId,
        libraryId,
        answerDate,
      },
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const items = assessmentResponsesData?.assessmentResponses?.items;
  const answers = (items || []).flatMap((a) => a?.answers || []);

  const questionAnswersMap = new Map();

  return {
    answers,
    loading,
    answerToQuestions: questions
      ? generateModifiedQuestionsArray(questions, answers, questionAnswersMap, items)
      : null,
    refetch,
    questionAnswersMap,
  };
}
