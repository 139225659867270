import { ReactElement } from 'react';
import { Dialog, Drawer, DrawerProps } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Properties extends DrawerProps {
  maxWidthDefault?: number;
  maxWidthMobile?: number;
  isOpened?: boolean;
  variant?: 'temporary' | 'permanent' | 'persistent';
  onClose: () => void;
}

export function SideNavigation({
  maxWidthDefault = 240,
  maxWidthMobile = 750,
  isOpened = false,
  variant = 'persistent',
  onClose,
  children,
  ...rest
}: Properties): ReactElement {
  const xs = useMediaQuery(`(max-width:${maxWidthMobile}px)`);

  if (xs) {
    return (
      <Dialog fullScreen open={isOpened} onClose={onClose}>
        {children}
      </Dialog>
    );
  }

  return (
    <Drawer
      data-testid="sideNavigationComponent"
      sx={{
        width: '100%',
        maxWidth: xs ? maxWidthMobile : maxWidthDefault,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          maxWidth: xs ? maxWidthMobile : maxWidthDefault,
          boxSizing: 'border-box',
        },
      }}
      variant={variant}
      open={isOpened}
      onClose={onClose}
      {...rest}
    >
      {children}
    </Drawer>
  );
}
