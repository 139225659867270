import { useCallback, useState } from 'react';

import { Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FluidAmountInputControl } from '../../atoms/Journal';

interface Properties {
  onChangeValue?: (value: number) => void;
  min?: number;
  max?: number;
  defaultValue?: number;
}

export default function HydrationQuestion({
  min = 0,
  max = 128,
  defaultValue = 8,
  onChangeValue,
}: Properties) {
  const sm = useMediaQuery('(min-width:750px)');
  const [waterAmount, setWaterAmount] = useState(defaultValue);

  const handleChangeWaterAmount = useCallback(
    (amount: number) => {
      onChangeValue?.(amount);
      setWaterAmount(amount);
    },
    [setWaterAmount, onChangeValue],
  );

  return (
    <Stack justifyContent="center" alignItems="center" my={sm ? 8 : 4} sx={{ width: '100%' }}>
      <FluidAmountInputControl
        min={Number(min)}
        max={Number(max)}
        waterAmount={waterAmount}
        onChangeWaterAmount={handleChangeWaterAmount}
      />
    </Stack>
  );
}
