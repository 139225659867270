import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import { theme } from '../../../../theme';

export const containerStyles: SxProps = {
  p: 2,
};

export const headerContainerStyles: SxProps = {
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  marginBottom: 6,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

export const tabsContainerStyles: SxProps = {
  overflow: 'auto',
  backgroundColor: '#F6F6F6',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
};

export const tabsStyles: SxProps = {
  flexGrow: 1,
  alignItems: 'flex-start',
  cursor: 'pointer',
  p: 2,
  ':hover': {
    backgroundColor: grey[200],
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '150px',
  },
};

export const activeTabStyles: SxProps = {
  backgroundColor: 'white',
  borderTop: `2px solid ${theme.palette.primary.main}`,
  cursor: 'pointer',
  p: 2,
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    minWidth: '150px',
  },
};
