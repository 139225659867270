import { Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import _ from 'lodash';
import { theme } from '../../../../theme';
import { AdherenceMetricsQuery } from '../../../../graphql';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  adherenceData: AdherenceMetricsQuery | undefined;
}

interface CallSummaryItemProperties {
  label: string;
  value: number;
  xs: number;
  disableDivider?: boolean;
}

const CallSummaryItem: React.FC<CallSummaryItemProperties> = ({
  label,
  value,
  xs,
  disableDivider = false,
}) => {
  const sx = {
    py: theme.spacing(1),
    ...(disableDivider ? {} : { borderRight: '1px solid #ccc' }),
  };

  return (
    <Grid item xs={xs || 3} sx={sx}>
      <Stack alignItems="center" justifyContent="center">
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(23),
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {label}
        </Typography>
      </Stack>
    </Grid>
  );
};

const CallSummary: React.FC<Properties> = ({ adherenceData }) => {
  const { t } = usePreferredTranslation();

  const summary = useMemo(() => {
    const metrics = adherenceData?.adherenceMetrics.adherenceItems || [];

    const completedJournals = adherenceData?.adherenceMetrics.completedJournals || 0;

    if (metrics.length === 0) {
      return {
        ivr: 0,
        coaching: 0,
        directCalls: 0,
        total: 0,
        jouranls: completedJournals,
      };
    }

    const ivr = metrics.filter((m) => m.type === 'IVR').length;
    const coaching = metrics.filter((m) => m.type === 'COACHING').length;

    const calls = metrics.filter((m) => m.type === 'video-call' || m.type === 'phone-call').length;

    return {
      ivr,
      coaching,
      total: metrics.length,
      directCalls: calls,
      jouranls: completedJournals,
    };
  }, [adherenceData]);

  return (
    <Grid
      container
      sx={{
        border: '1px solid #ccc',
        borderRadius: '9px',
        my: theme.spacing(1),
        minWidth: 400,
      }}
    >
      <CallSummaryItem label={t('Journal')} value={summary.jouranls} xs={2} />
      <CallSummaryItem label={t('IVR')} value={summary.ivr} xs={2} />
      <CallSummaryItem label={t('Coaching')} value={summary.coaching} xs={2.5} />
      <CallSummaryItem label={t('Direct calls')} value={summary.directCalls} xs={3} />
      <CallSummaryItem label={t('Total Calls')} value={summary.total} xs={2.5} disableDivider />
    </Grid>
  );
};

export default CallSummary;
