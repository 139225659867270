import { SVGProps } from 'react';

const LightlyActiveIcon = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g fill="#6C5DD3" clipPath="url(#a)">
      <path d="M20 13a2.333 2.333 0 1 0 0-4.667A2.333 2.333 0 0 0 20 13Z" />
      <path d="M24.538 15.461c-.455-.455-1.236-1.295-2.753-1.295h-2.963C15.613 14.155 13 11.541 13 8.333h-2.334c0 3.687 2.462 6.813 5.834 7.828v15.505h2.333v-7h2.334v7H23.5V17.725l4.608 4.608 1.645-1.645-5.215-5.227Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6 6h28v28H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default LightlyActiveIcon;
