import { INotification } from '../types/notifications';
import { users } from './users';
import { userSharedFiles } from './userSharedFiles';

const fakeUser = {
  id: '934f1f77bcf86cd11075993',
  name: 'Sandra Brown',
  email: 'sandra13brown@example.com',
  studies: [],
  role: 'Researcher',
  status: 'Pending',
};

const mockNotification1: INotification = {
  id: '13jx82lndjsn2s7666',
  content: 'to complete the intake form.',
  sender: users[2] || fakeUser,
  type: 'reminder',
  createdAt: 'Now',
  isRead: true,
  study: {
    name: 'COVID-19 study',
  },
};

const mockNotification2: INotification = {
  id: '94jd82lndjsn2s3532',
  content: 'uploaded a file',
  sender: users[1] || fakeUser,
  type: 'file_upload',
  meta: {
    attachment: userSharedFiles[2],
  },
  createdAt: '3h ago',
  isRead: true,
  study: {
    name: 'COVID-19 study',
  },
};

const mockNotification4: INotification = {
  id: '53bhb234kjnjsn2s046',
  content: 'to join the study',
  sender: users[3] || fakeUser,
  type: 'join_study',
  createdAt: '15h ago',
  isRead: true,
  study: {
    name: 'COVID-19 study',
  },
};

const mockNotification3: INotification = {
  id: '3b4k234k3jkytn2mk45',
  content: 'wants to schedule time',
  sender: users[3] || fakeUser,
  type: 'schedule_time',
  createdAt: '15h ago',
  isRead: true,
  study: {
    name: 'COVID-19 study',
  },
};

const mockNotification5: INotification = {
  id: '9gf30h3bhb234kjnj64932',
  content: 'Content of the comment here',
  sender: users[3] || fakeUser,
  type: 'left_comment',
  createdAt: '15h ago',
  isRead: false,
  study: {
    name: 'COVID-19 study',
  },
};

export const notifications: INotification[] = [
  mockNotification1,
  mockNotification2,
  mockNotification3,
  mockNotification4,
  mockNotification5,
];
