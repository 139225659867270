import { Box, BoxProps, Chip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { dayjs } from '../../../../utils/dayjs';
import { FORMATS, PUSHER } from '../../../../constants';
import { UserEntity, useCountMessagesQuery } from '../../../../graphql';
import { useChannel, useEvents } from '../../../../modules';

import { Avatar } from '../../../atoms';
import { theme } from '../../../../theme';

interface Properties extends BoxProps {
  user: UserEntity;
  selected?: boolean;
  newChat?: boolean;
}

export const UserMessage = ({
  user,
  selected = false,
  newChat = false,
  children,
  ...rest
}: Properties) => {
  const countNotViewedMessages = useCountMessagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      user: Number(user.id),
      where: {
        viewed: false,
      },
    },
  });

  const countNotViewed = countNotViewedMessages.data?.countMessages || 0;

  const messagesChannel = useChannel(PUSHER.CHANNELS.MESSAGES);

  useEvents(
    messagesChannel,
    [
      `${PUSHER.EVENTS.MESSAGE_CREATED}.${user.id}`,
      `${PUSHER.EVENTS.MESSAGE_DELETED}.${user.id}`,
      `${PUSHER.EVENTS.MESSAGE_UPDATED}.${user.id}`,
      `${PUSHER.EVENTS.MESSAGES_UPDATED}.${user.id}`,
    ],
    () => {
      countNotViewedMessages.refetch();
    },
  );

  return (
    <Box
      sx={{
        p: 2,
        gap: 1.5,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        minWidth: 0,
        background: selected
          ? alpha(theme.palette.primary.main, 0.12)
          : newChat
          ? alpha(theme.palette.primary.main, 0.05)
          : theme.palette.common.white,
      }}
      {...rest}
    >
      <Avatar size={40} name={user.name} />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Box
          color={theme.palette.text.primary}
          sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', pr: 3 }}
        >
          {user.name}
        </Box>
        {user.lastMessageAt && (
          <Typography variant="body2" color={theme.palette.secondary.main}>
            {dayjs(user.lastMessageAt).format(FORMATS.DATE)}
          </Typography>
        )}
      </Box>

      {children && <Box sx={{ flexGrow: 1 }}>{children}</Box>}

      {user.lastMessageAt ? (
        <Typography variant="body2" color={theme.palette.secondary.main}>
          {dayjs().to(dayjs(user.lastMessageAt))}
        </Typography>
      ) : null}

      {countNotViewed > 0 && <Chip size="small" label={countNotViewed} color="error" />}
    </Box>
  );
};
