import { CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Calendar from 'react-calendar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import styled from 'styled-components';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { dayjs } from '../../../../utils/dayjs';
import { theme } from '../../../../theme';
import { useCurrentUser, useUser } from '../../../../states';
import { formatTimezone, getDateStringFromDateTime } from '../../../../utils';
import {
  AssessmentsAnsweredResponseOutputType,
  useAssessmentAnsweredResponsesLazyQuery,
} from '../../../../graphql';
import { useActiveJournal } from '../../../../states/journal/useActiveJournal';
import { getValidJournalDates } from '../../../../utils/journal';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

dayjs.extend(weekOfYear);

interface Properties {
  currentDate?: Date;
  open?: boolean;
  darkMode?: boolean;
  libraryIds?: string[] | null;
  onChangeDate?: (newDate: any) => void;
  onToggleOpen?: () => void;
}

const StyledProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  z-index: 9999;
`;

export function JournalCalendar({
  open,
  darkMode,
  currentDate = new Date(),
  libraryIds,
  onChangeDate,
  onToggleOpen,
}: Properties) {
  const { t, i18n } = usePreferredTranslation();
  const { userId } = useUser();
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [filledDates, setFilledDates] = useState<AssessmentsAnsweredResponseOutputType[]>([]);
  const [getAnsweredAssessments] = useAssessmentAnsweredResponsesLazyQuery({});
  const timeZone = formatTimezone(currentUser?.data?.user?.timezone);

  const { activeJournal } = useActiveJournal();

  const journalValidDates = useMemo(() => {
    return getValidJournalDates(
      activeJournal,
      currentUser?.data?.user?.startDate,
      currentUser?.data?.user?.timezone,
    );
  }, [activeJournal, currentUser?.data?.user?.startDate, currentUser?.data?.user?.timezone]);

  useEffect(() => {
    if (journalValidDates.length === 0) return;

    const isTodayValid = journalValidDates.some((date) =>
      dayjs(date.date).tz(timeZone).isSame(dayjs(), 'day'),
    );

    if (!isTodayValid) {
      const currentWeekNumber = dayjs().tz(timeZone).week();
      const currentWeekDate = journalValidDates.find(
        (date) => dayjs(date.date).tz(timeZone).week() === currentWeekNumber,
      );
      onChangeDate?.(currentWeekDate?.date.tz(timeZone));
    }
  }, []);

  const fetchAssessment = async (date: Date) => {
    if (loading || libraryIds?.length === 0) return;
    setLoading(true);
    setFilledDates([]);
    const startDate = getDateStringFromDateTime(
      dayjs(date || currentDate)
        .startOf('month')
        .toDate(),
    ) as unknown as Date;
    const endDate = getDateStringFromDateTime(
      dayjs(date || currentDate)
        .endOf('month')
        .startOf('day')
        .toDate(),
    ) as unknown as Date;

    const { data } = await getAnsweredAssessments({
      fetchPolicy: 'network-only',
      variables: {
        userId: Number(userId),
        libraryIds: libraryIds?.map(Number) || [],
        startDate,
        endDate,
      },
    });
    setFilledDates(data?.assessmentAnsweredResponses || []);
    setLoading(false);
  };

  const onActiveStartDateChange = (value: any) => {
    fetchAssessment(value?.activeStartDate);
  };

  const handleChangeDate = useCallback(
    (value: any) => {
      if (
        dayjs(value).isAfter(
          dayjs(currentUser?.data?.user?.startDate).subtract(1, 'day').startOf('day'),
        )
      ) {
        if (onChangeDate) onChangeDate(value);
        if (onToggleOpen) onToggleOpen();
      }
    },
    [onChangeDate, onToggleOpen, currentUser?.data?.user?.startDate],
  );

  useEffect(() => {
    if (open) {
      fetchAssessment(currentDate);
    }
  }, [open]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          color: darkMode ? theme.palette.text.primary : 'white',
          my: 2,
          caretColor: 'transparent',
          cursor: 'pointer',
        }}
        onClick={onToggleOpen}
      >
        <Typography variant="h6">
          {dayjs().isSame(currentDate, 'day')
            ? t('journal.today')
            : dayjs(currentDate).tz(timeZone).format('MMM D, YYYY')}
        </Typography>
        <ExpandMoreIcon sx={{ color: darkMode ? grey[700] : 'white' }} />
      </Stack>
      {open && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          {loading && <StyledProgress size="5rem" sx={{ color: 'primary' }} />}
          <Calendar
            onChange={handleChangeDate}
            showNeighboringMonth={false}
            onActiveStartDateChange={onActiveStartDateChange}
            value={dayjs(currentDate).tz(timeZone).format('YYYY-MM-DD')}
            className={`journal-date-picker ${loading ? 'loading' : ''}`}
            locale={i18n.language}
            tileDisabled={({ date }) => {
              return !journalValidDates.find((d) => dayjs(date).tz(timeZone).isSame(d.date, 'day'))
                ?.isEditable;
            }}
            tileClassName={({ date }) => {
              const isFilled = filledDates.find((filledDate) =>
                dayjs(date).tz(timeZone).isSame(filledDate.date, 'day'),
              );

              // check if the date is valid for the journal
              const isValidDate = journalValidDates.find((d) =>
                dayjs(date).tz(timeZone).isSame(d.date, 'day'),
              );

              if (isValidDate?.isEditable) {
                return isFilled?.isAnswered ? 'tile__filled' : 'tile__not__filled';
              }

              return isValidDate ? 'title__future' : 'title__out_of_range';
            }}
          />
        </Stack>
      )}
    </>
  );
}
