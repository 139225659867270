import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';

export interface IParticipantMedication {
  readonly id: string;
  title: string;
  description: string;
  date: string;
  category: string;
}

export type IParticipantMedications = Array<IParticipantMedication>;

export const ParticipantMedicationsContext = createContext<IParticipantMedications>([]);

export const ParticipantMedicationsProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USER_MEDICATIONS });
  const contextValue = useMemo((): IParticipantMedications => data || [], [data]);

  return (
    <ParticipantMedicationsContext.Provider value={contextValue}>
      {children}
    </ParticipantMedicationsContext.Provider>
  );
};

export const useParticipantMedications = () => useContext(ParticipantMedicationsContext);
