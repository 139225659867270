import { ReactElement } from 'react';
import { AssessmentQuestionEntity } from '../../../graphql';
import { renderQuestion } from '../../callnotes/Question';

interface AnswerableAssessmentQuestionEntity extends AssessmentQuestionEntity {
  answer: string[];
  libraryId: string;
  comment: string;
}

interface QuestionProperties {
  question: AnswerableAssessmentQuestionEntity;
  handleQuestionAnswered: (questionId: string, values: string[]) => void;
}

const Question = ({
  question,
  handleQuestionAnswered,
}: QuestionProperties): ReactElement | null => {
  return renderQuestion(question, handleQuestionAnswered);
};

export default Question;
