import { Box, FormGroup, Typography, Button } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from '../../../theme';
import { Image } from '../Image/Image';

interface ImageSelectorProperties {
  index?: number;
  label?: string;
  value?: string;
  options: string[];
  required?: boolean;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
  onChange: (value: string) => void;
}

export const ImageSelector = ({
  index,
  label,
  required,
  value,
  showBoldLabel,
  onChange,
  options,
  hideLabel = false,
}: ImageSelectorProperties) => {
  const handleRangeChanged = (newValue: string) => {
    if (onChange) {
      onChange(newValue === value ? '' : newValue);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        {!hideLabel && (
          <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
            {index}
            {index && '.'} {label} {required && '*'}
          </Typography>
        )}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {options.map((item) => (
            <Button
              data-testid="block-selector-button"
              key={`item-${item}`}
              size="large"
              color="secondary"
              variant="outlined"
              sx={{
                position: 'relative',
                m: 1,
                width: 200,
                maxWidth: 200,
                borderWidth: 2,
                borderColor: value === item ? theme.palette.primary.main : theme.palette.grey[200],
                flexBasis: '45%',
              }}
              onClick={() => handleRangeChanged(item)}
            >
              {value === item && (
                <CheckCircleIcon
                  color="primary"
                  sx={{ position: 'absolute', top: 5, left: 5, zIndex: 1 }}
                />
              )}
              <Image width="100%" src={item} />
            </Button>
          ))}
        </Box>
      </FormGroup>
    </Box>
  );
};
