import { logger } from '../../utils';
import { MediaPermissionName } from './types';

export const checkMediaPermission = async (
  permissionName: MediaPermissionName,
): Promise<'granted' | 'denied' | 'prompt'> => {
  if (!navigator.permissions) {
    logger.error('Permissions API is not available');
  }

  const permissionStatus = await navigator.permissions.query({
    name: permissionName as PermissionName,
  });

  return permissionStatus.state;
};
