import { SVGProps } from 'react';

const MissedDirectCallSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...properties}>
    <path
      fill="#E53935"
      d="M2.413 5.193a10.1 10.1 0 0 0 4.394 4.394L8.273 8.12c.18-.18.447-.24.68-.16.747.247 1.554.38 2.38.38.367 0 .667.3.667.667v2.326c0 .367-.3.667-.667.667C5.073 12 0 6.927 0 .667 0 .3.3 0 .667 0H3c.367 0 .667.3.667.667 0 .833.133 1.633.38 2.38a.669.669 0 0 1-.167.68L2.413 5.193Z"
    />
  </svg>
);
export default MissedDirectCallSvg;
