export const invariant = (condition: any, format: string, ...rest: any[]) => {
  if (process.env.NODE_ENV !== 'production' && format === undefined) {
    throw new Error('invariant requires an error message argument');
  }

  if (!condition) {
    let error;
    if (format === undefined) {
      error = new Error(
        'Minified exception occurred; use the non-minified dev environment ' +
          'for the full error message and additional helpful warnings.',
      );
    } else {
      let index = 0;
      // eslint-disable-next-line no-return-assign
      error = new Error(format.replace(/%s/g, () => rest[(index += 1)]));
      error.name = 'Invariant Violation';
    }

    throw error;
  }
};
