import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ENDPOINTS } from '../../constants';
import { IRole, IRoles } from '../../types/role';
import { useAxios } from '../../hooks';

export type IRolesContextType = {
  roles: IRoles;
  saveRole: (role: IRole) => void;
  deleteRoles: (names: string[]) => void;
};

export const RolesContext = createContext<IRolesContextType | null>(null);

export const RolesProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.ROLES });
  const [rolesData, setRolesData] = useState<IRoles>([]);

  useEffect(() => {
    if (rolesData?.length === 0) {
      setRolesData(data || []);
    }
  }, [data, rolesData?.length]);

  const saveRole = useCallback(
    (role: IRole) => {
      const newNote: IRole = {
        ...role,
        id: uuidv4(),
      };
      if (role.id) {
        setRolesData(rolesData?.map((item) => (item.id === role.id ? role : item)) || []);
      } else {
        setRolesData([...(rolesData || []), newNote]);
      }
    },
    [setRolesData, rolesData],
  );

  const deleteRoles = useCallback(
    (names: string[]) => {
      setRolesData(rolesData!.filter((role) => !names.includes(role.name)));
    },
    [rolesData, setRolesData],
  );

  const contextValue = useMemo(
    (): IRolesContextType => ({
      roles: rolesData || [],
      saveRole,
      deleteRoles,
    }),
    [rolesData, saveRole, deleteRoles],
  );

  return <RolesContext.Provider value={contextValue}>{children}</RolesContext.Provider>;
};

export const useRoles = () => useContext(RolesContext);
