import { EmailOutlined, SmsOutlined, VoicemailOutlined } from '@mui/icons-material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { HTMLAttributes, ReactElement, useCallback } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid } from '@mui/material';
import { dayjs } from '../../utils/dayjs';
import { DataRowGrid } from '../atoms/TabbedCard/DataRowGrid';
import { CallConferencesQuery, EmailEntity, MessageEntity, VoiceEntity } from '../../graphql';
import { ContentLoader, TabbedCard } from '../atoms';
import { COLLABORATIONS, COLLABORATION_LABELS, FORMATS, PAGES } from '../../constants';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  profileId?: number;
  emails: Array<EmailEntity> | null;
  messages: Array<MessageEntity> | null;
  voices: Array<VoiceEntity> | null;
  conferences?: CallConferencesQuery | undefined;
  loading: boolean;
}

export default function Activities({
  emails,
  messages,
  voices,
  conferences,
  loading,
  profileId,
}: Properties) {
  const { t } = usePreferredTranslation();
  const getHeadline = useCallback(
    (sender: IUser, receiver: IUser, icon: ReactElement<SVGElement>) => {
      return (
        <Grid container spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <strong>{t('From')}: </strong>{' '}
            {profileId === Number(sender?.id) ? <strong>{sender?.name}</strong> : sender?.name}
            {receiver && (
              <>
                {`  •  `}
                <strong>{t('To')}: </strong>{' '}
                {profileId === Number(receiver?.id) ? (
                  <strong>{receiver?.name}</strong>
                ) : (
                  receiver?.name
                )}
              </>
            )}
          </Grid>
        </Grid>
      );
    },
    [profileId],
  );

  const recordings = conferences?.callConferences?.items || [];

  const activities = [
    ...(emails?.map((email: EmailEntity) => ({
      id: email.id.toString(),
      type: COLLABORATIONS.EMAIL,
      date: dayjs(email.createdAt).format(FORMATS.DATE),
      headline: getHeadline(email.sender, email.receiver, <EmailOutlined />),
      subtitle: email.title.slice(0, 120) + (email.title.length > 120 ? '...' : ''),
      router: {
        content: `${PAGES.MESSAGING}/${COLLABORATIONS.EMAIL?.toLowerCase() ?? ''}?userId=${
          (profileId || email.sender?.id) as number
        }`,
      },
    })) || []),
    ...(messages?.map((message: MessageEntity) => ({
      id: message.id.toString(),
      type: COLLABORATIONS.TEXT,
      date: dayjs(message.createdAt).format(FORMATS.DATE),
      headline: getHeadline(message.sender, message.receiver, <SmsOutlined />),
      subtitle: message.title.slice(0, 120) + (message.title.length > 120 ? '...' : ''),
      notes: message.description,
      router: {
        content: `${PAGES.MESSAGING}/${COLLABORATIONS.TEXT?.toLowerCase() ?? ''}?userId=${
          (profileId || message.sender?.id) as number
        }`,
      },
    })) ?? []),
    ...(voices?.map((voice: VoiceEntity) => ({
      id: voice.id.toString(),
      type: COLLABORATIONS.VOICE,
      date: dayjs(voice.createdAt).format(FORMATS.DATE),
      headline: getHeadline(voice.sender, voice.receiver, <VoicemailOutlined />),
      subtitle: voice.title,
      // TODO: attachment: voice.data ? <Waveform url={voice.data} /> : null,
      router: {
        content: `${PAGES.MESSAGING}/${COLLABORATIONS.VOICE?.toLowerCase() ?? ''}?userId=${
          (profileId || voice.sender?.id) as number
        }`,
      },
    })) ?? []),
    ...recordings.map((recording) => ({
      id: recording.id,
      type: COLLABORATIONS.RECORDING,
      date: dayjs(recording.createdAt).format(FORMATS.DATE),
      headline: getHeadline(
        recording.fromUser as IUser,
        recording.toUser as IUser,
        <RadioButtonCheckedIcon />,
      ),
      subtitle: recording.title || '',
      router: {
        content: `${PAGES.MESSAGING}/${COLLABORATIONS.RECORDING?.toLowerCase() ?? ''}?userId=${
          (profileId || recording.toUser?.id) as number
        }`,
      },
    })),
  ].sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1));

  return (
    <TabbedCard
      title={t('Activity')}
      ariaLabel="participant activity tabs"
      sx={{
        p: 3,
        mb: 3,
        minWidth: 160,
      }}
      tabs={Object.values(COLLABORATIONS).map((tab) => {
        const tabActivities = activities?.filter(
          (activity) => activity.type === tab || tab === COLLABORATIONS.ALL,
        );
        const label = COLLABORATION_LABELS.get(tab) as string;

        return {
          label,
          data: tabActivities?.length ? (
            tabActivities.map((dataRow, rowIndex) => (
              <DataRowGrid
                key={`${dataRow.type}_${dataRow.id || rowIndex}`}
                {...dataRow}
                divider={rowIndex > 0}
                primaryAction={<ChevronRightIcon />}
              />
            ))
          ) : (
            <div
              style={{
                marginTop: '2rem',
                textAlign: 'center',
              }}
            >
              {loading ? (
                <ContentLoader
                  speed={2}
                  width="99%"
                  viewBox="0 0 3000 510"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="10" y="0" rx="3" ry="3" width="100%" height="150" />
                  <rect x="10" y="180" rx="3" ry="3" width="80%" height="150" />
                  <rect x="10" y="360" rx="3" ry="3" width="90%" height="150" />
                </ContentLoader>
              ) : (
                <span>{t('No activity found.')}</span>
              )}
            </div>
          ),
        };
      })}
    />
  );
}
