import React, { FC } from 'react';
import Pusher from 'pusher-js';
import { CorePusherProvider } from './PusherProvider';
import { PusherProviderProperties } from './types';

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;

/**
 * Wrapper around the core PusherProvider that passes in the Pusher lib
 *
 * @param properties
 */
export const PusherProvider: FC<PusherProviderProperties> = (
  properties: PusherProviderProperties,
) => <CorePusherProvider _PusherRuntime={Pusher} {...properties} />;

export * from './usePusher';
export * from './useChannel';
export * from './usePresenceChannel';
export * from './useEvent';
export * from './useEvents';
export * from './useClientTrigger';
export * from './useChannels';
export * from './useTrigger';
export * from './ChannelsProvider';
