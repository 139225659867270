import { SVGProps } from 'react';

const DistanceIcon = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g clipPath="url(#a)">
      <path
        fill="#6C5DD3"
        d="M20 8.333a8.16 8.16 0 0 0-8.166 8.167c0 2.03.583 3.931 1.645 5.646 1.108 1.797 2.566 3.337 3.686 5.134.549.875.945 1.691 1.365 2.636.303.642.548 1.75 1.47 1.75.922 0 1.167-1.108 1.459-1.75.431-.945.816-1.761 1.364-2.636 1.12-1.785 2.579-3.325 3.687-5.134 1.073-1.715 1.657-3.616 1.657-5.646A8.16 8.16 0 0 0 20 8.333Zm0 11.375a2.918 2.918 0 0 1 0-5.833 2.918 2.918 0 0 1 0 5.833Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6 6h28v28H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default DistanceIcon;
