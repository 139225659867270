export const LANGUAGES = {
  SPANISH: {
    label: 'Spanish',
    shortName: 'es',
    value: 'es-US',
    flag: '🇪🇸',
  },
  ENGLISH: {
    label: 'English',
    shortName: 'en',
    value: 'en-US',
    flag: '🇬🇧',
  },
  FRENCH: {
    label: 'French',
    shortName: 'fr',
    value: 'fr-US',
    flag: '🇬🇧',
  },
};
