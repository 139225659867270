import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';
import { IActivityData } from '../../types/user';
import { IResponseBase } from '../../types/axios';

interface IResponse extends IResponseBase {
  data: IActivityData;
}

const defaultResponse: IResponse = {
  data: [],
  loading: true,
};

export const ParticipantActivityContext = createContext<IResponse>(defaultResponse);

export const ParticipantActivityProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useAxios({ url: ENDPOINTS.PARTICIPANT_ACTIVITIES });

  const contextValue = useMemo((): IResponse => {
    return { data, loading };
  }, [data, loading]);

  return (
    <ParticipantActivityContext.Provider value={contextValue}>
      {children}
    </ParticipantActivityContext.Provider>
  );
};

export const useParticipantActivity = () => useContext(ParticipantActivityContext);
