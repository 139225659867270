import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';
import AdherenceTimeline from './AdherenceTimeline';
import { AdherenceMetricsQuery } from '../../../../../graphql';
import { getAdherenceMetricsByWeek } from '../../../../../utils/adherence';

interface Properties {
  open: boolean;
  onClose: () => void;
  adherenceData: AdherenceMetricsQuery | undefined;
  userStartDate: string | Date;
}

const AdherenceModal: React.FC<Properties> = ({ onClose, open, adherenceData, userStartDate }) => {
  const { t } = usePreferredTranslation();
  const adherenceMetrics = useMemo(() => {
    const metrics = adherenceData?.adherenceMetrics.adherenceItems || [];

    if (metrics.length === 0) {
      return [];
    }

    return getAdherenceMetricsByWeek(userStartDate, new Date(), metrics);
  }, [adherenceData, userStartDate]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minHeight: '80vh',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('Adherence')}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: '100%' }}>
        <AdherenceTimeline adherenceMetrics={adherenceMetrics} />
      </DialogContent>
    </Dialog>
  );
};

export default AdherenceModal;
