import { useState, useCallback } from 'react';
import { Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { useUpdateAssessmentAnswerMutation } from '../../../graphql';
import { theme } from '../../../theme';
import { TextareaField } from '../../atoms';
import Question from './Question';
import QuestionTypes from '../../organisms/EditableQuestion/QuestionTypes';
import { Image } from '../../atoms/Image/Image';

const Answer = ({ answer, assessmentResponseId, onAnswerUpdate }: any) => {
  const { t } = usePreferredTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<string[]>(answer.values);
  const [newComment, setNewComment] = useState<string>(answer.comment);
  const [updateAssessmentAnswer] = useUpdateAssessmentAnswerMutation();

  const onSubmitAnswer = useCallback(async () => {
    setIsSaving(true);
    const { errors } = await updateAssessmentAnswer({
      variables: {
        id: Number(assessmentResponseId),
        updateAssessmentAnswerInput: {
          values: newValues,
          comment: newComment,
          questionId: Number(answer.question?.id),
        },
      },
    });
    if (errors) {
      toast.error(t('There was an error saving your response'));
    }

    setIsEditing(false);
    setIsSaving(false);
    onAnswerUpdate();
  }, [
    answer.question?.id,
    assessmentResponseId,
    newComment,
    newValues,
    onAnswerUpdate,
    updateAssessmentAnswer,
  ]);

  const isImagePickerAnswer = answer.question?.type === QuestionTypes.imagePicker;

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        mb: 4,
        pb: 2,
      }}
      key={answer?.id}
    >
      {!isEditing && (
        <>
          <Typography sx={{ fontWeight: 600 }}>{answer.question?.label}</Typography>
          <Box
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mt: 1,
            }}
          >
            {isImagePickerAnswer ? (
              <div>
                {newValues?.map((value, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      m: 1,
                      width: 200,
                      maxWidth: 200,
                    }}
                    key={index}
                  >
                    <Image width="100%" src={value} />
                  </Box>
                ))}
              </div>
            ) : (
              <Typography
                component="span"
                sx={{
                  display: 'inline-block',
                  fontSize: theme.typography.h5,
                  maxWidth: '100%',
                  overflowWrap: 'anywhere',
                }}
              >
                {newValues?.join(', ')}
              </Typography>
            )}
            <Button onClick={() => setIsEditing(true)}>{t('Edit')}</Button>
          </Box>
        </>
      )}
      {isEditing && (
        <Box>
          <Question
            question={{
              ...answer.question,
              answer: newValues,
            }}
            handleQuestionAnswered={(questionId, values) => {
              setNewValues(values);
            }}
          />
          <Box sx={{ maxWidth: 300, pt: 2 }}>
            <TextareaField
              value={newComment}
              label={t('Note (optional)')}
              onChange={(event) => setNewComment(event.target.value)}
            />
          </Box>
          <Button variant="contained" disabled={isSaving} onClick={onSubmitAnswer}>
            {t('Save')}
          </Button>
        </Box>
      )}
      {!isEditing && answer.comment && (
        <Box sx={{ maxWidth: 300, pt: 2 }}>
          <Typography variant="caption">{t('Note (optional):')}</Typography>
          <Typography>{newComment}</Typography>
        </Box>
      )}
      <Typography sx={{ mt: 2 }}>
        {t('Answered')} {new Date(answer.updatedAt).toISOString().split('T')[0]}
      </Typography>
    </Box>
  );
};
export default Answer;
