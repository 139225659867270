import { ReactElement } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { Image } from '../Image';

type Properties = BoxProps;

const COMPANY_LOGO_URL = '/images/logo/Syl_logo_3c_Full.png';

export function CompanyLogo({ children, ...rest }: Properties): ReactElement<Properties> {
  return (
    <Box data-testid="companyLogoComponent" display="flex" alignItems="center" {...rest}>
      <Box
        sx={{
          overflow: 'hidden',
          height: 48,
          mr: 1,
        }}
      >
        <Image
          src={COMPANY_LOGO_URL}
          alt="Company Logo"
          height="48px"
          data-testid="companyLogoImage"
          style={{ maxWidth: '100%' }}
        />
      </Box>
      {children && (
        <Typography data-testid="companyLogoTitle" variant="h6">
          {children}
        </Typography>
      )}
    </Box>
  );
}
