import { request } from '@axios-use/react';
import { UserEntity } from '../../graphql';
import { getToken } from '../../utils';

export function getActivity(user: IFitbitUserInput, date?: Date | string) {
  const { fitbitAccessToken, fitbitId } = user;

  if (!fitbitAccessToken) {
    throw new Error('An access token is required to gather user activities from Fitbit');
  }

  if (!fitbitId) {
    throw new Error('User ID is required to gather user activities from Fitbit');
  }

  return request({
    url: `${process.env.REACT_APP_FITBIT_API_HOST}/1/user/${fitbitId}/activities/date/${date}.json`,
    headers: {
      authorization: `Bearer ${fitbitAccessToken}`,
    },
  });
}

export function getLifetimeActivityStats(user: IFitbitUserInput) {
  const { fitbitAccessToken, fitbitId } = user;

  if (!fitbitAccessToken) {
    throw new Error('An access token is required to gather user activities from Fitbit');
  }

  return request({
    url: `${process.env.REACT_APP_FITBIT_API_HOST}/1/user/${fitbitId}/activities.json`,
    headers: {
      authorization: `Bearer ${fitbitAccessToken}`,
    },
  });
}

export function disconnect(user: IFitbitUserInput) {
  const { fitbitAccessToken } = user;

  if (!fitbitAccessToken) {
    throw new Error('An access token is required to disconnect from Fitbit account');
  }

  return request({
    url: `${process.env.REACT_APP_API_URL}/fitbit/disconnect/${fitbitAccessToken}`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
}

/**
 * Returns the users activities trend from Fitbit
 *
 * @param {UserEntity} user
 * @param {string} resource
 * @param {Date|string} date
 * @param {string} period
 *
 * For a list of possible resources, see:
 * https://dev.fitbit.com/build/reference/web-api/activity-timeseries/get-activity-timeseries-by-date/#Resource-Options
 */
export function useActivityTrend(
  user: IFitbitUserInput,
  resource: string,
  date?: Date | string,
  period?: string,
) {
  const { fitbitId, fitbitAccessToken } = user;

  if (!fitbitAccessToken) {
    throw new Error('An access token is required to gather user activities from Fitbit');
  }

  if (!resource) {
    throw new Error('A resource is required to gather user activities from Fitbit');
  }

  return request({
    url: `${process.env.REACT_APP_FITBIT_API_HOST}/1/user/${fitbitId}/activities/${resource}/date/${date}/${period}.json`,
    headers: {
      authorization: `Bearer ${fitbitAccessToken}`,
    },
  });
}

export function getDevices(user: IFitbitUserInput) {
  const { fitbitId, fitbitAccessToken } = user;

  if (!fitbitAccessToken) {
    throw new Error('An access token is required to gather user devices from Fitbit');
  }

  return request({
    url: `${process.env.REACT_APP_FITBIT_API_HOST}/1/user/${fitbitId}/devices.json`,
    headers: {
      authorization: `Bearer ${fitbitAccessToken}`,
    },
  });
}
