import { SVGProps } from 'react';

const ExerciseGenericSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g clipPath="url(#a)">
      <path
        fill="#6C5DD3"
        d="M23.05 8.785h-6.1v2.033h6.1V8.785ZM18.982 22h2.033V15.9h-2.033V22Zm8.162-6.719 1.444-1.443a11.23 11.23 0 0 0-1.433-1.433l-1.444 1.443A9.11 9.11 0 0 0 20 11.835a9.149 9.149 0 0 0-9.148 9.148A9.142 9.142 0 0 0 20 30.13a9.142 9.142 0 0 0 7.145-14.85ZM20 28.098a7.11 7.11 0 0 1-7.116-7.115A7.11 7.11 0 0 1 20 13.867a7.11 7.11 0 0 1 7.115 7.116A7.11 7.11 0 0 1 20 28.098Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M7 7h26v26H7z" />
      </clipPath>
    </defs>
  </svg>
);
export default ExerciseGenericSvg;
