import { HTMLAttributes, ReactElement, useCallback } from 'react';
import { FormControl, InputLabel, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { usePreferredTranslation } from 'src/hooks/usePreferredTranslation';
import { theme } from '../../../theme';

interface Properties extends HTMLAttributes<HTMLElement> {
  readonly disabled?: boolean;
  readonly label?: string;
  readonly minutesStep?: number;
  readonly value?: string | null;
  readonly placeholder?: string;
  readonly onChange?: (event: any) => void;
  sx?: any;
}

export function DateTimeField({
  disabled = false,
  label,
  minutesStep = 15,
  value,
  placeholder,
  sx = {},
  onChange,
}: Properties): ReactElement<Properties> {
  const { i18n } = usePreferredTranslation();
  const handleChange = useCallback(
    (event: any) => {
      onChange?.(event);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2 }} data-testid="datetime-field">
      <Stack spacing={3}>
        <InputLabel
          shrink
          sx={{
            ml: '2px',
            transform: 'scale(1)',
            color: theme.palette.common.black,
            fontWeight: 500,
          }}
        >
          {label}
        </InputLabel>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language === 'es' ? 'es' : 'en'}
          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <DateTimePicker
            minutesStep={minutesStep}
            disabled={disabled}
            label={placeholder}
            value={value}
            onChange={handleChange}
            renderInput={(parameters) => <TextField {...parameters} sx={sx} />}
          />
        </LocalizationProvider>
      </Stack>
    </FormControl>
  );
}
