import { ChangeEvent, ReactElement } from 'react';
import { FormControl, InputLabel, InputAdornment, InputBaseProps } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { theme } from '../../../theme';

interface Properties extends InputBaseProps {
  index?: number;
  label?: string;
  value?: string;
  type?: string;
  error?: boolean;
  iconStart?: ReactElement;
  iconEnd?: ReactElement;
  wrapperSx?: object;
  labelSx?: object;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const StyledInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    position: 'relative',
  },
  'label + &.Mui-focused': {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

export function InputField({
  index,
  label,
  value,
  placeholder,
  type,
  iconStart,
  iconEnd,
  onChange,
  wrapperSx,
  labelSx,
  ...rest
}: Properties): ReactElement<Properties> {
  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2, ...wrapperSx }}>
      <InputLabel
        shrink
        sx={{
          ml: '2px',
          transform: 'scale(1)',
          color: theme.palette.common.black,
          fontWeight: 500,
          ...labelSx,
        }}
      >
        {index && `${index}. `}
        {label}
      </InputLabel>
      <StyledInput
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        startAdornment={
          iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null
        }
        endAdornment={iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null}
        {...rest}
      />
    </FormControl>
  );
}
