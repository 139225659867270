export const researchActivity = [
  {
    id: '007f1f77bcf86cd799439011',
    title: 'Colon cancer',
    nbParticipants: 43,
    date: 'yesterday',
    nbIssues: 2,
    category: 'Projects',
  },
  {
    id: '107f1f77bcf86cd799439011',
    title: 'COVID-19 study',
    nbParticipants: 217,
    date: 'Tue, 25 Jun',
    nbIssues: 12,
    category: 'Projects',
  },
  {
    id: '207f1f77bcf86cd799439011',
    title: 'Mock study',
    nbParticipants: 98,
    date: 'Wed, 19 Jun',
    nbIssues: 0,
    category: 'Projects',
  },
];
