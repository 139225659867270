import { useCallback, ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Divider, Paper, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { green, grey } from '@mui/material/colors';
import { LibraryEntity } from '../../../../graphql';
import { Image } from '../../Image';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { useActiveJournal } from '../../../../states/journal/useActiveJournal';

interface Properties {
  journal: LibraryEntity;
  completed?: boolean;
}

export function JournalCard({ journal, completed }: Properties): ReactElement | null {
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:750px)');
  const { setActiveJournal, setIncompleteModalJournal } = useActiveJournal();

  const handleAccept = useCallback(() => {
    setActiveJournal(journal);
  }, [journal, setActiveJournal]);

  const handleDecline = useCallback(() => {
    setIncompleteModalJournal(journal);
  }, [journal, setIncompleteModalJournal]);

  return (
    <Paper
      sx={{
        minWidth: sm ? 259 : 141,
        borderRadius: 2,
        overflow: 'hidden',
        border: `1px solid ${grey[300]}`,
        boxShadow: 'none',
      }}
    >
      <Box
        width="100%"
        height={178}
        sx={{ position: 'relative', overflow: 'hidden' }}
        onClick={handleAccept}
      >
        <Image
          style={{
            width: '259px',
            cursor: 'pointer',
            marginLeft: window.innerWidth > 600 ? 0 : '-40px',
            position: 'relative',
          }}
          src={journal.icon || ''}
        />
        <Box sx={{ position: 'absolute', bottom: 0, ml: 1.5, mb: 1.5 }}>
          <Typography sx={{ mb: 0.25, fontWeight: 600, color: 'white' }}>
            {journal.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              background: completed ? green[500] : 'white',
              color: completed ? 'white' : 'inherit',
              px: 0.5,
              py: 0.25,
              borderRadius: 1,
              display: 'inline-block',
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {completed
              ? t('journal.completed')
              : t('journal.time.duration', { duration: journal.duration })}
          </Typography>
        </Box>
      </Box>
      <Box
        flexDirection="row"
        display="flex"
        justifyContent="space-evenly"
        sx={{ caretColor: 'transparent' }}
      >
        <Box
          sx={{
            pt: 1,
            pb: 1,
            cursor: completed ? 'unset' : 'pointer',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
          onClick={() => !completed && handleAccept()}
        >
          <DoneIcon sx={{ color: grey[400] }} />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            pt: 1,
            pb: 1,
            cursor: completed ? 'unset' : 'pointer',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
          onClick={() => !completed && handleDecline()}
        >
          <CloseIcon sx={{ color: grey[400] }} />
        </Box>
      </Box>
    </Paper>
  );
}
