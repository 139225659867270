import { Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { ChangeEvent, HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MailIcon from '@mui/icons-material/Mail';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '../../../utils';
import { DialogCloseButton } from '../../atoms';
import { PAGES, PhoneNumberType } from '../../../constants';
import { CountryPhoneNumberEntity, Maybe, Scalars } from '../../../graphql';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  open?: boolean;
  user: IUser;
  onClose?: () => void;
}

interface CallDeviceOptionProperties {
  isSelected?: boolean;
  country?: Maybe<CountryPhoneNumberEntity>;
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  label: Scalars['String'];
  type: Scalars['String'];
}

const CallDeviceOption = ({
  type,
  label,
  isPreferred,
  country,
  isSelected,
}: CallDeviceOptionProperties) => {
  const theme = useTheme();
  const formattedNumber = formatPhoneNumber(country);

  return (
    <Stack direction="row" alignItems="center">
      <Stack flex={1} direction="row" alignItems="center">
        <IconButton
          aria-label="call type"
          size="large"
          sx={{
            border: `1px solid ${isSelected ? theme.palette.primary.main : grey[300]}`,
            color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
          }}
        >
          {type === 'mobile' ? <PhoneIphoneOutlinedIcon /> : <PhoneCallbackOutlinedIcon />}
        </IconButton>
        <Box sx={{ pl: 1.5 }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            {label}
          </Typography>
          <Typography variant="body1">
            {country?.countryCallingCode || ''}
            {formattedNumber}
          </Typography>
        </Box>
      </Stack>
      <Typography
        variant="caption"
        sx={{ fontStyle: 'italic', color: theme.palette.text.secondary, pr: 1 }}
      >
        {isPreferred && 'preferred'}
      </Typography>
    </Stack>
  );
};

export default function SendMessageDeviceModal({ open = false, user, onClose }: Properties) {
  const { t } = usePreferredTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');

  const handleClose = useCallback(() => onClose && onClose(), [onClose]);

  const handleStartWriting = () => {
    const phoneNumber = user?.phoneNumbers?.find(({ id }) => id === selectedOption);

    if (phoneNumber) {
      navigate(`${PAGES.MESSAGING}/text?userId=${user?.id || ''}`, {
        state: {
          phoneNumber,
        },
      });
    }
  };

  const handleChangeOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack spacing={1}>
          <Box sx={{ mb: 2 }}>
            <Typography component="p" variant="h5">
              {t('Select a number to send SMS/MMS')}
            </Typography>
            <DialogCloseButton onClick={handleClose} />
          </Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
              value={selectedOption}
              onChange={handleChangeOption}
            >
              {user?.phoneNumbers
                ?.filter(({ type }) => type === PhoneNumberType.SMS)
                .map(({ id, type, label, isPreferred, country }) => (
                  <FormControlLabel
                    key={id}
                    value={id}
                    label={
                      <CallDeviceOption
                        id={id}
                        type={type}
                        label={label}
                        isPreferred={isPreferred}
                        country={country}
                        isSelected={id === selectedOption}
                      />
                    }
                    control={<Radio sx={{ color: grey[300] }} />}
                    labelPlacement="start"
                    sx={{
                      p: 2,
                      m: 0,
                      borderRadius: theme.typography.pxToRem(12),
                      background:
                        id === selectedOption
                          ? alpha(theme.palette.primary.main, 0.12)
                          : theme.palette.common.white,
                      '& .MuiFormControlLabel-label': {
                        flex: 1,
                      },
                    }}
                  />
                ))}
            </RadioGroup>
          </FormControl>

          <Stack alignItems="flex-end">
            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
              <Button variant="outlined" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={handleStartWriting}
                disabled={!selectedOption}
                startIcon={<MailIcon />}
              >
                {t('Start writing message')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
