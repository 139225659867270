import { ReactElement, useCallback } from 'react';
import {
  RadioGroupProps,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';

interface Properties extends RadioGroupProps {
  label?: string;
  value?: string;
  options: string[] | null;
  index?: number;
  required?: boolean;
  onChange?: (event: any) => void;
  showBoldLabel?: boolean;
  hideLabel?: boolean;
}

export const RadioButtonList = ({
  label,
  value = '',
  options,
  index,
  required,
  onChange,
  showBoldLabel,
  hideLabel = false,
  ...rest
}: Properties): ReactElement<Properties> => {
  const handleChange = useCallback(
    (event: any) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  return (
    <Box sx={{ mb: 2 }}>
      {!hideLabel && (
        <Typography variant={showBoldLabel ? 'h5' : 'body2'}>
          {index}
          {index && '.'} {label} {required && '*'}
        </Typography>
      )}
      <RadioGroup value={value} {...rest} onChange={handleChange}>
        {options?.map((option, index_) => (
          <FormControlLabel
            key={index_}
            value={option}
            label={option}
            control={<Radio sx={{ color: grey[300] }} />}
            sx={{
              m: 0,
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioButtonList;
