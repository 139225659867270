import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCountMessagesQuery } from '../../graphql';
import { useCurrentUser } from '../../states/user/useCurrentUser';
import { useChannel, useEvents } from '../../modules';
import { PUSHER } from '../../constants';

export type MessagesAnalytics = {
  readonly viewedCount: number;
  readonly notViewedCount: number;
  readonly likedCount: number;
  readonly notLikedCount: number;
  readonly count: number;
};

const defaultValue: MessagesAnalytics = {
  viewedCount: 0,
  notViewedCount: 0,
  likedCount: 0,
  notLikedCount: 0,
  count: 0,
};

export const MessagesAnalyticsContext = createContext<MessagesAnalytics>(defaultValue);

export const MessagesAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();

  const notViewedCountMessagesQuery = useCountMessagesQuery({
    variables: { where: { viewed: false } },
  });
  const notLikedCountMessagesQuery = useCountMessagesQuery({
    variables: { where: { liked: false } },
  });
  const countMessagesQuery = useCountMessagesQuery();

  const contextValue: MessagesAnalytics = useMemo<MessagesAnalytics>((): MessagesAnalytics => {
    const notViewedCount = notViewedCountMessagesQuery.data?.countMessages || 0;
    const notLikedCount = notLikedCountMessagesQuery.data?.countMessages || 0;
    const count = countMessagesQuery.data?.countMessages || 0;
    const viewedCount = count - notViewedCount;
    const likedCount = count - notLikedCount;

    return {
      ...defaultValue,
      viewedCount,
      notViewedCount,
      likedCount,
      notLikedCount,
      count,
    };
  }, [notViewedCountMessagesQuery, notLikedCountMessagesQuery, countMessagesQuery]);

  const messagesChannel = useChannel(PUSHER.CHANNELS.MESSAGES);

  useEvents(
    messagesChannel,
    [
      `${PUSHER.EVENTS.MESSAGE_CREATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGE_DELETED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGE_UPDATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.MESSAGES_UPDATED}.${currentUser.data?.user?.id}`,
    ],
    () => {
      notViewedCountMessagesQuery.refetch();
      notLikedCountMessagesQuery.refetch();
      countMessagesQuery.refetch();
    },
  );

  return (
    <MessagesAnalyticsContext.Provider value={contextValue}>
      {children}
    </MessagesAnalyticsContext.Provider>
  );
};

export const useMessagesAnalytics = () => useContext(MessagesAnalyticsContext);
