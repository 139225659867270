import { addDays, subDays } from 'date-fns';
import { LazyQueryExecFunction } from '@apollo/client';
import { dayjs } from '../dayjs';
import {
  AssessmentQuestionEntity,
  Exact,
  GraphDataPointsInput,
  GraphDataPointsQuery,
  LibraryQuery,
} from '../../graphql';
import { isNumericString, tryParseNumber } from '../format';
import QuestionTypes from '../../components/organisms/EditableQuestion/QuestionTypes';

export const calculateOffsetDays = (date: dayjs.Dayjs, startDate: dayjs.Dayjs) => {
  return date.startOf('day').diff(startDate.startOf('day'), 'days');
};

const parseBooleanToNumber = (value: string) => {
  return value.toLowerCase() === 'yes' ? 1 : 0;
};

export const getDataValue = (data: any, label: string | undefined) => {
  if (data.question?.type === QuestionTypes.boolean) {
    const value = data.values?.[0];

    if (!value) return null;

    return parseBooleanToNumber(data.values?.[0]);
  }

  if (data.question?.type === 'human-body') {
    return data.values.name === label ?? '' ? data.values?.painLevel : null;
  }

  if (
    data.question?.type === 'exercise-question' ||
    data.question?.type === 'resistance-bands' ||
    data.question?.type === 'resistance-free-weight' ||
    data.question?.type === 'resistance-body-weight' ||
    data.question?.type === 'cardio'
  ) {
    return tryParseNumber(data.values);
  }

  return tryParseNumber(data.values?.[0] ?? data.value);
};

export const transformDataSourceForCustomTypes = async (
  dataSource: AssessmentQuestionEntity,
  graphDataQuery: LazyQueryExecFunction<
    GraphDataPointsQuery,
    Exact<{
      graphDataPointsInput: GraphDataPointsInput;
    }>
  >,
  getLibraryQuery: LazyQueryExecFunction<
    LibraryQuery,
    Exact<{
      id: number;
    }>
  >,
  profileId: number,
  metaDataSourceType?: string,
  metaDataSourceValue?: string,
) => {
  if (!dataSource) return null;

  const currentDate = new Date();
  const fromDate = subDays(currentDate, 30).toISOString();
  const toDate = addDays(currentDate, 1).toISOString();
  const resistanceQuestions = new Set([
    'resistance-bands',
    'resistance-body-weight',
    'resistance-free-weight',
  ]);
  const typesToFilter = new Set(['human-body']);

  const dataSourceState: {
    id: string;
    questions: Partial<AssessmentQuestionEntity[]>;
    label: string;
    type: string;
  } = {
    id: dataSource.id,
    questions: [],
    label: metaDataSourceValue || dataSource.label,
    type: metaDataSourceType || (dataSource.type as string),
  };

  if (
    dataSource.type === '' ||
    dataSource.type === 'assessment' ||
    metaDataSourceType === 'coaching' ||
    metaDataSourceType === 'coaching'
  ) {
    const whiteListedQuestionTypes = new Set([
      QuestionTypes.plusMinus,
      QuestionTypes.range5,
      QuestionTypes.range10,
      QuestionTypes.slider,
      QuestionTypes.boolean,
    ]);

    const { data: coachingLibraryData } = await getLibraryQuery({
      variables: {
        id: Number(dataSource.id),
      },
    });

    const library = coachingLibraryData?.library;

    const questions = library?.questions?.filter((question) =>
      whiteListedQuestionTypes.has(question.type ?? ''),
    );

    return {
      questions,
      label: library?.title,
      type: 'coaching',
      id: dataSource.id,
    };
  }

  if (dataSource.type === 'ivr' || metaDataSourceType === 'ivr') {
    const result: {
      id?: string;
      label: string;
      meta: { units?: string };
    }[] = [];

    const ivrOptions = JSON.parse(dataSource.options ?? '[]');

    for (const option of ivrOptions) {
      result.push({
        id: dataSource.id,
        label: option,
        meta: {},
      });
    }

    dataSourceState.questions.push(...(result as AssessmentQuestionEntity[]));
    return dataSourceState;
  }

  if (
    resistanceQuestions.has(dataSource.type ?? '') ||
    resistanceQuestions.has(metaDataSourceType ?? '')
  ) {
    const result: {
      id?: string;
      label: string;
      meta: { units: string };
    }[] = [];

    const { data: graphDataPoints } = await graphDataQuery({
      fetchPolicy: 'network-only',

      variables: {
        graphDataPointsInput: {
          fromDate,
          toDate,
          questionsId: [Number(dataSource.id)],
          userId: profileId,
        },
      },
    });

    if (graphDataPoints?.graphDataPoints.items) {
      for (const item of graphDataPoints.graphDataPoints.items) {
        const value: { [key: string]: { [key: string]: any } } = item?.value ?? {};

        for (const itemValue of Object.values(value)) {
          if (typeof itemValue === 'object' && itemValue !== null) {
            for (const band of Object.keys(itemValue ?? {})) {
              if (typeof itemValue[band] === 'number' || typeof itemValue[band] === 'string') {
                if (!isNumericString(itemValue[band] as string)) continue;
                // Check if an object with the same label already exists
                const existingBand = result.find((result_) => result_.label === band);

                if (!existingBand) {
                  result.push({
                    id: item.question?.id,
                    label: band,
                    meta: {
                      units: band,
                    },
                  });
                }
              } else if (
                typeof itemValue[band] === 'object' &&
                dataSource.type !== 'resistance-bands' &&
                dataSource.type !== 'resistance-body-weight'
              ) {
                for (const bandValue of Object.values(itemValue[band] ?? {})) {
                  for (const bandValueKey of Object.keys(bandValue ?? {})) {
                    const value = (bandValue as any)[bandValueKey] as string;
                    if (typeof value === 'number' || typeof value === 'string') {
                      if (!isNumericString(value)) continue;
                      // Check if an object with the same label already exists
                      const existingBand = result.find((result_) => result_.label === bandValueKey);

                      if (!existingBand) {
                        result.push({
                          id: item.question?.id,
                          label: bandValueKey,
                          meta: {
                            units: bandValueKey,
                          },
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      dataSourceState.questions.push(...(result as AssessmentQuestionEntity[]));
      return dataSourceState;
    }
  }

  if (dataSource.type === 'exercise-question' || metaDataSourceType === 'exercise-question') {
    const { data: graphDataPoints } = await graphDataQuery({
      fetchPolicy: 'network-only',

      variables: {
        graphDataPointsInput: {
          fromDate,
          toDate,
          questionsId: [Number(dataSource.id)],
          userId: profileId,
        },
      },
    });

    const result: {
      id?: string;
      label: string;
      meta: { units: string };
    }[] = [];

    if (graphDataPoints?.graphDataPoints?.items)
      for (const item of graphDataPoints.graphDataPoints.items) {
        const value = item.value?.[metaDataSourceValue || dataSource.label] ?? {};

        for (const key of Object.keys(value)) {
          if (typeof value[key] === 'number' || typeof value[key] === 'string') {
            if (!isNumericString(value[key])) continue;
            // Check if an object with the same label already exists
            const existingQuestion = result.find((question) => question.label === key);

            if (!existingQuestion) {
              result.push({
                id: item.question?.id,
                label: key,
                meta: {
                  units: key,
                },
              });
            }
          }
        }
      }

    dataSourceState.questions.push(...(result as AssessmentQuestionEntity[]));
    return dataSourceState;
  }

  if (dataSource.type === 'cardio' || metaDataSourceType === 'cardio') {
    const { data: graphDataPoints } = await graphDataQuery({
      fetchPolicy: 'network-only',

      variables: {
        graphDataPointsInput: {
          fromDate,
          toDate,
          questionsId: [Number(dataSource.id)],
          userId: profileId,
        },
      },
    });

    const result: {
      id?: string;
      label: string;
      meta: { units: string };
    }[] = [];

    if (graphDataPoints?.graphDataPoints?.items)
      for (const item of graphDataPoints.graphDataPoints.items) {
        const { value } = item;

        for (const key of Object.keys(value ?? {})) {
          if (typeof value[key] === 'number' || typeof value[key] === 'string') {
            if (!isNumericString(value[key])) continue;

            // Check if an object with the same label already exists
            const existingQuestion = result.find((question) => question.label === key);

            if (!existingQuestion) {
              result.push({
                id: item.question?.id,
                label: key,
                meta: {
                  units: key,
                },
              });
            }
          }
        }
      }

    dataSourceState.questions.push(...(result as AssessmentQuestionEntity[]));
    return dataSourceState;
  }

  // Filter questions based on types using the Set
  const filteredQuestions =
    dataSource.questions?.filter((question) => !typesToFilter.has(question.type ?? '')) ?? [];

  // Add the filtered questions to the dataSourceState
  dataSourceState.questions.push(...filteredQuestions);

  // Transform All Human Body Questions
  const humanBodyQuestions =
    dataSource.questions?.filter((question) => question.type === 'human-body') ?? [];

  if (humanBodyQuestions?.length > 0) {
    const humanBodyQuestionIds = humanBodyQuestions.map((question) => Number(question.id));

    const { data: graphDataPoints } = await graphDataQuery({
      fetchPolicy: 'network-only',
      variables: {
        graphDataPointsInput: {
          fromDate,
          toDate,
          questionsId: humanBodyQuestionIds,
          userId: profileId,
        },
      },
    });

    const result: {
      id?: string;
      label: string;
      meta: { units: string };
    }[] = [];

    if (graphDataPoints?.graphDataPoints?.items)
      for (const item of graphDataPoints.graphDataPoints.items) {
        const valuesArray = JSON.parse(item.values?.join(',') ?? '');

        for (const valueObject of valuesArray) {
          const { name } = valueObject;

          // Check if an object with the same label already exists
          const existingQuestion = result.find((question) => question.label === name);

          if (!existingQuestion) {
            result.push({
              id: item.question?.id,
              label: name,
              meta: {
                units: 'Pain Level',
              },
            });
          }
        }
      }

    dataSourceState.questions.push(...(result as AssessmentQuestionEntity[]));
  }

  return dataSourceState;
};

export const handleExerciseQuestion = (item: any) => {
  const items = [];
  for (const [key, value] of Object.entries(item.value ?? {})) {
    for (const [ansKey, ansValue] of Object.entries(value ?? {})) {
      const dataItem = {
        ...item,
        question: {
          ...item.question,
          type: 'exercise-question',
          label: `${key} - ${ansKey}`,
        },
        value: null,
        values: ansValue,
      };
      items.push(dataItem);
    }
  }

  return items;
};

export const handleResistanceBandsQuestion = (item: any, dataSourceType: string) => {
  const items = [];
  for (const [key, value] of Object.entries(item.value ?? {})) {
    if (typeof value === 'object') {
      for (const [band, bandValue] of Object.entries(value ?? {})) {
        if (typeof bandValue === 'number') {
          const dataItem = {
            ...item,
            question: {
              ...item.question,
              type: 'resistance-bands',
              label: `${item.question?.label} - ${band}`,
            },
            value: null,
            values: bandValue,
          };
          items.push(dataItem);
        } else if (typeof bandValue === 'object' && dataSourceType === 'resistance-free-weight') {
          for (const [bandValueKey, bandValueValue] of Object.entries(bandValue ?? {})) {
            for (const [itemKey, itemValue] of Object.entries(bandValueValue ?? {})) {
              if (typeof itemValue === 'number' || typeof itemValue === 'string') {
                const dataItem = {
                  ...item,
                  question: {
                    ...item.question,
                    type: 'resistance-bands',
                    label: `${item.question?.label} - ${itemKey}`,
                  },
                  value: null,
                  values: Number(itemValue),
                };
                items.push(dataItem);
              }
            }
          }
        }
      }
    }
  }

  return items;
};

export const handleCardioQuestions = (item: any) => {
  const items = [];
  for (const [key, value] of Object.entries(item.value ?? {})) {
    const dataItem = {
      ...item,
      question: {
        ...item.question,
        type: 'cardio',
        label: `${item.question?.label} - ${key}`,
      },
      value: null,
      values: value,
    };
    items.push(dataItem);
  }

  return items;
};

export const handleIvrQuestions = (item: any) => {
  return {
    ...item,
    question: {
      ...item.question,
      type: 'ivr',
    },
    values: Array.isArray(item.values) ? item.values?.[0] : item.values,
  };
};
