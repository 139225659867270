import axios from 'axios';
import create from 'zustand';
import { getToken, logger } from '../../../utils';
import {
  FITBIT_ACCESS_TOKEN_KEY,
  FITBIT_PROFILE_KEY,
  FITBIT_REFRESH_TOKEN_KEY,
} from '../constants';

interface Properties {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  profile?: FitBitProfile;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setAccessToken: (data: FitBitAccessToken) => void;
  setProfile: (data: FitBitProfile) => void;
  removeAccessToken: () => void;
  removeRefreshToken: () => void;
  handleRefreshToken: (user: IFitbitUserInput) => Promise<any>;
}

const useAuth = create<Properties>((set) => {
  const accessToken = localStorage.getItem(FITBIT_ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(FITBIT_REFRESH_TOKEN_KEY);
  const cachedProfile = localStorage.getItem(FITBIT_PROFILE_KEY);
  const profile = cachedProfile ? JSON.parse(cachedProfile) : undefined;

  return {
    isAuthenticated: !!accessToken,
    showModal: false,
    accessToken,
    refreshToken,
    profile,
    setShowModal: (value: boolean) => set(() => ({ showModal: value })),
    setAccessToken: ({ accessToken: aToken, refreshToken: rToken }: FitBitAccessToken) => {
      // Store the token's into persistent storage for later use
      if (aToken) {
        localStorage.setItem(FITBIT_ACCESS_TOKEN_KEY, aToken);
      } else {
        localStorage.removeItem(FITBIT_ACCESS_TOKEN_KEY);
      }

      if (rToken) {
        localStorage.setItem(FITBIT_REFRESH_TOKEN_KEY, rToken);
      } else {
        localStorage.removeItem(FITBIT_REFRESH_TOKEN_KEY);
      }

      return set(() => ({
        accessToken: aToken,
        refreshToken: rToken,
        isAuthenticated: true,
        showModal: false,
      }));
    },
    setProfile: (profile: FitBitProfile) => {
      if (profile) {
        localStorage.setItem(FITBIT_PROFILE_KEY, JSON.stringify(profile));
      } else {
        localStorage.removeItem(FITBIT_PROFILE_KEY);
      }

      return set(() => ({ profile }));
    },
    removeAccessToken: () => {
      localStorage.removeItem(FITBIT_ACCESS_TOKEN_KEY);
      localStorage.removeItem(FITBIT_PROFILE_KEY);

      return set({
        accessToken: null,
        isAuthenticated: false,
        showModal: true,
        profile: undefined,
      });
    },
    removeRefreshToken: () => {
      localStorage.removeItem(FITBIT_REFRESH_TOKEN_KEY);

      return set({
        refreshToken: null,
      });
    },
    handleRefreshToken: async (user: IFitbitUserInput) => {
      const { id } = user;

      return axios({
        url: `${process.env.REACT_APP_API_URL}/fitbit/auth/refresh`,
        params: {
          userId: id,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).catch((error_) => {
        logger.log(`axios error response: ${error_.message}`);
      });
    },
  };
});

export default useAuth;
