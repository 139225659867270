import { HTMLAttributes, ReactElement, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { Waveform } from '../Waveform';
import { IAttachment } from '../../../types/user';
import { AttachmentType } from '../../../constants';
import { downloadFilebyUrl } from '../../../utils';

export interface ITabbedContent extends HTMLAttributes<HTMLElement> {
  avatar?: ReactElement;
  headline: ReactElement | string;
  subtitle: string;
  date?: string;
  router?: any;
  badges?: Array<string>;
  attachment?: IAttachment;
  attachments?: IAttachment[];
  divider?: boolean;
  primaryAction?: ReactElement;
  secondaryAction?: ReactElement;
  tertiaryAction?: ReactElement;
  onAddFlag?: () => void;
  buttonStyles?: CSSProperties;
}

export function DataRowGrid({
  avatar,
  headline,
  subtitle,
  date,
  router,
  badges,
  attachment,
  attachments,
  divider,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  buttonStyles,
  onAddFlag,
}: ITabbedContent): ReactElement<ITabbedContent> | null {
  const screen450Up = useMediaQuery('(min-width:450px)');
  const secondaryColor = 'text.secondary';
  const { t } = usePreferredTranslation();

  const getAttachment = useCallback((attach: IAttachment) => {
    const { type, name, url } = attach;
    switch (type) {
      case AttachmentType.FILE: {
        return (
          <Button
            variant="outlined"
            onClick={() => downloadFilebyUrl(url, name)}
            startIcon={<AttachFileIcon />}
            sx={{
              color: secondaryColor,
              borderColor: secondaryColor,
            }}
          >
            {name}
          </Button>
        );
      }
      case AttachmentType.AUDIO: {
        return <Waveform url={url} />;
      }
      default: {
        return null;
      }
    }
  }, []);

  const GridContent = (
    <ListItemButton
      sx={{
        py: 2,
        borderTop: divider ? '1px solid #d9d9d9' : 0,
        ...buttonStyles,
      }}
    >
      <Grid container>
        {avatar && screen450Up && (
          <Grid item width={55}>
            {avatar}
          </Grid>
        )}
        <Grid item flexGrow={1}>
          <Grid container>
            <Grid item flexGrow={1}>
              <Stack spacing={1}>
                {badges && (
                  <Grid container spacing={1}>
                    {badges.map((badge: string, index_: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item key={index_}>
                        <Chip label={badge} variant="outlined" sx={{ borderRadius: 2 }} />
                      </Grid>
                    ))}
                    {onAddFlag && (
                      <Grid item>
                        <Chip
                          label={t('Add flag')}
                          variant="outlined"
                          sx={{ borderRadius: 2, color: secondaryColor }}
                          onClick={onAddFlag}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}

                <Box sx={{ mt: 1 }}>{headline}</Box>

                <Box>
                  <Typography component="p" variant="subtitle2" sx={{ color: secondaryColor }}>
                    {subtitle}
                  </Typography>
                </Box>

                {attachment && <Box sx={{ mt: 1 }}>{getAttachment(attachment)}</Box>}
                <Box sx={{ display: 'flex' }}>
                  {(attachments?.length || 0) > 0 &&
                    attachments?.map((a) => (
                      <Box sx={{ mt: 1, mr: 0.5 }} key={a.url}>
                        {getAttachment(a)}
                      </Box>
                    ))}
                </Box>
                {secondaryAction && <Box sx={{ mt: 1 }}>{secondaryAction}</Box>}
              </Stack>
            </Grid>
            <Grid item>
              <Stack sx={{ height: '100%', justifyContent: 'center' }}>
                {date && (
                  <Typography
                    flexGrow={1}
                    component="p"
                    variant="caption"
                    sx={{ color: secondaryColor }}
                  >
                    {date}
                  </Typography>
                )}
                {tertiaryAction && (
                  <Box sx={{ display: 'flex' }} alignItems="flex-end" justifyContent="flex-end">
                    {tertiaryAction}
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {primaryAction && (
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            justifyContent="center"
            alignItems="flex-end"
          >
            {primaryAction}
          </Grid>
        )}
      </Grid>
    </ListItemButton>
  );

  if (router?.content) {
    return (
      <Link
        component={RouterLink}
        to={router.content}
        sx={{ textDecoration: 'none', color: 'inherit' }}
      >
        {GridContent}
      </Link>
    );
  }
  return GridContent;
}
