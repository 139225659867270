import { SVGProps } from 'react';

const SpinningSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M29.896 17.341a.405.405 0 0 1-.295.443l-1.736.333-1.993 6.313c.037.037 2.289.48 2.289 2.843a2.884 2.884 0 0 1-2.88 2.88h-.074l.443.96h.48a.37.37 0 0 1 0 .739h-1.698c-.222 0-.37-.148-.37-.37 0-.221.148-.369.37-.369h.406l-.443-.96H20.37l-3.545-3.692c.407-1.366-.627-2.806-2.104-2.806a2.222 2.222 0 0 0-2.216 2.215c0 1.477 1.403 2.511 2.733 2.142l2.067 2.141c-2.953 0-2.917.037-3.397-.037l-.443.96h.406c.222 0 .37.148.37.37 0 .221-.148.369-.37.369h-1.698c-.222 0-.37-.148-.37-.37 0-.22.148-.369.37-.369h.48l.517-1.144a4.253 4.253 0 0 1-3.065-4.099c0-2.769 2.585-4.8 5.28-4.172l-.295-1.292.85.443.258 1.07 3.027.776.48 3.766a2.113 2.113 0 0 0 2.105 1.846 2.126 2.126 0 0 0 2.105-2.363l-.259-2.104 1.588.406 2.067-6.573c.037-.147.148-.221.296-.258l1.92-.37c.148 0 .332.149.37.333ZM25.538 10.179c0 1.08-.892 1.957-1.993 1.957-1.102 0-1.994-.877-1.994-1.957 0-1.081.892-1.957 1.994-1.957 1.1 0 1.993.876 1.993 1.957Z"
    />
    <path
      fill="#6C5DD3"
      d="M21.293 31.446a.37.37 0 0 1-.37.37h-2.252a.37.37 0 0 1 0-.739h.554l-4.8-4.985a.357.357 0 0 1 0-.516.357.357 0 0 1 .517 0l5.28 5.501h.702a.37.37 0 0 1 .369.37ZM14.35 16.234l3.693-4.616c1.108-1.366 3.323-.96 3.803.813l1.071 2.732 2.806.148c.628.037 1.145.59 1.108 1.218-.037 1.108-1.182 1.108-1.219 1.108l-3.581-.148c-.48-.037-.886-.332-1.034-.738l-.48-1.219-2.105 2.622 3.323 1.662c.407.221.702.59.739 1.07l.628 5.133c.11.738-.444 1.44-1.182 1.514-.739.11-1.44-.443-1.55-1.182l-.555-4.394-4.873-2.437c-.96-.517-1.477-2.141-.591-3.286Z"
    />
  </svg>
);
export default SpinningSvg;
