import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { dayjs } from '../../../../utils/dayjs';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

import { useCoachingEventsQuery, useUserQuery } from '../../../../graphql';

import { theme } from '../../../../theme';
import CoachingCallSummary from './CoachingCallSummary';
import CoachingDatePicker from './CoachingDatePicker';

interface InfoHeaderProperties {
  userId: number;
  date: Date;
  handleChangeDate: (value: any) => void;
}

const InfoHeader: React.FC<InfoHeaderProperties> = ({ date, handleChangeDate, userId }) => {
  const { t } = usePreferredTranslation();
  const { data: userData } = useUserQuery({
    variables: {
      id: userId,
    },
  });

  const { data: coachingEventsData, loading: coachingEventsLoading } = useCoachingEventsQuery({
    variables: {
      userId,
    },
  });

  const studyDay = useMemo(() => {
    const userStartDate = userData?.user?.startDate;

    if (!userStartDate) {
      return 'Week 0 Day 0';
    }

    const startDate = dayjs(userStartDate);
    const today = dayjs();

    const diffInDays = today.diff(startDate, 'day');
    const weeks = Math.floor(diffInDays / 7);
    const days = diffInDays % 7;

    return `Week ${weeks} Day ${days}`;
  }, [userData]);

  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{ mb: 1 }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <CoachingDatePicker
          selectedDate={date}
          handleChangeDate={handleChangeDate}
          coachingEvents={coachingEventsData}
          loading={coachingEventsLoading}
        />
        <Stack direction="row" gap={1} alignItems="center" sx={{ pl: 4 }}>
          <Typography variant="body1" sx={{ weight: 600 }}>
            {t('Study Day')}
          </Typography>
          <Box sx={{ bgcolor: theme.palette.common.white, p: 1, borderRadius: 1.5 }}>
            <Typography variant="body2">{studyDay}</Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2}>
        <CoachingCallSummary coachingEvents={coachingEventsData} />
      </Stack>
    </Stack>
  );
};

export default InfoHeader;
