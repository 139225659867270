import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  AppBarProps,
  Box,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { ReactComponent as MessagesIcon } from '../../../assets/icons/messages.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { PAGES } from '../../../constants';
import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties extends AppBarProps {
  hasRightSidebar?: boolean;
  onOpenRightMenu?: () => void;
}

export function SidebarMain({ hasRightSidebar = false, onOpenRightMenu, ...rest }: Properties) {
  const xs = useMediaQuery('(max-width:750px)');
  const { pathname } = useLocation();
  const { t } = usePreferredTranslation();
  const handleOpenRightMenu = useCallback(() => {
    if (onOpenRightMenu) {
      onOpenRightMenu();
    }
  }, [onOpenRightMenu]);

  return (
    <AppBar
      aria-label="Sidebar Main"
      data-testid="sidebarMainComponent"
      position="fixed"
      color="default"
      sx={{ top: 'auto', bottom: 0 }}
      {...rest}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
          display: 'flex',
          p: 0,
          background: theme.palette.common.white,
        }}
      >
        {hasRightSidebar && handleOpenRightMenu && (
          <IconButton
            aria-label="Open Right Menu"
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleOpenRightMenu}
          >
            <MenuIcon />
          </IconButton>
        )}
        {xs && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ flex: 1, maxWidth: 500 }}
          >
            <Link
              aria-label="Home"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              component={RouterLink}
              to={PAGES.HOME}
            >
              {pathname === PAGES.HOME && (
                <Box sx={{ width: '100%', background: theme.palette.primary.main, height: 3 }} />
              )}
              <IconButton
                aria-label="Home"
                size="large"
                color="inherit"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <HomeIcon
                  color={
                    pathname === PAGES.HOME ? theme.palette.primary.main : theme.palette.grey[700]
                  }
                  fill={
                    pathname === PAGES.HOME ? theme.palette.primary.main : theme.palette.grey[700]
                  }
                />

                <Typography
                  variant="body2"
                  sx={{
                    color:
                      pathname === PAGES.HOME
                        ? theme.palette.primary.main
                        : theme.palette.grey[700],
                  }}
                >
                  {t('Home')}
                </Typography>
              </IconButton>
            </Link>
            <Link
              aria-label="Messaging"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              component={RouterLink}
              to={PAGES.MESSAGING}
            >
              {pathname === PAGES.MESSAGING && (
                <Box sx={{ width: '100%', background: theme.palette.primary.main, height: 3 }} />
              )}
              <IconButton
                aria-label="Messaging"
                size="large"
                color="inherit"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <MessagesIcon
                  color={
                    pathname === PAGES.MESSAGING
                      ? theme.palette.primary.main
                      : theme.palette.grey[700]
                  }
                />
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      pathname === PAGES.MESSAGING
                        ? theme.palette.primary.main
                        : theme.palette.grey[700],
                  }}
                >
                  {t('Messaging')}
                </Typography>
              </IconButton>
            </Link>
            {/* <Link
              aria-label="Schedule"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              component={RouterLink}
              to={PAGES.SCHEDULE}
            >
              {pathname === PAGES.SCHEDULE && (
                <Box sx={{ width: '100%', background: theme.palette.primary.main, height: 3 }} />
              )}
              <IconButton
                aria-label="Study Management"
                size="large"
                color="inherit"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FolderCopyOutlinedIcon
                  sx={{
                    color:
                      pathname === PAGES.STUDY_MANAGEMENT
                        ? theme.palette.primary.main
                        : theme.palette.grey[700],
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{
                    color:
                      pathname === PAGES.STUDY_MANAGEMENT
                        ? theme.palette.primary.main
                        : theme.palette.grey[700],
                  }}
                >{t('Studies')}</Typography>
              </IconButton>
            </Link> */}
            <Link
              aria-label="Profile"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              component={RouterLink}
              to={PAGES.PERSONAL_DETAILS}
            >
              {pathname === PAGES.PERSONAL_DETAILS && (
                <Box sx={{ width: '100%', background: theme.palette.primary.main, height: 3 }} />
              )}
              <IconButton
                aria-label="Profile"
                size="large"
                edge="end"
                color="inherit"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ProfileIcon
                  color={
                    pathname === PAGES.PERSONAL_DETAILS
                      ? theme.palette.primary.main
                      : theme.palette.grey[700]
                  }
                />
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      pathname === PAGES.PERSONAL_DETAILS
                        ? theme.palette.primary.main
                        : theme.palette.grey[700],
                  }}
                >
                  {t('Profile')}
                </Typography>
              </IconButton>
            </Link>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
