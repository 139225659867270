import { IActivityData } from '../types/user';
import { COLLABORATIONS, AttachmentType } from '../constants';

const categories = ['Colon cancer'];

export const participantActivity: IActivityData = [
  {
    id: '007f1f77bcf86cd799439011',
    title: 'Chat about David’s bowel ache',
    type: COLLABORATIONS.TEXT,
    date: 'Tue, 24 June',
    category: categories[0] as string,
    attachment: {
      type: AttachmentType.FILE,
      name: 'Meeting notes',
      url: 'https://example.com/example.pdf',
    },
  },
  {
    id: '107f1f77bcf86cd799439011',
    title: 'Weekly checkin call with David',
    type: COLLABORATIONS.VOICE,
    date: 'Tue, 24 June',
    category: categories[0] as string,
    notes: 'Here are the notes for this activity.',
    attachment: {
      type: AttachmentType.AUDIO,
      name: 'Check-in call with David',
      url: '/audio/sample.wav',
    },
  },
  {
    id: '207f1f77bcf86cd799439011',
    title: 'Weekly checkin call with David',
    type: COLLABORATIONS.EMAIL,
    date: 'Thu, 29 June',
    category: categories[0] as string,
    attachment: {
      type: AttachmentType.EMAIL,
      name: 'Check-in call with David',
      url: '/audio/sample.wav',
      summary: `Hi David, I'm just checking in on your condition XTZ. Please...`,
    },
  },
];
