import { ReactElement } from 'react';
import Content, { IContentLoaderProps } from 'react-content-loader';

import { isStorybook } from '../../../utils';

type Properties = IContentLoaderProps;

export function ContentLoader({ children, speed, ...rest }: Properties): ReactElement<Properties> {
  return (
    <Content data-testid="contentLoaderComponent" speed={isStorybook ? 0 : speed} {...rest}>
      {children}
    </Content>
  );
}
