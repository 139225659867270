import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  MessageEntity,
  UserEntity,
  useUpdateMessageMutation,
  useViewAllMessagesMutation,
} from '../../../../graphql';
import { useCurrentUser, useUser } from '../../../../states';

import { MessageDetails } from '../MessageDetails';
import { Messages } from '../Messages';
import { PhoneNumberEntity } from '../../../../openapi';
import { UserRoles } from '../../../../constants/users';
import { UsersMessages } from '../UsersMessages';
import { logger } from '../../../../utils';

interface Properties {
  studyId: number;
  readonly preSelectedUser?: UserEntity;
  phoneNumber?: PhoneNumberEntity;
}

export const MessagesSection = ({
  studyId,
  preSelectedUser,
  phoneNumber,
}: Properties): ReactElement => {
  const { checkUserRole } = useUser();
  const { data: userData } = useCurrentUser();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);
  const [selectedMessage, setSelectedMessage] = useState<MessageEntity | null>(null);
  const sm = useMediaQuery('(max-width:750px)');

  useEffect(() => {
    if (preSelectedUser) setSelectedUser(preSelectedUser);
  }, [preSelectedUser]);

  const [updateMessageMutation] = useUpdateMessageMutation();
  const [viewAllMessages] = useViewAllMessagesMutation();

  const handleSelectMessage = useCallback(
    (message: MessageEntity | null) => {
      setSelectedMessage(message);

      if (message && !message.viewed) {
        updateMessageMutation({
          variables: {
            id: message.id,
            updateMessageInput: { viewed: true },
          },
        }).catch((error) => {
          logger.error(error);
        });
      }
    },
    [updateMessageMutation],
  );

  const handleSelectUser = useCallback(
    (user: UserEntity | null) => {
      if (user && user.id) setSearchParameters({ userId: user.id });
      else setSearchParameters({});
      setSelectedUser(user);
    },
    [setSearchParameters],
  );

  useEffect(() => {
    if (selectedUser) {
      viewAllMessages({
        variables: {
          user: Number(selectedUser.id),
        },
      }).catch((error) => {
        logger.error(error);
      });
    }
  }, [selectedUser, viewAllMessages]);

  const isStaff =
    checkUserRole(userData?.user, UserRoles.ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.SUPER_ADMINISTRATOR) ||
    checkUserRole(userData?.user, UserRoles.COACH);

  return (
    <Paper sx={{ flexGrow: 1 }}>
      <Grid container sx={{ flexGrow: 1, height: '100%' }}>
        {isStaff && (
          <Grid
            item
            xs={selectedUser ? 4 : 12}
            sx={{ overflow: 'auto', height: 'calc(100vh - 135px)' }}
          >
            <Box
              sx={{
                textAlign: 'left',
                padding: 0,
                flexGrow: 1,
                overflow: 'auto',
                height: '100%',
              }}
            >
              <Box sx={{ overflow: 'auto', height: '100%' }}>
                <UsersMessages
                  selectedUser={selectedUser}
                  onSelectUser={handleSelectUser}
                  studyId={studyId}
                />
              </Box>
            </Box>
          </Grid>
        )}
        {(selectedUser || checkUserRole(userData?.user, UserRoles.PARTICIPANT)) && (
          <Grid
            item
            xs={isStaff ? 6 : 12}
            sx={{
              height: 'calc(100vh - 135px)', // Have a fixed height for content to be scrollable
              border: '1px solid',
              borderTop: 'none',
              borderBottom: 'none',
              borderColor: (theme) => theme.palette.divider,
              marginBottom: sm ? 15 : 0,
            }}
          >
            <Messages
              user={selectedUser}
              phoneNumber={phoneNumber}
              selectedMessage={selectedMessage}
              onSelectMessage={handleSelectMessage}
            />
          </Grid>
        )}
        {isStaff && !!selectedUser && (
          <Grid item xs={2}>
            <MessageDetails user={selectedUser} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
