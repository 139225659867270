import { SxProps } from '@mui/system';

export const containerStyles: SxProps = {
  p: 2,
};

export const headerContainerStyles: SxProps = {
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  marginBottom: 3,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  p: 2,
};

export const cardHeaderStyles: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 2,
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
  marginRight: 2,
};
