import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import { bluetoothIconStyles, notFoundPapterStyles } from './styles';

interface FitbitDeviceNotFoundProperties {
  title: string;
  subtitle: string;
}

export const FitbitDeviceNotFound: React.FC<FitbitDeviceNotFoundProperties> = ({
  subtitle,
  title,
}) => {
  return (
    <Paper sx={notFoundPapterStyles}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack sx={bluetoothIconStyles}>
          <BluetoothDisabledIcon color="primary" />
        </Stack>
        <Stack>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">{subtitle}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
