import { MouseEvent, ReactElement, useCallback, useState } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Box, IconButton, BoxProps, Menu } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { styled } from '@mui/material/styles';

export type Emoji = {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  emoticons: string[];
};

interface Properties extends BoxProps {
  readonly disabled?: boolean;
  readonly onEmojiSelect?: (emoji: Emoji) => void;
}

const StyledMenu = styled(Menu)(() => ({
  '.MuiList-root': {
    padding: '0 !important',
  },
}));

export function Emojis({
  disabled = false,
  sx,
  onEmojiSelect,
  ...rest
}: Properties): ReactElement<Properties> {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleSelect = useCallback(
    (emoji: Emoji) => {
      onEmojiSelect?.(emoji);
    },
    [onEmojiSelect],
  );

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, [setAnchor]);

  return (
    <Box data-testid="emojisComponent" sx={{ display: 'inline-flex', ...sx }} {...rest}>
      <IconButton onClick={handleClick} color="secondary" aria-label="Emojis" disabled={disabled}>
        <SentimentSatisfiedAltIcon />
      </IconButton>
      <StyledMenu
        sx={{ m: 0, p: 0 }}
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Picker data={data} onEmojiSelect={handleSelect} />
      </StyledMenu>
    </Box>
  );
}
