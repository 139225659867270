import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback } from 'react';

interface AnswerNoteProperties {
  defaultValue: string | undefined;
  handleCommentChange: (value: string) => void;
  placeholder?: string;
}

const AnswerNote: React.FC<AnswerNoteProperties> = ({
  handleCommentChange,
  defaultValue,
  placeholder,
}) => {
  const handleChangeInputBox = useCallback(
    (event: any) => {
      handleCommentChange(event.target.value);
    },
    [handleCommentChange],
  );

  return (
    <TextField
      data-testid="new-note-entry"
      maxRows={5}
      minRows={1}
      multiline
      variant="outlined"
      value={defaultValue || ''}
      onChange={handleChangeInputBox}
      placeholder={placeholder || 'Any additional notes goes here..'}
      InputProps={{
        startAdornment: (
          <InsertDriveFileOutlinedIcon fontSize="small" sx={{ color: grey[500], marginRight: 1 }} />
        ),
        sx: {
          alignItems: 'flex-start',
          minHeight: 50,
          paddingBottom: '5px',
          borderRadius: '12px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey[300],
            height: 'calc(100% - 7px)',
          },
          '& .MuiInputBase-input': {
            marginBottom: '25px',
          },
        },
      }}
      sx={{
        width: '100%',
        background: 'white',
        borderRadius: '12px',
      }}
    />
  );
};

export default AnswerNote;
