import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import { grey } from '@mui/material/colors';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import TabInput from './TabInput';

import {
  ChartTabInputType,
  ChartTabOutputType,
  useChartTabsLazyQuery,
  useManageChartTabsMutation,
} from '../../../../graphql';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  userId: number;
  open: boolean;
  onClose: () => void;
  onRefetchTabs?: () => void;
}

const CreateTabsDialogue = ({ userId, open, onClose, onRefetchTabs }: Properties) => {
  const { t } = usePreferredTranslation();
  const [chartTabsLoading, setChartTabsLoading] = useState(true);
  const [chartTabs, setChartsTabs] = useState<ChartTabOutputType[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [tabs, setTabs] = useState<ChartTabOutputType[]>([]);
  const [createList, setCreateList] = useState<ChartTabOutputType[]>([]);
  const [editList, setEditList] = useState<ChartTabOutputType[]>([]);
  const [deleteList, setDeleteList] = useState<number[]>([]);
  const [saveChartTabs] = useManageChartTabsMutation();
  const [chartTabsQuery] = useChartTabsLazyQuery();

  const handleClose = () => {
    if (submitLoading) return;
    onClose();
    onRefetchTabs?.();
    setCreateList([]);
    setEditList([]);
    setDeleteList([]);
    setIsAdding(false);
  };

  const fetchTabs = async () => {
    setChartTabsLoading(true);
    const { data } = await chartTabsQuery({
      fetchPolicy: 'network-only',
      variables: {
        userId: String(userId),
        chartCount: true,
      },
    });
    setChartsTabs(data?.chartTabs || []);
    setChartTabsLoading(false);
  };

  const onSave = async () => {
    if (createList.length === 0 && deleteList.length === 0 && editList.length === 0) {
      handleClose();
      return;
    }
    setSubmitLoading(true);
    const create: ChartTabInputType[] = createList.map((ele) => ({ title: ele.title }));
    const update: ChartTabInputType[] = editList.map((ele) => ({
      id: Number(ele.id),
      title: ele.title,
    }));
    const { errors } = await saveChartTabs({
      variables: {
        userId: String(userId),
        data: { create, update, delete: deleteList },
      },
    });
    setSubmitLoading(false);
    if (errors) {
      toast.error(errors[0]?.message);
      return;
    }
    handleClose();
    onRefetchTabs?.();
  };

  const handleCreate = (value: string) => {
    const nameCheck = tabs.find((ele) => ele.title.toLowerCase() === value.toLowerCase());
    if (nameCheck) {
      toast.error(t('Category is exists with this name'));
      return;
    }
    const element = {
      id: String(Date.now()),
      title: value,
      chartCount: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    setCreateList([...createList, element]);
    setTabs([...tabs, element]);
    setIsAdding(!isAdding);
  };

  const handleUpdate = (id: string, value: string) => {
    const nameCheck = tabs.find(
      (ele) => ele.id !== id && ele.title.toLowerCase() === value.toLowerCase(),
    );
    if (nameCheck) {
      toast.error(t('Category is exists with this name'));
      return;
    }
    const index = tabs.findIndex((ele) => ele.id === id);
    if (index === -1) return;
    tabs[index]!.title = value;
    setTabs([...tabs]);
    const isCreated = createList.find((ele) => ele.id === id);
    if (isCreated) {
      const cIndex = createList.findIndex((ele) => ele.id === id);
      createList[cIndex]!.title = value;
      setCreateList([...createList]);
    } else {
      const editIndex = editList.findIndex((ele) => ele.id === id);
      if (editIndex === -1) {
        editList.push(tabs[index]!);
      } else {
        editList[index]!.title = value;
        setEditList([...editList]);
      }
    }
  };

  const handleDelete = (id: string) => {
    const checkCount = tabs.find((tab) => tab.id === id)?.chartCount || 0;
    if (checkCount > 0) {
      toast.error(
        'Chart(s) exists for this Category, Please remove all the chart(s) & then try again',
      );
      return;
    }
    setTabs(tabs.filter((tab) => tab.id !== id));
    setCreateList(createList.filter((tab) => tab.id !== id));
    setEditList(editList.filter((tab) => tab.id !== id));
    const isExists = chartTabs.find((tab) => tab.id === id);
    if (isExists) {
      setDeleteList([...deleteList, Number(id)]);
    }
  };

  useEffect(() => {
    setTabs(chartTabs);
  }, [chartTabs]);

  useEffect(() => {
    if (open) {
      fetchTabs();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {chartTabsLoading && <LinearProgress />}
      <DialogTitle>{t('Manage Categories')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {tabs.map((tab) => (
          <TabInput
            userId={userId}
            element={tab}
            onRefetchTabs={() => onRefetchTabs?.()}
            key={tab.id}
            onSave={(value) => handleUpdate(tab.id, value)}
            onDelete={() => handleDelete(tab.id)}
            loading={submitLoading}
          />
        ))}
        {isAdding && (
          <TabInput
            isAdding
            onRefetchTabs={() => onRefetchTabs?.()}
            onCloseAdd={() => setIsAdding(false)}
            userId={userId}
            onSave={(value: string) => handleCreate(value)}
            loading={submitLoading}
          />
        )}
        <Button
          sx={{ mt: 1, padding: '8px 12px', borderRadius: 1 }}
          startIcon={<AddIcon />}
          onClick={() => setIsAdding(!isAdding)}
        >
          {t('Add Category')}
        </Button>
      </DialogContent>
      <DialogActions sx={{ marginBottom: 2 }}>
        <Button
          sx={{
            padding: '8px 12px',
            borderRadius: 1,
            color: '#616175',
            backgroundColor: grey[300],
            mr: 1,
            '&:hover': {
              backgroundColor: grey[400],
            },
          }}
          color="secondary"
          variant="contained"
          disabled={submitLoading}
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={submitLoading}
          sx={{ padding: '8px 12px' }}
          variant="contained"
          onClick={onSave}
        >
          {t('Done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTabsDialogue;
