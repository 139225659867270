import { HTMLAttributes, useState } from 'react';
import { createPortal } from 'react-dom';

interface Properties extends HTMLAttributes<HTMLIFrameElement> {
  readonly css?: string;
}

export const Frame = ({ title, children, ...rest }: Properties) => {
  const [contentReference, setContentReference] = useState<HTMLIFrameElement | null>(null);
  const mountNode: HTMLElement | undefined = contentReference?.contentWindow?.document?.body;

  return (
    <iframe title={title} {...rest} data-testid="frameComponent" ref={setContentReference}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
