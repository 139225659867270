import { Channel, PresenceChannel } from 'pusher-js';
import { useEffect } from 'react';
import { invariant } from '../../utils';

/**
 * Subscribes to a channel event and registers a callback.
 *
 * @param channel Pusher channel to bind to
 * @param event Name of event to bind to
 * @param callback Callback to call on a new event
 */
export function useEvent<D>(
  channel: Channel | PresenceChannel | undefined,
  event: string,
  callback: (data?: D, metadata?: { user_id: string }) => void,
) {
  // error when required arguments aren't passed.
  invariant(event, 'Must supply event and callback to onEvent');
  invariant(callback, 'Must supply callback to onEvent');

  // bind and unbind events whenever the channel, event or callback changes.
  useEffect(() => {
    if (channel === undefined) {
      return;
    }

    channel.bind(event, callback);

    return () => {
      channel.unbind(event, callback);
    };
  }, [channel, event, callback]);
}
