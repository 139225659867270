export const downloadJSON = (data: object, fileName: string) => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.append(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(href);
};

export const downloadFilebyUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.target = '_blank';
  document.body.append(link);
  link.click();

  link.remove();
};

export const pathFile = (url: string) => {
  const { pathname } = new URL(decodeURIComponent(url));

  return pathname.slice(Math.max(0, pathname.lastIndexOf('/') + 1));
};

export const pathExtension = (url: string) => {
  return pathFile(url).split('.').pop()?.toLowerCase();
};

export const paths = (value: string) => {
  return {
    file: pathFile(value),
    extension: pathExtension(value),
  };
};
