export const studies = [
  {
    name: 'Colon cancer',
  },
  {
    name: 'CoVax study',
  },
  {
    name: 'COVID-19 study',
  },
];
