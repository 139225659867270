import { SVGProps } from 'react';

const EmptyEventIcon = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width="66"
    height="64"
    viewBox="0 0 66 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <g clipPath="url(#clip0_18197_10815)">
      <path
        d="M41.8401 14.3935H24.1731C23.7705 14.394 23.3845 14.5541 23.0998 14.8388C22.8152 15.1235 22.655 15.5095 22.6545 15.9121V55.1772L22.452 55.239L18.118 56.5662C17.9126 56.6288 17.6908 56.6074 17.5012 56.5066C17.3116 56.4058 17.1697 56.2339 17.1067 56.0286L4.21503 13.9187C4.15229 13.7133 4.17367 13.4914 4.27448 13.3017C4.37528 13.1121 4.54725 12.9702 4.75261 12.9073L11.4313 10.8623L30.7931 4.93583L37.4717 2.89082C37.5733 2.85954 37.6802 2.84861 37.786 2.85866C37.8919 2.8687 37.9947 2.89953 38.0887 2.94936C38.1826 2.9992 38.2658 3.06707 38.3335 3.1491C38.4012 3.23112 38.452 3.32569 38.4831 3.42739L41.7784 14.191L41.8401 14.3935Z"
        fill="#F2F2F2"
      />
      <path
        d="M45.695 14.1911L41.7234 1.21849C41.6573 1.00235 41.5493 0.801336 41.4055 0.626951C41.2617 0.452566 41.085 0.308225 40.8854 0.202174C40.6858 0.0961226 40.4672 0.0304398 40.2422 0.00888132C40.0172 -0.0126772 39.7902 0.010311 39.5741 0.0765298L30.1843 2.95068L10.8236 8.87816L1.43374 11.7533C0.99754 11.8873 0.632302 12.1888 0.418198 12.5918C0.204094 12.9947 0.158615 13.4662 0.291746 13.9026L13.8657 58.2378C13.9739 58.5901 14.1921 58.8984 14.4884 59.1176C14.7846 59.3368 15.1434 59.4553 15.5119 59.4556C15.6825 59.4557 15.8521 59.4301 16.015 59.3797L22.4517 57.4096L22.6542 57.3469V57.1353L22.4517 57.197L15.9553 59.1863C15.5703 59.3037 15.1544 59.2636 14.7989 59.0747C14.4435 58.8859 14.1774 58.5638 14.0591 58.179L0.486165 13.8429C0.427557 13.6522 0.407131 13.4519 0.426055 13.2534C0.444979 13.0548 0.50288 12.862 0.596446 12.6858C0.690012 12.5097 0.817405 12.3537 0.971327 12.2269C1.12525 12.1001 1.30268 12.0048 1.49345 11.9467L10.8833 9.07153L30.244 3.14505L39.6339 0.269896C39.7786 0.225736 39.929 0.20322 40.0803 0.203079C40.405 0.203808 40.7209 0.308484 40.9818 0.501774C41.2427 0.695064 41.4348 0.966812 41.5301 1.27721L45.4834 14.1911L45.5462 14.3936H45.7567L45.695 14.1911Z"
        fill="#3F3D56"
      />
      <path
        d="M12.6376 12.9403C12.4425 12.9402 12.2525 12.8775 12.0956 12.7615C11.9387 12.6455 11.8231 12.4823 11.7658 12.2958L10.4618 8.03653C10.4268 7.92212 10.4146 7.80193 10.426 7.68282C10.4374 7.56371 10.4722 7.44801 10.5284 7.34234C10.5845 7.23667 10.6609 7.14309 10.7532 7.06695C10.8455 6.99082 10.9519 6.93361 11.0663 6.89859L28.878 1.44553C29.1091 1.37502 29.3587 1.39907 29.572 1.5124C29.7854 1.62572 29.9451 1.81907 30.016 2.05L31.32 6.30931C31.3905 6.54037 31.3664 6.78995 31.2531 7.00329C31.1398 7.21663 30.9465 7.37631 30.7155 7.4473L12.9038 12.9004C12.8175 12.9268 12.7278 12.9403 12.6376 12.9403Z"
        fill="#6C63FF"
      />
      <path
        d="M19.4681 4.55117C20.5864 4.55117 21.4929 3.64465 21.4929 2.52641C21.4929 1.40816 20.5864 0.501648 19.4681 0.501648C18.3499 0.501648 17.4434 1.40816 17.4434 2.52641C17.4434 3.64465 18.3499 4.55117 19.4681 4.55117Z"
        fill="#6C63FF"
      />
      <path
        d="M19.4677 3.80848C20.1758 3.80848 20.7498 3.23445 20.7498 2.52634C20.7498 1.81824 20.1758 1.2442 19.4677 1.2442C18.7596 1.2442 18.1855 1.81824 18.1855 2.52634C18.1855 3.23445 18.7596 3.80848 19.4677 3.80848Z"
        fill="white"
      />
      <path
        d="M61.2274 58.9382H27.009C26.7808 58.938 26.5621 58.8472 26.4008 58.6859C26.2394 58.5246 26.1487 58.3059 26.1484 58.0777V17.0763C26.1487 16.8482 26.2394 16.6295 26.4008 16.4681C26.5621 16.3068 26.7808 16.2161 27.009 16.2158H61.2274C61.4555 16.2161 61.6743 16.3068 61.8356 16.4682C61.9969 16.6295 62.0877 16.8482 62.0879 17.0763V58.0777C62.0876 58.3059 61.9969 58.5246 61.8356 58.6859C61.6743 58.8472 61.4555 58.938 61.2274 58.9382Z"
        fill="#E6E6E6"
      />
      <path
        d="M45.4848 14.191H24.1742C23.7179 14.1916 23.2805 14.3732 22.9579 14.6958C22.6353 15.0184 22.4538 15.4558 22.4531 15.912V57.1969L22.6556 57.1351V15.912C22.6561 15.5094 22.8162 15.1235 23.1009 14.8388C23.3856 14.5541 23.7716 14.3939 24.1742 14.3935H45.5475L45.4848 14.191ZM64.0619 14.191H24.1742C23.7179 14.1916 23.2805 14.3732 22.9579 14.6958C22.6353 15.0184 22.4538 15.4558 22.4531 15.912V62.279C22.4538 62.7353 22.6353 63.1727 22.9579 63.4953C23.2805 63.8179 23.7179 63.9994 24.1742 64.0001H64.0619C64.5182 63.9994 64.9556 63.8179 65.2782 63.4953C65.6008 63.1727 65.7823 62.7353 65.783 62.279V15.912C65.7823 15.4558 65.6008 15.0184 65.2782 14.6958C64.9556 14.3732 64.5182 14.1916 64.0619 14.191ZM65.5805 62.279C65.58 62.6816 65.4199 63.0676 65.1352 63.3523C64.8505 63.637 64.4645 63.7971 64.0619 63.7976H24.1742C23.7716 63.7971 23.3856 63.637 23.1009 63.3523C22.8162 63.0676 22.6561 62.6816 22.6556 62.279V15.912C22.6561 15.5094 22.8162 15.1235 23.1009 14.8388C23.3856 14.5541 23.7716 14.3939 24.1742 14.3935H64.0619C64.4645 14.3939 64.8505 14.5541 65.1352 14.8388C65.4199 15.1235 65.58 15.5094 65.5805 15.912V62.279Z"
        fill="#3F3D56"
      />
      <path
        d="M53.4315 18.6455H34.8037C34.5622 18.6452 34.3306 18.5491 34.1597 18.3783C33.9889 18.2075 33.8929 17.9759 33.8926 17.7343V13.2799C33.8929 13.0383 33.9889 12.8067 34.1597 12.6359C34.3306 12.4651 34.5622 12.369 34.8037 12.3687H53.4315C53.6731 12.369 53.9047 12.4651 54.0755 12.6359C54.2463 12.8067 54.3424 13.0383 54.3427 13.2799V17.7343C54.3424 17.9759 54.2463 18.2075 54.0755 18.3783C53.9047 18.5491 53.6731 18.6452 53.4315 18.6455Z"
        fill="#6C63FF"
      />
      <path
        d="M44.1166 12.6724C45.2348 12.6724 46.1413 11.7659 46.1413 10.6476C46.1413 9.52938 45.2348 8.62286 44.1166 8.62286C42.9983 8.62286 42.0918 9.52938 42.0918 10.6476C42.0918 11.7659 42.9983 12.6724 44.1166 12.6724Z"
        fill="#6C63FF"
      />
      <path
        d="M44.118 11.8809C44.7991 11.8809 45.3513 11.3288 45.3513 10.6476C45.3513 9.96652 44.7991 9.41437 44.118 9.41437C43.4369 9.41437 42.8848 9.96652 42.8848 10.6476C42.8848 11.3288 43.4369 11.8809 44.118 11.8809Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18197_10815">
        <rect width="65.5654" height="64" fill="white" transform="translate(0.216797)" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyEventIcon;
