import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import { theme } from '../../theme';

interface Properties {
  id?: string;
  label: string;
  metric: string;
  change?: string;
  date?: string;
  hasRates?: boolean;
  children?: ReactElement;
}

export default function Card(properties: Properties): ReactElement<Properties> {
  const { label, metric, change, date, hasRates, children } = properties;

  return (
    <Paper sx={{ minWidth: 100, p: 2 }}>
      <Stack>
        <Typography
          variant="caption"
          sx={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: 'secondary.main',
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: '2rem',
          }}
        >
          {metric || 'N/A'}
        </Typography>
        {date && (
          <Typography component="span" variant="subtitle2">
            {change && (
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: 'success.dark',
                  mx: 0.5,
                }}
              >
                {change}
              </Typography>
            )}
            {date}
          </Typography>
        )}
        {hasRates && (
          <Stack direction="row" alignItems="center">
            <CircleIcon
              sx={{
                fontSize: 20,
                color: theme.palette.grey[300],
                mr: 1,
              }}
            />
            {children}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
