import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';

import { AssessmentQuestionEntity } from '../../../../../graphql';
import { theme } from '../../../../../theme';
import { usePreferredTranslation } from '../../../../../hooks/usePreferredTranslation';

interface Properties {
  question: AssessmentQuestionEntity;
  defaultValue?: { feelingStart: number; feelingEnd: number };
  onChange?: (value: any) => void;
}

const ResistanceFeelingInput = ({ question, onChange, defaultValue }: Properties) => {
  const { t } = usePreferredTranslation();
  const handleChange = (event: any, label: string) => {
    onChange?.({ [label]: event.target.value });
  };

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        mb: 1.5,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'hidden',
      }}
    >
      {question?.meta?.feelingStart && (
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={theme.typography.pxToRem(14)} fontWeight={500} sx={{ mb: 0.5 }}>
            {t('journal.feeling.start')}
          </Typography>
          <Select
            value={defaultValue?.feelingStart}
            sx={{
              backgroundColor: theme.palette.common.white,
              width: '100%',
              height: '57px',
              minWidth: 200,
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '10px',
                height: '10px',
              },
            }}
            onChange={(event_) => handleChange(event_, 'feelingStart')}
          >
            <MenuItem value={5}>
              <span style={{ marginLeft: '5px' }}>5</span>
            </MenuItem>
            <MenuItem value={4}>
              <span style={{ marginLeft: '5px' }}>4</span>
            </MenuItem>
            <MenuItem value={3}>
              <span style={{ marginLeft: '5px' }}>3</span>
            </MenuItem>
            <MenuItem value={2}>
              <span style={{ marginLeft: '5px' }}>2</span>
            </MenuItem>
            <MenuItem value={1}>
              <span style={{ marginLeft: '5px' }}>1</span>
            </MenuItem>
            <MenuItem value={0}>
              <span style={{ marginLeft: '5px' }}>0</span>
            </MenuItem>
            <MenuItem value={-1}>
              <span style={{ marginLeft: '5px' }}>-1</span>
            </MenuItem>
            <MenuItem value={-2}>
              <span style={{ marginLeft: '5px' }}>-2</span>
            </MenuItem>
            <MenuItem value={-3}>
              <span style={{ marginLeft: '5px' }}>-3</span>
            </MenuItem>
            <MenuItem value={-4}>
              <span style={{ marginLeft: '5px' }}>-4</span>
            </MenuItem>
            <MenuItem value={-5}>
              <span style={{ marginLeft: '5px' }}>-5</span>
            </MenuItem>
          </Select>
        </Box>
      )}
      {question?.meta?.feelingEnd && (
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={theme.typography.pxToRem(14)} fontWeight={500} sx={{ mb: 0.5 }}>
            {t('journal.feeling.end')}
          </Typography>
          <Select
            value={defaultValue?.feelingEnd}
            sx={{
              backgroundColor: theme.palette.common.white,
              height: '57px',
              width: '100%',
              minWidth: 200,
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '10px',
                height: '10px',
              },
            }}
            onChange={(event_) => handleChange(event_, 'feelingEnd')}
          >
            <MenuItem value={5}>
              <span style={{ marginLeft: '5px' }}>5</span>
            </MenuItem>
            <MenuItem value={4}>
              <span style={{ marginLeft: '5px' }}>4</span>
            </MenuItem>
            <MenuItem value={3}>
              <span style={{ marginLeft: '5px' }}>3</span>
            </MenuItem>
            <MenuItem value={2}>
              <span style={{ marginLeft: '5px' }}>2</span>
            </MenuItem>
            <MenuItem value={1}>
              <span style={{ marginLeft: '5px' }}>1</span>
            </MenuItem>
            <MenuItem value={0}>
              <span style={{ marginLeft: '5px' }}>0</span>
            </MenuItem>
            <MenuItem value={-1}>
              <span style={{ marginLeft: '5px' }}>-1</span>
            </MenuItem>
            <MenuItem value={-2}>
              <span style={{ marginLeft: '5px' }}>-2</span>
            </MenuItem>
            <MenuItem value={-3}>
              <span style={{ marginLeft: '5px' }}>-3</span>
            </MenuItem>
            <MenuItem value={-4}>
              <span style={{ marginLeft: '5px' }}>-4</span>
            </MenuItem>
            <MenuItem value={-5}>
              <span style={{ marginLeft: '5px' }}>-5</span>
            </MenuItem>
          </Select>
        </Box>
      )}
    </Stack>
  );
};

export default ResistanceFeelingInput;
