import { dayjs } from '../dayjs';
import { IDurationOption } from '../../components/calendar/types';
import { TIMEZONES, REAL_TIMEZONES } from '../../constants/types';

/**
 * Returns the label (e.g. '30 minutes') for the specified date range
 *
 * @param {Date} start The start time/date
 * @param {Date} end The end time/date
 * @param {IDurationOption[]} options The
 * @returns {string|undefined} The label for the selected time/date range, or null
 */
export function getDurationLabel(
  start?: Date,
  end?: Date,
  options?: Array<IDurationOption>,
): string | undefined {
  if (!start || !end || !options || options.length === 0) {
    return undefined;
  }

  const seconds = (end.getTime() - start.getTime()) / 1000;
  const selected = options.find((option) => option.seconds === seconds);

  return selected?.label;
}

export function formatTimezone(timezoneString: string | null | undefined) {
  const localTimeZone = dayjs.tz.guess();
  if (!timezoneString) return localTimeZone;
  const timezoneIndex = TIMEZONES.indexOf(timezoneString);
  if (timezoneIndex < 0) return localTimeZone;
  return REAL_TIMEZONES[timezoneIndex];
}

export function getDateBasedOnTimezone(date: Date, timezoneString: string | null | undefined) {
  const timezone = formatTimezone(timezoneString);
  const adjustedDate = dayjs(date).tz(timezone).startOf('day');
  return adjustedDate.toDate();
}

export function getDateStringFromDateTime(date: Date | null | undefined) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function getDateFromDateTime(date: Date) {
  const dateWithoutTime = getDateStringFromDateTime(date); // Format to get only the date part

  // Construct a dayjs date object from the date string
  return dayjs.utc(dateWithoutTime).toISOString() as unknown as Date;
}

export function removeTime(date: Date) {
  return date.toString().split('T')[0];
}

export function getDateTimeStringBasedOnTimezone(
  date: Date,
  timezoneString: string | null | undefined,
) {
  const timezone = formatTimezone(timezoneString);
  const isoString = dayjs(date).toISOString();
  return dayjs(isoString).utc().tz(timezone).format();
}

export function calculateDateLength(fromDate: string | Date, toDate: string | Date) {
  const from = dayjs(fromDate);
  const to = dayjs(toDate);

  return to.diff(from, 'day') + 1;
}
