import parsePhoneNumber from 'libphonenumber-js';
import { CountryPhoneNumberInput } from '../../graphql';

export function formatPhoneNumber(number?: CountryPhoneNumberInput | null) {
  const numberValue: string = number?.numberValue || '';
  const defaultCallingCode: string = number?.countryCallingCode || '';
  const phoneNumber = parsePhoneNumber(numberValue, { defaultCallingCode });

  return phoneNumber ? phoneNumber.formatNational() : '';
}
