import { useEffect, useState } from 'react';
import { Button, Tab } from '@mui/material';
import { Box } from '@mui/system';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ChartTabOutputType } from '../../../../graphql';
import { Tabs } from '../../../atoms';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  chartTabs: ChartTabOutputType[] | undefined;
  chartTabLoading?: boolean;
  onAddChart: () => void;
  onAddChartTabs?: () => void;
  onChartTabChange?: (value: string) => void;
}

const ChartsHeader = ({
  chartTabLoading,
  chartTabs,
  onChartTabChange,
  onAddChart,
  onAddChartTabs,
}: Properties) => {
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const { t } = usePreferredTranslation();
  useEffect(() => {
    if (!chartTabLoading && chartTabs?.length) {
      const isExists = chartTabs.find((ele) => ele.id === selectedTab);
      if (!isExists) {
        setSelectedTab(chartTabs[0]?.id);
      }
    }
  }, [chartTabs, chartTabLoading, selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      onChartTabChange?.(selectedTab);
    }
  }, [selectedTab, onChartTabChange]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px',
      }}
    >
      <Box sx={{ maxWidth: { xs: 380, sm: 480, lg: 680 } }}>
        {!chartTabLoading && (chartTabs?.length || 0) > 0 && (
          <Tabs
            value={selectedTab ?? chartTabs?.[0]?.id}
            onChange={(_, value: string) => setSelectedTab(value)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="chart-tabs"
          >
            {chartTabs?.map((tab) => (
              <Tab value={tab?.id} label={tab?.title} key={tab?.id} sx={{ textWrap: 'nowrap' }} />
            ))}
          </Tabs>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '8px',
          width: { xs: '100%', md: 'auto' },
        }}
      >
        <Button
          size="small"
          color="inherit"
          variant="text"
          startIcon={<SettingsOutlinedIcon />}
          onClick={onAddChartTabs}
        >
          {t('Manage Categories')}
        </Button>
        {!!chartTabs?.length && (
          <Button variant="contained" onClick={() => onAddChart()}>
            {t('Add Chart')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ChartsHeader;
