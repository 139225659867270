import { useEffect } from 'react';

export const useLifecycles = (mount: () => void, unmount?: () => void) => {
  useEffect(() => {
    mount?.();

    return () => {
      unmount?.();
    };
  }, []);
};
