import { WatchQueryFetchPolicy } from '@apollo/client';
import { useUserQuery } from '../../../graphql';
import { useUser } from '../useUser';

interface Properties {
  fetchPolicy?: WatchQueryFetchPolicy | undefined;
}

export function useCurrentUser(properties?: Properties) {
  const { userId } = useUser();
  return useUserQuery({
    variables: { id: userId },
    fetchPolicy: properties?.fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });
}
