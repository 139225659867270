import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';

export interface IParticipantSymptom {
  readonly id: string;
  title: string;
  description: string;
  count: number;
}

export type IParticipantSymptoms = Array<IParticipantSymptom>;

export const ParticipantSymptomsContext = createContext<IParticipantSymptoms>([]);

export const ParticipantSymptomsProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USER_SYMPTOMS });
  const contextValue = useMemo((): IParticipantSymptoms => data || [], [data]);

  return (
    <ParticipantSymptomsContext.Provider value={contextValue}>
      {children}
    </ParticipantSymptomsContext.Provider>
  );
};

export const useParticipantSymptoms = () => useContext(ParticipantSymptomsContext);
