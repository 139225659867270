import { Box, Button, Stack, Typography } from '@mui/material';
import { HTMLAttributes, ReactElement, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ProfileEventsQuery } from '../../../../graphql';
import { EmptyEventIcon } from '../../../atoms';
import { TabbedCard } from '../../../atoms/TabbedCard';
import { EventList } from './EventList';
import { Event, EventsTabs } from './types';
import { getEvents } from './utils';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  schedules: ProfileEventsQuery | undefined;
  loadingProcess?: boolean;
  onSetupCall?: () => void;
  onLike?: (id: string) => void;
  userId?: string;
}

const TabData = ({ tab, schedules, onSetupCall }: Properties & { tab: string }) => {
  const { t } = usePreferredTranslation();
  const scheduledEvents = schedules?.getProfileEvents as Event[];

  const { activeEvents, pastEvents, upcomingEvents } = useMemo(
    () => getEvents(scheduledEvents || [], tab as EventsTabs),
    [scheduledEvents, tab],
  );

  const isEventsListEmpty =
    activeEvents.length === 0 && pastEvents.length === 0 && upcomingEvents.length === 0;

  return (
    <Box>
      {isEventsListEmpty ? (
        <Stack alignItems="center" sx={{ mt: 4 }}>
          <EmptyEventIcon />
          <Typography variant="body1" sx={{ mt: 3, fontWeight: 500 }}>
            {tab === t('Upcoming') ? t('No events scheduled') : t('No past events')}
          </Typography>
          <Button onClick={onSetupCall} variant="contained" sx={{ width: '100%', mt: 3 }}>
            {t('Schedule a Call')}
          </Button>
        </Stack>
      ) : (
        <Stack gap={2} sx={{ maxHeight: 483, overflow: 'auto', mt: 2 }}>
          {activeEvents.length > 0 && (
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {t('Active')}
              </Typography>
              <EventList events={activeEvents} isActive />
            </Stack>
          )}
          {upcomingEvents.length > 0 && (
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {t('Upcoming')}
              </Typography>
              <EventList events={upcomingEvents} />
            </Stack>
          )}
          {pastEvents.length > 0 && (
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {t('Past')}
              </Typography>
              <EventList events={pastEvents} />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default function ParticipantProfileSchedule({
  schedules,
  loadingProcess,
  onSetupCall,
  onLike,
}: Properties) {
  const { t } = usePreferredTranslation();
  return (
    <TabbedCard
      title={t('Scheduled Events')}
      ariaLabel="participant call scheduling tabs"
      sx={{
        p: 3,
        minWidth: 160,
      }}
      secondaryAction={
        <Button
          data-testid="go-to-setup-call"
          variant="text"
          sx={{ textTransform: 'none' }}
          startIcon={<AddIcon />}
          onClick={onSetupCall}
        >
          {t('Setup')}
        </Button>
      }
      tabs={Object.keys(EventsTabs).map((tab) => ({
        label: tab,
        data: (
          <TabData
            tab={tab}
            schedules={schedules}
            loadingProcess={loadingProcess}
            onLike={onLike}
            onSetupCall={onSetupCall}
          />
        ),
      }))}
    />
  );
}
