import { useEffect, useState } from 'react';

function msToTime(milliSeconds: number) {
  let s = milliSeconds;
  const ms = s % 1000;
  s = (s - ms) / 1000;

  const secs = s % 60;
  s = (s - secs) / 60;

  const mins = s % 60;
  const hrs = (s - mins) / 60;

  const formattedSecs = String(secs < 10 ? `0${secs}` : secs);
  const formattedMins = String(mins < 10 ? `0${mins}` : mins);
  const formattedHrs = String(hrs < 10 ? `0${hrs}` : mins);

  if (hrs === 0) {
    return `${formattedMins}:${formattedSecs}`;
  }

  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
}

export const useElapsedTime = (startDate?: Date) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    if (!startDate) {
      return;
    }

    setInterval(() => {
      const now = new Date();
      const time = msToTime(now.getTime() - startDate.getTime());

      setElapsedTime(time);
    }, 1000);
  }, [startDate]);

  return elapsedTime;
};
