import { Stack } from '@mui/system';
import { IconButton, MenuItem, Select } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { ColorPicker } from '../../atoms/ColorPicker';
import { useMediaQuery } from '../../../hooks';
import { AssessmentQuestionEntity } from '../../../graphql';

export interface IPlot {
  id: string;
  sourceId: number | undefined;
  sourceValue: string;
  color: string;
  meta?: {
    dataSourceValue: string;
    dataSourceType: string;
  };
}

type Properties = {
  color: string;
  setColor: (colorValue: string) => void;
  sourceValue: string | undefined;
  setSourceValue: (value: any) => void;
  onDelete: () => void;
  dataSource: AssessmentQuestionEntity | null;
};

export const Plot = ({
  color,
  setColor,
  setSourceValue,
  sourceValue,
  onDelete,
  dataSource,
}: Properties) => {
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(max-width: 900px)');
  // Question types that are not plottable on chart
  const blackListedTypes = new Set(['radio']);
  const resistanceQuestions = new Set([
    'resistance-bands',
    'resistance-free-weight',
    'resistance-body-weight',
  ]);

  return (
    <Stack key={color} direction="row" alignItems="center" sx={{ mt: 1 }}>
      <ColorPicker color={color} setColor={(colorValue) => setColor(colorValue)} />
      <Select sx={{ width: '100%', ml: 2, maxWidth: sm ? '75%' : 240 }} value={sourceValue}>
        <MenuItem disabled>{t('Select Plot')}</MenuItem>
        {dataSource &&
          dataSource?.questions?.map((question) => {
            if (blackListedTypes.has(question?.type as string)) return null;

            return (
              <MenuItem
                key={question.id + question.label}
                value={
                  (resistanceQuestions.has(dataSource.type || '') ? dataSource.id : question.id) +
                  question.label
                }
                onClick={() =>
                  setSourceValue({
                    sourceId: resistanceQuestions.has(dataSource.type || '')
                      ? Number(dataSource.id)
                      : Number(question.id),
                    sourceValue: question.label,
                    meta: {
                      dataSourceValue: dataSource.label,
                      dataSourceType: dataSource.type,
                      questionId: question.id,
                    },
                  })
                }
              >
                {question.label}
              </MenuItem>
            );
          })}
      </Select>
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </Stack>
  );
};
