import create from 'zustand';

import {
  destroyToken,
  getToken,
  getUserId,
  getStudyId,
  removeStudySelected,
  removeStudyArmSelected,
  removeUserId,
  storeStudyId,
  storeToken,
  storeUserId,
  getStudyArmId,
  storeStudyArmId,
} from '../../../utils';
import { UserRoles } from '../../../constants/users';

interface Properties {
  studyId?: number | null;
  studyArmId?: number | null;
  userId?: number | null;
  token?: string | null;
  setUserId: (userId: number) => void;
  setToken: (token: string) => void;
  setStudyId: (studyId: number) => void;
  setStudyArmId: (studyArmId: number | null) => void;
  checkUserRole: (user?: IUser, role?: string) => boolean;
  checkUserPermission: (user?: IUser, permissionName?: string) => boolean;
  checkUserRolesPermission: (userRole?: IUserRole | null, permissionName?: string) => boolean;
  checkUserIsSuperAdministrator: (user?: IUser) => boolean;
}

export const useUser = create<Properties>((set, get) => {
  return {
    studyId: getStudyId(),
    studyArmId: getStudyArmId(),
    userId: getUserId(),
    token: getToken(),
    setToken: (token?: string) => {
      if (token) {
        storeToken(token);

        return set(() => ({ token }));
      }

      removeStudySelected();
      removeStudyArmSelected();
      removeUserId();
      destroyToken();

      return set(() => ({
        token: null,
        userId: null,
        studyId: null,
        studyArmId: null,
      }));
    },

    setStudyId: (studyId: number) => {
      storeStudyId(studyId);

      return set(() => ({ studyId }));
    },

    setStudyArmId: (studyArmId: number | null) => {
      if (studyArmId) {
        storeStudyArmId(studyArmId);
      } else {
        removeStudyArmSelected();
      }

      return set(() => ({ studyArmId }));
    },

    setUserId: (userId: number) => {
      storeUserId(userId);

      return set(() => ({ userId }));
    },

    checkUserRole: (user?: IUser, role?: string): boolean => {
      const { studyId } = get();

      if (!user || !role || !studyId) {
        return false;
      }
      if (role === UserRoles.SUPER_ADMINISTRATOR) {
        return user?.primaryRole?.name.toLowerCase() === UserRoles.SUPER_ADMINISTRATOR;
      }
      return Boolean(
        user.roles?.some(
          (sr) => sr.role.name.toLowerCase() === role.toLowerCase() && sr.study.id === studyId,
        ),
      );
    },

    checkUserIsSuperAdministrator: (user?: IUser): boolean => {
      return user?.primaryRole?.name.toLowerCase() === UserRoles.SUPER_ADMINISTRATOR;
    },

    checkUserRolesPermission: (userRole?: IUserRole | null, permissionName?: string): boolean => {
      if (!userRole) {
        return false;
      }

      return Boolean(
        userRole.role.permissions?.some(
          (p) => p.name.toLowerCase() === permissionName?.toLowerCase(),
        ),
      );
    },

    checkUserPermission: (user?: IUser, permissionName?: string): boolean => {
      const { studyId, checkUserRolesPermission, checkUserIsSuperAdministrator } = get();

      if (!user || !studyId) {
        return false;
      }

      if (checkUserIsSuperAdministrator(user)) {
        return true;
      }

      return Boolean(
        user.roles?.some(
          (sr) => checkUserRolesPermission(sr, permissionName) && sr.study.id === studyId,
        ),
      );
    },
  };
});
