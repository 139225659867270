/* eslint-disable import/no-extraneous-dependencies */
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MutableRefObject, useRef, useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { useClickOutside } from '../../../hooks';

type Properties = {
  color: string;
  setColor: (value: string) => void;
};

export const ColorPicker = ({ color, setColor }: Properties) => {
  const reference = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const { t } = usePreferredTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  useClickOutside(reference, () => setShowColorPicker(false));

  return (
    <Box
      sx={{
        position: 'relative',
        width: 125,
        py: 0.7,
        borderWidth: 1,
        borderColor: '#ccc',
        borderStyle: 'solid',
        borderRadius: 2,
        cursor: 'pointer',
      }}
    >
      <Button
        variant="text"
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 2 }}
        onClick={() => setShowColorPicker(!showColorPicker)}
      >
        {color ? (
          <>
            <Box sx={{ minWidth: 30, minHeight: 30, backgroundColor: color, borderRadius: 2 }} />
            <Typography>{color}</Typography>
          </>
        ) : (
          <Typography sx={{ py: 0.5, px: 1.6 }} variant="body2">
            {t('Select Color')}
          </Typography>
        )}
      </Button>
      {showColorPicker && (
        <Box ref={reference} sx={{ position: 'absolute', left: '100%', bottom: 0, zIndex: 100 }}>
          <SwatchesPicker color={color} onChange={(colorValue) => setColor(colorValue.hex)} />
        </Box>
      )}
    </Box>
  );
};
