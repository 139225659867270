import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Box, IconButton, BoxProps, Portal } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

import { useMediaRecorder } from '../../../../hooks';

interface Properties extends BoxProps {
  readonly disabled?: boolean;
  readonly onComplete?: (blob: Blob, file: File, url: string) => void;
}

export function PhotoRecord({
  disabled = false,
  sx,
  onComplete,
  ...rest
}: Properties): ReactElement<Properties> {
  const video = useRef<HTMLVideoElement | null>(null);
  const mediaRecorder = useMediaRecorder({ video: true, audio: true });
  const handleClick = useCallback(() => {
    if (!mediaRecorder.hasPermission) {
      mediaRecorder.getMediaAccess().catch((error) => {
        if (error.name === 'NotAllowedError') {
          toast.warning(
            `To continue, please give your browser permission to access your camera and microphone.`,
          );
        }
      });
    } else if (mediaRecorder.status === 'recording') {
      if (video.current) {
        const [track] = mediaRecorder.previewVideoStream?.getVideoTracks() || [];

        if (track) {
          const canvas = document.createElement('canvas');

          const { width, height } = track.getSettings();
          canvas.width = width || 100;
          canvas.height = height || 100;

          const context = canvas.getContext('2d');

          if (context) {
            context.drawImage(video.current, 0, 0);
          }

          canvas.toBlob((blob: Blob | null) => {
            if (blob) {
              onComplete?.(blob, new File([blob], 'photo.png'), canvas.toDataURL('image/png'));
            }
          }, 'image/png');

          canvas.remove();
        }
      }

      mediaRecorder.stopRecording();
    } else {
      mediaRecorder.startRecording();
    }
  }, [mediaRecorder, onComplete]);

  useEffect(() => {
    if (mediaRecorder.status === 'recording' && video.current) {
      video.current.srcObject = mediaRecorder.previewVideoStream;
    }
  }, [mediaRecorder.previewVideoStream, mediaRecorder.status]);

  return (
    <Box data-testid="photoRecordComponent" sx={{ display: 'inline-flex', ...sx }} {...rest}>
      <Portal>
        {mediaRecorder.status === 'recording' && (
          <Box
            sx={{
              position: 'fixed',
              right: '50px',
              top: '50px',
            }}
          >
            <video height="300px" muted autoPlay controls ref={video} />
          </Box>
        )}
      </Portal>

      <IconButton disabled={disabled} aria-label="Photo Recording" onClick={handleClick}>
        {mediaRecorder.status === 'recording' ? <WallpaperIcon /> : <AddAPhotoIcon />}
      </IconButton>
    </Box>
  );
}
