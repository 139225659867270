import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { AssessmentQuestionEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import QuestionNavigation from '../QuestionNavigation';
import ResistanceExerciseInput from './ResistanceExerciseInput';
import ResistanceAnswerPreview from '../ResistanceAnswerPreview';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { useActiveJournal } from '../../../../states/journal/useActiveJournal';

interface Properties {
  question: AssessmentQuestionEntity;
  resistanceAnswers?: IResistanceIntakeAnswers[] | [];
  showResistanceAnswerPreview?: boolean;
  questionAnswersMap?: Map<any, any>;
  onChange?: (value: IResistanceIntakeTab[]) => void;
  onAnswer?: (value: IResistanceIntakeAnswers[]) => void;
  onSubmit?: (value: IResistanceIntakeAnswers[]) => void;
  onPrev?: () => void;
  onFinishResistanceAnswerPreview?: () => void;
}

const ResistanceExercise = ({
  question,
  showResistanceAnswerPreview,
  resistanceAnswers,
  onChange,
  onAnswer,
  onSubmit,
  onPrev,
  onFinishResistanceAnswerPreview,
  questionAnswersMap,
}: Properties): ReactElement => {
  const { t } = usePreferredTranslation();
  const [step, setStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [value, setValue] = useState<IResistanceIntakeTab[]>([]);
  const [answers, setAnswers] = useState<IResistanceIntakeAnswers[]>([]);
  const [selectedValues, setSelectedValues] = useState<IResistanceExerciseIntakeTab[]>([]);

  const { isEditing } = useActiveJournal();

  useEffect(() => {
    if (!isEditing) return;

    const tabs = new Map<string, IResistanceExerciseIntakeTab>();
    const newAnswers: IResistanceIntakeAnswers[] = [];

    for (const ques of question?.questions || []) {
      const categoryQuestions = ques.questions || [];

      for (const catgQuestion of categoryQuestions) {
        const answer = questionAnswersMap?.get(catgQuestion.id);

        if (answer) {
          if (tabs.has(ques.id)) {
            const currentTab = tabs.get(ques.id);
            tabs.set(ques.id, {
              question: ques,
              values: [...(currentTab?.values || []), catgQuestion],
            });
          } else {
            tabs.set(ques.id, {
              question: ques,
              values: [catgQuestion],
            });
          }
          newAnswers.push({
            questionId: catgQuestion.id,
            value: answer,
          });
        }
      }
    }

    setSelectedValues([...tabs.values()]);
    setAnswers(newAnswers);
  }, []);

  const handleChange = useCallback(
    (event: any, parent: AssessmentQuestionEntity, question: AssessmentQuestionEntity) => {
      const index = selectedValues.findIndex(
        (element: IResistanceExerciseIntakeTab) => element.question?.id === parent.id,
      );
      if (event.target.checked) {
        if (index === -1) {
          selectedValues.push({ question: parent, values: [question] });
        } else {
          selectedValues[index]?.values.push(question);
        }
      } else {
        const values = selectedValues[index]?.values || [];
        if (index >= 0 && selectedValues[index]) {
          const newValues = values.filter(
            (ele: AssessmentQuestionEntity) => ele?.id !== question?.id,
          );
          if (newValues.length === 0) {
            selectedValues.splice(index, 1);
          } else {
            selectedValues[index] = {
              question: parent,
              values: newValues,
            };
          }
        }
      }
      setSelectedValues([...selectedValues]);
    },
    [selectedValues],
  );

  const handleSubmit = useCallback(() => {
    const ans = answers.filter((a) =>
      selectedValues.some((v) => v.values?.some((q) => q.id === a.questionId)),
    );
    onSubmit?.(ans);
  }, [onSubmit, answers]);

  const handlePrevious = useCallback(() => {
    setValue([]);
    setStep(0);
    onPrev?.();
  }, [onPrev]);

  const handleAnswers = useCallback((newAnswers: IResistanceIntakeAnswers[]) => {
    setAnswers(newAnswers);
  }, []);

  useEffect(() => {
    setActiveTab(0);
  }, [step]);

  if (showResistanceAnswerPreview) {
    return (
      <ResistanceAnswerPreview
        answers={resistanceAnswers}
        question={selectedValues}
        onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
      />
    );
  }

  if (step === 1) {
    return (
      <>
        <ResistanceExerciseInput
          defaultValues={answers}
          tabs={selectedValues}
          onChange={handleAnswers}
          currentTab={activeTab}
          onActiveTabChanged={(index: number) => {
            setActiveTab(index);
          }}
        />
        <QuestionNavigation
          submit={activeTab === selectedValues.length - 1}
          onPrev={() => setStep(0)}
          onNext={() => {
            setActiveTab(activeTab + 1);
          }}
          onSubmit={handleSubmit}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {question.questions?.map((q) => {
          const values =
            selectedValues.find(
              (element: IResistanceExerciseIntakeTab) => element.question?.id === q.id,
            )?.values || [];
          const totalCounts: number = values?.length || 0;
          return (
            <Accordion
              defaultExpanded
              key={q.id}
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.grey[100],
                my: 2,
                '.MuiCollapse-wrapperInner': {
                  width: '95%',
                },
                '::before': {
                  height: '0px',
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6" component="span">
                    {q.label}
                  </Typography>
                  {totalCounts > 0 && (
                    <Typography
                      sx={{
                        alignSelf: 'flex-end',
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        borderRadius: 5,
                        pl: 1.5,
                        pr: 1.5,
                        pt: 0.5,
                        pb: 0.5,
                      }}
                    >
                      {totalCounts}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <FormGroup>
                  {(q?.questions || []).map((option) => (
                    <FormControlLabel
                      key={option.id}
                      labelPlacement="start"
                      onChange={(event) => handleChange(event, q, option)}
                      control={<Checkbox />}
                      label={<Typography sx={{ width: '100%' }}>{option.label}</Typography>}
                      checked={!!values.some((ele) => ele.id === option.id)}
                      sx={{
                        mb: 2,
                        p: 1,
                        width: '100%',
                        backgroundColor: theme.palette.common.white,
                        borderRadius: '8px',
                      }}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box
          sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
          onClick={() => {
            onSubmit?.([{ questionId: question.id, values: ['DID_NOT_DO_RESISTANCE'] }]);
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: theme.palette.primary.main,
              borderRadius: 5,
              pt: 0.5,
              pb: 0.5,
            }}
          >
            {t('journal.exercies.decline')}
          </Typography>
        </Box>
      </Box>
      <QuestionNavigation
        onPrev={handlePrevious}
        onNext={() => setStep(1)}
        disabledSubmit={selectedValues.length === 0}
      />
    </>
  );
};

export default ResistanceExercise;
