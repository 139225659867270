import { SVGProps } from 'react';

const BedIcon = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M17 22v-5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 19 15h7c1.1 0 2.042.392 2.825 1.175C29.608 16.958 30 17.9 30 19v3H17Zm-6 3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 24c0-.283.096-.521.288-.713A.967.967 0 0 1 11 23h18c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 29 25H11Zm2-3a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 10 19c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 13 16c.833 0 1.542.292 2.125.875S16 18.167 16 19s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 13 22Z"
    />
  </svg>
);
export default BedIcon;
