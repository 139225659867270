import {
  RefObject,
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import type { AssignableReference } from './types';

type AnyFunction = (...properties: any[]) => unknown;

export const noop = (): void => {
  /**/
};

export function getElementHeight(
  element: RefObject<HTMLElement> | { current?: { scrollHeight: number } },
): string | number {
  if (!element?.current) return 'auto';

  return element.current.scrollHeight;
}

export const callAll =
  (...fns: AnyFunction[]) =>
  (...properties: any[]): void => {
    for (const function_ of fns) {
      function_(...properties);
    }
  };

export function getAutoHeightDuration(height: number | string): number {
  if (!height || typeof height === 'string') return 0;

  const constant = height / 36;

  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

export function assignReference<ReferenceValueType = any>(
  reference: AssignableReference<ReferenceValueType> | null | undefined,
  value: any,
) {
  if (!reference) return;
  if (typeof reference === 'function') reference(value);
  else {
    try {
      reference.current = value;
    } catch {
      throw new Error(`Cannot assign value "${value}" to ref "${reference}"`);
    }
  }
}

export function mergeReferences<ReferenceValueType = any>(
  ...references: (AssignableReference<ReferenceValueType> | null | undefined)[]
) {
  if (references.every((reference) => !reference)) return null;

  return (node: any) => {
    for (const reference of references) {
      assignReference(reference, node);
    }
  };
}

export function useControlledState(
  isExpanded?: boolean,
  defaultExpanded?: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [stateExpanded, setStateExpanded] = useState(defaultExpanded || false);
  const initiallyControlled = useRef(isExpanded !== null);
  const expanded = initiallyControlled.current ? (isExpanded as boolean) : stateExpanded;
  const setExpanded = useCallback((n: any) => {
    if (!initiallyControlled.current) setStateExpanded(n);
  }, []);

  return [expanded, setExpanded];
}

export function useEffectAfterMount(callback: () => void, dependencies: unknown[]): void {
  const justMounted = useRef(true);
  useEffect(() => {
    if (!justMounted.current) {
      callback();
      return;
    }

    justMounted.current = false;
  }, dependencies);
}

const useIsomorphicLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

let serverHandoffComplete = false;

let id = 0;

const genId = (): number => {
  id += 1;

  return id;
};

export function useUniqueId(idFromProperties?: string | null) {
  const initialId = idFromProperties || (serverHandoffComplete ? genId() : null);

  const [_id, setId] = useState(initialId);

  useIsomorphicLayoutEffect((): void => {
    if (!_id) setId(genId());
  }, []);

  useEffect((): void => {
    if (!serverHandoffComplete) {
      serverHandoffComplete = true;
    }
  }, []);
  return _id ? `${_id}` : undefined;
}
