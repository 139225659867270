import { HTMLAttributes, ReactElement, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormControl, NativeSelect, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { green } from '@mui/material/colors';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  title?: string;
  period?: string;
  sx?: object;
  hideSelection?: boolean;
  showStatus?: boolean;
  children: ReactElement;
  onChange?: (value: any) => void;
  onPeriodChange?: (value: any) => void;
}

export function LineChart({
  title,
  children,
  onChange,
  onPeriodChange,
  sx = {},
  period = '1m',
  hideSelection = false,
  showStatus = false,
}: Properties): ReactElement {
  const theme = useTheme();
  const { t } = usePreferredTranslation();
  const handleChange = useCallback(
    (event: any) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const handlePeriodChange = useCallback(
    (event: any) => {
      if (onPeriodChange) {
        onPeriodChange(event.target.value);
      }
    },
    [onPeriodChange],
  );

  return (
    <Paper sx={{ p: 3, pb: 0, ...sx }}>
      <Stack spacing={2}>
        {/*  Section header */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            component="p"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          {!hideSelection && (
            <>
              <Box flexGrow={1}>
                <FormControl variant="standard">
                  <NativeSelect
                    disableUnderline
                    defaultValue={title}
                    sx={{ color: theme.palette.text.secondary }}
                    onChange={handleChange}
                  >
                    <option value="Steps">Steps</option>
                    <option value="Active Zone Minutes">Active Zone Minutes</option>
                  </NativeSelect>
                </FormControl>
              </Box>
              <Box>
                <FormControl variant="standard">
                  <NativeSelect
                    disableUnderline
                    value={period}
                    sx={{ color: theme.palette.text.secondary }}
                    onChange={handlePeriodChange}
                  >
                    <option value="1d">1 Day</option>
                    <option value="1w">1 Week</option>
                    <option value="1m">1 Month</option>
                    <option value="3m">3 Months</option>
                    <option value="6m">6 Months</option>
                    <option value="1y">1 Year</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </>
          )}
        </Stack>
        {children}
      </Stack>
      {showStatus && (
        <Stack direction="row" alignItems="center" mt={2}>
          <Box
            sx={{
              background: green[300],
              width: 6,
              height: 6,
              minWidth: 6,
              borderRadius: '100%',
              mr: 1,
            }}
          />
          <Typography variant="caption" color={theme.palette.text.secondary}>
            {t('On track')}
          </Typography>
        </Stack>
      )}
    </Paper>
  );
}
