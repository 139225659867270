export const participantMedications = [
  {
    id: '007f1f77bcf86cd799439011',
    label: 'Abraxane - IV',
    userId: 11,
    description: '5mg tablet per day',
    updatedAt: new Date('Mon, 22 Jun'),
    createdAt: new Date('Mon, 22 Jun'),
    treatment: true,
  },
  {
    id: '107f1f77bcf86cd799439011',
    userId: 11,
    label: 'Cyclophosphamide (Cytoxan)',
    description: '5mg tablet per day',
    updatedAt: new Date('Mon, 22 Jun'),
    createdAt: new Date('Mon, 22 Jun'),
    treatment: false,
  },
];
