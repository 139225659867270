import { ReactElement } from 'react';
import { BoxProps } from '@mui/material/Box';
import { UserRoles } from '../../../constants/users';
import { useCurrentUser, useUser } from '../../../states';
import { SidebarLeftParticipant } from './SidebarLeftParticipant';
import { SidebarLeftResearcher } from './SidebarLeftResearcher';

interface Properties extends BoxProps {
  onClose: () => void;
}

export function SidebarLeft(properties: Properties): ReactElement {
  const { data: userData } = useCurrentUser({ fetchPolicy: 'cache-only' });
  const { checkUserRole } = useUser();

  if (checkUserRole(userData?.user, UserRoles.PARTICIPANT)) {
    return <SidebarLeftParticipant {...properties} />;
  }

  return <SidebarLeftResearcher {...properties} />;
}
