import { PropsWithChildren, ReactNode } from 'react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PhoneNumberField from './PhoneNumberField';
import { PhoneNumberInputType } from '../../graphql';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface ProfileInfoPhoneNumbersFieldProperties {
  label: string;
  type: string;
  isEditing: boolean;
  addButtonLabel: string;
  phoneNumbers: PhoneNumberInputType[];
  loading: boolean;
  onPhoneNumberChange: (phoneNumber: ITelephoneInput, index?: number) => void;
  setAdditionalNumberForCall: (info: any, index: number) => void;
  handleRemoveNumberForCall: (index: number) => void;
  handleAddAnotherNumberForCall: (type: string) => void;
  icon?: ReactNode;
}

interface ProfileInfoEditableFieldProperties extends PropsWithChildren {
  label: string;
  value: string | number | null | undefined;
  isEditing: boolean;
  icon?: ReactNode;
}

export default function ProfileInfoEditableField({
  label,
  children,
  isEditing,
  value,
  icon,
}: ProfileInfoEditableFieldProperties) {
  return (
    <Grid item sm={6} xs={6}>
      <Stack direction="row" gap={1} flexGrow={1}>
        {/* {!isEditing && icon} */}
        <Stack flexGrow={1}>
          {label && (
            <Box sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 0.5, whiteSpace: 'nowrap' }}>
              {label}
            </Box>
          )}
          <Box sx={{ fontSize: '0.9rem', fontWeight: 400 }}>{isEditing ? children : value}</Box>
        </Stack>
      </Stack>
    </Grid>
  );
}

export const ProfileInfoPhoneNumbersField = ({
  label,
  type,
  isEditing,
  addButtonLabel,
  phoneNumbers,
  loading,
  onPhoneNumberChange,
  setAdditionalNumberForCall,
  handleRemoveNumberForCall,
  handleAddAnotherNumberForCall,
  icon,
}: ProfileInfoPhoneNumbersFieldProperties) => {
  const { t } = usePreferredTranslation();
  return (
    <Grid item sm={6}>
      <Stack direction="row" gap={1} flexGrow={1}>
        {/* {!isEditing && icon} */}
        <Stack flexGrow={1}>
          <Box sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 0.5 }}>{label}</Box>
          <Box>
            {!isEditing &&
              phoneNumbers
                .filter((p) => p.type === type)
                .map((phoneNumber, index) => (
                  <Box flexGrow={1} key={index}>
                    <PhoneNumberField
                      disabled={loading}
                      index={index}
                      numberDataTestId={`info-phone-number-${type}-${index}`}
                      editMode={isEditing}
                      phoneNumber={phoneNumber}
                      onChange={onPhoneNumberChange}
                    />
                  </Box>
                ))}

            {isEditing &&
              phoneNumbers.map((item: PhoneNumberInputType, itemIndex: number) => {
                if (item.type !== type) return null;
                return (
                  <Stack key={itemIndex} direction="row" alignItems="flex-end" spacing={2}>
                    <Box flexGrow={1}>
                      <MuiTelInput
                        fullWidth
                        disabled={loading}
                        defaultCountry="US"
                        value={item?.country?.numberValue || ''}
                        onChange={(_v, info) => setAdditionalNumberForCall(info, itemIndex)}
                      />
                      {item?.country?.numberValue && !matchIsValidTel(item.country.numberValue) && (
                        <Typography variant="body2" sx={{ my: 1 }} color="red">
                          {t('Invalid phone number')}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ pb: 1 }}>
                      <IconButton
                        disabled={loading}
                        onClick={() => handleRemoveNumberForCall(itemIndex)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                );
              })}
            {isEditing && (
              <Box>
                <Button
                  variant="text"
                  color="secondary"
                  disabled={loading}
                  startIcon={<AddIcon />}
                  onClick={() => handleAddAnotherNumberForCall('SMS')}
                >
                  {addButtonLabel}
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
};
