import { Box, Stack, Typography, alpha } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { dayjs } from '../../../../utils/dayjs';
import { theme } from '../../../../theme';
import { Particle } from '../../particles';
import { FORMATS } from '../../../../constants';
import { downloadFilebyUrl } from '../../../../utils';

interface Properties {
  owner: boolean;
  recording: {
    url: string;
    type: string;
    createdAt: Date;
  };
}

export const RecordingItem = ({ owner, recording }: Properties) => {
  return (
    <Box
      sx={{
        minWidth: '60%',
        maxWidth: '60%',
        py: 0,
        px: 0,
        display: 'flex',
        alignSelf: owner ? 'flex-end' : 'flex-start',
        justifyContent: owner ? 'flex-end' : 'flex-start',
      }}
    >
      <Box
        sx={{
          px: 1.5,
          py: 1.5,
          display: 'flex',
          flexGrow: 1,
          minWidth: 230,
          borderRadius: owner ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
          color: owner ? theme.palette.common.white : theme.palette.common.black,
          background: owner ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12),
        }}
      >
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Typography
            fontWeight="normal"
            sx={{ wordBreak: 'break-word', mb: 1, whiteSpace: 'pre-line' }}
          >
            <Particle particle={{ content: recording.url, type: recording.type }} />
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              fontSize={10}
              fontWeight="normal"
              color={alpha(owner ? theme.palette.common.white : theme.palette.common.black, 0.6)}
            >
              {dayjs(recording.createdAt).format(FORMATS.DATE)} (
              {dayjs().to(dayjs(recording.createdAt))})
            </Typography>
            <IconButton onClick={() => downloadFilebyUrl(recording.url, 'recording')}>
              <GetAppIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
