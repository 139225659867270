export const permissions = [
  {
    name: 'See profiles',
  },
  {
    name: 'Edit profiles',
  },
  {
    name: 'Study modules',
  },
  {
    name: 'Active data readings',
  },
  {
    name: 'PROs',
  },
  {
    name: 'Telephone assessments',
  },
];
