import { useCallback, useState } from 'react';
import {
  IconButton,
  TextField,
  MenuItem,
  Select,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  InputLabel,
  FormControl,
  Button,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { ReactComponent as PieChartIcon } from '../../../assets/icons/pie-chart.svg';

import { IQuestion } from '../../../types/intervention';
import { theme } from '../../../theme';
import { InputField } from '../../atoms';
import { QuestionCategoryEntity } from '../../../graphql';
import QuestionTypes from './QuestionTypes';
import { EditableDefaultQuestion } from './EditableDefaultQuestion';

const filter = createFilterOptions<QuestionCategoryEntity>();

interface EditableQuestionProperties extends IQuestion {
  categories: QuestionCategoryEntity[];
  loading?: boolean;
  onDelete: () => void;
  onUpdateQuestion?: (question: IQuestion) => void;
  onSave?: () => void;
  showDelete?: boolean;
  showUpdateButton?: boolean;
}

export const EditableQuestion = ({
  id,
  loading,
  type,
  category,
  categories,
  identifier,
  variableName,
  label,
  required,
  options,
  onDelete,
  onUpdateQuestion,
  onSave,
  showUpdateButton,
  showDelete,
}: EditableQuestionProperties) => {
  const { t } = usePreferredTranslation();
  const [showVariableNameInput, setShowVariableNameInput] = useState<boolean>(false);
  const handleUpdateQuestion = useCallback(
    (options_: any = {}) =>
      onUpdateQuestion &&
      onUpdateQuestion({
        id,
        type,
        category,
        label,
        variableName,
        identifier,
        options,
        ...options_,
      }),
    [id, identifier, label, variableName, onUpdateQuestion, options, type, category],
  );

  const handleSave = useCallback(() => onSave && onSave(), [onSave]);

  const handleIdentifierChange = useCallback(
    (event: any) => {
      handleUpdateQuestion({ identifier: event.target.value });
    },
    [handleUpdateQuestion],
  );

  const handleTitleChange = useCallback(
    (event: any) => {
      handleUpdateQuestion({ label: event.target.value });
    },
    [handleUpdateQuestion],
  );

  const handleQuestionTypeChange = useCallback(
    (event: any) => {
      handleUpdateQuestion({ type: event.target.value });
    },
    [handleUpdateQuestion],
  );

  const handleQuestionCategoryChange = useCallback(
    (event: any, newValue: QuestionCategoryEntity) => {
      handleUpdateQuestion({
        category: newValue,
      });
    },
    [handleUpdateQuestion],
  );

  const handleQuestionOptionsChange = useCallback(
    (newValue: string[]) => {
      handleUpdateQuestion(newValue);
    },
    [handleUpdateQuestion],
  );

  const handleChangeRequired = useCallback(() => {
    handleUpdateQuestion({ required: !required });
  }, [handleUpdateQuestion, required]);

  return (
    <Box
      sx={{
        mb: 3,
        pb: 2,
        pr: 8,
        borderBottom: '1px solid',
        borderColor: theme.palette.divider,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <TextField
          disabled={loading}
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: theme.typography.h5.fontSize },
          }}
          variant="standard"
          fullWidth
          placeholder={t('Identifier eg: PRO-CTCAE#12')}
          value={identifier}
          onChange={handleIdentifierChange}
        />
        {showVariableNameInput ? (
          <TextField
            variant="outlined"
            size="small"
            value={variableName || ''}
            placeholder={t('Variable name')}
            sx={{
              maxWidth: '160px',
              backgroundColor: theme.palette.common.white,
              borderRadius: 1,
            }}
            onChange={(event) => {
              handleUpdateQuestion({ variableName: event.target.value });
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setShowVariableNameInput(!showVariableNameInput);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowVariableNameInput(!showVariableNameInput)}
                    sx={{ p: 0 }}
                  >
                    <PieChartIcon
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <IconButton
            onClick={() => setShowVariableNameInput(!showVariableNameInput)}
            sx={{ p: 0.5 }}
          >
            <PieChartIcon style={{ height: 18, width: 18 }} />
          </IconButton>
        )}
      </Box>
      <FormControl fullWidth sx={{ mt: 1 }} disabled={loading}>
        <Autocomplete
          id="free-solo-demo"
          disableClearable
          defaultValue={category || undefined}
          options={categories}
          handleHomeEndKeys
          filterOptions={(availableOptions, parameters) => {
            const filtered = filter(availableOptions, parameters);

            const { inputValue } = parameters;
            // Suggest the creation of a new value
            const isExisting = availableOptions.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                id: '',
                name: inputValue,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }

            // Regular option
            return option.name;
          }}
          onChange={handleQuestionCategoryChange}
          renderInput={(parameters) => (
            <TextField
              {...parameters}
              label={t('Question Category')}
              InputProps={{
                ...parameters.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 1 }} disabled={loading}>
        <InputLabel>{t('Question type')}</InputLabel>
        <Select
          data-testid="question-type-selector"
          label={t('Question type')}
          value={type || ''}
          onChange={handleQuestionTypeChange}
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>
            {t('Select a question type')}
          </MenuItem>
          <MenuItem data-testid="type-radio" value={QuestionTypes.radio}>
            Radio
          </MenuItem>
          <MenuItem data-testid="type-checkbox" value={QuestionTypes.checkbox}>
            Checkbox
          </MenuItem>
          <MenuItem data-testid="type-multiselect" value={QuestionTypes.multiselect}>
            MultiSelect
          </MenuItem>
          <MenuItem data-testid="type-select" value={QuestionTypes.select}>
            Select
          </MenuItem>
          <MenuItem data-testid="type-text" value={QuestionTypes.text}>
            Textarea
          </MenuItem>
          <MenuItem data-testid="type-input" value={QuestionTypes.input}>
            Input
          </MenuItem>
          <MenuItem data-testid="type-boolean" value={QuestionTypes.boolean}>
            Yes/No
          </MenuItem>
          <MenuItem data-testid="type-range-5" value={QuestionTypes.range5}>
            Range 1-5
          </MenuItem>
          <MenuItem data-testid="type-range-10" value={QuestionTypes.range10}>
            Range 1-10
          </MenuItem>
          <MenuItem data-testid="type-range-slider" value={QuestionTypes.slider}>
            Slider
          </MenuItem>
          <MenuItem data-testid="type-plus-minus" value={QuestionTypes.plusMinus}>
            Plus Minus
          </MenuItem>
          <MenuItem data-testid="type-image-picker" value={QuestionTypes.imagePicker}>
            Image Picker
          </MenuItem>
        </Select>
      </FormControl>

      {type !== '' && (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch disabled={loading} checked={required} onChange={handleChangeRequired} />
              }
              label={t('Required')}
            />
          </FormGroup>

          <InputField
            disabled={loading}
            placeholder={t('Question title')}
            value={label}
            onChange={handleTitleChange}
          />

          {[
            QuestionTypes.select,
            QuestionTypes.radio,
            QuestionTypes.multiselect,
            QuestionTypes.checkbox,
            QuestionTypes.imagePicker,
          ].includes(type) && (
            <EditableDefaultQuestion
              type={type}
              loading={loading}
              options={options}
              onChange={handleQuestionOptionsChange}
            />
          )}

          {/* {[JournalQuestionTypes.incrementPanel.value].includes(type) && (
            <EditableJournalQuestion type={type} />
          )} */}
        </>
      )}

      {showUpdateButton && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            {t('Save Changes')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
