/* eslint-disable react/no-unstable-nested-components */
import {
  ComposedChart as RechartComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Scatter,
  ResponsiveContainer,
} from 'recharts';
import { compareAsc } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { dayjs } from '../../utils/dayjs';
import {
  AssessmentQuestionEntity,
  GraphDataPointsQuery,
  useGraphDataPointsLazyQuery,
  useLibraryLazyQuery,
  useUserQuery,
} from '../../graphql';
import { IPlot } from '../users/UserCharts/Plot';
import {
  calculateOffsetDays,
  getDataValue,
  transformDataSourceForCustomTypes,
} from '../../utils/charts';
import { getDateStringFromDateTime, removeTime } from '../../utils';
import { findPlots, getPlotkey, getPlotsDataItems } from '../../utils/charts/plots';

const Y_AXIS_CHART_ID = 'yaxis-chart';
const Y_AXIS_CHART_ID1 = 'yaxis-chart1';

export enum GraphTypes {
  None = 'Select graph type',
  PieChart = 'Pie Chart',
  ScatterChart = 'Scatter Chart',
  RadialBarChart = 'Radial Bar Chart',
  SimpleChart = 'Simple Chart',
  DashedLineChart = 'Dashed Line Chart',
  BarChart = 'Bar Chart',
}

const CustomTooltip = ({ active, payload }: { active: any; payload: any }) => {
  if (active && payload && payload?.length > 0) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '8px',
          borderRadius: '4px',
          pointerEvents: 'none',
          zIndex: 9999,
          maxWidth: '70%',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        {payload.map((item: any) => (
          <p key={item?.dataKey} style={{ margin: 0, color: item.color }}>
            {item.name} - {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

interface Properties {
  data: GraphDataPointsQuery | null | undefined;
  xKey: string;
  yKey: string;
  yKey2: string;
  plots: IPlot[];
  plots1: IPlot[];
  graphType: GraphTypes;
  graphType1: GraphTypes;
  dataSource?: AssessmentQuestionEntity | null;
  dataSource1?: AssessmentQuestionEntity | null;
  profileId: number;
  isPreview: boolean;
  meta: any;
}

export const ComposedChart = ({
  data,
  xKey,
  yKey,
  plots,
  plots1,
  graphType,
  graphType1,
  yKey2,
  dataSource1: dataSource1Property,
  dataSource: dataSourceProperty,
  profileId,
  isPreview,
  meta,
}: Properties) => {
  const [dataSources, setDataSources] = useState({
    dataSource: dataSourceProperty,
    dataSource1: dataSource1Property,
  });
  const { data: userData } = useUserQuery({
    variables: { id: profileId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [graphDataQuery] = useGraphDataPointsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getLibrary] = useLibraryLazyQuery({
    fetchPolicy: 'network-only',
  });

  const transformDataSources = async () => {
    const [transformeddataSource, transformedDataSource1] = await Promise.all([
      transformDataSourceForCustomTypes(
        dataSourceProperty!,
        graphDataQuery,
        getLibrary,
        profileId,
        meta?.dataSourceType,
        meta?.dataSourceValue,
      ),
      transformDataSourceForCustomTypes(
        dataSource1Property!,
        graphDataQuery,
        getLibrary,
        profileId,
        meta?.dataSourceType1,
        meta?.dataSourceValue1,
      ),
    ]);

    setDataSources({
      dataSource: transformeddataSource as AssessmentQuestionEntity,
      dataSource1: transformedDataSource1 as AssessmentQuestionEntity,
    });
  };

  useEffect(() => {
    if (!isPreview) {
      transformDataSources();
    }
  }, []);

  const { plotsKeys, transformedData } = useMemo(() => {
    const dataByDateMap = new Map();
    const keysMap = new Map();

    let dataSource;
    let dataSource1;

    if (isPreview) {
      dataSource = dataSourceProperty;
      dataSource1 = dataSource1Property;
    } else {
      dataSource = dataSources.dataSource;
      dataSource1 = dataSources.dataSource1;
    }

    const { graphDataPoints } = data ?? {};
    const { items } = graphDataPoints ?? { items: [] };
    const dataItems = getPlotsDataItems(items, dataSource, dataSource1);
    for (const item of dataItems) {
      const plot = findPlots(item, plots, plots1);

      if (plot) {
        const isInDataSource = plots.some((plotItem) => plotItem.id === plot.id);

        const { color } = plot;
        const plotType = isInDataSource
          ? { graphType, yAxisId: Y_AXIS_CHART_ID }
          : { graphType: graphType1, yAxisId: Y_AXIS_CHART_ID1 };

        const dataSource_ = isInDataSource ? dataSource : dataSource1;
        const plotKey = getPlotkey(plot, dataSource_, item);

        if (plotKey && !keysMap.has(plotKey)) {
          keysMap.set(plotKey, {
            color,
            graphType: plotType.graphType,
            yAxisId: plotType.yAxisId,
          });
        }

        const plotDate = getDateStringFromDateTime(
          removeTime(item.answerDate ?? new Date()) as unknown as Date,
        );
        const formattedDate = dayjs(plotDate).format('MM/DD');
        const dataValue = getDataValue(item, plot?.sourceValue) as number;

        if (dataByDateMap.has(formattedDate)) {
          if (plot.meta?.dataSourceType === 'ivr') {
            if (!dataByDateMap.get(formattedDate)[plotKey ?? '']) {
              dataByDateMap.get(formattedDate)[plotKey ?? ''] = dataValue;
            }
          } else {
            dataByDateMap.get(formattedDate)[plotKey ?? ''] =
              ((dataByDateMap.get(formattedDate)[plotKey ?? ''] as number) ?? 0) + dataValue;
          }
        } else if (plotKey) {
          const newData = {
            [xKey === 'answerDate' ? 'answerDate' : 'offsetDays']:
              xKey === 'answerDate'
                ? formattedDate
                : calculateOffsetDays(
                    dayjs(plotDate),
                    // NOTE: assumption start date cannot be null
                    dayjs(userData?.user?.startDate),
                  ),
            [plotKey]: dataValue,
          };
          dataByDateMap.set(formattedDate, newData);
        }
      }
    }

    const graphData = [...dataByDateMap.values()];

    const sortedData = graphData.sort((a, b) => {
      if (xKey === 'answerDate') {
        return compareAsc(new Date(a.answerDate), new Date(b.answerDate));
      }
      if (xKey === 'offsetDays') {
        return a.offsetDays - b.offsetDays;
      }
      return 0;
    });

    return {
      transformedData: sortedData,
      plotsKeys: keysMap,
    };
  }, [
    plots,
    plots1,
    graphType,
    graphType1,
    dataSource1Property,
    dataSourceProperty,
    data,
    xKey,
    userData,
    dataSources,
  ]);

  const plotsToRender = useMemo(() => {
    const renderedPlots = [];

    for (const [key, { color, graphType, yAxisId }] of plotsKeys) {
      switch (graphType) {
        case GraphTypes.SimpleChart: {
          renderedPlots.push(
            <Line
              yAxisId={yAxisId}
              key={`line-${key}`}
              dataKey={key}
              stroke={color}
              type="monotone"
              activeDot
              dot={{ strokeWidth: 10 }}
            />,
          );
          break;
        }
        case GraphTypes.DashedLineChart: {
          renderedPlots.push(
            <Line
              key={`dashed-line-${key}`}
              yAxisId={yAxisId}
              dataKey={key}
              stroke={color}
              type="monotone"
              strokeDasharray="3 3"
              activeDot
              dot={{ strokeWidth: 10 }}
            />,
          );
          break;
        }
        case GraphTypes.ScatterChart: {
          renderedPlots.push(
            <Scatter key={`scatter-${key}`} yAxisId={yAxisId} dataKey={key} fill={color} />,
          );
          break;
        }

        case GraphTypes.BarChart: {
          renderedPlots.push(
            <Bar yAxisId={yAxisId} key={`bar-${key}`} dataKey={key} fill={color} barSize={30} />,
          );
          break;
        }
        default: {
          break;
        }
      }
    }

    return renderedPlots;
  }, [plotsKeys]);

  return (
    <ResponsiveContainer width="90%" height={300}>
      <RechartComposedChart
        data={transformedData}
        margin={{
          top: 10,
          right: 5,
          left: 5,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <Tooltip
          content={({ active, payload }) => <CustomTooltip active={active} payload={payload} />}
        />
        <Legend
          verticalAlign="bottom"
          align="left"
          iconType="circle"
          height={35}
          wrapperStyle={{
            bottom: '-30px',
          }}
        />
        {plotsToRender}
        <XAxis dataKey={xKey} padding={{ left: 0, right: 10 }}>
          <Label
            value={xKey === 'answerDate' ? 'Time' : xKey === 'offsetDays' ? 'Offset Days' : ''}
            offset={-15}
            position="insideBottom"
          />
        </XAxis>
        <YAxis
          yAxisId={Y_AXIS_CHART_ID}
          label={{ value: yKey, angle: -90, position: 'insideLeft' }}
        />
        {plots1?.length > 0 && (
          <YAxis
            yAxisId={Y_AXIS_CHART_ID1}
            orientation="right"
            label={{ value: yKey2, angle: 90, position: 'insideRight' }}
          />
        )}
      </RechartComposedChart>
    </ResponsiveContainer>
  );
};
