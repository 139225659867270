import { HTMLAttributes, ReactElement, useCallback } from 'react';
import { FormControl, InputLabel, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';

import { theme } from '../../../theme';

interface Properties extends HTMLAttributes<HTMLElement> {
  dataTestId?: string;
  readonly disabled?: boolean;
  readonly label?: string;
  readonly value?: string | null;
  readonly placeholder?: string;
  readonly onChange?: (event: any) => void;
  sx?: any;
  formSx?: any;
}

export function DateField({
  disabled = false,
  dataTestId,
  label,
  value,
  placeholder,
  sx = {},
  onChange,
  formSx,
}: Properties): ReactElement<Properties> {
  const handleChange = useCallback(
    (event: any) => {
      onChange?.(event);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth variant="standard" sx={{ mb: 2, ...formSx }}>
      <Stack spacing={3}>
        <InputLabel
          shrink
          sx={{
            ml: '2px',
            transform: 'scale(1)',
            color: theme.palette.common.black,
            fontWeight: 500,
          }}
        >
          {label}
        </InputLabel>
        <DatePicker
          disabled={disabled}
          label={placeholder}
          value={value}
          onChange={handleChange}
          renderInput={(parameters) => (
            <TextField
              {...parameters}
              inputProps={{
                ...parameters.inputProps,
                'data-testid': dataTestId,
              }}
              sx={sx}
            />
          )}
        />
      </Stack>
    </FormControl>
  );
}
