import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { theme } from '../../../../../theme';
import { AssessmentQuestionEntity } from '../../../../../graphql';
import ResistanceFeelingInput from './ResistanceFeelingInput';
import ResistanceSelectInput from './ResistanceSelectInput';
import ResistanceRepsSetsInput from './ResistanceRepsSets';
import JournalQuestionTypes from '../../../../organisms/EditableQuestion/JournalQuestionTypes';

interface Properties {
  tabs: IResistanceExerciseIntakeTab[];
  currentTab: number;
  defaultValues?: IResistanceIntakeAnswers[];
  onChange?: (value: IResistanceIntakeAnswers[]) => void;
  onActiveTabChanged?: (tabIndex: number) => void;
}

export default function ResistanceExerciseInput({
  tabs,
  currentTab,
  onChange,
  onActiveTabChanged,
  defaultValues,
}: Properties): ReactElement {
  const [answers, setAnswers] = useState<IResistanceIntakeAnswers[]>(defaultValues || []);
  const activeTab = tabs[currentTab];

  const handleTabChange = useCallback((event: any, value: number) => {
    onActiveTabChanged?.(value);
  }, []);

  const handleChange = useCallback(
    (option: string, { questionId, value }: IResistanceIntakeAnswer) => {
      let newAnswers = [...answers, { questionId, value: { [option]: value } }];

      if (answers.some((a) => a.questionId === questionId)) {
        newAnswers = answers?.map((q) => {
          const existingOptions: any = q.value;
          if (q.questionId === questionId) {
            const newValue =
              existingOptions && existingOptions[option] && typeof value !== 'string'
                ? { ...existingOptions[option], ...value }
                : value;
            return { ...q, value: { ...q.value, [option]: newValue } };
          }
          return q;
        }, []);
      }
      if (onChange) {
        onChange(newAnswers as IResistanceIntakeAnswers[]);
      }
      setAnswers(newAnswers as IResistanceIntakeAnswers[]);
    },
    [answers, onChange],
  );

  const getQuestions = (
    parentQuestion: AssessmentQuestionEntity,
    question: AssessmentQuestionEntity,
  ) => {
    const { id: questionId } = parentQuestion;
    const value = answers.find((a) => a.questionId === questionId)?.value;

    switch (question.type) {
      case JournalQuestionTypes.resistanceRepsSets.value: {
        const defaultValue = value?.[question.id] as string | undefined;

        return (
          <ResistanceRepsSetsInput
            key={questionId + question.id}
            onChange={(value: any) => handleChange(question.id, { questionId, value })}
            question={question}
            parentQuestion={parentQuestion}
            defaultValue={defaultValue}
          />
        );
      }
      case JournalQuestionTypes.resistanceSelect.value: {
        const defaultValue = value?.[question.id] as string | undefined;

        return (
          <FormControl fullWidth sx={{ mb: 1.5 }}>
            <ResistanceSelectInput
              defaultValue={defaultValue}
              key={questionId + question.id}
              onChange={(value: any) => {
                handleChange(question.id, { questionId, value });
              }}
              question={question}
            />
          </FormControl>
        );
      }
      case JournalQuestionTypes.resistanceFeeling.value: {
        const defaultValue = value?.[question.id] as { feelingStart: number; feelingEnd: number };

        return (
          <ResistanceFeelingInput
            key={questionId + question.id}
            onChange={(value: any) => handleChange(question.id, { questionId, value })}
            question={question}
            defaultValue={defaultValue}
          />
        );
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs onChange={handleTabChange} value={currentTab}>
          {tabs?.map((q, index) => (
            <Tab key={q.question.id} label={q.question.label} value={index} />
          ))}
        </Tabs>
      </Box>
      {activeTab?.values.map((option, index) => (
        <Accordion
          defaultExpanded={index === 0}
          key={option.id}
          sx={{
            borderRadius: '8px',
            backgroundColor: theme.palette.grey[100],
            my: 2,
            '::before': {
              height: '0px',
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              sx={{
                alignSelf: 'flex-end',
                background: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: 5,
                p: '4px 12px',
                mr: 1,
              }}
            >
              {index + 1}
            </Typography>
            <Typography variant="h6" component="span">
              {option.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(option.questions || [])
              .filter((ele) => ele.meta?.type !== 'resistance-input')
              .sort((q1, q2) => (q1.order || 0) - (q2.order || 0))
              .map((element) => {
                return getQuestions(option, element);
              })}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
