import { useMemo } from 'react';
import _ from 'lodash';
import { TOptions } from 'i18next';
import { useFitbitActivitiesListQuery } from '../../../../graphql';
import { convertToMiles, formatMiles, getDateStringFromDateTime } from '../../../../utils';

export const useFitbitActivityOverview = (
  user: IUser,
  fromDate: Date,
  toDate: Date,
  t: (key: string, options?: TOptions | undefined) => string,
) => {
  const { data, loading } = useFitbitActivitiesListQuery({
    variables: {
      fromDate: getDateStringFromDateTime(fromDate),
      toDate: getDateStringFromDateTime(toDate),
      userId: Number(user?.id),
    },
  });

  const overviewData = useMemo(() => {
    const activities = data?.fitbitActivitiesList || [];

    // calculate total steps
    const totalSteps = _.sumBy(activities, 'steps') || 0;
    const totalStepsFormatted = totalSteps.toLocaleString();

    // calculate total distance
    const totalDistance = _.sumBy(activities, 'distance') || 0;
    const distanceInMiles = convertToMiles(totalDistance, 'Kilometer')?.toFixed(2);
    const formattedDistance = formatMiles(Number(distanceInMiles), t);

    // calculate active mins
    let lightlyActiveMinutes = 0;
    let fairlyActiveMinutes = 0;
    let veryActiveMinutes = 0;
    let sedentaryMinutes = 0;

    for (const activity of activities) {
      for (const level of activity.activityLevel || []) {
        switch (level.name) {
          case 'lightly': {
            lightlyActiveMinutes += level.minutes;
            break;
          }
          case 'fairly': {
            fairlyActiveMinutes += level.minutes;
            break;
          }
          case 'very': {
            veryActiveMinutes += level.minutes;
            break;
          }
          case 'sedentary': {
            sedentaryMinutes += level.minutes;
            break;
          }

          default: {
            break;
          }
        }
      }
    }

    return {
      steps: `${totalStepsFormatted} ${t('steps')}`,
      distance: formattedDistance,
      lightlyActiveMinutes: `${lightlyActiveMinutes} ${t('mins')}`,
      fairlyActiveMinutes: `${fairlyActiveMinutes} ${t('mins')}`,
      veryActiveMinutes: `${veryActiveMinutes} ${t('mins')}`,
      sedentaryMinutes: `${sedentaryMinutes} ${t('mins')}`,
    };
  }, [data]);

  return { overviewData, loading };
};
