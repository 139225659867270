import { Box, SxProps, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useState } from 'react';

interface Properties {
  value: string;
  setValue(value: string): void;
  onClick?: () => void;
  sx?: SxProps<Theme> | undefined;
}

export const EditableTextField = ({ setValue, value, sx, onClick }: Properties) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);

  return (
    <Box sx={{ paddingBottom: '10px', paddingLeft: '5px', cursor: 'pointer', ...sx }}>
      {isFieldFocused ? (
        <TextField
          inputProps={{
            style: { padding: 0, fontSize: '15px' },
          }}
          autoFocus
          fullWidth
          variant="outlined"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={() => setIsFieldFocused(false)}
          sx={{ border: 'none', '& fieldset': { border: 'none' } }}
        />
      ) : (
        <Typography
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setIsFieldFocused(true);
          }}
          fontSize={15}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};
