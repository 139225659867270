import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { AssessmentQuestionEntity, AssessmentQuestionInputType } from '../../../graphql';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import { findAnswers } from '../../../utils/journal';
import PopUp from './PopUp';
import QuestionInput from './QuestionInput';

interface Properties {
  question: AssessmentQuestionInputType;
  i18nKey?: string;
  questions?: AssessmentQuestionInputType[] | null;
  scrollableList?: boolean;
  showResistanceAnswerPreview: boolean | undefined;
  resistanceAnswers?: IResistanceIntakeAnswers[] | [];
  questionAnswersMap: Map<any, any>;
  handlePrevious: () => void;
  onFinishResistanceAnswerPreview?: () => void;
  handleResistanceSubmit?: (values: IResistanceIntakeAnswers[]) => void;
  handleCardioInputSubmit: (value: ICardioInput) => void;
}

export const QuestionList = ({
  i18nKey,
  scrollableList,
  questions,
  question,
  resistanceAnswers,
  showResistanceAnswerPreview,
  onFinishResistanceAnswerPreview,
  handleResistanceSubmit,
  handleCardioInputSubmit,
  handlePrevious,
  questionAnswersMap,
}: Properties): React.ReactElement => {
  const sm = useMediaQuery('(min-width:750px)');
  const { t } = usePreferredTranslation();
  const { answersCollection, updateAnswers, updateAnswersCollection, isEditing } =
    useActiveJournal();

  useEffect(() => {
    if (!isEditing) return;

    const alreadyQuestionsAnswers = findAnswers(
      questionAnswersMap,
      (scrollableList ? questions : [question]) || [],
    );
    if (alreadyQuestionsAnswers.length === 0) return;

    if (scrollableList) {
      updateAnswersCollection(alreadyQuestionsAnswers);
    } else {
      const newAnswers = {
        questionId: alreadyQuestionsAnswers[0]?.questionId,
        values: [] as IAnswer[],
      };
      for (const a of alreadyQuestionsAnswers) {
        newAnswers.values.push(a.values[0] as IAnswer);
      }
      updateAnswers(newAnswers);
    }
  }, []);

  const onCategoryChange = useCallback(
    (ques: AssessmentQuestionEntity, values: IAnswer[]) => {
      if (scrollableList) {
        updateAnswersCollection([
          ...answersCollection.filter((a) => a.questionId !== ques?.id),
          { questionId: ques.id, values },
        ]);
      } else {
        updateAnswers({ questionId: ques.id, values });
      }
    },
    [answersCollection, scrollableList, updateAnswers, updateAnswersCollection],
  );

  const onAnswerChange = useCallback(
    (values: IAnswer[], ques?: AssessmentQuestionInputType | null) => {
      if (scrollableList) {
        updateAnswersCollection([
          ...answersCollection.filter((a) => a.questionId !== ques?.id),
          { questionId: ques?.id || '', values },
        ]);
      } else {
        updateAnswers({ questionId: ques?.id || '', values });
      }
    },
    [answersCollection, scrollableList, updateAnswers, updateAnswersCollection],
  );

  const questionElements = (scrollableList ? questions : [question])?.map((q) => {
    const cardioValue = questionAnswersMap.get(q?.id);
    return (
      <Grid
        key={q?.id}
        container
        flexDirection="column"
        spacing={showResistanceAnswerPreview ? 0 : 1}
        columns={(question?.questions?.length || 0) > 3 ? 12 : 1}
        className={(question?.questions?.length || 0) <= 3 ? 'single-column' : 'multi-column'}
      >
        {!showResistanceAnswerPreview && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <Typography variant={sm ? 'h5' : 'h6'}>
              {i18nKey ? <Trans i18nKey={i18nKey}>{q?.label}</Trans> : q?.label}
            </Typography>

            {q?.tooltip?.title && (
              <PopUp
                title={q?.tooltip?.title}
                description={q?.tooltip?.description}
                icon={q?.tooltip?.icon}
              />
            )}
          </Stack>
        )}
        <QuestionInput
          question={q as AssessmentQuestionEntity}
          onCategoryChange={onCategoryChange}
          onChange={(values) => onAnswerChange(values, q)}
          onPrev={handlePrevious}
          onSubmitResistance={handleResistanceSubmit}
          onSubmitCardio={handleCardioInputSubmit}
          resistanceAnswers={resistanceAnswers}
          showResistanceAnswerPreview={showResistanceAnswerPreview}
          onFinishResistanceAnswerPreview={onFinishResistanceAnswerPreview}
          questionAnswersMap={questionAnswersMap}
          cardioValue={cardioValue}
          scrollableList={scrollableList}
        />
      </Grid>
    );
  });

  if (!questionElements || questionElements.length === 0) {
    return <Typography>{t('No Questions available')}</Typography>;
  }

  return <Box>{questionElements}</Box>;
};
