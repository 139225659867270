import { Stack } from '@mui/system';
import { ReactElement, useCallback, useState } from 'react';
import { Radio, RadioGroup, Box, Grid } from '@mui/material';

import { grey } from '@mui/material/colors';
import { theme } from '../../../theme';

interface Properties {
  value?: string;
  options: string[] | null;
  onChange?: (value: string) => void;
}

export const RadioButtonGrid = ({
  value = '',
  options,
  onChange,
}: Properties): ReactElement<Properties> => {
  const [selectedValue, setSelectedValue] = useState(value);
  const handleChange = useCallback(
    (event: any) => {
      if (onChange) {
        onChange(event.target.value);
      }

      setSelectedValue(event.target.value);
    },
    [onChange],
  );

  const handleClick = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }

      setSelectedValue(value);
    },
    [onChange],
  );

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      <RadioGroup value={value} onChange={handleChange}>
        <Grid container>
          {options?.map((option, index_) => (
            <Grid
              key={index_}
              item
              xs={12}
              sm={6}
              fontSize={theme.typography.pxToRem(16)}
              fontWeight={500}
            >
              <Stack
                direction="row"
                alignItems="center"
                border="1px solid #E6E5E5"
                sx={{ mx: 2, mb: 1, p: 2, borderRadius: '8px', cursor: 'pointer' }}
                onClick={() => handleClick(option)}
              >
                <Box flexGrow={1} sx={{ minWidth: 50 }}>
                  {index_ + 1}. {option}
                </Box>
                <Radio
                  value={option}
                  checked={selectedValue === option}
                  sx={{ color: grey[300] }}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

export default RadioButtonGrid;
