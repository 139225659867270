import './linearProgress.css';

import { Box, Button, DialogTitle, LinearProgress, Stack, Typography } from '@mui/material';
import { HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import { alpha } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DialogCloseButton, Image } from '../../atoms';

import { AssessmentQuestionInputType } from '../../../graphql';
import CategoryCard from './CategoryCard';
import { JournalCalendar } from '../../atoms/Journal';
import { theme } from '../../../theme';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';

interface Properties extends HTMLAttributes<ReactElement> {
  questions?: AssessmentQuestionInputType[] | null;
  answers?: any;
  loading?: boolean;
}

export default function SplashStep({ questions, answers, loading }: Properties) {
  const { t, Trans } = usePreferredTranslation();
  const sm = useMediaQuery('(min-width:750px)');

  const { activeJournal, activeDate, setActiveDate, closeJournalModal, startJournal } =
    useActiveJournal();

  const handleStart = useCallback(() => {
    const quesId = questions?.findIndex((q) => !answers.find((a: any) => a.id === q.id).isAnswered);
    if (quesId !== undefined && quesId !== -1) startJournal(quesId);
  }, [questions, answers, startJournal]);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleChangeDate = useCallback(
    (value: any) => {
      setActiveDate(value);
    },
    [setActiveDate],
  );
  const handleToggleCalendarOpen = () => setCalendarOpen(!calendarOpen);

  const questionsAnswered = answers.filter((a: any) => a.isAnswered).length;
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      >
        <JournalCalendar
          libraryIds={[activeJournal?.id || '']}
          open={calendarOpen}
          currentDate={activeDate}
          onChangeDate={handleChangeDate}
          onToggleOpen={handleToggleCalendarOpen}
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          maxHeight: sm ? '480px' : 'auto',
          overflow: 'hidden',
        }}
      >
        <Image
          wrapperProps={{
            style: {
              width: '100%',
            },
          }}
          style={
            sm
              ? {
                  width: '100%',
                }
              : {
                  width: '100%',
                  height: '100%',
                }
          }
          src={activeJournal?.icon || ''}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            backgroundImage: `linear-gradient(transparent, ${alpha(
              theme.palette.common.black,
              0.1,
            )}, #000)`,
          }}
        />
      </Box>
      <DialogCloseButton iconColor="white" onClick={closeJournalModal} />
      <DialogTitle
        sx={{
          boxShadow: '0px -8px 8px black',
          zIndex: 1000,
          background: 'black',
          color: 'white',
          padding: '40px',
          pt: 1,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography component="p" variant="h4">
            {activeJournal?.title}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
          <AccessTimeIcon />
          <Typography component="p">
            {t('journal.takes.minute', { count: Number(activeJournal?.duration?.toString() || 1) })}
          </Typography>
        </Stack>
        <Box display="flex" justifyContent="space-between">
          <Typography component="p" sx={{ fontSize: 16 }}>
            {t('journal.info.progress')}
          </Typography>
          <Box display="flex" alignItems="center">
            <LinearProgress
              variant="determinate"
              sx={{
                height: 10,
                width: 100,
                borderRadius: 5,
                background: alpha(theme.palette.common.white, 0.3),
                mr: 1,
              }}
              color="success"
              value={(questionsAnswered / (questions?.length || 100)) * 100}
            />
            <Typography component="p" sx={{ fontSize: 16 }}>
              {`${questionsAnswered}/${questions?.length}`}
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={2} sx={{ mt: 3, overflowX: 'auto', mr: -5, pb: 1 }}>
          {questions &&
            questions.length > 0 &&
            questions?.map((question, index) => (
              <CategoryCard
                key={question.id}
                label={question.category?.name || ''}
                index={index}
                loading={loading}
                isAnswered={answers.find((a: any) => a.id === question.id).isAnswered}
                responseId={answers.find((a: any) => a.id === question.id).responseId}
              />
            ))}
        </Stack>
        <Typography component="p" sx={{ fontSize: 14, py: 4 }}>
          <Trans i18nKey="journal.missing.help" components={{ b: <b /> }} />
        </Typography>
        <Button
          variant="contained"
          onClick={handleStart}
          sx={{ width: 160 }}
          disabled={loading || questionsAnswered === questions?.length}
        >
          {t('journal.button.start')}
        </Button>
      </DialogTitle>
    </>
  );
}
