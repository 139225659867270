import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  List,
  TablePagination,
  Box,
  BoxProps,
  ListItem,
  InputAdornment,
  IconButton,
  TextField,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { height } from '@mui/system';
import { theme } from '../../../../theme';
import { VoiceEntity, useVoicesQuery, UserEntity } from '../../../../graphql';
import { useChannel, useEvent } from '../../../../modules';
import { PUSHER } from '../../../../constants';
import { VoiceCardsPlaceholder } from '../../../atoms';
import { useCurrentUser, useUser } from '../../../../states';

import { Voice } from '../Voice';
import { withValidStudyUsers } from '../../../hoc/withValidStudyUsers';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties extends BoxProps {
  readonly user?: UserEntity;
  readonly onSelectVoice: (voice: VoiceEntity | null) => void;
  readonly selectedVoice: VoiceEntity | null;
  readonly hasSearch?: boolean;
  readonly hasPagination?: boolean;
}

const VoicesComp = ({
  user,
  onSelectVoice,
  selectedVoice,
  hasSearch = true,
  hasPagination = true,
  ...rest
}: Properties) => {
  const { t } = usePreferredTranslation();
  const { studyId } = useUser();
  const [search, setSearch] = useState('');
  const listReference = useRef<HTMLUListElement | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [channelVoices, setChannelVoices] = useState<VoiceEntity[]>([]);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setChannelVoices([]);
    setPage(value);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannelVoices([]);
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setChannelVoices([]);
      setPage(0);
    },
    [setSearch, setChannelVoices, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setChannelVoices([]);
    setPage(0);
  }, [setSearch, setChannelVoices, setPage]);

  const currentUser = useCurrentUser();

  const voicesQuery = useVoicesQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      user: Number(currentUser.data?.user?.id),
      correspondingUser: user?.id ? Number(user.id) : null,
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    skip: !currentUser.data?.user?.id,
  });

  useEffect(() => {
    if (listReference.current) {
      listReference.current.scrollTop = listReference.current.scrollHeight;
    }
  }, [voicesQuery.loading]);

  const voices = useMemo<VoiceEntity[]>(
    () => [...channelVoices, ...(voicesQuery.data?.voices.items || [])].reverse(),
    [channelVoices, voicesQuery],
  );

  const count = (voicesQuery.data?.voices.count || 0) + channelVoices.length;

  const voicesChannel = useChannel(PUSHER.CHANNELS.VOICES);

  useEvent<VoiceEntity>(
    voicesChannel,
    `${PUSHER.EVENTS.VOICE_CREATED}.${currentUser.data?.user?.id || 0}`,
    (voice?: VoiceEntity) => {
      if (voice) {
        setPage(0);
        setChannelVoices([]);
        voicesQuery.refetch();
      }
    },
  );
  useEvent<number>(
    voicesChannel,
    `${PUSHER.EVENTS.VOICES_UPDATED}.${currentUser.data?.user?.id || 0}`,
    (count?: number) => {
      if (count) {
        setPage(0);
        setChannelVoices([]);
        voicesQuery.refetch();
      }
    },
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}
      {...rest}
    >
      {hasSearch && (
        <Box
          sx={{
            p: 2,
            flex: 0,
            display: 'flex',
          }}
        >
          {hasSearch && (
            <TextField
              fullWidth
              placeholder={t('Search...')}
              type="text"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: search && (
                  <IconButton aria-label="Reset search voices..." onClick={handleSearchReset}>
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        </Box>
      )}
      {count === 0 && (currentUser.loading || voicesQuery.loading) ? (
        <VoiceCardsPlaceholder />
      ) : (
        <List
          sx={{ p: 0, overflow: 'auto' }}
          ref={(r) => {
            listReference.current = r;
          }}
        >
          {voices.length === 0 && (
            <ListItem>
              <Alert sx={{ width: '100%' }} severity="error">
                {t('Nothing found')}
              </Alert>
            </ListItem>
          )}

          {voices?.map((voice: VoiceEntity) => (
            <ListItem
              key={voice.id}
              onClick={() => onSelectVoice(voice)}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderColor: theme.palette.divider,
                p: 2,
              }}
            >
              <Voice
                owner={String(voice.sender?.id) === String(currentUser.data?.user?.id)}
                voice={voice}
              />
            </ListItem>
          ))}
        </List>
      )}
      {hasPagination && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'flex-end',
          }}
        >
          {hasPagination && (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              labelRowsPerPage={t('Rows per page:')}
              nextIconButtonProps={{
                'aria-label': t('Go to next page'),
                title: t('Go to next page'),
              }}
              backIconButtonProps={{
                'aria-label': t('Go to previous page'),
                title: t('Go to previous page'),
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const Voices = withValidStudyUsers(VoicesComp);

export { Voices };
