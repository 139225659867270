import { ReactElement } from 'react';
import { TabsProps, Tabs as MuiTabs } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { theme } from '../../../theme';

export function Tabs({ children, sx, variant, ...rest }: TabsProps): ReactElement<ReactElement> {
  return (
    <MuiTabs
      TabIndicatorProps={{ hidden: true }}
      sx={{
        minHeight: 40,
        '& .MuiTabs-scroller': {
          textAlign: 'center',
        },
        '& .MuiTabs-flexContainer': {
          background: alpha(theme.palette.text.secondary, 0.12),
          padding: '3px',
          borderRadius: '10px',
          display: variant === 'fullWidth' ? 'flex' : 'inline-flex',
        },
        '& button': {
          borderRadius: '10px',
          fontWeight: 600,
          textTransform: 'capitalize',
          minHeight: 40,
          flex: 1,
        },
        '& button.Mui-selected': {
          background: theme.palette.common.white,
          color: theme.palette.text.primary,
        },
        '& button:not(.Mui-selected):hover': {
          color: theme.palette.common.black,
        },
        ...sx,
      }}
      variant={variant}
      {...rest}
    >
      {children}
    </MuiTabs>
  );
}
