import { create } from 'zustand';
import { getDateStringFromDateTime } from '../../../utils';
import { ActiveJournal, ActiveStep } from './types';

export const useActiveJournal = create<ActiveJournal>((set, get) => ({
  activeJournal: null,
  activeStep: ActiveStep.Splash,
  incompleteModalJournal: null,
  activeDate: getDateStringFromDateTime(new Date()) as unknown as Date,
  answers: undefined,
  answersCollection: [],
  refetch: false,
  questionIndex: 0,
  isEditing: false,
  editingResponseId: null,

  updateAnswers: (answers) => set({ answers }),
  updateAnswersCollection: (answers) => set({ answersCollection: answers }),
  setIncompleteModalJournal: (journal) => set({ incompleteModalJournal: journal }),
  setActiveJournal: (journal) => set({ activeJournal: journal }),
  closeJournalModal: () =>
    set({
      activeJournal: null,
      isEditing: false,
      answers: undefined,
      answersCollection: [],
      activeDate: getDateStringFromDateTime(new Date()) as unknown as Date,
    }),
  setActiveStepToSplash: () =>
    set({
      activeStep: ActiveStep.Splash,
      isEditing: false,
      answers: undefined,
      answersCollection: [],
    }),
  setActiveStepToQuestion: () => set({ activeStep: ActiveStep.Question }),
  setActiveDate: (date) => set({ activeDate: date }),
  refetchJournalDetails: () => set({ refetch: !get().refetch }),
  startJournal: (qsIndex) => set({ activeStep: ActiveStep.Question, questionIndex: qsIndex }),
  setQuestionIndex: (index) => set({ questionIndex: index }),
  setIsEditing: (isEditing) => set({ isEditing }),
  editJournal: (index, responseId) =>
    set({
      questionIndex: index,
      editingResponseId: responseId,
      isEditing: true,
      activeStep: ActiveStep.Question,
    }),
  closeEditing: () =>
    set({ isEditing: false, editingResponseId: null, answers: undefined, answersCollection: [] }),
}));
