import { SVGProps } from 'react';

const FootSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M13.4 17.5h-3.2c.067-.233.154-.454.263-.663.108-.208.237-.412.387-.612l3.85-5.15a2.006 2.006 0 0 1 1.138-.762c.475-.126.929-.071 1.362.162l.7.35a1.988 1.988 0 0 1 1.1 1.8v2.1l1.85-.475c.5-.133.983-.07 1.45.188.467.258.783.629.95 1.112l1.625 4.9 4.25 4.25c.333.333.563.692.688 1.075S30 26.567 30 27c0 .617-.167 1.167-.5 1.65a3.023 3.023 0 0 1-1.3 1.075l-11.35-10.85a5.133 5.133 0 0 0-1.6-1.025 4.933 4.933 0 0 0-1.85-.35ZM22.15 30c-.5 0-.975-.092-1.425-.275a4.467 4.467 0 0 1-1.25-.775l-8.125-7.375a3.72 3.72 0 0 1-.775-.962c-.2-.359-.342-.73-.425-1.113h3.25c.383 0 .754.067 1.112.2.359.133.68.342.963.625L25.575 30H22.15Z"
    />
  </svg>
);
export default FootSvg;
