import { SVGProps } from 'react';

const ActivityIcon = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M17.433 22.13H15.04c-.381 0-.676-.173-.884-.52a.945.945 0 0 1 0-1.04l6.45-11.287c.138-.243.338-.33.598-.26.26.07.39.243.39.52v8.427h2.393c.381 0 .676.173.884.52a.945.945 0 0 1 0 1.04l-6.45 11.287c-.139.242-.338.33-.598.26-.26-.07-.39-.243-.39-.52V22.13Z"
    />
  </svg>
);
export default ActivityIcon;
