import { SVGProps } from 'react';

const MartialArtSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <g clipPath="url(#a)">
      <rect width={40} height={40} fill="#fff" rx={20} />
      <path
        fill="#6C5DD3"
        d="M16.936 10.527c0-1.378 1.12-2.5 2.5-2.5 1.378 0 2.5 1.122 2.5 2.5 0 1.38-1.122 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5ZM31.438 15.528c0 .689-.56 1.25-1.25 1.25h-6.377a.625.625 0 0 0-.625.625v1.544l-3.1-3.959 1.792-1.803 3.737 1.068a.639.639 0 0 0 .172.024h4.402c.689 0 1.25.56 1.25 1.25ZM23.186 20.974v2.678c0 .094.02.187.061.271l3.127 6.51c.187.389.162.82-.067 1.185-.23.364-.607.573-1.037.573h-.314c-.468 0-.887-.26-1.096-.68l-3.64-7.3a.625.625 0 0 0-1.04-.118l-1.52 1.848a5.641 5.641 0 0 0-1.279 3.571v1.734a1.226 1.226 0 0 1-2.45 0v-2.357c0-.823.23-1.627.667-2.323l1.618-2.582a.624.624 0 0 0 .095-.332v-2.357h1.491a2.261 2.261 0 0 0 2.259-2.26 2.261 2.261 0 0 0-2.259-2.259h-2.116a.625.625 0 1 0 0 1.25h2.116a1.01 1.01 0 0 1 0 2.019h-1.935a.624.624 0 0 0-.19-.057l-2.055-.27a1.223 1.223 0 0 1-1.059-1.207l-.002-2.665c0-.469.273-.901.696-1.102l3.417-1.621 2.107 2.223 4.406 5.627-.001.001Z"
      />
    </g>
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <defs>
      <clipPath id="a">
        <rect width={40} height={40} fill="#fff" rx={20} />
      </clipPath>
    </defs>
  </svg>
);
export default MartialArtSvg;
