import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

interface Properties extends ButtonProps {
  dimension?: number;
}

export const RoundButton = styled(({ dimension = 40, ...rest }: Properties) => (
  <Button aria-label="Button" data-testId="roundButtonComponent" {...rest} />
))(({ theme, dimension }) => ({
  borderRadius: '9999px',
  padding: 0,
  width: `${dimension}px`,
  minWidth: `${dimension}px`,
  maxWidth: `${dimension}px`,
  height: `${dimension}px`,
  minHeight: `${dimension}px`,
  maxHeight: `${dimension}px`,
}));
