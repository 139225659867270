/* eslint-disable react/no-unstable-nested-components */
import { Box } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { lastEventId } from '@sentry/browser';
import { dayjs } from '../../../../../utils/dayjs';
import { FitbitChartsDataQuery } from '../../../../../graphql';
import { ChartsProperties } from './types';
import { generateRandomBoldColor, getActivityDuration } from '../../../../../utils';
import { theme } from '../../../../../theme';

interface Plot {
  [key: string]: number | string;
  date: string;
}

interface TooltipProperties {
  data: FitbitChartsDataQuery | undefined;
  tooltipProps: TooltipProps<ValueType, NameType>;
}

const legendFormatter = (value: string) => {
  return (
    <span style={{ color: 'black', fontWeight: 500, fontSize: theme.typography.pxToRem(12) }}>
      {value}
    </span>
  );
};

const formatTick = (tick: number) => {
  return `${tick} min`;
};

const CustomTooltip = ({ data, tooltipProps }: TooltipProperties) => {
  const { payload } = tooltipProps;

  if (!payload) return null;

  const label = payload[0]?.payload?.date as string;
  const dataKey = payload[0]?.dataKey;

  const { duration, timeRanges } = getActivityDuration(data, label, dataKey);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        outline: 'none',
        p: 1,
        borderRadius: 2,
        pointerEvents: 'none',
      }}
    >
      <p style={{ margin: 0 }}>
        {dataKey}: {duration}min ({label})
      </p>
      <ul>
        {timeRanges.map((timeRange, index) => (
          <li key={index}>
            <p>{timeRange}</p>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export const ExerciseChart: React.FC<ChartsProperties> = ({ data }) => {
  const { bars, chartData } = useMemo(() => {
    const activityData = data?.fitbitChartsData.activity || [];

    const groupedDataBydate = _.groupBy(activityData, (o) => {
      const dateWithoutTimezone = o.startTime?.split('.')?.[0];
      return dayjs.utc(dateWithoutTimezone).format('MM/DD');
    });

    const finalData: Array<Plot> = [];

    for (const [date, activities] of Object.entries(groupedDataBydate)) {
      const plot: Plot = {
        date,
      };

      for (const activity of activities) {
        const type = activity.name;

        const currentData = plot[type];

        plot[type] = ((currentData as number) ?? 0) + Number(activity.duration);
      }

      finalData.push(plot);
    }

    const uniqueBarsKey = new Set();
    const finalBars: JSX.Element[] = [];

    for (const plot of finalData) {
      for (const key of Object.keys(plot)) {
        if (key !== 'date') {
          if (uniqueBarsKey.has(key)) {
            continue;
          } else {
            uniqueBarsKey.add(key);
            finalBars.push(
              <Bar
                key={key}
                stackId="stack"
                dataKey={key}
                fill={generateRandomBoldColor()}
                barSize={30}
              />,
            );
          }
        }
      }
    }

    return {
      chartData: finalData,
      bars: finalBars,
    };
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} barGap="-78.8%">
        <CartesianGrid stroke="#e0e0e0" vertical={false} strokeDasharray="3 3" />
        <Tooltip
          shared={false}
          content={(properties) => <CustomTooltip data={data} tooltipProps={properties} />}
        />
        <Legend
          formatter={legendFormatter}
          verticalAlign="top"
          iconType="circle"
          wrapperStyle={{
            top: '-10px',
          }}
        />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatTick} axisLine={false} />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};
