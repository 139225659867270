import { SVGProps } from 'react';

const SwimmingSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <path
      fill="#6C5DD3"
      d="M13.84 24.367c.504.197.952.365 1.764.532.168-.055.476-.195.7-.307.896-.42 2.1-.98 3.696-.98 1.652 0 2.912.616 3.724 1.008.224.111.532.252.644.28.644-.112 1.092-.252 1.624-.42.14-.056.28-.085.42-.14-.504-1.008-1.148-2.325-1.26-2.549-.868-.28-1.596-.84-2.128-1.595l-.168-.252a4.264 4.264 0 0 1-.588-2.296c-.28-.336-2.016-2.8-2.016-2.8s4.004-2.1 4.62-2.408c.616-.308.84-1.036.476-1.68-.336-.588-1.148-.728-1.848-.392-.7.364-5.964 2.968-6.524 3.36-.532.364-.392.98-.14 1.4l-.028-.056c.028.028.056.056.112.14.252.364 2.212 3.36 2.38 3.556l-6.132 5.348.672.252Z"
    />
    <path
      fill="#6C5DD3"
      d="M29.631 17.788a3.192 3.192 0 1 1-6.384 0 3.192 3.192 0 0 1 6.384 0ZM33.244 27.951a1.395 1.395 0 0 0-.7-.84c-1.568-.812-2.66-1.148-3.696-1.148-.784 0-1.456.196-2.184.448-.56.168-1.12.364-1.932.504-.112.028-.224.028-.336.028-.504 0-.952-.224-1.54-.504-.728-.364-1.652-.812-2.828-.812-1.12 0-2.016.42-2.828.784-.616.28-1.092.504-1.568.504-.084 0-.196 0-.28-.028-1.036-.196-1.652-.448-2.212-.644-.644-.252-1.204-.448-1.876-.448-.924 0-1.904.336-3.724 1.288a1.395 1.395 0 0 0-.7.84c-.112.364-.084.756.084 1.092.252.476.728.784 1.288.784a1.3 1.3 0 0 0 .644-.168c1.344-.7 2.016-.952 2.408-.952.224 0 .448.084.868.252.588.224 1.4.532 2.688.784.28.056.56.084.84.084 1.12 0 2.044-.448 2.8-.784.588-.28 1.12-.532 1.596-.532.532 0 .98.224 1.568.504.728.364 1.624.812 2.8.812.28 0 .56-.028.84-.084 1.008-.168 1.736-.42 2.324-.616.56-.168.896-.308 1.288-.308.532 0 1.26.252 2.38.84.196.112.42.168.672.168.532 0 1.036-.308 1.288-.784a1.71 1.71 0 0 0 .028-1.064Z"
    />
  </svg>
);
export default SwimmingSvg;
