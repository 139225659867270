import { styled } from '@mui/material/styles';
import { Box, Grid, Paper } from '@mui/material';

export const StyledPaper = styled(Paper)({
  flexGrow: 1,
  height: '100%',
});

export const GridContainer = styled(Grid)({
  flexGrow: 1,
  height: '100%',
});

export const RecordingUsersListSection = styled(Grid)({
  height: 'calc(100vh - 135px)',
  overflow: 'auto',
});

export const RecordingUsersListInnerSection = styled(Box)({
  textAlign: 'left',
  padding: 0,
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
});

export const RecordingsContainer = styled(Grid)({
  height: 'calc(100vh - 135px)',
  border: '1px solid',
  borderTop: 'none',
  borderBottom: 'none',
});
