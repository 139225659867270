export const EVENTS = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  NOTES: 'NOTES',
  MESSAGES: 'MESSAGES',
};

export const EVENT_STATUS = {
  '0': {
    label: 'Not Accepted',
    color: 'grey.600',
  },
  '1': {
    label: 'Accepted',
    color: 'success.main',
  },
  '-1': {
    label: 'Rejected',
    color: 'grey.600',
  },
};
