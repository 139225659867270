import { MouseEvent, ReactElement, ReactNode, useCallback } from 'react';
import { Button, Link, Typography, Box, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TOptions } from 'i18next';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

import { IParticle } from '../../../../types/particles';
import { Frame, Image, Waveform } from '../../../atoms';
import { useBusEmitter } from '../../../../hooks';
import { EVENTS } from '../../../../constants';
import { paths, proxy } from '../../../../utils';

interface Properties extends BoxProps {
  readonly renderBefore?: () => ReactNode;
  readonly renderAfter?: () => ReactNode;
  readonly particle: IParticle;
}

type Action = (type: string, particle: IParticle, event: MouseEvent<any>) => void;

const render = (
  particle: IParticle,
  action: Action,
  t?: (key: string, options?: TOptions | undefined) => string,
): ReactNode => {
  const { type, content, text } = particle;

  switch (type) {
    case 'text': {
      return <Typography>{content}</Typography>;
    }
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
    case 'subtitle1':
    case 'subtitle2':
    case 'body1':
    case 'body2':
    case 'caption':
    case 'overline': {
      return <Typography variant={type}>{content}</Typography>;
    }
    case 'html': {
      return (
        <Frame>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Frame>
      );
    }
    case 'link': {
      return (
        <Link
          onClick={(event: MouseEvent<HTMLAnchorElement>) => action(type, particle, event)}
          href={content}
          target="_blank"
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          {text || content}
        </Link>
      );
    }
    case 'router': {
      return (
        <Button
          size="small"
          onClick={(event: MouseEvent<HTMLAnchorElement>) => action(type, particle, event)}
          component={RouterLink}
          to={content}
          sx={{ textDecoration: 'none', color: 'inherit' }}
          variant="outlined"
        >
          {text || content}
        </Button>
      );
    }
    case 'button': {
      return (
        <Button
          size="small"
          onClick={(event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) =>
            action(type, particle, event)
          }
          variant="outlined"
        >
          {text || content}
        </Button>
      );
    }
    case 'waveform': {
      return <Waveform url={proxy(content)} />;
    }
    case 'image': {
      return (
        <a href={proxy(content)}>
          <Image width="100%" src={proxy(content)} style={{ maxHeight: 190 }} />
        </a>
      );
    }
    case 'audio': {
      return (
        <audio style={{ width: '100%' }} controls>
          <track kind="captions" srcLang="en" label={t?.('Audio')} />
          <source src={proxy(content)} />
        </audio>
      );
      // return <AudioPlayer source={content} />;
    }
    case 'video': {
      return (
        <video width="100%" height="100%" controls>
          <track kind="captions" srcLang="en" label={t?.('Video')} />
          <source src={proxy(content)} />
        </video>
      );
      // return <VideoPlayer source={content} />;
    }
    case 'file': {
      const { extension } = paths(content);

      switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg': {
          return render({ ...particle, type: 'image' }, action);
        }
        case 'mp3': {
          return render({ ...particle, type: 'audio' }, action);
        }
        case 'mp4': {
          return render({ ...particle, type: 'video' }, action);
        }
        default: {
          return render({ ...particle, type: 'link' }, action);
        }
      }
    }
    default: {
      return null;
    }
  }
};

export function Particle({
  renderBefore,
  renderAfter,
  particle,
  sx,
  children,
  ...rest
}: Properties): ReactElement<Properties> {
  const busEmitter = useBusEmitter();
  const { t } = usePreferredTranslation();

  const action = useCallback(
    (type: string, particle: IParticle, event: MouseEvent<any>) => {
      busEmitter(EVENTS.NOTIFICATIONS, type, particle, event);
    },
    [busEmitter],
  );

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', ...sx }}
      data-testid="particleComponent"
      {...rest}
    >
      {renderBefore?.()}
      {particle && render(particle, action, t)}
      {renderAfter?.()}
    </Box>
  );
}
