import { IAttachment } from '../types/messages';
import { AttachmentType } from '../constants';

const attachment1: IAttachment = {
  id: 'ni24b5u2h3b5224',
  name: 'rash_photo',
  format: 'png',
  fileSize: '37kb',
  url: 'https://picsum.photos/seed/picsum/768/1024',
  type: AttachmentType.IMAGE,
  createdAt: '18 mins ago',
};

const attachment2: IAttachment = {
  id: 'ni24b5u2h3b5224',
  name: 'sound',
  format: 'wav',
  fileSize: '53kb',
  url: '/audio/sample.wav',
  type: AttachmentType.AUDIO,
  createdAt: '22 days ago',
};

const attachment3: IAttachment = {
  id: 'ni24b5u2h3b5224',
  name: 'video file',
  format: 'mp4',
  fileSize: '10MB',
  url: '/video/sample-video-file.mp4',
  type: AttachmentType.VIDEO,
  thumbnailUrl: '/images/doctor.png',
  createdAt: '12 days ago',
};

export const userSharedFiles: IAttachment[] = [attachment1, attachment2, attachment3];
