import { Button, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { ChangeEvent, HTMLAttributes, ReactElement, useCallback, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Empty } from 'antd';
import { dayjs } from '../../../utils/dayjs';

import { formatPhoneNumber } from '../../../utils';
import { PhoneNumberType } from '../../../constants';
import { DialogCloseButton } from '../../atoms';
import { CountryPhoneNumberEntity, Maybe, Scalars, useUserLazyQuery } from '../../../graphql';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';

interface Properties extends HTMLAttributes<ReactElement> {
  open?: boolean;
  user: IUser;
  onClose?: () => void;
  eventDate?: string | Date;
  isCoachingSession?: boolean;
}

interface CallDeviceOptionProperties {
  isSelected?: boolean;
  country?: Maybe<CountryPhoneNumberEntity>;
  id: Scalars['ID'];
  isPreferred: Scalars['Boolean'];
  label: Scalars['String'];
  type: Scalars['String'];
}

const CallDeviceOption = ({
  type,
  label,
  isPreferred,
  country,
  isSelected,
}: CallDeviceOptionProperties) => {
  const theme = useTheme();
  const formattedPhoneNumber = formatPhoneNumber(country);

  return (
    <Stack direction="row" alignItems="center">
      <Stack flex={1} direction="row" alignItems="center">
        <IconButton
          aria-label="call type"
          size="large"
          sx={{
            border: `1px solid ${isSelected ? theme.palette.primary.main : grey[300]}`,
            color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
          }}
        >
          {type === 'mobile' ? <PhoneIphoneOutlinedIcon /> : <PhoneCallbackOutlinedIcon />}
        </IconButton>
        <Box sx={{ pl: 1.5 }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            {label}
          </Typography>
          <Typography variant="body1">
            {country?.countryCallingCode || ''}
            {formattedPhoneNumber}
          </Typography>
        </Box>
      </Stack>
      <Typography
        variant="caption"
        sx={{ fontStyle: 'italic', color: theme.palette.text.secondary, pr: 1 }}
      >
        {isPreferred && 'preferred'}
      </Typography>
    </Stack>
  );
};

export default function SelectCallDeviceModal({
  open = false,
  user,
  onClose,
  eventDate,
  isCoachingSession,
}: Properties) {
  const theme = useTheme();
  const { t } = usePreferredTranslation();
  const navigate = useNavigate();
  const [getUser] = useUserLazyQuery();
  const [selectedOption, setSelectedOption] = useState('');

  const handleClose = useCallback(() => onClose && onClose(), [onClose]);

  const handleCall = async () => {
    const detailedUser = await getUser({ variables: { id: Number(user?.id) } });
    const phoneNumber = detailedUser?.data?.user?.phoneNumbers?.find(
      ({ id }) => id === selectedOption,
    );

    if (phoneNumber) {
      let url = '/video-call';

      if (eventDate && isCoachingSession) {
        url += `?coachingEventDate=${dayjs(eventDate).format('YYYY-MM-DD')}`;
      }

      navigate(url, {
        state: {
          user: detailedUser?.data?.user,
          phoneNumber,
          callType: 'phone-call',
          startDate: new Date(),
          coachingEventDate: eventDate,
        },
      });
    }
  };

  const handleChangeOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  const userPhoneNumbers =
    user?.phoneNumbers?.filter(
      ({ type }) => type === PhoneNumberType.MOBILE || type === PhoneNumberType.LINE,
    ) || [];

  const userHasNoPhoneNumbers = userPhoneNumbers?.length === 0;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack spacing={1}>
          <Box sx={{ mb: 2 }}>
            <Typography component="p" variant="h5" sx={{ maxWidth: '80%' }}>
              {t('Select a number or device to call on')}
            </Typography>
            <DialogCloseButton onClick={handleClose} />
          </Box>

          {userHasNoPhoneNumbers ? (
            <Box sx={{ my: 1 }}>
              <Empty
                description={t('The participant does not have a phone number.')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </Box>
          ) : (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
                value={selectedOption}
                onChange={handleChangeOption}
              >
                {userPhoneNumbers.map(({ id, type, label, isPreferred, country }) => (
                  <FormControlLabel
                    key={id}
                    value={id}
                    label={
                      <CallDeviceOption
                        id={id}
                        type={type}
                        label={label}
                        isPreferred={isPreferred}
                        country={country}
                        isSelected={id === selectedOption}
                      />
                    }
                    control={<Radio sx={{ color: grey[300] }} />}
                    labelPlacement="start"
                    sx={{
                      p: 2,
                      m: 0,
                      borderRadius: theme.typography.pxToRem(12),
                      background:
                        id === selectedOption
                          ? alpha(theme.palette.primary.main, 0.12)
                          : theme.palette.common.white,
                      '& .MuiFormControlLabel-label': {
                        flex: 1,
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          <Stack alignItems="flex-end">
            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
              <Button variant="outlined" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={handleCall}
                disabled={!selectedOption || userHasNoPhoneNumbers}
              >
                {t('Call')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
