import { LANGUAGES } from '../../constants';

export const getUserPreferredLanguage = (user: any | null | undefined): string => {
  const cacheLanguage = localStorage.getItem('language');

  if (cacheLanguage) return cacheLanguage;

  if (!user?.preferredLanguage) {
    return LANGUAGES.ENGLISH.shortName;
  }

  const language = Object.values(LANGUAGES).find((lang) => lang.label === user.preferredLanguage);
  return language?.shortName || LANGUAGES.ENGLISH.shortName;
};
