export const userMedia = [
  {
    id: 1,
    type: 'document',
    name: 'Design sprint Q1 2021',
    sharedBy: 'Bessie Cooper',
    date: '16/02/2021',
    url: '/svgs/card1.svg',
    caption: 'Jane Cooper',
    alt: 'Media Card',
  },
  {
    id: 2,
    type: 'directory',
    name: 'Documentation',
    sharedBy: 'Savannah Nguyen',
    date: '16/02/2021',
    url: '/svgs/card2.svg',
    caption: 'Brooklyn Simmons',
    alt: 'Media Card',
  },
  {
    id: 3,
    type: 'directory',
    name: 'Notes',
    sharedBy: 'Ralph Edwards',
    date: '16/02/2021',
    url: '/svgs/card3.svg',
    caption: 'Guy Hawkins',
    alt: 'Media Card',
  },
  {
    id: 4,
    type: 'image',
    name: 'Use-This-Meme.jpg',
    sharedBy: 'Esther Howard',
    date: '16/02/2021',
    url: '/svgs/card4.svg',
    caption: 'Devon Lane',
    alt: 'Media Card',
  },
  {
    id: 5,
    type: 'image',
    name: 'Magical-Retreat-2020.jpg',
    sharedBy: 'Jane Cooper',
    date: '16/02/2021',
    url: '/svgs/card5.svg',
    caption: 'Darrell Steward',
    alt: 'Media Card',
  },
  {
    id: 6,
    type: 'video',
    name: 'Drigma-Logo.eps',
    sharedBy: 'Theresa Webb',
    date: '16/02/2021',
    url: '/svgs/card6.svg',
    caption: 'Jenny Wilson',
    alt: 'Media Card',
  },
  {
    id: 7,
    type: 'document',
    name: 'Table Documentation',
    sharedBy: 'Robert Fox',
    date: '16/02/2021',
    url: '/svgs/card7.svg',
    caption: 'Brooklyn Simmons',
    alt: 'Media Card',
  },
  {
    id: 8,
    type: 'spreadsheet',
    name: 'Drigma-Video.mp4',
    sharedBy: 'Darlene Robertson',
    date: '16/02/2021',
    url: '/svgs/card8.svg',
    caption: 'Ryan Jones',
    alt: 'Media Card',
  },
  {
    id: 9,
    type: 'image',
    name: 'Figma-Tutorial.mp4',
    sharedBy: 'Dianne Russell',
    date: '16/02/2021',
    url: '/svgs/card9.svg',
    caption: '8º año - trabajo de investiga...',
    alt: 'Media Card',
  },
  {
    id: 10,
    type: 'document',
    name: 'Design sprint Q1 2021',
    sharedBy: 'Bessie Cooper',
    date: '16/02/2021',
    url: '/svgs/card10.svg',
    caption: '8º año - trabajo de investiga...',
    alt: 'Media Card',
  },
  {
    id: 11,
    type: 'image',
    name: 'Use-This-Meme.jpg',
    sharedBy: 'Esther Howard',
    date: '16/02/2021',
    url: '/svgs/card11.svg',
    caption: '8º año - trabajo de investiga...',
    alt: 'Media Card',
  },
  {
    id: 12,
    type: 'video',
    name: 'Figma-Tutorial.mp4',
    sharedBy: 'Dianne Russell',
    date: '16/02/2021',
    url: '/svgs/card12.svg',
    caption: '8º año - trabajo de investiga...',
    alt: 'Media Card',
  },
];
