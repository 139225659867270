import NexmoClient, { Application, Member, NXMCall } from 'nexmo-client';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useStartConference } from '../../../components/organisms/VideoCall/hooks/useStartConference';
import { useUpdateConferenceMutation, useVonageAuthLazyQuery } from '../../../graphql';
import { useUser } from '../../user';

const PhoneCallContext = createContext<{
  app: Application | null;
  call: NXMCall | null;
  conferenceId: string;
  token: string;
  initPhoneCall: (user: any, fromUser: any, phoneNumber: any, onEndPhoneCall: any) => Promise<any>;
  isInitialized: boolean;
  isMute: boolean;
  mutePhoneCall: (shouldMute: boolean) => void;
  onPhoneCallEnd: () => void;
}>({
  app: null,
  call: null,
  conferenceId: '',
  token: '',
  initPhoneCall: async () => {},
  isInitialized: false,
  isMute: false,
  mutePhoneCall: async () => {},
  onPhoneCallEnd: () => {},
});

export const PhoneCallProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [app, setApp] = useState<Application | null>(null);
  const [call, setCall] = useState<NXMCall | null>(null);
  const [isMute, setIsMute] = useState<boolean>(false);
  const callReference = useRef<NXMCall | undefined>();
  const [token, setToken] = useState<string>('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [conferenceId, setConferenceId] = useState<string>('');
  const { studyId } = useUser();
  const [getVonageAuth] = useVonageAuthLazyQuery();
  const { startConference } = useStartConference({ retryCount: 3 });
  const [updateConference] = useUpdateConferenceMutation();
  const mutePhoneCall = useCallback(
    (shouldMute: boolean) => {
      call?.conversation.media.mute(shouldMute);
      setIsMute(shouldMute);
    },
    [call],
  );

  useEffect(() => {
    return () => {
      setIsInitialized(false);
      setConferenceId('');
      setToken('');
    };
  }, []);

  const onPhoneCallEnd = useCallback(() => {
    setConferenceId('');
    setApp(null);
    setCall(null);
    setToken('');
    setIsInitialized(false);
    setIsMute(false);
  }, []);

  const initPhoneCall = useCallback(
    async (user: any, fromUser: any, phoneNumber: any, onEndPhoneCall: any) => {
      if (isInitialized || !user) {
        return;
      }

      let configId = '';

      try {
        const { data: { vonageAuth: { token: vonageToken = null } = {} } = {}, error } =
          await getVonageAuth({
            variables: { userId: Number(user.id) },
            fetchPolicy: 'network-only',
          });

        if (error) {
          toast.error('Call disconnected due to some error, please try again');
          navigate(-1);
          return;
        }

        if (!vonageToken) {
          return;
        }

        setIsInitialized(true);

        const vonageApp = await new NexmoClient().createSession(vonageToken);

        vonageApp.on('member:call', async (_member: Member, c: NXMCall) => {
          setCall(c);
          callReference.current = c;

          if (!user || !fromUser) {
            return;
          }

          const { response: configResponse, errors } = await startConference({
            variables: {
              type: 'phone-call',
              fromUserId: Number(fromUser.id),
              toUserId: Number(user.id),
              conversationUuid: c.conversation.id,
              studyId,
              sessionId: null,
            },
          });

          if (errors && errors.length > 0) {
            toast.error('Call disconnected due to some error, please try again');
            c.hangUp();
            navigate(-1);
            return;
          }

          const data = configResponse?.data;

          if (data?.startConference?.id) {
            setConferenceId(data.startConference.id);
            configId = data.startConference.id;
          }
        });

        vonageApp.on('call:status:changed', (c: NXMCall) => {
          if (c.status === 'answered') {
            updateConference({
              variables: {
                id: Number(configId),
                updateConferenceInput: {
                  status: 'completed',
                },
              },
              fetchPolicy: 'network-only',
            });
          }

          if (c.status === 'completed' && onEndPhoneCall) {
            onEndPhoneCall();
          }
        });

        if (!phoneNumber) {
          return;
        }

        const { countryCallingCode = '', nationalNumber = '' } = phoneNumber.country || {};
        await vonageApp.callServer(`${countryCallingCode}${nationalNumber}`);
        setApp(vonageApp);
        setToken(vonageToken);
      } catch (error) {
        toast.error(error.message || 'The call dropped unexpectedly. Please try again.');
      }
    },
    [
      isInitialized,
      getVonageAuth,
      startConference,
      studyId,
      setConferenceId,
      navigate,
      updateConference,
    ],
  );

  const contextValue = useMemo(
    () => ({
      app,
      call,
      conferenceId,
      token,
      initPhoneCall,
      isInitialized,
      isMute,
      mutePhoneCall,
      onPhoneCallEnd,
    }),
    [
      app,
      call,
      conferenceId,
      token,
      initPhoneCall,
      isInitialized,
      isMute,
      mutePhoneCall,
      onPhoneCallEnd,
    ],
  );

  return <PhoneCallContext.Provider value={contextValue}>{children}</PhoneCallContext.Provider>;
};

export const usePhoneCall = () => {
  const context = useContext(PhoneCallContext);
  if (!context) {
    throw new Error('usePhoneCall must be used within a PhoneCallProvider');
  }
  return context;
};
