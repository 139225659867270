import { Cell, Pie, PieChart } from 'recharts';

import { Box } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

interface Properties {
  data: any[];
  colors: string[];
  isCompleted?: boolean;
  size?: number;
}

export const DonutPieChart = ({ colors, data, size = 150, isCompleted = false }: Properties) => {
  const innerRadius = size * 0.25;
  const tickSize = size * 0.4;

  // If there is no data, create a single entry with a gray color
  const chartData = data.length === 0 ? [{ value: 1, fill: '#E0E0E0' }] : data;

  return (
    <Box position="relative" width={size} height={size}>
      <PieChart width={size} height={size}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={chartData}
          cx="50%"
          cy="50%"
          startAngle={-170}
          innerRadius={innerRadius}
          outerRadius={innerRadius + 8} // Add some padding to the outer radius
          fill="#8884d8"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill || colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: `100%`,
          height: `100%`,
        }}
      >
        {isCompleted && <DoneIcon style={{ fill: '#00000', fontSize: `${tickSize}px` }} />}
        {!isCompleted && <DoneIcon style={{ fill: '#E0E0E0', fontSize: `${tickSize}px` }} />}
      </Box>
    </Box>
  );
};
