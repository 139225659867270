import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { InfoModal } from '../../atoms/Journal';

const PopUp = ({
  title,
  description,
  icon,
}: {
  title?: string;
  description?: string | null;
  icon?: string | null;
}) => {
  const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      {title && (
        <InfoIcon
          color="primary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{ cursor: 'pointer', ml: 1 }}
        />
      )}
      <Popover
        id="info-popover"
        sx={{ pointerEvents: 'none' }}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableRestoreFocus
        PaperProps={{
          style: {
            boxShadow:
              '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
            borderRadius: '10px',
          },
        }}
      >
        <InfoModal
          displayTitle={title}
          example={description?.toString()}
          thumbnail={icon?.toString()}
          showCloseButton={false}
        />
      </Popover>
    </>
  );
};
export default PopUp;
