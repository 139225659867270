export enum COLLABORATIONS {
  ALL = '',
  TEXT = 'TEXT',
  VOICE = 'VOICE',
  EMAIL = 'EMAIL',
  RECORDING = 'RECORDING',
}

export enum PhoneNumberType {
  MOBILE = 'Mobile',
  SMS = 'SMS',
  LINE = 'Line',
}

export const COLLABORATION_LABELS = new Map<string, string>([
  [COLLABORATIONS.ALL, 'All'],
  [COLLABORATIONS.VOICE, 'Voice Mail'],
  [COLLABORATIONS.TEXT, 'Text Messages'],
  [COLLABORATIONS.EMAIL, 'Email'],
  [COLLABORATIONS.RECORDING, 'Recordings'],
]);
