import { createContext, ReactNode, useContext, useMemo } from 'react';

import { ENDPOINTS } from '../../constants';
import { IPermissions } from '../../types/permissions';
import { useAxios } from '../../hooks';

export const PermissionsContext = createContext<IPermissions>([]);

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.PERMISSIONS });
  const contextValue = useMemo((): IPermissions => data, [data]);

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => useContext(PermissionsContext);
