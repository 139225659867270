import React from 'react';
import { ActivityType } from './BottomTabs';
import { DistanceChart } from './Charts/DistanceChart';
import { StepsChart } from './Charts/StepsChart';
import { HeartRateChart } from './Charts/HeartRateChart';
import { ExerciseChart } from './Charts/ExerciseChart';
import { SleepChart } from './Charts/SleepChart';
import { ActivityChart } from './Charts/ActivityChart';
import { FitbitChartsDataQuery } from '../../../../graphql';

interface ChartsProperties {
  selectedActivity: ActivityType;
  data: FitbitChartsDataQuery | undefined;
}

export const Charts: React.FC<ChartsProperties> = ({ selectedActivity, data }) => {
  let Chart;

  switch (selectedActivity) {
    case ActivityType.Activity: {
      Chart = ActivityChart;
      break;
    }

    case ActivityType.Steps: {
      Chart = StepsChart;
      break;
    }
    case ActivityType.HeartRate: {
      Chart = HeartRateChart;
      break;
    }
    case ActivityType.Distance: {
      Chart = DistanceChart;
      break;
    }
    case ActivityType.Exercise: {
      Chart = ExerciseChart;
      break;
    }
    case ActivityType.Sleep: {
      Chart = SleepChart;
      break;
    }
    default: {
      Chart = StepsChart;
    }
  }

  return <Chart key={selectedActivity} data={data} />;
};
