import React from 'react';
import { TabbedCard } from '../../../../atoms';
import { theme } from '../../../../../theme';
import Timeline, { AdherenceTabs } from './Timeline';
import { MetricsAdherenceByWeek } from '../../../../../utils/adherence';

interface Properties {
  adherenceMetrics: MetricsAdherenceByWeek[];
}

const AdherenceTimeline: React.FC<Properties> = ({ adherenceMetrics }) => {
  return (
    <TabbedCard
      sx={{ boxShadow: theme.shadows[0], height: '100%' }}
      ariaLabel="participant adherence metrics tabs"
      tabs={Object.keys(AdherenceTabs).map((tab) => ({
        label: tab,
        data: <Timeline items={adherenceMetrics} tab={tab as AdherenceTabs} />,
      }))}
    />
  );
};

export default AdherenceTimeline;
