import { ReactElement, useEffect, useState } from 'react';
import { LineChart as Chart } from 'chartist';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { theme } from '../../theme';

const StyledChart = styled(Box)(() => ({
  '.ct-line': {
    stroke: theme.palette.primary.main,
  },
  '.ct-series-a .ct-point': {
    stroke: theme.palette.primary.main,
    strokeWidth: '1rem',
  },
  '.ct-vertical ~ .ct-vertical': {
    strokeDasharray: '0.25rem',
  },
  '.ct-horizontal ~ .ct-horizontal': {
    stroke: 'none',
  },
  '.ct-label.ct-horizontal': {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(theme.typography.fontSize),
  },
  '.ct-label.ct-vertical': {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(12),
  },
}));

export function LineChartist(): ReactElement {
  const [data] = useState({
    labels: [
      'Sun, 24 Jul',
      'Mon, 25 Jul',
      'Tue, 26 Jul',
      'Wed, 27 Jul',
      'Thu, 28 Jul',
      'Fri, 29 Jul',
      'Sat, 30 Jul',
    ],
    series: [[118, 140, 130, 140, 150, 147, 118]],
  });
  const [options] = useState({
    showArea: true,
    height: 260,
    chartPadding: 20,
  });

  useEffect(() => {
    const chart = new Chart('.ct-chart', data, options);

    return () => {
      chart.detach();
    };
  }, [data, options]);

  return <StyledChart className="ct-chart" />;
}
