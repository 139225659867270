import { Box, BoxProps, Typography } from '@mui/material';
import { dayjs } from '../../../../utils/dayjs';

import { NoteEntity } from '../../../../graphql';
import { theme } from '../../../../theme';
import { FORMATS } from '../../../../constants';

interface Properties extends BoxProps {
  note: NoteEntity;
}

export const NoteDetails = ({ note, ...rest }: Properties): JSX.Element => {
  return (
    <Box
      data-testid="noteDetailsComponent"
      sx={{ flexGrow: 1, p: 2, height: 'inherit', position: 'relative', overflow: 'auto' }}
      {...rest}
    >
      <Box sx={{ display: 'flex', alignParticles: 'center', mb: 1 }}>{note.title}</Box>
      {note.description && (
        <Typography fontWeight={500} color={theme.palette.grey[500]} sx={{ mb: 1 }}>
          {note.description}
        </Typography>
      )}
      <Typography
        variant="subtitle2"
        fontWeight={500}
        color={theme.palette.grey[500]}
        sx={{ mb: 1 }}
      >
        {dayjs(note.createdAt).format(FORMATS.DATE)} ({dayjs().to(dayjs(note.createdAt))})
      </Typography>
    </Box>
  );
};
