import { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { dayjs } from '../../../../utils/dayjs';
import { EventByType } from './types';
import { theme } from '../../../../theme';
import { EventItem } from './EventItem';

export const EventList = ({
  events,
  isActive = false,
}: {
  events: EventByType[];
  isActive?: boolean;
}) => {
  const groupedEventsBydate = useMemo(() => {
    const groupedEvents = new Map<string, Array<EventByType>>();

    for (const scheduledEvent of events) {
      const { event } = scheduledEvent;
      const dateKey = dayjs(event.start).format('MMMM D, YYYY');
      const group = groupedEvents.get(dateKey) || [];
      group.push(scheduledEvent);
      group.sort((a: EventByType, b: EventByType) =>
        dayjs(a.event.start).isBefore(dayjs(b.event.start)) ? -1 : 1,
      );
      groupedEvents.set(dateKey, group);
    }

    return new Map(
      [...groupedEvents.entries()].sort((a, b) => (dayjs(a[0]).isAfter(dayjs(b[0])) ? 1 : -1)),
    );
  }, [events]);

  return (
    <>
      {[...groupedEventsBydate].map(([date, events], index) => (
        <Box key={date} sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.primary.main, mb: 0.5 }}
          >
            {date}
          </Typography>
          {events.map((dataRow) => (
            <EventItem key={dataRow.event.id} event={dataRow} isActive={isActive} />
          ))}
          {index !== events.length && <Divider sx={{ mt: 0.5 }} />}
        </Box>
      ))}
    </>
  );
};
