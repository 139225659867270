import { useCallback, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageLoader from '../components/atoms/PageLoader';
import SelectStudyToLoginModal from '../components/modals/users/SelectStudyToLoginModal';
import { useStudiesWithStudyArmsQuery } from '../graphql';
import NotFoundPage from '../pages/errors/NotFound';
import { useCurrentUser, useUser } from '../states';
import { UserRoles } from '../constants/users';
import { usePreferredTranslation } from '../hooks/usePreferredTranslation';

interface Properties {
  permission?: string;
}

export const ProtectedRoutes = ({ permission }: Properties) => {
  const {
    token,
    studyId,
    setToken,
    checkUserPermission,
    checkUserIsSuperAdministrator,
    setStudyId,
    setStudyArmId,
    checkUserRole,
  } = useUser();
  const { t } = usePreferredTranslation();
  const [selectStudyModalOpened, setSelectStudyModalOpened] = useState(!studyId);

  const currentUser = useCurrentUser();
  const studiesVariables = useStudiesWithStudyArmsQuery({ fetchPolicy: 'network-only' });

  const onSelectStudyModalToggle = useCallback(() => {
    setSelectStudyModalOpened(!selectStudyModalOpened);
  }, [selectStudyModalOpened]);

  const onSelectStudyModalCancel = useCallback(() => {
    setToken('');
    setSelectStudyModalOpened(!selectStudyModalOpened);
  }, [selectStudyModalOpened, setToken]);

  useEffect(() => {
    if (token && !currentUser.loading && (currentUser.error || !currentUser.data?.user)) {
      setToken('');
    }
  }, [token, currentUser.loading, currentUser, setToken]);

  if ((token && currentUser.loading) || studiesVariables.loading) {
    return <PageLoader />;
  }

  if (token && !currentUser.loading && (currentUser.error || !currentUser.data?.user)) {
    return (
      <Navigate to="/sign-in" state={{ error: 'User session has expired. Please sign in again' }} />
    );
  }

  if (token && !studyId) {
    const availableStudies = studiesVariables.data?.studiesWithStudyArms.items || [];

    if (!checkUserIsSuperAdministrator(currentUser.data?.user) && availableStudies.length === 0) {
      toast.error(t('You have not been assigned to any studies. Please contact any administrator'));
      setToken('');

      return <PageLoader />;
    }

    if (
      !checkUserIsSuperAdministrator(currentUser.data?.user) &&
      availableStudies.length === 1 &&
      availableStudies[0]
    ) {
      setStudyId(availableStudies[0].id);
      if (
        checkUserRole(currentUser.data?.user, UserRoles.ADMINISTRATOR) &&
        availableStudies[0].studyArms?.length
      ) {
        const armId = availableStudies[0]?.studyArms?.[0]?.id;
        if (armId) {
          setStudyArmId(Number(armId));
        }
      }

      return <PageLoader />;
    }

    return (
      <SelectStudyToLoginModal
        loading={studiesVariables.loading}
        studies={availableStudies}
        open={selectStudyModalOpened}
        onClose={onSelectStudyModalToggle}
        onCancel={onSelectStudyModalCancel}
      />
    );
  }

  if (token && permission) {
    return checkUserPermission(currentUser.data?.user, permission) ? <Outlet /> : <NotFoundPage />;
  }

  return token && !permission ? <Outlet /> : <Navigate to="/sign-in" />;
};
