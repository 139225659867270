import { HTMLAttributes, ReactElement, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material/styles';

interface Properties extends HTMLAttributes<ReactElement> {
  hideAxis?: boolean;
  chartData?: any;
  xKey?: string;
  yKey?: string;
}

export function LineRecharts({
  hideAxis = false,
  chartData = [],
  xKey = 'name',
  yKey = 'Heart Rate (BPM)',
}: Properties): ReactElement {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={260}>
      <AreaChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={theme.palette.primary.main} stopOpacity={1} />
            <stop offset="100%" stopColor={theme.palette.primary.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey={xKey} hide={hideAxis} />
        <YAxis domain={['auto', 'auto']} hide={hideAxis} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={yKey}
          strokeWidth="0.28rem"
          stroke={theme.palette.primary.main}
          dot={{ strokeWidth: '0.4rem' }}
          fillOpacity={0.2}
          fill="url(#gradientColor)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
