import { useCallback } from 'react';
import { Exact, InputMaybe, Scalars, useStartConferenceMutation } from '../../../../graphql';

const DEFAULT_RETRY_COUNT = 3;

type Variables =
  | Exact<{
      type: Scalars['String'];
      fromUserId?: InputMaybe<Scalars['Float']>;
      toUserId?: InputMaybe<Scalars['Float']>;
      studyId?: InputMaybe<Scalars['Float']>;
      sessionId?: InputMaybe<Scalars['String']>;
      conversationUuid?: InputMaybe<Scalars['String']>;
    }>
  | undefined;

interface HookProperties {
  retryCount?: number;
}

export const useStartConference = ({ retryCount = DEFAULT_RETRY_COUNT }: HookProperties) => {
  const [startConference] = useStartConferenceMutation();
  const startConferenceWithRetry = useCallback(
    async ({ variables }: { variables: Variables }) => {
      let retry = 0;
      let response;
      let errors = null;

      while (retry < retryCount) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await startConference({
            variables,
          });

          if (response.data?.startConference) {
            return { response, errors: null };
          }

          if (response.errors) {
            errors = response.errors;
          }

          retry += 1;
        } catch (error) {
          errors = error;
          retry += 1;
        }
      }

      return { response, errors };
    },
    [startConference, retryCount],
  );

  return { startConference: startConferenceWithRetry };
};
