import React, { useMemo } from 'react';
import _ from 'lodash';
import { usePreferredTranslation } from 'src/hooks/usePreferredTranslation';
import { dayjs } from '../../../../utils/dayjs';
import { CoachingEventsQuery } from '../../../../graphql';
import SummaryGroup from '../../../atoms/SummaryGroup';
import { EventStatuses } from './type';

interface CoachingCallSummaryProperties {
  coachingEvents: CoachingEventsQuery | undefined;
}

const SUMMARY = {
  completed: {
    label: 'COMPLETED',
    color: '#1B5E20',
  },
  incomplete: {
    label: 'INCOMPLETE',
    color: '#A18710',
  },
  attempted: {
    label: 'ATTEMPTS',
    color: '#B71C1C',
  },
};

const CoachingCallSummary: React.FC<CoachingCallSummaryProperties> = ({ coachingEvents }) => {
  const { t } = usePreferredTranslation();
  const summaryData = useMemo(() => {
    const events = coachingEvents?.coachingEvents || [];

    const validEvents = events.filter((event) => dayjs(event.start).isBefore(dayjs().endOf('day')));

    const completed = _.countBy(validEvents, 'status')[EventStatuses.COMPLETED] || 0;
    const attempted = _.countBy(validEvents, 'status')[EventStatuses.STARTED] || 0;
    const incomplete = _.countBy(validEvents, 'status')[EventStatuses.INCOMPLETE] || 0;

    return [
      {
        label: t(SUMMARY.attempted.label),
        value: String(attempted),
        color: SUMMARY.attempted.color,
      },
      {
        label: t(SUMMARY.completed.label),
        value: String(completed),
        color: SUMMARY.completed.color,
      },
      {
        label: t(SUMMARY.incomplete.label),
        value: String(incomplete),
        color: SUMMARY.incomplete.color,
      },
    ];
  }, [coachingEvents]);

  return <SummaryGroup data={summaryData} />;
};

export default CoachingCallSummary;
