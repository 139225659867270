import { PathRouteProps } from 'react-router-dom';
import { lazy } from 'react';
import Interventions from '../pages/Interventions';
import { PAGES } from '../constants';
import ParticipantProfilePage from '../pages/ParticipantProfile/index';
import { Permissions } from '../constants/users';

const RETRY_LAZY_REFRESH = 'retry-lazy-refreshed';
const lazyRetry = (componentImport: any): any => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(RETRY_LAZY_REFRESH) || 'false');
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem(RETRY_LAZY_REFRESH, 'false'); // success so reset the refresh
        return resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(RETRY_LAZY_REFRESH, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const NotFound = lazy(() => lazyRetry(() => import('../pages/errors/NotFound')));
const InternalServer = lazy(() => lazyRetry(() => import('../pages/errors/InternalServer')));

const Empty = lazy(() => lazyRetry(() => import('../pages/Empty')));
const Example = lazy(() => lazyRetry(() => import('../pages/Example')));
const Graphql = lazy(() => lazyRetry(() => import('../pages/Graphql')));

const Sitemap = lazy(() => lazyRetry(() => import('../pages/Sitemap')));
const SignIn = lazy(() => lazyRetry(() => import('../pages/SignIn')));
const SignUp = lazy(() => lazyRetry(() => import('../pages/SignUp')));
const ForgotPassword = lazy(() => lazyRetry(() => import('../pages/ForgotPassword')));
const ResetPassword = lazy(() => lazyRetry(() => import('../pages/ResetPassword')));
const Dashboard = lazy(() => lazyRetry(() => import('../pages/Dashboard')));
const PatientDashboard = lazy(() => lazyRetry(() => import('../pages/DashboardPatient')));
const UserManage = lazy(() => lazyRetry(() => import('../pages/Users/Manage')));
const ExportStudyData = lazy(() => lazyRetry(() => import('../pages/ExportStudyData')));
const ExportFitbitData = lazy(() => lazyRetry(() => import('../pages/ExportFitbitData')));
const StudyManagement = lazy(() => lazyRetry(() => import('../pages/StudyManagement')));
const Reports = lazy(() => lazyRetry(() => import('../pages/Reports')));
const Notes = lazy(() => lazyRetry(() => import('../pages/Notes')));
const Notifications = lazy(() => lazyRetry(() => import('../pages/Notifications')));
const Support = lazy(() => lazyRetry(() => import('../pages/Support')));
const Settings = lazy(() => lazyRetry(() => import('../pages/Settings')));
const PersonalDetails = lazy(() => lazyRetry(() => import('../pages/PersonalDetails')));
const VideoCall = lazy(() => lazyRetry(() => import('../pages/VideoCall')));
const CallNotes = lazy(() => lazyRetry(() => import('../pages/CallNotes')));
const InterventionDetail = lazy(() => lazyRetry(() => import('../pages/InterventionDetail')));
const Messaging = lazy(() => lazyRetry(() => import('../pages/Messaging')));
const StudySetup = lazy(() => lazyRetry(() => import('../pages/CreateStudy')));
const Library = lazy(() => lazyRetry(() => import('../pages/Library')));
const LibraryPage = lazy(() => lazyRetry(() => import('../pages/Library/LibraryPage')));
const Schedule = lazy(() => lazyRetry(() => import('../pages/Schedule')));
const AuthDirectSignin = lazy(() => lazyRetry(() => import('../pages/Auth/DirectSignin')));

type Route = PathRouteProps & {
  readonly layout?: '' | 'normal' | 'full';
  readonly path: string;
  readonly permission?: string;
};

export const protectedRoutes: Route[] = [
  {
    path: PAGES.HOME,
    element: <Dashboard />,
    layout: 'normal',
    permission: Permissions.DASHBOARD,
  },
  {
    path: PAGES.PATIENT_HOME,
    element: <PatientDashboard />,
    layout: 'normal',
  },
  {
    path: PAGES.STUDY_MANAGEMENT,
    element: <StudyManagement />,
    layout: 'normal',
    permission: Permissions.STUDY_MANAGEMENT,
  },
  {
    path: PAGES.REPORTS,
    element: <Reports />,
    layout: 'normal',
    permission: Permissions.REPORTS,
  },
  {
    path: PAGES.NOTES,
    element: <Notes />,
    layout: 'normal',
    permission: Permissions.NOTES,
  },
  {
    path: PAGES.NOTIFICATIONS,
    element: <Notifications />,
    layout: 'normal',
    permission: Permissions.NOTIFICATIONS,
  },
  {
    path: PAGES.SUPPORT,
    element: <Support />,
    layout: 'normal',
  },
  {
    path: PAGES.SETTINGS,
    element: <Settings />,
    layout: 'normal',
  },
  {
    path: PAGES.PERSONAL_DETAILS,
    element: <PersonalDetails />,
    layout: 'normal',
  },
  {
    path: PAGES.USER_PROFILE,
    element: <ParticipantProfilePage />,
    layout: 'normal',
    permission: Permissions.VIEW_PROFILE,
  },
  {
    path: PAGES.VIDEO_CALL,
    element: <VideoCall />,
    layout: 'normal',
  },
  {
    path: PAGES.VIDEO_CALL_GUEST,
    element: <VideoCall />,
  },
  {
    path: PAGES.CALL_NOTES,
    element: <CallNotes />,
    layout: 'full',
  },
  {
    path: PAGES.MANAGER_USERS,
    element: <UserManage />,
    layout: 'normal',
    permission: Permissions.USER_MANAGEMENT,
  },
  {
    path: PAGES.EXPORT_STUDY_DATA,
    element: <ExportStudyData />,
    layout: 'normal',
    permission: Permissions.USER_MANAGEMENT,
  },
  {
    path: PAGES.EXPORT_FITBIT,
    element: <ExportFitbitData />,
    layout: 'normal',
    permission: Permissions.USER_MANAGEMENT,
  },
  {
    path: PAGES.INTERVENTIONS,
    element: <Interventions />,
    layout: 'normal',
  },
  {
    path: PAGES.INTERVENTION_DETAIL,
    element: <InterventionDetail />,
    layout: 'normal',
  },
  {
    path: PAGES.GRAPHQL,
    element: <Graphql />,
    layout: 'normal',
  },
  {
    path: PAGES.MESSAGING,
    element: <Messaging />,
    layout: 'normal',
    permission: Permissions.MESSAGING,
  },
  {
    path: `${PAGES.MESSAGING}/:id`,
    element: <Messaging />,
    layout: 'normal',
    permission: Permissions.MESSAGING,
  },
  ...['messages', 'voices', 'emails'].map(
    (key: string): Route => ({
      path: `${PAGES.MESSAGING}/${key}?id=:userId`,
      element: <Messaging />,
      layout: 'normal',
      permission: Permissions.MESSAGING,
    }),
  ),
  {
    path: PAGES.CREATE_STUDY,
    element: <StudySetup />,
    layout: 'full',
    permission: Permissions.STUDY_MANAGEMENT,
  },
  {
    path: PAGES.EDIT_STUDY,
    element: <StudySetup />,
    layout: 'full',
    permission: Permissions.STUDY_MANAGEMENT,
  },
  {
    path: PAGES.LIBRARY,
    element: <Library />,
    layout: 'normal',
    permission: Permissions.LIBRARY,
  },
  {
    path: PAGES.CREATE_LIBRARY,
    element: <LibraryPage />,
    layout: 'normal',
    permission: Permissions.LIBRARY,
  },
  {
    path: PAGES.UPDATE_LIBRARY,
    element: <LibraryPage />,
    layout: 'normal',
    permission: Permissions.LIBRARY,
  },
  {
    path: PAGES.EXAMPLE,
    element: <Example />,
  },
  {
    path: PAGES.SCHEDULE,
    element: <Schedule />,
    layout: 'normal',
    permission: Permissions.SCHEDULING,
  },
];

export const publicUnauthenticatedRoutes: Route[] = [
  {
    path: PAGES.SITEMAP,
    element: <Sitemap />,
  },
  {
    path: PAGES.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: PAGES.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: PAGES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: PAGES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
];

export const publicRoutes: Route[] = [
  {
    path: PAGES.SITEMAP,
    element: <Sitemap />,
  },
  {
    path: PAGES.EMPTY,
    element: <Empty />,
  },
  {
    path: PAGES.INTERNAL_SERVER,
    element: <InternalServer />,
  },
  {
    path: PAGES.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: PAGES.AUTH_DIRECT_SIGNIN,
    element: <AuthDirectSignin />,
  },
];

export const routes: Route[] = [
  ...publicUnauthenticatedRoutes,
  ...protectedRoutes,
  ...publicRoutes,
];
