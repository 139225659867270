import { useCallback } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { FormBuilder } from '../../../organisms/FormBuilder';
import { DialogCloseButton } from '../../..';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';

interface Properties {
  open?: boolean;
  onClose?: () => void;
}

const CreateInterventionModal = ({ open = false, onClose }: Properties) => {
  const handleClose = useCallback(() => onClose && onClose(), [onClose]);
  const { t } = usePreferredTranslation();
  return (
    <Dialog
      PaperProps={{ sx: { minHeight: '80vh' } }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box>
          <DialogCloseButton onClick={handleClose} />

          <FormBuilder />
        </Box>

        <Stack alignItems="flex-end">
          <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 1 }}>
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button variant="contained">{t('Create')}</Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default CreateInterventionModal;
