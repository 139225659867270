import { IInterventions } from '../types/intervention';

const radioQuestion = {
  id: '20kf1f77bcf86cd799435304',
  identifier: 'Pro-CTCAE#12',
  label: 'What was the severity of the pain at its worst',
  options: ['High', 'Medium', 'Low'],
  type: 'radio',
  category: {
    id: 'gf4jd42k5nj7n4knj9f',
    name: 'Category 1',
  },
  required: true,
};

const selectQuestion = {
  id: '40kf1f77bcf86cd799435402',
  identifier: 'Pro-CTCAE#11',
  label: 'What was the severity of the pain at its worst',
  options: ['High', 'Medium', 'Low'],
  type: 'select',
  category: {
    id: 'gf4jd42k5nj7n4knj9f',
    name: 'Category 1',
  },
  required: true,
};

const textQuestion = {
  id: '2kjn3877bcf86cd799484634',
  identifier: 'Pro-CTCAE#10',
  label: 'What was the severity of the pain at its worst',
  options: null,
  type: 'text',
  category: {
    id: 'ifjnd43kjnj5n4knj5n',
    name: 'Category 2',
  },
  required: true,
};

export const defaultQuestions = [
  {
    ...radioQuestion,
    value: '',
  },
  {
    ...selectQuestion,
    value: '',
  },
  {
    ...textQuestion,
    value: '',
  },
];

export const interventions: IInterventions = [
  {
    id: '507f1f77bcf86cd799439011',
    type: 'template',
    title: 'Food Module',
    questionsCount: 2,
    studies: [],
    isPopular: true,
    questions: [
      {
        ...radioQuestion,
        value: 'High',
      },
      {
        ...selectQuestion,
        value: 'Medium',
      },
      {
        ...textQuestion,
        value: 'The visual analogue scale (VAS) and numeric rating scale (NRS)',
      },
    ],
  },
  {
    id: '507f191e810c19729de860ea',
    type: 'custom',
    title: 'Physical Activity',
    questionsCount: 3,
    studies: [],
    isPopular: true,
    questions: [
      {
        ...radioQuestion,
        value: 'Low',
      },
      {
        ...selectQuestion,
        value: 'Medium',
      },
      {
        ...textQuestion,
        value: 'The visual analogue scale (VAS) and numeric rating scale (NRS)',
      },
    ],
  },
  {
    id: '00000020f51bb4362eee2a4d',
    type: 'template',
    title: 'Psychosocial module',
    questionsCount: 3,
    studies: [],
    isPopular: false,
    questions: [radioQuestion, selectQuestion, textQuestion],
  },
  {
    id: '407f1f77bcf86cd799439011',
    type: 'template',
    title: 'Symptoms list',
    questionsCount: 1,
    studies: [],
    isPopular: false,
    questions: [
      {
        ...radioQuestion,
        value: 'Medium',
      },
      {
        ...selectQuestion,
        value: 'Medium',
      },
      {
        ...textQuestion,
        value: 'The visual analogue scale (VAS) and numeric rating scale (NRS)',
      },
    ],
  },
  {
    id: '407f1f77bcf86cd123439011',
    type: 'custom',
    title: 'Symptoms list',
    questionsCount: 3,
    studies: [],
    isPopular: false,
    questions: [
      {
        ...radioQuestion,
        value: 'Low',
      },
      {
        ...selectQuestion,
        value: 'Low',
      },
      {
        ...textQuestion,
        value: 'The visual analogue scale (VAS) and numeric rating scale (NRS)',
      },
    ],
  },
  {
    id: '4072ff77bcf86cd799439011',
    type: 'template',
    title: 'Symptoms list',
    questionsCount: 3,
    studies: [],
    isPopular: false,
    questions: [
      {
        ...radioQuestion,
        value: 'High',
      },
      {
        ...selectQuestion,
        value: 'High',
      },
      {
        ...textQuestion,
        value: 'The visual analogue scale (VAS) and numeric rating scale (NRS)',
      },
    ],
  },
];
