import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useClinicIdsQuery } from '../../graphql';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

const filterClientId = createFilterOptions<string>();

interface AutoCompleteClinicIdProperties {
  defaultValue: string | null;
  onChange: (v: string | null) => void;
  disabled?: boolean;
  loading?: boolean;
}

export default function AutoCompleteClinicIds({
  defaultValue,
  onChange,
  disabled,
  loading,
}: AutoCompleteClinicIdProperties) {
  const { t } = usePreferredTranslation();
  const { data: clinicIdsdata, loading: clinicsLoading } = useClinicIdsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <Autocomplete
      noOptionsText={t('No options')}
      id="clinic-id"
      disabled={disabled}
      loading={clinicsLoading || loading}
      defaultValue={defaultValue || undefined}
      options={clinicIdsdata?.clinicIds ?? []}
      handleHomeEndKeys
      filterOptions={(availableOptions, parameters) => {
        const filtered = filterClientId(availableOptions, parameters);

        const { inputValue } = parameters;
        // Suggest the creation of a new value
        const isExisting: boolean = availableOptions.includes(inputValue);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        // Regular option
        return option;
      }}
      onChange={(_event, value) => onChange(value)}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          placeholder={t('Select')}
          InputProps={{
            ...parameters.InputProps,
          }}
        />
      )}
    />
  );
}
