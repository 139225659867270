import { startCase } from 'lodash';
import { HTMLAttributes, ReactElement } from 'react';
import {
  CalendarMonthOutlined,
  EmailOutlined,
  QuestionMarkOutlined,
  SmsOutlined,
  VoicemailOutlined,
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { dayjs } from '../../../../utils/dayjs';

import {
  AuditEntity,
  EmailEntity,
  EventEntity,
  MessageEntity,
  VoiceEntity,
} from '../../../../graphql';
import { FORMATS } from '../../../../constants';
import { DataRowGrid } from '../../../atoms/TabbedCard/DataRowGrid';
import { ContentLoader, TabbedCard } from '../../../atoms';

interface Properties extends HTMLAttributes<ReactElement> {
  profileId?: number;
  audits: AuditEntity[];
  loading: boolean;
}

const getHeadline = ({ action, tableName, newData }: AuditEntity) => {
  const newObject = JSON.parse(newData ?? '{}');
  const unknownUser = 'another user';
  let icon;
  let text;

  if (action === 'insert') {
    switch (tableName) {
      case 'email': {
        icon = <EmailOutlined />;
        text = `Email sent to ${
          ((newObject as EmailEntity).receiver?.name as string) ?? unknownUser
        }`;

        break;
      }
      case 'message': {
        icon = <SmsOutlined />;
        text = `Message sent to ${
          ((newObject as MessageEntity).receiver?.name as string) ?? unknownUser
        }`;

        break;
      }
      case 'voice': {
        icon = <VoicemailOutlined />;
        text = `Voicemail left for ${
          ((newObject as VoiceEntity).receiver?.name as string) ?? unknownUser
        }`;

        break;
      }
      case 'event': {
        icon = <CalendarMonthOutlined />;
        text = `Event created for ${
          ((newObject as EventEntity).receiver?.name as string) ?? unknownUser
        }`;

        break;
      }
      default: {
        icon = <QuestionMarkOutlined />;
        text = `Unknown ${action as string} action.`;
      }
    }
  } else {
    icon = <QuestionMarkOutlined />;
    text =
      tableName === 'error'
        ? newObject.title ||
          `Error occured while ${action} with ${
            (((newObject as EventEntity).sender || (newObject as EventEntity).receiver)
              ?.name as string) ?? unknownUser
          }`
        : `Unknown ${action} action.`;
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography fontWeight={600}>{icon}</Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={600}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default function Audits({ audits, loading }: Properties) {
  const entries = [
    ...(audits?.map((audit: AuditEntity) => ({
      id: audit.id.toString(),
      date: dayjs(audit.updatedAt).format(FORMATS.DATE),
      headline: getHeadline(audit),
      subtitle: startCase(audit.tableName),
    })) || []),
  ].sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1));

  return (
    <TabbedCard ariaLabel="audit trail">
      <Box sx={{ px: 3 }}>
        {entries?.length ? (
          entries.map((dataRow, rowIndex) => (
            <DataRowGrid
              key={`${dataRow.id || rowIndex}`}
              {...dataRow}
              divider={rowIndex > 0}
              primaryAction={<ChevronRightIcon />}
            />
          ))
        ) : (
          <div
            style={{
              marginTop: '2rem',
              textAlign: 'center',
            }}
          >
            {loading ? (
              <ContentLoader
                speed={2}
                width="99%"
                viewBox="0 0 3000 510"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="10" y="0" rx="3" ry="3" width="100%" height="150" />
                <rect x="10" y="180" rx="3" ry="3" width="80%" height="150" />
                <rect x="10" y="360" rx="3" ry="3" width="90%" height="150" />
              </ContentLoader>
            ) : (
              <span>No audit entries found.</span>
            )}
          </div>
        )}
      </Box>
    </TabbedCard>
  );
}
