import { ReactElement, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, IconButton, BoxProps } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { useMediaRecorder, useTimer } from '../../../../hooks';

interface Properties extends BoxProps {
  readonly disabled?: boolean;
  readonly onComplete?: (blob: Blob, file: File, url: string) => void;
}

export function AudioRecord({
  disabled = false,
  sx,
  onComplete,
  ...rest
}: Properties): ReactElement<Properties> {
  const timer = useTimer(false);

  const onStart = useCallback(() => {
    timer.start(true);
  }, [timer]);

  const onStop = useCallback(
    (url: string, blob: Blob) => {
      timer.stop(true);

      onComplete?.(blob, new File([blob], 'audio.mp3'), url);
    },
    [timer, onComplete],
  );

  const mediaRecorder = useMediaRecorder({ onStart, onStop, audio: true });

  const handleClick = useCallback(() => {
    if (!mediaRecorder.hasPermission) {
      mediaRecorder.getMediaAccess().catch((error) => {
        if (error.name === 'NotAllowedError') {
          toast.warning(
            `To continue, please give your browser permission to access your microphone.`,
          );
        }
      });
    } else if (mediaRecorder.status === 'recording') {
      mediaRecorder.stopRecording();
    } else {
      mediaRecorder.startRecording();
    }
  }, [mediaRecorder]);

  return (
    <Box data-testid="audioRecordComponent" sx={{ display: 'inline-flex', ...sx }} {...rest}>
      <IconButton disabled={disabled} aria-label="Audio Recording" onClick={handleClick}>
        {mediaRecorder.status === 'recording' ? <RecordVoiceOverIcon /> : <MicIcon />}
        {timer.time > 0 && (
          <Badge
            color="primary"
            variant="standard"
            badgeContent={Number(timer.time / 1000).toFixed(2)}
          />
        )}
      </IconButton>
    </Box>
  );
}
