import { SVGProps } from 'react';

const HikingSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g fill="#6C5DD3" clipPath="url(#a)">
      <path d="M11.664 18.711a.936.936 0 0 0 .43.096.906.906 0 0 0 .812-.478l.979-1.839a1.12 1.12 0 0 1 .43-.453c.072-.048.12-.072.19-.12l-.572 2.58a1.756 1.756 0 0 0 .979 2.006l-1.768 5.923v.048l1.123.143h.048c.095 0 .167-.048.239-.096l1.122-1.194 1.194-4.036 1.386.55c.047.023.071.047.071.095l.382 2.317h2.126l-.43-2.652a2.205 2.205 0 0 0-1.361-1.695l-1.577-.621.86-2.866.215.286a2.2 2.2 0 0 0 1.48.932l1.673.262.716 5.47.717-.454-.669-5.111c.239-.143.406-.358.43-.645a.9.9 0 0 0-.645-1.003l-.072-.478a.38.38 0 0 0-.43-.334.38.38 0 0 0-.334.43l.048.263-1.218-.192a.454.454 0 0 1-.263-.167c-1.552-2.269-1.863-2.388-2.317-2.412l-1.504-.096c-.287-.024-.55-.047-2.795 1.29-.477.287-.86.693-1.122 1.17l-.98 1.84c-.19.453-.023 1.003.407 1.241ZM17.423 8.682a2.03 2.03 0 1 1-.44 4.036 2.03 2.03 0 0 1 .44-4.036" />
      <path d="m30.699 29.626-3.081-2.985c-.048-.048-.096-.12-.143-.167l-1.887-2.866a1.266 1.266 0 0 0-1.72-.383l-2.723 1.672-3.75.024c-.286 0-.549.12-.74.31l-1.672 1.744a.98.98 0 0 1-.86.31l-2.245-.31a1.032 1.032 0 0 0-1.027.526l-1.29 2.34c-.358.669.12 1.505.884 1.505h19.49c.382 0 .764-.191.931-.526.239-.406.143-.883-.167-1.194Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9.44 8.67H31v22.675H9.44z" />
      </clipPath>
    </defs>
  </svg>
);
export default HikingSvg;
