import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useCountNotesQuery } from '../../graphql';
import { useCurrentUser } from '../../states/user/useCurrentUser';
import { useChannel, useEvents } from '../../modules';
import { PUSHER } from '../../constants';

export type NotesAnalytics = {
  readonly viewedCount: number;
  readonly notViewedCount: number;
  readonly likedCount: number;
  readonly notLikedCount: number;
  readonly count: number;
};

const defaultValue: NotesAnalytics = {
  viewedCount: 0,
  notViewedCount: 0,
  likedCount: 0,
  notLikedCount: 0,
  count: 0,
};

export const NotesAnalyticsContext = createContext<NotesAnalytics>(defaultValue);

export const NotesAnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();

  const viewedCountNotesQuery = useCountNotesQuery({
    variables: { where: { viewed: true } },
  });
  const likedCountNotesQuery = useCountNotesQuery({
    variables: { where: { liked: true } },
  });
  const countNotesQuery = useCountNotesQuery();

  const contextValue: NotesAnalytics = useMemo<NotesAnalytics>((): NotesAnalytics => {
    const viewedCount = viewedCountNotesQuery.data?.countNotes || 0;
    const likedCount = likedCountNotesQuery.data?.countNotes || 0;
    const count = countNotesQuery.data?.countNotes || 0;
    const notViewedCount = count - viewedCount;
    const notLikedCount = count - likedCount;

    return { ...defaultValue, viewedCount, notViewedCount, likedCount, notLikedCount, count };
  }, [viewedCountNotesQuery, likedCountNotesQuery, countNotesQuery]);

  const notesChannel = useChannel(PUSHER.CHANNELS.NOTES);

  useEvents(
    notesChannel,
    [
      `${PUSHER.EVENTS.NOTE_CREATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.NOTE_DELETED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.NOTE_UPDATED}.${currentUser.data?.user?.id}`,
      `${PUSHER.EVENTS.NOTES_UPDATED}.${currentUser.data?.user?.id}`,
    ],
    () => {
      viewedCountNotesQuery.refetch();
      likedCountNotesQuery.refetch();
      countNotesQuery.refetch();
    },
  );

  return (
    <NotesAnalyticsContext.Provider value={contextValue}>{children}</NotesAnalyticsContext.Provider>
  );
};

export const useNotesAnalytics = () => useContext(NotesAnalyticsContext);
