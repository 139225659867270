import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAxios } from '../../hooks';
import { ENDPOINTS } from '../../constants';
import { IUser } from '../../types/user';

export interface IParticipantInternalNote {
  readonly id: string;
  message: string;
  description: string;
  date: string;
  badges: string[];
  recommendations?: IParticipantRecommendation[];
}

export interface IParticipantRecommendation {
  id: string;
  user: IUser;
  date: string;
  content: string;
}

export type IParticipantInternalNotes = Array<IParticipantInternalNote>;

export type IParticipantInternalNotesContextType = {
  notes: IParticipantInternalNotes;
  saveNote: (note: IParticipantInternalNote) => void;
};

export const ParticipantInternalNotesContext =
  createContext<IParticipantInternalNotesContextType | null>(null);

export const ParticipantInternalNotesProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.USER_INTERNAL_NOTES });
  const [notesData, setNotesData] = useState<IParticipantInternalNotes>([]);

  useEffect(() => {
    if (notesData.length === 0) {
      setNotesData(data || []);
    }
  }, [data, notesData.length]);

  const saveNote = useCallback(
    (note: IParticipantInternalNote) => {
      const newNote: IParticipantInternalNote = {
        ...note,
        id: uuidv4(),
      };
      if (note.id) {
        setNotesData(notesData.map((item) => (item.id === note.id ? note : item)));
      } else {
        setNotesData([...notesData, newNote]);
      }
    },
    [setNotesData, notesData],
  );

  const contextValue = useMemo(
    (): IParticipantInternalNotesContextType => ({
      notes: notesData || [],
      saveNote,
    }),
    [notesData, saveNote],
  );

  return (
    <ParticipantInternalNotesContext.Provider value={contextValue}>
      {children}
    </ParticipantInternalNotesContext.Provider>
  );
};

export const useParticipantInternalNotes = () => useContext(ParticipantInternalNotesContext);
