import { createContext, ReactNode, useContext, useMemo } from 'react';

import { ENDPOINTS } from '../../constants';
import { IStudies } from '../../types/study';
import { useAxios } from '../../hooks';

export const StudiesContext = createContext<IStudies>([]);

export const StudiesProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAxios({ url: ENDPOINTS.STUDIES });
  const contextValue = useMemo((): IStudies => data, [data]);

  return <StudiesContext.Provider value={contextValue}>{children}</StudiesContext.Provider>;
};

export const useStudies = () => useContext(StudiesContext);
