import { SVGProps } from 'react';

const WalkSvg = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...properties}>
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#E2E2E2" rx={19.5} />
    <g clipPath="url(#a)">
      <path
        fill="#6C5DD3"
        d="M21.75 12.417a2.34 2.34 0 0 0 2.333-2.334A2.34 2.34 0 0 0 21.75 7.75a2.34 2.34 0 0 0-2.333 2.333 2.34 2.34 0 0 0 2.333 2.334Zm-4.317 3.966-3.266 16.45h2.45l2.1-9.333 2.45 2.333v7H23.5v-8.75l-2.45-2.333.7-3.5a8.543 8.543 0 0 0 6.417 2.917v-2.334c-2.217 0-4.084-1.166-5.017-2.8l-1.167-1.866C21.517 13.467 20.817 13 20 13c-.35 0-.583.117-.933.117L13 15.683v5.484h2.333V17.2l2.1-.817Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6 6h28v28H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default WalkSvg;
