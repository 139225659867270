import { IconButton, IconButtonProps } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ReactElement } from 'react';

interface DialogCloseButtonProperties extends IconButtonProps {
  disabled?: boolean;
  iconColor?: string;
  onClick?: () => void;
}

export function DialogCloseButton({
  onClick,
  iconColor = 'inherit',
  disabled,
  ...rest
}: DialogCloseButtonProperties): ReactElement {
  return (
    <IconButton
      aria-label="close"
      disabled={disabled}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 3000,
      }}
      onClick={onClick}
      {...rest}
    >
      <CloseIcon sx={{ color: iconColor }} />
    </IconButton>
  );
}
