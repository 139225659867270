import { alpha, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  AssessmentResponsesQuery,
  ParticipantCoachingLibrariesForDateQuery,
} from '../../../../graphql';
import { theme } from '../../../../theme';

type ILibraryItem =
  ParticipantCoachingLibrariesForDateQuery['participantCoachingLibrariesForDate']['items'][0];

interface Properties {
  library: ILibraryItem;
  selectedLibrary: string | null;
  assessmentResponsesData: AssessmentResponsesQuery | undefined;
  onSelectLibrary: (libraryId: string) => void;
}

export default function LibraryItem({
  library,
  selectedLibrary,
  assessmentResponsesData,
  onSelectLibrary,
}: Properties) {
  const assessmentResponses = assessmentResponsesData?.assessmentResponses?.items || [];
  const answerItems =
    assessmentResponses.find((response) => response.library.id === library.id)?.answers ?? [];
  const answeredAnswersCount = answerItems.filter(
    (a) => !(!a.value && (!a.values?.length || a.values?.length === 0)),
  )?.length;
  const isLibraryAnswered = answeredAnswersCount > 0;

  return (
    <div>
      <ListItemButton
        sx={{
          width: '100%',
          textAlign: 'left',
          borderRadius: 1,
          backgroundColor:
            library.id === selectedLibrary
              ? alpha(theme.palette.primary.light, 0.3)
              : 'transparent',
        }}
        onClick={() => onSelectLibrary(library.id)}
      >
        <ListItemIcon>
          <CheckCircleIcon
            sx={{
              color: isLibraryAnswered ? theme.palette.primary.dark : theme.palette.grey[500],
            }}
          />
        </ListItemIcon>
        <Typography variant="body1" fontWeight={theme.typography.fontWeightMedium}>
          {library.title}
        </Typography>
      </ListItemButton>
    </div>
  );
}
